import { Field, Formik } from "formik";
import React from "react";
import { Modal } from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import { Input } from "../../../../_metronic/_partials/controls";

export default function StockAdjustmentModal({
  show,
  onHide,
  branchData,
  saveAdjustmentStock,
}) {
  return (
    <>
      <Modal
        size="md"
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Penyesuaian Stok
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            ...branchData,
            adjustment: branchData && branchData.stock,
            actual_stock: 0,
          }}
          onSubmit={(values) => saveAdjustmentStock(values)}
        >
          {({ setFieldValue, values, handleSubmit }) => (
            <>
              <Modal.Body className="overlay overlay-block cursor-default">
                <div className="form-group">
                  <div className="col">
                    <h5>Stok saat ini: {branchData.stock}</h5>
                  </div>
                </div>
                <div className="form-group">
                  <div className="col">
                    <Field
                      name="qty"
                      type="number"
                      label="Penyesuaian"
                      component={Input}
                      disabled
                      value={values.qty}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="col">
                    <Field
                      name="adjustment"
                      type="number"
                      label="Stok Sesungguhnya"
                      component={Input}
                      placeholder="Masukkan stok sesungguhnya"
                      onChange={(e) => {
                        setFieldValue("adjustment", e.target.value);
                        setFieldValue(
                          "qty",
                          +e.target.value - +branchData.stock
                        );
                      }}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-secondary" onClick={onHide}>
                  Cancel
                </button>
                <button className="btn btn-primary" onClick={handleSubmit}>
                  Save
                </button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Modal>
    </>
  );
}
