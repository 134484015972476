export const StoreStatusCssClasses = ["danger", "success", "info", ""];
export const StoreStatusTitles = ["Suspended", "Active", "Pending", ""];
export const StoreTypeCssClasses = ["success", "primary", ""];
export const StoreTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "created_at", order: "desc" }];
export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
];
export const initialFilter = {
  is_active: null,
  market_name: "",
  platform: "",
  sort_order: "DESC", // asc||desc
  sort_field: "created_at",
  page_number: 1,
  page_size: 10,
};

export let initialValue = {
  is_active: null,
  market_name: "",
  platform: "",
};

export const IS_ACTIVE = [
  {
    label: "Aktif",
    value: true,
  },
  {
    label: "Non AKtif",
    value: false,
  },
];

export const IS_ONLINE = [
  {
    label: "Online",
    value: true,
  },
  {
    label: "Offline",
    value: false,
  },
];

export const status = [
  {
    name: "Aktif",
    value: "ACTIVE",
  },
  {
    name: "Tidak Aktif",
    value: "INACTIVE",
  },
];

export const category = [
  {
    name: "Global",
    value: "GLOBAL",
  },
  {
    name: "Individu",
    value: "INDIVIDU",
  },
];

export const badge = (status) => {
  let data = {};
  if (status === "ACTIVE") data = { text: "ACTIVE", type: "success" };
  else data = { text: "INACTIVE", type: "warning" };

  return data;
};
