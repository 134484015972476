import { createSlice } from "@reduxjs/toolkit";
import { initialFilter } from "../../pages/brand/BrandUIHelpers";

const initialCustomersState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  brandForEdit: undefined,
  lastError: null,
  filters: initialFilter
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const brandSlice = createSlice({
  name: "brand",
  initialState: initialCustomersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getCustomerById
    brandFetched: (state, action) => {
      state.actionsLoading = false;
      state.brandForEdit = action.payload.brandForEdit;
      state.error = null;
    },
    // findCustomers
    brandsFetched: (state, action) => {
      const { total_items, items } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = items;
      state.totalCount = total_items;
    },
    // createCustomer
    brandCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.brand);
    },
    // updateCustomer
    brandUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.brand.id) {
          return action.payload.brand;
        }
        return entity;
      });
    },
    brandFilter: (state, action) => {
      state.listLoading = false
      state.error = false
      state.filters = action.payload
    }
  },
});
