export const ProductStatusCssClasses = ["danger", "success", "info", ""];
export const ProductStatusTitles = ["Suspended", "Active", "Pending", ""];
export const ProductTypeCssClasses = ["success", "primary", ""];
export const ProductTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "created_at", order: "desc" }];
export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
];
export const initialFilter = {
  name: "",
  product_name_or_sku: "",
  description: "",
  slug: "",
  variant: "",
  publish_status: "",
  brand_name: "",
  brand_uuid: "",
  category_name: "",
  category_uuid: "",
  sortOrder: "DESC", // asc||desc
  sortField: "product.created_at",
  page_number: 1,
  page_size: 10,
  isEmpty: true,
  show_empty_stock: true,
  stock_start: null,
  stock_end: null,
  weight_start: null,
  weight_end: null,
  created_at_start: null,
  created_at_end: null,
  dateValue: [],
  publish_status: "",
};

export const initValue = {
  name: "",
  brand: "",
  category: [],
  supplier: "",
  description: "",
  product_image: [],
  product_sku: [],
  product_variant: [],
};

export const PUBLISH_STATUS = [
  {
    name: "PUBLISH",
    value: "PUBLISH",
  },
  {
    name: "UNPUBLISH",
    value: "UNPUBLISH",
  },
];
