// Code helper pembantu diletakan pada halaman ini.
import React from "react";

const defaultOptions = {
    significantDigits: 2,
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbol: 'Rp. ',
};

// Formatter Currency
export const currencyFormatter = (value, options) => {
    value = Number(value)
    if (isNaN(value)) value = 0.0;
    options = {...defaultOptions, ...options};
    value = value.toFixed(options.significantDigits);

    const [currency, decimal] = value.split('.');
    return `${options.symbol} ${currency.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        options.thousandsSeparator,
    )}${options.decimalSeparator}${decimal}`;
};
// End Formatter Currency

export const copyValue = (x) => {
    return JSON.parse(JSON.stringify(x));
}


export function PrivateNumber(cell, row) {
    if (cell) {
        let number = []
        for (let i = 0; i <= cell.length; i++) {
            if (i > cell.length - 3) {
                number.push('*')
            } else {
                number.push(cell[i])
            }
        }
        return (
            <span>{number.join('')}</span>
        );
    } else {
        return ''
    }
}

export function PrivateEmail(cell, row) {
    if (cell) {
        var maskedEmail = cell.replace(/([^@.])/g, "*").split('');
        var previous = "";
        for (let i = 0; i < maskedEmail.length; i++) {
            if (i <= 2 || previous === "." || previous === "@") {
                maskedEmail[i] = cell[i];
            }
            previous = cell[i];
        }
        return (
            <span>{maskedEmail.join('')}</span>
        );
    } else {
        return ''
    }
}
