import React, { useMemo, useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import { VoucherFilter } from './voucher-filter/VoucherFilter';
import { VoucherTable } from './voucher-table/VoucherTable';
import { Badge, Collapse } from 'react-bootstrap';
import { useVoucherUIContext } from './VoucherUIContext';
import { RoleChecker } from '../../../RoleChecker';
import { VoucherEditDialog } from './voucher-edit-dialog/VoucherEditDialog';
import { shallowEqual, useSelector } from 'react-redux';

export const VoucherCard = ({ history }) => {
  const voucherUIContext = useVoucherUIContext();
  const voucherUIProps = useMemo(() => {
    return {
      ids: voucherUIContext.ids,
      newVoucherButtonClick: voucherUIContext.newVoucherButtonClick,
      queryParams: voucherUIContext.queryParams,
    };
  }, [voucherUIContext]);

  const [statusFilter, setStatusFilter] = useState(false);
  const [open, setOpen] = useState(false);
  const [voucherModal, setVoucherModal] = useState(false);

  const changeShow = () => {
    setOpen(!open);

    // Set Status Filter To False
    setStatusFilter(false);
    const filter = voucherUIProps.queryParams;
    for (const [key, value] of Object.entries(filter)) {
      if (value !== '' && key) {
        setStatusFilter(true);
      }
    }
  };

  useEffect(() => {
    if (voucherUIProps.filterType !== undefined) {
      setStatusFilter(true);
      // setIsDisabled(true);
    }
    // eslint-disable-next-line
  }, [voucherUIProps.filterType]);

  const { filters } = useSelector(state => state.voucher, shallowEqual)
  useEffect(() => {
    if (filters.filterIsOpen) setOpen(true);
  }, [])

  return (
    <>
      <VoucherEditDialog show={voucherModal} onHide={() => setVoucherModal(false)} />
      <Card>
        <CardHeader title="Voucher List">
          <CardHeaderToolbar>
            {statusFilter && (
              <Badge className="mr-5" pill variant="danger">
                Filter Aktif
              </Badge>
            )}
            {RoleChecker("read", "voucher") && (
            <button
              type="button"
              className="btn btn-warning mr-2"
              onClick={changeShow}
              aria-controls="collapse-filter-voucher"
              aria-expanded={open}
            >
              Cari
            </button>
            )}
            {RoleChecker("create", "voucher") && (
            <button type="button" className="btn btn-primary" onClick={() => setVoucherModal(true)}>
              Tambah Voucher
            </button>
            )}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Collapse in={open}>
            <div id="collapse-filter-voucher">
              <VoucherFilter history={history}/>
            </div>
          </Collapse>
          <VoucherTable />
        </CardBody>
      </Card>
    </>
  );
};
