import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/notification/notificationActions";
import { useNotificationUIContext } from "../NotificationUIContext";
// import { Input, Select } from "../../../../../../_metronic/_partials/controls";
import { role } from "../NotificationUIHelpers";

export function NotificationDisableDialog({ id, show, onHide }) {
  // Notification Redux state
  const dispatch = useDispatch();
  // const { actionsLoading, notificationForEdit } = useSelector(
  //   (state) => ({
  //     actionsLoading: state.notification.actionsLoading,
  //     notificationForEdit: state.notification.notificationForEdit,
  //   }),
  //   shallowEqual
  // );

  const notificationUIContext = useNotificationUIContext();
  const notificationUIProps = useMemo(() => {
    return {
      ids: notificationUIContext.ids,
      setIds: notificationUIContext.setIds,
      queryParams: notificationUIContext.queryParams,
      setQueryParams: notificationUIContext.setQueryParams,
      openEditNotification: notificationUIContext.openEditNotification,
      // openDeleteNotificationDialog: notificationUIContext.openDeleteNotificationDialog,
    };
  }, [notificationUIContext]);

  const [title, setTitle] = useState("");
  // Title couting
  // useEffect(() => {
  //   setTitle(id);
  //   let _title = "";
  //   if (notificationForEdit && id) {
  //     _title = `Delete notification '${notificationForEdit.name}'`;
  //   }

  //   setTitle(_title);
  //   // eslint-disable-next-line
  // }, [notificationForEdit, actionsLoading]);
  useEffect(() => {
    setTitle(id);
    let _title = "";

    setTitle(_title);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // server call for getting Notification by id
    dispatch(actions.fetchNotifications(notificationUIProps.queryParams));
  }, [id, dispatch]);

  // server request for saving notification
  const updateNotification = () => {
    let data = {
      uuid: id,
      is_active: false,
    };
    dispatch(actions.updateNotification(data)).then(() => onHide());
  };

  const deleteNotification = () => {
    dispatch(actions.deleteNotification(id)).then(() => {
      onHide();
      dispatch(actions.fetchNotifications(notificationUIProps.queryParams));
    });
  };

  return (
    <Modal
      size="md"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Anda yakin akan menghapus notification ini?</span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteNotification}
            className="btn btn-primary btn-elevate"
          >
            Yakin
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
