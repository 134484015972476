import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '../SvgIcon'
import { defaultFill } from '../constant'

const Close = props => (
  <SvgIcon {...props}>
    <path fill={props.fill} className="custom-fill" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
  </SvgIcon>
)

Close.propTypes = {
  fill: PropTypes.string,
}

Close.defaultProps = {
  fill: defaultFill,
}

export default Close
