import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '../SvgIcon'
import { defaultFill } from '../constant'

const Television = props => (
  <SvgIcon {...props}>
    <path fill={props.fill} className="custom-fill" d="M21,17H3V5H21M21,3H3A2,2 0 0,0 1,5V17A2,2 0 0,0 3,19H8V21H16V19H21A2,2 0 0,0 23,17V5A2,2 0 0,0 21,3Z" />
  </SvgIcon>
)

Television.propTypes = {
  fill: PropTypes.string,
}

Television.defaultProps = {
  fill: defaultFill,
}

export default Television
