import { createSlice } from "@reduxjs/toolkit";

const initialDynamicConfigsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  dynamicConfigForEdit: undefined,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const dynamicConfigSlice = createSlice({
  name: "dynamicConfigs",
  initialState: initialDynamicConfigsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getDynamicConfigById
    dynamicConfigFetched: (state, action) => {
      state.actionsLoading = false;
      state.dynamicConfigForEdit = action.payload.dynamicConfigForEdit;
      state.error = null;
    },
    // findDynamicConfigs
    dynamicConfigsFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.entities = action.payload;
    },
    // getAllDynamicConfig
    dynamicConfigAllFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.all = action.payload.all;
    },
    // createDynamicConfig
    dynamicConfigCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.dynamicConfig);
    },
    // updateDynamicConfig
    dynamicConfigUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.dynamicConfig.id) {
          return action.payload.dynamicConfig;
        }
        return entity;
      });
    },
  },
});
