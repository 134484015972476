import { createSlice } from "@reduxjs/toolkit";

const initialMarketplaceTransactionsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  marketplaceTransactionForEdit: undefined,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const marketplaceTransactionSlice = createSlice({
  name: "marketplaceTransactions",
  initialState: initialMarketplaceTransactionsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getMarketplaceTransactionById
    marketplaceTransactionFetched: (state, action) => {
      state.actionsLoading = false;
      state.marketplaceTransactionForEdit =
        action.payload.marketplaceTransactionForEdit;
      state.error = null;
    },
    // findMarketplaceTransactions
    marketplaceTransactionsFetched: (state, action) => {
      const { total_items, items } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = items;
      state.totalCount = total_items;
    },
    // allMarketplaceTransactions
    marketplaceTransactionALlFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.allData = action.payload.allData;
    },
    // createMarketplaceTransaction
    // marketplaceTransactionCreated: (state, action) => {
    //   state.ewactionsLoading = false;
    //   state.error = null;
    //   state.entities.push(action.payload.marketplaceTransaction);
    // },
    // updateMarketplaceTransaction
    marketplaceTransactionUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.marketplaceTransaction.id) {
          return action.payload.marketplaceTransaction;
        }
        return entity;
      });
    },
  },
});
