/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { Dropdown } from "react-bootstrap";
import ApexCharts from "apexcharts";
import {
  DropdownCustomToggler,
  DropdownMenu4,
} from "../../../../_metronic/_partials/dropdowns";
import { GET_MONTHLY_SALES } from "../../Order/_redux/order/orderCrud";

export const MonthlyTransactionWidget = ({
  className,
  chartColor = "danger",
  orders = [],
}) => {
  // const [orders, setOrders] = useState([]);
  // const [fetchOrders] = useLazyQuery(GET_MONTHLY_SALES, {
  //   variables: {},
  //   onCompleted: (data) => {
  //     const { getMonthlySalesByDateRange } = data ?? {};
  //     const { items } = getMonthlySalesByDateRange ?? [];

  //     if (items instanceof Array) {
  //       setOrders(items);
  //     }
  //   },
  // });

  // useEffect(() => {
  //   fetchOrders();
  // }, []);

  useEffect(() => {
    const element = document.getElementById("monthly-sales-chart");

    if (!element) {
      return;
    }

    const options = getChartOption(
      orders.map((item) => ({ x: item.trx_date_month, y: item.total_amount }))
    );
    const chart = new ApexCharts(element, options);

    chart.render();

    return () => {
      chart.destroy();
    };
  }, [orders]);

  return (
    <>
      {/* begin::Tiles Widget 1 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="card-title">
            <div className="card-label">
              <div className="font-weight-bolder">Penjualan Bulanan</div>
              <div className="font-size-sm text-muted mt-2">dalam juta</div>
            </div>
          </div>
          {/* <div className="card-toolbar">
            <Dropdown className="dropdown-inline" alignRight>
              <Dropdown.Toggle
                className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                variant="transparent"
                id="dropdown-toggle-top"
                as={DropdownCustomToggler}
              >
                <i className="ki ki-bold-more-hor" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                <DropdownMenu4 />
              </Dropdown.Menu>
            </Dropdown>
          </div> */}
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body d-flex flex-column px-0">
          {/* begin::Chart */}
          <div
            id="monthly-sales-chart"
            data-color={chartColor}
            style={{ height: "150px" }}
          />
          {/* end::Chart */}
          <div className="flex-grow-1 card-spacer-x"></div>
        </div>
      </div>
      {/* end::Tiles Widget 1 */}
    </>
  );
};

function getChartOption(data) {
  const options = {
    series: [
      {
        name: "Total Pendapatan",
        data,
      },
    ],
    // dataLabels: {
    //   enabled: true,
    //   enabledOnSeries: undefined,
    //   formatter: (val, opts) => currencyFormatter(val),
    //   textAnchor: 'middle',
    // },
    chart: {
      type: "area",
      height: "350px",
    },
    colors: ["#24b8e0", "#66DA26"],
    markers: {
      size: 5,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      type: "category",
    },
    yaxis: {
      type: "integer",
      // min: 0,
      // max: 500,
      labels: {
        formatter: (value) => +value,
      },
    },
  };
  return options;
}
