export const PickupStatusCssClasses = ['danger', 'success', 'info', ''];
export const PickupStatusTitles = ['Suspended', 'Active', 'Pending', ''];
export const PickupTypeCssClasses = ['success', 'primary', ''];
export const PickupTypeTitles = ['Business', 'Individual', ''];
export const defaultSorted = [{ dataField: 'created_at', order: 'desc' }];
export const sizePerPageList = [
  { text: '10', value: 10 },
  { text: '50', value: 50 },
  { text: '100', value: 100 },
];
export const initialFilter = {
  sort_order: 'DESC', // asc||desc
  sort_field: 'rpx_shipment.created_at',
  page_number: 1,
  page_size: 150,
  status: 'WAITING_CONFIRMATION',
  branch_uuid: '',
};

export let initialValue = {
  name: '',
  email: '',
  hp: '',
  password: '',
};

export const status = [
  {
    name: 'Aktif',
    value: 'ACTIVE',
  },
  {
    name: 'Tidak Aktif',
    value: 'INACTIVE',
  },
];

export const category = [
  {
    name: 'Global',
    value: 'GLOBAL',
  },
  {
    name: 'Individu',
    value: 'INDIVIDU',
  },
];

export const RPX_SHIPMENT_STATUS = {
  WAITING_CONFIRMATION: 'WAITING_CONFIRMATION',
  WAITING_PICKUP: 'WAITING_PICKUP',
  ON_PROCESS: 'ON_PROCESS',
  ON_DELIVERY: 'ON_DELIVERY',
  DELIVERED: 'DELIVERED',
  ABORTED: 'ABORTED',
  EXPIRED: 'EXPIRED',
};

export const badge = (status) => {
  let data = {};
  if (status === 'ACTIVE') data = { text: 'ACTIVE', type: 'success' };
  else data = { text: 'INACTIVE', type: 'warning' };

  return data;
};
