import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Formik, Field } from 'formik';
import { Modal } from 'react-bootstrap';
import { Input } from '../../../../../../_metronic/_partials/controls';
import { useLazyQuery } from '@apollo/client';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import { GET_ADDRESS } from '../../../_redux/order/orderCrud';

export const UpdateAddressModal = ({ show, onHide, actionsLoading, initialValues, submit }) => {
  const [currentTimeout, setCurrentTimeout] = useState(null);
  const [currentCallback, setCurrentCallback] = useState(null);
  const [cityOptions, setCityOptions] = useState([]);
  const [postalCodeOptions, setPostalCodeOptions] = useState([]);

  const [searchAddress] = useLazyQuery(GET_ADDRESS, {
    variables: {
      keyword: '',
    },
    onCompleted: (data) => {
      const { findAddressSuggestion } = data;
      const { items = [] } = findAddressSuggestion;

      currentCallback(
        items.map(({ city, province_name, district, postal_codes }) => ({
          label: `${district}, ${city}, ${province_name}`,
          value: city,
          city,
          province_name,
          district,
          postal_codes,
        }))
      );
    },
    fetchPolicy: 'cache-and-network',
  });

  function loadAddressOptions(inputValue, callback) {
    clearTimeout(currentTimeout);

    if (inputValue.length >= 1) {
      setCurrentTimeout(
        setTimeout(() => {
          searchAddress({ variables: { keyword: inputValue } });
          setCurrentCallback(() => callback);
        }, 250)
      );
    } else {
      callback([]);
    }
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ ...initialValues }}
      onSubmit={(values) => {
        submit(values);
      }}
    >
      {({ handleSubmit, values, setFieldValue, errors }) => (
        <Modal size="lg" show={show} onHide={onHide} centered>
          <Modal.Header>
            <Modal.Title>Update Alamat Pengiriman</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group row">
              <div className="col-md-4 mb-5 mb-md-0">
                <label htmlFor="receiver_name">Nama Penerima</label>
                <Field
                  id="receiver_name"
                  name="receiver_name"
                  component={Input}
                  placeholder="Fred Astaire"
                />
              </div>
              <div className="col-md-4 mb-5 mb-md-0">
                <label htmlFor="receiver_hp">No HP Penerima</label>
                <Field
                  id="receiver_hp"
                  name="receiver_hp"
                  component={Input}
                  placeholder="Fred Astaire"
                />
              </div>
              <div className="col-md-4 mb-5 mb-md-0">
                <label htmlFor="address">Alamat</label>
                <Field
                  id="address"
                  name="address"
                  className="form-control"
                  component={'textarea'}
                  placeholder="Jln.Pengasinan, Sawangan, Depok"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-md-8 mb-5 mb-md-0">
                <label htmlFor="city">Kota / Kecamatan</label>
                <AsyncSelect
                  name="city"
                  defaultOptions={cityOptions}
                  loadOptions={(inputValue, callback) => loadAddressOptions(inputValue, callback)}
                  value={
                    values.city
                      ? {
                          label: `${values.sub_district}, ${values.city}, ${values.province}`,
                          value: values.city,
                        }
                      : {
                          label: '',
                          value: '',
                        }
                  }
                  onChange={({ city, province_name, district, postal_codes }) => {
                    setFieldValue('city', city);
                    setFieldValue('province', province_name);
                    setFieldValue('sub_district', district);
                    setPostalCodeOptions(postal_codes);
                  }}
                  noOptionsMessage={({ inputValue }) =>
                    inputValue ? (
                      <p>Kota / Kecamatan tidak ditemukan</p>
                    ) : (
                      <span>Ketik untuk mencari Kota / Kecamatan.</span>
                    )
                  }
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      border: errors.city ? '1px solid rgb(246, 78, 96)' : '1px solid #ddd',
                    }),
                    dropdownIndicator: (provided, state) => ({
                      ...provided,
                      color: errors.city ? 'rgb(246, 78, 96)' : '#ddd',
                    }),
                  }}
                />
              </div>
              <div className="col-md-4 mb-5 mb-md-0">
                <label htmlFor="city">Kode Pos</label>
                <CreatableSelect
                  id="codepos"
                  name="codepos"
                  type="number"
                  options={postalCodeOptions.map((code) => ({
                    label: code,
                    value: code,
                  }))}
                  value={{
                    label: values.codepos,
                    value: values.codepos,
                  }}
                  placeholder="Kode Pos..."
                  onChange={({ value }) => {
                    setFieldValue('codepos', value);
                  }}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              style={{ width: '75px' }}
              className="btn btn-secondary d-flex justify-content-center align-items-center"
              onClick={onHide}
            >
              <span>Cancel</span>
            </button>
            <button
              style={{ width: '75px' }}
              className="btn btn-primary d-flex justify-content-center align-items-center"
              onClick={() => !actionsLoading && handleSubmit()}
            >
              {actionsLoading ? <CircularProgress color="inherit" size={16} /> : <span>Save</span>}
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  );
};
