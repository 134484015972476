// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/notificationbook/index.html?selectedKind=Work%20on%20Columns&selectedNotification=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=notificationbook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";

export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  { showModal, id, allowDelete, allowUpdate, resendFunction }
) {
  return (
    <>
      {row.status === "FAILED" && (
        <a
          title="Kirim Ulang"
          className="btn btn-icon btn-light btn-hover-warning btn-sm mx-3"
          onClick={() => resendFunction(row.uuid)}
        >
          <span className="svg-icon svg-icon-md svg-icon-warning">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Redo.svg")} />
          </span>
        </a>
      )}
      {/* )} */}
      <> </>
      {allowDelete && (
      <a
        title="Delete Notif"
        className="btn btn-icon btn-light btn-hover-danger btn-sm"
        onClick={() => {
          showModal(true);
          id(row.uuid);
        }}
      >
        <span className="svg-icon svg-icon-md svg-icon-danger">
          <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
        </span>
      </a>
      )}
    </>
  );
}
