import React from 'react';
import { Modal } from 'react-bootstrap';
import { MarketplaceTransactionEditForm } from './MarketplaceTransactionEditForm';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { toastOption } from '../../../../../_metronic/_helpers';
import { useMutation } from '@apollo/client';
import { LoadingAnimation } from '../loading-animation/LoadingAnimation';
import { initMarketplaceTransaction } from '../MarketplaceTransactionUIHelpers';
import { CREATE_MARKETPLACE_TRANSACTIONS } from '../../_redux/marketplaceTransaction/marketplaceTransactionCrud';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from '../../../../../_metronic/_partials/controls';
// } from "../../../../_metronic/_partials/controls";

export function MarketplaceTransactionEditDialog({ history, match }) {
  const [fetchCreateMarketplaceTrxs, { loading: fetchLoading }] = useMutation(
    CREATE_MARKETPLACE_TRANSACTIONS
  );

  const saveMarketplaceTransaction = async (params) => {
    const file_uuid = uuidv4();

    const { marketplace_transactions, order_updates } = params;

    if (!(marketplace_transactions instanceof Array)) {
      return;
    }

    try {
      for (let i = 0; i < marketplace_transactions.length; i += 50) {
        let mtrxItems = marketplace_transactions.slice(i, i + 50);
        let orderUpdateItems = order_updates.slice(i, i + 50);

        await fetchCreateMarketplaceTrxs({
          variables: {
            ...params,
            file_uuid,
            marketplace_transactions: mtrxItems,
            order_updates: orderUpdateItems,
          },
          onError: (error) => {
            throw error;
          },
        });
      }

      toast.success('Succes Import Transactions');
      history.go(0);
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      <LoadingAnimation open={fetchLoading} />
      <Card>
        <CardHeader title={'Marketplace Import Transaction'} />
        <MarketplaceTransactionEditForm
          history={history}
          match={match}
          marketplaceTransactionInitValue={initMarketplaceTransaction}
          saveMarketplaceTransaction={saveMarketplaceTransaction}
        />
      </Card>
    </>
  );
}
