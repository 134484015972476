// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState, useEffect } from 'react';
import { Modal, Form as Forms, Carousel, OverlayTrigger, Tooltip, Table } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Input } from '../../../../../_metronic/_partials/controls';
import ReactSelect from 'react-select';
import { Space, DatePicker } from 'antd';
import {
  getVoucherInitialValues,
  IS_ACTIVE,
  VOUCHER_PRODUCT_FILTER_TYPES,
  VOUCHER_TYPES,
} from '../VoucherUIHelpers';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AsyncSelect from 'react-select/async';
import { gql, useLazyQuery } from '@apollo/client';
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';
import SVG from 'react-inlinesvg';
import * as moment from 'moment';
import CurrencyInput from 'react-currency-input-field';
import { getSelectorStyles } from '../../../../../_metronic/_helpers/style-helpers';

const { RangePicker } = DatePicker;

const SEARCH_PRODUCTS = gql`
  query Query($findProductInput: FindCountAllProductInput!) {
    findProduct(findProductInput: $findProductInput) {
      items {
        uuid
        name
        description
        is_deleted
        weight
        brand {
          name
        }
        product_sku {
          price
          price_before
          sku
          uuid
          variant_value_1
          variant_value_2
          image {
            url
          }
        }
      }
    }
  }
`;

const VoucherSchema = yup.object().shape({
  allow_multi_voucher: yup.bool().required('Multi Voucher wajib dipilih.'),
  period_start: yup.date().required('Tanggal mulai/akhir periode wajib diisi.'),
  period_end: yup.date().required('Tanggal mulai/akhir periode wajib diisi.'),
  name: yup.string().required('Tanggal berakhir periode wajib diisi.'),
  type: yup.string().required('Tipe voucher wajib diisi.'),
  amount: yup
    .string()
    .test(
      'amount',
      'Potongan Voucher/Potongan Persen wajib diisi (Isi salah satu).',
      function (value) {
        const { percent } = this.parent;
        const amount = value ?? 0;

        if (+percent > 0) {
          return +amount === 0;
        }

        return +amount > 0;
      }
    ),
  percent: yup
    .string()
    .test(
      'percent',
      'Potongan Voucher/Potongan Persen wajib diisi (Isi salah satu).',
      function (value) {
        const { amount } = this.parent;
        const percent = value ?? 0;

        if (+amount > 0) {
          return +percent === 0;
        }

        return +percent > 0;
      }
    ),
  quota: yup.number().required('Kuota wajib diisi.'),
  code: yup.string().required('Kode voucher wajib diisi.'),
  is_active: yup.bool().required('Status aktif voucher wajib diisi.'),
  publish_status: yup.string().required('Status publish wajib diisi.'),
  minimal_order_amount: yup.number().required('Minimal belanja wajib diisi.'),
  product_filter: yup.string().required('Tipe Filter produk wajib dipilih.'),
  product_list: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
    )
    .when('product_filter', {
      is: (value) => (value ?? '').toUpperCase() !== 'ALL',
      then: (schema) => schema.required('Daftar produk wajib diisi.'),
      otherwise: (schema) => schema.optional(),
    })
    .optional(),
  term_and_conditions: yup.string().required('Syarat Dan Ketentuan wajib diisi.'),
});

export const VoucherEditForm = ({ saveVoucher, voucher, actionsLoading, onHide }) => {
  const [currentTimeout, setCurrentTimeout] = useState(null);
  const [currentCallback, setCurrentCallback] = useState(null);

  const [imagePreviews, setImagePreviews] = useState([]);
  const [isActiveValue, setIsActiveValue] = useState({});
  const [deletedImage, setDeletedImage] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setImageIndex(selectedIndex);
  };

  useEffect(() => {
    if (voucher.uuid) {
      setIsActiveValue({
        label: `${voucher.is_active}`,
        value: voucher.is_active,
      });
    }

    if (voucher.images) {
      setImagePreviews(voucher.images);
    }
  }, [voucher]);

  function previewImage(file) {
    return URL.createObjectURL(file);
  }

  function deleteImageFunction(value) {
    let imageCopy = [...imagePreviews];

    const index = imagePreviews.findIndex((obj) => obj === value);

    imageCopy.splice(index, 1);

    if (value.uuid) {
      setDeletedImage([...deletedImage, value.filename]);
    }
    setImageIndex(index == 0 ? 0 : +index - 1);
    setImagePreviews(imageCopy);
  }

  // * Fetch Search Product
  const [fetchProductOptions] = useLazyQuery(SEARCH_PRODUCTS, {
    variables: { name: '' },
    onCompleted: (data) => {
      if (!data) return;

      const {
        findProduct: { items },
      } = data;

      if (items.length === 0) return currentCallback([]);

      const skus = items.map((item) => {
        return {
          label: item.name,
          value: item.uuid,
        };
      });

      currentCallback(skus);
    },
    fetchPolicy: 'cache-and-network',
  });

  function loadProductOptions(inputValue, callback) {
    clearTimeout(currentTimeout);

    if (inputValue.length >= 3) {
      setCurrentTimeout(
        setTimeout(() => {
          fetchProductOptions({
            variables: {
              findProductInput: {
                product_name_or_sku: inputValue,
                page_number: 1,
                page_size: 15,
                sort_field: 'name',
                sort_order: 'ASC',
              },
            },
          });
          setCurrentCallback(() => callback);
        }, 250)
      );
    } else {
      callback([]);
    }
  }

  const submitVoucher = (v) => {
    let value = { ...v };

    saveVoucher(value);
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        validationSchema={VoucherSchema}
        initialValues={getVoucherInitialValues(voucher)}
        onSubmit={(values) => {
          const { __typename, product_list, products, amount, percent, ...submitValues } = values;

          if (product_list instanceof Array) {
            submitValues.product_list = product_list.map(({ value }) => value);
          }

          if (percent) {
            submitValues.percent = +percent;
            submitValues.amount = 0;
          } else {
            submitValues.amount = +amount ?? 0;
            submitValues.percent = 0;
          }

          submitVoucher(submitValues);
        }}
      >
        {({ handleSubmit, setFieldValue, errors, values, touched }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col">
                    <Field name="name" placeholder="Nama Voucher" component={Input} label="Nama" />
                  </div>
                  <div className="col"></div>
                </div>
                <div className="form-group row">
                  <div className="col">
                    <div className="mb-2">
                      <span>Tanggal Mulai/Akhir Periode</span>
                    </div>
                    <RangePicker
                      name="period_start"
                      showTime={{ format: 'HH:mm' }}
                      format="YYYY-MM-DD HH:mm"
                      value={[values.period_start, values.period_end]
                        .filter((date) => date)
                        .map((date) => moment(date))}
                      onChange={(dates) => {
                        const [start, end] = dates ?? [];

                        setFieldValue(
                          'period_start',
                          (start && start.format('YYYY-MM-DD HH:mm')) || ''
                        );
                        setFieldValue('period_end', (end && end.format('YYYY-MM-DD HH:mm')) || '');
                      }}
                    />
                    <ErrorMessage
                      name="period_start"
                      render={(msg) => <span className="mt-2 text-danger">{msg}</span>}
                    />
                  </div>
                  <div className="col">
                    <Field name="code" component={Input} placeholder="Kode..." label="Kode" />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col">
                    <label>Tipe</label>
                    <ReactSelect
                      name="type"
                      placeholder="Pilih Tipe..."
                      options={VOUCHER_TYPES.map((item) => ({
                        label: item.name,
                        value: item.value,
                      }))}
                      value={
                        values.type
                          ? {
                              label: values.type,
                              value: values.type,
                            }
                          : null
                      }
                      onChange={(e) => {
                        setFieldValue('type', e.value);
                      }}
                    />
                  </div>
                  <div className="col">
                    <label>Status Aktif</label>
                    <ReactSelect
                      name="is_active"
                      placeholder="Pilih Status Aktif..."
                      options={[
                        { label: 'ACTIVE', value: true },
                        { label: 'INACTIVE', value: false },
                      ]}
                      value={
                        values.is_active != null
                          ? {
                              label: values.is_active ? 'ACTIVE' : 'INACTIVE',
                              value: values.is_active,
                            }
                          : null
                      }
                      onChange={(e) => {
                        setFieldValue('is_active', e.value);
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col">
                    <label>Status Publish</label>
                    <ReactSelect
                      name="publish_status"
                      placeholder="Pilih Status Publish..."
                      options={[
                        { label: 'PUBLISHED', value: 'PUBLISHED' },
                        { label: 'UNPUBLISHED', value: 'UNPUBLISHED' },
                      ]}
                      value={
                        values.publish_status
                          ? {
                              label: values.publish_status,
                              value: values.publish_status,
                            }
                          : null
                      }
                      onChange={(e) => {
                        setFieldValue('publish_status', e.value);
                      }}
                    />
                  </div>
                  <div className="col">
                    <label>Allow Multi Voucher</label>
                    <ReactSelect
                      name="allow_multi_voucher"
                      placeholder="Pilih Allow Multi Voucher..."
                      options={[
                        { label: 'TIDAK', value: false },
                        { label: 'YA', value: true },
                      ]}
                      value={
                        values.allow_multi_voucher != null
                          ? {
                              label: values.allow_multi_voucher ? 'YA' : 'TIDAK',
                              value: values.allow_multi_voucher,
                            }
                          : null
                      }
                      onChange={(e) => {
                        setFieldValue('allow_multi_voucher', e.value);
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col">
                    <Field
                      type="number"
                      name="quota"
                      component={Input}
                      placeholder="Kuota"
                      label="Kuota"
                    />
                  </div>
                  <div className="col">
                    <label>Potongan Voucher</label>
                    <CurrencyInput
                      name="amount"
                      placeholder="Masukkan Jumlah"
                      intlConfig={{ locale: 'id-ID', currency: 'IDR' }}
                      className="form-control"
                      value={values.amount}
                      decimalsLimit={2}
                      label="Jumlah"
                      onValueChange={(value, name) => {
                        setFieldValue(name, value);
                      }}
                    />
                    <ErrorMessage
                      name="amount"
                      render={(msg) => <span className="mt-2 text-danger">{msg}</span>}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col">
                    <Field
                      type="number"
                      name="percent"
                      component={Input}
                      placeholder="Persentase"
                      label="Potongan Persen"
                    />
                  </div>
                  <div className="col">
                    <label>Minimal Belanja</label>
                    <CurrencyInput
                      name="minimal_order_amount"
                      placeholder="Masukkan Jumlah Minimal Belanja"
                      intlConfig={{ locale: 'id-ID', currency: 'IDR' }}
                      className="form-control"
                      value={values.minimal_order_amount}
                      decimalsLimit={2}
                      label="Jumlah"
                      onValueChange={(value) => {
                        setFieldValue('minimal_order_amount', +value || '');
                      }}
                    />
                    <ErrorMessage
                      name="minimal_order_amount"
                      render={(msg) => <span className="mt-2 text-danger">{msg}</span>}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col">
                    <label>Tipe Filter Produk</label>
                    <ReactSelect
                      name="product_filter"
                      placeholder="Pilih Tipe Filter Produk..."
                      options={VOUCHER_PRODUCT_FILTER_TYPES.map((item) => ({
                        label: item.name,
                        value: item.value,
                      }))}
                      value={
                        values.product_filter
                          ? {
                              label: values.product_filter,
                              value: values.product_filter,
                            }
                          : null
                      }
                      onChange={(e) => {
                        setFieldValue('product_filter', e.value);
                      }}
                    />
                  </div>
                  <div className="col">
                    <label htmlFor="product">Daftar Produk</label>
                    <AsyncSelect
                      cacheOptions={false}
                      name="product_list"
                      isMulti
                      placeholder="Nama Produk"
                      noOptionsMessage={({ inputValue }) =>
                        inputValue.length >= 3 ? 'No options' : 'Type 3 or more characters'
                      }
                      loadOptions={loadProductOptions}
                      defaultOptions={false}
                      value={values.product_list}
                      onChange={(values) => {
                        if (values instanceof Array) {
                          return setFieldValue('product_list', values);
                        }

                        setFieldValue('product_list', []);
                      }}
                      styles={getSelectorStyles('product_list', errors, touched)}
                    />
                    <ErrorMessage
                      name="product_list"
                      render={(msg) => <span className="mt-2 text-danger">{msg}</span>}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col">
                    <div className="mb-2">
                      <span>Syarat Dan Ketentuan</span>
                    </div>
                    <CKEditor
                      name="term_and_conditions"
                      editor={ClassicEditor}
                      data={voucher.uuid ? voucher.term_and_conditions : ''}
                      config={{
                        removePlugins: ['EasyImage', 'ImageUpload'],
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setFieldValue('term_and_conditions', data);
                      }}
                    />
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button type="button" onClick={onHide} className="btn btn-light btn-elevate">
                Cancel
              </button>
              <> </>
              <button
                type="button"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
};
