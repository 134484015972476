import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { currencyFormatter } from "../../../../../../_metronic/_helpers";
import { CardBody } from "../../../../../../_metronic/_partials/controls";

export default function ProjectDetailList({ data }) {
  const columns = [
    {
      dataField: "product_sku",
      text: "SKU",
      footer: "Total",
    },
    {
      dataField: "product_name",
      text: "Nama Produk",
      sort: true,
      footer: "",
    },
    {
      dataField: "total_order_qty",
      text: "QTY",
      sort: true,
      footer: (cell) => {
        let totalQty = 0;
        cell.map((qty) => (totalQty += Number(qty)));
        return totalQty;
      },
    },
    {
      dataField: "total_amount",
      text: "Nominal",
      headerClasses: "text-right",
      classes: "text-right",
      sort: true,
      formatter: (cell, row, indexRow) => {
        return currencyFormatter(cell);
      },
      footer: (cell) => {
        let total = 0;
        cell.map((amount) => (total += Number(amount)));
        return currencyFormatter(total);
      },
      footerClasses: "text-right",
    },
  ];

  return (
    <>
      <CardBody>
        <BootstrapTable
          wrapperClasses="table-responsive"
          bordered={false}
          classes="table table-head-custom table-vertical-center overflow-hidden"
          bootstrap4
          remote
          keyField="product_sku"
          data={data}
          columns={columns}
        ></BootstrapTable>
      </CardBody>
    </>
  );
}
