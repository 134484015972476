import { createSlice } from "@reduxjs/toolkit";
import { initialFilter } from "../../pages/store/StoreUIHelpers";

const initialStoresState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  storeForEdit: undefined,
  lastError: null,
  filters: initialFilter
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const storeSlice = createSlice({
  name: "stores",
  initialState: initialStoresState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getStoreById
    storeFetched: (state, action) => {
      state.actionsLoading = false;
      state.storeForEdit = action.payload.storeForEdit;
      state.error = null;
    },
    // findStores
    storesFetched: (state, action) => {
      const { total_items, items } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = items;
      state.totalCount = total_items;
    },
    // getAllStore
    storeAllFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.all = action.payload.all;
    },
    // createStore
    storeCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.store);
    },
    // updateStore
    storeUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.store.id) {
          return action.payload.store;
        }
        return entity;
      });
    },
    storeFilter: (state, action) => {
      state.listLoading = false
      state.error = null
      state.filters = action.payload
    }
  },
});
