export const PaymentMethodStatusCssClasses = ["danger", "success", "info", ""];
export const PaymentMethodStatusTitles = ["Suspended", "Active", "Pending", ""];
export const PaymentMethodTypeCssClasses = ["success", "primary", ""];
export const PaymentMethodTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "created_at", order: "desc" }];
export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
];
export const initialFilter = {
  code: "",
  is_active: "",
  pg_code: "",
  type: "",
  price_start: "",
  price_end: "",
  price_percentage: "",
  publish_status: "",
  sort_order: "DESC", // asc||desc
  sort_field: "created_at",
  page_number: 1,
  page_size: 10,
};

export let initialValue = {
  code: "",
  name: "",
  is_active: "",
  pg_code: "",
  type: "",
  price: "",
  price_percentage: "",
};

export const IS_ACTIVE = [
  {
    name: "true",
    value: true,
  },
  {
    name: "false",
    value: false,
  },
];

export const category = [
  {
    name: "Global",
    value: "GLOBAL",
  },
  {
    name: "Individu",
    value: "INDIVIDU",
  },
];

export const paymentType = [
  { name: "WIRED_TRANSFER", value: "WIRED_TRANSFER" },
  { name: "VIRTUAL_ACCOUNT", value: "VIRTUAL_ACCOUNT" },
  { name: "EWALLET", value: "EWALLET" },
  { name: "QRIS", value: "QRIS" },
  { name: "MERCHANT", value: "MERCHANT" },
  { name: "CREDIT_CARD", value: "CREDIT_CARD" },
];

export const paymentMethodType = {
  WIRED_TRANSFER: "WIRED_TRANSFER",
  VIRTUAL_ACCOUNT: "VIRTUAL_ACCOUNT",
  EWALLET: "EWALLET",
  QRIS: "QRIS",
  MERCHANT: "MERCHANT",
  CREDIT_CARD: "CREDIT_CARD",
};

export const badge = (status) => {
  let data = {};
  if (status === "ACTIVE") data = { text: "ACTIVE", type: "success" };
  else data = { text: "INACTIVE", type: "warning" };

  return data;
};

export const PUBLISH_STATUS = [
  {
    name: "PUBLISHED",
    value: "PUBLISHED",
  },
  {
    name: "UNPUBLISHED",
    value: "UNPUBLISHED",
  },
];
