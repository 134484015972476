import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '../SvgIcon'
import { defaultFill } from '../constant'

const Home = props => (
  <SvgIcon {...props}>
    <>
      <path fill={props.fill} className="custom-fill" d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </>
  </SvgIcon>
)

Home.propTypes = {
  fill: PropTypes.string,
}

Home.defaultProps = {
  fill: defaultFill,
}

export default Home
