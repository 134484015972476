import React, { useMemo, useEffect } from "react";
import objectPath from "object-path";
import { useHistory } from "react-router-dom";
import { AsideMenu } from "./aside-menu/AsideMenu";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { getUserByToken } from "../../../../app/modules/Auth/_redux/authCrud";
import { useSelector, useDispatch } from "react-redux";
import { actionTypes } from "../../../../app/modules/Auth/_redux/authRedux";

export function Aside() {
  const uiService = useHtmlClassService();
  const history = useHistory();

  // useEffect(async () => {
  //   await getUserByToken().catch((err) => {
  //     console.log("err", err);
  //     localStorage.clear();
  //     history.push("/")
  //     actionTypes
  //     // window.location.replace("/")
  //   });
  // }, []);

  const layoutProps = useMemo(() => {
    return {
      disableScroll: objectPath.get(uiService.config, "aside.menu.dropdown") === "true" || false,
      asideClassesFromConfig: uiService.getClasses("aside", true),
      disableAsideSelfDisplay: objectPath.get(uiService.config, "aside.self.display") === false,
      headerLogo: uiService.getLogo(),
    };
  }, [uiService]);

  return (
    <>
      {/* begin::Aside */}
      <div
        id="kt_aside"
        className={`aside aside-left ${layoutProps.asideClassesFromConfig} d-flex flex-column flex-row-auto`}
      >
        {/* begin::Aside Menu */}
        <div id="kt_aside_menu_wrapper" className="aside-menu-wrapper flex-column-fluid">
          <AsideMenu disableScroll={layoutProps.disableScroll} />
        </div>
        {/* end::Aside Menu */}
      </div>
      {/* end::Aside */}
    </>
  );
}
