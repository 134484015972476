import { gql } from '@apollo/client';
import { QueryGraphql } from '../../../config/ApoloRequest';

export const CREATE_VOUCHER = gql`
  mutation m($createVoucherInput: CreateVoucherInput!) {
    createVoucher(createVoucherInput: $createVoucherInput) {
      uuid
      code
      amount
    }
  }
`;

export const UPDATE_VOUCHER = gql`
  mutation m($updateVoucherInput: UpdateVoucherInput!) {
    updateVoucher(updateVoucherInput: $updateVoucherInput) {
      uuid
      period_start
      period_end
    }
  }
`;

export const DELETE_VOUCHER = gql`
  mutation Mutation($uuid: String!) {
    deleteVoucher(uuid: $uuid) {
      message
    }
  }
`;

export async function findVoucher(variables) {
  const query = gql`
    query (
      $code: String
      $type: String
      $publish_status: String
      $page_number: Int
      $page_size: Int
    ) {
      findVoucher(findVoucherInput: {
        code: $code
        type: $type
        publish_status: $publish_status
        page_number: $page_number
        page_size: $page_size
      }) {
        items {
          uuid
          name
          code
          type
          period_start
          period_end
          amount
          is_active
          percent
          quota
          used_quota
          publish_status
          created_at
          updated_at
        }
      }
    }
  `;

  const response = await QueryGraphql(query, variables);

  return response.data.findVoucher;
}

export async function searchVoucher(queryParams) {
  let filter = {};

  for (const key in queryParams.filter) {
    if (queryParams.filter[key]) {
      filter = Object.assign(filter, { [key]: queryParams.filter[key] });
    }
  }

  if (!queryParams.filter['page_number']) {
    filter.page_number = 1;
  }

  if (!queryParams.filter['page_size']) {
    filter.page_size = 10;
  }

  const query = gql`
    {
      findVoucher(findVoucherInput: ${JSON.stringify(filter).replace(/"([^"]+)":/g, '$1:')}){
        items {
          uuid
          name
        }
      }
      
    }
  `;

  const newResponse = await QueryGraphql(query);
  return newResponse.data.findVoucher;
}

export async function getVoucherById(uuid) {
  const query = gql`
    query Query($uuid_code: String!) {
      getVoucher(uuid_code: $uuid_code) {
        uuid
        name
        code
        type
        period_start
        period_end
        amount
        minimal_order_amount
        is_active
        percent
        quota
        product_filter
        product_list
        period_start
        period_end
        term_and_conditions
        publish_status
        allow_multi_voucher
        publish_status
        products {
          uuid
          name
        }
      }
    }
  `;

  const newResponse = await QueryGraphql(query, { uuid_code: uuid });
  return newResponse.data.getVoucher;
}
