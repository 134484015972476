export const ShipperServiceStatusCssClasses = ["danger", "success", "info", ""];
export const ShipperServiceStatusTitles = [
  "Suspended",
  "Active",
  "Pending",
  "",
];
export const ShipperServiceTypeCssClasses = ["success", "primary", ""];
export const ShipperServiceTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "created_at", order: "desc" }];
export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
];
export const initialFilter = {
  company_code: "",
  service: "",
  category: null,
  is_active: null,
  code: "",
  sort_order: "DESC", // asc||desc
  // sort_field: "shipping_service.created_at",
  page_number: 1,
  page_size: 10,
};

export let initialValue = {
  company_name: "",
  service: "",
  is_active: true,
};

export const status = [
  {
    name: "ACTIVE",
    value: true,
  },
  {
    name: "INACTIVE",
    value: false,
  },
];

export const category = [
  {
    name: "Global",
    value: "GLOBAL",
  },
  {
    name: "Individu",
    value: "INDIVIDU",
  },
];

export const badge = (status) => {
  let data = {};
  if (status === "ACTIVE") data = { text: "ACTIVE", type: "success" };
  else data = { text: "INACTIVE", type: "warning" };

  return data;
};
