import * as requestFromServer from "./purchaseCrud";
import { purchaseSlice, callTypes } from "./purchaseSlice";
import { toastOption } from "../../../../../_metronic/_helpers";
import { toast } from "react-toastify";

const { actions } = purchaseSlice;

export const fetchPurchases = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findPurchases(queryParams)
    .then((response) => {
      const { total_items, items } = response;
      dispatch(actions.purchasesFetched({ total_items, items }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find purchases";
      console.log(error);
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchPurchase = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.purchaseFetched({ purchaseForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getPurchaseById(id)
    .then((response) => {
      dispatch(actions.purchaseFetched({ purchaseForEdit: response }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find purchase";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updatePurchase = (purchase) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updatePurchase(purchase)
    .then((response) => {
      fetchPurchases();
    })
    .catch((error) => {
      error.clientMessage = "Can't update purchase";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const purchaseFilter = params => dispatch => {
  dispatch(actions.startCall({ callTypes: callTypes.list }))
  dispatch(actions.purchaseFilter(params))
}
