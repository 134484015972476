import axios from 'axios';
import { gql } from '@apollo/client';
import { QueryGraphql, MutationGraphql } from '../../../../config/ApoloRequest';
import { API } from '../../../../config/UrlEndPoint';

export const URL = `${API}master-data/shipperService/`;

export async function findShipperServices(queryParams) {
  const query = gql`
    query Q(
      $category: [String!]
      $code: String
      $company_code: String
      $is_active: Boolean
      $page_number: Int
      $page_size: Int
      $service: String
      $sort_field: String
      $sort_order: String
    ) {
      findShippingService(
        findShippingServiceInput: {
          company_code: $company_code
          category: $category
          service: $service
          is_active: $is_active
          code: $code
          page_number: $page_number
          page_size: $page_size
          sort_order: $sort_order
          sort_field: $sort_field
        }
      ) {
        items {
          service
          company_code
          uuid
          code
          category
          is_active
        }
        total_items
      }
    }
  `;

  const newResponse = await QueryGraphql(query, queryParams);
  return newResponse.data.findShippingService;
}

export async function createShipperService(payload) {
  const query = gql`
    mutation Mutation {
      createShippingService(
        createShippingServiceInput: {
          company_code: "${payload.company_code}"
          service: "${payload.service}"
          code: "${payload.code}"
          category: "${payload.category}"
          is_active: ${payload.is_active}
        }
      ) {
        company_code
        service
      }
    }
  `;
  const newResponse = await MutationGraphql(query);
  return newResponse.data.createShippingService;
}

export const CREATE_SHIPPING_SERVICE = gql`
  mutation Mutation(
    $category: [String!]!
    $code: String!
    $company_code: String!
    $is_active: Boolean!
    $service: String!
  ) {
    createShippingService(
      createShippingServiceInput: {
        company_code: $company_code
        service: $service
        code: $code
        category: $category
        is_active: $is_active
      }
    ) {
      company_code
      service
    }
  }
`;

export const UPDATE_SHIPPING_SERVICE = gql`
  mutation Mutation(
    $uuid: String!
    $category: [String!]
    $code: String
    $company_code: String
    $is_active: Boolean
    $service: String
  ) {
    updateShippingService(
      updateShippingServiceInput: {
        uuid: $uuid
        company_code: $company_code
        service: $service
        code: $code
        category: $category
        is_active: $is_active
      }
    ) {
      company_code
      service
    }
  }
`;

export async function updateShipperService(payload) {
  const query = gql`
    mutation Mutation {
      updateShippingService(
        updateShippingServiceInput: {
          uuid: "${payload.uuid}"
          company_code: "${payload.company_code}"
          service: "${payload.service}"
          code: "${payload.code}"
          category: "${payload.category}"
          is_active: ${payload.is_active}
        }
      ) {
        company_code
        service
        uuid
      }
    }
  `;

  const newResponse = await MutationGraphql(query);
  return newResponse.data.updateShippingService;
}

export async function deleteShipperService(id) {
  const query = gql`
    mutation Mutation {
      deleteShippingService(uuid: "${id}") {
        message
        item {
          company_code
          service
        }
      }
    }
  `;

  const newResponse = await MutationGraphql(query);
  return newResponse.data.deleteShippingService;
}

export async function getShipperServiceById(id) {
  const query = gql`
    {
      getShippingService(uuid: "${id}") {
        company_code
        service
        uuid
        code
        category
        is_active
        shipping_company {
          code
          name
        }
      }
    }
  `;

  const newResponse = await QueryGraphql(query);
  return newResponse.data.getShippingService;
}

// CREATE =>  POST: add a new customer to the server
// export function createShipperService(shipperService) {
//     return axios.post(URL, shipperService);
// }

// GET ALL
export function getAllShipperServices() {
  return axios.get(URL);
}

export const GET_SHIPPING_COST = gql`
  query GetShippingCost(
    $destination_city_name: String!
    $destination_postal_code: String!
    $destination_province_name: String!
    $destination_subdistrict_name: String!
    $origin_postal_code: String!
    $origin_subdistrict_id: String!
    $store_uuid: String
    $weight: Int!
  ) {
    customerGetShippingCost(
      getShippingCostInput: {
        destination_city_name: $destination_city_name
        destination_postal_code: $destination_postal_code
        destination_province_name: $destination_province_name
        destination_subdistrict_name: $destination_subdistrict_name
        origin_postal_code: $origin_postal_code
        origin_subdistrict_id: $origin_subdistrict_id
        store_uuid: $store_uuid
        weight: $weight
      }
    ) {
      is_gayalo
      discount
      results {
        company_code
        company_name
        category
        description
        etd
        price
        service_code
        service_name
        uuid
      }
    }
  }
`;

export const GET_SHIPMENT_TRACKING = gql`
  query Query($order_uuid: String!) {
    getShipmentTracking(order_uuid: $order_uuid) {
      delivery_status {
        status
        pod_time
        pod_receiver
        pod_date
      }
      delivered
      manifest {
        city_name
        manifest_code
        manifest_date
        manifest_description
        manifest_time
      }
    }
  }
`;

// GET SINGLE USER
// export function getShipperServiceById(id) {
//     return axios.get(`${URL}${id}`);
// }

// GET USER WITH CONDITION
// export function findShipperServices(queryParams) {
//     return axios.post(`${URL}find`, queryParams);
// }

// UPDATE SINGEL USER
// export function updateShipperService(shipperService) {
//     return axios.put(`${URL}`, shipperService);
// }
