import { createSlice } from '@reduxjs/toolkit';
import { initialFilter } from '../page/VoucherUIHelpers';

const initialVOucherState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  voucherForEdit: undefined,
  lastError: null,
  filters: initialFilter
};
export const callTypes = {
  list: 'list',
  action: 'action',
};

export const voucherSlice = createSlice({
  name: 'vouchers',
  initialState: initialVOucherState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getVOucherById
    voucherFetched: (state, action) => {
      state.actionsLoading = false;
      state.voucherForEdit = action.payload.voucherForEdit;
      state.error = null;
    },
    // findVOucher
    vouchersFetched: (state, action) => {
      const { total_items, items } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = items;
      state.totalCount = total_items;
    },
    // getAllVOucher
    voucherAllFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.all = action.payload.all;
    },
    // createVOucher
    voucherCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.voucher);
    },
    // updateVOucher
    voucherUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.voucher.id) {
          return action.payload.voucher;
        }
        return entity;
      });
    },
    voucherFilter: (state, action) => {
      state.listLoading = false
      state.error = null
      state.filters = action.payload
    }
  },
});
