import * as requestFromServer from "./notificationCrud";
import { notificationSlice, callTypes } from "./notificationSlice";
import { toastOption } from "../../../../../_metronic/_helpers";
import { toast } from "react-toastify";

const { actions } = notificationSlice;

export const fetchNotifications = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findNotifications(queryParams)
    .then((response) => {
      const { total_items, items } = response;
      dispatch(actions.notificationsFetched({ total_items, items }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find notifications";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchNotificationAll = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllNotifications(queryParams)
    .then((response) => {
      dispatch(actions.notificationALlFetched({ allData: response.data.data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find notifications";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchNotification = (id) => (dispatch) => {
  if (!id) {
    return dispatch(
      actions.notificationFetched({ notificationForEdit: undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getNotificationById(id)
    .then((response) => {
      dispatch(actions.notificationFetched({ notificationForEdit: response }));
      return response;
    })
    .catch((error) => {
      error.clientMessage = "Can't find notification";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateNotification = (notification) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateNotification(notification)
    .then((response) => {
      toast.success(response.data.message, toastOption);
      dispatch(actions.notificationUpdated({ notification }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update notification";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteNotification = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteNotification(id)
    .then(() => {
      fetchNotifications();
      toast.success("Berhasil menghapus notifikasi!", toastOption);
    })
    .catch((error) => {
      console.log(error);
      error.clientMessage = "Can't delete category";
      toast.error("Gagal menghapus notifikasi!", toastOption);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const notificationFilter = params => dispatch => {
  dispatch(actions.startCall({ callTypes: callTypes.list }))
  dispatch(actions.notificationFilter(params))
}
