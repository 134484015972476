import axios from "axios";
import { API } from "../../../../config/UrlEndPoint";
import { gql, useMutation } from "@apollo/client";
import { QueryGraphql, MutationGraphql } from "../../../../config/ApoloRequest";
import { array } from "prop-types";

export const USER_URL = `${API}master-data/admin/`;

export async function findAdmins(queryParams) {
  let email = queryParams.email
    ? `email: "${queryParams.email}"`
    : `email: ${null}`;
  let name = queryParams.name ? `name: "${queryParams.name}"` : `name: ${null}`;
  let role = queryParams.role ? `role: "${queryParams.role}"` : `role: ${null}`;
  const stringQuery = `{
    ${email}
    ${name}
    ${role}
    page_size: ${+queryParams.page_size}  
    page_number: ${+queryParams.page_number}
  }`;

  const query = gql`
    {
      __typename
      findAdmin(findAdminInput: ${stringQuery}) {
        items {
          uuid
          email
          name
          branches {
            branchjoin {
              city
              name
            }
          }
          role
        }
        total_items
      }
    }
  `;
  const newResponse = await QueryGraphql(query);

  return newResponse.data.findAdmin;
}

// CREATE =>  POST: add a new customer to the server
// export async function createAdmin(payload) {
//   const query = gql`

//   `;
//   console.log(query);
//   const newResponse = await MutationGraphql(query);
//   console.log(newResponse.data.createBranch);
//   return newResponse.data.createBranch;
// }

export async function createAdmin(payload) {
  const query = gql`
    mutation {
      createAdmin(
        createAdminInput: {
          email: "${payload.email}"
          password: "${payload.password}"
          branches: ${JSON.stringify(payload.branches)}
          name: "${payload.name}"
          role: "${payload.role}"
        }
      ) {
        name
        email
        hp
        role
      }
    }
  `;
  // return;
  const newResponse = await MutationGraphql(query);
  return newResponse.data.createAdmin;
}

export const CREATE_ADMIN = gql`
  mutation Mutation(
    $branches: [ID!]!
    $email: String!
    $name: String!
    $password: String!
    $role: String!
  ) {
    createAdmin(
      createAdminInput: {
        branches: $branches
        email: $email
        name: $name
        password: $password
        role: $role
      }
    ) {
      name
      email
      hp
      role
    }
  }
`;

export const UPDATE_ADMIN = gql`
  mutation Mutation(
    $uuid: String!
    $branches: [ID!]
    $email: String
    $name: String
    $password: String
    $role: String
  ) {
    updateAdmin(
      updateAdminInput: {
        uuid: $uuid
        branches: $branches
        email: $email
        name: $name
        password: $password
        role: $role
      }
    ) {
      email
      hp
      name
      role
    }
  }
`;

// GET ALL
export function getAllAdmins() {
  return axios.get(USER_URL);
}

// GET SINGLE USER
export async function getAdminById(id) {
  const query = gql`
  {
    getAdmin(uuid: "${id}") {
      email
      hp
      name
      role
      uuid
      branches {
        branches_uuid
        branchjoin {
          uuid
          name
        }
      }
    }
  }
  
  `;
  const newResponse = await QueryGraphql(query);
  return newResponse.data.getAdmin;
}

// GET USER WITH CONDITION
// export function findAdmins(queryParams) {
//     return axios.post(`${USER_URL}find`, queryParams);
// }

export async function updateAdmin(admin) {
  let password = admin.password ? `password: "${admin.password}"` : "";
  let email = admin.email ? `email: "${admin.email}"` : "";
  let name = admin.name ? `name: "${admin.name}"` : "";
  let role = admin.role ? `role: "${admin.role}"` : "";
  let hp = admin.hp ? `hp: "${admin.hp}"` : "";
  let branches = admin.branches
    ? `branches: ${JSON.stringify(admin.branches)}`
    : "";
  const stringQuery = `{
    uuid: "${admin.uuid}"
    ${name}
    ${email}
    ${password}
    ${role}
    ${hp}
    ${branches}
  }`;
  const query = gql`
    mutation Mutation {
      updateAdmin(
        updateAdminInput: ${stringQuery}
      ) {
        email
        name
        role
      }
    }
  `;
  const adminData = await MutationGraphql(query);
  return adminData.data.updateAdmin;
}

export async function deleteAdmin(id) {
  const query = gql`
    mutation {
      deleteAdmin(uuid: "${id}") {
        name
      }
    }
  `;
  const adminData = await MutationGraphql(query);
  return adminData.data.deleteAdmin;
}
