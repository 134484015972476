import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';

export const PackingProductTable = ({ orderItemsValidity = [], removeSkuItem }) => {
  const columns = [
    {
      dataField: 'sku',
      text: 'SKU',
    },
    {
      dataField: 'product',
      text: 'Product Name',
    },
    {
      dataField: 'qty',
      text: 'QTY',
      formatter: (cell, row, rowIndex) => {
        return row.valid_qty != null ? (
          <div>
            {row.valid ? (
              <span className="text-success">
                {cell}/{row.valid_qty}
              </span>
            ) : (
              <span className="text-danger">
                {cell}/{row.valid_qty}
              </span>
            )}
          </div>
        ) : (
          <span className="text-danger">{cell}</span>
        );
      },
    },
    {
      text: 'Status',
      formatter: (cell, row, rowIndex) => {
        if (row.valid === true) {
          return <i style={{ fontSize: '20px', color: '#22BB33' }} class="fas fa-check-circle"></i>;
        } else if (row.valid === false) {
          return <i style={{ fontSize: '20px', color: '#f44336' }} class="fas fa-times-circle"></i>;
        }
      },
    },
    {
      text: 'Actions',
      dataField: 'product_sku_uuid',
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex align-items-center">
            <a
              title="Delete Address"
              className={`btn btn-icon btn-light btn-hover-danger btn-sm mr-5 flex-shrink-0`}
              onClick={() => {
                removeSkuItem(row.product_sku_uuid);
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-danger">
                <SVG src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')} />
              </span>
            </a>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <BootstrapTable
        wrapperClasses="table-responsive"
        bpackinged={false}
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bootstrap4
        remote
        keyField="product_sku_uuid"
        data={orderItemsValidity}
        columns={columns}
      />
    </>
  );
};
