import React, { useMemo, useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import { isEqual } from "lodash";
import { useStoryUIContext } from "../StoryUIContext";
import { initialFilter } from "../StoryUIHelpers";
import ReactSelect from "react-select";
import * as brandCrud from "../../../MasterData/_redux/brand/brandCrud";
import { story_type } from "../StoryUIHelpers";
import { useDispatch, useSelector } from "react-redux";
import { storyFilter } from "../../_redux/story/storyActions";

const prepareFilter = (queryParams, values) => {
  const { brand, story_text, story_type, filterIsOpen } = values;
  const newQueryParams = { ...queryParams };
  newQueryParams.brand_label = brand ? brand.label : "";
  newQueryParams.brand = brand ? brand.value : "";
  newQueryParams.story_text = story_text ?? "";
  newQueryParams.story_type_label = story_type ? story_type.label : '';
  newQueryParams.story_type = story_type ? story_type.value : "";
  newQueryParams.filterIsOpen = filterIsOpen; 

  return newQueryParams;
};

export function StoryFilter({ listLoading, history }) {
  const { filters } = useSelector(state => state.story)
  const dispatch = useDispatch()
  // Storys UI Context
  const storysUIContext = useStoryUIContext();
  const storysUIProps = useMemo(() => {
    return {
      queryParams: storysUIContext.queryParams,
      setQueryParams: storysUIContext.setQueryParams,
    };
  }, [storysUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(storysUIProps.queryParams, values);
    if (!isEqual(newQueryParams, storysUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      storysUIProps.setQueryParams(newQueryParams);
      dispatch(storyFilter(newQueryParams))
    }
  };

  const [brand, setBrand] = useState([]);

  useEffect(() => {
    brandCrud
      .findBrands({
        page_number: 1,
        page_size: 1000,
        is_deleted: false,
        name: "",
        sortOrder: "DESC",
        sortField: "created_at",
      })
      .then((response) => {
        setBrand(response.items);
      });
  }, []);

  return (
    <>
      <Formik
        initialValues={filters}
        onSubmit={(values) => {
          applyFilter({...values, filterIsOpen: true});
        }}
        onReset={() => {
          applyFilter({...initialFilter, filterIsOpen: false})
          history.push("/story")
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
          resetForm,
        }) => (
          <>
            <Form onSubmit={handleSubmit} className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-4">
                  <Field
                    name="brand"
                    component={ReactSelect}
                    isClearable
                    placeholder="Pilih brand..."
                    value={
                      values.brand
                        ? {
                            label: values.brand_label,
                            value: values.brand.value,
                          }
                        : null
                    }
                    options={brand.map((item) => ({
                      value: item.name,
                      label: item.name,
                    }))}
                    onChange={(e) => {
                      setFieldValue("brand_label", e.label);
                      setFieldValue("brand", e);
                    }}
                  />
                  <small className="form-text ">
                    Cari <b>Brand</b>
                  </small>
                </div>
                <div className="col-lg-4">
                  <input
                    type="text"
                    className="form-control"
                    name="story_text"
                    placeholder="Search"
                    onBlur={handleBlur}
                    value={values.story_text}
                    onChange={(e) => {
                      setFieldValue("story_text", e.target.value);
                    }}
                  />
                  <small className="form-text ">
                    Cari <b>Story Text</b>
                  </small>
                </div>
                <div className="col-lg-4">
                  <Field
                    name="story_type"
                    component={ReactSelect}
                    isClearable
                    placeholder="Pilih Tipe Story..."
                    value={
                      values.story_type
                        ? {
                            label: values.story_type_label,
                            value: values.story_type.value,
                          }
                        : null
                    }
                    options={story_type.map((item) => ({
                      value: item.value,
                      label: item.name,
                    }))}
                    onChange={(e) => {
                      setFieldValue("story_type_label", e.label);
                      setFieldValue("story_type", e);
                    }}
                  />
                  <small className="form-text ">
                    Cari <b>Story Type</b>
                  </small>
                </div>
              </div>
              <button
                type="submit"
                onClick={handleSubmit}
                className="btn btn-primary btn-elevate float-right mb-20 ml-4"
              >
                Terapkan
              </button>
              <button
                type="button"
                onClick={() => {
                  resetForm();
                  handleSubmit();
                }}
                className="btn btn-danger btn-elevate float-right mb-20 ml-8"
              >
                Hapus Pencarian
              </button>
              <hr className="mt-25" />
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
