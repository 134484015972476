import React from "react";
import "./timeline.css";

const TimelineItem = ({ children }) => {
  return (
    <div className={"timeline-item"}>
      {children}
      <div className={"circle"}></div>
    </div>
  );
};

export default TimelineItem;
