import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '../SvgIcon'
import { defaultFill } from '../constant'

const Share = props => (
  <SvgIcon {...props}>
    <path fill={props.fill} className="custom-fill" d="M21,12L14,5V9C7,10 4,15 3,20C5.5,16.5 9,14.9 14,14.9V19L21,12Z" />
  </SvgIcon>
)

Share.propTypes = {
  fill: PropTypes.string,
}

Share.defaultProps = {
  fill: defaultFill,
}

export default Share
