export const ProjectStatusCssClasses = ["danger", "success", "info", ""];
export const ProjectStatusTitles = ["Suspended", "Active", "Pending", ""];
export const ProjectTypeCssClasses = ["success", "primary", ""];
export const ProjectTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "created_at", order: "desc" }];
export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
];
export const initialFilter = {
  name: "",
  sortField: "created_at",
  sortOrder: "DESC", // asc||desc
  page_number: 1,
  page_size: 10,
};

export const initProject = {
  name: "",
  start_date: "",
  end_date: "",
};

export const role = [
  {
    name: "Customer Care",
    value: "CustomerCare",
  },
];

export const badge = (status) => {
  let data = {};
  if (status) data = { text: "ACTIVE", type: "success" };
  else data = { text: "INACTIVE", type: "warning" };

  return data;
};
