// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { CircularProgress } from "@material-ui/core";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { ORDER_STATUS } from "../../OrderUIHelpers";
import { Link } from "react-router-dom";

export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  {
    openEditOrderDialog,
    openDeleteOrderDialog,
    setShowModal,
    id,
    printOrderReceipt,
    printLoading,
    printLabel,
  }
) {
  return (
    <div className="d-flex">
      <> </>
      <Link to={`/order/${row.uuid}/edit`}>
        <button title="Edit Order" className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")} />
          </span>
        </button>
      </Link>
    </div>
  );
}
