import axios from 'axios';
import { gql } from '@apollo/client';
import { QueryGraphql, MutationGraphql } from '../../../../config/ApoloRequest';
import { API } from '../../../../config/UrlEndPoint';

export const URL = `${API}master-data/paymentMethod/`;

export const FIND_PAYMENT_METHOD = gql`
  query Q($page_number: Int, $page_size: Int, $is_active: Boolean, $publish_status: String) {
    findPaymentMethod(
      findPaymentMethodInput: {
        is_active: $is_active
        publish_status: $publish_status
        page_number: $page_number
        page_size: $page_size
      }
    ) {
      items {
        uuid
        code
        type
        name
        description
        pg_code
        publish_status
      }
    }
  }
`;

export async function findPaymentMethods(queryParams) {
  let is_active = queryParams.is_active ? `is_active: ${queryParams.is_active.value}` : '';

  const stringQuery = `{
          code: "${queryParams.code}"
          ${is_active}
          pg_code: "${queryParams.pg_code}"
          type: "${queryParams.type}"
          price_start: ${+queryParams.price_start}
          price_end: ${+queryParams.price_end}
          price_percentage: ${+queryParams.price_percentage}
          publish_status: "${queryParams.publish_status}"
          page_number: ${+queryParams.page_number}
          page_size: ${+queryParams.page_size}
          sort_order: "${queryParams.sort_order}"
          sort_field: "${queryParams.sort_field}"
        }`;

  const query = gql`
    {
      findPaymentMethod(
        findPaymentMethodInput: ${stringQuery}
      ) {
        items {
          image {
            url
            uuid
          }
          code
          name
          is_active
          pg_code
          price
          price_percentage
          publish_status
          type
          uuid
        }
        total_items
        sort_order
        sort_field
      }
    }
  `;
  const newResponse = await QueryGraphql(query);
  return newResponse.data.findPaymentMethod;
}

export async function searchPaymentMethods(queryParams) {
  let filter = {};

  for (const key in queryParams.filter) {
    if (queryParams.filter[key]) {
      filter = Object.assign(filter, { [key]: queryParams.filter[key] });
    }
  }

  if (!queryParams.filter['page_number']) {
    filter.page_number = 1;
  }

  if (!queryParams.filter['page_size']) {
    filter.page_size = 10;
  }

  const query = gql`
    {
      findPaymentMethod(findPaymentMethodInput: ${JSON.stringify(filter).replace(
        /"([^"]+)":/g,
        '$1:'
      )}){
        items {
          uuid
          name
        }
      }
      
    }
  `;

  const newResponse = await QueryGraphql(query);
  return newResponse.data.findPaymentMethod;
}

export const CREATE_PAYMENT_METHOD = gql`
  mutation Mutation(
    $code: String!
    $is_active: Boolean
    $name: String!
    $pg_code: String!
    $price: Float!
    $price_percentage: Int!
    $type: String!
    $description: String!
    $publish_status: String!
    $image: Upload!
  ) {
    createPaymentMethod(
      createPaymentMethodInput: {
        code: $code
        name: $name
        pg_code: $pg_code
        price: $price
        price_percentage: $price_percentage
        type: $type
        is_active: $is_active
        description: $description
        publish_status: $publish_status
      }
      image: $image
    ) {
      name
      pg_code
    }
  }
`;

export const UPDATE_PAYMENT_METHOD = gql`
  mutation Mutation(
    $code: String
    $is_active: Boolean
    $name: String
    $pg_code: String
    $price: Float
    $price_percentage: Int
    $type: String
    $image: Upload
    $description: String
    $publish_status: String
    $uuid: String!
  ) {
    updatePaymentMethod(
      updatePaymentMethodInput: {
        code: $code
        name: $name
        pg_code: $pg_code
        price: $price
        price_percentage: $price_percentage
        type: $type
        is_active: $is_active
        description: $description
        publish_status: $publish_status
        uuid: $uuid
      }
      image: $image
    ) {
      name
      code
    }
  }
`;

export async function createPaymentMethod(payload) {
  console.log('CRUD', payload);
  const query = gql`
    mutation Mutation {
      createPaymentMethod(
        createPaymentMethodInput: {
          code: "${payload.code}"
          is_active: ${payload.is_active.value}
          name: "${payload.name}"
          pg_code: "${payload.pg_code.value}"
          price: ${payload.price}
          price_percentage: ${payload.price_percentage}
          type: "${payload.type}"
        }
      ) {
        code
        name
      }
    }
  `;
  const newResponse = await MutationGraphql(query);
  return newResponse.data.createPaymentMethod;
}

export async function updatePaymentMethod(payload) {
  const query = gql`
    mutation Mutation {
      updatePaymentMethod(
        updatePaymentMethodInput: {
          uuid: "${payload.uuid}"
          code: "${payload.code}"
          is_active: ${payload.is_active.value}
          name: "${payload.name}"
          pg_code: "${payload.pg_code.value}"
          price: ${payload.price}
          price_percentage: ${payload.price_percentage}
          type: "${payload.type}"
        }
      ) {
        name
        code
      }
    }
  `;

  const newResponse = await MutationGraphql(query);
  return newResponse.data.updatePaymentMethod;
}

export async function deletePaymentMethod(id) {
  const query = gql`
    mutation Mutation {
      deletePaymentMethod(uuid: "${id}") {
        code
        name
      }
    }
  `;

  const newResponse = await MutationGraphql(query);
  return newResponse.data.deletePaymentMethod;
}

export async function getPaymentMethodById(id) {
  const query = gql`
    {
      getPaymentMethod(uuid: "${id}") {
        uuid
        is_active
        name
        code
        pg_code
        price
        price_percentage
        publish_status
        type
        description
        image{
          uuid
          url
        }
      }
    }
  `;

  const newResponse = await QueryGraphql(query);
  return newResponse.data.getPaymentMethod;
}

// CREATE =>  POST: add a new customer to the server
// export function createPaymentMethod(paymentMethod) {
//     return axios.post(URL, paymentMethod);
// }

// GET ALL
export function getAllPaymentMethods() {
  return axios.get(URL);
}

// GET SINGLE USER
// export function getPaymentMethodById(id) {
//     return axios.get(`${URL}${id}`);
// }

// GET USER WITH CONDITION
// export function findPaymentMethods(queryParams) {
//     return axios.post(`${URL}find`, queryParams);
// }

// UPDATE SINGEL USER
// export function updatePaymentMethod(paymentMethod) {
//     return axios.put(`${URL}`, paymentMethod);
// }
