import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useLazyQuery } from '@apollo/client';
import { Card, CardHeader, CardBody } from '../../../../../../_metronic/_partials/controls';
import { GET_SHIPMENT_TRACKING } from '../../../../MasterData/_redux/shipper-service/shipperServiceCrud';
import TimelineContainer from '../timeline/TimelineContainer';
import TimelineItem from '../timeline/TimelineItem';

export const OrderLogsTimeline = ({ logs }) => {
  const [sortedLogs, setSortedLogs] = useState([]);

  useEffect(() => {
    if (logs instanceof Array && logs.length > 0) {
      const tempLogs = [...logs].sort(
        (a, b) => new Date(`${b.created_at}`).getTime() - new Date(`${a.created_at}`).getTime()
      );

      setSortedLogs(tempLogs);
    }
  }, [logs]);

  return (
    <Card>
      <CardHeader title="Riwayat Order" />
      <CardBody>
        <div className="row m-0 p-0">
          <div className="col-12 col-lg-6">
            <TimelineContainer>
              {sortedLogs.map(({ status, description, created_at }, index) => (
                <TimelineItem key={status + index}>
                  <div className="pb-5 display 5 d-flex flex-column">
                    <span className="font-weight-bold">
                      {moment(created_at).format('YYYY-MM-DD HH:mm:ss')}
                    </span>
                    <div className="d-flex align-items-center">
                      <span className="font-weight-bold mr-3">{status}:</span>
                      <span>{description}</span>
                    </div>
                  </div>
                </TimelineItem>
              ))}
            </TimelineContainer>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
