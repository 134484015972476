import React from "react";
import { Route } from "react-router-dom";
import { ProductLoadingDialog } from "./product-loading-dialog/ProductLoadingDialog";
import { ProductUIProvider } from "./ProductUIContext";
import { ProductCard } from "./ProductCard";

export function ProductPage({ history }) {
  const productUIEvents = {
    newProductButtonClick: () => {
      history.push("/product/new");
    },
    openEditProductPage: (id) => {
      history.push(`/product/${id}/edit`);
    },
    openFetchProductDialog: () => {
      history.push(`/product/fetch`);
    },
  };

  return (
    <ProductUIProvider productUIEvents={productUIEvents}>
      {/*<ProductLoadingDialog />*/}
      {/* <Route path="/product/new">
        {({ history, match }) => (
          <ProductEditDialog
            show={match != null}
            onHide={() => {
              history.push("/product");
            }}
          />
        )}
      </Route> */}
      {/* <Route path="/product/:id/edit">
        {({ history, match }) => (
          <ProductEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/product");
            }}
          />
        )}
      </Route> */}
      <ProductCard history={history} />
    </ProductUIProvider>
  );
}
