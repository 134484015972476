import React, { useState, useEffect } from 'react';
import { Field, Formik, useFormikContext } from 'formik';
import ReactSelect from 'react-select';
import AsikSelect from 'react-select/async';
import SVG from 'react-inlinesvg';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  Input,
} from '../../../../../_metronic/_partials/controls';
import BootstrapTable from 'react-bootstrap-table-next';
import { currencyFormatter, toAbsoluteUrl } from '../../../../../_metronic/_helpers';

export const OrderReturnGoodsTable = ({ title, items, removeItem }) => {
  const { values } = useFormikContext();

  const columns = [
    {
      text: 'Nama Produk',
      dataField: 'product_name',
    },
    {
      text: 'Sku',
      dataField: 'sku',
    },
    {
      text: 'Qty',
      dataField: 'qty',
      formatter: (cell) => <span>{Math.abs(+cell)}</span>,
    },
    {
      text: 'Harga',
      dataField: 'price',
      formatter: (cell) => <span>{currencyFormatter(+cell)}</span>,
    },
    {
      text: 'Catatan',
      dataField: 'notes',
    },
    {
      text: 'Actions',
      dataField: '',
      formatter: (_, row) => {
        return Boolean(values.uuid) ? null : (
          <a
            title="Delete customer"
            className={`btn btn-icon btn-light btn-hover-danger btn-sm`}
            onClick={() => {
              removeItem(row.product_sku_uuid, row.qty);
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <SVG src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')} />
            </span>
          </a>
        );
      },
    },
  ];

  return (
    <Card>
      <CardHeader title={title}></CardHeader>
      <CardBody>
        <BootstrapTable keyField="product_sku_uuid" columns={columns} data={items} />
      </CardBody>
    </Card>
  );
};
