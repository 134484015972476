export const AdminStatusCssClasses = ["danger", "success", "info", ""];
export const AdminStatusTitles = ["Suspended", "Active", "Pending", ""];
export const AdminTypeCssClasses = ["success", "primary", ""];
export const AdminTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "created_at", order: "desc" }];
export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
];
export const initialFilter = {
  name: "",
  email: "",
  role: "",
  branch: "",
  sortOrder: "asc", // asc||desc
  sortField: "created_at",
  page_number: 1,
  page_size: 10,
};

export let initAdmin = {
  name: "",
  role: "",
  email: "",
};

export const role = [
  {
    name: "Customer Care",
    value: "CustomerCare",
  },
];
export const branch = [
  {
    name: "Customer admin",
    value: "asdasd",
  },
  {
    name: "Customer user",
    value: "sdffsgfg",
  },
  {
    name: "Customer botol",
    value: "dfdfdfdf",
  },
  {
    name: "Customer meja",
    value: "assadsdfdsf",
  },
];

export const badge = (status) => {
  let data = {};
  if (status) data = { text: "ACTIVE", type: "success" };
  else data = { text: "INACTIVE", type: "warning" };

  return data;
};
