// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useState, useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/purchase/purchaseActions";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
  currencyFormatter,
} from "../../../../../_metronic/_helpers";
import * as uiHelpers from "../PurchaseUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../_metronic/_partials/controls";
import { usePurchaseUIContext } from "../PurchaseUIContext";
import PurchaseDeleteDialog from "../../purchase-delete-dialog/PurchaseDeleteDialog";
import { RoleChecker } from "../../../../RoleChecker";
import { LoadingAnimation } from "../../../../components/loading-animation/LoadingAnimation";

export const PurchaseTable = () => {
  const allowDelete = RoleChecker("delete", "purchase");
  const allowUpdate = RoleChecker("update", "purchase");
  // purchase UI Context
  const purchaseUIContext = usePurchaseUIContext();
  const purchaseUIProps = useMemo(() => {
    return {
      ids: purchaseUIContext.ids,
      setIds: purchaseUIContext.setIds,
      queryParams: purchaseUIContext.queryParams,
      setQueryParams: purchaseUIContext.setQueryParams,
      openEditPurchase: purchaseUIContext.openEditPurchase,
      // openDeletePurchaseDialog: purchaseUIContext.openDeletePurchaseDialog,
    };
  }, [purchaseUIContext]);

  const [rowId, setRowId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (rowId) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [rowId]);

  // Getting curret state of purchase list from store (Redux)
  const { totalCount, entities, listLoading } = useSelector(
    (state) => state.purchases,
    shallowEqual
  );

  // purchase Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // console.log("purchase filter", purchaseUIProps.queryParams);

    dispatch(actions.fetchPurchases(purchaseUIProps.queryParams));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseUIProps.queryParams, dispatch]);
  // Table columns
  const columns = [
    {
      dataField: "trx_date",
      text: "Trx Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field, order) => {
        purchaseUIProps.setQueryParams({
          ...purchaseUIProps.queryParams,
          sort_field: `purchase.${field}`,
          sort_order: order.toLocaleUpperCase(),
        });
      },
    },
    {
      dataField: "project.name",
      text: "Project",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field, order) => {
        purchaseUIProps.setQueryParams({
          ...purchaseUIProps.queryParams,
          sort_field: `${field}`,
          sort_order: order.toLocaleUpperCase(),
        });
      },
    },
    {
      dataField: "supplier.name",
      text: "Supplier",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field, order) => {
        purchaseUIProps.setQueryParams({
          ...purchaseUIProps.queryParams,
          sort_field: `${field}`,
          sort_order: order.toLocaleUpperCase(),
        });
      },
    },
    {
      dataField: "branch.name",
      text: "Branch",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field, order) => {
        purchaseUIProps.setQueryParams({
          ...purchaseUIProps.queryParams,
          sort_field: `${field}`,
          sort_order: order.toLocaleUpperCase(),
        });
      },
    },
    {
      dataField: "qty",
      text: "Qty",
      formatter: (cellContent, row) => {
        const { purchase_item } = row;

        const total_amount = purchase_item.reduce(
          (prev, { qty }) => prev + qty,
          0
        );

        return total_amount;
      },
    },
    {
      dataField: "total_amount",
      text: "Total Amount",
      classes: "text-right",
      headerClasses: "text-right",
      formatter: (cellContent, row) => {
        const { purchase_item } = row;

        const total_amount = purchase_item.reduce(
          (prev, { total_price }) => prev + total_price,
          0
        );

        return currencyFormatter(total_amount);
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      onSort: (field, order) => {
        purchaseUIProps.setQueryParams({
          ...purchaseUIProps.queryParams,
          sort_field: `purchase.${field}`,
          sort_order: order.toLocaleUpperCase(),
        });
      },
      formatter: columnFormatters.StatusColumnFormatter,
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      // formatter: (cellContent, row) => {
      //   const { purchase_item } = row;

      //   const total_amount = purchase_item.reduce((prev, { qty }) => prev + qty, 0);

      //   return total_amount;
      // },
      formatExtraData: {
        openEditPurchase: purchaseUIProps.openEditPurchase,
        openDeletePurchaseDialog: (id) => setRowId(id),
        allowDelete,
        allowUpdate,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: purchaseUIProps.queryParams.page_size,
    page: purchaseUIProps.queryParams.page_number,
  };
  return (
    <>
      <LoadingAnimation open={listLoading} />
      <PurchaseDeleteDialog
        id={rowId}
        show={showModal}
        onHide={() => setRowId(null)}
      />
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="uuid"
                data={entities || []}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  purchaseUIProps.setQueryParams
                )}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
};
