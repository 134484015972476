import axios from 'axios';
import { gql } from '@apollo/client';
import { QueryGraphql, MutationGraphql } from '../../../../config/ApoloRequest';
import { API } from '../../../../config/UrlEndPoint';

export const URL = `${API}master-data/pickup/`;

export async function findPickups(queryParams) {
  const query = gql`
    query Q(
      $sort_order: String
      $sort_field: String
      $page_number: Int
      $page_size: Int
      $status: String
      $branch_uuid: String
    ) {
      findRpxShipment(
        findRpxShipmentInput: {
          sort_field: $sort_field
          sort_order: $sort_order
          page_number: $page_number
          page_size: $page_size
          status: $status
          branch_uuid: $branch_uuid
        }
      ) {
        awb
        uuid
        order_uuid
        status
        tot_package
        tot_weight
        tot_declare_value
        service_snapshot
        order {
          invoice_no
          member {
            name
          }
        }
      }
    }
  `;
  const newResponse = await QueryGraphql(query, queryParams);
  return newResponse.data.findRpxShipment;
}

export const FIND_PICKUPS = gql`
  query Q(
    $sort_order: String
    $sort_field: String
    $page_number: Int
    $page_size: Int
    $status: String
    $branch_uuid: String
  ) {
    findRpxShipment(
      findRpxShipmentInput: {
        sort_field: $sort_field
        sort_order: $sort_order
        page_number: $page_number
        page_size: $page_size
        status: $status
        branch_uuid: $branch_uuid
      }
    ) {
      awb
      uuid
      order_uuid
      status
      tot_package
      tot_weight
      tot_declare_value
      service_snapshot
      order {
        invoice_no
        member {
          name
        }
      }
    }
  }
`;

export const SEND_PICKUP_REQUEST = gql`
  mutation Mutation($branch_uuid: String!, $shipping_uuids: [String!]!, $utc_offset: Int!) {
    sendPickupRequest(
      sendPickupRequestInput: {
        branch_uuid: $branch_uuid
        shipping_uuids: $shipping_uuids
        utc_offset: $utc_offset
      }
    ) {
      __typename
      message
      status
    }
  }
`;

export const FIND_RPX_SHIPMENT = gql`
  query Q(
    $sort_order: String
    $sort_field: String
    $page_number: Int
    $page_size: Int
    $status: String
    $branch_uuid: String
  ) {
    findRpxShipment(
      findRpxShipmentInput: {
        sort_field: $sort_field
        sort_order: $sort_order
        page_number: $page_number
        page_size: $page_size
        status: $status
        branch_uuid: $branch_uuid
      }
    ) {
      awb
      uuid
      order_uuid
      status
      tot_package
      tot_weight
      tot_declare_value
      service_snapshot
      order {
        invoice_no
        member {
          name
        }
      }
    }
  }
`;

export async function searchPickups(queryParams) {
  let filter = {};

  for (const key in queryParams.filter) {
    if (queryParams.filter[key]) {
      filter = Object.assign(filter, { [key]: queryParams.filter[key] });
    }
  }

  if (!queryParams.filter['page_number']) {
    filter.page_number = 1;
  }

  if (!queryParams.filter['page_size']) {
    filter.page_size = 10;
  }

  const query = gql`
    {
      findPickup(findPickupInput: ${JSON.stringify(filter).replace(/"([^"]+)":/g, '$1:')}){
        items {
          uuid
          name
        }
      }
      
    }
  `;

  const newResponse = await QueryGraphql(query);
  return newResponse.data.findPickup;
}

export async function createPickup(payload) {
  const query = gql`
    mutation {
        createPickup(createPickupInput: {
          name: "${payload.name}"
          email: "${payload.email}"
          hp: "${payload.hp}"
          password: "${payload.password}"
        })
        {
          uuid
          name
          email
          hp
          password
          created_at
          updated_at
          is_active
        }
      }`;
  const newResponse = await MutationGraphql(query);
  return newResponse.data.createPickup;
}

export async function updatePickup(payload) {
  const query = gql`
  mutation{
    updatePickup(updatePickupInput: {
      uuid: "${payload.uuid}"
      name: "${payload.name}"
      email: "${payload.email}"
      hp: "${payload.hp}"
      password: "${payload.password}"
    })
    {
      name
      email
      hp
    }
  }`;

  const newResponse = await MutationGraphql(query);
  return newResponse.data.updatePickup;
}

export async function deletePickup(payload) {
  const query = gql`
  mutation{
    deletePickup(uuid: "${payload}"){
      message
      item{
        name
        email
        hp
      }
    }
  }`;

  const newResponse = await MutationGraphql(query);
  return newResponse.data.deletePickup;
}

export async function getPickupById(payload) {
  const query = gql`
    {
      __typename
      getPickup(uuid: "${payload}") {
        uuid
        email
        hp
        name
      }
    }
  `;

  const newResponse = await QueryGraphql(query);
  return newResponse.data.getPickup;
}

// CREATE =>  POST: add a new customer to the server
// export function createPickup(pickup) {
//     return axios.post(URL, pickup);
// }

// GET ALL
export function getAllPickups() {
  return axios.get(URL);
}

// GET SINGLE USER
// export function getPickupById(id) {
//     return axios.get(`${URL}${id}`);
// }

// GET USER WITH CONDITION
// export function findPickups(queryParams) {
//     return axios.post(`${URL}find`, queryParams);
// }

// UPDATE SINGEL USER
// export function updatePickup(pickup) {
//     return axios.put(`${URL}`, pickup);
// }
