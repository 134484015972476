import React, { useMemo, useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { Badge, Collapse } from "react-bootstrap";
import { OrderReportFilter } from "./order-report-filter/OrderReportFilter";
import { NotificationTable } from "./order-report-table/OrderReportTable";
import { useNotificationUIContext } from "./OrderReportUIContext";
import { RoleChecker } from "../../../RoleChecker";
import { NotificationEditDialog } from "./order-report-edit/OrderReportEdit";
import { Table } from "react-bootstrap";
import { currencyFormatter } from "../../../../_metronic/_helpers";

export const OrderFeeTable = ({ orderFees }) => {
  return (
    <Card>
      <CardHeader title="Biaya"></CardHeader>
      <CardBody>
        <Table bordered responsive className="text-left">
          <thead className="thead-light">
            <tr variant="secondary">
              <th>Jenis</th>
              {/* <th>Jumlah</th> */}
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {orderFees.items instanceof Array &&
              orderFees.items.map(
                ({ total_order_payment, total_order_payment_amount, trans_code }) => (
                  <tr>
                    <td>{trans_code}</td>
                    {/* <td>{total_order_payment}</td> */}
                    <td className="text-right">{currencyFormatter(total_order_payment_amount ?? 0)}</td>
                  </tr>
                )
              )}
          </tbody>
          <tr>
            <th colSpan={1}>Total Biaya</th>
            <th className="text-right">{currencyFormatter(orderFees.total ?? 0)}</th>
          </tr>
        </Table>
      </CardBody>
    </Card>
  );
};
