import React, { useState, useEffect } from 'react';
import { Formik, Field } from 'formik';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import { Input } from '../../../../../../_metronic/_partials/controls';
import { CircularProgress } from '@material-ui/core';
import Select from 'react-select';

const CreateStoreSchema = Yup.object().shape({
  market_name: Yup.string().required('Nama Store wajib diisi.'),
  platform: Yup.string().required('Platform wajib diisi.'),
  is_active: Yup.boolean().required('Status aktif wajib diisi.'),
  is_online: Yup.boolean().required('Status online wajib diisi.'),
  store_fee_amount: Yup.number().required('store_fee_amount.'),
  store_fee_percent: Yup.number().required('store_fee_percent.'),
});

export const CreateStoreModal = ({ show, onHide, isLoading, submitCreateStore }) => {
  return (
    <Modal size="md" show={show} onHide={onHide} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Buat Store Baru</Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize={true}
        initialValues={{
          market_name: '',
          platform: '',
          is_active: true,
          is_online: true,
          store_fee_amount: 0,
          store_fee_percent: 0,
        }}
        validationSchema={CreateStoreSchema}
        onSubmit={(values) => {
          submitCreateStore(values);
        }}
      >
        {({ handleSubmit, values, setFieldValue, errors }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              <div className="form-group">
                <div className="col">
                  <Field name="market_name" component={Input} placeholder="Gayalo" label="Nama" />
                </div>
              </div>
              <div className="form-group">
                <div className="col">
                  <Field
                    name="platform"
                    component={Input}
                    placeholder="Tokopedia"
                    label="Platform"
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="col">
                  <Field
                    name="store_fee_amount"
                    component={Input}
                    placeholder="Store Fee Amount"
                    label="Store Fee Amount"
                    onChange={(e) => setFieldValue('store_fee_amount', +e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="col">
                  <Field
                    name="store_fee_percent"
                    component={Input}
                    placeholder="Store Fee Percent"
                    label="Store Fee Percent"
                    onChange={(e) => setFieldValue('store_fee_percent', +e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="col">
                  <label htmlFor="is_online">Online / Offline</label>
                  <Field
                    name="is_online"
                    id="is_online"
                    options={[
                      {
                        value: true,
                        label: 'Online',
                      },
                      {
                        value: false,
                        label: 'Offline',
                      },
                    ]}
                    component={Select}
                    placeholder="is_online"
                    label="is_online"
                    onChange={(e) => {
                      console.log('e', e.value);
                      setFieldValue('is_online', e.value);
                    }}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="col">
                  <label htmlFor="is_active">Aktif / Tidak Aktif</label>
                  <Field
                    name="is_active"
                    id="is_active"
                    options={[
                      {
                        value: true,
                        label: 'Aktif',
                      },
                      {
                        value: false,
                        label: 'Tidak Aktif',
                      },
                    ]}
                    component={Select}
                    placeholder="is_active"
                    label="Is_Active"
                    onChange={(e) => {
                      console.log('e', e.value);
                      setFieldValue('is_active', e.value);
                    }}
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button type="button" onClick={onHide} className="btn btn-light btn-elevate">
                Cancel
              </button>
              <button
                type="button"
                onClick={handleSubmit}
                className="btn btn-primary btn-elevate"
                style={{ width: '75px' }}
              >
                {isLoading ? <CircularProgress color="inherit" size={14} /> : <span>Save</span>}
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
};
