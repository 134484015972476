import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { Form as Forms, Badge } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import "../style.css";
import DropzoneUploader from "./DropzoneUploader";
import { UploadIcon } from "../../../../config/icons";
import { GridContextProvider, GridDropZone, GridItem, swap } from "react-grid-dnd";
import { setImageResolution } from "../../../../config/Helper";

export function ImageProduct({
  history,
  uploadImage,
  initImage,
  deleteImage,
  uploadDropzone,
  changeSort,
}) {
  // * Preview Product Photo
  const [previewImage, setPreviewImage] = useState([]);
  const [image, setImage] = useState([
    { product_image_uuid: "", image: "", sort_number: "" },
    { product_image_uuid: "", image: "", sort_number: "" },
    { product_image_uuid: "", image: "", sort_number: "" },
    { product_image_uuid: "", image: "", sort_number: "" },
    { product_image_uuid: "", image: "", sort_number: "" },
  ]);

  const [updateSort, setUpdateSort] = useState(false);

  useEffect(() => {
    // setPreviewImage(initImage);
    if (initImage) {
      let imageCopy = [...initImage];
      imageCopy.sort(function (a, b) {
        return a.sort_number - b.sort_number;
      });

      setImage(
        [...image].map((object, index) => {
          object.product_image_uuid = imageCopy[index] && imageCopy[index].uuid;
          object.image = imageCopy[index] && imageCopy[index].images.url;
          object.sort_number = imageCopy[index] && imageCopy[index].sort_number;
          return object;
        })
      );
    }
  }, [initImage]);

  const previewImageFunction = (value) => {
    if (value && value.length !== 0) {
      for (const item of value) {
        setPreviewImage((prevStat) => {
          if (!prevStat || prevStat.length === 0) {
            return [item];
          }
          return [...prevStat, item];
        });
      }
    }
  };

  const previewImageValue = (value) => {
    if (value.uuid) return value.images.url;
    if (value.length !== 0) {
      return URL.createObjectURL(value);
    }
  };

  const deleteImageFunction = (uuid) => {
    if (uuid) {
      deleteImage([uuid]);
    }

    let data = [...previewImage];
    const index = previewImage.findIndex((obj) => obj === uuid);
    data.splice(index, 1);
    setPreviewImage(data);
  };

  const saveImage = () => {
    let images = {};
    for (const [index, image] of previewImage.entries()) {
      images[`image${index + 1}`] = image;
    }
    uploadImage(images);
  };

  const onChangeDnD = (sourceId, sourceIndex, targetIndex, targetId) => {
    setUpdateSort(true);
    const nextState = swap(image, sourceIndex, targetIndex);
    setImage(nextState);
  };

  const updateSortFunc = () => {
    const finalValue = image.filter((item, index) => {
      if (item.product_image_uuid) {
        delete item.image;
        item.sort_number = index + 1;
        return item;
      }
    });
    changeSort(finalValue);
  };

  return (
    <>
      <Card>
        <CardHeader title="Foto Produk">
          {/* <CardHeaderToolbar>
            {updateSort && (
              <button
                className="btn btn-success"
                onClick={() => {
                  updateSortFunc();
                }}
              >
                Simpan Urutan
              </button>
            )}
          </CardHeaderToolbar> */}
        </CardHeader>
        <CardBody>
          <div className="form form-label-right">
            <div className="form-group row">
              <div className="col">
                <h4>Foto Produk</h4> <br /> Format gambar .jpg .jpeg .png dan ukuran minimum 300 x
                300px (Untuk gambar optimal gunakan ukuran minimum 700 x 700 px).
                <br /> Pilih foto produk maksimal 5 foto. Cantumkan min. 3 foto yang menarik agar
                produk semakin menarik perhatianpembeli.
              </div>
            </div>
            <div className="form-group row">
              <div className="col">
                <GridContextProvider onChange={onChangeDnD}>
                  <GridDropZone
                    id="items"
                    boxesPerRow={5}
                    rowHeight={100}
                    style={{ height: "200px" }}
                  >
                    {image.map((item, index) => {
                      return item.product_image_uuid ? (
                        <GridItem
                          key={item.product_image_uuid}
                          style={{
                            padding: "0 10px",
                          }}
                        >
                          <div className="col-lg col-md col-sm">
                            <div
                              style={{
                                backgroundImage: `url(${setImageResolution(item.image, "512")})`,
                                backgroundPosition: "center",
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                height: "105px",
                                // width: "175px",
                                cursor: "pointer",
                              }}
                            >
                              {index === 0 && (
                                <Badge variant="success" style={{ position: "absolute" }}>
                                  Foto Utama
                                </Badge>
                              )}
                              <div
                                className="background-image-overlay"
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "contain",
                                }}
                              >
                                <div class="overlay-image-text">Geser gambar untuk mengurutkan</div>
                                <div className="image-tool">
                                  <label
                                    title="Edit image"
                                    htmlFor={`image${index + 1}`}
                                    className="btn btn-icon btn-light btn-hover-primary btn-sm"
                                  >
                                    <span className="svg-icon svg-icon-md svg-icon-primary">
                                      <SVG
                                        src={toAbsoluteUrl(
                                          "/media/svg/icons/Communication/Write.svg"
                                        )}
                                      />
                                    </span>
                                    <Forms.Group>
                                      <Forms.File
                                        id={`image${index + 1}`}
                                        accept=".png, .jpg, .jpeg"
                                        custom
                                        style={{ width: "0px" }}
                                        onChange={(event) => {
                                          let obj = {};
                                          obj[`image${index + 1}`] = event.currentTarget.files[0];
                                          uploadImage(obj);
                                        }}
                                      />
                                    </Forms.Group>
                                  </label>
                                  <label
                                    title="Delete image"
                                    className="btn btn-icon btn-light btn-hover-danger btn-sm"
                                    onClick={() => deleteImageFunction(item.product_image_uuid)}
                                  >
                                    <span className="svg-icon svg-icon-md svg-icon-danger">
                                      <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                                      />
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </GridItem>
                      ) : (
                        <GridItem style={{ padding: "0 10px" }}>
                          <label
                            htmlFor={`image${index + 1}`}
                            className="image-container col-lg col-md col-sm"
                          >
                            <div className="inner-container">
                              <i className="fas fa-upload"></i>
                              <span>Foto Ke-{index + 1}</span>
                            </div>
                            <Forms.Group>
                              <Forms.File
                                id={`image${index + 1}`}
                                accept=".png, .jpg, .jpeg"
                                custom
                                style={{ width: "0px" }}
                                onChange={(event) => {
                                  // previewImageFunction(event.currentTarget.files);
                                  let obj = {};
                                  obj[`image${index + 1}`] = event.currentTarget.files[0];
                                  uploadImage(obj);
                                }}
                              />
                            </Forms.Group>
                          </label>
                        </GridItem>
                      );
                    })}
                  </GridDropZone>
                </GridContextProvider>
              </div>
              {!image[4].product_image_uuid && (
                <DropzoneUploader onDrop={(file) => uploadDropzone(file)}>
                  <div
                    className={`wrapper-flex is-column is-v-center is-h-center is-clickable upload-wrapper`}
                  >
                    <div>
                      <UploadIcon width="48px" height="48px" />
                    </div>
                    <div className="mt-2 has-text-14">
                      Klik atau Drag dan drop untuk upload foto
                    </div>
                  </div>
                </DropzoneUploader>
              )}
            </div>
          </div>
        </CardBody>
        <CardFooter>
          {updateSort && (
            <button
              className="btn btn-success btn-elevate float-right"
              onClick={() => {
                updateSortFunc();
              }}
            >
              Simpan Urutan
            </button>
          )}
        </CardFooter>
      </Card>
    </>
  );
}
