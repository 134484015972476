import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/marketplaceTransaction/marketplaceTransactionActions";
import { useMarketplaceTransactionUIContext } from "../MarketplaceTransactionUIContext";
// import { Input, Select } from "../../../../../../_metronic/_partials/controls";
import { role } from "../MarketplaceTransactionUIHelpers";

export function MarketplaceTransactionDisableDialog({ id, show, onHide }) {
  // MarketplaceTransaction Redux state
  const dispatch = useDispatch();
  // const { actionsLoading, marketplaceTransactionForEdit } = useSelector(
  //   (state) => ({
  //     actionsLoading: state.marketplaceTransaction.actionsLoading,
  //     marketplaceTransactionForEdit: state.marketplaceTransaction.marketplaceTransactionForEdit,
  //   }),
  //   shallowEqual
  // );

  const marketplaceTransactionUIContext = useMarketplaceTransactionUIContext();
  const marketplaceTransactionUIProps = useMemo(() => {
    return {
      ids: marketplaceTransactionUIContext.ids,
      setIds: marketplaceTransactionUIContext.setIds,
      queryParams: marketplaceTransactionUIContext.queryParams,
      setQueryParams: marketplaceTransactionUIContext.setQueryParams,
      openEditMarketplaceTransaction:
        marketplaceTransactionUIContext.openEditMarketplaceTransaction,
      // openDeleteMarketplaceTransactionDialog: marketplaceTransactionUIContext.openDeleteMarketplaceTransactionDialog,
    };
  }, [marketplaceTransactionUIContext]);

  const [title, setTitle] = useState("");
  // Title couting
  // useEffect(() => {
  //   setTitle(id);
  //   let _title = "";
  //   if (marketplaceTransactionForEdit && id) {
  //     _title = `Delete marketplaceTransaction '${marketplaceTransactionForEdit.name}'`;
  //   }

  //   setTitle(_title);
  //   // eslint-disable-next-line
  // }, [marketplaceTransactionForEdit, actionsLoading]);
  useEffect(() => {
    setTitle(id);
    let _title = "";

    setTitle(_title);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // server call for getting MarketplaceTransaction by id
    dispatch(
      actions.fetchMarketplaceTransactions(
        marketplaceTransactionUIProps.queryParams
      )
    );
  }, [id, dispatch]);

  // server request for saving marketplaceTransaction
  const updateMarketplaceTransaction = () => {
    let data = {
      uuid: id,
      is_active: false,
    };
    dispatch(actions.updateMarketplaceTransaction(data)).then(() => onHide());
  };

  const deleteMarketplaceTransaction = () => {
    dispatch(actions.deleteMarketplaceTransaction(id)).then(() => {
      onHide();
      dispatch(
        actions.fetchMarketplaceTransactions(
          marketplaceTransactionUIProps.queryParams
        )
      );
    });
  };

  return (
    <Modal
      size="md"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Anda yakin akan menghapus marketplaceTransaction ini?</span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteMarketplaceTransaction}
            className="btn btn-primary btn-elevate"
          >
            Yakin
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
