import axios from 'axios';
import { gql } from '@apollo/client';
import { QueryGraphql, MutationGraphql } from '../../../../config/ApoloRequest';
import { API } from '../../../../config/UrlEndPoint';

export const URL = `${API}master-data/store/`;

export async function findStores(queryParams) {
  const query = gql`
    query Query(
      $is_active: Boolean
      $market_name: String
      $page_number: Int
      $page_size: Int
      $platform: String
      $sort_field: String
      $sort_order: String
    ) {
      findStore(
        findStoreInput: {
          is_active: $is_active
          market_name: $market_name
          page_number: $page_number
          page_size: $page_size
          platform: $platform
          sort_field: $sort_field
          sort_order: $sort_order
        }
      ) {
        items {
          uuid
          is_active
          is_online
          market_name
          platform
          store_fee_amount
          store_fee_percent
        }
        total_items
        total_pages
      }
    }
  `;
  const newResponse = await QueryGraphql(query, queryParams);
  return newResponse && newResponse.data.findStore;
}

export const FIND_STORE = gql`
  query Query(
    $is_active: Boolean
    $is_online: Boolean
    $market_name: String
    $page_number: Int
    $page_size: Int
    $platform: String
    $sort_field: String
    $sort_order: String
  ) {
    findStore(
      findStoreInput: {
        is_active: $is_active
        is_online: $is_online
        market_name: $market_name
        page_number: $page_number
        page_size: $page_size
        platform: $platform
        sort_field: $sort_field
        sort_order: $sort_order
      }
    ) {
      items {
        uuid
        is_active
        is_online
        market_name
        platform
        store_fee_amount
        store_fee_percent
      }
      total_items
      total_pages
    }
  }
`;

export const CREATE_TOKO = gql`
  mutation Mutation($createStoreInput: CreateStoreInput!) {
    createStore(createStoreInput: $createStoreInput) {
      uuid
      is_active
      market_name
      platform
    }
  }
`;

export const UPDATE_TOKO = gql`
  mutation Mutation(
    $uuid: String!
    $is_active: Boolean
    $is_online: Boolean
    $market_name: String
    $platform: String
    $store_fee_amount: Float!
    $store_fee_percent: Float!
  ) {
    updateStore(
      updateStoreInput: {
        uuid: $uuid
        is_active: $is_active
        is_online: $is_online
        market_name: $market_name
        platform: $platform
        store_fee_amount: $store_fee_amount
        store_fee_percent: $store_fee_percent
      }
    ) {
      uuid
    }
  }
`;

export const DELETE_TOKO = gql`
  mutation Mutation($uuid: String!) {
    deleteStore(store_uuid: $uuid) {
      message
    }
  }
`;

export async function createStore(payload) {
  const query = gql`
    mutation {
        createStore(createStoreInput: {
          name: "${payload.name}"
          email: "${payload.email}"
          hp: "${payload.hp}"
          password: "${payload.password}"
        })
        {
          uuid
          name
          email
          hp
          password
          created_at
          updated_at
          is_active
        }
      }`;
  const newResponse = await MutationGraphql(query);
  return newResponse.data.createStore;
}

export async function updateStore(payload) {
  const query = gql`
  mutation{
    updateStore(updateStoreInput: {
      uuid: "${payload.uuid}"
      name: "${payload.name}"
      email: "${payload.email}"
      hp: "${payload.hp}"
      password: "${payload.password}"
    })
    {
      name
      email
      hp
    }
  }`;

  const newResponse = await MutationGraphql(query);
  return newResponse.data.updateStore;
}

export async function deleteStore(payload) {
  const query = gql`
  mutation{
    deleteStore(uuid: "${payload}"){
      message
      item{
        name
        email
        hp
      }
    }
  }`;

  const newResponse = await MutationGraphql(query);
  return newResponse.data.deleteStore;
}

export async function getStoreById(uuid) {
  const query = gql`
    {
      getStore(uuid: "${uuid}") {
        is_active
        is_online
        market_name
        platform
        uuid
        store_fee_amount
        store_fee_percent
      }
    }
  `;

  const newResponse = await QueryGraphql(query);
  return newResponse.data.getStore;
}

// CREATE =>  POST: add a new customer to the server
// export function createStore(store) {
//     return axios.post(URL, store);
// }

// GET ALL
export function getAllStores() {
  return axios.get(URL);
}

export const CREATE_STORE = gql`
  mutation m($createStoreInput: CreateStoreInput!) {
    createStore(createStoreInput: $createStoreInput) {
      uuid
      market_name
      platform
    }
  }
`;
