import axios from "axios";
import { API } from "../../../../config/UrlEndPoint";
import { gql, useMutation } from "@apollo/client";
import { MutationGraphql, QueryGraphql } from "../../../../config/ApoloRequest";

export const CUSTOMERS_URL = `${API}marketplaceTransaction/`;

// CREATE =>  POST: add a new marketplaceTransaction to the server
// export function createMarketplaceTransaction(marketplaceTransaction) {
//   return axios.post(CUSTOMERS_URL, { marketplaceTransaction });
// }

export async function findMarketplaceTransactions(queryParams) {
  const query = gql`
    query Query($page_number: Int, $page_size: Int, $sort_field: String, $sort_order: String) {
      __typename
      findMarketplaceTrxFileGroup(
        findMarketplaceTrxFileGroupInput: {
          page_size: $page_size
          page_number: $page_number
          sort_field: $sort_field
          sort_order: $sort_order
        }
      ) {
        items {
          file_uuid
          imported_at
          marketplace
          store_uuid
          synchronized_count
          trx_date_end
          trx_date_start
          unsynchronized_count
        }
        total_items
      }
    }
  `;

  const newResponse = await QueryGraphql(query, queryParams);
  return newResponse.data.findMarketplaceTrxFileGroup;
}

export const FIND_MARKETPLACE_TRX_IMPORT = gql`
  query Q(
    $file_uuid: String
    $page_number: Int
    $sort_field: String
    $sort_order: String
    $page_size: Int
  ) {
    __typename
    findMarketplaceTrx(
      findMarketplaceTrxImportInput: {
        page_number: $page_number
        page_size: $page_size
        sort_field: $sort_field
        sort_order: $sort_order
        file_uuid: $file_uuid
      }
    ) {
      items {
        description
        file_uuid
        invoice_no
        order_uuid
        store_uuid
        trans_code
        trx_date
        amount_imported
        amount_value
        uuid
      }
      total_items
    }
  }
`;

export async function getMarketplaceTransactionById(id) {
  const query = gql`
  {
  getMarketplaceTransaction(uuid: "${id}") {
    uuid
    brand_uuid
    media_type
    marketplaceTransaction_text
    marketplaceTransaction_type
    video_link
    related_products {
      name
      uuid
    }
    related_products_uuid
    images {
      url
      uuid
      filename
    }
    brand {
      name
      uuid
    }
    related_products {
      name
      uuid
    }
  }
}
`;

  const newResponse = await QueryGraphql(query);
  return newResponse.data.getMarketplaceTransaction;
}

export const CREATE_MARKETPLACE_IMPORT = gql`
  mutation M($store_uuid: String!, $file: Upload!) {
    createMarketplaceTrxImport(file: $file, store_uuid: $store_uuid) {
      message
    }
  }
`;

export const RESEND_NOTIF = gql`
  mutation Mutation($uuid: String!) {
    resendMarketplaceTransaction(marketplaceTransaction_uuid: $uuid) {
      title
      status
      type
    }
  }
`;

export async function deleteMarketplaceTransaction(id) {
  const query = gql`
    mutation Mutation {
      deleteMarketplaceTransaction(marketplaceTransaction_uuid: "${id}") {
        message
      }
    }
  `;

  const response = await MutationGraphql(query);
  return response.data.deleteMarketplaceTransaction;
}

export function getAllMarketplaceTransactions() {
  return axios.get(CUSTOMERS_URL);
}

// export function getMarketplaceTransactionById(marketplaceTransactionId) {
//   return axios.get(`${CUSTOMERS_URL}${marketplaceTransactionId}`);
// }

// export function findMarketplaceTransactions(queryParams) {
//   return axios.post(`${CUSTOMERS_URL}find`, queryParams );
// }

export function updateMarketplaceTransaction(marketplaceTransaction) {
  return axios.put(`${CUSTOMERS_URL}/`, marketplaceTransaction);
}

// DELETE => delete the marketplaceTransaction from the server
// export function deleteMarketplaceTransaction(marketplaceTransactionId) {
//   return axios.delete(`${CUSTOMERS_URL}/${marketplaceTransactionId}`);
// }

export const CREATE_MARKETPLACE_TRANSACTIONS = gql`
  mutation m(
    $file_uuid: String!
    $store_uuid: String!
    $marketplace_transactions: [CreateMarketplaceTrxImportByInvoiceInput!]!
    $order_updates: [UpdateOrderByInvoiceInput!]!
  ) {
    createMarketplaceTransactions(
      createMarketplaceTransactionsInput: {
        file_uuid: $file_uuid
        store_uuid: $store_uuid
        marketplace_transactions: $marketplace_transactions
        order_updates: $order_updates
      }
    ) {
      message
    }
  }
`;

export const FIND_MTRX_BY_INVOICE_CODE = gql`
  query ($invoice_codes: [InvoiceCode!]) {
    findMarketplaceTrx(findMarketplaceTrxImportInput: { invoice_codes: $invoice_codes }) {
      items {
        uuid
        invoice_no
        trans_code
        description
        amount_imported
        amount_value
      }
    }
  }
`;
