import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./CampaignUIHelpers";
import { shallowEqual, useSelector } from "react-redux";

const CampaignUIContext = createContext();

export function useCampaignUIContext() {
  return useContext(CampaignUIContext);
}

export const CampaignUIConsumer = CampaignUIContext.Consumer;

export function CampaignUIProvider({ campaignUIEvents, children }) {
  const { filters } = useSelector(state => state.campaign, shallowEqual)
  const [queryParams, setQueryParamsBase] = useState(filters);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    newCampaignButtonClick: campaignUIEvents.newCampaignButtonClick,
    openEditCampaignDialog: campaignUIEvents.openEditCampaignDialog,
    openDeleteCampaignDialog: campaignUIEvents.openDeleteCampaignDialog,
    openFetchCampaignDialog: campaignUIEvents.openFetchCampaignDialog,
    openDisableCampaignDialog: campaignUIEvents.openDisableCampaignDialog,
    openUpdateCampaignStatusDialog:
      campaignUIEvents.openUpdateCampaignStatusDialog,
  };

  return (
    <CampaignUIContext.Provider value={value}>
      {children}
    </CampaignUIContext.Provider>
  );
}
