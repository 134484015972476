import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  createHttpLink,
  concat,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { API } from "./UrlEndPoint";
import { setContext } from "@apollo/client/link/context";
import store from "../../redux/store";
import { createUploadLink } from "apollo-upload-client";
import { actionTypes } from "../modules/Auth/_redux/authRedux";
import { toastOption } from "../../_metronic/_helpers";
import { toast } from "react-toastify";

const clientRequest = () => {
  const {
    auth: { authToken },
  } = store.getState();

  const httpLink = createHttpLink({
    uri: API,
  });

  const logoutLink = onError(({ networkError }) => {
    console.log("network error", networkError);

    if (networkError.statusCode === 401) {
      store.dispatch({ type: actionTypes.Logout });
    }
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: authToken ? `Bearer ${authToken}` : "",
      },
    };
  });
  try {
    return new ApolloClient({
      link: concat(authLink, httpLink, logoutLink),
      cache: new InMemoryCache(),
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export async function QueryGraphql(payload, variables) {
  try {
    return await clientRequest().query({
      query: payload,
      variables,
    });
  } catch (error) {
    let str = error.message.toLowerCase();
    if (str.startsWith("token error")) {
      store.dispatch({ type: actionTypes.Logout });
      toast.error("Sesi anda telah habis!", toastOption);
    }
    return error.message;
  }
}

export async function MutationGraphql(payload, variables) {
  return await clientRequest().mutate({
    mutation: payload,
    variables,
  });
}
