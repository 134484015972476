import React from 'react';
import { useContext } from 'react';
import { Card, CardBody, CardHeader } from '../../../../../../_metronic/_partials/controls';
import { paymentMethodType } from '../../../../MasterData/pages/payment-method/PMUIHelpers';
import { currencyFormatter } from '../../order-table/column-formatters';
import { OrderFormContext } from '../FormContext';
import { OrderPaymentVerification } from './OrderPaymentVerification';

export const OrderPaymentLog = ({ logs }) => {
  const { order } = useContext(OrderFormContext);
  const { order_payment = {} } = order;

  return (
    <Card>
      <CardHeader title="Pembayaran"></CardHeader>
      <CardBody>
        <div className="m-0 mb-5 p-0 d-flex flex-column">
          <div className="row m-0 p-0">
            <div className="col-4 m-0 p-0 col-md-3">Metode Pembayaran</div>
            <div className="col-8 col-md-9">
              <span>:</span>
              <span className="ml-4">
                {order_payment.payment_method && order_payment.payment_method.name}
              </span>
            </div>
            <div className="col-4 m-0 p-0 col-md-3">Jumlah</div>
            <div className="col-8 col-md-9">
              <span>:</span>
              <span className="ml-4">
                {order_payment && order_payment.pg_type === paymentMethodType.WIRED_TRANSFER
                  ? currencyFormatter(+order_payment.amount + +order_payment.pg_trx_id)
                  : currencyFormatter(order_payment.amount)}
              </span>
            </div>
          </div>
        </div>
        <OrderPaymentVerification logs={logs} />
      </CardBody>
    </Card>
  );
};
