import React, { useMemo, useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import { isEqual } from "lodash";
import { useMarketplaceOrderUIContext } from "../MarketplaceOrderUIContext";
import { initialFilter, STATUS_OPTIONS } from "../MarketplaceOrderUIHelpers";
import { marketplaceOrder_type } from "../MarketplaceOrderUIHelpers";
import { Input } from "../../../../../_metronic/_partials/controls";
import { SEARCH_MEMBER } from "../../../Member/_redux/member/memberCrud";
import { useLazyQuery, useQuery } from "@apollo/client";
import AsyncSelect from "react-select/async";
import { SEARCH_BRANCHES } from "../../../MasterData/_redux/branch/branchCrud";
import { IS_ONLINE } from "../../../Order/pages/OrderUIHelpers";
import Select from "react-select";
import { GET_ORDER_STATUS } from "../../../Order/_redux/order/orderCrud";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { orderMarketplaceFilter } from "../../_redux/marketplaceOrder/marketplaceOrderActions";

const prepareFilter = (queryParams, values) => {
  const { branch_uuid, branch_label, member_uuid, invoice_no, filterIsOpen, member_label } = values;
  
  const newQueryParams = { ...queryParams };
  newQueryParams.branch_uuid = branch_uuid ?? "";
  newQueryParams.branch_label = branch_label ?? "";
  newQueryParams.member_label = member_label ?? "";
  newQueryParams.member_uuid = member_uuid ?? "";
  newQueryParams.invoice_no = invoice_no ?? "";
  newQueryParams.filterIsOpen = filterIsOpen ?? "";

  return newQueryParams;
};

export function MarketplaceOrderFilter({ listLoading, history }) {
  // const { loading, data } = useQuery(GET_ORDER_STATUS);
  const { filters } = useSelector((state) => state.marketplaceOrder, shallowEqual);
  const dispatch = useDispatch();

  const [branchName, setBranchName] = useState("");
  const [memberName, setMemberName] = useState("");

  const [searchBranches] = useLazyQuery(SEARCH_BRANCHES, {
    variables: {
      name: "",
    },
    onCompleted: ({ findBranches: { items } }) => {
      currentCallback(
        items.map(({ uuid, name }) => ({ label: name, value: uuid }))
      );
    },
  });

  const [searchMembers] = useLazyQuery(SEARCH_MEMBER, {
    variables: {
      name: "",
    },
    onCompleted: ({ findMember: { items } }) => {
      currentCallback(
        items.map(({ uuid, name }) => ({ label: name, value: uuid }))
      );
    },
  });

  function loadOptions(queryCaller, inputValue, callback) {
    clearTimeout(currentTimeout);

    if (inputValue.length >= 3) {
      setCurrentTimeout(
        setTimeout(() => {
          queryCaller({ variables: { name: inputValue } });
          setCurrentCallback(() => callback);
        }, 250)
      );
    } else {
      callback([]);
    }
  }

  // MarketplaceOrders UI Context
  const marketplaceOrdersUIContext = useMarketplaceOrderUIContext();
  const marketplaceOrdersUIProps = useMemo(() => {
    return {
      queryParams: marketplaceOrdersUIContext.queryParams,
      setQueryParams: marketplaceOrdersUIContext.setQueryParams,
    };
  }, [marketplaceOrdersUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(
      marketplaceOrdersUIProps.queryParams,
      values
    );
    if (!isEqual(newQueryParams, marketplaceOrdersUIProps.queryParams)) {
      newQueryParams.page_number = 1;
      // update list by queryParams
      marketplaceOrdersUIProps.setQueryParams(newQueryParams);
      dispatch(orderMarketplaceFilter(newQueryParams))
    }
  };

  const [currentTimeout, setCurrentTimeout] = useState(null);
  const [currentCallback, setCurrentCallback] = useState(() => () => null);

  // * MEMBER
  const [searchMember] = useLazyQuery(SEARCH_MEMBER, {
    variables: {
      name: "",
    },
    onCompleted: ({ findMember: { items } }) => {
      currentCallback(
        items.map((member) => ({
          label: member.name,
          value: member.uuid,
          ...member,
        }))
      );
    },
    fetchPolicy: "cache-and-network",
  });

  function loadMemberOptions(inputValue, callback) {
    clearTimeout(currentTimeout);
    if (inputValue.length >= 1) {
      setCurrentTimeout(
        setTimeout(() => {
          searchMember({ variables: { name: inputValue } });
          setCurrentCallback(() => callback);
        }, 250)
      );
    } else {
      callback([]);
    }
  }

  return (
    <>
      <Formik
        initialValues={filters}
        onSubmit={(values) => {
          // setTanggal({ start_date: null, end_date: null });
          applyFilter({...values, filterIsOpen: true});
        }}
        onReset={() => {
          history.push("/marketplace-order")
          applyFilter({...initialFilter, filterIsOpen: false})
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
          resetForm,
        }) => (
          <>
            <Form>
              <div className="form-group row">
                <div className="col-lg-4">
                  <AsyncSelect
                    cacheOptions={true}
                    name="branch_uuid"
                    placeholder="Branch"
                    noOptionsMessage={({ inputValue }) =>
                      inputValue.length >= 3
                        ? "No options"
                        : "Type 3 or more characters"
                    }
                    loadOptions={(inputValue, callback) =>
                      loadOptions(searchBranches, inputValue, callback)
                    }
                    defaultOptions={false}
                    value={
                      values.branch_label && values.branch_uuid
                        ? {
                            label: values.branch_label,
                            value: values.branch_uuid,
                          }
                        : null
                    }
                    onChange={({ label, value }) => {
                      // setBranchName(label);
                      setFieldValue("branch_label", label);
                      setFieldValue("branch_uuid", value);
                    }}
                  />
                  <small className="form-text ">
                    Cari <b>ID Branch</b>
                  </small>
                </div>
                <div className="col-lg-4">
                  <AsyncSelect
                    cacheOptions={true}
                    name="member_uuid"
                    placeholder="Member"
                    noOptionsMessage={({ inputValue }) =>
                      inputValue.length >= 3
                        ? "No options"
                        : "Type 3 or more characters"
                    }
                    loadOptions={(inputValue, callback) =>
                      loadOptions(searchMembers, inputValue, callback)
                    }
                    defaultOptions={false}
                    value={
                      values.member_label && values.member_uuid
                        ? {
                            label: values.member_label,
                            value: values.member_uuid,
                          }
                        : null
                    }
                    onChange={({ label, value }) => {
                      // setMemberName(label);
                      setFieldValue("member_label", label);
                      setFieldValue("member_uuid", value);
                    }}
                  />
                  <small className="form-text ">
                    Cari <b>ID Member</b>
                  </small>
                </div>
                <div className="col-lg-4">
                  <Field
                    name="invoice_no"
                    component={Input}
                    value={values.invoice_no}
                    placeholder=" Cari No Invoice.."
                    onChange={(e) =>
                      setFieldValue("invoice_no", e.target.value)
                    }
                  />
                  <small className="form-text ">
                    Cari <b>Invoice</b>
                  </small>
                </div>
              </div>
              <button
                type="submit"
                onClick={handleSubmit}
                className="btn btn-primary btn-elevate float-right mb-20 ml-4"
              >
                Terapkan
              </button>
              <button
                type="button"
                onClick={() => {
                  // setTanggal({ start_date: null, end_date: null });
                  resetForm();
                  handleSubmit();
                }}
                className="btn btn-danger btn-elevate ml-3 float-right mb-20"
              >
                Hapus Pencarian
              </button>
              <hr className="mt-25" />
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
