import React, { useMemo, useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { Badge, Collapse } from "react-bootstrap";
import { OrderReportFilter } from "./order-report-filter/OrderReportFilter";
import { NotificationTable } from "./order-report-table/OrderReportTable";
import { useNotificationUIContext } from "./OrderReportUIContext";
import { RoleChecker } from "../../../RoleChecker";
import { NotificationEditDialog } from "./order-report-edit/OrderReportEdit";
import { Table } from "react-bootstrap";
import { currencyFormatter } from "../../../../_metronic/_helpers";

export const OrderSummary = ({ totalIncome, totalFee, totalNett }) => {
  return (
    <Card>
      <CardHeader title="Total"></CardHeader>
      <CardBody>
        <Table bordered responsive className="text-left">
          <tbody>
            <tr>
              <th>Total Pemasukan</th>
              <th className="text-right">{currencyFormatter(totalIncome)}</th>
            </tr>
            <tr>
              <th>Total Biaya</th>
              <th className="text-right">({currencyFormatter(totalFee)})</th>
            </tr>
          </tbody>
          <tr>
            <th>Total Pemasukan Bersih</th>
            <th className="text-right">{currencyFormatter(totalNett)}</th>
          </tr>
        </Table>
      </CardBody>
    </Card>
  );
};
