// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState, useEffect } from "react";
import {
  Modal,
  Form as Forms,
  Carousel,
  OverlayTrigger,
  Tooltip,
  Table,
} from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Input } from "../../../../../_metronic/_partials/controls";
import ReactSelect from "react-select";
import { IS_ACTIVE, TYPE } from "../CampaignUIHelpers";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import AsyncSelect from "react-select/async";
import { gql, useLazyQuery } from "@apollo/client";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { toast } from "react-toastify";
import { toastOption } from "../../../../../_metronic/_helpers";

const SEARCH_PRODUCTS = gql`
  query Query($name: String) {
    findProduct(
      findProductInput: { name: $name, page_number: 1, page_size: 100 }
    ) {
      items {
        uuid
        name
        description
        is_deleted
        weight
        brand {
          name
        }
        product_sku {
          price
          price_before
          sku
          uuid
          variant_value_1
          variant_value_2
          image {
            url
          }
        }
      }
    }
  }
`;

const shape = {
  title: Yup.string().required("title wajib diisi"),
  name: Yup.string().required("name wajib diisi"),
  content: Yup.string().required("content wajib diisi"),
  // is_active: Yup.boolean().required("is_active wajib diisi"),
};
const CampaignEditSchema = Yup.object().shape(shape);

export const CampaignEditForm = ({
  saveCampaign,
  campaign,
  actionsLoading,
  onHide,
  id,
}) => {
  const [currentTimeout, setCurrentTimeout] = useState(null);
  const [currentCallback, setCurrentCallback] = useState(null);

  const [productArray, setProductArray] = useState([]);

  const [imagePreviews, setImagePreviews] = useState([]);
  const [isActiveValue, setIsActiveValue] = useState({});
  const [deletedImage, setDeletedImage] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setImageIndex(selectedIndex);
  };

  useEffect(() => {
    if (campaign.uuid) {
      setIsActiveValue({
        label: `${campaign.is_active}`,
        value: campaign.is_active,
      });
      setProductArray(() =>
        campaign.products.map((item) => {
          return {
            name: item.product.name,
            uuid: item.product.uuid,
          };
        })
      );
    }

    if (campaign.images) {
      setImagePreviews(campaign.images);
    }
  }, [campaign]);

  function previewImage(file) {
    return URL.createObjectURL(file);
  }

  const deleteImageFunction = (value) => {
    // console.log("value", value);
    let imageCopy = [...imagePreviews];
    // let images = [...deletedImage];

    const index = imagePreviews.findIndex((obj) => obj === value);

    imageCopy.splice(index, 1);

    if (value.uuid) {
      setDeletedImage([...deletedImage, value.filename]);
    }
    setImageIndex(index == 0 ? 0 : +index - 1);
    setImagePreviews(imageCopy);
  };

  // * Fetch Search Product
  const [fetchProductOptions] = useLazyQuery(SEARCH_PRODUCTS, {
    variables: { name: "" },
    onCompleted: (data) => {
      if (!data) return;

      const {
        findProduct: { items },
      } = data;

      if (items.length === 0) return currentCallback([]);

      const skus = items.map((item) => {
        return {
          label: item.name,
          value: item.uuid,
        };
      });

      // setCurrentProducts(skus);
      currentCallback(skus);
    },
    fetchPolicy: "cache-and-network",
  });

  function loadProductOptions(inputValue, callback) {
    clearTimeout(currentTimeout);

    if (inputValue.length >= 3) {
      setCurrentTimeout(
        setTimeout(() => {
          fetchProductOptions({
            variables: { name: inputValue },
          });
          setCurrentCallback(() => callback);
        }, 250)
      );
    } else {
      callback([]);
    }
  }

  const deleteProduct = (value) => {
    // console.log(value);
    let data = [...productArray];
    const index = productArray.findIndex((obj) => obj === value);
    data.splice(index, 1);
    setProductArray(data);
  };

  const sendCampaign = (v) => {
    let value = { ...v };

    // let sendProductArr = []
    const sendProductArr = productArray.map((item, index) => {
      return {
        product_uuid: item.uuid,
        sort_no: index + 1,
      };
    });
    if (sendProductArr.length > 0) {
      value.products = sendProductArr;
    } else {
      value.products = [];
    }
    value.deletedImage = deletedImage ?? [];


    let tempImage = value.images.filter((item) => !item.filename || !value.deletedImage.includes(item.filename))
    
    if (tempImage.length <= 0) {
      toast.warning("Pilih foto terlebihdahulu!", toastOption);
      return;
    }
    
    saveCampaign(value);
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={campaign}
        validationSchema={CampaignEditSchema}
        onSubmit={(values) => {
          sendCampaign(values);
        }}
      >
        {({ handleSubmit, setFieldValue, errors, values }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col">
                    <Field
                      name="title"
                      component={Input}
                      placeholder="Title..."
                      label="Title"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col">
                    <Field
                      name="name"
                      component={Input}
                      placeholder="Name"
                      label="Name"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col">
                    <label>Tipe</label>
                    <ReactSelect
                      name="type"
                      placeholder="Pilih Tipe..."
                      options={TYPE.map((item) => ({
                        label: item.name,
                        value: item.value,
                      }))}
                      value={
                        values.type
                          ? {
                              label: values.type,
                              value: values.type,
                            }
                          : null
                      }
                      onChange={(e) => {
                        setFieldValue("type", e.value);
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col">
                    <label htmlFor="product">Related Product</label>
                    <AsyncSelect
                      cacheOptions={false}
                      name="product_uuid"
                      placeholder="Product Name"
                      noOptionsMessage={({ inputValue }) => {
                        if (inputValue.length >= 3) return "No options";
                        else return "Type 3 or more characters";
                      }}
                      loadOptions={loadProductOptions}
                      defaultOptions={false}
                      onChange={({ label, value }) => {
                        setProductArray([
                          ...productArray,
                          { uuid: value, name: label },
                        ]);
                      }}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          border: errors.product_uuid
                            ? "1px solid rgb(246, 78, 96)"
                            : "1px solid #ddd",
                        }),
                        dropdownIndicator: (provided, state) => ({
                          ...provided,
                          color: errors.product_uuid
                            ? "rgb(246, 78, 96)"
                            : "#ddd",
                        }),
                      }}
                    />
                    <ErrorMessage
                      name="product_uuid"
                      render={(msg) => (
                        <div style={{ color: "rgb(246, 78, 96)" }}>{msg}</div>
                      )}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col">
                    <ol>
                      {productArray.map((item, index) => (
                        <>
                          <li key={item.name + index} className="mt-2">
                            {item.name}
                            <a
                              title="Delete customer"
                              className="btn btn-icon btn-light btn-hover-danger btn-sm ml-4"
                              onClick={() => deleteProduct(item)}
                            >
                              <span className="svg-icon svg-icon-md svg-icon-danger">
                                <SVG
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/General/Trash.svg"
                                  )}
                                />
                              </span>
                            </a>
                          </li>
                        </>
                      ))}
                    </ol>
                    {/* <Table>
                      <thead>
                        <tr>
                          <th>Sort Number</th>
                          <th>Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {productArray.map((item, index) => (
                          <>
                            <tr>
                              <td className="text-right">{index + 1}</td>
                              <td>{item.name}</td>
                              <td>
                                <a
                                  title="Delete customer"
                                  className="btn btn-icon btn-light btn-hover-danger btn-sm"
                                  onClick={() => deleteProduct(item)}
                                >
                                  <span className="svg-icon svg-icon-md svg-icon-danger">
                                    <SVG
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/General/Trash.svg"
                                      )}
                                    />
                                  </span>
                                </a>
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </Table> */}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col">
                    <label htmlFor="is_active">is_active</label>
                    <Field
                      name="is_active"
                      id="is_active"
                      value={isActiveValue || (campaign && campaign.is_active)}
                      options={IS_ACTIVE.map((item) => ({
                        value: item.value,
                        label: item.name,
                      }))}
                      component={ReactSelect}
                      placeholder="is_active"
                      label="Is_Active"
                      onChange={(e) => {
                        setFieldValue("is_active", e);
                        setIsActiveValue(e);
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col">
                    <CKEditor
                      name="content"
                      editor={ClassicEditor}
                      data={campaign.uuid ? campaign.content : ""}
                      config={{
                        removePlugins: ["EasyImage", "ImageUpload"],
                      }}
                      // onReady={(editor) => {
                      //   // You can store the "editor" and use when it is needed.
                      //   console.log("Editor is ready to use!", editor);
                      // }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setFieldValue("content", data);
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col">
                    <label htmlFor="image">
                      <i
                        className="fa fa-plus btn btn-primary mr-2"
                        style={{ color: "#fff", marginRight: "5px" }}
                      >
                        Tambah Foto
                      </i>
                    </label>
                    <Forms.File
                      name="image"
                      id="image"
                      style={{ width: "0px" }}
                      accept=".png, .jpg, .jpeg"
                      onChange={(e) => {
                        const tempId = `${Math.round(
                          Math.random() * 10000
                        )}-${Date.now()}`;
                        const images = [...imagePreviews];
                        [...e.target.files].forEach((file) => {
                          file.tempId = tempId;
                          images.push(file);
                        });

                        setFieldValue("images", images);
                        setImagePreviews(images);
                      }}
                      multiple
                    />
                  </div>
                </div>
                <div
                  className="form-group"
                  // style={{ width: "250px", height: "200px" }}
                >
                  <div className="col">
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-top">Click To Delete!</Tooltip>
                      }
                    >
                      <Carousel
                        variant="dark"
                        activeIndex={imageIndex}
                        onSelect={handleSelect}
                      >
                        {imagePreviews instanceof Array &&
                          imagePreviews.map((image, index) => (
                            <Carousel.Item
                              interval={3000}
                              style={{
                                // width: "50%",
                                // height: "50%",
                                objectFit: "cover",
                                cursor: "pointer",
                              }}
                              onClick={() => deleteImageFunction(image)}
                            >
                              <img
                                className="d-block w-100 img-fuild img-thumbnail"
                                src={
                                  image instanceof File
                                    ? previewImage(image)
                                    : image.url
                                }
                                alt={`image ${index}`}
                              />
                            </Carousel.Item>
                          ))}
                      </Carousel>
                    </OverlayTrigger>
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
};
