import React, { useMemo, useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { Badge, Collapse } from "react-bootstrap";
import { SynchronizeMarketplaceFilter } from "./synchronizeMarketplace-filter/SynchronizeMarketplaceFilter";
import { SynchronizeMarketplaceTable } from "./synchronizeMarketplace-table/SynchronizeMarketplaceTable";
import { useSynchronizeMarketplaceUIContext } from "./SynchronizeMarketplaceUIContext";
import { RoleChecker } from "../../../RoleChecker";
import { SynchronizeMarketplaceEditDialog } from "./synchronizeMarketplace-edit/SynchronizeMarketplaceEdit";
import { useSelector } from "react-redux";

export function SynchronizeMarketplaceCard({ history }) {
  const synchronizeMarketplaceUIContext = useSynchronizeMarketplaceUIContext();
  const synchronizeMarketplaceUIProps = useMemo(() => {
    return {
      queryParams: synchronizeMarketplaceUIContext.queryParams,
      newSynchronizeMarketplaceButtonClick:
        synchronizeMarketplaceUIContext.newSynchronizeMarketplaceButtonClick,
    };
  }, [synchronizeMarketplaceUIContext]);

  const [statusFilter, setStatusFilter] = useState(false);
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const changeShow = () => {
    setOpen(!open);

    // Set Status Filter To False
    setStatusFilter(false);
    const filter = synchronizeMarketplaceUIProps.queryParams;
    for (const [key, value] of Object.entries(filter)) {
      if (value !== "" && key) {
        setStatusFilter(true);
      }
    }
  };

  useEffect(() => {
    if (synchronizeMarketplaceUIProps.filterType !== undefined) {
      setStatusFilter(true);
      // setIsDisabled(true);
    }
    // eslint-disable-next-line
  }, [synchronizeMarketplaceUIProps.filterType]);

  const { filters } = useSelector(state => state.synchronizeMarketplace)
  useEffect(() => {
    if (filters.filterIsOpen) {
      setOpen(true)
    }
  }, [])

  return (
    <>
      <Card>
        <CardHeader title="Synchronize Marketplace">
          <CardHeaderToolbar>
            {statusFilter && (
              <Badge className="mr-5" pill variant="danger">
                Filter Aktif
              </Badge>
            )}
            {RoleChecker("read", "order") && (
              <button
                type="button"
                className="btn btn-warning mr-2"
                onClick={changeShow}
                aria-controls="collapse-filter-order"
                aria-expanded={open}
              >
                Cari
              </button>
            )}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Collapse in={open}>
            <div id="collapse-filter-synchronizeMarketplace">
              <SynchronizeMarketplaceFilter history={history} />
            </div>
          </Collapse>

          <SynchronizeMarketplaceTable />
        </CardBody>
      </Card>
    </>
  );
}
