import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import BootstrapTable from 'react-bootstrap-table-next';
import * as Yup from 'yup';
import ReactSelect from 'react-select';
import * as uiHelpers from '../MemberUIHelpers';
import { Input } from '../../../../../_metronic/_partials/controls';

const MemberSchema = Yup.object().shape({
  uuid: Yup.string().optional(),
  name: Yup.string().min(3).required('Nama minimal berisi 3 karakter'),
  email: Yup.string().test('email', 'Email/Hp wajib diisi.', function (value) {
    const { hp } = this.parent;

    if (!hp) {
      return Boolean(value);
    }

    return true;
  }),
  hp: Yup.string().test('hp', 'Email/Hp wajib diisi.', function (value) {
    const { email } = this.parent;

    if (!email) {
      return Boolean(value);
    }

    return true;
  }),
  member_level_code: Yup.string().optional(),
  dropship_name: Yup.string().optional(),
  dropship_hp: Yup.string().optional(),
  password: Yup.string().optional(),
});

export const MemberEditProfile = ({ member, btnRef, saveProfile }) => {
  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={uiHelpers.getMemberInitialValues(member)}
        validationSchema={MemberSchema}
        onSubmit={(formValues) => {
          const { update_password, ...values } = formValues;

          if (!update_password) {
            values.password = null;
          }

          saveProfile(values);
        }}
      >
        {({ handleSubmit, values, isSubmitting, setFieldValue }) => (
          <form className="form form-label-right" onSubmit={handleSubmit}>
            <div className="form-group row">
              <div className="col">
                <Field name="name" component={Input} placeholder="member" label="Nama" />
              </div>
              <div className="col">
                <Field
                  name="email"
                  component={Input}
                  placeholder="member@member.co.id"
                  label="Email"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col">
                <Field
                  type="text"
                  name="hp"
                  component={Input}
                  placeholder="+628231237448383"
                  label="Phone"
                />
              </div>
              <div className="col">
                <label htmlFor="password">Password</label>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <input
                        name="update_password"
                        type="checkbox"
                        aria-label="Checkbox for following text input"
                        checked={values.update_password}
                        onChange={(e) => setFieldValue('update_password', e.target.checked)}
                      />
                    </div>
                  </div>
                  <input
                    name="password"
                    type="password"
                    className="form-control"
                    aria-label="Text input with checkbox"
                    value={values.password}
                    onChange={(e) => setFieldValue('password', e.target.value)}
                    disabled={!values.update_password}
                  />
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="col">
                <label htmlFor="dropship_name">Nama Dropship</label>
                <Field name="dropship_name" component={Input} placeholder="Nama Dropship" />
              </div>
              <div className="col">
                <label htmlFor="dropship_hp">Hp Dropship</label>
                <Field name="dropship_hp" component={Input} placeholder="No Hp Dropship" />
              </div>
            </div>
            <button
              type="submit"
              style={{ display: 'none' }}
              ref={btnRef}
              disabled={isSubmitting}
            ></button>
          </form>
        )}
      </Formik>
    </div>
  );
};
