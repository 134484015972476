import React, { useMemo } from "react";
import { Card, CardBody, CardHeader } from "../../../../_metronic/_partials/controls";
import { PickupTable } from "./pickup-table/PickupTable";
import { usePickupUIContext } from "./PickupUIContext";
import { SEND_PICKUP_REQUEST } from "../redux/pickup-request/pickupCrud";
import { useMutation } from "@apollo/client";
import { toastOption } from "../../../../_metronic/_helpers";
import { toast } from "react-toastify";
import { LoadingAnimation } from "../../../components/loading-animation/LoadingAnimation"

export function PickupCard({ history }) {
  const pickupUIContext = usePickupUIContext();
  const pickupUIProps = useMemo(() => {
    return {
      ids: pickupUIContext.ids,
      newPickupButtonClick: pickupUIContext.newPickupButtonClick,
      queryParams: pickupUIContext.queryParams,
    };
  }, [pickupUIContext]);

  const [sendPickupRequest, { loading }] = useMutation(SEND_PICKUP_REQUEST, {
    onCompleted: (data) => {
      console.log(data);
      toast.success(data.sendPickupRequest.message, toastOption);
      history.push("/pickup-request")
    },
    onError: (error) => {
      console.log(error);
      toast.error("Failed Pickup Request", toastOption);
    },
  });

  const pickupRequest = (params) => {
    sendPickupRequest({
      variables: {
        branch_uuid: params.branch,
        shipping_uuids: params.shipping_uuid,
        utc_offset: params.utc_offset,
      },
    });
  };

  return (
    <>
    <LoadingAnimation open={loading}/>
    <Card>
      <CardHeader title="Pickups Request List"></CardHeader>
      <CardBody>
        <PickupTable history={history} pickupRequest={pickupRequest} />
      </CardBody>
    </Card>
    </>
  );
}
