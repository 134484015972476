import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../../../../_metronic/_partials/controls";

export const CampaignEditDialogHeader = ({ id }) => {
  const { campaignForEdit, actionsLoading } = useSelector(
    (state) => ({
      campaignForEdit: state.campaign.campaignForEdit,
      actionsLoading: state.campaign.actionsLoading,
    }),
    shallowEqual
  );

  const [title, setTitle] = useState("");
  useEffect(() => {
    let _title = id ? "" : "New Campaign";
    if (campaignForEdit && id) {
      _title = `Edit Campaign ${campaignForEdit.name}`;
    }

    setTitle(_title);
    // eslint-disable-next-line
  }, [campaignForEdit, actionsLoading]);

  return (
    <>
      {actionsLoading && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
    </>
  );
};
