import { gql } from "@apollo/client";
import { QueryGraphql, MutationGraphql } from "../../../../config/ApoloRequest";

export async function findProjects(filter) {
  const query = gql`
    query Query(
      $name: String
      $page_number: Int
      $page_size: Int
      $sort_field: String
      $sort_order: String
    ) {
      findProject(
        findProjectInput: {
          name: $name
          page_number: $page_number
          page_size: $page_size
          sort_field: $sort_field
          sort_order: $sort_order
        }
      ) {
        items {
          uuid
          name
          start_date
          end_date
        }
        total_items
      }
    }
  `;

  const newResponse = await QueryGraphql(query, filter);
  return newResponse.data.findProject;
}

export async function getProjectById(filter) {
  const query = gql`
    query Query($uuid: String!) {
      getProject(uuid: $uuid) {
        name
        uuid
        start_date
        end_date
      }
    }
  `;

  const newResponse = await QueryGraphql(query, filter);
  return newResponse.data.getProject;
}

export async function createProject(variables) {}

export async function updateProject(variables) {}

export async function deleteProject(variables) {}

export const CREATE_PROJECT = gql`
  mutation Mutation($name: String!, $start_date: String!, $end_date: String!) {
    createProject(
      createProjectInput: { name: $name, start_date: $start_date, end_date: $end_date }
    ) {
      uuid
      name
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation Mutation($uuid: String!, $name: String, $start_date: String, $end_date: String) {
    updateProject(
      updateProjectInput: { uuid: $uuid, name: $name, start_date: $start_date, end_date: $end_date }
    ) {
      uuid
      name
    }
  }
`;

export const FIND_PRODUCT_SALES_PROJECT = gql`
  query Q($project_uuid: String!) {
    findProductSalesByProject(project_uuid: $project_uuid) {
      items {
        total_amount
        total_order_qty
        product_name
        product_sku
      }
    }
  }
`;

export const DELETE_PROJECT = gql`
  mutation Mutation($uuid: String!) {
    deleteProject(uuid: $uuid) {
      name
    }
  }
`;

export const SEARCH_PROJECTS = gql`
  query Query($name: String) {
    findProject(findProjectInput: { name: $name, page_number: 1, page_size: 10 }) {
      items {
        uuid
        name
      }
    }
  }
`;
