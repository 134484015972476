/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import { NavLink } from 'react-router-dom';
// import SVG from "react-inlinesvg";
import {
  // toAbsoluteUrl,
  checkIsActive,
} from '../../../../_helpers';
import { RoleChecker } from '../../../../../app/RoleChecker';
import { gql } from '@apollo/client';
import { QueryGraphql } from '../../../../../app/config/ApoloRequest';

export function HeaderMenu({ layoutProps }) {
  const match = useRouteMatch();
  const location = useLocation();
  const getMenuItemActive = (url, includeQueryParams) => {
    return checkIsActive(location, url, includeQueryParams) ? 'menu-item-active' : '';
  };

  const [paymentOrderCount, setPaymentOrderCount] = useState(0);
  const [paidOrderCount, setPaidOrderCount] = useState(0);

  useEffect(() => {
    getOrderStatusGroup();
  }, [match]);

  const getOrderStatusGroup = async () => {
    try {
      const { data } = await QueryGraphql(gql`
        {
          __typename
          getOrderStatusGroup {
            items {
              count
              status
            }
          }
        }
      `);

      const { getOrderStatusGroup } = data ?? {};
      const { items = [] } = getOrderStatusGroup ?? { items: [] };

      if (items instanceof Array) {
        const paymentGroup = items.find((item) => item['status'] === 'PAYMENT');
        const paidGroup = items.find((item) => item['status'] === 'PAID');

        if (paymentGroup) {
          setPaymentOrderCount(paymentGroup['count']);
        }

        if (paidGroup) {
          setPaidOrderCount(paidGroup['count']);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-left header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {RoleChecker('create', 'order') && (
          <li className={`menu-item menu-item-rel ${getMenuItemActive('/order/new')}`}>
            <NavLink className="menu-link" to="/order/new">
              <span className="menu-text">Tambah Order</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
        )}

        {RoleChecker('create', 'product') && (
          <li className={`menu-item menu-item-rel ${getMenuItemActive('/product/new')}`}>
            <NavLink className="menu-link" to="/product/new">
              <span className="menu-text">Tambah Product</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
        )}
        {RoleChecker('read', 'packing') && (
          <li className={`menu-item menu-item-rel ${getMenuItemActive('/packing')}`}>
            <NavLink className="menu-link" to="/packing">
              <span className="menu-text">Packing</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
        )}
        {RoleChecker('read', 'order') && (
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              '/order-application?status=PAYMENT',
              true
            )}`}
          >
            <NavLink
              className="menu-link"
              to="/order-application?status=PAYMENT"
              isActive={() => getMenuItemActive('/order-application?status=PAYMENT', true)}
            >
              <span className="menu-text">
                Order Aplikasi (PAYMENT)
                {paymentOrderCount > 0 && (
                  <span className="ml-2 text-success rounded text-warning">
                    {paymentOrderCount}
                  </span>
                )}
              </span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
        )}
        {RoleChecker('read', 'order') && (
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              '/order-application?status=PAID',
              true
            )}`}
          >
            <NavLink
              className="menu-link"
              to="/order-application?status=PAID"
              isActive={() => getMenuItemActive('/order-application?status=PAID', true)}
            >
              <span className="menu-text">
                Order Aplikasi (PAID)
                {paidOrderCount > 0 && (
                  <span className="ml-2 text-success rounded text-success">{paidOrderCount}</span>
                )}
              </span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
        )}
      </ul>
    </div>
  );
}
