import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useSubheader } from "../../../_metronic/layout";
import AccountInformation from "./AccountInformation";
import { ProfileOverview } from "./ProfileOverview";
import ChangePassword from "./ChangePassword";
import PersonaInformation from "./PersonaInformation";
import EmailSettings from "./EmailSettings";
import { ProfileCard } from "./components/ProfileCard";
import { getAdmin, logoutOthers, UPDATE_ADMIN } from "../Auth/_redux/authCrud";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { toastOption } from "../../../_metronic/_helpers";
import { LoadingAnimation } from "../MasterData/pages/brand/loading-animation/LoadingAnimation";

export default function UserProfilePage() {
  const suhbeader = useSubheader();
  suhbeader.setTitle("User profile");

  const [userAdmin, setuserAdmin] = useState({});
  const user = useSelector(({ auth }) => auth.user, shallowEqual);

  const admin = () => {
    getAdmin()
      .then((res) => {
        setuserAdmin(res);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    admin();
    return () => {};
  }, [user]);

  const [updateAdmin, { loading }] = useMutation(UPDATE_ADMIN, {
    onCompleted: () => {
      admin();
      toast.success("Berhasil update profile", toastOption);
    },
    onError: (error) => {
      console.log(error);
      toast.error("Gagal update profile", toastOption);
    },
  });

  const saveAdmin = (value) => {
    updateAdmin({
      variables: {
        email: value.email,
        name: value.name,
        hp: value.hp,
        password: value.password,
      },
    });
  };

  const logoutOthersFunc = () => {
    logoutOthers()
      .then((res) => {
        toast.success("Berhasi!l logout others", toastOption);
      })
      .catch((err) => {
        console.log(err);
        toast.success("Gagal logout others!", toastOption);
      });
  };

  return (
    <>
      <LoadingAnimation open={loading} />
      <ProfileCard
        userAdmin={userAdmin}
        updateAdmin={saveAdmin}
        logoutOthers={logoutOthersFunc}
      />
      {/* <div className="flex-row-fluid ml-lg-8">
        <Switch>
          <Redirect
            from="/user-profile"
            exact={true}
            to="/user-profile/profile-overview"
          />
          <Route
            path="/user-profile/profile-overview"
            component={ProfileOverview}
          />
          <Route
            path="/user-profile/account-information"
            component={AccountInformation}
          />
          <Route
            path="/user-profile/change-password"
            component={ChangePassword}
          />
          <Route
            path="/user-profile/email-settings"
            component={EmailSettings}
          />
          <Route
            path="/user-profile/personal-information"
            component={PersonaInformation}
          />
        </Switch>
      </div> */}
    </>
  );
}
