import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";

export function MemberAddressEditDialogHeader({ memberAddress }) {
  // MemberAddress Redux state
  // const { memberAddressForEdit, actionsLoading } = useSelector(
  //   (state) => ({
  //     memberAddressForEdit: state.members.memberForEdit.member_address,
  //     actionsLoading: state.members.actionsLoading,
  //   }),
  //   shallowEqual
  // );

  const [title, setTitle] = useState("");
  // Title couting
  useEffect(() => {
    let _title = "New Member Address";
    if (memberAddress) {
      _title = `Edit memberAddress '${memberAddress.label}'`;
    }

    setTitle(_title);
    // eslint-disable-next-line
    // }, [memberAddressForEdit, actionsLoading]);
  }, []);

  return (
    <>
      {/* {actionsLoading && <ModalProgressBar />} */}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
    </>
  );
}
