import axios from "axios";
import { API } from "../../../../config/UrlEndPoint";
import { gql } from "@apollo/client";
import { MutationGraphql, QueryGraphql } from "../../../../config/ApoloRequest";

export const CUSTOMERS_URL = `${API}synchronizeMarketplace/`;

// CREATE =>  POST: add a new synchronizeMarketplace to the server
// export function createSynchronizeMarketplace(synchronizeMarketplace) {
//   return axios.post(CUSTOMERS_URL, { synchronizeMarketplace });
// }

export async function findSynchronizeMarketplaces(queryParams) {
  const query = gql`
    query Query(
      $page_number: Int
      $page_size: Int
      $sort_field: String
      $sort_order: String
      $invoice_no: String
    ) {
      __typename
      findMarketplaceTrxInvoiceGroup(
        findMarketplaceTrxInvoiceGroupInput: {
          invoice_no: $invoice_no
          page_number: $page_number
          page_size: $page_size
          sort_field: $sort_field
          sort_order: $sort_order
        }
      ) {
        items {
          invoice_no
          marketplace
          store_uuid
          total_amount
          trx_date
          synchronized_count
          unsynchronized_count
        }
        total_items
      }
    }
  `;

  const newResponse = await QueryGraphql(query, queryParams);
  return newResponse.data.findMarketplaceTrxInvoiceGroup;
}

export const FIND_MARKETPLACE_TRX_IMPORT = gql`
  query Q($invoice_no: String) {
    __typename
    findMarketplaceTrx(findMarketplaceTrxImportInput: { invoice_no: $invoice_no }) {
      items {
        description
        trans_code
        uuid
        order_uuid
        amount_value
      }
    }
  }
`;

export const FIND_ORDER_BY_INVOICE = gql`
  query Q($marketplace_invoice: String) {
    __typename
    findOrder(findOrderInput: { marketplace_invoice: $marketplace_invoice }) {
      items {
        invoice_no
        marketplace_invoice
        status
        uuid
        net_amount
        order_amount
      }
    }
  }
`;

export const UPDATE_MARKETPLACE_TRX = gql`
  mutation M($updateMarketplaceTrxItem: [UpdateMarketplaceTrxItem!]!) {
    updateMarketplaceTrx(
      updateMarketplaceTrxImportInput: { marketplace_transactions: $updateMarketplaceTrxItem }
    ) {
      uuid
    }
  }
`;

export async function getSynchronizeMarketplaceById(id) {
  const query = gql`
  {
      getOrder(uuid: "${id}") {
        uuid
        status
        trx_date
        updated_at
        is_online
        invoice_no
        created_at
        store{
          uuid
          platform
          market_name
        }
        marketplace_invoice
        branch {
          city
          name
          province
          uuid
        }
        member {
          name
          email
          hp
          uuid
          member_level_code
        }
        order_snapshot
        image_confirmation {
          url
        }
        order_payment {
          uuid
          amount
          pg_trx_id
          payment_method {
            code
            name
          }
      }
      order_item {
      product_sku_uuid
      product_sku {
        product {
          name
        }
        sku
        price
      }
      qty
      uuid
    }
        order_delivery {
          uuid
          awb
          province
          city
          codepos
          sub_district
          shipping_service {
            company_code
            service
          }
        }
      }
    }
`;

  const newResponse = await QueryGraphql(query);
  return newResponse.data.getOrder;
}

export const ORDER_ITEM_AND_DELIVERY = gql`
  mutation Mutation(
    $createOrderItemItems: [CreateOrderItemInput!]!
    $address: String!
    $city: String!
    $codepos: String!
    $order_uuid: String!
    $province: String!
    $receiver_hp: String!
    $receiver_name: String!
    $sub_district: String!
    $orderDeliveryUuid: String!
    $awb: String
    $trx_date: DateTime
    $status: String
  ) {
    createOrderItem(
      createOrderItemInput: { order_uuid: $order_uuid, items: $createOrderItemItems }
    ) {
      qty
      order_uuid
      uuid
      assigned_price
      product_snapshot
      product_sku_snapshot
    }
    updateOrderDelivery(
      updateOrderDeliveryInput: {
        address: $address
        city: $city
        codepos: $codepos
        order_uuid: $order_uuid
        province: $province
        receiver_hp: $receiver_hp
        receiver_name: $receiver_name
        sub_district: $sub_district
        uuid: $orderDeliveryUuid
      }
    ) {
      address
      city
      receiver_name
      receiver_hp
    }
    updateOrder(
      updateOrderInput: { uuid: $order_uuid, awb: $awb, status: $status, trx_date: $trx_date }
    ) {
      uuid
      marketplace_invoice
    }
  }
`;

export const UPDATE_ITEM_DELIVERY = gql`
  mutation Mutation(
    $orderItemInput: [UpdateOrderItemInput!]!
    $address: String!
    $city: String!
    $codepos: String!
    $order_uuid: String!
    $province: String!
    $receiver_hp: String!
    $receiver_name: String!
    $sub_district: String!
    $orderDeliveryUuid: String!
    $awb: String
    $store: String
    $status: String
    $trx_date: DateTime
  ) {
    updateOrderItem(updateOrderItemInput: $orderItemInput) {
      qty
      order_uuid
      uuid
    }
    updateOrderDelivery(
      updateOrderDeliveryInput: {
        address: $address
        city: $city
        codepos: $codepos
        order_uuid: $order_uuid
        province: $province
        receiver_hp: $receiver_hp
        receiver_name: $receiver_name
        sub_district: $sub_district
        uuid: $orderDeliveryUuid
      }
    ) {
      address
      city
      receiver_name
      receiver_hp
    }
    updateOrder(
      updateOrderInput: {
        uuid: $order_uuid
        awb: $awb
        store_uuid: $store
        status: $status
        trx_date: $trx_date
      }
    ) {
      uuid
      marketplace_invoice
    }
  }
`;

export const UPDATE_ORDER_AWB = gql`
  mutation ($uuid: String!, $awb: String) {
    updateOrder(updateOrderInput: { uuid: $uuid, awb: $awb }) {
      uuid
      marketplace_invoice
    }
  }
`;

export async function deleteSynchronizeMarketplace(id) {
  const query = gql`
    mutation Mutation {
      deleteSynchronizeMarketplace(synchronizeMarketplace_uuid: "${id}") {
        message
      }
    }
  `;

  const response = await MutationGraphql(query);
  return response.data.deleteSynchronizeMarketplace;
}

export function getAllSynchronizeMarketplaces() {
  return axios.get(CUSTOMERS_URL);
}

// export function getSynchronizeMarketplaceById(synchronizeMarketplaceId) {
//   return axios.get(`${CUSTOMERS_URL}${synchronizeMarketplaceId}`);
// }

// export function findSynchronizeMarketplaces(queryParams) {
//   return axios.post(`${CUSTOMERS_URL}find`, queryParams );
// }

export function updateSynchronizeMarketplace(synchronizeMarketplace) {
  return axios.put(`${CUSTOMERS_URL}/`, synchronizeMarketplace);
}

// DELETE => delete the synchronizeMarketplace from the server
// export function deleteSynchronizeMarketplace(synchronizeMarketplaceId) {
//   return axios.delete(`${CUSTOMERS_URL}/${synchronizeMarketplaceId}`);
// }
