import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./MemberUIHelpers";
import { shallowEqual, useSelector } from "react-redux";

const MemberUIContext = createContext();

export function useMemberUIContext() {
  return useContext(MemberUIContext);
}

export const MemberUIConsumer = MemberUIContext.Consumer;

export function MemberUIProvider({memberUIEvents, children}) {
  const { filters } = useSelector(state => state.members, shallowEqual)
  const [queryParams, setQueryParamsBase] = useState(filters);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initMember = {
    id: undefined,
    firstName: "",
    lastName: "",
    email: "",
    userName: "",
    gender: "Female",
    status: 0,
    dateOfBbirth: "",
    ipAddress: "",
    type: 1
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initMember,
    newMemberButtonClick: memberUIEvents.newMemberButtonClick,
    openEditMember: memberUIEvents.openEditMember,
  };

  return <MemberUIContext.Provider value={value}>{children}</MemberUIContext.Provider>;
}