import React, { useState, useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { StoryEditForm } from './StoryEditForm';
import { toastOption } from '../../../../../_metronic/_helpers';
import { LoadingAnimation } from '../loading-animation/LoadingAnimation';
import { CREATE_STORY, GET_STORY, UPDATE_STORY } from '../../_redux/story/storyCrud';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

export function StoryEditDialog() {
  const history = useHistory();
  const match = useRouteMatch();
  const { id } = useParams();
  const [story, setStory] = useState(null);

  const [fetchStory, { loading: entityLoading }] = useLazyQuery(GET_STORY, {
    onCompleted: (data) => {
      const { getStory } = data ?? {};

      setStory(getStory);
    },
    onError: (error) => {
      toast.error(error, toastOption);
    },
  });

  useEffect(() => {
    if (id) {
      fetchStory({
        variables: {
          uuid: id,
        },
      });
    }
  }, [id]);

  const [createStory, { loading: createLoading }] = useMutation(CREATE_STORY, {
    onCompleted: (data) => {
      const { createStory } = data ?? {};
      const { story = {} } = createStory;

      toast.success('Success add new story', toastOption);

      if (story) {
        history.push(`/story/${story.uuid}/edit`);
      } else {
        history.push(`/story/`);
      }
    },
    onError: (data) => {
      toast.error('Failed add new story', toastOption);
    },
  });

  const [updateStory, { loading: updateLoading }] = useMutation(UPDATE_STORY, {
    onCompleted: (data) => {
      toast.success(data.updateStory.message, toastOption);
      history.go(0);
    },
    onError: (data) => {
      toast.error('Failed update story', toastOption);
      console.log(data);
    },
  });

  const saveStory = (params) => {
    if (!match.params.id) {
      createStory({
        variables: {
          brand: params.brand,
          media_type: params.media_type,
          related_products: params.related_products_uuid,
          story_text: params.story_text,
          story_type: params.story_type,
          image: params.image,
          video_link: params.video_link,
        },
      });
    } else {
      updateStory({
        variables: {
          uuid: match.params.id,
          brand: params.brand,
          media_type: params.media_type,
          related_products: params.related_products_uuid,
          story_text: params.story_text,
          story_type: params.story_type,
          image: params.image,
          video_link: params.video_link,
          deletedImageFilenames: params.deletedImage,
        },
      });
    }
  };

  const loading = entityLoading || createLoading || updateLoading;

  return (
    <>
      <LoadingAnimation open={loading} />
      <StoryEditForm history={history} match={match} story={story} saveStory={saveStory} />
    </>
  );
}
