import * as requestFromServer from "./orderLogisticCrud";
import { orderLogisticSlice, callTypes } from "./orderLogisticSlice";

const { actions } = orderLogisticSlice;

export const fetchOrderLogistics = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findOrderLogistics(queryParams)
    .then((response) => {
      const {
        total_items,
        items,
        total_real_shipping_cost,
        total_shipping_cost,
      } = response;

      dispatch(
        actions.orderLogisticsFetched({
          total_items,
          items,
          total_real_shipping_cost,
          total_shipping_cost,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find orderLogistics";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchOrderLogistic = (id) => (dispatch) => {
  if (!id) {
    return dispatch(
      actions.orderLogisticFetched({ orderLogisticForEdit: undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getOrderLogisticById(id)
    .then((response) => {
      const orderLogistic = response;
      dispatch(
        actions.orderLogisticFetched({ orderLogisticForEdit: orderLogistic })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find orderLogistic";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createOrderLogistic = (orderLogisticForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createOrderLogistic(orderLogisticForCreation)
    .then((response) => {
      const { orderLogistic } = response.data;
      dispatch(actions.orderLogisticCreated({ orderLogistic }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create orderLogistic";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateOrderLogistic = (orderLogistic) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateOrderLogistic(orderLogistic)
    .then(() => {
      dispatch(actions.orderLogisticUpdated({ orderLogistic }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update orderLogistic";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const removeState = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  const removeItem = {
    total_items: 0,
    items: [],
    total_real_shipping_cost: 0,
    total_shipping_cost: 0,
  };
  dispatch(actions.orderLogisticsFetched(removeItem));
};

// export const updateOrderLogisticsStatus = (ids, status) => dispatch => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .updateStatusForOrderLogistics(ids, status)
//     .then(() => {
//       dispatch(actions.orderLogisticStatusUpdated({ ids, status }));
//     })
//     .catch(error => {
//       error.clientMessage = "Can't update orderLogistics status";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };
