import React, { useState, useEffect } from "react";
import * as supplierCrud from "../../../MasterData/_redux/supplier/supplierCrud";
import * as categoryCrud from "../../../MasterData/_redux/category/categoryCrud";
import * as brandCrud from "../../../MasterData/_redux/brand/brandCrud";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../../../_metronic/_partials/controls";
import { Input } from "../../../../../_metronic/_partials/controls";
import { Formik, Field } from "formik";
import { Cascader } from "antd";
import { Form as Forms, InputGroup } from "react-bootstrap";
import Select, { components } from "react-select";
import { CreateBrandModal } from "../../../../components/create-brand-modal/BrandDialog";
import { CreateSupplierModal } from "../../../../components/create-supplier-modal/SupplierDialog";
import * as Yup from "yup";
import { PUBLISH_STATUS } from "../../../../config/Helper";

export function Product({ history, uploadProduct }) {
  // * Validation
  const ProductEditSchema = Yup.object().shape({
    name: Yup.string().required("Nama Produk wajib diisi"),
    // supplier: Yup.string().required("Supplier wajib diisi"),
    brand: Yup.string().required("Brand wajib diisi"),
    weight: Yup.string().required("Berat wajib diisi"),
    description: Yup.string().required("Berat wajib diisi"),
  });

  // * Back To List
  const backToList = () => {
    history.push("/product");
  };

  // * Second Variant ?
  const [haveVariant, setHaveVariant] = useState({
    variant1: false,
    variant2: false,
  });

  // * Fetch Other Data
  const [supplier, setSupplier] = useState([]);
  const [category, setCategory] = useState([]);
  const [brand, setBrand] = useState([]);
  useEffect(() => {
    supplierCrud
      .findSuppliers({
        page_number: 1,
        page_size: 1000,
        name: "",
        hp: "",
        email: "",
      })
      .then((response) => {
        setSupplier(response.items);
      });

    categoryCrud.findCategorys().then((response) => {
      setCategory(response.children);
    });

    brandCrud
      .findBrands({
        page_number: 1,
        page_size: 1000,
        is_deleted: false,
        name: "",
        sortField: "created_at",
        sortOrder: "DESC",
      })
      .then((response) => {
        setBrand(response.items);
      });
  }, []);

  // * Make Category Nested
  const generateCategory = (categoryChildren) => {
    const categories = [];
    for (const item of categoryChildren) {
      let newCategory = {
        label: item.name,
        value: item.uuid,
      };
      if (item.children) newCategory.children = generateCategory(item.children);
      categories.push(newCategory);
    }
    return categories;
  };
  let arr = [];
  if (category) {
    arr = generateCategory(category);
  }

  // * Modal create brand
  const { MenuList, ValueContainer, SingleValue, Placeholder } = components;

  const [brandModal, setBrandModal] = useState(false);

  const CustomMenuList = ({ selectProps, ...props }) => {
    // Copied from source
    // const { onInputChange, inputValue, onMenuInputFocus } = selectProps;

    return (
      <div style={{ overflow: "scroll", height: "250px" }}>
        <div
          style={{
            width: "100%",
            height: "40px",
            boxSizing: "border-box",
            padding: "10px",
            backgroundColor: "#f1f1f1",
            textAlign: "center",
            cursor: "pointer",
          }}
          onClick={() => setBrandModal(true)}
        >
          Tambah Brand
        </div>
        <MenuList {...props} selectProps={selectProps} />
      </div>
    );
  };

  // * Modal create supplier

  const [supplierModal, setSupplierModal] = useState(false);

  const SupplierMenuList = ({ selectProps, ...props }) => {
    // Copied from source
    // const { onInputChange, inputValue, onMenuInputFocus } = selectProps;

    return (
      <div style={{ overflow: "scroll", height: "250px" }}>
        <div
          style={{
            width: "100%",
            height: "40px",
            boxSizing: "border-box",
            padding: "10px",
            backgroundColor: "#f1f1f1",
            textAlign: "center",
            cursor: "pointer",
          }}
          onClick={() => setSupplierModal(true)}
        >
          Tambah Supplier
        </div>
        <MenuList {...props} selectProps={selectProps} />
      </div>
    );
  };

  const [categoryValue, setCategoryValue] = useState();

  return (
    <>
      <CreateBrandModal
        show={brandModal}
        onHide={() => setBrandModal(false)}
        history={history}
        linkTo="/product/new"
      />
      <CreateSupplierModal
        show={supplierModal}
        onHide={() => setSupplierModal(false)}
        history={history}
        linkTo="/product/new"
      />
      <Formik
        enableReinitialize={true}
        validationSchema={ProductEditSchema}
        initialValues={{}}
        onSubmit={(values) => uploadProduct(values)}
      >
        {({ handleSubmit, setFieldValue }) => (
          <>
            <Card>
              <CardHeader title="Informasi Produk"></CardHeader>
              <CardBody>
                <div className="form-group row">
                  <div className="col-lg-4">Nama Produk</div>
                  <div className="col-lg-8">
                    <Field
                      name="name"
                      component={Input}
                      placeholder="Nama Produk"
                      onChange={(e) => {
                        setFieldValue("name", e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-4">Pilih Kategori</div>
                  <div className="col-lg-8">
                    <Cascader
                      options={arr}
                      name="category"
                      changeOnSelect
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        // console.log(e);
                        setFieldValue("category", e);
                      }}
                    />
                  </div>
                  {/* <div className="col-lg-1">
                    <a
                      title="Tambah Category"
                      className="btn btn-icon btn-light btn-hover-info btn-sm"
                      // onClick={() => console.log("asd")}
                    >
                      <i className="fas fa-plus"></i>
                    </a>
                  </div> */}
                </div>
                {/* <div className="form-group row">
                  <div className="col-lg-4">Pilih Supplier</div>
                  <div className="col-lg-8">
                    <Select
                      name="supplier"
                      components={{ MenuList: SupplierMenuList }}
                      placeholder="Pilih supplier..."
                      options={supplier.map((item) => ({
                        value: item.uuid,
                        label: item.name,
                      }))}
                      onChange={(e) => {
                        setFieldValue("supplier", e.value);
                      }}
                    />
                  </div>
                </div> */}
                <div className="form-group row">
                  <div className="col-lg-4">Publish Status</div>
                  <div className="col-lg-8">
                    <Select
                      name="publish_status"
                      placeholder="Pilih status..."
                      options={PUBLISH_STATUS.map((item) => ({
                        label: item.name,
                        value: item.value,
                      }))}
                      onChange={(e) => {
                        setFieldValue("publish_status", e.value);
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-4">Pilih Brand</div>
                  <div className="col-lg-8">
                    <Select
                      name="brand"
                      components={{ MenuList: CustomMenuList }}
                      placeholder="Pilih brand..."
                      options={brand.map((item) => ({
                        value: item.uuid,
                        label: item.name,
                      }))}
                      onChange={(e) => {
                        setFieldValue("brand", e.value);
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-4">Berat</div>
                  <div className="col-lg-4">
                    <Forms.Group>
                      <InputGroup hasValidation>
                        <Forms.Control
                          type="number"
                          name="weight"
                          aria-describedby="inputGroupPrepend"
                          required
                          onChange={(e) => setFieldValue("weight", parseInt(e.target.value))}
                        />
                        <InputGroup.Text id="inputGroupPrepend">Gram</InputGroup.Text>
                        <Forms.Control.Feedback type="invalid">
                          Please choose a username.
                        </Forms.Control.Feedback>
                      </InputGroup>
                    </Forms.Group>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-4">
                    <h4>Deskripsi Produk</h4> <br /> Pastikan deskripsi produk memuat spesifikasi,
                    ukuran, bahan, masa berlaku, dan lainnya. Semakin detail, semakin berguna bagi
                    pembeli, cantumkan min. 260 karakter agar pembeli semakin mudah mengerti dan
                    menemukan produk anda
                  </div>
                  <div className="col-lg-8">
                    <label htmlFor="desc_text">Description</label>
                    <Field
                      className="form-control"
                      name="description"
                      component="textarea"
                      rows="5"
                      id="desc_text"
                      placeholder="Masukkan deskripsi produk..."
                      label="Description"
                      onChange={(e) => {
                        setFieldValue("description", e.target.value);
                      }}
                    />
                  </div>
                </div>
              </CardBody>
              <CardFooter>
                <button
                  type="submit"
                  onClick={() => handleSubmit()}
                  className="btn btn-info btn-elevate float-right"
                >
                  Save
                </button>
              </CardFooter>
            </Card>
          </>
        )}
      </Formik>
    </>
  );
}
