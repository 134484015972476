// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../../_redux/story/storyActions';
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from '../../../../../_metronic/_helpers';
import * as uiHelpers from '../StoryUIHelpers';
import * as columnFormatters from './column-formatters';
import { Pagination } from '../../../../../_metronic/_partials/controls';
import { useStoryUIContext } from '../StoryUIContext';
import { StoryDisableDialog } from '../story-disable-dialog/StoryDisableDialog';
import { RoleChecker } from '../../../../RoleChecker';
import { ImagesModal } from '../../../../components/ImagesModal';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { setImageResolution } from '../../../../config/Helper';

export function StoryTable() {
  const allowDelete = RoleChecker('delete', 'story');
  const allowUpdate = RoleChecker('update', 'story');
  // story UI Context
  const storyUIContext = useStoryUIContext();
  const storyUIProps = useMemo(() => {
    return {
      ids: storyUIContext.ids,
      setIds: storyUIContext.setIds,
      queryParams: storyUIContext.queryParams,
      setQueryParams: storyUIContext.setQueryParams,
      openEditStory: storyUIContext.openEditStory,
      // openDeleteStoryDialog: storyUIContext.openDeleteStoryDialog,
    };
  }, [storyUIContext]);

  const [showModal, setShowModal] = useState(false);
  const [storyIdForDelete, setStoryIdForDelete] = useState(null);

  const [showModalImage, setShowModalImage] = useState(false);
  const [currentImages, setCurrentImages] = useState(null);

  useEffect(() => {
    setShowModalImage(Boolean(currentImages));
  }, [currentImages]);

  const { currentState } = useSelector((state) => ({ currentState: state.story }), shallowEqual);
  const { totalCount, entities, listLoading } = currentState;

  const dispatch = useDispatch();
  useEffect(() => {
    storyUIProps.setIds([]);
    dispatch(actions.fetchStories(storyUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storyUIProps.queryParams, dispatch]);

  const columns = [
    {
      dataField: 'images',
      text: 'Image',
      sort: true,
      formatter: (cell, row, rowIndex) => {
        const image = setImageResolution(row.images[0].url, '512');

        const filterImage = cell.map((item) => {
          return { url: setImageResolution(item.url, '1080') };
        });

        return (
          <OverlayTrigger overlay={<Tooltip id="tooltip-top">Click For Detail Image!</Tooltip>}>
            <div style={{ height: '85px', width: '145px' }}>
              <img
                style={{ objectFit: 'contain', cursor: 'pointer' }}
                src={image}
                alt="Product Image"
                width="100%"
                height="100%"
                onClick={() => {
                  setCurrentImages(filterImage);
                }}
                className="shadow rounded"
              ></img>
            </div>
          </OverlayTrigger>
        );
      },
    },
    {
      dataField: 'brand',
      text: 'Brand',
      sort: true,
      formatter: (cell, row) => {
        const image = row.brand.file.url;
        const brandName = row.brand.name;
        return (
          <OverlayTrigger overlay={<Tooltip id="tooltip-top">Click For Detail Image!</Tooltip>}>
            <div style={{ height: '85px', width: '145px', cursor: 'pointer' }}>
              <img
                style={{ objectFit: 'contain' }}
                src={image}
                alt="Product Image"
                width="100%"
                height="100%"
                onClick={() => {
                  setCurrentImages(image);
                }}
                className="rounded"
              />
            </div>
          </OverlayTrigger>
        );
      },
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: 'story_text',
      text: 'Story Text',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: 'story_type',
      text: 'Story Type',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: 'action',
      text: 'Actions',
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditStory: storyUIProps.openEditStory,
        setStoryIdForDelete: (story_id) => setStoryIdForDelete(story_id),
        allowDelete,
        allowUpdate,
      },
      classes: 'text-right pr-0',
      headerClasses: 'text-right pr-3',
      style: {
        minWidth: '100px',
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: storyUIProps.queryParams.page_size,
    page: storyUIProps.queryParams.page_number,
  };
  return (
    <>
      <ImagesModal
        show={showModalImage}
        images={currentImages}
        onHide={() => setCurrentImages(null)}
      />
      <StoryDisableDialog
        show={Boolean(storyIdForDelete)}
        onHide={() => setStoryIdForDelete(null)}
        id={storyIdForDelete}
      />
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={listLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="uuid"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(storyUIProps.setQueryParams)}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
