import React from "react";
import { Modal } from "react-bootstrap";
import { BrandEditForm } from "./BrandForm";
import { initBrand } from "../../modules/MasterData/pages/brand/BrandUIHelpers";
import { toast } from "react-toastify";
import { toastOption } from "../../../_metronic/_helpers";
import { useMutation, gql } from "@apollo/client";
import { LoadingAnimation } from "../../modules/MasterData/pages/brand/loading-animation/LoadingAnimation";

const CREATE_LOGO = gql`
  mutation Mutation($logo: Upload!, $name: String!) {
    createBrand(createBrandInput: { name: $name }, logo: $logo) {
      message
      data {
        name
      }
    }
  }
`;

export function CreateBrandModal({ show, onHide, history, linkTo }) {
  const [createBrand, { loading }] = useMutation(CREATE_LOGO, {
    onCompleted: (data) => {
      toast.success(data.createBrand.message, toastOption);
      onHide();
      history.push(`${linkTo}`);
    },
    onError: (error) => {
      console.log(error);
      toast.error("Gagal menambahkan brand!", toastOption);
    },
  });

  const addBrand = (params) => {
    createBrand({ variables: { name: params.name, logo: params.logo } });
  };

  return (
    <>
      <LoadingAnimation open={loading} />
      <Modal
        size="md"
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton={true}>
          <Modal.Title id="example-modal-sizes-title-lg">
            Create Brand
          </Modal.Title>
        </Modal.Header>
        <BrandEditForm addBrand={addBrand} brand={initBrand} onHide={onHide} />
      </Modal>
    </>
  );
}
