import gql from 'graphql-tag';

export const VERIFY_ORDER_PAYMENT = gql`
  query Order($no_invoice: String!) {
    verifyOrderPayment(order_invoice_no: $no_invoice) {
      message
      is_paid
      uuid
    }
  }
`;

export const VERIFY_ORDER_ITEM_SKU = gql`
  query Order($sku_name: String!) {
    verifyOrderItemSku(product_sku: $sku_name) {
      product_sku {
        sku
        uuid
        variant_value_1
        variant_value_2
        product {
          name
        }
      }
      qty
    }
  }
`;

export const VERIFY_ORDER_PACKING_INPUT = gql`
  query Order($order_uuid: String!, $product_sku_qty: [ProductSkuQty!]!) {
    verifyOrderPacking(
      verifyOrderPackingInput: { order_uuid: $order_uuid, product_skus_qty: $product_sku_qty }
    ) {
      skus
      message
      valid
    }
  }
`;

export const GET_ORDER_AND_ITEMS = gql`
  query ($uuid: String!) {
    getOrder(uuid: $uuid) {
      uuid
      status
      invoice_no
      order_item {
        uuid
        product_sku_uuid
        qty
        product_sku {
          uuid
          sku
          variant_value_1
          variant_value_2
          product {
            uuid
            name
          }
        }
      }
    }
  }
`;

export const UPDATE_ORDER = gql`
  mutation Mutation($uuid: String!, $status: String) {
    updateOrder(updateOrderInput: { uuid: $uuid, status: $status }) {
      status
      invoice_no
    }
  }
`;
