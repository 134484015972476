export const VoucherStatusCssClasses = ['danger', 'success', 'info', ''];
export const VoucherStatusTitles = ['Suspended', 'Active', 'Pending', ''];
export const VoucherTypeCssClasses = ['success', 'primary', ''];
export const VoucherTypeTitles = ['Business', 'Individual', ''];
export const defaultSorted = [{ dataField: 'created_at', order: 'desc' }];
export const sizePerPageList = [
  { text: '10', value: 10 },
  { text: '50', value: 50 },
  { text: '100', value: 100 },
];
export const initialFilter = {
  quota_start: null,
  quota_end: null,
  period_start: '',
  period_end: '',
  is_active: null,
  allow_multi_voucher: null,
  code: '',
  publish_status: '',
  amount_start: null,
  amount_end: null,
  percent_start: null,
  percent_end: null,
  type: '',
  product_filter: '',
  product_list: null,
  page_number: 1,
  page_size: 10,
  sort_order: 'DESC', // asc||desc
  sort_field: 'created_at',
};

export const initialValue = {
  name: '',
  code: '',
  type: '',
  is_active: true,
  publish_status: 'PUBLISHED',
  amount: 0,
  percent: 0,
  allow_multi_voucher: true,
  product_filter: '',
  product_list: [],
  // guide: '',
  period_start: '',
  period_end: '',
  term_and_conditions: '',
  quota: 0,
  minimal_order_amount: 0,
};

export const IS_ACTIVE = [
  {
    name: 'true',
    value: true,
  },
  {
    name: 'false',
    value: false,
  },
];

export const VOUCHER_TYPES = [
  {
    name: 'PRICE',
    value: 'PRICE',
    description: 'Potongan Harga',
  },
  {
    name: 'SHIPPING',
    value: 'SHIPPING',
    description: 'Potongan Ongkir',
  },
  {
    name: 'CASHBACK',
    value: 'CASHBACK',
    description: 'CashBack',
  },
];

export const VOUCHER_PRODUCT_FILTER_TYPES = [
  {
    name: 'WHITELIST',
    value: 'WHITELIST',
  },
  {
    name: 'BLACKLIST',
    value: 'BLACKLIST',
  },
  {
    name: 'ALL',
    value: 'ALL',
  },
];

export const category = [
  {
    name: 'Global',
    value: 'GLOBAL',
  },
  {
    name: 'Individu',
    value: 'INDIVIDU',
  },
];

export const badge = (status) => {
  let data = {};
  if (status === 'ACTIVE') data = { text: 'ACTIVE', type: 'success' };
  else data = { text: 'INACTIVE', type: 'warning' };

  return data;
};

export function getVoucherInitialValues(voucher) {
  const {
    uuid = '',
    allow_multi_voucher = false,
    code = '',
    minimal_order_amount = '',
    quota = '',
    name = '',
    percent = '',
    amount = '',
    period_start = '',
    period_end = '',
    type = '',
    is_active = '',
    publish_status = '',
    product_filter = '',
    products = [],
    term_and_conditions = '',
  } = voucher ?? {};

  const values = {
    uuid: uuid ?? '',
    allow_multi_voucher: allow_multi_voucher ?? false,
    code: code ?? '',
    minimal_order_amount: minimal_order_amount ?? '',
    name: name ?? '',
    percent: percent ?? '',
    amount: amount ?? '',
    period_start: period_start ?? '',
    period_end: period_end ?? '',
    type: type ?? '',
    is_active: is_active ?? '',
    publish_status: publish_status ?? '',
    product_filter: product_filter ?? '',
    product_list: [],
    products: products ?? [],
    term_and_conditions: term_and_conditions ?? '',
    quota: quota ?? '',
  };

  if (products instanceof Array) {
    values.product_list = products.map(({ uuid, name }) => ({ label: name, value: uuid }));
  }

  return values;
}
