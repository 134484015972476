import React, { useMemo, useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { ProductFilter } from "./product-filter/ProductFilter";
import { ProductTable } from "./product-table/ProductTable";
import { useProductUIContext } from "./ProductUIContext";
import { Badge, Collapse } from "react-bootstrap";
import { RoleChecker } from "../../../RoleChecker";
import { shallowEqual, useSelector } from "react-redux";

export function ProductCard({ history }) {
  const productUIContext = useProductUIContext();
  const productUIProps = useMemo(() => {
    return {
      queryParams: productUIContext.queryParams,
      newProductButtonClick: productUIContext.newProductButtonClick,
    };
  }, [productUIContext]);
  const [statusFilter, setStatusFilter] = useState(false);
  const [open, setOpen] = useState(false);

  const changeShow = () => {
    setOpen(!open);

    // Set Status Filter To False
    setStatusFilter(false);
    const filter = productUIProps.queryParams;
    for (const [key, value] of Object.entries(filter)) {
      if (value !== "" && key) {
        setStatusFilter(true);
      }
    }
  };

  useEffect(() => {
    if (productUIProps.filterType !== undefined) {
      setStatusFilter(true);
      // setIsDisabled(true);
    }
    // eslint-disable-next-line
  }, [productUIProps.filterType]);

  const { filters } = useSelector(state => state.product, shallowEqual)
  useEffect(() => {
    if (filters.filterIsOpen) setOpen(true) 
  }, [])

  return (
    <Card>
      <CardHeader title="Daftar Product">
        <CardHeaderToolbar>
          {statusFilter && (
            <Badge className="mr-5" pill variant="danger">
              Filter Aktif
            </Badge>
          )}
          {RoleChecker("read", "product") && (
            <button
              type="button"
              className="btn btn-warning mr-2"
              onClick={changeShow}
              aria-controls="collapse-filter-product"
              aria-expanded={open}
            >
              Cari
            </button>
          )}
          {RoleChecker("create", "product") && (
            <button
              type="button"
              className="btn btn-primary mr-2"
              onClick={productUIProps.newProductButtonClick}
              aria-controls="collapse-filter-product"
              aria-expanded={open}
            >
              Tambah Product
            </button>
          )}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <Collapse in={open}>
          <div id="collapse-filter-product">
            <ProductFilter history={history} />
          </div>
        </Collapse>

        <ProductTable />
      </CardBody>
    </Card>
  );
}
