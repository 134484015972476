export const BranchStatusCssClasses = ["danger", "success", "info", ""];
export const BranchStatusTitles = ["Suspended", "Active", "Pending", ""];
export const BranchTypeCssClasses = ["success", "primary", ""];
export const BranchTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "created_at", order: "desc" }];
export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
];
export const initialFilter = {
  name: "",
  city: "",
  province: "",
  sortOrder: "asc", // asc||desc
  sortField: "created_at",
  page_number: 1,
  page_size: 10,
};

export let initBranch = {
  address: "",
  city: "",
  latitude: "",
  longitude: "",
  name: "",
  postal_code: "",
  province: "",
  subdistrict_id: "",
  subdistrict_name: "",
};

export const role = [
  {
    name: "Customer Care",
    value: "CustomerCare",
  },
];

export const badge = (status) => {
  let data = {};
  if (status) data = { text: "ACTIVE", type: "success" };
  else data = { text: "INACTIVE", type: "warning" };

  return data;
};
