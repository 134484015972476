export const CONFIG_HELPER = {
  default_pickup_helper: 'default_pickup_hour',
  default_branch_uuid: 'default_branch_uuid',
  gayalo_store: 'gayalo_store',
  manual_store: 'manual_store',
  promo_text: 'promo_text',
  promo_url: 'promo_url',
};

export const SHIPPING_SERVICE_CATEGORIES = [
  {
    name: 'REGULER',
    value: 'REGULER',
  },
  // {
  //   name: "EXPRESS",
  //   value: "EXPRESS",
  // },
  {
    name: 'INSTANT',
    value: 'INSTANT',
  },
  {
    name: 'SAME DAY',
    value: 'SAME_DAY',
  },
  {
    name: 'NEXT DAY',
    value: 'NEXT_DAY',
  },
  {
    name: 'KARGO',
    value: 'KARGO',
  },
  {
    name: 'EKONOMI',
    value: 'EKONOMI',
  },
  {
    name: 'BEBAS ONGKIR',
    value: 'BEBAS_ONGKIR',
  },
];

export const SS_CATEGORY = {
  REGULER: 'REGULER',
  INSTANT: 'INSTANT',
  SAME_DAY: 'SAME DAY',
  NEXT_DAY: 'NEXT DAY',
  KARGO: 'KARGO',
  EKONOMI: 'EKONOMI',
  BEBAS_ONGKIR: 'BEBAS_ONGKIR',
};

export const setImageResolution = (image, resolution) => {
  let strImage;
  if (image) {
    if (image.includes('production')) {
      strImage = [image.slice(0, 85), resolution, image.slice(85)].join('');
    } else {
      strImage = [image.slice(0, 86), resolution, image.slice(86)].join('');
    }
  }
  return strImage;
};

export const PUBLISH_STATUS = [
  {
    name: 'PUBLISH',
    value: 'PUBLISH',
  },
  {
    name: 'UNPUBLISH',
    value: 'UNPUBLISH',
  },
];
