import React from 'react'
import PropTypes from 'prop-types'

const SvgIcon = ({
  children, style, viewBox, width, className, height, onClick, onBlur,
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    onClick={() => onClick()}
    onBlur={() => onBlur()}
  >
    {children}
  </svg>
)

SvgIcon.propTypes = {
  children: PropTypes.element.isRequired,
  style: PropTypes.object,
  viewBox: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
}

SvgIcon.defaultProps = {
  style: {},
  viewBox: '0 0 24 24',
  width: '24px',
  height: '24px',
  className: '',
  onClick: () => {},
  onBlur: () => {},
}

export default SvgIcon
