/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  Input,
} from "../../../../_metronic/_partials/controls";
import { Formik, Field } from "formik";
import { logoutUser } from "../../Auth/_redux/authCrud";
import { Link } from "react-router-dom";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  password: Yup.string().notRequired(),
  confirmationPassword: Yup.string().when("password", {
    is: (password) => password && password.length > 0,
    then: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
        "Konfirmasi password harus sama dengan password!"
      )
      .required("Konfirmasi password wajib diisi!"),
  }),
});

export function ProfileCard({ userAdmin, updateAdmin, logoutOthers }) {
  return (
    <>
      {userAdmin && (
        <>
          <Card>
            <Formik
              enableReinitialize={true}
              initialValues={userAdmin}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                updateAdmin(values);
              }}
            >
              {({ handleSubmit, setFieldValue }) => (
                <>
                  <CardHeader title="Admin Profile">
                    <CardHeaderToolbar>
                      <button
                        className="btn btn-success"
                        onClick={() => handleSubmit()}
                      >
                        Save Change
                      </button>
                    </CardHeaderToolbar>
                  </CardHeader>
                  <CardBody>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                            <div
                              className="symbol-label"
                              // style={{ backgroundImage: `url(${user.pic})` }}
                              style={{ fontSize: "34px" }}
                            >
                              {userAdmin.name && userAdmin.name[0]}
                            </div>
                            <i className="symbol-badge bg-success"></i>
                          </div>
                          <div>
                            <div className="font-weight-bolder font-size-h5 text-dark-75">
                              {userAdmin.name}
                            </div>
                            <div className="mt-2">
                              <Link
                                to="/logout"
                                onClick={() => {
                                  logoutUser();
                                  localStorage.clear();
                                }}
                                className="btn btn-sm btn-danger font-weight-bold mr-2 py-2 px-3 px-xxl-5 my-1"
                              >
                                Logout
                              </Link>
                              <button
                                className="btn btn-sm btn-outline-danger font-weight-bold py-2 px-3 px-xxl-5 my-1"
                                onClick={logoutOthers}
                              >
                                Logout Other
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="py-9">
                          <div className="d-flex align-items-center mb-2">
                            <span className="font-weight-bold mr-2">
                              Email:
                            </span>
                            <span className="text-muted text-hover-primary">
                              {userAdmin.email}
                            </span>
                          </div>
                          <div className="d-flex align-items-center mb-2">
                            <span className="font-weight-bold mr-2">
                              Phone:
                            </span>
                            <span className="text-muted">{userAdmin.hp}</span>
                          </div>
                          <div className="d-flex align-items-center">
                            <span className="font-weight-bold mr-2">Role:</span>
                            <span className="text-muted">{userAdmin.role}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group row">
                          <div className="col">
                            <Field
                              name="name"
                              label="Name"
                              component={Input}
                              placeholder="Nama"
                              onChange={(e) => {
                                setFieldValue("name", e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col">
                            <Field
                              name="hp"
                              label="No Hp."
                              component={Input}
                              placeholder="No Hp"
                              onChange={(e) => {
                                setFieldValue("hp", e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col">
                            <Field
                              name="email"
                              label="Email"
                              component={Input}
                              placeholder="Email"
                              onChange={(e) => {
                                setFieldValue("email", e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col">
                            <Field
                              name="password"
                              label="Password"
                              component={Input}
                              type="password"
                              placeholder="Password"
                              onChange={(e) => {
                                setFieldValue("password", e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col">
                            <Field
                              name="confirmationPassword"
                              label="Konfirmasi Password"
                              component={Input}
                              type="password"
                              placeholder="Konfirmasi Password"
                              onChange={(e) => {
                                setFieldValue(
                                  "confirmationPassword",
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </>
              )}
            </Formik>
          </Card>
        </>
      )}
    </>
  );
}
