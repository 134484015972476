import * as requestFromServer from './productCrud';
import { productSlice, callTypes } from './productSlice';
import { toastOption } from '../../../../../_metronic/_helpers';
import { toast } from 'react-toastify';

const { actions } = productSlice;

export const startAction = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
};

export const fetchProducts = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findProducts(queryParams)
    .then((response) => {
      const { total_items, items } = response;
      dispatch(actions.productsFetched({ total_items, items }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchProduct = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.productFetched({ productForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getProductById(id)
    .then((response) => {
      const product = response;
      dispatch(actions.productFetched({ productForEdit: product }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find product";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchProductsRating = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findProductsRating(queryParams)
    .then((response) => {
      const { total_items, items } = response;
      dispatch(actions.productsRatingFetched({ total_items, items }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const deleteProduct = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteProduct(id)
    .then(() => {
      fetchProducts();
      toast.success('Successful Delete Product!', toastOption);
    })
    .catch((error) => {
      console.log(error);
      error.clientMessage = "Can't delete category";
      toast.error('Delete Product Failed!', toastOption);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const productFilter = (params) => (dispatch) => {
  dispatch(actions.startCall({ callTypes: callTypes.list }));
  dispatch(actions.productFilter(params));
};
