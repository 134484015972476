import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/member/memberActions";

export function MemberAddressDisableDialog({ memberAddress, show, onHide }) {
  // MemberAddress Redux state
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  // Title couting
  useEffect(() => {
    let _title = "";
    if (memberAddress) {
      _title = `Disable MemberAddress '${memberAddress.label}'`;
    }

    setTitle(_title);
    // eslint-disable-next-line
  }, [memberAddress]);

  // server request for saving MemberAddress
  const deleteMemberAddress = () => {
    dispatch(actions.deleteMemberAddress(memberAddress.uuid)).then(() => {
      dispatch(actions.fetchMember(memberAddress.member_uuid));
      onHide();
    });
  };

  return (
    <Modal
      size="md"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Anda yakin akan non aktifkan MemberAddress ini?</span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteMemberAddress}
            className="btn btn-primary btn-elevate"
          >
            simpan
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
