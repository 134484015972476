import axios from 'axios';
import { gql } from '@apollo/client';
import { QueryGraphql, MutationGraphql } from '../../../../config/ApoloRequest';
import { API } from '../../../../config/UrlEndPoint';

export const CUSTOMERS_URL = `${API}member/`;

// CREATE =>  POST: add a new member to the server

export const CREATE_MEMBER = gql`
  mutation Mutation($email: String, $hp: String, $name: String!) {
    createMember(createMemberInput: { email: $email, hp: $hp, name: $name }) {
      uuid
      email
      hp
      name
    }
  }
`;

export const SEARCH_MEMBER = gql`
  query Query($name: String) {
    findMember(findMemberInput: { name: $name }) {
      items {
        name
        email
        hp
        uuid
        dropship_name
        dropship_hp
      }
    }
  }
`;

export const SEARCH_MEMBER_ANY = gql`
  query Query($nameEmailHp: String) {
    findMember(findMemberInput: { nameEmailHp: $nameEmailHp, page_size: 20 }) {
      items {
        uuid
        name
        email
        hp
        dropship_name
        dropship_hp
      }
    }
  }
`;

export const CREATE_MEMBER_ADDRESS = gql`
  mutation Mutation(
    $address: String!
    $city: String!
    $codepos: String!
    $is_default: Boolean
    $label: String!
    $member_uuid: String!
    $province: String!
    $recipient_name: String!
    $recipient_phone: String!
    $sub_district: String!
    $village: String
  ) {
    createMemberAddress(
      createMemberAddressInput: {
        address: $address
        city: $city
        codepos: $codepos
        label: $label
        member_uuid: $member_uuid
        province: $province
        recipient_name: $recipient_name
        recipient_phone: $recipient_phone
        sub_district: $sub_district
        is_default: $is_default
        village: $village
      }
    ) {
      uuid
      province
      city
      codepos
      address
      recipient_name
      recipient_phone
      sub_district
    }
  }
`;

export const UPDATE_MEMBER_ADDRESS = gql`
  mutation Mutation($updateMemberAddressInput: UpdateMemberAddressInput!) {
    updateMemberAddress(updateMemberAddressInput: $updateMemberAddressInput) {
      uuid
      province
      city
      sub_district
      codepos
      address
      recipient_name
      recipient_phone
    }
  }
`;

export function getAllMembers() {
  return axios.get(CUSTOMERS_URL);
}

export async function getMemberById(uuid) {
  const query = gql`
    {
      getMember(uuid: "${uuid}") {
        created_at
        email
        hp
        member_address {
          address
          city
          codepos
          created_at
          is_default
          label
          province
          sub_district
          village
          updated_at
          member_uuid
          uuid
          longitude
          latitude
          recipient_name
          recipient_phone
        }
        dropship_hp
        dropship_name
        member_level_code
        name
        uuid
        updated_at
        member_level {
          code
          level_order
          name
        }
      }
    }
  `;

  const newResponse = await QueryGraphql(query);
  return newResponse.data.getMember;
}

export async function findMembers(queryParams) {
  const query = gql`
    {
      findMember(
        findMemberInput: {
          member_level_code: "${queryParams.member_level_code}"
          name: "${queryParams.name}"
          email: "${queryParams.email}"
          hp: "${queryParams.hp}"
          page_number: ${+queryParams.page_number}
          page_size: ${+queryParams.page_size}
          sort_field: "${queryParams.sortField}"
          sort_order: "${queryParams.sortOrder}"
        }
      ) {
        items {
          email
          hp
          member_level_code
          name
          uuid
          updated_at
          created_at
        }
        sort_field
        sort_order
        total_items
        total_pages
        current_page
      }
    }
  `;
  const newResponse = await QueryGraphql(query);

  return newResponse.data.findMember;
}

export async function updateMember(payload) {
  const query = gql`
    mutation UpdateMember(
      $uuid: String!
      $name: String
      $member_level_code: String
      $password: String
      $email: String
      $hp: String
      $dropship_name: String
      $dropship_hp: String
    ) {
      updateMember(
        updateMemberInput: {
          uuid: $uuid
          name: $name
          member_level_code: $member_level_code
          password: $password
          email: $email
          hp: $hp
          dropship_name: $dropship_name
          dropship_hp: $dropship_hp
        }
      ) {
        uuid
      }
    }
  `;
  const newResponse = await MutationGraphql(query, payload);
  return newResponse.data.updateMember;
}

export async function createMember(payload) {
  const query = gql`
    mutation CreateMember(
      $name: String!
      $member_level_code: String
      $password: String
      $email: String
      $hp: String
      $dropship_name: String
      $dropship_hp: String
    ) {
      createMember(
        createMemberInput: {
          name: $name
          member_level_code: $member_level_code
          password: $password
          email: $email
          hp: $hp
          dropship_name: $dropship_name
          dropship_hp: $dropship_hp
        }
      ) {
        created_at
        uuid
        email
        hp
        name
        uuid
      }
    }
  `;

  const newResponse = await MutationGraphql(query, payload);

  return newResponse.data.createMember;
}

export async function createMemberAddress(payload) {
  const query = gql`
    mutation Mutation {
      createMemberAddress(
        createMemberAddressInput: {
          address: "${payload.address}"
          city: "${payload.city}"
          codepos: "${payload.codepos}"
          is_default: ${payload.is_default}
          label: "${payload.label}"
          latitude: "${payload.latitude}"
          longitude: "${payload.longitude}"
          member_uuid: "${payload.member_uuid}"
          province: "${payload.province}"
          recipient_name: "${payload.recipient_name}"
          recipient_phone: "${payload.recipient_phoone}"
          sub_district: "${payload.sub_district}"
          village: "${payload.village}"
        }
      ) {
        city
        address
        codepos
        province
      }
    }
  `;
  const newResponse = await MutationGraphql(query);
  return newResponse.data.createMemberAddress;
}

export async function updateMemberAddress(payload) {
  const query = gql`
    mutation {
      updateMemberAddress(
        updateMemberAddressInput: {
          uuid: "${payload.uuid}"
          member_uuid: "${payload.member_uuid}"
          label: "${payload.label}"
          address: "${payload.address}"
          sub_district: "${payload.sub_district}"
          city: "${payload.city}"
          province: "${payload.province}"
          codepos: "${payload.codepos}"
          is_default: ${payload.is_default}
          district: "${payload.district}"
          latitude: "${payload.latitude}"
          longitude: "${payload.longitude}"
          recipient_name: "${payload.recipient_name}"
          recipient_phone: "${payload.recipient_phoone}"
        }
      ) {
        address
        city
        codepos
        created_at
        is_default
        label
        member_uuid
        province
        sub_district
        updated_at
        uuid
      }
    }
  `;
  const newResponse = await MutationGraphql(query);
  return newResponse.data.createMemberAddress;
}

// DELETE => delete the member from the server
export async function deleteMemberAddress(uuid) {
  const query = gql`
    mutation {
      deleteMemberAddress(uuid: "${uuid}") {
        message
      }
    }
  `;
  const newResponse = await MutationGraphql(query);
  return newResponse.data.deleteMemberAddress;
}

export const GET_MEMBER_ADDRESS = gql`
  query MemberAddress($uuid: String!) {
    getMember(uuid: $uuid) {
      member_address {
        uuid
        address
        city
        codepos
        sub_district
        province
        recipient_name
        recipient_phone
        label
      }
    }
  }
`;
