import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '../SvgIcon'
import { defaultFill } from '../constant'

const Bank = props => (
  <SvgIcon {...props}>
    <path fill={props.fill} className="custom-fill" d="M12,18H6V14H12M21,14V12L20,7H4L3,12V14H4V20H14V14H18V20H20V14M20,4H4V6H20V4Z" />
  </SvgIcon>
)

Bank.propTypes = {
  fill: PropTypes.string,
}

Bank.defaultProps = {
  fill: defaultFill,
}

export default Bank
