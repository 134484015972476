import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/products/productActions";
import { useProductUIContext } from "../ProductUIContext";
// import { Input, Select } from "../../../../../../_metronic/_partials/controls";
import { role } from "../ProductUIHelpers";
import { LoadingAnimation } from "../loading-animation/LoadingAnimation";
import { DELETE_PRODUCT } from "../../_redux/products/productCrud";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { toastOption } from "../../../../../_metronic/_helpers";

export function ProductDisableDialog({ id, show, onHide }) {
  // Product Redux state
  const dispatch = useDispatch();
  const { actionsLoading, productForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.product.actionsLoading,
      productForEdit: state.product.productForEdit,
    }),
    shallowEqual
  );

  const productUIContext = useProductUIContext();
  const productUIProps = useMemo(() => {
    return {
      queryParams: productUIContext.queryParams,
    };
  }, [productUIContext]);

  const [title, setTitle] = useState("");
  // Title couting
  useEffect(() => {
    setTitle(id);
    let _title = "";
    if (productForEdit && id) {
      _title = `Delete product '${productForEdit.name}'`;
    }

    setTitle(_title);
    // eslint-disable-next-line
  }, [productForEdit, actionsLoading]);

  useEffect(() => {
    // server call for getting Product by id
    dispatch(actions.fetchProducts(productUIProps.queryParams));
    dispatch(actions.fetchProduct(id));
  }, [id, dispatch]);

  const [deleteProduct, { loading }] = useMutation(DELETE_PRODUCT, {
    onCompleted: () => {
      toast.success("Berhasil menghapus product!", toastOption);
      dispatch(actions.fetchProducts(productUIProps.queryParams));
      onHide();
    },
    onError: (error) => {
      console.log(error);
      toast.success("gagal menghapus product!", toastOption);
    },
  });

  const deletingProduct = () => {
    deleteProduct({
      variables: {
        uuid: id,
      },
    });
    // dispatch(actions.deleteProduct(id)).then(() => {
    //   onHide();
    //   dispatch(actions.fetchProducts(productUIProps.queryParams));
    // });
  };

  return (
    <>
      <LoadingAnimation open={loading} />
      <Modal
        size="md"
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>Anda yakin akan menghapus product ini?</span>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={onHide}
              className="btn btn-light btn-elevate"
            >
              Cancel
            </button>
            <> </>
            <button
              type="button"
              onClick={deletingProduct}
              className="btn btn-primary btn-elevate"
            >
              Yakin
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
