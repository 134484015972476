import React, { useMemo } from "react";
import { Formik, Form } from "formik";
import { isEqual } from "lodash";
import { useMemberUIContext } from "../MemberUIContext";
import { initialFilter } from "../MemberUIHelpers";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { memberFilter } from "../../_redux/member/memberActions";

const prepareFilter = (queryParams, values) => {
  const { email, member_level_code, name, hp, filterIsOpen } = values;
  const newQueryParams = { ...queryParams };
  newQueryParams.member_level_code = member_level_code ?? "";
  newQueryParams.email = email ?? "";
  newQueryParams.name = name ?? "";
  newQueryParams.hp = hp ?? "";
  newQueryParams.filterIsOpen = filterIsOpen
  return newQueryParams;
};

export function MemberFilter({ listLoading, history }) {
  const { filters } = useSelector(state => state.members, shallowEqual)
  const dispatch = useDispatch()
  // Members UI Context
  const membersUIContext = useMemberUIContext();
  const membersUIProps = useMemo(() => {
    return {
      queryParams: membersUIContext.queryParams,
      setQueryParams: membersUIContext.setQueryParams,
    };
  }, [membersUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(membersUIProps.queryParams, values);
    if (!isEqual(newQueryParams, membersUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      membersUIProps.setQueryParams(newQueryParams);
      dispatch(memberFilter(newQueryParams))
    }
  };

  return (
    <>
      <Formik
        initialValues={filters}
        onSubmit={(values) => {
          applyFilter({...values, filterIsOpen: true});
        }}
        onReset={() => {
          applyFilter({...initialFilter, filterIsOpen: false})
          history.push("/member")
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
          resetForm,
        }) => (
          <>
            <Form onSubmit={handleSubmit} className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-3">
                  <input
                    type="text"
                    className="form-control"
                    name="member_level_code"
                    placeholder="Search"
                    onBlur={handleBlur}
                    value={values.member_level_code}
                    onChange={(e) => {
                      setFieldValue("member_level_code", e.target.value);
                    }}
                  />
                  <small className="form-text ">
                    Cari <b>Member Level</b>
                  </small>
                </div>
                <div className="col-lg-3">
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    placeholder="Search"
                    onBlur={handleBlur}
                    value={values.name}
                    onChange={(e) => {
                      setFieldValue("name", e.target.value);
                    }}
                  />
                  <small className="form-text ">
                    Cari <b>Nama</b>
                  </small>
                </div>
                <div className="col-lg-3">
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    placeholder="Search"
                    onBlur={handleBlur}
                    value={values.email}
                    onChange={(e) => {
                      setFieldValue("email", e.target.value);
                    }}
                  />
                  <small className="form-text ">
                    Cari <b>Email</b>
                  </small>
                </div>
                <div className="col-lg-3">
                  <input
                    type="text"
                    className="form-control"
                    name="hp"
                    placeholder="Search"
                    onBlur={handleBlur}
                    value={values.hp}
                    onChange={(e) => {
                      setFieldValue("hp", e.target.value);
                    }}
                  />
                  <small className="form-text ">
                    Cari <b>Handphone</b>
                  </small>
                </div>
              </div>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate float-right mb-20 ml-4"
              >
                Terapkan
              </button>
              <button
                type="button"
                onClick={() => {
                  resetForm();
                  handleSubmit();
                }}
                className="btn btn-danger btn-elevate float-right mb-20 ml-8"
              >
                Hapus Pencarian
              </button>
              <hr className="mt-25" />
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
