import React from "react";
import { NotificationLoadingDialog } from "./notification-loading-dialog/NotificationLoadingDialog";
import { NotificationUIProvider } from "./NotificationUIContext";
import { NotificationCard } from "./NotificationCard";

export function NotificationPage({ history }) {
  const notificationUIEvents = {
    newNotificationButtonClick: () => {
      history.push("/notification-user/new");
    },
    openEditNotification: (id) => {
      history.push(`/notification-user/${id}/edit`);
    },
  };

  return (
    <NotificationUIProvider notificationUIEvents={notificationUIEvents}>
      {/*<NotificationLoadingDialog/>*/}
      <NotificationCard history={history} />
    </NotificationUIProvider>
  );
}
