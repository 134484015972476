import { createSlice } from "@reduxjs/toolkit";

const initialPickupsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  pickupForEdit: undefined,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const pickupSlice = createSlice({
  name: "pickups",
  initialState: initialPickupsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getPickupById
    pickupFetched: (state, action) => {
      state.actionsLoading = false;
      state.pickupForEdit = action.payload.pickupForEdit;
      state.error = null;
    },
    // findPickups
    pickupsFetched: (state, action) => {
      const { response } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = response;
      // state.totalCount = total_items;
    },
    // getAllPickup
    pickupAllFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.all = action.payload.all;
    },
    // createPickup
    pickupCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.pickup);
    },
    // updatePickup
    pickupUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.pickup.id) {
          return action.payload.pickup;
        }
        return entity;
      });
    },
  },
});
