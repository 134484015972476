import { createSlice } from "@reduxjs/toolkit";
import { initialFilter } from "../../pages/SynchronizeMarketplaceUIHelpers";

const initialSynchronizeMarketplacesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  synchronizeMarketplaceForEdit: undefined,
  lastError: null,
  filters: initialFilter
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const synchronizeMarketplaceSlice = createSlice({
  name: "synchronizeMarketplaces",
  initialState: initialSynchronizeMarketplacesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getSynchronizeMarketplaceById
    synchronizeMarketplaceFetched: (state, action) => {
      state.actionsLoading = false;
      state.synchronizeMarketplaceForEdit =
        action.payload.synchronizeMarketplaceForEdit;
      state.error = null;
    },
    // findSynchronizeMarketplaces
    synchronizeMarketplacesFetched: (state, action) => {
      const { total_items, items } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = items;
      state.totalCount = total_items;
    },
    // allSynchronizeMarketplaces
    synchronizeMarketplaceALlFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.allData = action.payload.allData;
    },
    synchronizeMarketplacesFilter: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.filters = action.payload
    },
    // createSynchronizeMarketplace
    // synchronizeMarketplaceCreated: (state, action) => {
    //   state.ewactionsLoading = false;
    //   state.error = null;
    //   state.entities.push(action.payload.synchronizeMarketplace);
    // },
    // updateSynchronizeMarketplace
    synchronizeMarketplaceUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.synchronizeMarketplace.id) {
          return action.payload.synchronizeMarketplace;
        }
        return entity;
      });
    },
  },
});
