import * as requestFromServer from "./pickupCrud";
import { pickupSlice, callTypes } from "./pickupSlice";
import { toastOption } from "../../../../../_metronic/_helpers";
import { toast } from "react-toastify";

const { actions } = pickupSlice;

export const fetchPickups = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findPickups(queryParams)
    .then((response) => {
      // console.log(response);
      // const { total_items, items } = response;
      dispatch(actions.pickupsFetched({ response }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find pickups";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchAllPickup = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllPickups(queryParams)
    .then((response) => {
      dispatch(actions.pickupAllFetched({ all: response.data.data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find pickups";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchPickup = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.pickupFetched({ pickupForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getPickupById(id)
    .then((response) => {
      dispatch(actions.pickupFetched({ pickupForEdit: response }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find pickup";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createPickup = (pickupForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createPickup(pickupForCreation)
    .then((res) => {
      // console.log(res);
      toast.success("Berhasil menambahkan admin!", toastOption);
      // const {data} = res;
      // dispatch(actions.pickupCreated({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create pickup";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updatePickup = (pickup) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updatePickup(pickup)
    .then(() => {
      toast.success("Successful updated Pickup!", toastOption);
      // dispatch(actions.pickupUpdated({ pickup }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update pickup";
      toast.error("Updated Pickup failed!", toastOption);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deletePickup = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deletePickup(id)
    .then((res) => {
      // console.log(res);
      toast.success("Successful deleted Pickup!", toastOption);
    })
    .catch((error) => {
      toast.error("Delete Pickup failed!", toastOption);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
