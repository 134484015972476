import React, { useEffect, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../../_redux/voucherActions';
import { CREATE_VOUCHER, UPDATE_VOUCHER } from '../../_redux/voucherCrud';
import { useMutation } from '@apollo/client';
import { VoucherEditDialogHeader } from './VoucherEditDialogHeader';
import { VoucherEditForm } from './VoucherEditForm';
import { initialValue } from '../VoucherUIHelpers';
import { toastOption } from '../../../../../_metronic/_helpers';
import { toast } from 'react-toastify';
import { LoadingAnimation } from '../loading-animation/LoadingAnimation';
import { useVoucherUIContext } from '../VoucherUIContext';

export const VoucherEditDialog = ({ id, show, onHide }) => {
  const voucherUIContext = useVoucherUIContext();
  const voucherUIProps = useMemo(() => {
    return {
      queryParams: voucherUIContext.queryParams,
    };
  });

  // Voucher Redux state
  const dispatch = useDispatch();
  const { actionsLoading, voucherForEdit } = useSelector((state) => state.voucher, shallowEqual);

  const [createVoucher, { loading: createLoading }] = useMutation(CREATE_VOUCHER, {
    onCompleted: (data) => {
      dispatch(actions.findVoucher({ findVoucherInput: voucherUIProps.queryParams }));
      toast.success('Voucher created successfully.', toastOption);

      onHide();
    },
    onError: (data) => {
      toast.error('Failed create Voucher ', toastOption);
    },
  });

  const [updateVoucher, { loading: updateLoading }] = useMutation(UPDATE_VOUCHER, {
    onCompleted: (data) => {
      dispatch(actions.findVoucher({ findVoucherInput: voucherUIProps.queryParams }));
      toast.success('Voucher updated successfully.', toastOption);
      onHide();
    },
    onError: (data) => {
      toast.error('Failed update Voucher ', toastOption);
      console.log(data);
    },
  });

  useEffect(() => {
    // server call for getting Voucher by id
    dispatch(actions.getVoucherById(id));
  }, [id, dispatch]);

  // server request for saving voucher
  const saveVoucher = (params) => {
    if (!id) {
      const { uuid, ...input } = params;

      createVoucher({
        variables: {
          createVoucherInput: input,
        },
      });
    } else {
      updateVoucher({
        variables: {
          updateVoucherInput: params,
        },
      });
    }
  };

  return (
    <>
      <LoadingAnimation open={createLoading || updateLoading} />
      <Modal size="lg" show={show} onHide={onHide} aria-labelledby="example-modal-sizes-title-lg">
        <VoucherEditDialogHeader id={id} />
        <VoucherEditForm
          saveVoucher={saveVoucher}
          actionsLoading={actionsLoading}
          voucher={voucherForEdit || initialValue}
          onHide={onHide}
        />
      </Modal>
    </>
  );
};
