import React, { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { Card, CardBody, LoadingDialog } from '../../../../../_metronic/_partials/controls';
import { OrderSelectForm } from './OrderSelectForm';
import { OrderReturnGoodsForm } from './OrderReturnGoodsForm';
import { CREATE_ORDER_RETURN, GET_ORDER } from '../../_redux/order/orderCrud';
import { OrderReturnContextProvider } from './context';
import { OrderReturnGoodsTable } from './OrderReturnGoodsTable';
import { useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { toastOption } from '../../../../../_metronic/_helpers';
import { Modal } from 'react-bootstrap';
import { getOrderReturnInitialValues } from './OrderReturnUIHelpers';
import { LoadingAnimation } from '../../../MasterData/pages/brand/loading-animation/LoadingAnimation';

const OrderGoodsSchema = yup.object().shape({
  product_sku_uuid: yup.string().required('Sku Produk wajib dipilih.'),
  price: yup.string().required('Harga wajib diisi.'),
  qty: yup.string().required('Jumlah wajib diisi.'),
  notes: yup.string().required('Catatan wajib diisi..'),
});

const OrderReturnSchema = yup.object().shape({
  order_uuid: yup.string().required('Order wajib dipilih.'),
  invoice_no: yup.string().optional(),
  marketplace_invoice: yup.string().optional(),
  shipping_cost: yup.string().required('Shipping cost wajib diisi.'),
  shipping_awb: yup.string().required('Awb wajib diisi.'),
  goods_return: yup.array().of(OrderGoodsSchema).optional(),
  goods_defect: yup.array().of(OrderGoodsSchema).optional(),
});

export const OrderReturnForm = ({ actionsLoading, orderReturn, submit }) => {
  const [orderItems, setOrderItems] = useState([]);
  const [returnGoods, setReturnGoods] = useState([]);
  const [defectGoods, setDefectGoods] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [fetchOrder, { loading }] = useLazyQuery(GET_ORDER, {
    onCompleted: (data) => {
      const { getOrder } = data ?? {};
      let order_item = [];

      if (getOrder.order_item instanceof Array) {
        order_item = [...getOrder.order_item];
      }

      order_item = order_item.reduce((items, oi) => {
        const { product_sku_uuid } = oi;

        const itemIndex = items.findIndex((item) => item.product_sku_uuid === product_sku_uuid);

        if (itemIndex > -1) {
          items[itemIndex].qty = +items[itemIndex].qty + +oi.qty;

          return items;
        }

        return [...items, { ...oi }];
      }, []);

      setOrderItems(order_item);
    },
    onError: (error) => {
      console.error('error', error);
    },
  });

  useEffect(() => {
    if (!orderReturn || !(orderReturn.return_items instanceof Array)) {
      return;
    }

    let { return_items } = orderReturn;

    const goods_return = [];
    const goods_defect = [];

    return_items.forEach((oi) => {
      const { product_sku_uuid, product_sku, qty, assigned_price, notes } = oi;
      const { sku, product } = product_sku ?? {};

      const item = {
        product_sku_uuid,
        sku,
        qty,
        notes,
        product_name: product.name ?? '',
        price: assigned_price,
      };

      if (notes && notes.startsWith('RETURN')) {
        return goods_return.push(item);
      }

      if (notes && notes.startsWith('DEFECT')) {
        return goods_defect.push(item);
      }
    });

    setReturnGoods(goods_return);
    setDefectGoods(goods_defect);
  }, [orderReturn]);

  function addOrderItemQty(product_sku_uuid, qty) {
    setOrderItems((prev) => {
      const currentOrderItems = prev.map((item) => ({ ...item }));

      const oiIndex = prev.findIndex((oi) => oi.product_sku_uuid === product_sku_uuid);

      if (oiIndex > -1) {
        currentOrderItems[oiIndex].qty = +currentOrderItems[oiIndex].qty + +qty;
      }

      return currentOrderItems;
    });
  }

  function addReturnGoods(values) {
    setReturnGoods((prev) => {
      const { product_sku_uuid, qty, price } = values;

      const duplIndex = prev.findIndex((item) => item.product_sku_uuid === product_sku_uuid);

      if (duplIndex > -1) {
        const newItems = [...prev];

        const duplItem = prev[duplIndex];

        duplItem.qty = +duplItem.qty + +qty;
        duplItem.price = +price;

        return newItems;
      }

      return [...prev, values];
    });
    addOrderItemQty(values.product_sku_uuid, -Math.abs(+values.qty));
  }

  function addDefectGoods(values) {
    setDefectGoods((prev) => {
      const { product_sku_uuid, qty, price } = values;

      const duplIndex = prev.findIndex((item) => item.product_sku_uuid === product_sku_uuid);

      if (duplIndex > -1) {
        const newItems = [...prev];

        const duplItem = prev[duplIndex];

        duplItem.qty = +duplItem.qty + +qty;
        duplItem.price = +price;

        return newItems;
      }

      return [...prev, values];
    });
    addOrderItemQty(values.product_sku_uuid, -Math.abs(+values.qty));
  }

  function removeReturnGoods(product_sku_uuid, qty) {
    setReturnGoods((prev) => prev.filter((item) => item.product_sku_uuid !== product_sku_uuid));
    addOrderItemQty(product_sku_uuid, Math.abs(+qty));
  }

  function removeDefectGoods(product_sku_uuid, qty) {
    setDefectGoods((prev) => prev.filter((item) => item.product_sku_uuid !== product_sku_uuid));
    addOrderItemQty(product_sku_uuid, Math.abs(+qty));
  }

  const contextValues = {
    getOrder: (order_id) => fetchOrder({ variables: { order_uuid: order_id } }),
    orderItems,
  };

  function submitOrderReturn(values) {
    const input = { ...values };

    input.goods_return = [...returnGoods];
    input.goods_defect = [...defectGoods];

    submit(input);
  }

  return (
    <div className="d-flex flex-column">
      <LoadingAnimation open={loading || actionsLoading} />
      <Formik
        enableReinitialize={true}
        initialValues={getOrderReturnInitialValues(orderReturn)}
        validationSchema={OrderReturnSchema}
        onSubmit={() => {
          setShowConfirmationModal(true);
        }}
      >
        {({ handleSubmit, values }) => (
          <OrderReturnContextProvider values={contextValues}>
            <OrderSelectForm />

            <OrderReturnGoodsForm title={'Barang Return'} addItem={addReturnGoods} />
            <OrderReturnGoodsTable
              title={'Daftar Barang Return'}
              items={returnGoods}
              removeItem={removeReturnGoods}
            />

            <OrderReturnGoodsForm title={'Barang Defect'} addItem={addDefectGoods} />
            <OrderReturnGoodsTable
              title={'Daftar Barang Defect'}
              items={defectGoods}
              removeItem={removeDefectGoods}
            />
            <Modal
              show={showConfirmationModal}
              onHide={() => setShowConfirmationModal(false)}
              centered
            >
              <Modal.Header>
                <Modal.Title>Konfirmasi Return Order</Modal.Title>
              </Modal.Header>
              <Modal.Body className="row">
                <div className="col">
                  <h2 className="h2">Anda yakin ingin meretur order ini ?</h2>
                </div>
              </Modal.Body>
              <Modal.Footer className="row">
                <div className="col d-flex justify-content-end">
                  <button
                    className="btn btn-secondary mr-3"
                    onClick={() => setShowConfirmationModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-warning"
                    style={{ width: '100px', height: '36px' }}
                    onClick={() => submitOrderReturn(values)}
                  >
                    {actionsLoading ? (
                      <CircularProgress color="inherit" size={14} />
                    ) : (
                      <span>Submit</span>
                    )}
                  </button>
                </div>
              </Modal.Footer>
            </Modal>
            <Card>
              <CardBody>
                <div className="row">
                  {orderReturn && orderReturn.uuid ? null : (
                    <div className="col d-flex justify-content-end">
                      <button
                        className="btn btn-primary d-flex justify-content-center align-items-center"
                        style={{ width: '100px', height: '36px' }}
                        onClick={handleSubmit}
                      >
                        {actionsLoading ? (
                          <CircularProgress color="inherit" size={14} />
                        ) : (
                          <span>Save</span>
                        )}
                      </button>
                    </div>
                  )}
                </div>
              </CardBody>
            </Card>
          </OrderReturnContextProvider>
        )}
      </Formik>
    </div>
  );
};
