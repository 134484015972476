import React, { useEffect, useState } from 'react';
import { TopSellerTable } from './Component/TopSellerTable';
import { TopCustomerTable } from './Component/TopCustomerTable';
import { TilesWelcomeWidget } from './Component/TilesWelcomeWidget';
import { TilesCustomerWidget } from './Component/TilesCustomerWidget';
import { TilesSellerWidget } from './Component/TilesSellerWidget';
import { TodayTransactionWidget } from './Component/TodayTransactionWidget';
import { MonthlyTransactionWidget } from './Component/MonthlyTransactionWidget';
import { getUserByToken } from '../Auth/_redux/authCrud';
import { GET_DAILY_SALES, GET_MONTHLY_SALES } from '../Order/_redux/order/orderCrud';
import { useLazyQuery, useQuery } from '@apollo/client';
import OverStockTable from './Component/OverStock/OverStockTable';
import { LimitedStockTable } from './Component/LimitedStock/LimitedStockTable';

export function DashboardPage() {
  const [ordersDaily, setOrdersDaily] = useState([]);
  const [ordersMonthly, setOrdersMonthly] = useState([]);

  const ordersDailyData = useQuery(GET_DAILY_SALES, {
    variables: {},
  });
  const ordersMonthlyData = useQuery(GET_MONTHLY_SALES, {
    variables: {},
  });

  useEffect(() => {
    getUserByToken();
  }, []);

  useEffect(() => {
    ordersDailyData.data &&
      ordersDailyData.data.getDailySalesByDateRange.items &&
      setOrdersDaily(ordersDailyData.data.getDailySalesByDateRange.items ?? []);
  }, [ordersDailyData.loading]);

  useEffect(() => {
    ordersMonthlyData.data &&
      ordersMonthlyData.data.getMonthlySalesByDateRange.items &&
      setOrdersMonthly(ordersMonthlyData.data.getMonthlySalesByDateRange.items ?? []);
  }, [ordersMonthlyData.loading]);
  return (
    <>
      {/* begin::Dashboard */}

      {/* begin::Row */}
      <div className="row">
        <div className="col-xl-6">
          <TilesWelcomeWidget className="gutter-b card-stretch" />
        </div>
        <div className="col-xl-6">
          <div className="row">
            <div className="col-xl-6">
              <TilesSellerWidget className="gutter-b" baseColor="primary" widgetHeight="150px" />
            </div>
            <div className="col-xl-6">
              <TilesCustomerWidget className="gutter-b" iconColor="dark" widgetHeight="150px" />
            </div>
          </div>
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className="row">
        <div className="col-12"></div>
        <div className="col-12">
          <TodayTransactionWidget
            className="gutter-b card-stretch"
            chartColor="info"
            orders={ordersDaily}
          />
        </div>
        <div className="col-12">
          <MonthlyTransactionWidget
            className="gutter-b card-stretch"
            chartColor="info"
            orders={ordersMonthly}
          />
        </div>
        <div className="col-12">
          <OverStockTable />
        </div>
        <div className="col-12">
          <LimitedStockTable />
        </div>
      </div>
      {/* end::Row */}
    </>
  );
}
