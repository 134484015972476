import React, { useMemo, useEffect, useState } from 'react';
import { Formik, Field, Form } from 'formik';
import { isEqual } from 'lodash';
import { useVoucherUIContext } from '../VoucherUIContext';
import { initialFilter } from '../VoucherUIHelpers';
import { IS_ACTIVE } from '../VoucherUIHelpers';
import ReactSelect from 'react-select';
import { VOUCHER_TYPES } from '../VoucherUIHelpers';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { voucherFilter } from '../../_redux/voucherActions';

const prepareFilter = (queryParams, values) => {
  const newQueryParams = { ...queryParams, ...values };

  return newQueryParams;
};

export const VoucherFilter = ({ listLoading, history }) => {
  const { filters } = useSelector(state => state.voucher, shallowEqual)
  const dispatch = useDispatch()
  // Voucher UI Context
  const voucherUIContext = useVoucherUIContext();
  const voucherUIProps = useMemo(() => {
    return {
      queryParams: voucherUIContext.queryParams,
      setQueryParams: voucherUIContext.setQueryParams,
    };
  }, [voucherUIContext]);

  const [pgOptions, setPgOptions] = useState([]);

  useEffect(() => {}, []);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(voucherUIProps.queryParams, values);
    if (!isEqual(newQueryParams, voucherUIProps.queryParams)) {
      newQueryParams.page_number = 1;
      // update list by queryParams
      voucherUIProps.setQueryParams(newQueryParams);
      dispatch(voucherFilter(newQueryParams))
    }
  };

  return (
    <>
      <Formik
        initialValues={filters}
        onSubmit={(values) => {
          applyFilter({...values, filterIsOpen: true});
          // console.log(values);
        }}
        onReset={() => {
          applyFilter({...initialFilter, filterIsOpen: false})
          history.push("/voucher")
        }}
      >
        {({ values, handleSubmit, handleBlur, handleChange, setFieldValue, resetForm }) => (
          <>
            <Form onSubmit={handleSubmit} className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-4">
                  <input
                    type="text"
                    className="form-control"
                    name="code"
                    placeholder="Cari Kode..."
                    onBlur={handleBlur}
                    value={values.code}
                    onChange={(e) => {
                      setFieldValue('code', e.target.value);
                    }}
                  />
                  <small className="form-text ">
                    Cari <b>Kode</b>
                  </small>
                </div>
                <div className="col-lg-4">
                  <ReactSelect
                    name="type"
                    placeholder="Pilih Tipe..."
                    options={VOUCHER_TYPES.map((item) => ({
                      label: item.name,
                      value: item.value,
                    }))}
                    value={
                      values.type
                        ? {
                            label: values.type,
                            value: values.type,
                          }
                        : null
                    }
                    onChange={(e) => {
                      setFieldValue('type', e.value);
                    }}
                  />
                  <small className="form-text ">
                    Cari <b>Tipe</b>
                  </small>
                </div>
                <div className="col-lg-4">
                  <ReactSelect
                    name="publish_status"
                    placeholder="Pilih Status Publish..."
                    options={[
                      { label: 'PUBLISHED', value: 'PUBLISHED' },
                      { label: 'UNPUBLISHED', value: 'UNPUBLISHED' },
                    ]}
                    value={
                      values.publish_status
                        ? {
                            label: values.publish_status,
                            value: values.publish_status,
                          }
                        : null
                    }
                    onChange={(e) => {
                      setFieldValue('publish_status', e.value);
                    }}
                  />
                  <small className="form-text ">
                    Cari <b>Status Publish</b>
                  </small>
                </div>
              </div>
              <button
                type="submit"
                onClick={handleSubmit}
                className="btn btn-primary btn-elevate float-right mb-20 ml-4"
              >
                Terapkan
              </button>
              <button
                type="button"
                onClick={() => {
                  resetForm();
                  handleSubmit();
                }}
                className="btn btn-danger btn-elevate float-right mb-20 ml-8"
              >
                Hapus Pencarian
              </button>
              <hr className="mt-25" />
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
