import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '../SvgIcon'
import { defaultFill } from '../constant'

const ChevronUp = props => (
  <SvgIcon {...props}>
    <path fill={props.fill} className="custom-fill" d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z" />
  </SvgIcon>
)

ChevronUp.propTypes = {
  fill: PropTypes.string,
}

ChevronUp.defaultProps = {
  fill: defaultFill,
}

export default ChevronUp
