export const UrlRouterStatusCssClasses = ["danger", "success", "info", ""];
export const UrlRouterStatusTitles = ["Suspended", "Active", "Pending", ""];
export const UrlRouterTypeCssClasses = ["success", "primary", ""];
export const UrlRouterTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "created_at", order: "desc" }];
export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
];
export const initialFilter = {
  slug: "",
  hit_start: null,
  hit_end: null,
  sortOrder: "desc", // asc||desc
  sortField: "created_at",
  page_number: 1,
  page_size: 10,
};

export let initialValue = {
  slug: "",
  json_value: "",
};

export const status = [
  {
    name: "Aktif",
    value: "ACTIVE",
  },
  {
    name: "Tidak Aktif",
    value: "INACTIVE",
  },
];

export const category = [
  {
    name: "Global",
    value: "GLOBAL",
  },
  {
    name: "Individu",
    value: "INDIVIDU",
  },
];

export const badge = (status) => {
  let data = {};
  if (status === "ACTIVE") data = { text: "ACTIVE", type: "success" };
  else data = { text: "INACTIVE", type: "warning" };

  return data;
};
