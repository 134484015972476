import React, { useEffect } from 'react';
import { Formik, Field } from 'formik';
import ReactSelect from 'react-select';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import { Input } from '../../../../../../_metronic/_partials/controls';
import { CircularProgress } from '@material-ui/core';
import { useLazyQuery } from '@apollo/client';
import { FIND_MEMBER_LEVELS } from '../../../../MasterData/_redux/member-level/memberLevelCrud';

const MemberSchema = Yup.object().shape({
  uuid: Yup.string().optional(),
  name: Yup.string().min(3).required('Nama minimal berisi 3 karakter'),
  email: Yup.string().test('email', 'Email/Hp wajib diisi.', function (value) {
    const { hp } = this.parent;

    if (!hp) {
      return Boolean(value);
    }

    return true;
  }),
  hp: Yup.string().test('hp', 'Email/Hp wajib diisi.', function (value) {
    const { email } = this.parent;

    if (!email) {
      return Boolean(value);
    }

    return true;
  }),
  member_level_code: Yup.string().optional(),
  dropship_name: Yup.string().optional(),
  dropship_hp: Yup.string().optional(),
  password: Yup.string().optional(),
});

export const CreateMemberModal = ({ show, onHide, isLoading, submitCreateMember }) => {
  return (
    <Modal size="md" show={show} onHide={onHide} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Buat Member Baru</Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: '',
          email: '',
          hp: '',
        }}
        validationSchema={MemberSchema}
        onSubmit={(values) => {
          submitCreateMember(values);
        }}
      >
        {({ handleSubmit, values, setFieldValue, errors }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Body className="overlay overlay-block cursor-default">
              <div className="form-group">
                <div className="col">
                  <Field
                    name="name"
                    component={Input}
                    placeholder="Dennis Ritchie..."
                    label="Nama"
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="col">
                  <Field
                    name="email"
                    component={Input}
                    placeholder="dmr@gmail.com..."
                    label="Email"
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="col">
                  <Field name="hp" component={Input} placeholder="0898989989" label="Nomor Hp" />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button type="button" onClick={onHide} className="btn btn-light btn-elevate">
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary btn-elevate"
                style={{ width: '75px' }}
              >
                {isLoading ? <CircularProgress color="inherit" size={14} /> : <span>Save</span>}
              </button>
            </Modal.Footer>
          </form>
        )}
      </Formik>
    </Modal>
  );
};
