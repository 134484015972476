export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
];

export const initialParams = {
  page_number: 1,
  page_size: 10,
  sort_field: "stock",
  sort_order: "DESC",
};
