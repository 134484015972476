import React, { Suspense, lazy, forwardRef } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout';
import { BuilderPage } from './pages/BuilderPage';
import { MyPage } from './pages/MyPage';
import { DashboardPage } from './modules/Dashboard/DashboardPage';
import { PickupPage } from './modules/PickupRequest/pages/PickupPage';
import { ProductCreate } from './modules/Products2/pages/product-create/ProductForm';
import { ProductEdit } from './modules/Products2/pages/product-edit/ProductForm';
import { ProductPage } from './modules/Products2/pages/ProductPage';
import { StoryPage } from './modules/Story/pages/StoryPage';
import { StoryEditDialog } from './modules/Story/pages/story-edit/StoryEdit';
import { OrderPage } from './modules/Order/pages/OrderPage';
import { PurchasePage } from './modules/Purchase/pages/PurchasePage';
import { PurchaseEdit } from './modules/Purchase/pages/purchase-edit/PurchaseEdit';
import { Permissions, PrivateRoute } from '../_metronic/_helpers';
import { MemberPage } from './modules/Member/pages/MemberPage';
import { MemberEdit } from './modules/Member/pages/member-edit/MemberEdit';
import { createUploadLink } from 'apollo-upload-client';
import { API } from './config/UrlEndPoint';
import store from '../redux/store';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  ApolloLink,
  concat,
} from '@apollo/client';
import { PackingEdit } from './modules/Packing/pages/packing-form/PackingEdit';
import UserProfilePage from './modules/UserProfile/UserProfilePage';
import { NotificationPage } from './modules/NotificationsUser/pages/NotificationPage';
import { OrderReportPage } from './modules/OrderReport/pages/OrderReportPage';
import { NotificationEditDialog } from './modules/NotificationsUser/pages/notification-edit/NotificationEdit';
import { CampaignPage } from './modules/Campaign/page/CampaignPage';
import { MarketplaceOrderPage } from './modules/MarketplaceOrder/pages/MarketplaceOrderPage';
import { MarketplaceOrderEditDialog } from './modules/MarketplaceOrder/pages/marketplaceOrder-edit/MarketplaceOrderEdit';
import ProjectDetail from './modules/MasterData/pages/project/project-detail/ProjectDetail';
import ProductSkuStock from './modules/Products2/product-sku-stock/ProductSkuStock';
import { MarketplaceTransactionPage } from './modules/MarketplaceTransaction/pages/MarketplaceTransactionPage';
import { OrderLogisticPage } from './modules/OrderLogistic/pages/OrderLogisticPage';
import { MarketplaceTransactionList } from './modules/MarketplaceTransaction/pages/marketplaceTransaction-list/MarketplaceTransactionList';
import { SynchronizeMarketplacePage } from './modules/SynchronizeMarketplace/pages/SynchronizeMarketplacePage';
import { SynchronizeMarketplaceEditDialog } from './modules/SynchronizeMarketplace/pages/synchronizeMarketplace-edit/SynchronizeMarketplaceEdit';
import { MarketplaceTransactionEditDialog } from './modules/MarketplaceTransaction/pages/marketplaceTransaction-edit/MarketplaceTransactionEdit';
import { VoucherPage } from './modules/Voucher/page/VoucherPage';
import { RoleChecker } from './RoleChecker';
import { OrderFormPage } from './modules/Order/pages/order-form-baru/OrderFormPage';
import { OrderReturnPage } from './modules/Order/pages/order-return-form/OrderReturnPage';
const MasterDataPage = lazy(() => import('./modules/MasterData/pages/masterDataPage'));

export default function BasePage() {
  const {
    auth: { authToken },
  } = store.getState();

  const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: `Bearer ${authToken}` || null,
      },
    }));

    return forward(operation);
  });

  const client = new ApolloClient({
    link: concat(
      authMiddleware,
      createUploadLink({
        uri: API,
      })
    ),
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {
            /* Redirect from root URL to /dashboard. */
            <Redirect exact from="/" to="/dashboard" />
          }
          <ContentRoute path="/dashboard" component={DashboardPage} />

          <ContentRoute path="/builder" component={BuilderPage} />
          <ContentRoute path="/my-page" component={MyPage} />

          <PrivateRoute
            role={RoleChecker('read', 'campaign')}
            component={CampaignPage}
            path="/campaign"
            exact
          />
          <PrivateRoute
            role={RoleChecker('read', 'voucher')}
            component={VoucherPage}
            path="/voucher"
            exact
          />

          <PrivateRoute
            role={RoleChecker('read', 'order')}
            component={MarketplaceOrderPage}
            path="/marketplace-order"
            exact
          />
          <PrivateRoute
            role={RoleChecker('update', 'order')}
            component={MarketplaceOrderEditDialog}
            path="/marketplace-order/:id/edit"
            exact
          />

          <PrivateRoute
            role={RoleChecker('read', 'import_transaction')}
            component={MarketplaceTransactionPage}
            path="/marketplace-transaction"
            exact
          />
          <PrivateRoute
            role={RoleChecker('create', 'import_transaction')}
            component={MarketplaceTransactionEditDialog}
            path="/marketplace-transaction/new"
            exact
          />
          <PrivateRoute
            role={RoleChecker('update', 'import_transaction')}
            component={MarketplaceTransactionList}
            path="/marketplace-transaction/:id/list"
            exact
          />

          <PrivateRoute
            role={RoleChecker('read', 'synchronize_marketplace')}
            component={SynchronizeMarketplacePage}
            path="/marketplace-synchronize"
            exact
          />
          <PrivateRoute
            role={RoleChecker('update', 'synchronize_marketplace')}
            component={SynchronizeMarketplaceEditDialog}
            path="/marketplace-synchronize/:invoice"
          />
          {/* <PrivateRoute role={true} component={SynchronizeMarketplaceEditDialog} path="/marketplace-synchronize?invoice_no=" exact /> */}

          <PrivateRoute
            role={RoleChecker('read', 'member')}
            component={MemberPage}
            path="/member"
            exact
          />
          <PrivateRoute
            role={RoleChecker('create', 'member')}
            component={MemberEdit}
            path="/member/new"
            exact
          />
          <PrivateRoute
            role={RoleChecker('update', 'member')}
            component={MemberEdit}
            path="/member/:id/edit"
            exact
          />

          <PrivateRoute
            role={RoleChecker('read', 'order_report')}
            component={OrderReportPage}
            path="/report-order"
            exact
          />
          <PrivateRoute
            role={RoleChecker('read', 'notification')}
            component={NotificationPage}
            path="/notification-user"
            exact
          />
          <PrivateRoute
            role={RoleChecker('create', 'notification')}
            component={NotificationEditDialog}
            path="/notification-user/new"
            exact
          />
          {/* <PrivateRoute role={true} component={NotificationEditDialog} path="/notification-user/:id/edit" exact /> */}

          <PrivateRoute
            role={RoleChecker('read', 'order')}
            component={OrderPage}
            path="/order"
            exact
          />
          <PrivateRoute
            role={RoleChecker('create', 'order')}
            component={OrderFormPage}
            path="/order/new"
            exact
          />
          <PrivateRoute
            role={RoleChecker('update', 'order')}
            component={OrderFormPage}
            path="/order/:id/edit"
            exact
          />

          <PrivateRoute
            role={RoleChecker('update', 'order')}
            component={OrderReturnPage}
            path="/order/return"
            exact
          />

          <PrivateRoute
            role={RoleChecker('update', 'order')}
            component={OrderReturnPage}
            path="/order/return/:id"
          />

          <PrivateRoute
            role={RoleChecker('read', 'order')}
            component={OrderPage}
            path="/order-application"
            exact
          />

          <PrivateRoute
            role={RoleChecker('read', 'order_logistic')}
            component={OrderLogisticPage}
            path="/order-logistic"
            exact
          />

          <PrivateRoute
            role={RoleChecker('read', 'packing')}
            component={PackingEdit}
            path="/packing"
            exact
          />

          <PrivateRoute role={true} component={ProjectDetail} path="/project-detail/:id" exact />

          <PrivateRoute
            role={RoleChecker('read', 'pickup_request')}
            component={PickupPage}
            path="/pickup-request"
            exact
          />

          <PrivateRoute
            role={RoleChecker('read', 'purchase')}
            component={PurchasePage}
            path="/purchase"
            exact
          />
          <PrivateRoute
            role={RoleChecker('create', 'purchase')}
            component={PurchaseEdit}
            path="/purchase/new"
            exact
          />
          <PrivateRoute
            role={RoleChecker('update', 'purchase')}
            component={PurchaseEdit}
            path="/purchase/:id/edit"
            exact
          />

          <PrivateRoute
            role={RoleChecker('read', 'story')}
            component={StoryPage}
            path="/story"
            exact
          />
          <PrivateRoute
            role={RoleChecker('create', 'story')}
            component={StoryEditDialog}
            path="/story/new"
            exact
          />
          <PrivateRoute
            role={RoleChecker('update', 'story')}
            component={StoryEditDialog}
            path="/story/:id/edit"
            exact
          />

          <PrivateRoute
            role={RoleChecker('read', 'product')}
            component={ProductPage}
            path="/product"
            exact
          />
          <PrivateRoute
            role={RoleChecker('create', 'product')}
            component={ProductCreate}
            path="/product/new"
            exact
          />
          <PrivateRoute
            role={RoleChecker('update', 'product')}
            component={ProductEdit}
            path="/product/:id/edit"
            exact
          />
          <PrivateRoute
            role={true}
            component={ProductSkuStock}
            path="/product-sku/:id/:product_name/:sku"
            exact
          />

          <PrivateRoute role={true} component={UserProfilePage} path="/profile" exact />

          {/*MASTER DATA*/}
          <PrivateRoute role={true} component={MasterDataPage} path="/master-data" />

          <Redirect to="error/error-v3" />
        </Switch>
      </Suspense>
    </ApolloProvider>
  );
}
