import React from "react";
import { MarketplaceOrderLoadingDialog } from "./marketplaceOrder-loading-dialog/MarketplaceOrderLoadingDialog";
import { MarketplaceOrderUIProvider } from "./MarketplaceOrderUIContext";
import { MarketplaceOrderCard } from "./MarketplaceOrderCard";

export function MarketplaceOrderPage({ history }) {
  const marketplaceOrderUIEvents = {
    newMarketplaceOrderButtonClick: () => {
      history.push("/marketplaceOrder-user/new");
    },
    openEditMarketplaceOrder: (id) => {
      history.push(`/marketplace-order/${id}/edit`);
    },
  };

  return (
    <MarketplaceOrderUIProvider
      marketplaceOrderUIEvents={marketplaceOrderUIEvents}
    >
      {/*<MarketplaceOrderLoadingDialog/>*/}
      <MarketplaceOrderCard history={history} />
    </MarketplaceOrderUIProvider>
  );
}
