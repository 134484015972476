import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '../SvgIcon'
import { defaultFill } from '../constant'

const Bank = props => (
  <SvgIcon {...props}>
    <path fill={props.fill} className="custom-fill" d="M11.5,1L2,6V8H21V6M16,10V17H19V10M2,22H21V19H2M10,10V17H13V10M4,10V17H7V10H4Z" />
  </SvgIcon>
)

Bank.propTypes = {
  fill: PropTypes.string,
}

Bank.defaultProps = {
  fill: defaultFill,
}

export default Bank
