/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers';
import { RoleChecker } from '../../../../../app/RoleChecker';
import LocalShipping from '@material-ui/icons/LocalShipping';
import StoreIcon from '@material-ui/icons/Store';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import SyncIcon from '@material-ui/icons/Sync';

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open `
      : '';
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li className={`menu-item ${getMenuItemActive('/dashboard', false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Home/Home.svg')} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/builder", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/builder">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
            </span>
            <span className="menu-text">Layout Builder</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}

        {/* Order */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Order</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/*begin::1 Level*/}
        {RoleChecker('read', 'order') && (
          <li className={`menu-item ${getMenuItemActive('/order')}`} aria-haspopup="true">
            <NavLink className="menu-link" to="/order">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl('/media/svg/icons/Home/Library.svg')} />
              </span>
              <span className="menu-text">Order</span>
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {RoleChecker('read', 'packing') && (
          <li className={`menu-item ${getMenuItemActive('/packing')}`} aria-haspopup="true">
            <NavLink className="menu-link" to="/packing">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Barcode.svg')} />
              </span>
              <span className="menu-text">Packing</span>
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {RoleChecker('read', 'pickup_request') && (
          <li className={`menu-item ${getMenuItemActive('/pickup-request')}`} aria-haspopup="true">
            <NavLink className="menu-link" to="/pickup-request">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl('/media/svg/icons/Home/Mailbox.svg')} />
              </span>
              <span className="menu-text">Pickup Request</span>
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {RoleChecker('read', 'order') && (
          <li
            className={`menu-item ${getMenuItemActive('/marketplace-order')}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/marketplace-order">
              <span className="svg-icon menu-icon">
                <StoreIcon />
              </span>
              <span className="menu-text">Marketplace Order</span>
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {RoleChecker('read', 'order_logistic') && (
          <li className={`menu-item ${getMenuItemActive('/order-logistic')}`} aria-haspopup="true">
            <NavLink className="menu-link" to="/order-logistic">
              <span className="svg-icon menu-icon">
                <LocalShipping />
              </span>
              <span className="menu-text">Logistic</span>
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {RoleChecker('read', 'import_transaction') && (
          <li
            className={`menu-item ${getMenuItemActive('/marketplace-transaction')}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/marketplace-transaction">
              <span className="svg-icon menu-icon">
                <ImportExportIcon />
              </span>
              <span className="menu-text">Import Transaction</span>
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {RoleChecker('read', 'synchronize_marketplace') && (
          <li
            className={`menu-item ${getMenuItemActive('/marketplace-synchronize')}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/marketplace-synchronize">
              <span className="svg-icon menu-icon">
                <SyncIcon />
              </span>
              <span className="menu-text">Synchronize Marketplace</span>
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {RoleChecker('read', 'order') && (
          <li className={`menu-item ${getMenuItemActive('/order/return')}`} aria-haspopup="true">
            <NavLink className="menu-link" to="/order/return">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Arrow-to-up.svg')} />
              </span>
              <span className="menu-text">Order Return</span>
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}

        {/* Purchase */}

        {/*begin::1 Level*/}
        {RoleChecker('read', 'purchase') && (
          <>
            {/* begin::section */}
            <li className="menu-section ">
              <h4 className="menu-text">Purchase</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
            {/* end:: section */}
            <li className={`menu-item ${getMenuItemActive('/purchase')}`} aria-haspopup="true">
              <NavLink className="menu-link" to="/purchase">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Price1.svg')} />
                </span>
                <span className="menu-text">Purchase</span>
              </NavLink>
            </li>
          </>
        )}
        {/*end::1 Level*/}

        {/* Product */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Product</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/*begin::1 Level*/}
        {RoleChecker('read', 'product') && (
          <li className={`menu-item ${getMenuItemActive('/product')}`} aria-haspopup="true">
            <NavLink className="menu-link" to="/product">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Bag2.svg')} />
              </span>
              <span className="menu-text">Product</span>
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {RoleChecker('read', 'category') && (
          <li
            className={`menu-item ${getMenuItemActive('/master-data/category')}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/master-data/category">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl('/media/svg/icons/Layout/Layout-left-panel-2.svg')} />
              </span>
              <span className="menu-text">Categories</span>
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li className={`menu-item menu-item-submenu`} aria-haspopup="true" data-menu-toggle="click">
          <NavLink className="menu-link menu-toggle" to="/master-data">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Cart1.svg')} />
            </span>
            <span className="menu-text">Product Setting</span>
            <i className="menu-arrow"></i>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Product Setting</span>
                </span>
              </li>

              {/*begin::2 Level*/}
              {RoleChecker('read', 'brand') && (
                <li
                  className={`menu-item ${getMenuItemActive('/master-data/brand')}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/master-data/brand">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Brand</span>
                  </NavLink>
                </li>
              )}
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              {/* {RoleChecker("read", "category") && (
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/master-data/category"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/master-data/category">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Categories</span>
                  </NavLink>
                </li>
              )} */}
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              {RoleChecker('read', 'project') && (
                <li
                  className={`menu-item ${getMenuItemActive('/master-data/project')}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/master-data/project">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Project</span>
                  </NavLink>
                </li>
              )}
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              {RoleChecker('read', 'supplier') && (
                <li
                  className={`menu-item ${getMenuItemActive('/master-data/supplier')}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/master-data/supplier">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Supplier</span>
                  </NavLink>
                </li>
              )}
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/* Promo */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Promo</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/*begin::1 Level*/}
        {RoleChecker('read', 'campaign') && (
          <li className={`menu-item ${getMenuItemActive('/campaign')}`} aria-haspopup="true">
            <NavLink className="menu-link" to="/campaign">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Sale1.svg')} />
              </span>
              <span className="menu-text">Campaign</span>
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {RoleChecker('read', 'voucher') && (
          <li className={`menu-item ${getMenuItemActive('/voucher')}`} aria-haspopup="true">
            <NavLink className="menu-link" to="/voucher">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Gift.svg')} />
              </span>
              <span className="menu-text">Voucher</span>
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {RoleChecker('read', 'story') && (
          <li className={`menu-item ${getMenuItemActive('/story')}`} aria-haspopup="true">
            <NavLink className="menu-link" to="/story">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl('/media/svg/icons/Files/Media-folder.svg')} />
              </span>
              <span className="menu-text">Story</span>
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {RoleChecker('read', 'notification') && (
          <li
            className={`menu-item ${getMenuItemActive('/notification-user')}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/notification-user">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl('/media/svg/icons/General/Notifications1.svg')} />
              </span>
              <span className="menu-text">Notifications</span>
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}

        {/* Member */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Member</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/*begin::1 Level*/}
        {RoleChecker('read', 'member') && (
          <li className={`menu-item ${getMenuItemActive('/member')}`} aria-haspopup="true">
            <NavLink className="menu-link" to="/member">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Add-user.svg')} />
              </span>
              <span className="menu-text">Member</span>
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {RoleChecker('read', 'member_level') && (
          <li
            className={`menu-item ${getMenuItemActive('/master-data/member-level')}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/master-data/member-level">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Add-user.svg')} />
              </span>
              <span className="menu-text">Member Level</span>
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}

        {/* Order Report */}

        {/*begin::1 Level*/}
        {RoleChecker('read', 'order_report') && (
          <>
            {/* begin::section */}
            <li className="menu-section ">
              <h4 className="menu-text">Laporan</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
            {/* end:: section */}
            <li className={`menu-item ${getMenuItemActive('/report-order')}`} aria-haspopup="true">
              <NavLink className="menu-link" to="/report-order">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Price1.svg')} />
                </span>
                <span className="menu-text">Laporan Order</span>
              </NavLink>
            </li>
          </>
        )}

        {/* Master Data */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Setting</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/*begin::1 Level*/}
        <li className={`menu-item menu-item-submenu`} aria-haspopup="true" data-menu-toggle="click">
          <NavLink className="menu-link menu-toggle" to="/master-data">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Money.svg')} />
            </span>
            <span className="menu-text">Transaction Setting</span>
            <i className="menu-arrow"></i>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Transaction Setting</span>
                </span>
              </li>

              {/*begin::2 Level*/}
              {RoleChecker('read', 'payment_gateway') && (
                <li
                  className={`menu-item ${getMenuItemActive('/master-data/payment-gateway')}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/master-data/payment-gateway">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Payment Gateway</span>
                  </NavLink>
                </li>
              )}
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              {RoleChecker('read', 'payment_method') && (
                <li
                  className={`menu-item ${getMenuItemActive('/master-data/payment-method')}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/master-data/payment-method">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Payment Method</span>
                  </NavLink>
                </li>
              )}
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              {RoleChecker('read', 'shipping_service') && (
                <li
                  className={`menu-item ${getMenuItemActive('/master-data/shipper-service')}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/master-data/shipper-service">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Shipping Service</span>
                  </NavLink>
                </li>
              )}
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              {RoleChecker('read', 'shipping_company') && (
                <li
                  className={`menu-item ${getMenuItemActive('/master-data/shipping-company')}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/master-data/shipping-company">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Shipping Company</span>
                  </NavLink>
                </li>
              )}
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              {RoleChecker('read', 'store') && (
                <li
                  className={`menu-item ${getMenuItemActive('/master-data/store')}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/master-data/store">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Store</span>
                  </NavLink>
                </li>
              )}
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li className={`menu-item menu-item-submenu`} aria-haspopup="true" data-menu-toggle="click">
          <NavLink className="menu-link menu-toggle" to="/master-data">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/General/User.svg')} />
            </span>
            <span className="menu-text">User Setting</span>
            <i className="menu-arrow"></i>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">User Setting</span>
                </span>
              </li>
              {/*begin::2 Level*/}
              {RoleChecker('read', 'admin') && (
                <li
                  className={`menu-item ${getMenuItemActive('/master-data/admin')}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/master-data/admin">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Admin</span>
                  </NavLink>
                </li>
              )}
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              {RoleChecker('read', 'role') && (
                <li
                  className={`menu-item ${getMenuItemActive('/master-data/role')}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/master-data/role">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Role</span>
                  </NavLink>
                </li>
              )}
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li className={`menu-item menu-item-submenu`} aria-haspopup="true" data-menu-toggle="click">
          <NavLink className="menu-link menu-toggle" to="/master-data">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/General/Settings-2.svg')} />
            </span>
            <span className="menu-text">Advance Setting</span>
            <i className="menu-arrow"></i>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Advance Setting</span>
                </span>
              </li>

              {/*begin::2 Level*/}
              {RoleChecker('read', 'branch') && (
                <li
                  className={`menu-item ${getMenuItemActive('/master-data/branch')}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/master-data/branch">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Branch</span>
                  </NavLink>
                </li>
              )}
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              {RoleChecker('read', 'dynamic_config') && (
                <li
                  className={`menu-item ${getMenuItemActive('/master-data/dynamic-config')}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/master-data/dynamic-config">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Dynamic Config</span>
                  </NavLink>
                </li>
              )}
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              {RoleChecker('read', 'url_router') && (
                <li
                  className={`menu-item ${getMenuItemActive('/master-data/url-router')}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/master-data/url-router">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Url Router</span>
                  </NavLink>
                </li>
              )}
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              {RoleChecker('read', 'version_platform') && (
                <li
                  className={`menu-item ${getMenuItemActive('/master-data/version-platform')}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/master-data/version-platform">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Version Platform</span>
                  </NavLink>
                </li>
              )}
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
