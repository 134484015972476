import { gql, useMutation } from "@apollo/client";
import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { toastOption } from "../../../../../_metronic/_helpers";
import * as actions from "../../_redux/products/productActions";
import { LoadingAnimation } from "../loading-animation/LoadingAnimation";
import { useProductUIContext } from "../ProductUIContext";
import { RatingTable } from "./RatingTable";
import {
  DISABLE_RATING,
  RESTORE_RATING,
} from "../../_redux/products/productCrud";

export function ProductRatingDialog({ id, show, onHide }) {
  // Product Redux state
  const dispatch = useDispatch();
  const { actionsLoading, productForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.product.actionsLoading,
      productForEdit: state.product.productForEdit,
    }),
    shallowEqual
  );

  const productUIContext = useProductUIContext();
  const productUIProps = useMemo(() => {
    return {
      queryParams: productUIContext.queryParams,
    };
  }, [productUIContext]);

  const [title, setTitle] = useState("");
  // Title couting
  useEffect(() => {
    setTitle(id);
    let _title = "";
    if (productForEdit && id) {
      _title = `Rating Product '${productForEdit.name}'`;
    }

    setTitle(_title);
    // eslint-disable-next-line
  }, [productForEdit, actionsLoading]);

  useEffect(() => {
    // server call for getting Product by id
    Object.assign(productUIProps.queryParams, { uuid: id });
    dispatch(actions.fetchProducts(productUIProps.queryParams));
    dispatch(actions.fetchProductsRating(productUIProps.queryParams));
  }, [id, dispatch]);

  const [disableReview, { loading: disableLoading }] = useMutation(
    DISABLE_RATING,
    {
      onCompleted: (data) => {
        Object.assign(productUIProps.queryParams, { uuid: id });
        dispatch(actions.fetchProductsRating(productUIProps.queryParams));
        toast.success("Berhasil nonaktifkan review!", toastOption);
      },
      onError: (data) => {
        toast.error("Gagal nonaktifkan review!", toastOption);
        console.log(data);
      },
    }
  );

  const [restoreReview, { loading: restoreLoading }] = useMutation(
    RESTORE_RATING,
    {
      onCompleted: (data) => {
        Object.assign(productUIProps.queryParams, { uuid: id });
        dispatch(actions.fetchProductsRating(productUIProps.queryParams));
        toast.success("Berhasil mengaktifkan review!", toastOption);
      },
      onError: (data) => {
        toast.error("Gagal mengaktifkan review!", toastOption);
        console.log(data);
      },
    }
  );

  const disable = (uuid) => {
    disableReview({
      variables: {
        uuid: uuid,
      },
    });
  };

  const restore = (uuid) => {
    restoreReview({
      variables: {
        uuid: uuid,
      },
    });
  };

  return (
    <>
      <LoadingAnimation open={disableLoading || restoreLoading} />
      <Modal
        size="xl"
        show={show}
        // onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RatingTable
            uuid={id}
            disableReview={disable}
            restoreReview={restore}
          />
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={onHide}
              className="btn btn-light btn-elevate"
            >
              Tutup
            </button>
            <> </>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
