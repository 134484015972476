import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '../SvgIcon'
import { defaultFill } from '../constant'

const ChevronDown = props => (
  <SvgIcon {...props}>
    <path fill={props.fill} className="custom-fill" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
  </SvgIcon>
)

ChevronDown.propTypes = {
  fill: PropTypes.string,
}

ChevronDown.defaultProps = {
  fill: defaultFill,
}

export default ChevronDown
