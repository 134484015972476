import { createSlice } from "@reduxjs/toolkit";
import { initialFilter } from "../../pages/payment-gateway/PGUIHelpers";

const initialPaymentGatewaysState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  paymentGatewayForEdit: undefined,
  lastError: null,
  filters: initialFilter
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const paymentGatewaySlice = createSlice({
  name: "paymentGateways",
  initialState: initialPaymentGatewaysState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getPaymentGatewayById
    paymentGatewayFetched: (state, action) => {
      state.actionsLoading = false;
      state.paymentGatewayForEdit = action.payload.paymentGatewayForEdit;
      state.error = null;
    },
    // findPaymentGateways
    paymentGatewaysFetched: (state, action) => {
      const { total_items, items } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = items;
      state.totalCount = total_items;
    },
    // getAllPaymentGateway
    paymentGatewayAllFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.all = action.payload.all;
    },
    // createPaymentGateway
    paymentGatewayCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.paymentGateway);
    },
    // updatePaymentGateway
    paymentGatewayUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.paymentGateway.id) {
          return action.payload.paymentGateway;
        }
        return entity;
      });
    },
    paymentGatewayFilter: (state, action) => {
      state.listLoading = false
      state.error = null
      state.filters = action.payload
    }
  },
});
