import React, { useState, useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/marketplaceOrder/marketplaceOrderActions";
import { MarketplaceOrderEditForm } from "./MarketplaceOrderForm";
import { useMarketplaceOrderUIContext } from "../MarketplaceOrderUIContext";
import { toast } from "react-toastify";
import { toastOption } from "../../../../../_metronic/_helpers";
import { useMutation, gql, useLazyQuery } from "@apollo/client";
import { LoadingAnimation } from "../loading-animation/LoadingAnimation";
import { initMarketplaceOrder, marketplaceOrder_type } from "../MarketplaceOrderUIHelpers";
import {
  ORDER_ITEM_AND_DELIVERY,
  UPDATE_ITEM_DELIVERY,
} from "../../_redux/marketplaceOrder/marketplaceOrderCrud";
import { Card } from "../../../../../_metronic/_partials/controls";
import { ORDER_RECEIPT } from "../../../Order/_redux/order/orderCrud";
import axios from "axios";
import moment from "moment";
import { getAdmin } from "../../../Auth/_redux/authCrud";
import { getDynamicConfigById } from "../../../MasterData/_redux/dynamic-config/dynamicConfigCrud";
import { CONFIG_HELPER } from "../../../../config/Helper";

export function MarketplaceOrderEditDialog({ id, history, match }) {
  const [marketplaceOrderValue, setMarketplaceOrderValue] = useState();
  // MarketplaceOrder Redux state
  const dispatch = useDispatch();
  const { actionsLoading, marketplaceOrderForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.marketplaceOrder.actionsLoading,
      marketplaceOrderForEdit: state.marketplaceOrder.marketplaceOrderForEdit,
    }),
    shallowEqual
  );

  // console.log(initValue);

  useEffect(() => {
    // server call for getting MarketplaceOrder by id
    dispatch(actions.fetchMarketplaceOrder(match.params.id));
  }, [id, dispatch]);

  const [createOrderItemDelivery, { loading: createLoading }] = useMutation(
    ORDER_ITEM_AND_DELIVERY,
    {
      onCompleted: () => {
        toast.success("Berhasil Menyimpan Order!", toastOption);
        // history.push("/marketplace-order");
        history.push(`/order/${match.params.id}/edit`);
      },
      onError: (data) => {
        toast.error("Gagal Menyimpan Order!", toastOption);
        console.log(data);
      },
    }
  );

  const [updateOrderItemDelivery, { loading: updateLoading }] = useMutation(UPDATE_ITEM_DELIVERY, {
    onCompleted: () => {
      toast.success("Berhasil update Order!");
      // history.push("/marketplace-order");
      history.push(`/order/${match.params.id}/edit`);
    },
    onError: (error) => {
      console.log(error);
      toast.error("Gagal update order!");
    },
  });

  const saveMarketplaceOrder = (orderDelivery, orderItem, orderInput) => {
    // console.log("orderItem", orderItem);
    // console.log("orderDelivery", orderDelivery);
    // console.log("orderInput", orderInput);
    // ! NYALAIN LAGI!!!
    if (marketplaceOrderForEdit.order_item.length > 0) {
      updateOrderItemDelivery({
        variables: {
          orderItemInput: orderItem,
          address: orderDelivery.address,
          city: orderDelivery.city,
          codepos: orderDelivery.codepos,
          order_uuid: orderDelivery.order_uuid,
          province: orderDelivery.province,
          receiver_hp: orderDelivery.receiver_hp,
          receiver_name: orderDelivery.receiver_name,
          sub_district: orderDelivery.sub_district,
          orderDeliveryUuid: orderDelivery.orderDeliveryUuid,
          awb: orderInput.awb,
          store: orderInput.store,
          status: orderInput.updateTo,
          trx_date: orderInput.trx_date,
        },
      });
    } else {
      createOrderItemDelivery({
        variables: {
          createOrderItemItems: orderItem,
          address: orderDelivery.address,
          city: orderDelivery.city,
          codepos: orderDelivery.codepos,
          order_uuid: orderDelivery.order_uuid,
          province: orderDelivery.province,
          receiver_hp: orderDelivery.receiver_hp,
          receiver_name: orderDelivery.receiver_name,
          sub_district: orderDelivery.sub_district,
          orderDeliveryUuid: orderDelivery.orderDeliveryUuid,
          awb: orderInput.awb,
          store_uuid: orderInput.store,
          status: orderInput.updateTo,
          trx_date: orderInput.trx_date,
        },
      });
    }
  };

  // * Print Receipt

  const [userAdmin, setuserAdmin] = useState({});
  const user = useSelector(({ auth }) => auth.user, shallowEqual);

  const [promo, setPromo] = useState({
    text: "",
    url: "",
  });

  const admin = () => {
    getAdmin()
      .then((res) => {
        setuserAdmin(res);
      })
      .catch((err) => console.log(err));
    dynamicPromo();
  };

  useEffect(() => {
    admin();
    return () => {};
  }, [user]);

  const dynamicPromo = () => {
    getDynamicConfigById(CONFIG_HELPER.promo_text)
      .then((res) =>
        setPromo((prev) => ({
          ...prev,
          text: res.value_config,
        }))
      )
      .catch((err) => console.log(err));

    getDynamicConfigById(CONFIG_HELPER.promo_url)
      .then((res) =>
        setPromo((prev) => ({
          ...prev,
          url: res.value_config,
        }))
      )
      .catch((err) => console.log(err));
  };

  const [printLoading, setPrintLoading] = useState(false);

  const [getOrderReceipt] = useLazyQuery(ORDER_RECEIPT, {
    onCompleted: async ({ getOrder: order }) => {
      const { branch, member, order_delivery, order_item } = order;

      if (!order) {
        toast.error("Unable to fetch order");
        return;
      }

      let items = [];
      order_item.forEach((item) => {
        let strVariant = "";
        if (
          item.product_sku_snapshot.variant_value_1 &&
          item.product_sku_snapshot.variant_value_1 != "UMUM"
        ) {
          strVariant = ` - ${item.product_sku_snapshot.variant_value_1}`;
        }
        if (
          item.product_sku_snapshot.variant_value_2 &&
          item.product_sku_snapshot.variant_value_2 != "UMUM"
        ) {
          strVariant += ` - ${item.product_sku_snapshot.variant_value_2}`;
        }
        items.push({
          product_name: item.product_snapshot.name + strVariant,
          sku: item.product_sku_snapshot.sku,
          price: +item.product_sku_snapshot.price_before,
          // price: item.product_sku_snapshot.price,
          weight: +item.product_snapshot.weight * +item.qty,
          qty: +item.qty,
          price_discount: +item.product_sku_snapshot.price_before - +item.assigned_price,
        });
        // if (item.assigned_price != item.product_sku_snapshot.price) {
        //   items.push({
        //     product_name: "",
        //     sku: "Hemat",
        //     // price: +item.product_sku.price,
        //     price: +item.product_sku_snapshot.price - +item.assigned_price,
        //     weight: +item.product_sku_snapshot.product.weight,
        //     qty: +item.qty,
        //   });
        // }
      });

      const data = {
        header: {
          sale_hid: order.invoice_no,
          officer: userAdmin.name,
          is_dropship: order.is_dropship,
          dropship_name: order.is_dropship ? order.dropship_name : null,
          dropship_hp: order.is_dropship ? order.dropship_hp : null,
          date: moment(order.created_at).format("YYYY-MM-DD HH:mm:ss"),
          warehouse: branch.name,
          shipping_cost: order.order_delivery ? order.order_delivery.shipping_cost : 0,
          shipping_name: order_delivery && order_delivery.receiver_name,
          shipping_hp: order_delivery && order_delivery.receiver_hp,
          shipping_address: order_delivery ? order_delivery.address : "",

          shipping_address_2: order_delivery
            ? `${order_delivery.sub_district}, ${order_delivery.city}, ${order_delivery.province}`
            : "",
          promo_text: promo.text,
          promo_url: promo.url,
        },
        items,
      };

      try {
        const response = await axios({
          method: "POST",
          url: "https://thermal-print.gayalo.com/print",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        });

        toast.success("Print Success");
      } catch (error) {
        toast.error(error.message);
      } finally {
        setPrintLoading(false);
      }
    },
    onError: (error) => {
      toast.error(error.message);
      setPrintLoading(false);
    },
    fetchPolicy: "cache-and-network",
  });

  const printOrderReceipt = () => {
    if (marketplaceOrderForEdit.uuid && !printLoading) {
      setPrintLoading(true);
      getOrderReceipt({ variables: { uuid: marketplaceOrderForEdit.uuid } });
    } else {
      setPrintLoading(false);
    }
  };

  return (
    <>
      <LoadingAnimation open={createLoading || updateLoading} />
      <Card>
        <MarketplaceOrderEditForm
          history={history}
          match={match}
          // marketplaceOrderInitValue={marketplaceOrderForEdit || {}}
          marketplaceOrderInitValue={(!actionsLoading && marketplaceOrderForEdit) || {}}
          saveMarketplaceOrder={saveMarketplaceOrder}
          printReceipt={printOrderReceipt}
          isPrinting={printLoading}
        />
      </Card>
    </>
  );
}
