export function getOrderReturnInitialValues(orderReturn) {
  let {
    uuid = '',
    order_uuid = '',
    shipping_cost = '',
    shipping_awb = '',
    order = {},
  } = orderReturn ?? {};

  const values = {
    uuid: uuid ?? '',
    order_uuid: order_uuid ?? '',
    invoice_no: order.invoice_no ?? '',
    marketplace_invoice: order.marketplace_invoice ?? '',
    shipping_cost: shipping_cost ?? '',
    shipping_awb: shipping_awb ?? '',
  };

  return values;
}
