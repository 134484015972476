import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Formik, Field } from 'formik';
import { Modal } from 'react-bootstrap';
import { Input } from '../../../../../../_metronic/_partials/controls';

export const UpdateBasicModal = ({ show, onHide, actionsLoading, initialValues, submit }) => {
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ ...initialValues }}
      onSubmit={(values) => {
        submit(values);
      }}
    >
      {({ handleSubmit, errors }) => (
        <Modal size="lg" show={show} onHide={onHide} centered>
          <Modal.Header>
            <Modal.Title>Update Data Dasar</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group row">
              <div className="col-md-12 mb-5 mb-md-0">
                <label htmlFor="store_name">Store</label>
                <Field id="store_name" name="store_name" disabled={true} component={Input} />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-md-4 mb-5 mb-md-0">
                <label htmlFor="invoice_no">Invoice</label>
                <Field id="invoice_no" name="invoice_no" disabled={true} component={Input} />
              </div>
              <div className="col-md-4 mb-5 mb-md-0">
                <label htmlFor="trx_date">Trx Date</label>
                <Field
                  id="trx_date"
                  name="trx_date"
                  type="date"
                  disabled={false}
                  component={Input}
                />
              </div>
              <div className="col-md-4 mb-5 mb-md-0">
                <label htmlFor="marketplace_invoice">Marketplace Invoice</label>
                <Field
                  id="marketplace_invoice"
                  name="marketplace_invoice"
                  disabled={false}
                  component={Input}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              style={{ width: '75px' }}
              className="btn btn-secondary d-flex justify-content-center align-items-center"
              onClick={onHide}
            >
              <span>Cancel</span>
            </button>
            <button
              style={{ width: '75px' }}
              className="btn btn-primary d-flex justify-content-center align-items-center"
              onClick={() => !actionsLoading && handleSubmit()}
            >
              {actionsLoading ? <CircularProgress color="inherit" size={16} /> : <span>Save</span>}
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  );
};
