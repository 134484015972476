/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
/* eslint-disable no-unused-vars*/
import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  Input,
  CardFooter,
} from '../../../../../_metronic/_partials/controls';
import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import * as Yup from 'yup';
import { ORDER_ONLINE } from '../MarketplaceOrderUIHelpers';
import { useLazyQuery, gql } from '@apollo/client';
import { FETCH_TOKO, GET_ADDRESS } from '../../../Order/_redux/order/orderCrud';
import { CircularProgress } from '@material-ui/core';
import { RoleChecker } from '../../../../RoleChecker';

const SEARCH_PRODUCTS = gql`
  query Query(
    $product_name_or_sku: String
    $sort_field: String
    $sort_order: String
    $stock_start: Int
  ) {
    findProduct(
      findProductInput: {
        product_name_or_sku: $product_name_or_sku
        page_number: 1
        page_size: 100
        sort_field: $sort_field
        sort_order: $sort_order
        stock_start: $stock_start
      }
    ) {
      items {
        uuid
        name
        description
        is_deleted
        weight
        brand {
          name
        }
        product_sku {
          stock
          price
          price_before
          sku
          uuid
          variant_value_1
          variant_value_2
          image {
            url
          }
        }
      }
    }
  }
`;

export function MarketplaceOrderEditForm({
  history,
  saveMarketplaceOrder,
  marketplaceOrderInitValue,
  match,
  printReceipt,
  isPrinting,
}) {
  const { order_snapshot, member } = marketplaceOrderInitValue;

  const [statusUpdate, setStatusUpdate] = useState();

  const memberInfo = !member ? {} : member;
  const initValue = !marketplaceOrderInitValue ? {} : marketplaceOrderInitValue;
  const orderSnapshot = !order_snapshot ? {} : order_snapshot;
  const orderItems =
    marketplaceOrderInitValue.order_item && marketplaceOrderInitValue.order_item.length > 0
      ? marketplaceOrderInitValue.order_item
      : !orderSnapshot.order_item
      ? []
      : orderSnapshot.order_item;

  const MarketplaceOrderSchema = Yup.object().shape({
    province: Yup.string().required('Provinsi wajib diisi'),
    city: Yup.string().required('Kota wajib diisi'),
    sub_district: Yup.string().required('Kecamatan wajib diisi'),
    codepos: Yup.string().required('Kode Pos wajib diisi'),
  });

  const backToList = () => {
    history.push('/marketplace-order');
  };

  // * Fetch Product
  const [currentTimeout, setCurrentTimeout] = useState(null);
  const [currentCallback, setCurrentCallback] = useState(null);
  const [currentProducts, setCurrentProducts] = useState(null);

  const [fetchProductOptions] = useLazyQuery(SEARCH_PRODUCTS, {
    variables: { name: '' },
    onCompleted: (data) => {
      if (!data) return;

      const {
        findProduct: { items },
      } = data;

      if (items.length === 0) return currentCallback([]);

      const skus = items.reduce(
        (prev, { name, product_sku, description, is_deleted, brand, supplier, weight }) => [
          ...prev,
          ...product_sku.map(
            ({
              uuid,
              variant_value_1,
              variant_value_2,
              sku,
              price,
              price_before,
              stock,
              image,
            }) => {
              // let stock = branch_stock
              //   .filter((item) => item.stock > 0)
              //   .reduce((prev, curr) => curr.stock + prev, 0);
              return {
                // label: `${name} ${variant_value_1 || ""} ${
                //   variant_value_2 || ""
                // } (${stock})`,
                label: `${sku} - ${name} - ${variant_value_1 || ''} ${
                  variant_value_2 || ''
                } (${stock})`,
                value: uuid,
                price,
                sku,
                price_before,
                image,
                variant_value_1,
                variant_value_2,
                description,
                is_deleted,
                brand,
                supplier,
                weight,
              };
            }
          ),
        ],
        []
      );

      setCurrentProducts(skus);

      currentCallback(skus);
    },
    fetchPolicy: 'cache-and-network',
  });

  function loadProductOptions(inputValue, callback) {
    clearTimeout(currentTimeout);

    if (inputValue.length >= 3) {
      setCurrentTimeout(
        setTimeout(() => {
          fetchProductOptions({
            variables: {
              product_name_or_sku: inputValue,
              sort_order: 'DESC',
              sort_field: 'total_stock',
              stock_start: 1,
            },
          });
          setCurrentCallback(() => callback);
        }, 250)
      );
    } else {
      callback([]);
    }
  }

  // * Find Store
  const [tokoOptions, setTokoOptions] = useState([]);
  const [fetchToko, { loading }] = useLazyQuery(FETCH_TOKO, {
    onCompleted: (data) => {
      // console.log(data);
      setTokoOptions(data.findStore.items);
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    fetchToko({
      variables: {
        is_active: true,
        is_online: true,
      },
    });
  }, []);

  const orderData = (values) => {
    const {
      address,
      city,
      codepos,
      province,
      receiver_name,
      receiver_hp,
      shipping_cost,
      sub_district,
      awb,
      store,
      trx_date,
    } = values;

    const delveryInput = {
      address,
      city,
      codepos,
      province,
      receiver_name,
      receiver_hp,
      order_uuid: match.params.id,
      orderDeliveryUuid: marketplaceOrderInitValue.order_delivery.uuid,
      shipping_cost,
      sub_district,
    };

    const orderInput = {
      awb,
      store,
      updateTo: statusUpdate,
      trx_date,
    };

    let orderArray = [];
    if (marketplaceOrderInitValue.order_item.length > 0) {
      // ? UPDATE ORDER
      for (const [index, item] of marketplaceOrderInitValue.order_item.entries()) {
        let data = {};
        data['order_uuid'] = match.params.id;
        data['product_sku_uuid'] = values[`product_sku_uuid_${index}`];
        data['assigned_price'] = values[`assigned_price_${index}`];
        data['qty'] = values[`qty_${index}`] ?? item.qty;
        data['uuid'] = item.uuid;
        orderArray.push(data);
      }
    } else {
      // ? CREATE ORDER
      for (const [index, item] of orderSnapshot.order_item.entries()) {
        let data = {};
        data['order_uuid'] = match.params.id;
        data['product_sku_uuid'] = values[`product_sku_uuid_${index}`];
        data['assigned_price'] = values[`assigned_price_${index}`] ?? item.price;
        data['qty'] = values[`qty_${index}`] ?? item.qty;
        orderArray.push(data);
      }
    }

    saveMarketplaceOrder(delveryInput, orderArray, orderInput);
  };

  // * Search Address
  const [codeposOptions, setCodeposOptions] = useState([]);
  const [searchAddress] = useLazyQuery(GET_ADDRESS, {
    variables: {
      keyword: '',
    },
    onCompleted: (data) => {
      const { findAddressSuggestion } = data;

      if (findAddressSuggestion.items instanceof Array) {
        currentCallback(
          findAddressSuggestion.items.map(
            ({
              // uuid,
              city,
              province_name,
              district,
              postal_codes,
            }) => ({
              label: `${city}, ${district}, ${province_name}`,
              // value: uuid,
              city,
              province_name,
              district,
              postal_codes,
            })
          )
        );
      }
    },
    fetchPolicy: 'cache-and-network',
  });

  function loadAddressOptions(inputValue, callback) {
    clearTimeout(currentTimeout);

    if (inputValue.length >= 1) {
      setCurrentTimeout(
        setTimeout(() => {
          searchAddress({ variables: { keyword: inputValue } });
          setCurrentCallback(() => callback);
        }, 250)
      );
    } else {
      callback([]);
    }
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        validationSchema={MarketplaceOrderSchema}
        onSubmit={(values) => orderData(values)}
        initialValues={{
          is_online: initValue.is_online,
          is_online_label: `${initValue.is_online}`,
          trx_date: new Date(initValue.trx_date).toLocaleDateString('fr-CA'),
          branch_uuid: initValue.branch && initValue.branch.uuid,
          branch_name: initValue.branch && initValue.branch.name,
          store: initValue.store && initValue.store.uuid,
          store_label:
            initValue.store && `${initValue.store.market_name} - ${initValue.store.platform}`,
          marketplace_invoice: initValue.marketplace_invoice,
          invoice_no: initValue.invoice_no,
          member_uuid: memberInfo.uuid,
          member_name: memberInfo.name,
          address: orderSnapshot.order_delivery && orderSnapshot.order_delivery.address,
          receiver_name: orderSnapshot.order_delivery && orderSnapshot.order_delivery.receiver_name,
          receiver_hp: orderSnapshot.order_delivery && orderSnapshot.order_delivery.receiver_hp,
          service_name: orderSnapshot.order_delivery && orderSnapshot.order_delivery.service_name,
          shipping_cost: orderSnapshot.order_delivery && orderSnapshot.order_delivery.shipping_cost,
          province:
            initValue.order_delivery && initValue.order_delivery.province
              ? initValue.order_delivery.province
              : '',
          city:
            initValue.order_delivery && initValue.order_delivery.city
              ? initValue.order_delivery.city
              : '',
          sub_district:
            initValue.order_delivery && initValue.order_delivery.sub_district
              ? initValue.order_delivery.sub_district
              : '',
          codepos:
            initValue.order_delivery && initValue.order_delivery.codepos
              ? initValue.order_delivery.codepos
              : '',
          awb: initValue.order_delivery && initValue.order_delivery.awb,
        }}
      >
        {({ handleSubmit, setFieldValue, values, errors }) => (
          <>
            <Card>
              <CardHeader title="Data Dasar">
                <CardHeaderToolbar>
                  <button className="btn btn-secondary" onClick={backToList}>
                    <i className="fa fa-arrow-left"></i>
                    Kembali
                  </button>
                </CardHeaderToolbar>
              </CardHeader>
              <CardBody>
                <div className="form-group row">
                  <div className="col-lg-4">
                    <label htmlFor="is_online">Order Online</label>
                    <Field
                      name="is_online"
                      id="is_online"
                      component={ReactSelect}
                      isDisabled={true}
                      value={{
                        label: values.is_online_label,
                        value: values.is_online,
                      }}
                      placeholder="Pilih Order Online..."
                    />
                  </div>
                  <div className="col-lg-4">
                    <label htmlFor="trx_date">Trx Date</label>
                    <Field
                      id="trx_date"
                      name="trx_date"
                      type="date"
                      // disabled
                      // value={values.trx_date}
                      component={Input}
                      placeholder="Nama Produk"
                      onChange={(e) => setFieldValue('trx_date', e.target.value)}
                    />
                  </div>
                  <div className="col-lg-4">
                    <label htmlFor="branch">Cabang</label>
                    <AsyncSelect
                      id="branch"
                      cacheOptions={false}
                      name="branch_uuid"
                      placeholder="Cari Cabang..."
                      isDisabled
                      value={{
                        label: values.branch_name,
                        value: values.branch_uuid,
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-4">
                    <label htmlFor="store">Toko</label>
                    <Field
                      name="toko_uuid"
                      id="store"
                      component={ReactSelect}
                      options={tokoOptions.map((item) => ({
                        value: item.uuid,
                        label: `${item.market_name} - ${item.platform}`,
                      }))}
                      value={{
                        label: values.store_label,
                        value: values.store,
                      }}
                      placeholder="Pilih Toko..."
                      onChange={(e) => {
                        setFieldValue('store', e.value);
                        setFieldValue('store_label', e.label);
                      }}
                    />
                  </div>
                  <div className="col-lg-4">
                    <label htmlFor="marketplace_invoice">Marketplace Invoice</label>
                    <Field
                      id="marketplace_invoice"
                      name="marketplace_invoice"
                      component={Input}
                      disabled
                      placeholder="Marketplace Invoice..."
                    />
                  </div>
                  <div className="col-lg-4">
                    <label htmlFor="invoice_no">Invoice Number</label>
                    <Field
                      id="invoice_no"
                      name="invoice_no"
                      disabled
                      component={Input}
                      placeholder="Invoice Number..."
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardHeader title="Data Pelanggan">
                <CardHeaderToolbar>
                  <AsyncSelect
                    id="member"
                    cacheOptions={false}
                    name="member_uuid"
                    placeholder="Cari Member..."
                    isDisabled
                    value={{
                      label: values.member_name,
                      value: values.member_uuid,
                    }}
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        width: '250px',
                        border: errors.member_uuid
                          ? '1px solid rgb(246, 78, 96)'
                          : '1px solid #ddd',
                        marginRight: '1em',
                      }),
                      dropdownIndicator: (provided, state) => ({
                        ...provided,
                        color: errors.member_uuid ? 'rgb(246, 78, 96)' : '#ddd',
                      }),
                    }}
                  />
                </CardHeaderToolbar>
              </CardHeader>
              <CardBody>
                <div className="d-flex flex-column">
                  <div className="row m-0 p-0">
                    <div className="col-4 col-md-2">Nama</div>
                    <div className="col-8 col-md-10">
                      <span>:</span>
                      <span className="ml-3">{memberInfo.name}</span>
                    </div>
                    <div className="col-4 col-md-2">Email</div>
                    <div className="col-8 col-md-10">
                      <span>:</span>
                      <span className="ml-3">{memberInfo.email}</span>
                    </div>
                    <div className="col-4 col-md-2">Hp</div>
                    <div className="col-8 col-md-10">
                      <span>:</span>
                      <span className="ml-3">{memberInfo.hp}</span>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardHeader title="Pengiriman"></CardHeader>
              <CardBody>
                <div className="form-group row">
                  <div className="col-lg-4">
                    <Field
                      name="receiver_name"
                      component={Input}
                      className="form-control"
                      disabled
                      label="Penerima"
                    />
                  </div>
                  <div className="col-lg-4">
                    <Field
                      name="receiver_hp"
                      component={Input}
                      className="form-control"
                      disabled
                      label="No Hp"
                    />
                  </div>
                  <div className="col-lg-4">
                    <label htmlFor="address">Alamat</label>
                    <Field
                      className="form-control"
                      name="address"
                      component="textarea"
                      rows="5"
                      id="address"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-8">
                    <label>Kota / Kecamatan *</label>
                    <AsyncSelect
                      id="address_suggestion"
                      cacheOptions={false}
                      name="address_suggestion"
                      placeholder="Cari Kota / Kecamatan..."
                      noOptionsMessage={({ inputValue }) =>
                        inputValue.length >= 1
                          ? 'Kota / Kecamatan tidak di temukan'
                          : 'Ketik untuk mencari Kota / Kecamatan'
                      }
                      loadOptions={loadAddressOptions}
                      defaultOptions={false}
                      value={
                        values.city
                          ? {
                              label: `${values.city}, ${values.sub_district}, ${values.province}`,
                              value: null,
                            }
                          : null
                      }
                      onChange={({ city, province_name, district, postal_codes }) => {
                        setFieldValue('city', city);
                        setFieldValue('province', province_name);
                        setFieldValue('sub_district', district);
                        setFieldValue('codepos', '');
                        setCodeposOptions(postal_codes);
                      }}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          border: errors.branch_uuid
                            ? '1px solid rgb(246, 78, 96)'
                            : '1px solid #ddd',
                        }),
                        dropdownIndicator: (provided, state) => ({
                          ...provided,
                          color: errors.branch_uuid ? 'rgb(246, 78, 96)' : '#ddd',
                        }),
                      }}
                    />
                  </div>
                  <div className="col-lg-4">
                    <label htmlFor="codepos">Kode Pos *</label>
                    <CreatableSelect
                      id="codepos"
                      name="codepos"
                      type="number"
                      isDisabled={!values.city && true}
                      options={codeposOptions.map((code) => ({
                        label: code,
                        value: code,
                      }))}
                      value={{
                        label: values.codepos,
                        value: values.codepos,
                      }}
                      placeholder="Kode Pos..."
                      onChange={(e) => {
                        setFieldValue('codepos', e.value);
                      }}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardHeader title="Biaya Pengiriman" />
              <CardBody>
                <div className="row m-0 mb-5 p-0">
                  <div className="col-md-6 col-lg-4 mb-3">
                    <label htmlFor="shipping_service_uuid">Shipping Service</label>
                    <Field
                      id="shipping_service_uuid"
                      name="shipping_service_uuid"
                      component={ReactSelect}
                      isDisabled
                      value={{
                        label: values.service_name,
                        value: values.service_name,
                      }}
                    />
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <label htmlFor="shipping_cost">Shipping Cost</label>
                    <Field
                      id="shipping_cost"
                      type="number"
                      name="shipping_cost"
                      // disabled
                      component={Input}
                    />
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <label htmlFor="awb">AWB</label>
                    <Field id="awb" name="awb" component={Input} placeholder="Masukkan AWB..." />
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardHeader title="Order Barang" />
              <CardBody>
                {orderItems.map((item, index) => (
                  <>
                    <div className="form-group row">
                      <div className="col-lg-8">
                        <Field
                          name="product_name"
                          component={Input}
                          value={
                            item.uuid
                              ? item.product_sku &&
                                item.product_sku.product &&
                                item.product_sku.product.name
                              : item.name
                          }
                          disabled
                          label="Produk"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-4">
                        <label>SKU</label>
                        <AsyncSelect
                          cacheOptions={false}
                          name="product_sku_uuid"
                          placeholder="Product Name"
                          noOptionsMessage={({ inputValue }) => {
                            if (inputValue.length >= 3) return 'No options';
                            else return 'Type 3 or more characters';
                          }}
                          loadOptions={loadProductOptions}
                          defaultOptions={false}
                          defaultValue={{
                            label:
                              item.product_sku && item.product_sku.sku ? item.product_sku.sku : '',
                            value: item.product_sku_uuid,
                          }}
                          onChange={({
                            label,
                            value,
                            sku,
                            price,
                            price_before,
                            stock,
                            image,
                            variant_value_1,
                            variant_value_2,
                            description,
                            is_deleted,
                            brand,
                            supplier,
                            weight,
                          }) => {
                            setFieldValue(`product_sku_uuid_${index}`, value);
                            setFieldValue(`product_name_${index}`, label);
                            setFieldValue(`sku_${index}`, sku);
                            // setFieldValue(`price_${index}`, price);
                            // setFieldValue(`assigned_price_${index}`, price);
                            setFieldValue(`price_before_${index}`, price_before);
                            setFieldValue(`stock_${index}`, stock);
                            setFieldValue(`image_${index}`, image);
                            setFieldValue(`variant_value_1_${index}`, variant_value_1);
                            setFieldValue(`variant_value_2_${index}`, variant_value_2);
                            setFieldValue(`description_${index}`, description);
                            setFieldValue(`is_deleted_${index}`, is_deleted);
                            setFieldValue(`brand_${index}`, brand);
                            setFieldValue(`supplier_${index}`, supplier);
                            setFieldValue(`weight_${index}`, weight);
                          }}
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              border: errors.product_sku_uuid
                                ? '1px solid rgb(246, 78, 96)'
                                : '1px solid #ddd',
                            }),
                            dropdownIndicator: (provided, state) => ({
                              ...provided,
                              color: errors.product_sku_uuid ? 'rgb(246, 78, 96)' : '#ddd',
                            }),
                          }}
                        />
                      </div>
                      <div className="col-lg-4">
                        <Field
                          name={`assigned_price_${index}`}
                          component={Input}
                          defaultValue={item.uuid ? item.product_sku.price : item.price}
                          // value={
                          //   item.uuid ? item.product_sku.price : item.price
                          // }
                          type="number"
                          // disabled
                          label="Harga"
                          onChange={(e) =>
                            setFieldValue(`assigned_price_${index}`, parseInt(e.target.value))
                          }
                        />
                      </div>
                      <div className="col-lg-4">
                        <Field
                          name={`qty_${index}`}
                          component={Input}
                          defaultValue={item.qty}
                          // value={item.qty}
                          type="number"
                          // disabled
                          label="Qty"
                          onChange={(e) => setFieldValue(`qty_${index}`, parseInt(e.target.value))}
                        />
                      </div>
                    </div>
                    <hr
                      style={{
                        border: '0',
                        borderTop: '1px solid rgba(0, 0, 0, 0.1)',
                      }}
                    />
                  </>
                ))}
              </CardBody>
            </Card>
            <CardFooter>
              {RoleChecker('update', 'order') && (
                <button
                  type="submit"
                  className="btn btn-secondary ml-3 float-right"
                  onClick={() => {
                    setStatusUpdate('DRAFT');
                    handleSubmit();
                  }}
                >
                  Save to DRAFT
                </button>
              )}
            </CardFooter>
          </>
        )}
      </Formik>
    </>
  );
}
