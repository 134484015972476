import React, { useContext } from 'react';
import { OrderFormContext } from '../FormContext';
import { useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { ORDER_STATUS } from '../../OrderUIHelpers';
import { OrderPaymentLog } from './OrderPaymentLog';
import { OrderPaymentVerification } from './OrderPaymentVerification';
import { OrderTrackingLog } from './OrderTrackingLog';
import { OrderLogsTimeline } from './OrderLogsTimeline';

const FIND_ORDER_LOGS = gql`
  query ($order_uuid: String!) {
    findOrderLog(findOrderInput: { order_uuid: $order_uuid }) {
      items {
        uuid
        status
        description
        created_at
      }
    }
  }
`;

export const OrderLogsContainer = () => {
  // * Hooks
  // * Contexts
  const { order } = useContext(OrderFormContext);

  // * States
  const [orderLogs, setOrderLogs] = useState([]);

  // * Queries
  const [fetchOrderLogs] = useLazyQuery(FIND_ORDER_LOGS, {
    onCompleted: (data) => {
      const { findOrderLog } = data ?? {};
      const { items = [] } = findOrderLog;

      setOrderLogs(items);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  // * Effects
  useEffect(() => {
    if (order && order.uuid) {
      fetchOrderLogs({ variables: { order_uuid: order.uuid } });
    }
  }, [order]);

  // * Functions

  if (!order) {
    return null;
  }

  return (
    <>
      <OrderPaymentLog order={order} logs={orderLogs} />
      <OrderLogsTimeline order={order} logs={orderLogs} />
      {orderLogs && orderLogs.some(({ status }) => status === ORDER_STATUS.SENDING) && (
        <OrderTrackingLog order={order} />
      )}
    </>
  );
};
