import React, { useState, useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { CREATE_ORDER_RETURN, GET_ORDER_RETURN } from '../../_redux/order/orderCrud';

import { OrderReturnForm } from './OrderReturnForm';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toastOption } from '../../../../../_metronic/_helpers';

export const OrderReturnPage = () => {
  const { id } = useParams();
  const history = useHistory();

  const [orderReturn, setOrderReturn] = useState(null);

  const [fetchOrderReturn, { entityLoading }] = useLazyQuery(GET_ORDER_RETURN, {
    onCompleted: (data) => {
      const { getOrderReturn } = data ?? {};

      setOrderReturn(getOrderReturn);
    },
    onError: (error) => {
      toast.error(error, toastOption);
    },
  });
  const [fetchCreateOrderReturn, { loading: createLoading }] = useMutation(CREATE_ORDER_RETURN, {
    onCompleted: () => {
      toast.success('Success', toastOption);

      history.go(0);
    },
    onError: (error) => {
      console.log('error', error);
      toast.error(error, toastOption);
    },
  });

  useEffect(() => {
    if (id) {
      fetchOrderReturn({
        variables: {
          order_return_uuid: id,
        },
      });
    }
  }, [id]);

  function submitOrderReturn(values) {
    console.log('submit values', values);

    if (createLoading) {
      return;
    }

    let { order_uuid, shipping_cost, shipping_awb, goods_return, goods_defect } = values;

    goods_return = goods_return.map(({ product_sku_uuid, price, notes, qty }) => ({
      product_sku_uuid,
      price,
      notes,
      qty,
    }));
    goods_defect = goods_defect.map(({ product_sku_uuid, price, notes, qty }) => ({
      product_sku_uuid,
      price,
      notes,
      qty,
    }));

    const payload = {
      order_uuid,
      shipping_cost: +shipping_cost,
      shipping_awb,
      goods_return,
      goods_defect,
    };

    fetchCreateOrderReturn({
      variables: {
        createOrderReturnInput: payload,
      },
    });
  }

  return (
    <div className="d-flex flex-column">
      <OrderReturnForm
        actionsLoading={entityLoading || createLoading}
        orderReturn={orderReturn}
        submit={submitOrderReturn}
      />
    </div>
  );
};
