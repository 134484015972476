// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import Select from 'react-select';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../../redux/pickup-request/pickupActions';
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from '../../../../../_metronic/_helpers';
import * as uiHelpers from '../PickupUIHelpers';
import * as columnFormatters from './column-formatters';
import { CardFooter, Input, Pagination } from '../../../../../_metronic/_partials/controls';
import { usePickupUIContext } from '../PickupUIContext';
import { getSelectRow } from '../../../../../_metronic/_helpers';
import { Field, Formik } from 'formik';
import ReactSelect from 'react-select';
import * as branchCrud from '../../../MasterData/_redux/branch/branchCrud';
import * as companyCrud from '../../../MasterData/_redux/shipping-company/shippingCompanyCrud';
import * as configCrud from '../../../MasterData/_redux/dynamic-config/dynamicConfigCrud';
import { DatePicker, Space, TimePicker } from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import { CONFIG_HELPER } from '../../../../config/Helper';
import { LoadingAnimation } from '../../../../components/loading-animation/LoadingAnimation';
import { useLazyQuery } from '@apollo/client';
import { FIND_RPX_SHIPMENT } from '../../redux/pickup-request/pickupCrud';
import { RoleChecker } from '../../../../RoleChecker';

export function PickupTable({ pickupRequest, history }) {
  // Pickup UI Context
  const pickupUIContext = usePickupUIContext();
  const pickupUIProps = useMemo(() => {
    return {
      ids: pickupUIContext.ids,
      setIds: pickupUIContext.setIds,
      queryParams: pickupUIContext.queryParams,
      setQueryParams: pickupUIContext.setQueryParams,
      openEditPickupDialog: pickupUIContext.openEditPickupDialog,
      openDeletePickupDialog: pickupUIContext.openDeletePickupDialog,
      openDisablePickupDialog: pickupUIContext.openDisablePickupDialog,
    };
  }, [pickupUIContext]);

  // Getting curret state of pickup list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.pickupRequest }),
    shallowEqual
  );
  const { entities, listLoading } = currentState;

  useEffect(() => {
    getDefaultConfig();
  }, []);
  // * Get default config
  const [defaultBranch, setDefaultBranch] = useState();
  const getDefaultConfig = () => {
    configCrud.getDynamicConfigById(CONFIG_HELPER.default_branch_uuid).then((res) =>
      branchCrud.getBranchById(res.value_config).then((res) => {
        pickupUIProps.setQueryParams({
          ...pickupUIProps.queryParams,
          branch_uuid: res.uuid,
        });
        setDefaultBranch(res);
      })
    );
  };

  const [shipments, setShipments] = useState([]);
  const [fetchRpxShipments] = useLazyQuery(FIND_RPX_SHIPMENT, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const { findRpxShipment: items } = data ?? {};

      if (items instanceof Array) {
        setShipments(items);
      }
    },
    onError: (error) => {
      console.error(error);
    },
  });

  // Pickup Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    fetchRpxShipments({ variables: pickupUIProps.queryParams });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickupUIProps.queryParams]);

  // * Fetch Branch & Company Options
  const [branchOptions, setBranchOptions] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  useEffect(() => {
    // ? Branch
    branchCrud
      .findBranchs({
        page_number: 1,
        page_size: 1000,
      })
      .then((res) => setBranchOptions(res.items))
      .catch((err) => console.log(err));

    // ? Company
    companyCrud
      .findShippingCompanys({
        page_number: 1,
        page_size: 1000,
      })
      .then((res) => setCompanyOptions(res.items));
  }, []);

  // * Table
  const [shippingArray, setShippingArray] = useState([]);

  const addShipping = (params) => {
    let data = [...shippingArray];
    if (typeof params === 'string') {
      data.push(params);
    } else {
      params.forEach((item) => {
        data.push(item.uuid);
      });
    }
    // data.push(uuid);
    setShippingArray(data);
  };

  const removeShipping = (params) => {
    let data = [...shippingArray];
    const index = shippingArray.findIndex((uuid) => uuid === params);

    data.splice(index, 1);
    setShippingArray(data);
  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect === true) {
        addShipping(row.uuid);
      } else {
        removeShipping(row.uuid);
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      if (isSelect === true) {
        addShipping(rows);
      } else {
        setShippingArray([]);
      }
    },
  };
  // Table columns
  const columns = [
    {
      dataField: 'order.member.name',
      text: 'Name',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: 'order.invoice_no',
      text: 'Invoice Number',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: 'awb',
      text: 'AWB',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      formatter: (cell, row, rowIndex) => {
        return (
          <span className={`label label-lg label-light-warning label-inline`}>{row.status}</span>
        );
      },
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: 'tot_package',
      text: 'Total Package',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: 'tot_weight',
      text: 'Total Weight',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: 'tot_declare_value',
      text: 'Total Declare value',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: 'service_snapshot.service',
      text: 'Service',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    // totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: pickupUIProps.queryParams.page_size,
    page: pickupUIProps.queryParams.page_number,
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
  };

  const pickupData = (value) => {
    const utcOffset = new Date().getTimezoneOffset() / 60;

    const data = {
      branch: value.branch,
      utc_offset: utcOffset < 0 ? utcOffset * -1 : utcOffset,
      shipping_uuid: shippingArray,
    };
    // history.push("/pickup-request");
    pickupRequest(data);
  };

  return (
    <>
      <LoadingAnimation open={listLoading} />
      <Formik
        enableReinitialize={true}
        initialValues={{
          branch: defaultBranch && defaultBranch.uuid,
          branch_label: defaultBranch && defaultBranch.name,
        }}
        onSubmit={(values) => {
          pickupData(values);
        }}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <>
            <div className="form-group row">
              <div className="col-lg-4">
                <label htmlFor="branch">Cabang</label>
                <Field
                  name="branch"
                  component={ReactSelect}
                  options={branchOptions.map((item) => ({
                    value: item.uuid,
                    label: item.name,
                  }))}
                  value={
                    values.branch
                      ? {
                          label: values.branch_label,
                          value: values.branch,
                        }
                      : null
                  }
                  onChange={(e) => {
                    setFieldValue('branch', e.value);
                    setFieldValue('branch_label', e.label);
                    pickupUIProps.setQueryParams({
                      ...pickupUIProps.queryParams,
                      branch_uuid: e.value,
                    });
                  }}
                />
              </div>
              <div className="col-lg-4">
                <label htmlFor="branch">Status</label>
                <Select
                  name="status"
                  isClearable
                  placeholder="Pilih status..."
                  value={
                    values.status
                      ? {
                          label: values.status,
                          value: values.status,
                        }
                      : {
                          label: uiHelpers.RPX_SHIPMENT_STATUS.WAITING_CONFIRMATION,
                          values: uiHelpers.RPX_SHIPMENT_STATUS.WAITING_CONFIRMATION,
                        }
                  }
                  options={Object.keys(uiHelpers.RPX_SHIPMENT_STATUS).map((item) => ({
                    label: uiHelpers.RPX_SHIPMENT_STATUS[item],
                    value: uiHelpers.RPX_SHIPMENT_STATUS[item],
                  }))}
                  onChange={({ value }) => {
                    setFieldValue('status', value);

                    pickupUIProps.setQueryParams({
                      ...pickupUIProps.queryParams,
                      status: value,
                    });
                  }}
                />
              </div>
            </div>
            <>
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="uuid"
                data={shipments}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                selectRow={selectRow}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </>
            <CardFooter>
              <CardFooter>
                {RoleChecker('update', 'pickup_request') && (
                  <button
                    type="button"
                    className="btn btn-primary btn-elevate float-right"
                    onClick={() => handleSubmit()}
                  >
                    Request Pickup
                  </button>
                )}
              </CardFooter>
            </CardFooter>
          </>
        )}
      </Formik>
    </>
  );
}
