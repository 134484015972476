import * as requestFromServer from "./memberLevelCrud";
import { memberLevelSlice, callTypes } from "./memberLevelSlice";
import { toastOption } from "../../../../../_metronic/_helpers";
import { toast } from "react-toastify";

const { actions } = memberLevelSlice;

export const fetchMemberLevels = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findMemberLevels(queryParams)
    .then((response) => {
      const { total_items, items } = response;

      dispatch(actions.memberLevelsFetched({ total_items, items }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find member levels";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchMemberLevel = (id) => (dispatch) => {
  if (!id) {
    return dispatch(
      actions.memberLevelFetched({ memberLevelForEdit: undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getMemberLevelById(id)
    .then((response) => {
      dispatch(actions.memberLevelFetched({ memberLevelForEdit: response }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find member level";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createMemberLevel = (memberLevelForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createMemberLevel(memberLevelForCreation)
    .then((res) => {
      fetchMemberLevels();
      toast.success("Successful add new Member Level!", toastOption);
    })
    .catch((error) => {
      error.clientMessage = "Can't create member level";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateMemberLevel = (memberLevel) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateMemberLevel(memberLevel)
    .then(() => {
      fetchMemberLevels();
      toast.success("Successful updated Member Level!", toastOption);
    })
    .catch((error) => {
      error.clientMessage = "Can't update member level";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteMemberLevel = (code) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteMemberLevel(code)
    .then(() => {
      fetchMemberLevels();
    })
    .catch((error) => {
      error.clientMessage = "Can't delete member level";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const memberLevelFilter = params => dispatch => {
  dispatch(actions.startCall({ callTypes: callTypes.list }))
  dispatch(actions.memberLevelFilter(params))
}
