import React, { useState, useEffect, useContext } from 'react';
import { Field, useFormikContext } from 'formik';
import { OrderFormContext } from './FormContext';
import { Input } from '../../../../../_metronic/_partials/controls';
import AsyncSelect from 'react-select/async';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from '../../../../../_metronic/_partials/controls';
import * as moment from 'moment';

import { useHistory } from 'react-router-dom';
import { Form, Modal } from 'react-bootstrap';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CREATE_TOKO, FIND_STORE } from '../../../MasterData/_redux/store/storeCrud';
import { CreateStoreModal } from './modals/CreateStoreModal';
import { CircularProgress } from '@material-ui/core';
import { ORDER_STATUS } from '../OrderUIHelpers';
import { UpdateBasicModal } from './modals/UpdateBasicModal';
import { UPDATE_ORDER_BASIC } from '../../_redux/order/orderCrud';
import { toast } from 'react-toastify';
import { toastOption } from '../../../../../_metronic/_helpers';
import { useCLazyQuery } from '../../../../utility/global.util';

export const OrderBasicForm = () => {
  // * Hooks
  const history = useHistory();

  // * Contexts
  const { order } = useContext(OrderFormContext);
  const { values, errors, setFieldValue, touched } = useFormikContext();

  // * States
  const [showStoreModal, setShowStoreModal] = useState(false);
  const [createStoreCallback, setCreateStoreCallback] = useState(() => () => null);
  const [storeOptions, setStoreOptions] = useState([]);
  const [currentTimeout, setCurrentTimeout] = useState(null);
  const [currentCallback, setCurrentCallback] = useState(null);
  const [actionsLoading, setActionsLoading] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  // * Queries
  const [fetchStores] = useLazyQuery(FIND_STORE, {
    onCompleted: (data) => {
      const { findStore } = data ?? {};
      const { items = [] } = findStore;

      if (items instanceof Array) {
        const options = items.map((store) => {
          const { uuid, platform, market_name } = store;

          return {
            label: `${market_name} - ${platform}`,
            value: uuid,
            ...store,
          };
        });

        setStoreOptions(options);

        if (currentCallback instanceof Function) {
          currentCallback(options);
        }
      }
    },
    onError: (error) => {
      console.error(error);
    },
    fetchPolicy: 'cache-and-network',
  });
  const [fetchUpdateOrderBasic, { loading: updateLoading }] = useMutation(UPDATE_ORDER_BASIC, {
    onCompleted(data) {
      const { updateOrder } = data ?? {};

      setFieldValue('trx_date', new Date(updateOrder.trx_date));
      setFieldValue('marketplace_invoice', updateOrder.marketplace_invoice);
      setShowUpdateModal(false);

      toast.success('Success', toastOption);
    },
    onError(error) {
      console.log('ERROR', error);
    },
  });
  const [fetchCreateStore, { loading: createStoreLoading }] = useMutation(CREATE_TOKO, {
    onCompleted: (data) => {
      const { createStore } = data ?? {};

      setFieldValue('store.uuid', createStore.uuid);
      setFieldValue('store.market_name', createStore.market_name);
      setFieldValue('store.platform', createStore.platform);

      setShowStoreModal(false);
    },
  });

  // * Effects
  useEffect(() => {
    fetchStores({
      variables: { is_active: true, is_online: values.is_online, page_number: 1, page_size: 50 },
    });

    if (!values.uuid) {
      setFieldValue('store.uuid', '');
      setFieldValue('store.market_name', '');
      setFieldValue('store.platform', '');
    }
  }, [values.is_online]);

  // * Functions
  function createStore(values) {
    fetchCreateStore({
      variables: {
        createStoreInput: { ...values },
      },
    });
  }

  function loadOptions(queryCaller, inputValue, callback) {
    clearTimeout(currentTimeout);

    if (inputValue.length >= 3) {
      setCurrentTimeout(
        setTimeout(() => {
          queryCaller({ variables: { market_name: inputValue } });
          setCurrentCallback(() => callback);
        }, 250)
      );
    } else {
      callback([]);
    }
  }

  function updateOrderBasic(values) {
    if (order && order.uuid) {
      fetchUpdateOrderBasic({
        variables: {
          updateOrderInput: {
            uuid: order.uuid,
            trx_date: values.trx_date,
            marketplace_invoice: values.marketplace_invoice,
          },
        },
      });
    }
  }

  if (order) {
    return (
      <Card>
        <UpdateBasicModal
          show={showUpdateModal}
          onHide={() => setShowUpdateModal(false)}
          actionsLoading={updateLoading}
          initialValues={{
            invoice_no: values.invoice_no,
            marketplace_invoice: values.marketplace_invoice ?? '',
            trx_date: values.trx_date,
            store_name: `${values.store.market_name} - ${values.store.platform}`,
          }}
          submit={updateOrderBasic}
        />
        <CardHeader title="Data Dasar">
          <CardHeaderToolbar className="d-flex align-items-center">
            <span className="label label-lg label-light-info label-inline mr-4">
              {values.is_online ? 'ONLINE' : 'OFFLINE'}
            </span>
            {values.uuid &&
              [ORDER_STATUS.NEW, ORDER_STATUS.DRAFT, ORDER_STATUS.PAYMENT].includes(
                values.status
              ) && (
                <button
                  style={{ width: '75px' }}
                  className="btn btn-success d-flex justify-content-center align-items-center"
                  onClick={() => setShowUpdateModal(true)}
                >
                  {updateLoading ? (
                    <CircularProgress color="inherit" size={16} />
                  ) : (
                    <span>Edit</span>
                  )}
                </button>
              )}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div className="row m-0 p-0">
            <div className="col-4 col-md-3">Invoice</div>
            <div className="col-8 col-md-9">
              <span>:</span>
              <span className="ml-3">{values.invoice_no}</span>
            </div>
            <div className="col-4 col-md-3">Tanggal Transaksi</div>
            <div className="col-8 col-md-9">
              <span>:</span>
              <span className="ml-3">
                {values.trx_date ? moment(values.trx_date).format('YYYY-MM-DD') : ''}
              </span>
            </div>
            <div className="col-4 col-md-3">Store</div>
            <div className="col-8 col-md-9">
              <span>:</span>
              <span className="ml-3">{`${values.store.market_name} - ${values.store.platform}`}</span>
            </div>
            {values.is_online && (
              <>
                <div className="col-4 col-md-3">Marketplace Invoice</div>
                <div className="col-8 col-md-9">
                  <span>:</span>
                  <span className="ml-3">{values.marketplace_invoice}</span>
                </div>
              </>
            )}
          </div>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card>
      <CreateStoreModal
        show={showStoreModal}
        onHide={() => setShowStoreModal(false)}
        isLoading={createStoreLoading}
        submitCreateStore={createStore}
      />
      <CardHeader title="Data Dasar">
        <CardHeaderToolbar>
          {values.uuid ? (
            <span className="label label-lg label-light-info label-inline mr-4">
              {values.is_online ? 'ONLINE' : 'OFFLINE'}
            </span>
          ) : (
            <>
              <Form.Check
                inline
                label="Online"
                name="is_online"
                type="radio"
                value={values.is_online}
                checked={values.is_online}
                id="online"
                onChange={() => {
                  setFieldValue('is_online', true);
                }}
              />
              <Form.Check
                inline
                label="Offline"
                name="is_online"
                type="radio"
                value={values.is_online}
                checked={!values.is_online}
                id="offline"
                onChange={() => {
                  setFieldValue('is_online', false);
                }}
              />
            </>
          )}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <form>
          <div className="form-group row">
            <div className="col-md-4 mb-5 mb-md-0">
              <label htmlFor="trx_date">Trx Date</label>
              <Field id="trx_date" name="trx_date" type="date" disabled={false} component={Input} />
            </div>
            <div className="col-md-4 mb-5 mb-md-0">
              <label htmlFor="store_uuid">Store</label>
              <AsyncSelect
                name="store_uuid"
                defaultOptions={storeOptions}
                loadOptions={(inputValue, callback) =>
                  loadOptions(fetchStores, inputValue, callback)
                }
                value={
                  values.store.uuid
                    ? {
                        label: `${values.store.market_name} - ${values.store.platform}`,
                        value: values.store.uuid,
                      }
                    : {
                        label: '',
                        value: '',
                      }
                }
                onChange={({ value: uuid, market_name, platform }) => {
                  setFieldValue('store', { uuid, market_name, platform });
                }}
                noOptionsMessage={({ inputValue }) =>
                  inputValue.length >= 3 ? (
                    <>
                      <p>Store tidak ditemukan</p>
                      <button
                        type="button"
                        className="btn btn-sm btn-secondary mr-2"
                        aria-controls="collapse-filter-product"
                        onClick={() => {
                          setShowStoreModal(true);
                          setCreateStoreCallback(() => (newStore) => {
                            setFieldValue('store_uuid', newStore.uuid);
                            setFieldValue(
                              'store_label',
                              `${newStore.market_name} - ${newStore.platform}`
                            );
                          });
                        }}
                      >
                        Tambah
                      </button>
                    </>
                  ) : (
                    <span>Ketik 3 huruf atau lebih untuk mencari store.</span>
                  )
                }
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    border:
                      errors.store && touched.store
                        ? '1px solid rgb(246, 78, 96)'
                        : '1px solid #ddd',
                  }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    color: errors.store && touched.store ? 'rgb(246, 78, 96)' : '#ddd',
                  }),
                }}
              />
            </div>
            {values.is_online && (
              <div className="col-md-4 mb-5 mb-md-0">
                <label htmlFor="marketplace_invoice">Marketplace Invoice</label>
                <Field
                  id="marketplace_invoice"
                  name="marketplace_invoice"
                  component={Input}
                  placeholder="Invoice Number..."
                />
              </div>
            )}
          </div>
          {values.invoice_no && (
            <div className="form-group row">
              <div className="col-md-4">
                <label htmlFor="invoice_no">No Invoice</label>
                <Field component={Input} value={values.invoice_no} disabled={true} />
              </div>
            </div>
          )}
        </form>
      </CardBody>
    </Card>
  );
};
