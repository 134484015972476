import React from "react";
import { OrderReportDialog } from "./order-report-loading-dialog/OrderReportLoadingDialog";
import { OrderReportUIProvider } from "./OrderReportUIContext";
import { OrderReportCard } from "./OrderReportCard";

export function OrderReportPage({ history }) {
  const orderReportUIEvents = {
    newOrderPageButtonClick: () => {
      history.push("/report-order/new");
    },
    openEditOrderPage: (id) => {
      history.push(`/report-order/${id}/edit`);
    },
  };

  return (
    <OrderReportUIProvider orderReportUIEvents={orderReportUIEvents}>
      {/*<OrderPageLoadingDialog/>*/}
      <OrderReportCard history={history} />
    </OrderReportUIProvider>
  );
}
