/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import ApexCharts from "apexcharts";
import { GET_DAILY_SALES } from "../../Order/_redux/order/orderCrud";

export const TodayTransactionWidget = ({ className, chartColor = "danger", orders = [] }) => {
  // const [orders, setOrders] = useState([]);
  // const [fetchOrders] = useLazyQuery(GET_DAILY_SALES, {
  //   variables: {},
  //   onCompleted: (data) => {
  //     const { getDailySalesByDateRange } = data ?? {};
  //     const { items } = getDailySalesByDateRange ?? [];

  //     if (items instanceof Array) {
  //       setOrders(items);
  //     }
  //   },
  // });

  // useEffect(() => {
  //   fetchOrders();
  // }, []);

  useEffect(() => {
    const element = document.getElementById("daily-sales-chart");

    if (!element) {
      return;
    }

    const options = getChartOption(
      orders.map((item) => ({ x: item.trx_date_time, y: item.total_amount }))
    );
    const chart = new ApexCharts(element, options);

    chart.render();

    return () => {
      chart.destroy();
    };
  }, [orders]);

  return (
    <>
      {/* begin::Tiles Widget 1 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="card-title">
            <div className="card-label">
              <div className="font-weight-bolder">Penjualan Harian</div>
              <div className="font-size-sm text-muted mt-2">dalam juta</div>
            </div>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body d-flex flex-column px-0">
          {/* begin::Chart */}
          <div id="daily-sales-chart" data-color={chartColor} style={{ height: "150px" }} />
          {/* end::Chart */}
          <div className="flex-grow-1 card-spacer-x"></div>
        </div>
      </div>
      {/* end::Tiles Widget 1 */}
    </>
  );
};

function getChartOption(data) {
  const options = {
    series: [
      {
        name: "Total Pendapatan",
        data,
      },
    ],
    chart: {
      type: "area",
      height: "350px",
    },
    colors: ["#24e02d", "#66DA26"],
    markers: {
      size: 5,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      type: "datetime",
      labels: {
        format: "dd/MM",
      },
    },
    yaxis: {
      type: "integer",
      labels: { formatter: (value) => +value },
    },
  };
  return options;
}
