import axios from 'axios';
import { API } from '../../../../config/UrlEndPoint';
import { gql, useMutation } from '@apollo/client';
import { QueryGraphql, MutationGraphql } from '../../../../config/ApoloRequest';
import { array } from 'prop-types';

export const USER_URL = `${API}master-data/admin/`;

export const GET_ORDER = gql`
  query GetOrder($order_uuid: String!) {
    getOrder(uuid: $order_uuid) {
      uuid
      status
      store_uuid
      trx_date
      updated_at
      is_online
      insurance
      insurance_amount
      invoice_no
      created_at
      is_dropship
      dropship_name
      dropship_hp
      image_confirmation {
        uuid
        url
      }
      store {
        uuid
        platform
        market_name
      }
      marketplace_invoice
      branch {
        city
        name
        province
        uuid
      }
      member {
        name
        email
        hp
        uuid
        member_level_code
      }
      order_delivery {
        uuid
        awb
        address
        codepos
        shipping_cost
        city
        province
        sub_district
        receiver_name
        receiver_hp
        insurance_amount
        real_shipping_cost
        shipping_service {
          code
          company_code
          service
        }
        service_snapshot {
          uuid
          price
          final_price
          company_code
          company_name
          service_code
          service_name
        }
      }
      image_confirmation {
        url
      }
      order_logs {
        status
        uuid
        created_at
        description
      }
      order_payment {
        uuid
        amount
        pg_trx_id
        pg_type
        payment_method_uuid
        payment_method {
          code
          name
          type
        }
      }
      order_item {
        uuid
        assigned_price
        notes
        product_sku_uuid
        product_sku_snapshot
        product_snapshot
        qty
        order_return_uuid
        product_sku {
          uuid
          sku
          variant_value_1
          variant_value_2
          product {
            uuid
            name
            weight
          }
        }
        uuid
      }
      order_vouchers {
        voucher_uuid
        voucher_amount
        created_at
        voucher {
          type
        }
      }
    }
  }
`;

export async function findOrders(queryParams) {
  let filter = {};

  const query = gql`
    query Q(
      $created_at_start: String
      $created_at_end: String
      $trx_date_start: String
      $trx_date_end: String
      $admin_uuid: String
      $branch_uuid: String
      $invoice_no: String
      $is_online: Boolean
      $marketplace_invoice: String
      $member_uuid: String
      $page_number: Int
      $page_size: Int
      $shipping_cost: Int
      $status_excludes: [String!]
      $sort_field: String
      $sort_order: String
      $status: String
      $status_array: [String!]
      $store_uuid: String
      $shipping_service_uuid: String
      $shipping_company_code: String
      $receiver_name: String
      $receiver_hp: String
      $total_amount_start: Int
      $total_amount_end: Int
      $total_order_item_price_start: Int
      $total_order_item_price_end: Int
    ) {
      findOrder(
        findOrderInput: {
          created_at_start: $created_at_start
          created_at_end: $created_at_end
          trx_date_start: $trx_date_start
          trx_date_end: $trx_date_end
          admin_uuid: $admin_uuid
          branch_uuid: $branch_uuid
          invoice_no: $invoice_no
          is_online: $is_online
          marketplace_invoice: $marketplace_invoice
          member_uuid: $member_uuid
          page_number: $page_number
          page_size: $page_size
          shipping_cost: $shipping_cost
          sort_field: $sort_field
          sort_order: $sort_order
          status: $status
          status_excludes: $status_excludes
          status_array: $status_array
          store_uuid: $store_uuid
          shipping_service_uuid: $shipping_service_uuid
          shipping_company_code: $shipping_company_code
          receiver_name: $receiver_name
          receiver_hp: $receiver_hp
          total_amount_start: $total_amount_start
          total_amount_end: $total_amount_end
          total_order_item_price_start: $total_order_item_price_start
          total_order_item_price_end: $total_order_item_price_end
        }
      ) {
        items {
          admin {
            name
            uuid
          }
          invoice_no
          created_at
          is_online
          status
          trx_date
          uuid
          branch {
            city
            province
          }
          order_payment {
            amount
          }
          order_item {
            assigned_price
            qty
          }
          member {
            name
          }
          order_delivery {
            awb
            city
            province
            receiver_name
            shipping_cost
            shipping_service {
              company_code
              service
            }
          }
          store {
            market_name
            uuid
            platform
          }
        }
        total_items
      }
    }
  `;
  const newResponse = await QueryGraphql(query, queryParams);

  return newResponse.data.findOrder;
}

// CREATE =>  POST: add a new customer to the server
// export async function createAdmin(payload) {
//   const query = gql`

//   `;
//   console.log(query);
//   const newResponse = await MutationGraphql(query);
//   console.log(newResponse.data.createBranch);
//   return newResponse.data.createBranch;
// }

export async function createOrder(payload) {
  const query = gql`
    mutation {
      createAdmin(
        createAdminInput: {
          email: "${payload.email}"
          password: "${payload.password}"
          branches: ${JSON.stringify(payload.branches)}
          name: "${payload.name}"
          role: "${payload.role}"
        }
      ) {
        name
        email
        hp
        role
      }
    }
  `;
  // return;
  const newResponse = await MutationGraphql(query);
  return newResponse.data.createAdmin;
}

// GET ALL
export function getAllAdmins() {
  return axios.get(USER_URL);
}

// GET SINGLE USER
export async function getOrderById(id) {
  const query = gql`
    query GetOrder {
      getOrder(uuid: "${id}") {
        uuid
        status
        store_uuid
        trx_date
        updated_at
        is_online
        invoice_no
        created_at
        is_dropship
        dropship_name
        dropship_hp
        store {
          uuid
          platform
          market_name
        }
        marketplace_invoice
        branch {
          city
          name
          province
          uuid
        }
        member {
          name
          email
          hp
          uuid
          member_level_code
        }
        order_delivery {
          uuid
          awb
          address
          codepos
          shipping_cost
          city
          province
          sub_district
          receiver_name
          receiver_hp
          insurance_amount
          real_shipping_cost
          shipping_service {
            code
            company_code
            service
          }
          service_snapshot {
            uuid
            price
            final_price
          }
        }
        image_confirmation {
          url
        }
        order_logs {
          status
          uuid
          created_at
          description
        }
        order_payment {
          uuid
          amount
          pg_trx_id
          pg_type
          payment_method {
            code
            name
            type
          }
        }
        order_item {
          assigned_price
          notes
          product_sku_uuid
          product_sku_snapshot
          product_snapshot
          qty
          product_sku {
            sku
            variant_value_1
            variant_value_2
            product {
              weight
            }
          }
          uuid
        }
        shipping_cost
        insurance
        insurance_amount
        marketplace_fee
        net_amount
        order_amount
        order_vouchers {
          voucher_uuid
          voucher_amount
        }
      }
    }
  `;

  //? Untuk menampilkan voucher yang di gunakan order
  //* Taro di bawah variable order_amount
  // order_vouchers {
  //   voucher_uuid
  //   voucher_amount
  // }

  const newResponse = await QueryGraphql(query);
  return newResponse.data.getOrder;
}

export const UPDATE_ORDER_ITEM = gql`
  mutation Mutation(
    $createOrderItem: [CreateOrderItemInput!]!
    $deleted_order_item_uuids: [String!]!
    $order_uuid: String!
  ) {
    createOrderItem(createOrderItemInput: { order_uuid: $order_uuid, items: $createOrderItem }) {
      qty
      order_uuid
      uuid
    }
    deleteOrderItem(order_item_uuids: $deleted_order_item_uuids, order_uuid: $order_uuid) {
      message
    }
  }
`;

export const ORDER_RECEIPT = gql`
  query Query($uuid: String!) {
    getOrder(uuid: $uuid) {
      uuid
      status
      trx_date
      updated_at
      is_online
      invoice_no
      created_at
      is_dropship
      dropship_hp
      dropship_name
      branch {
        city
        name
        province
        uuid
      }
      member {
        name
        email
        hp
      }
      order_delivery {
        awb
        address
        codepos
        shipping_cost
        city
        province
        sub_district
        receiver_name
        receiver_hp
      }
      order_item {
        notes
        assigned_price
        product_sku_snapshot
        product_snapshot
        product_sku {
          price_before
          sku
          price
          product {
            name
            weight
          }
        }
        qty
        uuid
      }
    }
  }
`;

export const FIND_ORDER_INVOICES = gql`
  query ($marketplace_invoices: [String!]) {
    findOrder(
      findOrderInput: { marketplace_invoices: $marketplace_invoices, status_excludes: ["VOID"] }
    ) {
      items {
        uuid
        marketplace_invoice
      }
    }
  }
`;

export async function deleteAdmin(id) {
  const query = gql`
    mutation {
      deleteAdmin(uuid: "${id}") {
        name
      }
    }
  `;
  const adminData = await MutationGraphql(query);
  return adminData.data.deleteAdmin;
}

export const CREATE_ORDER = gql`
  mutation Mutation(
    $branch_uuid: String
    $is_online: Boolean
    $member_uuid: String
    $trx_date: DateTime!
    $order_delivery: OrderDeliveryInput
    $order_items: [EditOrderItems!]!
    $order_payment: OrderPaymentInput!
    $store_uuid: String
    $marketplace_invoice: String
    $is_dropship: Boolean
    $dropship_name: String
    $dropship_hp: String
    $status: String
  ) {
    createOrder(
      createOrderInput: {
        status: $status
        branch_uuid: $branch_uuid
        is_online: $is_online
        trx_date: $trx_date
        store_uuid: $store_uuid
        member_uuid: $member_uuid
        marketplace_invoice: $marketplace_invoice
        order_delivery: $order_delivery
        order_items: $order_items
        order_payment: $order_payment
        is_dropship: $is_dropship
        dropship_name: $dropship_name
        dropship_hp: $dropship_hp
      }
    ) {
      uuid
      status
      is_online
    }
  }
`;

export const UPDATE_ORDER = gql`
  mutation Mutation($uuid: String!, $awb: String, $status: String, $reason: String) {
    updateOrder(updateOrderInput: { uuid: $uuid, awb: $awb, status: $status, reason: $reason }) {
      status
      invoice_no
    }
  }
`;

export const UPDATE_ORDER_SHIPMENT = gql`
  mutation Mutation($updateOrderInput: UpdateOrderInput!) {
    updateOrder(updateOrderInput: $updateOrderInput) {
      status
      invoice_no
      order_delivery {
        awb
        real_shipping_cost
      }
    }
  }
`;

export const UPDATE_ORDER_BASIC = gql`
  mutation m($updateOrderInput: UpdateOrderInput!) {
    updateOrder(updateOrderInput: $updateOrderInput) {
      uuid
      trx_date
      marketplace_invoice
    }
  }
`;

export const GET_DAILY_SALES = gql`
  query Query($date_start: String, $date_end: String, $sort_field: String, $sort_order: String) {
    getDailySalesByDateRange(
      getSalesByDateRangeInput: {
        date_start: $date_start
        date_end: $date_end
        sort_field: $sort_field
        sort_order: $sort_order
      }
    ) {
      items {
        total_order
        total_amount
        trx_date_time
      }
    }
  }
`;

export const GET_MONTHLY_SALES = gql`
  query Query($date_start: String, $date_end: String, $sort_field: String, $sort_order: String) {
    getMonthlySalesByDateRange(
      getSalesByDateRangeInput: {
        date_start: $date_start
        date_end: $date_end
        sort_field: $sort_field
        sort_order: $sort_order
      }
    ) {
      items {
        total_order
        total_amount
        trx_date_month
      }
    }
  }
`;

export const FIND_ORDER_LOG = gql`
  query Query($order_uuid: String!) {
    findOrderLog(findOrderInput: { order_uuid: $order_uuid }) {
      items {
        status
        uuid
        created_at
      }
    }
  }
`;

export const FETCH_TOKO = gql`
  query FindStore($is_active: Boolean, $is_online: Boolean) {
    findStore(findStoreInput: { is_active: $is_active, is_online: $is_online }) {
      items {
        is_online
        is_active
        market_name
        platform
        uuid
      }
      total_items
      total_pages
    }
  }
`;

export const GET_ORDER_STATUS = gql`
  {
    __typename
    getOrderStatusGroup {
      items {
        count
        status
      }
    }
  }
`;

export const GET_ADDRESS = gql`
  query Q($keyword: String!) {
    __typename
    findAddressSuggestion(keyword: $keyword) {
      items {
        city
        district
        postal_codes
        province_name
      }
    }
  }
`;

export const ORDER_STORE_CONFIGS = gql`
  query Q($branch_key: String!, $manual_key: String!) {
    defaultBranchConfig: getConfig(keyname: $branch_key) {
      ...configFragment
    }

    manualStoreConfig: getConfig(keyname: $manual_key) {
      ...configFragment
    }
  }

  fragment configFragment on DynamicConfig {
    key_config
    type
    value_config
  }
`;

export const ORDER_CONFIGS = gql`
  query Q(
    $pmInput: FindPaymentMethodInput!
    $ssInput: FindShippingServiceInput!
    $memberInput: FindMemberInput
    $branchInput: FindAllBranchInput!
    $key_config: String!
  ) {
    findPaymentMethod(findPaymentMethodInput: $pmInput) {
      items {
        image {
          url
          uuid
        }
        code
        name
        is_active
        pg_code
        price
        price_percentage
        publish_status
        type
        uuid
      }
      total_items
      sort_order
      sort_field
    }

    findShippingService(findShippingServiceInput: $ssInput) {
      items {
        service
        company_code
        uuid
        code
        category
        is_active
      }
      total_items
    }

    findMember(findMemberInput: $memberInput) {
      items {
        email
        hp
        member_level_code
        name
        uuid
        updated_at
        created_at
      }
      sort_field
      sort_order
      total_items
      total_pages
      current_page
    }

    findBranches(findBranchInput: $branchInput) {
      items {
        uuid
        city
        name
        province
        postal_code
        subdistrict_id
      }
      total_items
    }

    branchConfig: getConfig(keyname: $key_config) {
      ...configFragment
    }
  }

  fragment configFragment on DynamicConfig {
    key_config
    type
    value_config
  }
`;

export const ORDER_VERIFY_PAYMENT = gql`
  mutation Mutation($payment_uuid: ID!) {
    verifyPayment(order_payment_uuid: $payment_uuid) {
      amount
      pg_trx_id
      pg_type
    }
  }
`;

export const UPDATE_ORDER_DELIVERY = gql`
  mutation m($updateOrderDeliveryInput: UpdateOrderDeliveryInput!) {
    updateOrderDelivery(updateOrderDeliveryInput: $updateOrderDeliveryInput) {
      awb
      shipping_cost
      address
      sub_district
      city
      province
      codepos
      receiver_name
      receiver_hp
    }
  }
`;

export const CREATE_ORDER_RETURN = gql`
  mutation CreateOrderReturn($createOrderReturnInput: CreateOrderReturnInput!) {
    createOrderReturn(createOrderReturnInput: $createOrderReturnInput) {
      uuid
      order_uuid
      shipping_awb
      shipping_cost
    }
  }
`;

export const GET_ORDER_RETURN = gql`
  query ($order_return_uuid: String!) {
    getOrderReturn(order_return_uuid: $order_return_uuid) {
      uuid
      order_uuid
      shipping_cost
      shipping_awb
      order {
        invoice_no
        marketplace_invoice
      }
      return_items {
        uuid
        assigned_price
        qty
        notes
        order_return_uuid
        product_sku_uuid
        product_sku {
          uuid
          sku
          product {
            name
          }
        }
      }
    }
  }
`;
