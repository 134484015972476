// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../../_redux/order/orderActions';
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from '../../../../../_metronic/_helpers';
import * as uiHelpers from '../OrderUIHelpers';
import * as columnFormatters from './column-formatters';
import { Pagination } from '../../../../../_metronic/_partials/controls';
import { useOrderUIContext } from '../OrderUIContext';
import { OrderEditDialog } from '../order-edit-dialog/OrderEditDialog';
import moment from 'moment';
import { ORDER_RECEIPT } from '../../_redux/order/orderCrud';
import { toast } from 'react-toastify';
import axios from 'axios';
import { getAdmin } from '../../../Auth/_redux/authCrud';
import { API_URL } from '../../../../config/UrlEndPoint';
import { LoadingAnimation } from '../../../../components/loading-animation/LoadingAnimation';
import { getDynamicConfigById } from '../../../MasterData/_redux/dynamic-config/dynamicConfigCrud';
import { CONFIG_HELPER } from '../../../../config/Helper';
import { getStoreById } from '../../../MasterData/_redux/store/storeCrud';
import { RoleChecker } from '../../../../RoleChecker';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function OrderTable({ match }) {
  const allowUpdate = RoleChecker("update", "order")
  // Order UI Context
  const orderUIContext = useOrderUIContext();
  const orderUIProps = useMemo(() => {
    return {
      queryParams: orderUIContext.queryParams,
      setQueryParams: orderUIContext.setQueryParams,
      openEditOrderDialog: orderUIContext.openEditOrderDialog,
      openDeleteOrderDialog: orderUIContext.openDeleteOrderDialog,
    };
  }, [orderUIContext]);
  const query = useQuery();

  // Getting curret state of order list from store (Redux)
  const { currentState } = useSelector((state) => ({ currentState: state.orders }), shallowEqual);
  const { totalCount, entities, listLoading } = currentState;

  const [isShowModal, setIsShowModal] = useState(false);
  const [rowId, setRowId] = useState();

  useEffect(() => {
    admin();
    dynamicPromo();
  }, []);

  // Order Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    const { queryParams } = orderUIProps;
    const params = { ...queryParams };

    if (!params.status && query.get('status')) {
      params.status = query.get('status');
    }

    // ! CHECK IF ORDER APPLICATION
    if (match.path === '/order-application') {
      getDynamicConfigById(CONFIG_HELPER.gayalo_store)
        .then((res) =>
          getStoreById(res.value_config)
            .then((res) => {
              dispatch(
                actions.fetchOrders({
                  ...params,
                  store_uuid: res.uuid,
                })
              );
            })
            .catch((err) => console.log(err))
        )
        .catch((err) => console.log(err));
    } else {
      dispatch(actions.fetchOrders(params));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderUIProps.queryParams, dispatch]);

  // * Print Receipt
  const [userAdmin, setuserAdmin] = useState({});
  const [promo, setPromo] = useState({
    text: '',
    url: '',
  });

  const admin = () => {
    getAdmin()
      .then((res) => {
        setuserAdmin(res);
      })
      .catch((err) => console.log(err));
  };

  const dynamicPromo = () => {
    getDynamicConfigById(CONFIG_HELPER.promo_text)
      .then((res) =>
        setPromo((prev) => ({
          ...prev,
          text: res.value_config,
        }))
      )
      .catch((err) => console.log(err));

    getDynamicConfigById(CONFIG_HELPER.promo_url)
      .then((res) =>
        setPromo((prev) => ({
          ...prev,
          url: res.value_config,
        }))
      )
      .catch((err) => console.log(err));
  };

  const [printLoading, setPrintLoading] = useState(false);

  const [getOrderReceipt] = useLazyQuery(ORDER_RECEIPT, {
    onCompleted: async ({ getOrder: order }) => {
      const { branch, member, order_delivery, order_item } = order;

      if (!order) {
        toast.error('Unable to fetch order');
        return;
      }

      let items = [];
      order_item.forEach((item) => {
        let strVariant = '';
        if (
          item.product_sku_snapshot.variant_value_1 &&
          item.product_sku_snapshot.variant_value_1 != 'UMUM'
        ) {
          strVariant = ` - ${item.product_sku_snapshot.variant_value_1}`;
        }
        if (
          item.product_sku_snapshot.variant_value_2 &&
          item.product_sku_snapshot.variant_value_2 != 'UMUM'
        ) {
          strVariant += ` - ${item.product_sku_snapshot.variant_value_2}`;
        }
        items.push({
          product_name: item.product_snapshot.name + strVariant,
          sku: item.product_sku_snapshot.sku,
          price: +item.product_sku_snapshot.price_before,
          // price: item.product_sku_snapshot.price,
          weight: +item.product_snapshot.weight * +item.qty,
          qty: +item.qty,
          price_discount: +item.product_sku_snapshot.price_before - +item.assigned_price,
        });
        // if (item.assigned_price != item.product_sku_snapshot.price) {
        //   items.push({
        //     product_name: "",
        //     sku: "Hemat",
        //     // price: +item.product_sku.price,
        //     price: +item.product_sku_snapshot.price - +item.assigned_price,
        //     weight: +item.product_sku_snapshot.product.weight,
        //     qty: +item.qty,
        //   });
        // }
      });

      const data = {
        header: {
          sale_hid: order.invoice_no,
          officer: userAdmin.name,
          is_dropship: order.is_dropship,
          dropship_name: order.is_dropship ? order.dropship_name : null,
          dropship_hp: order.is_dropship ? order.dropship_hp : null,
          date: moment(order.created_at).format('YYYY-MM-DD HH:mm:ss'),
          warehouse: branch.name,
          shipping_cost: order.order_delivery ? order.order_delivery.shipping_cost : 0,
          shipping_name: order_delivery && order_delivery.receiver_name,
          shipping_hp: order_delivery && order_delivery.receiver_hp,
          shipping_address: order_delivery ? order_delivery.address : '',

          shipping_address_2: order_delivery
            ? `${order_delivery.sub_district}, ${order_delivery.city}, ${order_delivery.province}`
            : '',
          promo_text: promo.text,
          promo_url: promo.url,
        },
        items,
      };

      try {
        const response = await axios({
          method: 'POST',
          url: 'https://thermal-print.gayalo.com/print',
          headers: {
            'Content-Type': 'application/json',
          },
          data,
        });

        toast.success('Print Success');
      } catch (error) {
        toast.error(error.message);
      } finally {
        setPrintLoading(false);
      }
    },
    onError: (error) => {
      toast.error(error.message);
      setPrintLoading(false);
    },
    fetchPolicy: 'cache-and-network',
  });

  const printOrderReceipt = (uuid) => {
    if (!printLoading) {
      setPrintLoading(true);
      getOrderReceipt({ variables: { uuid: uuid } });
    } else {
      setPrintLoading(false);
    }
  };

  // * Print Label
  const printLabel = (uuid) => {
    if (uuid) window.open(`${API_URL}template/packing/${uuid}`);
  };

  // Table columns
  const columns = [
    {
      dataField: 'invoice_no',
      text: 'No Invoice',
      sort: true,
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      // sortCaret: sortCaret,
      formatter: columnFormatters.StatusFormatter,
      // headerSortingClasses,
    },
    {
      dataField: 'admin.name',
      text: 'Admin',
      sort: true,
    },
    {
      dataField: 'trx_date',
      text: 'Tanggal',
      sort: true,
      formatter: (cell, row, indexRow) => {
        return <span>{moment(cell).format('YYYY-MM-DD')}</span>;
      },
    },
    {
      dataField: 'store',
      text: 'Toko',
      sort: true,
      formatter: (cell, row, indexRow) => {
        if (cell) {
          const marketName = cell && cell.market_name;
          const platform = cell && cell.platform;
          return <span>{`${marketName && marketName} - ${platform && platform}`}</span>;
        }
      },
    },
    {
      dataField: 'order_delivery.receiver_name',
      text: 'Penerima',
      sort: true,
    },
    {
      dataField: 'order_delivery.shipping_cost',
      text: 'Ongkir',
      sort: true,
      formatter: (cell, row, rowIndex) => {
        return (
          <div className="text-right">
            <span>{columnFormatters.currencyFormatter(cell)}</span>
          </div>
        );
      },
    },
    {
      dataField: 'order_item',
      text: 'Belanja',
      sort: true,
      formatter: (cell, row, rowIndex) => {
        const assignedPrice = row.order_item;
        const total = assignedPrice.reduce(
          (prev, curr) => curr.assigned_price * curr.qty + prev,
          0
        );

        return (
          <div className="text-right">
            <span>{columnFormatters.currencyFormatter(total)}</span>
          </div>
        );
      },
    },
    {
      dataField: '',
      text: 'Total',
      sort: true,
      formatter: (cell, row, rowIndex) => {
        const assignedPrice = row.order_item;
        const shippingCost = row.order_delivery && row.order_delivery.shipping_cost;

        const total = assignedPrice.reduce(
          (prev, curr) => curr.assigned_price * curr.qty + prev,
          0
        );

        const subTotal = shippingCost + total;

        return (
          <div className="text-right">
            <span>{columnFormatters.currencyFormatter(subTotal)}</span>
          </div>
        );
      },
    },
    {
      dataField: 'action',
      text: 'Actions',
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditOrderDialog: orderUIProps.openEditOrderDialog,
        openDeleteOrderDialog: orderUIProps.openDeleteOrderDialog,
        setShowModal: setIsShowModal,
        id: setRowId,
        printOrderReceipt,
        printLoading,
        printLabel,
        allowUpdate: allowUpdate,
      },
      classes: 'text-right pr-0',
      headerClasses: 'text-right pr-3',
      style: {
        minWidth: '100px',
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: orderUIProps.queryParams.page_size,
    page: orderUIProps.queryParams.page_number,
  };
  return (
    <>
      <LoadingAnimation open={listLoading} />
      <OrderEditDialog id={rowId} onHide={() => setIsShowModal(false)} show={isShowModal} />
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={listLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="uuid"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(orderUIProps.setQueryParams)}
                // selectRow={getSelectRow({
                //   entities,
                // })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
