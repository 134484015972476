import axios from "axios";
import { gql } from "@apollo/client";
import { QueryGraphql, MutationGraphql } from "../../../../config/ApoloRequest";
import { API } from "../../../../config/UrlEndPoint";

export const URL = `${API}master-data/shippingCompany/`;

export async function findShippingCompanys(queryParams) {
  const query = gql`
    {
      findShippingCompany(
        findShippingCompanyInput: {
          page_number: ${+queryParams.page_number}
          page_size: ${+queryParams.page_size}
          sort_field: "${queryParams.sortField}"
          sort_order: "${queryParams.sortOrder}"
        }
      ) {
        items {
          name
          code
        }
        current_page
        total_items
        total_pages
      }
    }
  `;
  const newResponse = await QueryGraphql(query);
  return newResponse.data.findShippingCompany;
}

export async function createShippingCompany(payload) {
  const query = gql`
    mutation Mutation {
      createShippingCompany(
        createShippingCompanyInput: { code: "${payload.code}", name: "${payload.name}" }
      ) {
        code
        name
      }
    }
  `;
  const newResponse = await MutationGraphql(query);
  return newResponse.data.createShippingCompany;
}

export async function updateShippingCompany(payload) {
  const query = gql`
    mutation Mutation {
      updateShippingCompany(
        updateShippingCompanyInput: { code: "${payload.code}", name: "${payload.name}" }
      ) {
        code
        name
      }
    }
  `;

  const newResponse = await MutationGraphql(query);
  return newResponse.data.updateShippingCompany;
}

export async function deleteShippingCompany(code) {
  const query = gql`
    mutation Mutation {
      deleteShippingCompany(code: "${code}") {
        message
      }
    }
  `;

  const newResponse = await MutationGraphql(query);
  return newResponse.data.deleteShippingCompany;
}

export async function getShippingCompanyById(code) {
  const query = gql`
    {
      getShippingCompany(code: "${code}") {
        code
        name
      }
    }
  `;

  const newResponse = await QueryGraphql(query);
  return newResponse.data.getShippingCompany;
}

// CREATE =>  POST: add a new customer to the server
// export function createShippingCompany(shippingCompany) {
//     return axios.post(URL, shippingCompany);
// }

// GET ALL
export function getAllShippingCompanys() {
  return axios.get(URL);
}

// GET SINGLE USER
// export function getShippingCompanyById(id) {
//     return axios.get(`${URL}${id}`);
// }

// GET USER WITH CONDITION
// export function findShippingCompanys(queryParams) {
//     return axios.post(`${URL}find`, queryParams);
// }

// UPDATE SINGEL USER
// export function updateShippingCompany(shippingCompany) {
//     return axios.put(`${URL}`, shippingCompany);
// }
