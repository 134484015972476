import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLazyQuery, useQuery } from '@apollo/client';
import AsyncSelect from 'react-select/async';
import { Field, Formik, Form } from 'formik';
import { isEqual } from 'lodash';
import { useOrderLogisticUIContext } from '../OrderLogisticUIContext';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import { Input } from '../../../../../_metronic/_partials/controls';
import { initialFilter, IS_ONLINE, ORDER_STATUS_OPTIONS } from '../OrderLogisticUIHelpers';
import Select from 'react-select';
import { DatePicker } from 'antd';
import { findAdmins } from '../../../MasterData/_redux/admin/adminCrud';
import { findShipperServices } from '../../../MasterData/_redux/shipper-service/shipperServiceCrud';
import { findShippingCompanys } from '../../../MasterData/_redux/shipping-company/shippingCompanyCrud';
import { findStores, getStoreById } from '../../../MasterData/_redux/store/storeCrud';
import { getDynamicConfigById } from '../../../MasterData/_redux/dynamic-config/dynamicConfigCrud';
import { CONFIG_HELPER } from '../../../../config/Helper';
import moment from 'moment';
import { ORDER_STATUS } from '../../../Order/pages/OrderUIHelpers';
const prepareFilter = (queryParams, values) => {
  const { store_uuid, shipping_service_uuid, shipping_company_code, trx_date_start, trx_date_end } =
    values;

  const newQueryParams = { ...queryParams };
  newQueryParams.store_uuid = store_uuid ?? null;
  newQueryParams.shipping_service_uuid = shipping_service_uuid ?? null;
  newQueryParams.shipping_service_uuid = shipping_service_uuid ?? null;
  newQueryParams.shipping_company_code = shipping_company_code ?? null;
  newQueryParams.trx_date_start = trx_date_start ?? null;
  newQueryParams.trx_date_end = trx_date_end ?? null;

  return newQueryParams;
};

export const OrderLogisticFilter = ({ listLoading, match }) => {
  // OrderLogistic UI Context
  const orderLogisticUIContext = useOrderLogisticUIContext();
  const orderLogisticUIProps = useMemo(() => {
    return {
      queryParams: orderLogisticUIContext.queryParams,
      setQueryParams: orderLogisticUIContext.setQueryParams,
    };
  }, [orderLogisticUIContext]);

  // queryParams, setQueryParams,
  function applyFilter(values) {
    const newQueryParams = prepareFilter(orderLogisticUIProps.queryParams, values);

    orderLogisticUIProps.setQueryParams({
      ...newQueryParams,
      page_number: 1,
      page_size: 10,
      status_array: [
        ORDER_STATUS.PACKING,
        ORDER_STATUS.SENDING,
        ORDER_STATUS.RECEIVED,
        ORDER_STATUS.RECEIVED_CONFIRM,
        ORDER_STATUS.FINISH,
      ],
    });
  }

  const [storeOptions, setStoreOptions] = useState([]);
  const [shippingServiceOptions, setShippingServiceOptions] = useState([]);
  const [shippingCompanyOptions, setShippingCompanyOptions] = useState([]);

  useEffect(() => {
    findForFilter();
  }, []);

  async function findForFilter() {
    findShipperServices({
      company_code: '',
      service: '',
      category: null,
      is_active: null,
      code: '',
      sort_orderLogistic: 'DESC', // asc||desc
      // sort_field: "shipping_service.trx_date",
      page_number: 1,
      page_size: 200,
    })
      .then((res) => setShippingServiceOptions(res.items))
      .catch((err) => console.log(err));

    findShippingCompanys({
      name: '',
      code: '',
      sortOrderLogistic: 'DESC', // asc||desc
      sortField: 'trx_date',
      page_number: 1,
      page_size: 200,
    })
      .then((res) => setShippingCompanyOptions(res.items))
      .catch((err) => console.log(err));

    findStores({
      is_active: true,
      page_number: 1,
      page_size: 50,
    })
      .then((res) => setStoreOptions(res.items))
      .catch((error) => console.error(error));
  }

  return (
    <>
      <Formik
        initialValues={{
          ...initialFilter,
          dateValue: [moment(), moment()],
          trx_date_start: moment().format('YYYY-MM-DD'),
          trx_date_end: moment().format('YYYY-MM-DD'),
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({ values, handleSubmit, handleBlur, handleChange, setFieldValue, resetForm }) => (
          <>
            <Form>
              <div className="form-group row">
                <div className="col-lg-4">
                  <DatePicker.RangePicker
                    style={{ width: '70%' }}
                    value={values.dateValue}
                    format={'YYYY/MM/DD'}
                    onChange={(e) =>
                      e &&
                      e.map((time, index) => {
                        setFieldValue('dateValue', e);
                        index === 0
                          ? setFieldValue('trx_date_start', time.format('YYYY-MM-DD'))
                          : setFieldValue('trx_date_end', time.format('YYYY-MM-DD'));
                      })
                    }
                  />
                  <small className="form-text ">
                    Cari <b>Tanggal Transaksi</b>
                  </small>
                </div>
                <div className="col-lg-4">
                  <Select
                    cacheOptions={true}
                    isClearable
                    name="shipping_company_code"
                    placeholder="Cari Shipping Company"
                    options={shippingCompanyOptions.map((item) => ({
                      label: item.name,
                      value: item.code,
                    }))}
                    value={
                      values.shipping_company_code
                        ? {
                            label: values.shipping_company_name,
                            value: values.shipping_company_code,
                          }
                        : null
                    }
                    onChange={(e) => {
                      const value = e;
                      setFieldValue('shipping_company_code', value && value.value);
                      setFieldValue('shipping_company_name', value && value.label);
                    }}
                  />
                  <small className="form-text ">
                    Cari <b>Shipping Company</b>
                  </small>
                </div>
                <div className="col-lg-4">
                  <Select
                    isClearable
                    cacheOptions={true}
                    name="shipping_service_uuid"
                    placeholder="Cari Shipping service"
                    options={shippingServiceOptions.map((item) => ({
                      label: item.service,
                      value: item.uuid,
                    }))}
                    value={
                      values.shipping_service_uuid
                        ? {
                            label: values.shipping_name,
                            value: values.shipping_service_uuid,
                          }
                        : null
                    }
                    onChange={(e) => {
                      const value = e;
                      setFieldValue('shipping_service_uuid', value && value.value);
                      setFieldValue('shipping_name', value && value.label);
                    }}
                  />
                  <small className="form-text ">
                    Cari <b>Shipping Service</b>
                  </small>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-4">
                  <Select
                    cacheOptions={true}
                    isClearable
                    isDisabled={match.path === '/orderLogistic-application' && true}
                    name="store_uuid"
                    placeholder="Cari Store"
                    options={storeOptions.map((item) => ({
                      label: `${item.market_name} - ${item.platform}`,
                      value: item.uuid,
                    }))}
                    value={
                      values.store_uuid
                        ? {
                            label: values.store_name,
                            value: values.store_uuid,
                          }
                        : null
                    }
                    onChange={({ value, label }) => {
                      setFieldValue('store_uuid', value);
                      setFieldValue('store_name', label);
                    }}
                  />
                  <small className="form-text ">
                    Cari <b>Store</b>
                  </small>
                </div>
              </div>
              <button
                type="submit"
                onClick={handleSubmit}
                className="btn btn-primary btn-elevate float-right mb-20 ml-4"
              >
                Terapkan
              </button>
              <button
                type="button"
                onClick={() => {
                  resetForm();
                  // setTanggal({ start_date: null, end_date: null });
                  handleSubmit();
                }}
                className="btn btn-danger btn-elevate ml-3 float-right mb-20"
              >
                Hapus Pencarian
              </button>
              <hr className="mt-25" />
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
