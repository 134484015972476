import { createSlice } from "@reduxjs/toolkit";
import { initialFilter } from "../../pages/MarketplaceOrderUIHelpers";

const initialMarketplaceOrdersState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  marketplaceOrderForEdit: undefined,
  lastError: null,
  filters: initialFilter
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const marketplaceOrderSlice = createSlice({
  name: "marketplaceOrders",
  initialState: initialMarketplaceOrdersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getMarketplaceOrderById
    marketplaceOrderFetched: (state, action) => {
      state.actionsLoading = false;
      state.marketplaceOrderForEdit = action.payload.marketplaceOrderForEdit;
      state.error = null;
    },
    // findMarketplaceOrders
    marketplaceOrdersFetched: (state, action) => {
      const { total_items, items } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = items;
      state.totalCount = total_items;
    },
    // allMarketplaceOrders
    marketplaceOrderALlFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.allData = action.payload.allData;
    },
    orderMarketplaceFilter: (state, action) => {
      state.listLoading = false
      state.error = null
      state.filters = action.payload
    },
    // createMarketplaceOrder
    // marketplaceOrderCreated: (state, action) => {
    //   state.ewactionsLoading = false;
    //   state.error = null;
    //   state.entities.push(action.payload.marketplaceOrder);
    // },
    // updateMarketplaceOrder
    marketplaceOrderUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.marketplaceOrder.id) {
          return action.payload.marketplaceOrder;
        }
        return entity;
      });
    },
  },
});
