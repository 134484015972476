import { getStorage } from "../../../../_metronic/_helpers";

export const PurchaseStatusCssClasses = ["danger", "success", "info", ""];
export const PurchaseStatusTitles = ["Suspended", "Active", "Pending", ""];
export const PurchaseTypeCssClasses = ["success", "primary", ""];
export const PurchaseTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "created_at", order: "desc" }];
export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
];
export const initialFilter = {
  branch_uuid: "",
  project_uuid: "",
  supplier_uuid: "",
  description: "",
  product_uuid: "",
  product_sku: "",
  product_name: "",
  purchase_uuid: "",
  sort_field: "purchase.created_at",
  sort_order: "DESC",
  status: "",
  page_number: 1,
  page_size: 10,
  start_trx_date: null,
  end_trx_date: null,
};

const purchaseStorage = JSON.parse(getStorage("purchase_storage"));

export const initPurchase = {
  uuid: "",
  trx_date: "",
  status: "",
  description: purchaseStorage ? purchaseStorage.description : "",
  purchase_items: [],
  branches: {
    name: purchaseStorage ? purchaseStorage.branch_name : "",
    uuid: purchaseStorage ? purchaseStorage.branch_value : "",
  },
  projects: {
    name: purchaseStorage ? purchaseStorage.project_name : "",
    uuid: purchaseStorage ? purchaseStorage.project_value : "",
  },
  suppliers: {
    name: purchaseStorage ? purchaseStorage.supplier_name : "",
    uuid: purchaseStorage ? purchaseStorage.supplier_value : "",
  },
};

export const statusPurchaseItem = {
  ADDED: "ADDED",
  EDITED: "EDITED",
  REMOVED: "REMOVED",
  VIEWED: "VIEWED",
};

export const statusPurchase = {
  DRAFT: "DRAFT",
  SETTLE: "SETTLE",
  PROCESSING: "PROCESSING",
};

export const badge = (status) => {
  let data = {};
  switch (status) {
    case "DRAFT":
      data = { type: "info" };
      break;
    case "SETTLE":
      data = { type: "primary" };
      break;
    case "PROCESSING":
      data = { type: "warning" };
      break;
    case "PRE-ORDER":
      data = { type: "primary" };
      break;
    default:
      data = { type: "secondary" };
  }

  return data;
};
