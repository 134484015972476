import React, { useState, useEffect, useContext } from 'react';
import { OrderFormContext } from './FormContext';
import SVG from 'react-inlinesvg';

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from '../../../../../_metronic/_partials/controls';
import { ORDER_FOR_RETURN_STATUS, ORDER_STATUS } from '../OrderUIHelpers';
import { useMutation } from '@apollo/client';
import BootstrapTable from 'react-bootstrap-table-next';
import { toAbsoluteUrl, toastOption, currencyFormatter } from '../../../../../_metronic/_helpers';
import { useFormikContext } from 'formik';
import { UPDATE_ORDER_ITEM } from '../../_redux/order/orderCrud';
import { toast } from 'react-toastify';
import { CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

export const OrderItemTable = () => {
  // * Contexts
  const history = useHistory();
  const { order, orderItems, removeProduct } = useContext(OrderFormContext);
  const { values } = useFormikContext();

  // * States
  const [newOrderItems, setNewOrderItems] = useState([]);
  const [removedOrderItems, setRemovedOrderItems] = useState([]);

  // * Queries
  const [fetchUpdateOrderItems, { loading: actionsLoading }] = useMutation(UPDATE_ORDER_ITEM, {
    onCompleted: (data) => {
      toast.success('Updated Successfully', toastOption);
    },
    onError: (error) => {
      toast.error(error, toastOption);
    },
  });

  // * Effects
  useEffect(() => {
    setNewOrderItems(orderItems);
  }, [orderItems]);

  // * Functions
  function addRemovedItem(product_sku_uuid) {
    const removed = orderItems.find((item) => item.product_sku_uuid === product_sku_uuid);

    if (removed && removed.uuid) {
      setRemovedOrderItems((prev) => {
        if (prev.findIndex((uuid) => uuid === removed.uuid) > -1) {
          return prev;
        }

        return [...prev, removed.uuid];
      });
    }

    removeProduct(product_sku_uuid);
  }

  function submitOrderItems() {
    const createOrderItem = newOrderItems.map((item) => {
      const { assigned_price, product_sku_uuid, qty } = item;
      return { assigned_price, product_sku_uuid, qty, order_uuid: values.uuid };
    });

    fetchUpdateOrderItems({
      variables: {
        order_uuid: values.uuid,
        createOrderItem,
        deleted_order_item_uuids: removedOrderItems,
      },
    });
  }

  function openOrderReturnPage(order_return_uuid) {
    history.push(`/order/return/${order_return_uuid}`);
  }

  // * Constants
  const orderItemColumn = [
    {
      text: 'Nama Product',
      dataField: 'product_name',
      footer: 'Sub Total',
      formatter: (cell, row, rowIndex) => {
        let variant2 = row.variant_value_2 ? ` - ${row.variant_value_2}` : '';
        let label = `${cell} - ${row.variant_value_1 ?? ''} ${variant2} `;
        return label;
      },
    },
    {
      text: 'Sku',
      dataField: 'product_sku',
      footer: '',
    },
    {
      text: 'Catatan',
      dataField: 'notes',
      footer: '',
    },
    {
      text: 'Jumlah',
      dataField: 'qty',
      formatter: (cell) => (
        <span className={`${+cell > 0 ? 'text-success' : 'text-danger'}`}>{cell}</span>
      ),
      footer: (column) => {
        const totalQty = column.reduce((tot, qty) => +tot + +qty, 0);

        return <span>{totalQty}</span>;
      },
    },
    {
      text: 'Berat',
      dataField: 'weight',
      footer: (column) => {
        const totalWeight = column.reduce((tot, weight) => +tot + +weight, 0);

        return <span>{totalWeight}</span>;
      },
    },
    {
      text: 'Harga',
      dataField: 'total_price',
      formatter: (cell) => {
        return (
          <span className={`${+cell > 0 ? 'text-success' : 'text-danger'}`}>
            {+cell > 0 ? currencyFormatter(cell) : '-' + currencyFormatter(Math.abs(+cell))}
          </span>
        );
      },
      footer: (columns) => {
        const totalAssignedPrice = columns.reduce(
          (tot, totalAssignedPrice) => +tot + +totalAssignedPrice,
          0
        );

        return <span>{currencyFormatter(totalAssignedPrice)}</span>;
      },
    },
    {
      dataField: '',
      text: '',
      formatter: (cell, row) => (
        <>
          {(!Boolean(values.uuid) ||
            [ORDER_STATUS.DRAFT, ORDER_STATUS.PAYMENT].includes(values.status)) && (
            <div className="d-flex align-items-center">
              <a
                title="Remove Product"
                className={`btn btn-icon btn-light btn-hover-danger btn-sm mr-5 flex-shrink-0`}
                onClick={() => {
                  addRemovedItem(row.product_sku_uuid);
                }}
              >
                <span className="svg-icon svg-icon-md svg-icon-danger">
                  <SVG src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')} />
                </span>
              </a>
              <span className="flex-shrink-0">{cell}</span>
            </div>
          )}
          {Boolean(values.uuid) && ORDER_FOR_RETURN_STATUS.includes(values.status) && (
            <div className="d-flex align-items-center">
              <a
                href={`/order/return/${row.order_return_uuid ? row.order_return_uuid : ''}`}
                target="_blank"
                title="Order Return"
                className={`btn btn-icon btn-light btn-hover-success btn-sm mr-5 flex-shrink-0`}
              >
                <span className="svg-icon svg-icon-md svg-icon-success">
                  <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Arrow-to-up.svg')} />
                </span>
              </a>
              <span className="flex-shrink-0">{cell}</span>
            </div>
          )}
        </>
      ),
    },
  ];

  return (
    <Card>
      <CardHeader title="Daftar Pembelian">
        <CardHeaderToolbar>
          {values.uuid &&
            [ORDER_STATUS.NEW, ORDER_STATUS.DRAFT, ORDER_STATUS.PAYMENT].includes(
              values.status
            ) && (
              <button
                style={{ width: '75px' }}
                className="btn btn-success d-flex justify-content-center align-items-center"
                onClick={submitOrderItems}
              >
                {actionsLoading ? (
                  <CircularProgress color="inherit" size={14} />
                ) : (
                  <span>Update</span>
                )}
              </button>
            )}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <BootstrapTable
          wrapperClasses="table-responsive"
          bordered={false}
          classes="table table-head-custom table-vertical-center overflow-hidden"
          bootstrap4
          remote
          keyField={ORDER_FOR_RETURN_STATUS.includes(values.status) ? 'uuid' : 'product_sku_uuid'}
          data={orderItems}
          columns={orderItemColumn}
        ></BootstrapTable>
      </CardBody>
    </Card>
  );
};
