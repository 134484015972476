import * as requestFromServer from './storyCrud';
import { storySlice, callTypes } from './storySlice';
import { toastOption } from '../../../../../_metronic/_helpers';
import { toast } from 'react-toastify';

const { actions } = storySlice;

export const fetchStories = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findStorys(queryParams)
    .then((response) => {
      const { total_items, items } = response;
      dispatch(actions.storysFetched({ total_items, items }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find stories";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchStoryAll = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllStorys(queryParams)
    .then((response) => {
      dispatch(actions.storyALlFetched({ allData: response.data.data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find storys";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchStory = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.storyFetched({ storyForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getStoryById(id)
    .then((response) => {
      dispatch(actions.storyFetched({ storyForEdit: response }));
      return response;
    })
    .catch((error) => {
      error.clientMessage = "Can't find story";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateStory = (story) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStory(story)
    .then((response) => {
      toast.success(response.data.message, toastOption);
      dispatch(actions.storyUpdated({ story }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update story";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteStory = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteStory(id)
    .then(() => {
      fetchStories();
      toast.success('Successful Story Brand!', toastOption);
    })
    .catch((error) => {
      console.log(error);
      error.clientMessage = "Can't delete category";
      toast.error('Delete Story Failed!', toastOption);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const storyFilter = (params) => (dispatch) => {
  dispatch(actions.startCall({ callTypes: callTypes.list }));
  dispatch(actions.storyFilter(params));
};
