import { createSlice } from "@reduxjs/toolkit";
import { initialFilter } from "../../pages/PurchaseUIHelpers";

const initialPurchasesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  purchaseForEdit: null,
  lastError: null,
  filters: initialFilter
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const purchaseSlice = createSlice({
  name: "purchases",
  initialState: initialPurchasesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getPurchaseById
    purchaseFetched: (state, action) => {
      state.actionsLoading = false;
      state.purchaseForEdit = action.payload.purchaseForEdit;
      state.error = null;
    },
    // findPurchases
    purchasesFetched: (state, action) => {
      const { total_items, items } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = items;
      state.totalCount = total_items;
    },
    // allPurchases
    purchaseALlFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.allData = action.payload.allData;
    },
    purchaseFilter: (state, action) => {
      state.listLoading = false
      state.error = null
      state.filters = action.payload
    },
    // createPurchase
    // purchaseCreated: (state, action) => {
    //   state.ewactionsLoading = false;
    //   state.error = null;
    //   state.entities.push(action.payload.purchase);
    // },
    // updatePurchase
    purchaseUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.map(entity => {
      //   if (entity.id === action.payload.purchase.id) {
      //     return action.payload.purchase;
      //   }
      //   return entity;
      // });
    },
  },
});
