import { useQuery } from "@apollo/client";
import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
import { FIND_PRODUCT_SALES_PROJECT } from "../../../_redux/project/projectCrud";
import ProjectDetailList from "./ProjectDetailList";

export default function ProjectDetail({ history, match }) {
  const {
    params: { id },
  } = match;

  const [entities, setEntities] = React.useState([]);

  const { data } = useQuery(FIND_PRODUCT_SALES_PROJECT, {
    variables: { project_uuid: id },
    onCompleted: (res) => {
      const { findProductSalesByProject } = res;
      setEntities(findProductSalesByProject.items);
    },
  });

  const backToList = () => {
    history.push("/master-data/project");
  };

  return (
    <>
      <Card>
        <CardHeader title="Project Detail">
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={backToList}
              aria-controls="collapse-filter-product"
            >
              <i className="fa fa-arrow-left"></i>
              Kembali
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <ProjectDetailList data={entities} />
      </Card>
    </>
  );
}
