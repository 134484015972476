import React, { useMemo, useState, useEffect, useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { Badge, Collapse } from "react-bootstrap";
import { OrderReportFilter } from "./order-report-filter/OrderReportFilter";
import { NotificationTable } from "./order-report-table/OrderReportTable";
import { useOrderReportUIContext } from "./OrderReportUIContext";
import { RoleChecker } from "../../../RoleChecker";
import { OrderReportEditDialog } from "./order-report-edit/OrderReportEdit";
import { OrderFeeTable } from "./OrderFeeTable";
import { OrderIncomeTable } from "./OrderIncomeTable";
import { OrderSummary } from "./OrderSummary";
import { useLazyQuery } from "@apollo/client";
import { FIND_ORDER_REPORT } from "../_redux/order-report/orderReportCrud";
import { toast } from "react-toastify";
import { toastOption } from "../../../../_metronic/_helpers/ToastHelpers";

export function OrderReportCard({ history }) {
  const orderReportUIContext = useOrderReportUIContext();
  const orderReportUIProps = useMemo(() => {
    return {
      queryParams: orderReportUIContext.queryParams,
      newOrderReportButtonClick: orderReportUIContext.newOrderButtonClick,
    };
  }, [orderReportUIContext]);
  const [statusFilter, setStatusFilter] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [orderFees, setOrderFees] = useState({});
  const [orderIncomes, setOrderIncomes] = useState({});
  const [totalNett, setTotalNett] = useState(0);
  // const [first, setfirst] = useState(second)

  const [findOrderReport] = useLazyQuery(FIND_ORDER_REPORT, {
    onCompleted: (data) => {
      if (data) {
        const { findOrderReport } = data ?? {};
        const {
          fee = [],
          income = [],
          total_fee = 0,
          total_income = 0,
          total_nett = 0,
        } = findOrderReport;

        setOrderFees({ total: total_fee, items: fee });
        setOrderIncomes({ total: total_income, items: income });
        setTotalNett(total_nett);
      }
    },
    onError: (error) => {
      toast.error(error, toastOption);
      console.error(error);
    },
    fetchPolicy: "cache-and-network",
  });

  const changeShow = () => {
    setOpen(!open);

    // Set Status Filter To False
    setStatusFilter(false);
    const filter = orderReportUIProps.queryParams;
    for (const [key, value] of Object.entries(filter)) {
      if (value !== "" && key) {
        setStatusFilter(true);
      }
    }
  };

  const didMount = useRef(false);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }

    console.log(orderReportUIProps.queryParams);

    setOrderFees({});
    setOrderIncomes({});
    setTotalNett(0);

    const { store_name, ...params } = orderReportUIProps.queryParams;

    if (params.list_status && params.list_status.length <= 0) {
      toast.warning(
        "Anda harus memilih salah satu status untuk menampilkan data!",
        toastOption
      );
      return;
    }
    if (!params.start_date || !params.end_date) {
      toast.warning(
        "Anda harus memilih tanggal mulai dan tanggal akhir untuk menampilkan data!",
        toastOption
      );
      return;
    }

    findOrderReport({
      variables: {
        orderReportInput: params,
      },
    });
  }, [orderReportUIProps.queryParams]);

  useEffect(() => {
    if (orderReportUIProps.filterType !== undefined) {
      setStatusFilter(true);
      // setIsDisabled(true);
    }
    // eslint-disable-next-line
  }, [orderReportUIProps.filterType]);

  return (
    <>
      {/* <OrderReportEditDialog
        show={showModal}
        onHide={() => setShowModal(false)}
        history={history}
      /> */}
      <OrderReportFilter />
      <OrderIncomeTable orderIncomes={orderIncomes} />
      <OrderFeeTable orderFees={orderFees} />
      <OrderSummary
        totalFee={orderFees.total}
        totalIncome={orderIncomes.total}
        totalNett={totalNett}
      />
    </>
  );
}
