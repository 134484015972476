import axios from "axios";
import { gql } from "@apollo/client";
import { QueryGraphql, MutationGraphql } from "../../../../config/ApoloRequest";
import { API } from "../../../../config/UrlEndPoint";

export const URL = `${API}master-data/dynamicConfig/`;

export const GET_CONFIG = gql`
  query ($keyname: String!) {
    getConfig(keyname: $keyname) {
      key_config
      type
      value_config
    }
  }
`;

export async function findDynamicConfigs() {
  const query = gql`
    {
      findConfig {
        key_config
        value_config
        type
      }
    }
  `;
  const newResponse = await QueryGraphql(query);
  return newResponse.data.findConfig;
}

export async function searchDynamicConfigs(queryParams) {
  let filter = {};

  for (const key in queryParams.filter) {
    if (queryParams.filter[key]) {
      filter = Object.assign(filter, { [key]: queryParams.filter[key] });
    }
  }

  if (!queryParams.filter["page_number"]) {
    filter.page_number = 1;
  }

  if (!queryParams.filter["page_size"]) {
    filter.page_size = 10;
  }

  // console.log(`
  //   query Query {
  //     findBranches(findBranchInput: ${JSON.stringify(filter).replace(/"([^"]+)":/g, "$1:")}
  //     ) {
  //       items {
  //         uuid
  //         name
  //       }
  //     }
  //   }
  // `);

  const query = gql`
    {
      findDynamicConfig(findDynamicConfigInput: ${JSON.stringify(filter).replace(
        /"([^"]+)":/g,
        "$1:"
      )}){
        items {
          uuid
          name
        }
      }
      
    }
  `;

  const newResponse = await QueryGraphql(query);
  return newResponse.data.findDynamicConfig;
}

export async function createDynamicConfig(payload) {
  const query = gql`
    mutation Mutation {
      createConfig(configInput: { key_config: "${payload.key_config}", value_config: "${payload.value_config}" }) {
        key_config
        value_config
      }
    }
  `;
  const newResponse = await MutationGraphql(query);
  return newResponse.data.createConfig;
}

export const CREATE_CONFIG = gql`
  mutation Mutation($key_config: String!, $type: String!, $value: String) {
    createConfig(configInput: { key_config: $key_config, type: $type, value_config: $value }) {
      key_config
      type
      value_config
    }
  }
`;

export const UPDATE_CONFIG = gql`
  mutation Mutation($key_config: String!, $type: String!, $value_config: String) {
    updateConfig(
      configInput: { key_config: $key_config, type: $type, value_config: $value_config }
    ) {
      key_config
      type
      value_config
    }
  }
`;

export async function updateDynamicConfig(payload) {
  const query = gql`
    mutation Mutation {
      updateConfig(configInput: { key_config: "${payload.key_config}", value_config: "${payload.value_config}" }) {
        key_config
        value_config
      }
    }
  `;

  const newResponse = await MutationGraphql(query);
  return newResponse.data.updateConfig;
}

export async function deleteDynamicConfig(key) {
  const query = gql`
    mutation Mutation {
      deleteConfig(key_config: "${key}") {
        message
      }
    }
  `;

  const newResponse = await MutationGraphql(query);
  return newResponse.data.deleteConfig;
}

export async function getDynamicConfigById(key) {
  const query = gql`
    {
      getConfig(keyname: "${key}") {
        key_config
        type
        value_config
      }
    }
  `;

  const newResponse = await QueryGraphql(query);
  return newResponse.data.getConfig;
}
