// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/member/memberActions";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../_metronic/_helpers";
import * as uiHelpers from "../MemberUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../_metronic/_partials/controls";
import { useMemberUIContext } from "../MemberUIContext";
import { RoleChecker } from "../../../../RoleChecker";

export function MemberTable() {
  const allowUpdate = RoleChecker("update", "member");
  // member UI Context
  const memberUIContext = useMemberUIContext();
  const memberUIProps = useMemo(() => {
    return {
      ids: memberUIContext.ids,
      setIds: memberUIContext.setIds,
      queryParams: memberUIContext.queryParams,
      setQueryParams: memberUIContext.setQueryParams,
      openEditMember: memberUIContext.openEditMember,
      // openDeleteMemberDialog: memberUIContext.openDeleteMemberDialog,
    };
  }, [memberUIContext]);

  // Getting curret state of member list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.members }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;

  // member Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.fetchMembers(memberUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberUIProps.queryParams, dispatch]);
  // Table columns
  const columns = [
    {
      dataField: "member_level_code",
      text: "Member Level Code",
      sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses,
    },

    {
      dataField: "name",
      text: "nama",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "hp",
      text: "HP",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditMember: memberUIProps.openEditMember,
        openDeleteMemberDialog: memberUIProps.openDeleteMemberDialog,
        allowUpdate,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: memberUIProps.queryParams.page_size,
    page: memberUIProps.queryParams.page_number,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="uuid"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  memberUIProps.setQueryParams
                )}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: memberUIProps.ids,
                //   setIds: memberUIProps.setIds,
                // })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
