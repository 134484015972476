export function getSelectorStyles(name, errors, touched) {
  return {
    control: (provided) => ({
      ...provided,
      width: '100%',
      border: errors[name] && touched[name] ? '1px solid rgb(246, 78, 96)' : '1px solid #ddd',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: errors[name] && touched[name] ? 'rgb(246, 78, 96)' : '#ddd',
    }),
  };
}
