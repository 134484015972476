import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';

import { adminSlice } from '../app/modules/MasterData/_redux/admin/adminSlice';
import * as auth from '../app/modules/Auth/_redux/authRedux';
import { brandSlice } from '../app/modules/MasterData/_redux/brand/brandSlice';
import { branchSlice } from '../app/modules/MasterData/_redux/branch/branchSlice';
import { categorySlice } from '../app/modules/MasterData/_redux/category/categorySlice';
import { dynamicConfigSlice } from '../app/modules/MasterData/_redux/dynamic-config/dynamicConfigSlice';
import { marketplaceOrderSlice } from '../app/modules/MarketplaceOrder/_redux/marketplaceOrder/marketplaceOrderSlice';
import { marketplaceTransactionSlice } from '../app/modules/MarketplaceTransaction/_redux/marketplaceTransaction/marketplaceTransactionSlice';
import { memberLevelSlice } from '../app/modules/MasterData/_redux/member-level/memberLevelSlice';
import { notificationSlice } from '../app/modules/NotificationsUser/_redux/notification/notificationSlice';
import { orderSlice } from '../app/modules/Order/_redux/order/orderSlice';
import { orderLogisticSlice } from '../app/modules/OrderLogistic/_redux/order/orderLogisticSlice';
import { paymentGatewaySlice } from '../app/modules/MasterData/_redux/payment-gateway/pgSlice';
import { paymentMethodSlice } from '../app/modules/MasterData/_redux/payment-method/pmSlice';
import { pickupSlice } from '../app/modules/PickupRequest/redux/pickup-request/pickupSlice';
import { productSlice } from '../app/modules/Products2/_redux/products/productSlice';
import { projectSlice } from '../app/modules/MasterData/_redux/project/projectSlice';
import { purchaseSlice } from '../app/modules/Purchase/_redux/purchase/purchaseSlice';
import { seasonSlice } from '../app/modules/MasterData/_redux/season/seasonSlice';
import { shipperServiceSlice } from '../app/modules/MasterData/_redux/shipper-service/shipperServiceSlice';
import { shippingCompanySlice } from '../app/modules/MasterData/_redux/shipping-company/shippingCompanySlice';
import { synchronizeMarketplaceSlice } from '../app/modules/SynchronizeMarketplace/_redux/synchronizeMarketplace/synchronizeMarketplaceSlice';
import { storySlice } from '../app/modules/Story/_redux/story/storySlice';
import { supplierSlice } from '../app/modules/MasterData/_redux/supplier/supplierSlice';
import { memberSlice } from '../app/modules/Member/_redux/member/memberSlice';
import { roleSlice } from '../app/modules/MasterData/_redux/role/roleSlice';
import { urlRouterSlice } from '../app/modules/MasterData/_redux/url-router/urlRouterSlice';
import { cmsSlice } from '../app/modules/MasterData/_redux/cms/cmsSlice';
import { campaignSlice } from '../app/modules/Campaign/_redux/campaignSlice';
import { storeSlice } from '../app/modules/MasterData/_redux/store/storeSlice';
import { versionPlatformSlice } from '../app/modules/MasterData/_redux/version-platform/versionPlatformSlice';
import { voucherSlice } from '../app/modules/Voucher/_redux/voucherSlice';

export const rootReducer = combineReducers({
  admin: adminSlice.reducer,
  auth: auth.reducer,
  brand: brandSlice.reducer,
  branch: branchSlice.reducer,
  categorys: categorySlice.reducer,
  campaign: campaignSlice.reducer,
  cms: cmsSlice.reducer,
  dynamicConfig: dynamicConfigSlice.reducer,
  marketplaceOrder: marketplaceOrderSlice.reducer,
  marketplaceTransaction: marketplaceTransactionSlice.reducer,
  memberLevels: memberLevelSlice.reducer,
  notificationUser: notificationSlice.reducer,
  orders: orderSlice.reducer,
  orderLogistic: orderLogisticSlice.reducer,
  paymentGateway: paymentGatewaySlice.reducer,
  paymentMethod: paymentMethodSlice.reducer,
  pickupRequest: pickupSlice.reducer,
  purchases: purchaseSlice.reducer,
  product: productSlice.reducer,
  project: projectSlice.reducer,
  seasons: seasonSlice.reducer,
  shipperService: shipperServiceSlice.reducer,
  shippingCompany: shippingCompanySlice.reducer,
  story: storySlice.reducer,
  store: storeSlice.reducer,
  suppliers: supplierSlice.reducer,
  synchronizeMarketplace: synchronizeMarketplaceSlice.reducer,
  members: memberSlice.reducer,
  roles: roleSlice.reducer,
  urlRouters: urlRouterSlice.reducer,
  versionPlatform: versionPlatformSlice.reducer,
  voucher: voucherSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
