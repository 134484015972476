export const RoleStatusCssClasses = ["danger", "success", "info", ""];
export const RoleStatusTitles = ["Suspended", "Active", "Pending", ""];
export const RoleTypeCssClasses = ["success", "primary", ""];
export const RoleTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "created_at", order: "desc" }];
export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
];
export const initialFilter = {
  name: "",
  sortOrder: "asc", // asc||desc
  sortField: "created_at",
  page_number: 1,
  page_size: 10,
};

export let initRole = {
  name: "",
  access: "",
};
