import React, { useState, useEffect } from 'react';
import { Formik, Field } from 'formik';
import ReactSelect from 'react-select';
import { Modal } from 'react-bootstrap';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import { DatePicker } from 'antd';
import * as Yup from 'yup';
import { Input } from '../../../../../_metronic/_partials/controls';
import { CircularProgress } from '@material-ui/core';
import { useLazyQuery } from '@apollo/client';
import Select from 'react-select';

const CreateProjectSchema = Yup.object().shape({
  name: Yup.string().required('Nama Project wajib diisi.'),
  start_date: Yup.date().required('Tanggal mulai wajib diisi.'),
  end_date: Yup.date().required('Tanggal berakhir wajib diisi.'),
});

export const CreateModal = ({ show, onHide, isLoading, submitCreate, schema }) => {
  return (
    <Modal size="md" show={show} onHide={onHide} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Buat Project Baru</Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: '',
          start_date: '',
          end_date: '',
        }}
        validationSchema={CreateProjectSchema}
        onSubmit={(values) => {
          submitCreate(values);
        }}
      >
        {({ handleSubmit, values, setFieldValue, errors }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              <div className="form-group">
                <div className="col">
                  <Field name="name" component={Input} placeholder="Gayalo" label="Nama Project" />
                </div>
              </div>
              <div className="form-group">
                <div className="col">
                  <span className="form-text ">Tanggal Mulai / Berakhir</span>
                  <DatePicker.RangePicker
                    style={{ width: '70%' }}
                    value={values.dateValue}
                    format={'YYYY/MM/DD'}
                    onChange={([start, end]) => {
                      setFieldValue(
                        'start_date',
                        start.hour(0).minute(0).second(0).format('YYYY-MM-DD HH:mm:ss')
                      );
                      setFieldValue(
                        'end_date',
                        end.hour(0).minute(0).second(0).format('YYYY-MM-DD HH:mm:ss')
                      );
                    }}
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button type="button" onClick={onHide} className="btn btn-light btn-elevate">
                Cancel
              </button>
              <button
                type="button"
                onClick={handleSubmit}
                className="btn btn-primary btn-elevate d-flex align-items-center justify-content-center"
                style={{ width: '75px' }}
              >
                {isLoading ? <CircularProgress color="white" size={14} /> : <span>Save</span>}
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
};
