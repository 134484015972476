import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Formik, Field } from 'formik';
import { Modal } from 'react-bootstrap';
import { Input } from '../../../../../../_metronic/_partials/controls';
import CurrencyInput from 'react-currency-input-field';

export const UpdateShipmentModal = ({ show, onHide, actionsLoading, initialValues, submit }) => {
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ ...initialValues }}
      onSubmit={(values) => {
        submit(values);
      }}
    >
      {({ handleSubmit, values, setFieldValue, errors }) => (
        <Modal size="lg" show={show} onHide={onHide} centered>
          <Modal.Header>
            <Modal.Title>Update Pengiriman</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group row">
              <div className="col-12 mb-5 mb-md-0">
                <label htmlFor="shipping_service_name">Layanan Pengiriman</label>
                <Field
                  id="shipping_service_name"
                  name="shipping_service_name"
                  disabled={true}
                  component={Input}
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-md-4 mb-5 mb-md-0">
                <label htmlFor="shipping_cost">Biaya Pengiriman</label>
                <CurrencyInput
                  id="shipping_cost"
                  name="shipping_cost"
                  placeholder="Rp 10.000"
                  className="form-control"
                  disabled={true}
                  intlConfig={{ locale: 'id-ID', currency: 'IDR' }}
                  decimalsLimit={2}
                  value={values.shipping_cost}
                  onValueChange={(value) => {
                    setFieldValue('shipping_cost', +value ?? 0);
                  }}
                />
              </div>
              <div className="col-md-4 mb-5 mb-md-0">
                <label htmlFor="real_shipping_cost">Real Shipping Cost</label>
                <CurrencyInput
                  id="real_shipping_cost"
                  name="real_shipping_cost"
                  placeholder="Rp 10.000"
                  className="form-control"
                  intlConfig={{ locale: 'id-ID', currency: 'IDR' }}
                  decimalsLimit={2}
                  value={values.real_shipping_cost}
                  onValueChange={(value) => {
                    setFieldValue('real_shipping_cost', +value ?? 0);
                  }}
                />
              </div>
              <div className="col-md-4 mb-5 mb-md-0">
                <label htmlFor="awb">AWB</label>
                <Field id="awb" name="awb" disabled={false} component={Input} />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              style={{ width: '75px' }}
              className="btn btn-secondary d-flex justify-content-center align-items-center"
              onClick={onHide}
            >
              <span>Cancel</span>
            </button>
            <button
              style={{ width: '75px' }}
              className="btn btn-primary d-flex justify-content-center align-items-center"
              onClick={() => !actionsLoading && handleSubmit()}
            >
              {actionsLoading ? <CircularProgress color="inherit" size={16} /> : <span>Save</span>}
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  );
};
