import { createSlice } from "@reduxjs/toolkit";
import { initialFilter } from "../../pages/version-platform/VersionPlatformUIHelpers";

const initialVersionPlatformsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  versionPlatformForEdit: undefined,
  lastError: null,
  filters: initialFilter
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const versionPlatformSlice = createSlice({
  name: "versionPlatforms",
  initialState: initialVersionPlatformsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getVersionPlatformById
    versionPlatformFetched: (state, action) => {
      state.actionsLoading = false;
      state.versionPlatformForEdit = action.payload.versionPlatformForEdit;
      state.error = null;
    },
    // findVersionPlatforms
    versionPlatformsFetched: (state, action) => {
      const { total_items, items } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = items;
      state.totalCount = total_items;
    },
    // getAllVersionPlatform
    versionPlatformAllFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.all = action.payload.all;
    },
    // createVersionPlatform
    versionPlatformCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.versionPlatform);
    },
    // updateVersionPlatform
    versionPlatformUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.versionPlatform.id) {
          return action.payload.versionPlatform;
        }
        return entity;
      });
    },
    versionPlatformFilter: (state, action) => {
      state.listLoading = false
      state.error = null
      state.filters = action.payload
    }
  },
});
