import React from "react";
import { Route } from "react-router-dom";
import { MemberEdit } from "./member-edit/MemberEdit";
import { MemberLoadingDialog } from "./member-loading-dialog/MemberLoadingDialog";
import { MemberUIProvider } from "./MemberUIContext";
import { MemberCard } from "./MemberCard";

export function MemberPage({ history }) {
  const memberUIEvents = {
    newMemberButtonClick: () => {
      history.push("/member/new");
    },
    openEditMember: (id) => {
      history.push(`/member/${id}/edit`);
    },
  };

  return (
    <MemberUIProvider memberUIEvents={memberUIEvents}>
      {/*<MemberLoadingDialog/>*/}
      {/* <Route path="/member/new">
        {({ history, match }) => (
          <MemberEdit
            show={match != null}
            onHide={() => {
              history.push("/member");
            }}
          />
        )}
      </Route>
      <Route path="/member/:id/edit">
        {({ history, match }) => (
          <MemberEdit
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/member");
            }}
          />
        )}
      </Route> */}
      <MemberCard history={history} />
    </MemberUIProvider>
  );
}
