import { createSlice } from "@reduxjs/toolkit";
import { initialFilter } from "../../pages/admin/AdminUIHelpers";

const initialCustomersState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  adminForEdit: undefined,
  lastError: null,
  filters: initialFilter
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const adminSlice = createSlice({
  name: "admins",
  initialState: initialCustomersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getCustomerById
    adminFetched: (state, action) => {
      state.actionsLoading = false;
      state.adminForEdit = action.payload.adminForEdit;
      state.error = null;
    },
    // findCustomers
    adminsFetched: (state, action) => {
      const { total_items, items } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = items;
      state.totalCount = total_items;
    },
    // createCustomer
    adminCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.admin);
    },
    // updateCustomer
    adminUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.admin.id) {
          return action.payload.admin;
        }
        return entity;
      });
    },
    adminFilter: (state, action) => {
      state.listLoading = false
      state.error = null
      state.filters = action.payload
    }
  },
});
