import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./NotificationUIHelpers";
import { useSelector } from "react-redux";

const NotificationUIContext = createContext();

export function useNotificationUIContext() {
  return useContext(NotificationUIContext);
}

export const NotificationUIConsumer = NotificationUIContext.Consumer;

export function NotificationUIProvider({ notificationUIEvents, children }) {
  const { filters } = useSelector(state => state.notificationUser)
  const [queryParams, setQueryParamsBase] = useState(filters);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initNotification = {
    id: undefined,
    firstName: "",
    lastName: "",
    email: "",
    userName: "",
    gender: "Female",
    status: 0,
    dateOfBbirth: "",
    ipAddress: "",
    type: 1,
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initNotification,
    newNotificationButtonClick: notificationUIEvents.newNotificationButtonClick,
    openEditNotification: notificationUIEvents.openEditNotification,
  };

  return (
    <NotificationUIContext.Provider value={value}>
      {children}
    </NotificationUIContext.Provider>
  );
}
