import React, { useMemo, useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { CampaignFilter } from "./campaign-filter/CampaignFilter";
import { CampaignTable } from "./campaign-table/CampaignTable";
import { Badge, Collapse } from "react-bootstrap";
import { useCampaignUIContext } from "./CampaignUIContext";
import { RoleChecker } from "../../../RoleChecker";
import { CampaignEditDialog } from "./campaign-edit-dialog/CampaignEditDialog";
import { useSelector } from "react-redux";

export const CampaignCard = ({ history }) => {
  const campaignUIContext = useCampaignUIContext();
  const campaignUIProps = useMemo(() => {
    return {
      ids: campaignUIContext.ids,
      newCampaignButtonClick: campaignUIContext.newCampaignButtonClick,
      queryParams: campaignUIContext.queryParams,
    };
  }, [campaignUIContext]);

  const [statusFilter, setStatusFilter] = useState(false);
  const [open, setOpen] = useState(false);
  const [campaignModal, setCampaignModal] = useState(false);

  const changeShow = () => {
    setOpen(!open);

    // Set Status Filter To False
    setStatusFilter(false);
    const filter = campaignUIProps.queryParams;
    for (const [key, value] of Object.entries(filter)) {
      if (value !== "" && key) {
        setStatusFilter(true);
      }
    }
  };

  useEffect(() => {
    if (campaignUIProps.filterType !== undefined) {
      setStatusFilter(true);
      // setIsDisabled(true);
    }
    // eslint-disable-next-line
  }, [campaignUIProps.filterType]);

  const { filters } = useSelector(state => state.campaign)
  useEffect(() => {
    if(filters.filterIsOpen) setOpen(true)
  }, [])

  return (
    <>
      <CampaignEditDialog
        show={campaignModal}
        onHide={() => setCampaignModal(false)}
      />
      <Card>
        <CardHeader title="Campaign List">
          <CardHeaderToolbar>
            {statusFilter && (
              <Badge className="mr-5" pill variant="danger">
                Filter Aktif
              </Badge>
            )}
            {RoleChecker("read", "campaign") && (
            <button
              type="button"
              className="btn btn-warning mr-2"
              onClick={changeShow}
              aria-controls="collapse-filter-campaign"
              aria-expanded={open}
            >
              Cari
            </button>
            )}
            {RoleChecker("create", "campaign") && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setCampaignModal(true)}
            >
              Tambah Campaign
            </button>
            )}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Collapse in={open}>
            <div id="collapse-filter-campaign">
              <CampaignFilter history={history} />
            </div>
          </Collapse>
          <CampaignTable />
        </CardBody>
      </Card>
    </>
  );
};
