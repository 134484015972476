// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/marketplaceTransactionbook/index.html?selectedKind=Work%20on%20Columns&selectedMarketplaceTransaction=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=marketplaceTransactionbook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import VisibilityIcon from "@material-ui/icons/Visibility";

export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  { showModal, id, allowDelete, allowUpdate, resendFunction }
) {
  return (
    <>
      <Link to={`/marketplace-transaction/${row.file_uuid}/list`}>
        <a
          title="Detail"
          className="btn btn-icon btn-light btn-hover-info btn-sm mx-3"
        >
          <span className="svg-icon svg-icon-md svg-icon-info">
            <VisibilityIcon />
            {/* <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            /> */}
          </span>
        </a>
      </Link>
    </>
  );
}
