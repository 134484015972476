/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
/* eslint-disable no-unused-vars*/
import React, { useState, useEffect } from 'react';
import { Modal, Form as Forms, Image } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Table } from 'react-bootstrap';
import { Input } from '../../../../../_metronic/_partials/controls';
import Select from 'react-select';
import * as Yup from 'yup';
import * as XLSX from 'xlsx';
import {
  getTokopediaTransactionCode,
  findRowIndexByLabels,
  getDescriptionFilter,
} from './utils/marketplace-trx.utils';
import { SEARCH_MEMBER } from '../../../Member/_redux/member/memberCrud';
import { marketplaceTransaction_type } from '../MarketplaceTransactionUIHelpers';
import { CreateBrandModal } from '../../../../components/create-brand-modal/BrandDialog';
import { useLazyQuery, useMutation } from '@apollo/client';
import { DatePicker, Space, TimePicker } from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import ReactSelect from 'react-select';
import { findStores } from '../../../MasterData/_redux/store/storeCrud';
import { FIND_ORDER_INVOICES } from '../../../Order/_redux/order/orderCrud';
import { FIND_MTRX_BY_INVOICE_CODE } from '../../_redux/marketplaceTransaction/marketplaceTransactionCrud';

export function MarketplaceTransactionEditForm({
  saveMarketplaceTransaction,
  marketplaceTransactionInitValue,
}) {
  const [selectedStore, setSelectedStore] = useState(null);
  const [reportHeaders, setReportHeaders] = useState({});
  const [marketplaceInvoices, setMarketplaceInvoices] = useState([]);
  const [orderInvoiceUuids, setOrderInvoiceUuids] = useState([]);
  const [trxRows, setTrxRows] = useState([]);
  const [mtrxs, setMtrxs] = useState([]);
  const [orderUpdates, setOrderUpdates] = useState([]);

  const [fetchOrders, { loading: orderLoading }] = useLazyQuery(FIND_ORDER_INVOICES, {
    onCompleted: (data) => {
      const { findOrder } = data ?? {};
      const { items = [] } = findOrder;

      setOrderInvoiceUuids(items);
    },
    fetchPolicy: 'no-cache',
  });

  const [fetchMtrxs] = useLazyQuery(FIND_MTRX_BY_INVOICE_CODE, {
    onCompleted: (data) => {
      const { findMarketplaceTrx } = data ?? {};
      const { items = [] } = findMarketplaceTrx;

      setMtrxs(items);
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (marketplaceInvoices.length > 0) {
      fetchOrders({
        variables: {
          marketplace_invoices: marketplaceInvoices,
        },
      });
    }
  }, [marketplaceInvoices]);

  useEffect(() => {
    setReportHeaders({});
    setMarketplaceInvoices([]);
    setOrderInvoiceUuids([]);
    setTrxRows([]);
    setOrderUpdates([]);
  }, [selectedStore]);

  useEffect(() => {
    let synch_count = 0;
    let unsynch_count = 0;

    const newTrxs = trxRows.map((item) => {
      const order = orderInvoiceUuids.find(
        (order) => order.marketplace_invoice === item.marketplace_invoice
      );

      if (order) {
        synch_count++;
        return { ...item, order_uuid: order.uuid, order_detected: true };
      }

      unsynch_count++;
      return { ...item, order_detected: false };
    });

    setTrxRows(newTrxs);
    setReportHeaders((prev) => ({ ...prev, synch_count, unsynch_count }));

    setTimeout(() => {
      fetchMtrxs({
        variables: {
          invoice_codes: newTrxs.map(({ marketplace_invoice, trans_code, description }) => ({
            invoice_no: marketplace_invoice,
            trans_code,
            description: getDescriptionFilter(trans_code, description),
          })),
        },
      });
    }, 0);
  }, [orderInvoiceUuids]);

  useEffect(() => {
    const newTrxs = trxRows.map((item) => {
      const mtrx = mtrxs.find(({ invoice_no, trans_code, description }) => {
        const { store_name } = selectedStore;
        const isTokped = (store_name + '').toLowerCase().includes('tokopedia');

        if (isTokped) {
          return (
            item.marketplace_invoice === invoice_no &&
            item.trans_code === trans_code &&
            getDescriptionFilter(item.trans_code, item.description) ===
              getDescriptionFilter(trans_code, description)
          );
        }

        return item.marketplace_invoice === invoice_no && item.trans_code === trans_code;
      });

      if (mtrx) {
        return { ...item, is_imported: true };
      }

      return { ...item, is_imported: false };
    });

    setTrxRows(newTrxs);
  }, [mtrxs]);

  // ! FETCH ALL DATA

  // * STORE
  const [storeOptions, setStoreOptions] = useState([]);

  useEffect(() => {
    findStores({
      is_active: null,
      market_name: '',
      platform: '',
      sort_order: 'DESC', // asc||desc
      sort_field: 'created_at',
      page_number: 1,
      page_size: 400,
    })
      .then((res) => setStoreOptions(res.items))
      .catch((err) => console.log(err));
  }, []);

  async function handleFileImport(file) {
    console.group('handleFileImport');

    try {
      if (!selectedStore) {
        return;
      }

      const { store_name } = selectedStore;

      if (!(file instanceof File)) {
        return;
      }

      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];

      if ((store_name + '').toLowerCase().includes('tokopedia')) {
        const withdraw_date = (worksheet['B2'] ?? {}).v;

        const { transactions, marketplaceInvoices, orders } =
          createTokopediaMarketplaceTrxs(worksheet);

        setReportHeaders({ withdraw_date });
        setTrxRows(transactions);
        setOrderUpdates(orders);
        setMarketplaceInvoices(marketplaceInvoices);

        if (marketplaceInvoices.length === 0) {
          fetchMtrxs({
            variables: {
              invoice_codes: transactions.map(
                ({ marketplace_invoice, trans_code, description }) => ({
                  invoice_no: marketplace_invoice,
                  trans_code,
                  description: getDescriptionFilter(trans_code, description),
                })
              ),
            },
          });
        }

        return;
      }

      if ((store_name + '').toLowerCase().includes('shopee')) {
        const withdraw_date = (worksheet['B3'] ?? {}).v;

        const { transactions, marketplaceInvoices, orders } =
          createShopeeMarketplaceTrxs(worksheet);

        setReportHeaders({ withdraw_date });
        setTrxRows(transactions);
        setOrderUpdates(orders);
        setMarketplaceInvoices(marketplaceInvoices);

        if (marketplaceInvoices.length === 0) {
          fetchMtrxs({
            variables: {
              invoice_codes: transactions.map(
                ({ marketplace_invoice, trans_code, description }) => ({
                  invoice_no: marketplace_invoice,
                  trans_code,
                  description: getDescriptionFilter(trans_code, description),
                })
              ),
            },
          });
        }
      }
    } catch (error) {
      console.error(error);
    }

    console.groupEnd();
  }

  function createTokopediaMarketplaceTrxs(worksheet) {
    const transactions = [];
    const marketplaceInvoices = [];
    const orders = [];

    if (!selectedStore) {
      return;
    }

    const { store_uuid } = selectedStore;

    let rowIndex = findRowIndexByLabels(
      ['Date', 'Description', 'Nominal (Rp)', 'Balance (Rp)'],
      worksheet
    );

    if (rowIndex < 0) {
      throw new Error('Invalid data shape');
    }

    try {
      while (worksheet[`A${rowIndex}`] && worksheet[`B${rowIndex}`] && worksheet[`C${rowIndex}`]) {
        const row = {
          trx_date: worksheet[`A${rowIndex}`].v,
          description: worksheet[`B${rowIndex}`].v,
          amount: +worksheet[`C${rowIndex}`].v,
          trans_code: '',
          order_detected: false,
          is_imported: false,
          marketplace_invoice: '',
          store_uuid,
        };

        row.marketplace_invoice =
          ((row.description ?? '').trim().match(/INV\/[A-Za-z0-9/]+/) ?? []).shift() ?? '';

        row.trans_code = getTokopediaTransactionCode(row.description);

        if (!row.marketplace_invoice) {
          row.order_detected = false;
        }

        transactions.push(row);

        const orderIndex = orders.findIndex(
          (order) => order['marketplace_invoice'] === row.marketplace_invoice
        );

        if (orderIndex > -1) {
          if (row.description.match(/pemotongan/i) || row.description.match(/dipotong/i)) {
            orders[orderIndex].net_amount = +orders[orderIndex].net_amount
              ? 0
              : +orders[orderIndex].net_amount + (row.amount ?? 0);
          }

          if (row.description.includes('Transaksi Penjualan Berhasil')) {
            orders[orderIndex].status = 'FINISH';
          }
        } else {
          const newOrder = {
            net_amount: 0,
            marketplace_invoice: row.marketplace_invoice,
            store_uuid: selectedStore.store_uuid,
            status: '',
          };

          if (row.description.includes('Pemotongan Biaya Layanan')) {
            newOrder.net_amount = row.amount;
          }

          if (row.description.includes('Transaksi Penjualan Berhasil')) {
            newOrder.status = 'FINISH';
          }

          if (row.marketplace_invoice) {
            orders.push(newOrder);
          }
        }

        if (row.marketplace_invoice && !marketplaceInvoices.includes(row.marketplace_invoice)) {
          marketplaceInvoices.push(row.marketplace_invoice);
        }

        rowIndex++;
      }

      return { transactions, marketplaceInvoices, orders };
    } catch (error) {
      console.error(error);
    }
  }

  function createShopeeMarketplaceTrxs(worksheet) {
    const transactions = [];
    const marketplaceInvoices = [];
    const orders = [];

    if (!selectedStore) {
      return;
    }

    let rowIndex = findRowIndexByLabels(
      ['Tanggal', 'Penerima Dana', 'Jumlah Dana', 'Deskripsi'],
      worksheet
    );

    if (rowIndex < 0) {
      throw new Error('Invalid data shape');
    }

    try {
      while (
        worksheet[`A${rowIndex}`] &&
        worksheet[`B${rowIndex}`] &&
        worksheet[`C${rowIndex}`] &&
        worksheet[`D${rowIndex}`]
      ) {
        const trxDate = moment((+worksheet[`A${rowIndex}`].v - 25569) * 86400 * 1000).format(
          'YYYY-MM-DD HH:mm:ss'
        );

        const row = {
          trx_date: trxDate,
          description: worksheet[`D${rowIndex}`].v,
          amount: +worksheet[`C${rowIndex}`].v,
          marketplace_invoice: '',
          store_uuid: selectedStore.store_uuid,
          trans_code: '',
          order_detected: false,
          is_imported: false,
        };

        row.trans_code = 'INCOME_SALES';

        if (row.description && row.description.includes('Penghasilan dari Pesanan')) {
          row.marketplace_invoice = row.description
            .split('Penghasilan dari Pesanan')
            .pop()
            .trim()
            .replace(/\W/g, '');

          if (!row.marketplace_invoice) {
            row.order_detected = false;
          }

          transactions.push(row);

          if (row.marketplace_invoice && !marketplaceInvoices.includes(row.marketplace_invoice)) {
            marketplaceInvoices.push(row.marketplace_invoice);
          }
        }

        rowIndex++;
      }

      return { transactions, marketplaceInvoices, orders };
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={marketplaceTransactionInitValue}
        onSubmit={() => {
          if (!selectedStore) {
            return;
          }

          const payload = {
            store_uuid: selectedStore.store_uuid,
            marketplace_transactions: trxRows,
            order_updates: orderUpdates,
          };

          saveMarketplaceTransaction(payload);
        }}
      >
        {({ handleSubmit, setFieldValue, handleChange, errors, values }) => (
          <>
            <Modal.Body>
              <div className="form-group row">
                <div className="col">
                  <label>Toko</label>
                  <ReactSelect
                    isClearable
                    name="store_uuid"
                    placeholder="Cari Store"
                    options={storeOptions.map((item) => ({
                      label: `${item.market_name} - ${item.platform}`,
                      value: item.uuid,
                    }))}
                    value={
                      values.store_uuid
                        ? {
                            label: values.store_name,
                            value: values.store_uuid,
                          }
                        : null
                    }
                    onChange={(e) => {
                      const value = e;
                      setFieldValue('store_uuid', value && value.value);
                      setFieldValue('store_name', value && value.label);
                      setSelectedStore({ store_uuid: e.value, store_name: e.label });
                    }}
                  />
                </div>
              </div>
              {selectedStore && (
                <div className="form-group row">
                  <div className="col">
                    <Forms.Group controlId="formFiles" className="mb-3">
                      <Forms.Label>Pilih File</Forms.Label>
                      <Forms.Control
                        type="file"
                        name="file"
                        accept=".xls, .csv, .xlsx"
                        onChange={(e) => {
                          if (!(e.target.files instanceof FileList)) {
                            return;
                          }

                          const [file] = e.target.files;

                          setFieldValue('file', file);
                          handleFileImport(file);
                        }}
                      />
                    </Forms.Group>
                  </div>
                </div>
              )}
            </Modal.Body>
            <Modal.Body>
              <div className="mb-3">
                <div>Tanggal Penarikan: {reportHeaders.withdraw_date ?? ''}</div>
                <div>Jumlah transaksi: {trxRows.length}</div>
                <div>Jumlah terdeteksi: {reportHeaders.synch_count ?? 0}</div>
                <div>Jumlah tidak terdeteksi: {reportHeaders.unsynch_count ?? 0}</div>
              </div>
              <Table striped hover responsive className="text-left">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Tanggal Transaksi</th>
                    <th>Deskripsi</th>
                    <th>Kode Transaksi</th>
                    <th>Jumlah</th>
                    <th>Order Ditemukan</th>
                  </tr>
                </thead>
                <tbody>
                  {trxRows.map(
                    (
                      { trx_date, description, amount, trans_code, order_detected, is_imported },
                      index
                    ) => (
                      <tr key={index + 1}>
                        <td>{index + 1}</td>
                        <td>{trx_date}</td>
                        <td>{description}</td>
                        <td>{trans_code}</td>
                        <td>{amount}</td>
                        <td
                          className={`
                            ${
                              is_imported
                                ? 'text-warning'
                                : order_detected
                                ? 'text-success'
                                : 'text-danger'
                            }
                          `}
                        >
                          {is_imported ? 'imported' : order_detected ? 'detected' : 'undetected'}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Simpan
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
