import React, { useMemo, useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { Badge, Collapse } from "react-bootstrap";
import { MarketplaceOrderFilter } from "./marketplaceOrder-filter/MarketplaceOrderFilter";
import { MarketplaceOrderTable } from "./marketplaceOrder-table/MarketplaceOrderTable";
import { useMarketplaceOrderUIContext } from "./MarketplaceOrderUIContext";
import { RoleChecker } from "../../../RoleChecker";
import { MarketplaceOrderEditDialog } from "./marketplaceOrder-edit/MarketplaceOrderEdit";
import { shallowEqual, useSelector } from "react-redux";

export function MarketplaceOrderCard({ history }) {
  const marketplaceOrderUIContext = useMarketplaceOrderUIContext();
  const marketplaceOrderUIProps = useMemo(() => {
    return {
      queryParams: marketplaceOrderUIContext.queryParams,
      newMarketplaceOrderButtonClick:
        marketplaceOrderUIContext.newMarketplaceOrderButtonClick,
    };
  }, [marketplaceOrderUIContext]);

  const [statusFilter, setStatusFilter] = useState(false);
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const changeShow = () => {
    setOpen(!open);

    // Set Status Filter To False
    setStatusFilter(false);
    const filter = marketplaceOrderUIProps.queryParams;
    for (const [key, value] of Object.entries(filter)) {
      if (value !== "" && key) {
        setStatusFilter(true);
      }
    }
  };

  useEffect(() => {
    if (marketplaceOrderUIProps.filterType !== undefined) {
      setStatusFilter(true);
      // setIsDisabled(true);
    }
    // eslint-disable-next-line
  }, [marketplaceOrderUIProps.filterType]);

  const { filters } = useSelector((state) => state.marketplaceOrder, shallowEqual);

  useEffect(() => {
    if (filters.filterIsOpen) {
      setOpen(true)
    }
  }, [])


  return (
    <>
      <Card>
        <CardHeader title="Daftar Marketplace Order">
          <CardHeaderToolbar>
            {statusFilter && (
              <Badge className="mr-5" pill variant="danger">
                Filter Aktif
              </Badge>
            )}
            {RoleChecker("read", "order") && (
              <button
                type="button"
                className="btn btn-warning mr-2"
                onClick={changeShow}
                aria-controls="collapse-filter-order"
                aria-expanded={open}
              >
                Cari
              </button>
            )}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Collapse in={open}>
            <div id="collapse-filter-marketplaceOrder">
              <MarketplaceOrderFilter history={history} />
            </div>
          </Collapse>

          <MarketplaceOrderTable />
        </CardBody>
      </Card>
    </>
  );
}
