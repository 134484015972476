import React, { useState, useEffect } from 'react';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import CurrencyInput from 'react-currency-input-field';
import AsyncSelect from 'react-select/async';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  Input,
} from '../../../../../_metronic/_partials/controls';
import { gql, useLazyQuery } from '@apollo/client';
import { useOrderReturnContext } from './context';
import { getSelectorStyles } from '../../../../../_metronic/_helpers/style-helpers';
import { useHistory } from 'react-router-dom';

const FIND_ORDERS = gql`
  query ($findOrderInput: FindOrderInput) {
    findOrder(findOrderInput: $findOrderInput) {
      items {
        uuid
        invoice_no
        marketplace_invoice
        order_delivery {
          uuid
          awb
          shipping_cost
        }
      }
    }
  }
`;

export const OrderSelectForm = () => {
  const history = useHistory();
  const { values, setFieldValue, errors, touched } = useFormikContext();
  const { order_uuid } = values;
  const { getOrder } = useOrderReturnContext();

  const [currentTimeout, setCurrentTimeout] = useState(null);
  const [currentCallback, setCurrentCallback] = useState(() => null);

  const [fetchOrders, { loading }] = useLazyQuery(FIND_ORDERS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const { findOrder } = data ?? {};
      const { items = [] } = findOrder;

      const options = items.map(({ uuid, invoice_no, marketplace_invoice }) => ({
        invoice_no,
        marketplace_invoice,
        label: `${invoice_no}${marketplace_invoice ? ' - ' + marketplace_invoice : ''}`,
        value: uuid,
      }));

      currentCallback(options);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  useEffect(() => {
    if (order_uuid) {
      getOrder(order_uuid);
    }
  }, [order_uuid]);

  function loadOrderOptions(inputValue, callback) {
    clearTimeout(currentTimeout);

    setCurrentTimeout(
      setTimeout(() => {
        fetchOrders({
          variables: {
            findOrderInput: {
              search_invoices: inputValue,
              page_number: 1,
              page_size: 10,
            },
          },
        });
      }, 250)
    );

    setCurrentCallback(() => callback);
  }

  return (
    <Card>
      <CardHeader title="Order Return">
        <CardHeaderToolbar>
          <button className="btn btn-secondary" onClick={() => history.goBack()}>
            Kembali
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="form-group row">
          <div className="col">
            <label htmlFor="order_uuid">Order</label>
            <AsyncSelect
              name="order_uuid"
              placeholder="Invoice/Marketplace Invoice"
              loadOptions={loadOrderOptions}
              value={{
                label: values.invoice_no
                  ? `${values.invoice_no}${
                      values.marketplace_invoice ? ' - ' + values.marketplace_invoice : ''
                    }`
                  : '',
                value: values.order_uuid ?? '',
              }}
              onChange={({ label, value, invoice_no, marketplace_invoice }) => {
                setFieldValue('invoice_no', invoice_no);
                setFieldValue('marketplace_invoice', marketplace_invoice ?? '');
                setFieldValue('order_uuid', value);
              }}
              styles={getSelectorStyles('order_uuid', errors, touched)}
              isDisabled={Boolean(values.uuid)}
            />
            <ErrorMessage
              name="order_uuid"
              render={(msg) => (
                <span className="mt-1 text-danger" style={{ fontSize: '0.9rem' }}>
                  {msg}
                </span>
              )}
            />
          </div>
          <div className="col">
            <label htmlFor="shipping_cost">Shipping Cost</label>
            <CurrencyInput
              name="shipping_cost"
              placeholder="Rp 25.000"
              intlConfig={{ locale: 'id-ID', currency: 'IDR' }}
              className="form-control"
              style={{ textAlign: 'right' }}
              value={values.shipping_cost}
              onValueChange={(value) => setFieldValue('shipping_cost', value)}
              decimalsLimit={2}
              disabled={Boolean(values.uuid)}
            />
          </div>
          <div className="col">
            <label htmlFor="shipping_awb">Shipping AWB</label>
            <Field name="shipping_awb" component={Input} disabled={Boolean(values.uuid)} />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
