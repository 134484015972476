import React from "react";
import { Route } from "react-router-dom";
import { CampaignLoadingDialog } from "./campaign-loading-dialog/CampaignLoadingDialog";
import { CampaignEditDialog } from "./campaign-edit-dialog/CampaignEditDialog";
import { CampaignUIProvider } from "./CampaignUIContext";
import { CampaignCard } from "./CampaignCard";
import { CampaignDisableDialog } from "./campaign-disable-dialog/CampaignDisableDialog";

export const CampaignPage = ({ history }) => {
  const campaignUIEvents = {
    newCampaignButtonClick: () => {
      history.push("/campaign/new");
    },
    openEditCampaignDialog: (id) => {
      history.push(`/campaign/${id}/edit`);
    },
    openDisableCampaignDialog: (id) => {
      history.push(`/campaign/${id}/disable`);
    },
    openFetchCampaignDialog: () => {
      history.push(`/campaign/fetch`);
    },
    openUpdateCampaignStatusDialog: () => {
      history.push("/campaign/updateStatus");
    },
  };

  return (
    <CampaignUIProvider campaignUIEvents={campaignUIEvents}>
      {/*<CampaignLoadingDialog />*/}
      <Route path="/campaign/new">
        {({ history, match }) => (
          <CampaignEditDialog
            show={match != null}
            onHide={() => {
              history.push("/campaign");
            }}
          />
        )}
      </Route>
      <Route path="/campaign/:id/edit">
        {({ history, match }) => (
          <CampaignEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/campaign");
            }}
          />
        )}
      </Route>
      <Route path="/campaign/:id/disable">
        {({ history, match }) => (
          <CampaignDisableDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/campaign");
            }}
          />
        )}
      </Route>
      <CampaignCard history={history} />
    </CampaignUIProvider>
  );
};
