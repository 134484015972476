import { createSlice } from "@reduxjs/toolkit";

const initialOrderLogisticsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  orderLogisticForEdit: undefined,
  lastError: null,
  filter: {
    branches: [],
    members: [],
    statuses: ["unpaid", "paid", "processed", "delivery", "finished"],
  },
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const orderLogisticSlice = createSlice({
  name: "orderLogisticSlice",
  initialState: initialOrderLogisticsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getOrderLogisticById
    orderLogisticFetched: (state, action) => {
      state.actionsLoading = false;
      state.orderLogisticForEdit = action.payload.orderLogisticForEdit;
      state.error = null;
    },
    // findOrderLogistics
    orderLogisticsFetched: (state, action) => {
      const {
        total_items,
        items,
        total_real_shipping_cost,
        total_shipping_cost,
      } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = items;
      state.totalCount = total_items;
      state.total_real_shipping_cost = total_real_shipping_cost;
      state.total_shipping_cost = total_shipping_cost;
    },
    // createOrderLogistic
    orderLogisticCreated: (state, action) => {
      state.ewactionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.orderLogistic);
    },
    // updateOrderLogistic
    orderLogisticUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.orderLogistic.id) {
          return action.payload.orderLogistic;
        }
        return entity;
      });
    },
    // orderLogisticsUpdateState
    orderLogisticStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    orderLogisticFiltersFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;

      const { branches, members } = action.payload;

      state.filter.branches = branches.items.map((branch) => branch);
      state.filter.members = members.items.map((member) => member);
    },
  },
});
