import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '../SvgIcon'
import { defaultFill } from '../constant'

const CreditCard = props => (
  <SvgIcon {...props}>
    <path fill={props.fill} className="custom-fill" d="M20 4H4A2 2 0 0 0 2 6V18A2 2 0 0 0 4 20H20A2 2 0 0 0 22 18V6A2 2 0 0 0 20 4M20 11H4V8H20Z" />
  </SvgIcon>
)

CreditCard.propTypes = {
  fill: PropTypes.string,
}

CreditCard.defaultProps = {
  fill: defaultFill,
}

export default CreditCard
