import React, { useEffect, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { useDispatch } from 'react-redux';
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
} from '../../../../../_metronic/_helpers';
import {
  Card,
  CardBody,
  CardHeader,
  Pagination,
} from '../../../../../_metronic/_partials/controls';
import { initialParams, sizePerPageList } from './LimitedStockHelper';

const FIND_LIMITED_STOCK = gql`
  query Q($findLimitedStockInput: FindOverStockedSkuInput) {
    __typename
    findLimitedStock(findLimitedStockInput: $findLimitedStockInput) {
      items {
        sku
        product_name
        variant_value_1
        variant_value_2
        stock
      }
      total_items
    }
  }
`;

export const LimitedStockTable = () => {
  const [queryParams, setQueryParams] = useState(initialParams);

  const [data, setData] = useState([]);
  const [totalItem, setTotalItem] = useState(0);

  const [findOverStock, { loading }] = useLazyQuery(FIND_LIMITED_STOCK, {
    onCompleted: (data) => {
      const { findLimitedStock } = data ?? {};
      const { items = [], total_items = 0 } = findLimitedStock;

      setData(items);
      setTotalItem(total_items);
    },
    onError: (err) => {
      console.log('err', err);
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    findOverStock({
      variables: {
        findLimitedStockInput: {
          ...queryParams,
        },
      },
    });
  }, [queryParams]);

  const columns = [
    {
      dataField: 'sku',
      text: 'SKU',
      sort: true,
    },
    {
      dataField: 'product_name',
      text: 'Nama Produk',
      sort: true,
    },
    {
      dataField: '',
      text: 'Variant',
      sort: true,
      formatter: (cell, row, indexRow) => {
        const variant1 = row.variant_value_1;
        const variant2 = row.variant_value_2;
        return (
          <>
            <span>{variant1 && variant1}</span>
            {variant2 && <span>, {variant2}</span>}
          </>
        );
      },
    },
    {
      dataField: 'stock',
      text: 'Stok',
      sort: true,
    },
  ];

  const paginationOptions = {
    custom: true,
    totalSize: totalItem,
    sizePerPageList: sizePerPageList,
    sizePerPage: queryParams.page_size,
    page: queryParams.page_number,
  };

  return (
    <>
      <Card>
        <CardHeader title="Limited Stock" />
        <CardBody>
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => {
              return (
                <Pagination isLoading={loading} paginationProps={paginationProps}>
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    bordered={false}
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    bootstrap4
                    remote
                    keyField="sku"
                    onTableChange={getHandlerTableChange(setQueryParams)}
                    data={data}
                    columns={columns}
                    {...paginationTableProps}
                  ></BootstrapTable>
                </Pagination>
              );
            }}
          </PaginationProvider>
        </CardBody>
      </Card>
    </>
  );
};
