import React from "react";
import { Carousel, CarouselItem, Modal } from "react-bootstrap";

export const ImagesModal = ({ images, show, onHide }) => {
  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Images</Modal.Title>
      </Modal.Header>
      <Modal.Body className="overlay overlay-block cursor-default">
        <Carousel>
          {images instanceof Array ? (
            images.map((image) => (
              <CarouselItem>
                <img
                  width="100%"
                  style={{ objectFit: "cover" }}
                  src={image.url ?? image.images.url}
                  alt=""
                />
              </CarouselItem>
            ))
          ) : (
            <img
              width="100%"
              style={{ objectFit: "cover" }}
              src={images}
              alt=""
            />
          )}
        </Carousel>
      </Modal.Body>
    </Modal>
  );
};
