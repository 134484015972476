import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Formik } from 'formik';
import * as yup from 'yup';
import { CONFIG_HELPER } from '../../../../config/Helper';
import { ORDER_STORE_CONFIGS } from '../../_redux/order/orderCrud';
import { getOrderInitialValue } from '../OrderUIHelpers';
import { OrderFormContext } from './FormContext';
import { OrderBasicForm } from './OrderBasicForm';
import { OrderFormActions } from './OrderFormActions';
import { OrderGrandTotal } from './OrderGrandTotal';
import { OrderItemForm } from './OrderItemForm';
import { OrderItemTable } from './OrderItemTable';
import { OrderLogsContainer } from './OrderLogs/OrderLogsContainer';
import { OrderMemberAddressForm } from './OrderMemberAddressForm';
import { OrderMemberAddressTable } from './OrderMemberAddressTable';
import { OrderMemberForm } from './OrderMemberForm';
import { OrderPaymentForm } from './OrderPaymentForm';
import { OrderShipmentForm } from './OrderShipmentForm';

const OrderSchema = yup.object().shape({
  trx_date: yup.date().required('Tanggal transaksi wajib diisi.'),
  is_online: yup.boolean().required('Online/Offline wajib dipilih.'),
  is_dropship: yup.boolean().required('Order Dropship wajib dipilih.'),
  dropship_name: yup.string().when('is_dropship', {
    is: true,
    then: (schema) => schema.required('Nama Dropship wajib diisi.'),
    otherwise: (schema) => schema.nullable(),
  }),
  dropship_hp: yup.string().when('is_dropship', {
    is: true,
    then: (schema) => schema.required('No Hp Dropship wajib diisi.'),
    otherwise: (schema) => schema.nullable(),
  }),
  store: yup.object().shape({
    uuid: yup.string().required('Store wajib dipilih.'),
  }),
  member: yup.object().when('is_online', {
    is: true,
    then: (schema) =>
      schema.shape({
        uuid: yup.string().required('Member wajib dipilih.'),
      }),
    otherwise: (schema) => schema.nullable(),
  }),
  order_payment: yup.object().shape({
    payment_method_uuid: yup.string().optional(),
  }),
  order_delivery: yup.object().when('is_online', {
    is: true,
    then: (schema) =>
      schema.shape({
        address: yup.string().required('Alamat wajib diisi.'),
        sub_district: yup.string().required('Kota/Kecamatan wajib diisi.'),
        city: yup.string().required('Kota/Kecamatan wajib diisi.'),
        codepos: yup.string().required('Kode Pos wajib diisi.'),
        province: yup.string().required('Provinsi wajib diisi.'),
        receiver_name: yup.string().required('Nama Penerima wajib diisi.'),
        receiver_hp: yup.string().required('Nomor Hp Penerima wajib diisi.'),
        service_snapshot: yup.object().shape({
          company_code: yup.string().optional(),
          comany_name: yup.string().optional(),
          service_code: yup.string().optional(),
          service_name: yup.string().optional(),
          price: yup.number().optional(),
          final_price: yup.number().optional(),
          uuid: yup.string().required('Layanan Pengiriman wajib dipilih.'),
        }),
      }),
    otherwise: (schema) => schema.nullable(),
  }),
});

export const OrderForm = ({ order, actionsLoading, submitOrder }) => {
  // * States
  const [orderItems, setOrderItems] = useState([]);
  const [configs, setConfigs] = useState({
    default_branch_uuid: '',
    default_store_uuid: '',
  });

  // * Queries
  const [fetchDynamicConfigs] = useLazyQuery(ORDER_STORE_CONFIGS, {
    onCompleted: (data) => {
      const { defaultBranchConfig, manualStoreConfig } = data ?? {};

      setConfigs({
        default_branch_uuid: defaultBranchConfig.value_config,
        default_store_uuid: manualStoreConfig.value_config,
      });
    },
    onError: (error) => {
      console.error(error);
    },
    fetchPolicy: 'cache-and-network',
  });

  // * Effects
  useEffect(() => {
    fetchDynamicConfigs({
      variables: {
        branch_key: CONFIG_HELPER.default_branch_uuid,
        manual_key: CONFIG_HELPER.manual_store,
      },
    });
  }, []);

  useEffect(() => {
    if (order && order.order_item instanceof Array) {
      const { order_item } = order;

      setOrderItems(
        order_item.map(({ uuid, qty, notes, assigned_price, product_sku, order_return_uuid }) => ({
          uuid,
          qty,
          notes,
          order_return_uuid,
          product_sku_uuid: product_sku.uuid,
          product_name: product_sku.product.name,
          product_sku: product_sku.sku,
          assigned_price: assigned_price,
          total_price: +qty * +assigned_price,
          weight: +qty * +product_sku.product.weight,
          variant_value_1: product_sku.variant_value_1,
          variant_value_2: product_sku.variant_value_2,
        }))
      );
    }
  }, [order]);

  // * Functions
  function addProduct(newProductSku) {
    const itemIndex = orderItems.findIndex(
      (oi) => oi.product_sku_uuid === newProductSku.product_sku_uuid
    );

    if (itemIndex > -1) {
      return setOrderItems((prev) => {
        const temp = [...prev];

        const item = temp[itemIndex];
        const qty = +item.qty + +newProductSku.qty;

        temp[itemIndex] = {
          ...item,
          qty,
          assigned_price: +newProductSku.assigned_price,
          total_price: +qty * +newProductSku.assigned_price,
          variant_value_1: newProductSku.variant_value_1,
          variant_value_2: newProductSku.variant_value_2,
        };

        return temp;
      });
    }

    setOrderItems((prev) => [...prev, newProductSku]);
  }

  function removeProduct(product_sku_uuid) {
    setOrderItems((prev) => prev.filter((prev) => prev.product_sku_uuid !== product_sku_uuid));
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={getOrderInitialValue(order)}
      validationSchema={OrderSchema}
      onSubmit={(values) => {
        values.branch_uuid = configs.default_branch_uuid;

        if (!values.store.uuid) {
          values.store.uuid = configs.default_store_uuid;
        }

        submitOrder({ ...values, order_item: orderItems });
      }}
    >
      {() => (
        <>
          <OrderFormContext.Provider
            value={{
              order,
              orderItems,
              addProduct,
              removeProduct,
              configs,
            }}
          >
            <OrderBasicForm />
            <OrderMemberForm />
            <OrderMemberAddressTable />
            <OrderMemberAddressForm />
            <OrderItemForm />
            <OrderItemTable />
            <OrderShipmentForm />
            <OrderGrandTotal />
            <OrderLogsContainer />
            <OrderPaymentForm />
            <OrderFormActions />
          </OrderFormContext.Provider>
        </>
      )}
    </Formik>
  );
};
