// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useState, useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/campaignActions";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
  toastOption,
} from "../../../../../_metronic/_helpers";
import * as uiHelpers from "../CampaignUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../_metronic/_partials/controls";
import { useCampaignUIContext } from "../CampaignUIContext";
import { RoleChecker } from "../../../../RoleChecker";
import { ImagesModal } from "../../../../components/ImagesModal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { CampaignEditDialog } from "../campaign-edit-dialog/CampaignEditDialog";
import { CampaignDisableDialog } from "../campaign-disable-dialog/CampaignDisableDialog";
import { setImageResolution } from "../../../../config/Helper";
import { toast } from "react-toastify";

export function CampaignTable() {
  const allowDelete = RoleChecker("delete", "campaign");
  const allowUpdate = RoleChecker("update", "campaign");
  // Campaign UI Context
  const campaignUIContext = useCampaignUIContext();
  const campaignUIProps = useMemo(() => {
    return {
      ids: campaignUIContext.ids,
      setIds: campaignUIContext.setIds,
      queryParams: campaignUIContext.queryParams,
      setQueryParams: campaignUIContext.setQueryParams,
      openEditCampaignDialog: campaignUIContext.openEditCampaignDialog,
      openDeleteCampaignDialog: campaignUIContext.openDeleteCampaignDialog,
      openDisableCampaignDialog: campaignUIContext.openDisableCampaignDialog,
    };
  }, [campaignUIContext]);

  // Getting curret state of campaign list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.campaign }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;

  const [showModal, setShowModal] = useState(false);
  const [currentImages, setCurrentImages] = useState(null);

  const [currentId, setCurrentId] = useState("");
  const [campaignModal, setCampaignModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    setShowModal(Boolean(currentImages));
  }, [currentImages]);

  // Campaign Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.findCampaign(campaignUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignUIProps.queryParams, dispatch]);

  // Table columns
  const columns = [
    {
      dataField: "name",
      text: "Nama",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "slug",
      text: "Slug",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "title",
      text: "Judul",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    // {
    //   dataField: "content",
    //   text: "Content",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   headerSortingClasses,
    // },
    {
      dataField: "dynamic_link",
      text: "Link",
      formatter: (cell) => {
        return (
          <>
            <OverlayTrigger overlay={<Tooltip>Klik untuk copy link!</Tooltip>}>
              <a
                onClick={() => {
                  navigator.clipboard.writeText(cell);
                  toast.success("Berhasil copy link!", toastOption);
                }}
              >
                {cell}
              </a>
            </OverlayTrigger>
          </>
        );
      },
    },
    {
      dataField: "hit_count",
      text: "Hit Count",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "images",
      text: "Images",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cell, row) => {
        if (cell instanceof Array && cell.length > 0) {
          
          const thumbnail = setImageResolution(cell[0].url, "512");
  
          const filterImage = cell.map((item) => {
            return { url: setImageResolution(item.url, "1080") };
          });
  
          return (
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-top">Click For Detail Image!</Tooltip>
              }
            >
              <div style={{ width: "145px", height: "85px" }}>
                {cell instanceof Array && cell[0] && (
                  <img
                    style={{ objectFit: "contain", cursor: "pointer" }}
                    onClick={() => {
                      setCurrentImages(filterImage);
                    }}
                    className="shadow rounded"
                    src={thumbnail}
                    alt="campaign"
                    width="100%"
                    height="100%"
                  />
                )}
              </div>
            </OverlayTrigger>
          );
        } else {
          return (<div>Campaign ini belum memiliki foto</div>);
        }
      },
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditCampaignDialog: campaignUIProps.openEditCampaignDialog,
        openDisableCampaignDialog: campaignUIProps.openDisableCampaignDialog,
        allowDelete,
        allowUpdate,
        setCurrentId,
        setCampaignModal,
        setDeleteModal,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: campaignUIProps.queryParams.page_size,
    page: campaignUIProps.queryParams.page_number,
  };

  return (
    <>
      <CampaignEditDialog
        show={campaignModal}
        onHide={() => {
          setCampaignModal(false);
          setCurrentId("");
        }}
        id={currentId}
      />
      <CampaignDisableDialog
        show={deleteModal}
        onHide={() => {
          setDeleteModal(false);
          setCurrentId("");
        }}
        id={currentId}
      />
      <ImagesModal
        show={showModal}
        images={currentImages}
        onHide={() => setCurrentImages(null)}
      />
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="uuid"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  campaignUIProps.setQueryParams
                )}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: campaignUIProps.ids,
                //   setIds: campaignUIProps.setIds,
                // })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
