import { createSlice } from '@reduxjs/toolkit';
import { initialFilter } from '../../pages/StoryUIHelpers';

const initialStorysState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  storyForEdit: undefined,
  lastError: null,
  filters: initialFilter,
};
export const callTypes = {
  list: 'list',
  action: 'action',
};

export const storySlice = createSlice({
  name: 'storys',
  initialState: initialStorysState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    storyFetched: (state, action) => {
      state.actionsLoading = false;
      state.storyForEdit = action.payload.storyForEdit;
      state.error = null;
    },
    storysFetched: (state, action) => {
      const { total_items, items } = action.payload;

      state.listLoading = false;
      state.error = null;
      state.entities = items;
      state.totalCount = total_items;
    },
    storyALlFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.allData = action.payload.allData;
    },
    storyUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.story.id) {
          return action.payload.story;
        }
        return entity;
      });
    },
    storyFilter: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.filters = action.payload;
    },
  },
});
