import { API } from '../../../../config/UrlEndPoint';
import { gql, useMutation } from '@apollo/client';
import { QueryGraphql, MutationGraphql } from '../../../../config/ApoloRequest';

export const USER_URL = `${API}master-data/branch/`;

export async function findBranchs(queryParams) {
  let name = queryParams.name ? `name: "${queryParams.name}"` : `name: ${null}`;
  let city = queryParams.city ? `city: "${queryParams.city}"` : `city: ${null}`;
  let province = queryParams.province ? `province: "${queryParams.province}"` : `province: ${null}`;

  const stringQuery = `{
    ${name}
    ${city}
    ${province}
    page_number: ${+queryParams.page_number}, page_size: ${+queryParams.page_size}
  }`;

  const query = gql`
    {
      __typename
      findBranches(findBranchInput: ${stringQuery}) {
        items {
          uuid
          city
          name
          province
          postal_code
          subdistrict_id
        }
        total_items
      }
    }
  `;

  const newResponse = await QueryGraphql(query);
  return newResponse.data.findBranches;
}

export const SEARCH_BRANCHES = gql`
  query Query($name: String) {
    findBranches(findBranchInput: { name: $name, page_number: 1, page_size: 10 }) {
      items {
        postal_code
        subdistrict_id
        name
        uuid
      }
    }
  }
`;

export const CREATE_BRANCH = gql`
  mutation Mutation(
    $address: String!
    $city: String!
    $email: String!
    $latitude: String
    $longitude: String
    $name: String!
    $phone: String!
    $postal_code: String!
    $province: String!
    $subdistrict_name: String!
    $subdistrict_id: String!
    $telephone: String!
  ) {
    createBranch(
      createBranchInput: {
        address: $address
        city: $city
        email: $email
        latitude: $latitude
        longitude: $longitude
        name: $name
        phone: $phone
        postal_code: $postal_code
        province: $province
        subdistrict_id: $subdistrict_id
        subdistrict_name: $subdistrict_name
        telephone: $telephone
      }
    ) {
      name
      uuid
      postal_code
    }
  }
`;

export const UPDATE_BRANCH = gql`
  mutation Mutation(
    $address: String
    $city: String
    $email: String
    $latitude: String
    $longitude: String
    $name: String
    $phone: String
    $postal_code: String
    $province: String
    $subdistrict_name: String
    $subdistrict_id: String
    $telephone: String
    $uuid: String!
  ) {
    updateBranch(
      updateBranchInput: {
        address: $address
        city: $city
        email: $email
        latitude: $latitude
        longitude: $longitude
        name: $name
        phone: $phone
        postal_code: $postal_code
        province: $province
        subdistrict_id: $subdistrict_id
        subdistrict_name: $subdistrict_name
        telephone: $telephone
        uuid: $uuid
      }
    ) {
      name
      uuid
      postal_code
    }
  }
`;

export async function createBranch(payload) {
  const query = gql`
        mutation {
          createBranch(createBranchInput: {
          name: "${payload.name}",
          city: "${payload.city}",
          province: "${payload.province}"
          }),
          {
          name,
          uuid
          }
        }`;
  const newResponse = await MutationGraphql(query);
  return newResponse.data.createBranch;
}

export async function updateBranch(payload) {
  const query = gql`
    mutation {
        updateBranch(updateBranchInput: {
          uuid: "${payload.uuid}"
          name: "${payload.name}"
          city: "${payload.city}"
          province: "${payload.province}"
          address: "${payload.address}"
          sub_district: "${payload.sub_district}"
          postal_code: "${payload.postal_code}"
          longitude: "${payload.longitude}"
          latitude: "${payload.latitude}"
        })
        {
          name
          city
          province
        }
      }`;

  const newResponse = await MutationGraphql(query);
  return newResponse.data.updateBranch;
}

export async function deleteBranch(payload) {
  const query = gql`
    mutation {
        deleteBranch(uuid: "${payload}"){
          name
          city
          province
        }
      }`;

  const newResponse = await MutationGraphql(query);
  return newResponse.data.deleteBranch;
}

// CREATE =>  POST: add a new customer to the server
// export function createBranch(branch) {
//     return axios.post(USER_URL, branch);
// }

// GET ALL
export async function getAllBranchs() {
  const query = gql`
    {
      findAllBranches {
        uuid
        name
        city
        province
      }
    }
  `;

  const newResponse = await QueryGraphql(query);
  return newResponse.data.findAllBranches;
}

export const GET_BRANCH = gql`
  query ($uuid: String!) {
    getBranch(uuid: $uuid) {
      uuid
      name
      subdistrict_id
      subdistrict_name
      postal_code
      phone
    }
  }
`;

export async function getBranchById(payload) {
  const query = gql`
    {
      getBranch(uuid: "${payload}") {
        address
        city
        name
        email
        phone
        latitude
        longitude
        postal_code
        province
        subdistrict_name
        subdistrict_id
        telephone
        uuid
      }
    }
  `;

  const newResponse = await QueryGraphql(query);
  return newResponse.data.getBranch;
}

// get province city
export const GET_CITY_PROVINCE = gql`
  query Q($keyword: String!) {
    __typename
    findCityProvinceMsPostalCode(keyword: $keyword) {
      items {
        city
        province_name
      }
    }
  }
`;

// get subdistrict name and id from raja ongkir
export const GET_SUBDISTRICT_NAME_ID = gql`
  query Q($keyword: String!) {
    __typename
    getAddressSuggestion(keyword: $keyword) {
      items {
        subdistrict_id
        subdistrict_name
      }
    }
  }
`;

// get postalcode from ms_postal_code
export const GET_POSTALCODE = gql`
  query Q($keyword: String!) {
    __typename
    findPostalCodeMsPostalCode(keyword: $keyword) {
      item {
        postal_codes
      }
    }
  }
`;

// GET SINGLE USER
// export function getBranchById(id) {
//   return axios.get(`${USER_URL}${id}`);
// }

// GET USER WITH CONDITION
// export function findBranchs(queryParams) {
//     return axios.post(`${USER_URL}find`, queryParams);
// }

// UPDATE SINGEL USER
// export function updateBranch(branch) {
//     return axios.put(`${USER_URL}`, branch);
// }
