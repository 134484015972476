import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../../_redux/voucherActions';
import { DELETE_VOUCHER } from '../../_redux/voucherCrud';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { toastOption } from '../../../../../_metronic/_helpers';
import { LoadingAnimation } from '../loading-animation/LoadingAnimation';
import { useVoucherUIContext } from '../VoucherUIContext';

export function VoucherDisableDialog({ id, show, onHide }) {
  const voucherUIContext = useVoucherUIContext();
  const voucherUIProps = useMemo(() => {
    return {
      queryParams: voucherUIContext.queryParams,
    };
  });
  // Voucher Redux state
  const dispatch = useDispatch();
  const { actionsLoading, voucherForEdit } = useSelector((state) => state.voucher, shallowEqual);

  const [title, setTitle] = useState('');
  // Title couting
  useEffect(() => {
    let _title = '';
    if (voucherForEdit && id) {
      _title = `Delete Voucher '${voucherForEdit.name}'`;
    }

    setTitle(_title);
    // eslint - disable - next - line;
  }, [voucherForEdit, actionsLoading]);

  useEffect(() => {
    // server call for getting Voucher by id
    dispatch(actions.getVoucherById(id));
  }, [id, dispatch]);

  // server request for saving voucher
  const [deleteVoucher, { loading }] = useMutation(DELETE_VOUCHER, {
    onCompleted: (data) => {
      toast.success('Successful deleted!', toastOption);
      dispatch(actions.findVoucher({ findVoucherInput: voucherUIProps.queryParams }));
      onHide();
    },
    onError: (err) => toast.error(err.message, toastOption),
  });

  const deleting = () => {
    deleteVoucher({
      variables: {
        uuid: id,
      },
    });
  };

  return (
    <>
      <LoadingAnimation open={loading} />
      <Modal size="md" show={show} onHide={onHide} aria-labelledby="example-modal-sizes-title-lg">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>Anda yakin akan menghapus Voucher ini?</span>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button type="button" onClick={onHide} className="btn btn-light btn-elevate">
              Cancel
            </button>
            <> </>
            <button type="button" onClick={deleting} className="btn btn-primary btn-elevate">
              Yakin
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
