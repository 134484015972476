import axios from "axios";
import { gql } from "@apollo/client";
import { QueryGraphql } from "../../../config/ApoloRequest";
import { API } from "../../../config/UrlEndPoint";

export const URL = `${API}master-data/paymentMethod/`;

export const CREATE_CAMPAIGN = gql`
  mutation Mutation($createCampaignInput: CreateCampaignInput!, $images: [Upload!]!) {
    createCampaign(createCampaignInput: $createCampaignInput, images: $images) {
      message
    }
  }
`;

export const UPDATE_CAMPAIGN = gql`
  mutation Mutation(
    $updateCampaignInput: UpdateCampaignInput!
    $images: [Upload!]!
    $deletedImage: [String!]
  ) {
    updateCampaign(
      updateCampaignInput: $updateCampaignInput
      images: $images
      deleted_image_name: $deletedImage
    ) {
      message
    }
  }
`;

export const DELETE_CAMPAIGN = gql`
  mutation Mutation($uuid: String!) {
    deleteCampaign(uuid: $uuid) {
      message
    }
  }
`;

export async function findCampaign(variables) {
  const query = gql`
    query Query(
      $name: String
      $content: String
      $is_active: Boolean
      $page_number: Int
      $page_size: Int
      $sort_field: String
      $sort_order: String
      $title: String
    ) {
      findCampaign(
        findCampaignInput: {
          name: $name
          content: $content
          is_active: $is_active
          page_number: $page_number
          page_size: $page_size
          sort_field: $sort_field
          sort_order: $sort_order
          title: $title
        }
      ) {
        items {
          name
          title
          content
          uuid
          hit_count
          slug
          dynamic_link
          products {
            product {
              name
            }
          }
          images {
            url
          }
        }
        total_items
        total_pages
      }
    }
  `;

  const response = await QueryGraphql(query, variables);

  return response.data.findCampaign;
}

export async function searchCampaign(queryParams) {
  let filter = {};

  for (const key in queryParams.filter) {
    if (queryParams.filter[key]) {
      filter = Object.assign(filter, { [key]: queryParams.filter[key] });
    }
  }

  if (!queryParams.filter["page_number"]) {
    filter.page_number = 1;
  }

  if (!queryParams.filter["page_size"]) {
    filter.page_size = 10;
  }

  const query = gql`
    {
      findCampaign(findCampaignInput: ${JSON.stringify(filter).replace(/"([^"]+)":/g, "$1:")}){
        items {
          uuid
          name
        }
      }
      
    }
  `;

  const newResponse = await QueryGraphql(query);
  return newResponse.data.findCampaign;
}

export async function getCampaignById(uuid) {
  const query = gql`
    query Query($uuid: String!) {
      getCampaign(uuid: $uuid) {
        content
        name
        title
        uuid
        is_active
        type
        images {
          uuid
          url
          filename
        }
        products {
          product {
            name
            uuid
          }
        }
      }
    }
  `;

  const newResponse = await QueryGraphql(query, { uuid });
  return newResponse.data.getCampaign;
}
