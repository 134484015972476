import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '../SvgIcon'
import { defaultFill } from '../constant'

const CheckBoxOutline = props => (
  <SvgIcon {...props}>
    <path fill={props.fill} className="custom-fill" d="M19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3M19,5V19H5V5H19M10,17L6,13L7.41,11.58L10,14.17L16.59,7.58L18,9" />
  </SvgIcon>
)

CheckBoxOutline.propTypes = {
  fill: PropTypes.string,
}

CheckBoxOutline.defaultProps = {
  fill: defaultFill,
}

export default CheckBoxOutline
