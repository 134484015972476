import React from "react";
import { OrderLogisticUIProvider } from "./OrderLogisticUIContext";
import { OrderLogisticCard } from "./OrderLogisticCard";

export function OrderLogisticPage({ history, match }) {
  const orderLogisticUIEvents = {
    newOrderLogisticButtonClick: () => {
      history.push("/orderLogistic/new");
    },
    openEditOrderLogisticDialog: (id) => {
      history.push(`/orderLogistic/${id}/edit`);
    },
    openFetchOrderLogisticDialog: () => {
      history.push(`/orderLogistic/fetch`);
    },
  };

  return (
    <OrderLogisticUIProvider orderLogisticUIEvents={orderLogisticUIEvents}>
      <OrderLogisticCard match={match} />
    </OrderLogisticUIProvider>
  );
}
