import * as requestFromServer from './voucherCrud';
import { voucherSlice, callTypes } from './voucherSlice';

const { actions } = voucherSlice;

export const findVoucher = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findVoucher(queryParams)
    .then((response) => {
      const { total_items, items } = response;

      dispatch(actions.vouchersFetched({ total_items, items }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find voucher";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getVoucherById = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.voucherFetched({ voucherForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getVoucherById(id)
    .then((response) => {
      dispatch(actions.voucherFetched({ voucherForEdit: response }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find Payment Method";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const voucherFilter = params => dispatch => {
  dispatch(actions.startCall({ callTypes: callTypes.list }))
  dispatch(actions.voucherFilter(params))
}
