import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./OrderUIHelpers";
import { shallowEqual, useSelector } from "react-redux";

const OrderUIContext = createContext();

export function useOrderUIContext() {
  return useContext(OrderUIContext);
}

export const OrderUIConsumer = OrderUIContext.Consumer;

export function OrderUIProvider({ orderUIEvents, children }) {
  const { filters } = useSelector((state) => state.orders, shallowEqual);
  // const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [queryParams, setQueryParamsBase] = useState(filters);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initOrder = {
    uuid: "",
    status: "",
    trx_date: "",
    updated_at: "",
    is_online: false,
    invoice_no: "",
    created_at: "",
    branch: {},
    member: {},
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    setQueryParams,
    initOrder,
    newOrderButtonClick: orderUIEvents.newOrderButtonClick,
    openEditOrderDialog: orderUIEvents.openEditOrderDialog,
    openDeleteOrderDialog: orderUIEvents.openDeleteOrderDialog,
    openFetchOrderDialog: orderUIEvents.openFetchOrderDialog,
    openUpdateOrderStatusDialog: orderUIEvents.openUpdateOrderStatusDialog,
  };

  return <OrderUIContext.Provider value={value}>{children}</OrderUIContext.Provider>;
}
