import React, { useState, useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
// import * as actions from "../../_redux/synchronizeMarketplace/synchronizeMarketplaceActions";
import { SynchronizeMarketplaceEditForm } from "./SynchronizeMarketplaceForm";
import { useSynchronizeMarketplaceUIContext } from "../SynchronizeMarketplaceUIContext";
import { toast } from "react-toastify";
import { toastOption } from "../../../../../_metronic/_helpers";
import { useMutation, gql, useLazyQuery } from "@apollo/client";
import { LoadingAnimation } from "../loading-animation/LoadingAnimation";
import {
  findSynchronizeMarketplaces,
  FIND_MARKETPLACE_TRX_IMPORT,
  FIND_ORDER_BY_INVOICE,
  UPDATE_MARKETPLACE_TRX,
} from "../../_redux/synchronizeMarketplace/synchronizeMarketplaceCrud";
import { Card } from "../../../../../_metronic/_partials/controls";
import { QueryGraphql } from "../../../../config/ApoloRequest";
import { fetchSynchronizeMarketplaces } from "../../_redux/synchronizeMarketplace/synchronizeMarketplaceActions";
import SynchronizerMarketplaceModal from "./SynchronizeMarketplaceModal";

export function SynchronizeMarketplaceEditDialog({ history, match }) {
  const {
    params: { invoice },
  } = match;

  const invoice_no = { invoice_no: invoice.replaceAll("-", "/") };

  const [data, setData] = useState({
    marketplaceImportData: [],
    orderData: [],
  });

  useEffect(() => {
    findMarketplaceTransactions(invoice_no);
    findOrderByInvoice(invoice_no);
    findMarketplaceImport(invoice_no);
  }, [invoice]);

  async function findMarketplaceTransactions(queryParams) {
    const query = FIND_MARKETPLACE_TRX_IMPORT;

    const newResponse = await QueryGraphql(query, queryParams);
    const {
      data: {
        findMarketplaceTrx: { items },
      },
    } = newResponse;
    const addKey = items.map((item) => ({
      ...item,
      key: item.uuid,
    }));

    setData((prevState) => ({
      ...prevState,
      marketplaceImportData: addKey,
    }));
  }

  async function findOrderByInvoice(queryParams) {
    const query = FIND_ORDER_BY_INVOICE;

    const newResponse = await QueryGraphql(query, {
      ...queryParams,
      marketplace_invoice: queryParams.invoice_no,
    });

    const {
      data: {
        findOrder: { items },
      },
    } = newResponse;

    const addKey = items.map((item) => ({
      ...item,
      key: item.uuid,
    }));

    setData((prevState) => ({
      ...prevState,
      orderData: addKey,
    }));
  }

  const [updateMarketplaceTrx, { loading: createLoading }] = useMutation(
    UPDATE_MARKETPLACE_TRX,
    {
      onCompleted: () => {
        toast.success("Berhasil Menyinkron Order!", toastOption);
        history.push(`/marketplace-synchronize`);
      },
      onError: (data) => {
        toast.error("Gagal Menyinkron Order!", toastOption);
        console.log(data);
      },
    }
  );

  const [groupedData, setGroupedData] = useState({});
  const findMarketplaceImport = (invoice) => {
    findSynchronizeMarketplaces(invoice).then((res) =>
      setGroupedData(res.items[0])
    );
  };

  const saveSynchronizeMarketplace = (value) => {
    updateMarketplaceTrx({
      variables: {
        updateMarketplaceTrxItem: value,
      },
    });
  };

  function addProduct(values) {
    // const {
    //   product_sku_uuid,
    //   product_name,
    //   price: product_price,
    //   quantity,
    //   sku,
    // } = values;
    // const price = Number.parseInt(product_price);
    // const qty = Number.parseInt(quantity);
    // const total_price = Number.parseInt(price * qty);
    // const newProduct = {
    //   product_sku_uuid,
    //   product_name,
    //   price,
    //   qty,
    //   total_price,
    //   status: statusPurchaseItem.ADDED,
    //   sku,
    // };
    // const sameItemIndex = products.findIndex(
    //   (product) => product.product_sku_uuid === newProduct.product_sku_uuid
    // );
    // if (sameItemIndex > -1) {
    //   const temp = [...products];
    //   temp[sameItemIndex] = {
    //     ...temp[sameItemIndex],
    //     price,
    //     qty: temp[sameItemIndex].qty + qty,
    //     total_price: product_price * (temp[sameItemIndex].qty + qty),
    //     status: statusPurchaseItem.EDITED,
    //   };
    //   setPurchaseModal(false);
    //   return setProducts(temp);
    // }
    // // setProducts([...products, newProduct]);
    // setProducts((prev) => {
    //   const old = [...prev];
    //   old.push(newProduct);
    //   setProducts(old);
    // });
    // setPurchaseModal(false);
    // let productForLocalStorage = [...products];
    // productForLocalStorage.push(newProduct);
    // setTrigger();
  }
  const [purchaseModal, setPurchaseModal] = useState(false);

  return (
    <>
      <SynchronizerMarketplaceModal
        show={purchaseModal}
        onHide={() => setPurchaseModal(false)}
        initValue={{}}
        addProduct={addProduct}
      />
      <LoadingAnimation open={createLoading} />
      <Card>
        <SynchronizeMarketplaceEditForm
          history={history}
          match={match}
          // synchronizeMarketplaceInitValue={synchronizeMarketplaceForEdit || {}}
          synchronizeMarketplaceInitValue={{}}
          updateMarketplaceTrx={saveSynchronizeMarketplace}
          data={data}
          groupedData={groupedData}
          openProductModal={() => setPurchaseModal(true)}
        />
      </Card>
    </>
  );
}
