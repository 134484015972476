import React, { useState, useEffect, useRef } from "react";
import { useLazyQuery, gql } from "@apollo/client";
import AsyncSelect from "react-select/async";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../../_metronic/_partials/controls";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { Input } from "../../../../../_metronic/_partials/controls";
import * as Yup from "yup";
// import { SEARCH_PRODUCTS } from "../../../Products/_redux/products/productCrud";

const ProductSchema = Yup.object().shape({
  product_sku_uuid: Yup.string().required(),
  price: Yup.number().moreThan(0).required(),
  quantity: Yup.number().moreThan(-1).required(),
});

// const SEARCH_PRODUCTS = gql`
//   query Query($name: String, $supplier_uuid: String) {
//     findProduct(
//       findProductInput: {
//         product_name_or_sku: $name
//         supplier_uuid: $supplier_uuid
//         page_number: 1
//         page_size: 100
//       }
//     ) {
//       items {
//         uuid
//         name
//         product_sku {
//           price
//           sku
//           uuid
//           variant_value_1
//           variant_value_2
//         }
//       }
//     }
//   }
// `;
const SEARCH_PRODUCTS = gql`
  query Query($name: String) {
    findProduct(
      findProductInput: {
        product_name_or_sku: $name
        page_number: 1
        page_size: 100
        show_empty_stock: true
      }
    ) {
      items {
        uuid
        name
        product_sku {
          price
          sku
          uuid
          variant_value_1
          variant_value_2
        }
      }
    }
  }
`;

const PurchaseAddProduct = ({ addProduct, supplier_uuid }) => {
  const [currentTimeout, setCurrentTimeout] = useState(null);
  const [currentCallback, setCurrentCallback] = useState(null);
  const [currentProducts, setCurrentProducts] = useState(null);

  const [fetchProductOptions] = useLazyQuery(SEARCH_PRODUCTS, {
    // variables: { name: "" },
    onCompleted: (data) => {
      if (!data) return;

      const {
        findProduct: { items },
      } = data;

      if (items.length === 0) return currentCallback([]);

      const skus = items.reduce(
        (prev, { name, product_sku }) => [
          ...prev,
          ...product_sku.map(
            ({ sku, uuid, variant_value_1, variant_value_2, price }) => ({
              label: `${name} ${
                variant_value_1 ? "- " + variant_value_1 : ""
              } ${variant_value_2 ? "- " + variant_value_2 : ""}`,
              value: uuid,
              price,
              sku,
            })
          ),
        ],
        []
      );

      setCurrentProducts(skus);

      currentCallback(skus);
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    // console.log(currentProducts);
  }, [currentProducts]);

  function loadProductOptions(inputValue, callback) {
    clearTimeout(currentTimeout);

    // if (!supplier_uuid) {
    //   return callback([]);
    // }

    if (inputValue.length >= 3) {
      setCurrentTimeout(
        setTimeout(() => {
          // fetchProductOptions({ variables: { supplier_uuid, name: inputValue } });
          fetchProductOptions({ variables: { name: inputValue } });
          setCurrentCallback(() => callback);
        }, 250)
      );
    } else {
      callback([]);
    }
  }

  const priceRef = useRef();
  const qtyRef = useRef();
  const productRef = useRef();

  const changeInput = (e, target) => {
    if (e.key === "Enter") {
      setTimeout(() => {
        if (target === "price") priceRef.current.select();
        if (target === "qty") qtyRef.current.select();
        if (target === "product") productRef.current.focus();
      }, 0);
    }
  };

  return (
    <Formik
      validationSchema={ProductSchema}
      enableReinitialize={true}
      initialValues={{
        product_sku_uuid: "",
        product_name: "",
        price: 0,
        quantity: 1,
      }}
      onSubmit={(values, { resetForm }) => {
        addProduct(values);
        resetForm();
      }}
    >
      {({
        values,
        handleSubmit,
        setFieldValue,
        handleChange,
        errors,
        resetForm,
      }) => (
        <Card>
          <CardHeader title="Tambah Produk yang akan dibeli" />
          <CardBody>
            <div className="d-flex flex-column-reverse flex-lg-row-reverse m-0 p-0">
              <div className="flex-shrink-0 pr-4 align-self-end align-self-lg-center">
                <button
                  type="submit"
                  className="btn btn-primary ml-3"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Tambah
                </button>
              </div>
              <div className="flex-grow-1 row m-0 p-0">
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group d-flex flex-column">
                    <label htmlFor="product_sku_uuid">Product Name</label>
                    <AsyncSelect
                      cacheOptions={false}
                      name="product_sku_uuid"
                      placeholder="Product Name"
                      noOptionsMessage={({ inputValue }) => {
                        // if (!supplier_uuid) return "Please select a supplier";

                        // if (inputValue.length >= 3) return "No options";
                        if (inputValue.length >= 3)
                          return (
                            <>
                              <span>Produk tidak ditemukan</span>
                              <button
                                className="btn btn-secondary mt-2"
                                onClick={() => window.open("/product/new")}
                              >
                                Tambah Produk
                              </button>
                            </>
                          );
                        else return "Type 3 or more characters";
                      }}
                      loadOptions={loadProductOptions}
                      defaultOptions={false}
                      value={
                        values.product_name && values.product_sku_uuid
                          ? {
                              label: values.product_name,
                              value: values.product_sku_uuid,
                            }
                          : null
                      }
                      onChange={({ label, value, sku }) => {
                        setFieldValue("product_sku_uuid", value);
                        setFieldValue("product_name", label);
                        setFieldValue("sku", sku);
                        setFieldValue(
                          "price",
                          currentProducts[
                            currentProducts.findIndex(
                              (product) => value === product.value
                            )
                          ].price
                        );
                      }}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          border: errors.product_sku_uuid
                            ? "1px solid rgb(246, 78, 96)"
                            : "1px solid #ddd",
                        }),
                        dropdownIndicator: (provided, state) => ({
                          ...provided,
                          color: errors.product_sku_uuid
                            ? "rgb(246, 78, 96)"
                            : "#ddd",
                        }),
                      }}
                      onKeyDown={(e) => changeInput(e, "price")}
                      ref={productRef}
                    />
                    <ErrorMessage
                      name="product_sku_uuid"
                      render={(msg) => (
                        <div style={{ color: "rgb(246, 78, 96)" }}>{msg}</div>
                      )}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group d-flex flex-column">
                    <label htmlFor="desc_text">Harga Barang</label>
                    <input
                      name="price"
                      type="number"
                      component={Input}
                      className="form-control"
                      placeholder="Harga Barang"
                      value={values.price}
                      onChange={handleChange}
                      ref={priceRef}
                      onKeyPress={(e) => changeInput(e, "qty")}
                    />
                    {/* <Field
                      name="price"
                      type="number"
                      component={Input}
                      placeholder="Harga Barang"
                      value={values.price}
                      onChange={handleChange}
                    /> */}
                    {/* <ErrorMessage
                      name="price"
                      render={(msg) => <div style={{ color: "rgb(246, 78, 96)" }}>{msg}</div>}
                    /> */}
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group d-flex flex-column">
                    <label htmlFor="desc_text">Qty</label>
                    <Form>
                      <input
                        name="quantity"
                        type="number"
                        className="form-control"
                        component={Input}
                        placeholder="Quantity"
                        value={values.quantity}
                        onChange={handleChange}
                        ref={qtyRef}
                        onKeyPress={(e) => changeInput(e, "product")}
                      />
                    </Form>
                    {/* <Field
                      name="quantity"
                      type="number"
                      component={Input}
                      placeholder="Quantity"
                      value={values.quantity}
                      onChange={handleChange}
                    /> */}
                    {/* <ErrorMessage
                      name="quantity"
                      render={(msg) => <div style={{ color: "rgb(246, 78, 96)" }}>{msg}</div>}
                    /> */}
                  </div>
                </div>
              </div>
              {/* <div className="col-12 col-md-6 col-lg-4"></div> */}
            </div>
          </CardBody>
        </Card>
      )}
    </Formik>
  );
};

export default PurchaseAddProduct;
