export const BrandStatusCssClasses = ["danger", "success", "info", ""];
export const BrandStatusTitles = ["Suspended", "Active", "Pending", ""];
export const BrandTypeCssClasses = ["success", "primary", ""];
export const BrandTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "created_at", order: "DESC" }];
export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
];
export const initialFilter = {
  name: "",
  city: "",
  is_deleted: false,
  province: "",
  sortOrder: "DESC", // asc||desc
  sortField: "created_at",
  page_number: 1,
  page_size: 10,
};

export let initBrand = {
  name: "",
  logo: "",
};

export const role = [
  {
    name: "Customer Care",
    value: "CustomerCare",
  },
];

export const badge = (status) => {
  let data = {};
  if (status) data = { text: "ACTIVE", type: "success" };
  else data = { text: "INACTIVE", type: "warning" };

  return data;
};
