import { createSlice } from "@reduxjs/toolkit";

const initialCMSState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  cmsForEdit: undefined,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const cmsSlice = createSlice({
  name: "cmss",
  initialState: initialCMSState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getCMSById
    cmsFetched: (state, action) => {
      state.actionsLoading = false;
      state.cmsForEdit = action.payload.cmsForEdit;
      state.error = null;
    },
    // findCMS
    cmssFetched: (state, action) => {
      const { total_items, items } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = items;
      state.totalCount = total_items;
    },
    // getAllCMS
    cmsAllFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.all = action.payload.all;
    },
    // createCMS
    cmsCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.cms);
    },
    // updateCMS
    cmsUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.cms.id) {
          return action.payload.cms;
        }
        return entity;
      });
    },
  },
});
