import moment from 'moment';

export const OrderLogisticStatusCssClasses = ['danger', 'success', 'info', ''];
export const OrderLogisticStatusTitles = ['Suspended', 'Active', 'Pending', ''];
export const OrderLogisticTypeCssClasses = ['success', 'primary', ''];
export const OrderLogisticTypeTitles = ['Business', 'Individual', ''];
export const ORDER_STATUS_VALIDATION = [
  'PAYMENT',
  'PAID',
  'PACKING',
  'PICKUP',
  'COMPLAIN',
  'RETURN',
];
export const defaultSorted = [{ dataField: 'created_at', orderLogistic: 'desc' }];

export const sizePerPageList = [
  { text: '10', value: 10 },
  { text: '50', value: 50 },
  { text: '100', value: 100 },
];

export const initialFilter = {
  store_uuid: '',
  shipping_company_code: '',
  shipping_service_uuid: '',
  trx_date_start: moment().format('YYYY-MM-DD'),
  trx_date_end: moment().format('YYYY-MM-DD'),
  page_number: 1,
  page_size: 10,
};

export const ORDER_ONLINE = [
  {
    label: 'Online',
    value: true,
  },
  {
    label: 'Offline',
    value: false,
  },
];

export const IS_ONLINE = [
  {
    label: 'Online',
    value: true,
  },
  {
    label: 'Offline',
    value: false,
  },
];

export const ORDER_STATUS_OPTIONS = [
  {
    label: 'ABORT',
    value: 'ABORT',
  },
  {
    label: 'DRAFT',
    value: 'DRAFT',
  },
  {
    label: 'PAYMENT',
    value: 'PAYMENT',
  },
  {
    label: 'PAID',
    value: 'PAID',
  },
  {
    label: 'PACKING',
    value: 'PACKING',
  },
  {
    label: 'PICKUP',
    value: 'PICKUP',
  },
  {
    label: 'SENDING',
    value: 'SENDING',
  },
  {
    label: 'RECEIVED',
    value: 'RECEIVED',
  },
  {
    label: 'COMPLAIN',
    value: 'COMPLAIN',
  },
  {
    label: 'COMPLAIN CONFIRM',
    value: 'COMPLAIN_CONFIRM',
  },
  {
    label: 'RETURN',
    value: 'RETURN',
  },
];

export const ORDER_STATUS = {
  DRAFT: 'DRAFT',
  NEW: 'NEW',
  PAYMENT: 'PAYMENT',
  PAID: 'PAID',
  PROCESS: 'PROCESS',
  PACKING: 'PACKING',
  PICKUP: 'PICKUP',
  SENDING: 'SENDING',
  RECEIVED: 'RECEIVED',
  RECEIVED_CONFIRM: 'RECEIVED_CONFIRM',
  COMPLAIN: 'COMPLAIN',
  COMPLAIN_CONFIRM: 'COMPLAIN_CONFIRM',
  RETURN: 'RETURN',
  ABORT: 'ABORT',
  FINISH: 'FINISH',
  VOID: 'VOID',
};

// export const initialFilter = {
//   filter: {
//     shipper_name: "",
//     package_id: "",
//     awb: "",
//     shipper_city: "",
//     consignee_city: "",
//     declare_value: "",
//   },
//   sortOrderLogistic: "desc", // asc||desc
//   sortField: "created_at",
//   pageNumber: 1,
//   pageSize: 10,
// };

export const buttonStatus = (status) => {
  let data = {};
  switch (status) {
    case ORDER_STATUS.PAYMENT:
      data = { updateTo: ORDER_STATUS.PAID };
      break;
    case ORDER_STATUS.DRAFT:
      data = { updateTo: ORDER_STATUS.PAID };
      break;
    case ORDER_STATUS.PAID:
      data = { updateTo: ORDER_STATUS.PACKING };
      break;
    case ORDER_STATUS.PACKING:
      data = { updateTo: ORDER_STATUS.PICKUP };
      break;
    case ORDER_STATUS.PICKUP:
      data = { updateTo: ORDER_STATUS.SENDING };
      break;
    case ORDER_STATUS.COMPLAIN:
      data = { updateTo: 'CONFIRM COMPLAIN' };
      break;
    default:
      data = { updateTo: '' };
  }

  return data;
};

export const badge = (status) => {
  let data = {};
  switch (status) {
    case ORDER_STATUS.PAYMENT:
      data = { type: 'warning' };
      break;
    case ORDER_STATUS.PAID:
      data = { type: 'primary' };
      break;
    case ORDER_STATUS.PACKING:
      data = { type: 'primary' };
      break;
    case ORDER_STATUS.PROCESS:
      data = { type: 'info' };
      break;
    case ORDER_STATUS.PICKUP:
      data = { type: 'info' };
      break;
    case ORDER_STATUS.SENDING:
      data = { type: 'info' };
      break;
    case ORDER_STATUS.RECEIVED:
      data = { type: 'success' };
      break;
    case ORDER_STATUS.COMPLAIN:
      data = { type: 'danger' };
      break;
    case ORDER_STATUS.COMPLAIN_CONFIRM:
      data = { type: 'success' };
      break;
    case ORDER_STATUS.ABORT:
      data = { type: 'danger' };
      break;
    case ORDER_STATUS.VOID:
      data = { type: 'dark' };
      break;
    default:
      data = { type: '' };
  }

  return data;
};
