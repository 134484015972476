import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '../SvgIcon'
import { defaultFill } from '../constant'

const User = props => (
  <SvgIcon {...props}>
    <path fill={props.fill} className="custom-fill" d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z" />
  </SvgIcon>
)

User.propTypes = {
  fill: PropTypes.string,
}

User.defaultProps = {
  fill: defaultFill,
}

export default User
