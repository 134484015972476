import { gql } from "@apollo/client";
import { QueryGraphql, MutationGraphql } from "../../../../config/ApoloRequest";

export async function getPurchaseById(uuid) {
  const query = gql`
    {
      getPurchase(uuid: "${uuid}") {
        status
        trx_date
        uuid
        description
        branch_uuid
        branches {
          name
          uuid
        }
        suppliers {
          name
          uuid
        }
        projects {
          name
          uuid
        }
        purchase_items {
          product_sku_uuid
          price
          qty
          total_price
          uuid
          is_printed
          product_sku {
            variant_value_1
            variant_value_2
            sku
            product {
              name
            }
          }
        }
      }
    }
  `;

  const newResponse = await QueryGraphql(query);
  return newResponse.data.getPurchase;
}

export async function findPurchases(filter) {
  const query = gql`
    query Query(
      ${filter["branch_uuid"] ? "$branch_uuid: ID" : ""}
      ${filter["project_uuid"] ? "$project_uuid: ID" : ""}
      ${filter["product_uuid"] ? "$product_uuid: ID" : ""}
      ${filter["supplier_uuid"] ? "$supplier_uuid: ID" : ""}
      $product_name: String
      $product_sku: String
      $description: String
      $start_trx_date: DateTime
      $end_trx_date: DateTime
      $page_number: Int!
      $page_size: Int!
      $status: String
      $sort_field: String
      $sort_order: String
    ) {
      searchPurchase(
        purchaseInput: {
          ${filter["branch_uuid"] ? "branch_uuid: $branch_uuid" : ""}
          ${filter["project_uuid"] ? "project_uuid: $project_uuid" : ""}
          ${filter["product_uuid"] ? "product_uuid: $product_uuid" : ""}
          ${filter["supplier_uuid"] ? "supplier_uuid: $supplier_uuid" : ""}
          product_name: $product_name
          product_sku: $product_sku
          description: $description
          start_trx_date: $start_trx_date
          end_trx_date: $end_trx_date
          page_number: $page_number
          page_size: $page_size
          status: $status
          is_deleted: false
          sort_field: $sort_field
          sort_order: $sort_order
        }
      ) {
        items {
          uuid
          trx_date
          status
          is_deleted
          purchase_item {
            qty
            price
            total_price
          }
          branch {
            name
          }
          supplier {
            name
          }
          project {
            name
          }
        }
        total_items
        sort_field
        sort_order
      }
    }
  `;

  const newResponse = await QueryGraphql(query, filter);
  return newResponse.data.searchPurchase;
}

export async function updatePurchase(payload) {
  const query = gql`
    mutation {
      updatePurchase(
        updatePurchaseInput: {
          uuid: "${payload.uuid}"
          name: "${payload.name}"
          purchase_level_code: "${payload.purchase_level_code}"
          password: "${payload.password}"
          email: "${payload.email}"
          hp: "${payload.hp}"
        }
      ) {
        uuid
      }
    }
  `;
  const newResponse = await MutationGraphql(query);
  return newResponse.data.updatePurchase;
}

export const CREATE_PURCHASE = gql`
  mutation Mutation(
    $branch_uuid: ID!
    $supplier_uuid: ID!
    $project_uuid: ID!
    $description: String!
    $status: String!
    $purchase_items: [PurchaseItemInput!]!
    $trx_date: DateTime!
  ) {
    createPurchase(
      createPurchaseInput: {
        branch_uuid: $branch_uuid
        supplier_uuid: $supplier_uuid
        project_uuid: $project_uuid
        description: $description
        status: $status
        purchase_items: $purchase_items
        trx_date: $trx_date
      }
    ) {
      message
    }
  }
`;

export const UPDATE_PURCHASE = gql`
  mutation Mutation(
    $uuid: ID!
    $branch_uuid: ID!
    $supplier_uuid: ID!
    $project_uuid: ID!
    $description: String!
    $status: String!
    $purchase_items: [PurchaseItemInputEdit!]
    $trx_date: DateTime!
  ) {
    updatePurchase(
      updatePurchaseInput: {
        uuid: $uuid
        branch_uuid: $branch_uuid
        supplier_uuid: $supplier_uuid
        project_uuid: $project_uuid
        description: $description
        status: $status
        purchase_items: $purchase_items
        trx_date: $trx_date
      }
    ) {
      message
    }
  }
`;

export const UPDATE_PURCHAE_ITEM = gql`
  mutation m($updatePurchaseItemInput: UpdatePurchaseItemInput!) {
    updatePurchaseItem(updatePurchaseItemInput: $updatePurchaseItemInput) {
      uuid
      price
      total_price
      is_printed
      qty
    }
  }
`;
