import axios from "axios";

import { API } from "../../../config/UrlEndPoint";
import { MutationGraphql, QueryGraphql } from "../../../config/ApoloRequest";
import { gql } from "@apollo/client";
export const SIGNIN_URL = `${API}auth/login_admin`;
export const ME_URL = `${API}auth/me`;
export const REQUEST_PASSWORD_URL = `${API}auth/forgot_password`;
export const GET_USER_DATA = `${API}auth/forgot_password`;
export const NEW_PASSWORD = `${API}auth/new_password/:uuid`;

export async function signin(payload) {
  const query = gql`
  {
    login(
      loginInput: {email:"${payload.email}", password:"${payload.password}" }
    ) {
      accessToken
      message
    }
  }
  `;
  const newResponse = await QueryGraphql(query);
  return { data: newResponse.data.login };
}

export function register(payload) {
  return axios.post(`${API}auth/register`, payload);
}

export async function getUserByToken() {
  const query = gql`
    {
      me {
        name
        email
        jwt_token
        access
      }
    }
  `;
  const newResponse = await QueryGraphql(query);
  if (newResponse.data) {
    return { data: newResponse.data.me };
  }
  return null;
}

export const logoutUser = async () => {
  const query = gql`
    mutation Mutation {
      logoutOne {
        message
      }
    }
  `;

  const newResponse = await MutationGraphql(query);
  return newResponse.data.logoutOne;
};

export const logoutOthers = async () => {
  const query = gql`
    mutation Mutation {
      logoutOthers {
        message
      }
    }
  `;

  const newResponse = await MutationGraphql(query);
  return newResponse.data.logoutOthers;
};

export const getAdmin = async () => {
  const query = gql`
    {
      getCurrentAdmin {
        role
        name
        email
        hp
      }
    }
  `;
  const newResponse = await QueryGraphql(query);
  return newResponse.data.getCurrentAdmin;
};

export const UPDATE_ADMIN = gql`
  mutation Mutation(
    $email: String
    $hp: String
    $name: String
    $password: String
  ) {
    updateCurrentAdmin(
      updateCurrentInput: {
        email: $email
        hp: $hp
        name: $name
        password: $password
      }
    ) {
      email
      hp
      name
      uuid
    }
  }
`;

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserForgotPassword() {
  return axios.get(GET_USER_DATA);
}

export function newPassword(params) {
  return axios.post(NEW_PASSWORD, { params });
}
