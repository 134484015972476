// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState, useEffect } from "react";
import { Modal, Form as Forms, Image } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Input } from "../../../_metronic/_partials/controls";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

// Validation schema
const BrandEditSchema = Yup.object().shape({
  name: Yup.string().required("Brandname is required"),
});

export function BrandEditForm({ addBrand, brand, onHide }) {
  const [previewImage, setPreviewImage] = useState();

  const previewImageFunction = (value) => {
    if (value && value.length !== 0) {
      setPreviewImage(URL.createObjectURL(value));
    }
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={brand}
        validationSchema={BrandEditSchema}
        onSubmit={(values) => {
          // saveBrand(values);
          addBrand(values);
        }}
      >
        {({ handleSubmit, setFieldValue }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              <Form className="form form-label-right">
                <div className="form-group">
                  <div className="col">
                    <Field
                      name="name"
                      component={Input}
                      placeholder="Brand"
                      label="Nama Brand"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="col">
                    <Forms.File
                      name="logo"
                      accept=".png, .jpg, .jpeg"
                      onChange={(e) => {
                        setFieldValue("logo", e.target.files[0]);
                        previewImageFunction(e.currentTarget.files[0]);
                      }}
                    />
                  </div>
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="tooltip-top">Image is required!</Tooltip>
                    }
                  >
                    <div className="col" style={{ marginTop: "40px" }}>
                      <div
                        style={{
                          height: "100%",
                          width: "100%",
                        }}
                      >
                        <Image
                          src={previewImage}
                          rounded
                          style={{
                            height: "100%",
                            width: "100%",
                          }}
                        />
                      </div>
                    </div>
                  </OverlayTrigger>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
