import * as requestFromServer from "./orderReportCrud";
import { orderReportSlice, callTypes } from "./orderReportSlice";
import { toastOption } from "../../../../../_metronic/_helpers";
import { toast } from "react-toastify";

const { actions } = orderReportSlice;

export const fetchOrderReportAll = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllOrderReports(queryParams)
    .then((response) => {
      dispatch(actions.orderReportALlFetched({ allData: response.data.data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find orderReports";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchOrderReport = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.orderReportFetched({ orderReportForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getOrderReportById(id)
    .then((response) => {
      dispatch(actions.orderReportFetched({ orderReportForEdit: response }));
      return response;
    })
    .catch((error) => {
      error.clientMessage = "Can't find orderReport";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateOrderReport = (orderReport) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateOrderReport(orderReport)
    .then((response) => {
      toast.success(response.data.message, toastOption);
      dispatch(actions.orderReportUpdated({ orderReport }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update orderReport";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteOrderReport = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteOrderReport(id)
    .then(() => {
      fetchOrderReport();
      toast.success("Berhasil menghapus notifikasi!", toastOption);
    })
    .catch((error) => {
      console.log(error);
      error.clientMessage = "Can't delete category";
      toast.error("Gagal menghapus notifikasi!", toastOption);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
