export const MemberLevelStatusCssClasses = ["danger", "success", "info", ""];
export const MemberLevelStatusTitles = ["Suspended", "Active", "Pending", ""];
export const MemberLevelTypeCssClasses = ["success", "primary", ""];
export const MemberLevelTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "created_at", order: "desc" }];
export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
];
export const initialFilter = {
  name: "",
  code: "",
  level_order: "",
  sortOrder: "asc", // asc||desc
  sortField: "created_at",
  page_number: 1,
  page_size: 10,
};

export let initMemberLevel = {
  name: "",
  code: "",
  level_order: "",
};
