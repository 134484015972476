import React, { useMemo, useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  Pagination,
} from '../../../../../_metronic/_partials/controls';
import BootstrapTable from 'react-bootstrap-table-next';
import { useLazyQuery } from '@apollo/client';
import { FIND_MARKETPLACE_TRX_IMPORT } from '../../_redux/marketplaceTransaction/marketplaceTransactionCrud';
import { QueryGraphql } from '../../../../config/ApoloRequest';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
} from '../../../../../_metronic/_helpers';
import { Link } from 'react-router-dom';

export function MarketplaceTransactionList({
  history,
  match: {
    params: { id },
  },
}) {
  const [queryParams, setQueryParams] = useState({
    page_number: 1,
    page_size: 10,
    sort_field: 'marketplace_trxs.invoice_no',
    sort_order: 'DESC',
    file_uuid: id,
  });

  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState();

  const [fetchMarketplaceTrxs] = useLazyQuery(FIND_MARKETPLACE_TRX_IMPORT, {
    onCompleted: (data) => {
      const { findMarketplaceTrx } = data ?? { findMarketplaceTrx: {} };
      const { items = [], total_items = 0 } = findMarketplaceTrx;

      setTotalCount(total_items);
      setData(items);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  useEffect(() => {
    fetchMarketplaceTrxs({ variables: queryParams });
  }, [queryParams]);

  const columns = [
    {
      dataField: 'invoice_no',
      text: 'Invoice No',
      sort: true,
      formatter: (cell, row, indexRow) => {
        return (
          <>
            {row.order_uuid ? (
              <Link to={`/order/${row.order_uuid}/edit`}>{cell}</Link>
            ) : (
              <div>{cell}</div>
            )}
          </>
        );
      },
      onSort: (sort_field, sort_order) => {
        setQueryParams((params) => ({ ...params, sort_field, sort_order }));
      },
    },
    {
      dataField: 'description',
      text: 'Deskripsi',
      sort: true,
    },
    {
      dataField: 'trans_code',
      text: 'Trans Code',
      sort: true,
    },
    // {
    //   dataField: "amount_imported",
    //   text: "Jumlah Diimpor",
    //   sort: true,
    // },
    {
      dataField: 'amount_value',
      text: 'Amount',
      sort: true,
    },
  ];

  const sizePerPageList = [
    { text: '10', value: 10 },
    { text: '50', value: 50 },
    { text: '100', value: 100 },
  ];

  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: sizePerPageList,
    sizePerPage: queryParams.page_size,
    page: queryParams.page_number,
  };

  return (
    <>
      <Card>
        <CardHeader title="Marketplace Transaction">
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={() => history.push('/marketplace-transaction')}
              aria-controls="collapse-filter-marketplaceTransaction"
            >
              <i className="fa fa-arrow-left"></i>
              Kembali
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => {
              return (
                <Pagination
                  //   isLoading={listLoading}
                  paginationProps={paginationProps}
                >
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    bordered={false}
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    bootstrap4
                    remote
                    keyField="uuid"
                    data={data}
                    columns={columns}
                    onTableChange={getHandlerTableChange(setQueryParams)}
                    {...paginationTableProps}
                  >
                    <PleaseWaitMessage entities={data} />
                    <NoRecordsFoundMessage entities={data} />
                  </BootstrapTable>
                </Pagination>
              );
            }}
          </PaginationProvider>
        </CardBody>
      </Card>
    </>
  );
}
