import React, { useState, useEffect, useContext } from 'react';
import { useFormikContext } from 'formik';
import { OrderFormContext } from './FormContext';
import { Card, CardBody, CardFooter } from '../../../../../_metronic/_partials/controls';
import { buttonStatus, ORDER_STATUS } from '../OrderUIHelpers';
import { RoleChecker } from '../../../../RoleChecker';
import { ORDER_STATUS_VALIDATION } from '../../../OrderLogistic/pages/OrderLogisticUIHelpers';
import { CONFIG_HELPER } from '../../../../config/Helper';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { toastOption } from '../../../../../_metronic/_helpers';
import axios from 'axios';
import * as moment from 'moment';
import { ORDER_RECEIPT, ORDER_VERIFY_PAYMENT, UPDATE_ORDER } from '../../_redux/order/orderCrud';
import { useHistory } from 'react-router-dom';
import { VoidModal } from './modals/VoidModal';

const GET_ADMIN_PROMO = gql`
  query getPromoConfigs($promo_text: String!, $promo_url: String!) {
    getCurrentAdmin {
      role
      name
      email
      hp
    }

    promoText: getConfig(keyname: $promo_text) {
      key_config
      value_config
    }

    promoUrl: getConfig(keyname: $promo_url) {
      key_config
      value_config
    }
  }
`;

export const OrderFormActions = () => {
  // * Hooks
  const history = useHistory();

  // * Contexts
  const { order, configs } = useContext(OrderFormContext);
  const { values, handleSubmit } = useFormikContext();

  // * States
  const [currentAdmin, setCurrentAdmin] = useState(null);
  const [promo, setPromo] = useState({
    text: '',
    url: '',
  });
  const [printLoading, setPrintLoading] = useState(false);
  const [showVoidModal, setShowVoidModal] = useState(false);

  // * Queries
  const [fetchAdminAndPromo] = useLazyQuery(GET_ADMIN_PROMO, {
    onCompleted: (data) => {
      const { getCurrentAdmin = {}, promoText = {}, promoUrl = {} } = data ?? {};

      setCurrentAdmin(getCurrentAdmin);
      setPromo({
        text: promoText.value_config,
        url: promoUrl.value_config,
      });
    },
    onError: (error) => {
      console.error(error);
    },
    fetchPolicy: 'cache-and-network',
  });
  const [fetchUpdateOrder, { loading: updateOrderLoading }] = useMutation(UPDATE_ORDER, {
    onCompleted: (data) => {
      toast.success('Success update order', toastOption);
      history.go(0);
    },
    onError: (error) => {
      toast.error(`Failed update order ${error}`, toastOption);
    },
  });
  const [fetchVerifyPayment, { loading: verifyLoading }] = useMutation(ORDER_VERIFY_PAYMENT, {
    onCompleted: (data) => {
      toast.success('Success verify payment!', toastOption);
      history.go(0);
    },
    onError: (err) => {
      console.log(err);
      toast.error('Failed verify payment!', toastOption);
    },
  });
  const [getOrderReceipt] = useLazyQuery(ORDER_RECEIPT, {
    onCompleted: async ({ getOrder: order }) => {
      const { branch, member, order_delivery, order_item } = order;

      if (!order) {
        toast.error('Unable to fetch order');
        return;
      }

      let items = [];

      order_item.forEach((item) => {
        let strVariant = '';
        if (
          item.product_sku_snapshot.variant_value_1 &&
          item.product_sku_snapshot.variant_value_1 != 'UMUM'
        ) {
          strVariant = ` - ${item.product_sku_snapshot.variant_value_1}`;
        }
        if (
          item.product_sku_snapshot.variant_value_2 &&
          item.product_sku_snapshot.variant_value_2 != 'UMUM'
        ) {
          strVariant += ` - ${item.product_sku_snapshot.variant_value_2}`;
        }
        items.push({
          product_name: item.product_snapshot.name + strVariant,
          sku: item.product_sku_snapshot.sku,
          price: +item.product_sku_snapshot.price_before,
          weight: +item.product_snapshot.weight * +item.qty,
          qty: +item.qty,
          price_discount: +item.product_sku_snapshot.price_before - +item.assigned_price,
        });
      });

      const data = {
        header: {
          sale_hid: order.invoice_no,
          officer: currentAdmin.name,
          is_dropship: order.is_dropship,
          dropship_name: order.is_dropship ? order.dropship_name : null,
          dropship_hp: order.is_dropship ? order.dropship_hp : null,
          date: moment(order.created_at).format('YYYY-MM-DD HH:mm:ss'),
          warehouse: branch.name,
          shipping_cost: order.order_delivery ? order.order_delivery.shipping_cost : 0,
          shipping_name: order_delivery && order_delivery.receiver_name,
          shipping_hp: order_delivery && order_delivery.receiver_hp,
          shipping_address: order_delivery ? order_delivery.address : '',

          shipping_address_2: order_delivery
            ? `${order_delivery.sub_district}, ${order_delivery.city}, ${order_delivery.province}`
            : '',
          promo_text: promo.text,
          promo_url: promo.url,
        },
        items,
      };

      try {
        const response = await axios({
          method: 'POST',
          url: 'https://thermal-print.gayalo.com/print',
          headers: {
            'Content-Type': 'application/json',
          },
          data,
        });

        toast.success('Print Success');
      } catch (error) {
        toast.error(error.message);
      } finally {
        if (order && order.status === ORDER_STATUS.PAID) {
          await fetchUpdateOrder({
            variables: {
              uuid: order.uuid,
              status: ORDER_STATUS.PROCESS,
            },
          });
        }

        setPrintLoading(false);
        history.push(`/order/${order.uuid}/edit`);
      }
    },
    onError: (error) => {
      toast.error(error.message);
      setPrintLoading(false);
    },
    fetchPolicy: 'cache-and-network',
  });

  // * Effects
  useEffect(() => {
    fetchAdminAndPromo({
      variables: {
        promo_text: CONFIG_HELPER.promo_text,
        promo_url: CONFIG_HELPER.promo_url,
      },
    });
  }, [order]);

  // * Functions
  function updateOrderStatus() {
    if (!order) {
      return;
    }

    const { updateTo } = buttonStatus(values.status);

    if (updateTo === ORDER_STATUS.PAID) {
      return verifyPayment();
    }

    if (values.uuid) {
      fetchUpdateOrder({
        variables: {
          uuid: values.uuid,
          status: updateTo,
        },
      });
    }
  }

  function verifyPayment() {
    if (order && order.order_payment) {
      fetchVerifyPayment({
        variables: {
          payment_uuid: order.order_payment.uuid,
        },
      });
    }
  }

  function setToVoid(reason) {
    if (values.uuid) {
      fetchUpdateOrder({
        variables: {
          uuid: values.uuid,
          status: ORDER_STATUS.VOID,
          reason,
        },
      });
    }
  }

  function printReceipt() {
    if (order) {
      getOrderReceipt({ variables: { uuid: order.uuid } });
    }
  }

  return (
    <Card>
      <VoidModal
        show={showVoidModal}
        onHide={() => setShowVoidModal(false)}
        updateVoid={setToVoid}
        order={values}
      />
      <CardBody className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          {RoleChecker('update', 'order') && (
            <>
              {/* // * Void Button */}
              {values.uuid &&
                ![
                  // ORDER_STATUS.SENDING,
                  ORDER_STATUS.RECEIVED,
                  // ORDER_STATUS.RECEIVED_CONFIRM,
                  // ORDER_STATUS.FINISH,
                  ORDER_STATUS.VOID,
                ].includes(values.status) && (
                  <button className="btn btn-danger" onClick={() => setShowVoidModal(true)}>
                    VOID
                  </button>
                )}
            </>
          )}
        </div>
        <div className="d-flex align-items-center">
          {/* // * Save Button */}
          {!values.uuid && (
            <button type="button" className={`btn btn-primary ml-3`} onClick={handleSubmit}>
              Save
            </button>
          )}
          {RoleChecker('update', 'order') && (
            <>
              {/* // * Print Receipt Button */}
              {values.uuid &&
                ![ORDER_STATUS.NEW, ORDER_STATUS.DRAFT, ORDER_STATUS.PAYMENT].includes(
                  values.status
                ) && (
                  <button
                    type="button"
                    style={{ height: '35.59px', minWidth: '95px' }}
                    className="btn btn-primary ml-3 float-right d-flex justify-content-center align-items-center"
                    onClick={printReceipt}
                  >
                    Print Receipt
                  </button>
                )}

              {/* // ? Update Button for online orders */}
              {ORDER_STATUS_VALIDATION.includes(values.status) && !values.is_online && (
                <button
                  type="submit"
                  className="btn btn-info ml-3 float-right"
                  onClick={updateOrderStatus}
                >
                  {buttonStatus(values.status).updateTo}
                </button>
              )}

              {ORDER_STATUS_VALIDATION.includes(values.status) &&
                values.status !== ORDER_STATUS.PAYMENT &&
                values.status !== ORDER_STATUS.DRAFT &&
                values.status !== ORDER_STATUS.PAID && (
                  <button
                    type="submit"
                    className="btn btn-info ml-3 float-right"
                    onClick={updateOrderStatus}
                  >
                    {buttonStatus(values.status).updateTo}
                  </button>
                )}

              {/* // ? Finish button for updating 'SENDING' manual store orders */}
              {values.uuid &&
                values.status === ORDER_STATUS.SENDING &&
                values.store_uuid === configs.default_store_uuid && (
                  <button
                    type="submit"
                    className="btn btn-info ml-3 float-right"
                    onClick={updateOrderStatus}
                  >
                    {buttonStatus(values.status).updateTo}
                  </button>
                )}
            </>
          )}
        </div>
      </CardBody>
    </Card>
  );
};
