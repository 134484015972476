export const VersionPlatformStatusCssClasses = [
  "danger",
  "success",
  "info",
  "",
];
export const VersionPlatformStatusTitles = [
  "Suspended",
  "Active",
  "Pending",
  "",
];
export const VersionPlatformTypeCssClasses = ["success", "primary", ""];
export const VersionPlatformTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "created_at", order: "desc" }];
export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
];
export const initialFilter = {
  admin_uuid: "",
  message: "",
  page_number: 1,
  page_size: 10,
  platform: "",
  sort_field: "created_at",
  sort_order: "DESC",
  version_number: "",
};

export const initialValue = {
  message: "",
  platform: "",
  version_number: "",
};

export const VERSION_STATUS = {
  CRITICAL: "CRITICAL",
  DEPRECATED: "DEPRECATED",
  LATEST: "LATEST",
};

export const VERSION_STATUS_OPTIONS = [
  {
    label: "CRITICAL",
    value: "CRITICAL",
  },
  {
    label: "DEPRECATED",
    value: "DEPRECATED",
  },
  // {
  //   label: "LATEST",
  //   value: "LATEST",
  // },
];

export const status = [
  {
    name: "Aktif",
    value: "ACTIVE",
  },
  {
    name: "Tidak Aktif",
    value: "INACTIVE",
  },
];

export const category = [
  {
    name: "Global",
    value: "GLOBAL",
  },
  {
    name: "Individu",
    value: "INDIVIDU",
  },
];

export const badge = (status) => {
  let data = {};
  if (status === "ACTIVE") data = { text: "ACTIVE", type: "success" };
  else data = { text: "INACTIVE", type: "warning" };

  return data;
};
