import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../modules/MasterData/_redux/supplier/supplierActions";
import { SupplierForm } from "./SupplierForm";
import { initialValue } from "../../modules/MasterData/pages/supplier/SupplierUIHelpers";

export function CreateSupplierModal({ id, show, onHide, history, linkTo }) {
  // Supplier Redux state
  const dispatch = useDispatch();

  // server request for saving supplier
  const saveSupplier = (supplier) => {
    dispatch(actions.createSupplier(supplier)).then(() => {
      onHide();
      history.push(`${linkTo}`);
    });
  };

  return (
    <Modal
      size="md"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Tambah Supplier
        </Modal.Title>
      </Modal.Header>
      <SupplierForm
        saveSupplier={saveSupplier}
        supplier={initialValue}
        onHide={onHide}
      />
    </Modal>
  );
}
