import React from "react";
import { Route } from "react-router-dom";
import { MarketplaceTransactionLoadingDialog } from "./marketplaceTransaction-loading-dialog/MarketplaceTransactionLoadingDialog";
import { MarketplaceTransactionUIProvider } from "./MarketplaceTransactionUIContext";
import { MarketplaceTransactionCard } from "./MarketplaceTransactionCard";
import { MarketplaceTransactionEditDialog } from "./marketplaceTransaction-edit/MarketplaceTransactionEdit";

export function MarketplaceTransactionPage({ history }) {
  const marketplaceTransactionUIEvents = {
    newMarketplaceTransactionButtonClick: () => {
      history.push("/marketplace-transaction/new");
    },
    openEditMarketplaceTransaction: (id) => {
      history.push(`/marketplaceTransaction/${id}/edit`);
    },
  };

  return (
    <MarketplaceTransactionUIProvider
      marketplaceTransactionUIEvents={marketplaceTransactionUIEvents}
    >
      {/*<MarketplaceTransactionLoadingDialog/>*/}
      {/* <Route path="/marketplace-transaction/new">
        {({ history, match }) => (
          <MarketplaceTransactionEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/marketplace-transaction");
            }}
          />
        )}
      </Route> */}
      <MarketplaceTransactionCard history={history} />
    </MarketplaceTransactionUIProvider>
  );
}
