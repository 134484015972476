import React, { useState, useEffect } from 'react';
import { ErrorMessage, Field, Formik, useFormikContext } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import ReactSelect from 'react-select';
import AsikSelect from 'react-select/async';
import { Card, CardBody, CardHeader, Input } from '../../../../../_metronic/_partials/controls';
import { useOrderReturnContext } from './context';
import { getSelectorStyles } from '../../../../../_metronic/_helpers/style-helpers';
import { currencyFormatter, toastOption } from '../../../../../_metronic/_helpers';

const OrderGoodsSchema = yup.object().shape({
  product_sku_uuid: yup.string().required('Sku Produk wajib dipilih.'),
  price: yup.string().required('Harga wajib diisi.'),
  qty: yup.string().required('Jumlah wajib diisi.'),
  notes: yup.string().required('Catatan wajib diisi..'),
});

export const OrderReturnGoodsForm = ({ title, addItem }) => {
  const { values: parentValues } = useFormikContext();
  const { orderItems } = useOrderReturnContext();

  const [orderItemOptions, setOrderItemOptions] = useState([]);

  useEffect(() => {
    if (orderItems instanceof Array) {
      setOrderItemOptions(
        orderItems.map(({ product_sku_uuid, product_sku, ...item }) => {
          const { qty, assigned_price } = item;
          const { sku, product } = product_sku;

          return {
            label: `${product.name} - ${sku} (${qty})`,
            value: product_sku_uuid,
            product_sku_uuid,
            sku,
            qty,
            product_name: product.name,
            price: assigned_price,
          };
        })
      );
    }
  }, [orderItems]);

  function submitReturnItem(values, resetForm) {
    const { qty, ...input } = values;

    const item = orderItemOptions.find(
      ({ product_sku_uuid }) => product_sku_uuid === input.product_sku_uuid
    );

    if (+qty > +item.qty) {
      toast.error('Jumlah return tidak bisa melebihi jumlah order item.', toastOption);
      return;
    }

    input.qty = -Math.abs(+qty);

    addItem(input);
    resetForm();
  }

  return (
    <Formik
      initialValues={{
        product_sku_uuid: '',
        product_name: '',
        sku: '',
        qty: '',
        price: '',
        notes: '',
      }}
      validationSchema={OrderGoodsSchema}
      onSubmit={(values, { resetForm }) => {
        submitReturnItem(values, resetForm);
      }}
    >
      {({ values, setFieldValue, handleSubmit, errors, touched }) => (
        <Card>
          <CardHeader title={title}></CardHeader>
          <CardBody>
            <div className="form-group row mb-5">
              <div className="col-12">
                <label htmlFor="product_sku_uuid">Produk</label>
                <ReactSelect
                  name="product_sku_uuid"
                  placeholder="Product Sku"
                  options={orderItemOptions}
                  value={{
                    label: values.product_name ? `${values.product_name} - ${values.sku}` : '',
                    value: values.product_sku_uuid ?? '',
                  }}
                  onChange={({ value, ...selected }) => {
                    const { product_name, sku, price } = selected;

                    setFieldValue('product_sku_uuid', value);
                    setFieldValue('product_name', product_name);
                    setFieldValue('sku', sku);
                    setFieldValue('price', price);
                  }}
                  styles={getSelectorStyles('product_sku_uuid', errors, touched)}
                  isDisabled={Boolean(parentValues.uuid)}
                />
                <ErrorMessage
                  name="product_sku_uuid"
                  render={(msg) => (
                    <span className="mt-1 text-danger" style={{ fontSize: '0.9rem' }}>
                      {msg}
                    </span>
                  )}
                />
              </div>
            </div>
            <div className="form-group row mb-5">
              <div className="col">
                <label htmlFor="price">Harga</label>
                <div className="p-3 border bg-secondary rounded-sm">
                  {currencyFormatter(values.price)}
                </div>
              </div>
              <div className="col">
                <label htmlFor="qty">Qty</label>
                <Field
                  name="qty"
                  type="number"
                  component={Input}
                  disabled={Boolean(parentValues.uuid)}
                />
              </div>
            </div>
            <div className="form-group row mb-5">
              <div className="col">
                <label htmlFor="notes">Catatan</label>
                <Field
                  name="notes"
                  type="textarea"
                  className="form-control"
                  disabled={Boolean(parentValues.uuid)}
                />
                <ErrorMessage
                  name="notes"
                  render={(msg) => (
                    <span className="mt-1 text-danger" style={{ fontSize: '0.9rem' }}>
                      {msg}
                    </span>
                  )}
                />
              </div>
            </div>
            <div className="form-group row mb-5">
              {!Boolean(parentValues.uuid) ? (
                <div className="col d-flex justify-content-end">
                  <button className="btn btn-primary" onClick={handleSubmit}>
                    Submit
                  </button>
                </div>
              ) : null}
            </div>
          </CardBody>
        </Card>
      )}
    </Formik>
  );
};
