import React, { useEffect, useRef, useState } from 'react';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderToolbar,
} from '../../../../../_metronic/_partials/controls';
import { toast } from 'react-toastify';
import { toastOption } from '../../../../../_metronic/_helpers';
import { useLazyQuery, useMutation } from '@apollo/client';
import { LoadingAnimation } from '../loading-animation/LoadingAnimation';
import { Input } from '../../../../../_metronic/_partials/controls';
import { Formik, Field, Form } from 'formik';
import { PackingProductTable } from './PackingProductTable';
import { VERIFY_ORDER_ITEM_SKU } from './PackingQuery';

export function PackingEditForm({
  history,
  order,
  actualOrderItems,
  submitVerifyOrder,
  verifyOrderPacking,
  packingValidity,
  isPrinted,
  printPacking,
  updateOrder,
}) {
  const [productSkus, setProductSkus] = useState([]);

  useEffect(() => {
    if (!packingValidity) {
      return;
    }

    setProductSkus((prev) => {
      const { skus: skusValidity } = packingValidity;

      const currentItems = prev.map((skuItem, index) => {
        const oi = actualOrderItems.find(
          (item) => item.product_sku_uuid === skuItem.product_sku_uuid
        );

        if (oi) {
          return { ...skuItem, valid: skusValidity[index], valid_qty: oi.qty };
        }

        return { ...skuItem, valid: skusValidity[index] };
      });

      let uncheckedItems = actualOrderItems.filter(
        (item) =>
          !currentItems.some((current) => current.product_sku_uuid === item.product_sku_uuid)
      );

      if (actualOrderItems instanceof Array) {
        uncheckedItems = uncheckedItems.map((item) => {
          const { product_sku_uuid, qty: actualQty, product_sku = {} } = item;
          const { sku, product: skuProduct } = product_sku ?? {};

          const product = `${skuProduct.name}, ${product_sku.variant_value_1} ${
            product_sku.variant_value_2 !== null ? `- ${product_sku.variant_value_2}` : ''
          }`;

          return {
            product,
            product_sku_uuid,
            sku,
            qty: 0,
            valid: false,
            valid_qty: actualQty,
          };
        });

        return [...currentItems, ...uncheckedItems];
      }

      return currentItems;
    });
  }, [packingValidity]);

  function addSkuItem(newItem) {
    setProductSkus((prev) => {
      const skuIndex = productSkus.findIndex((item) => item.sku === newItem.product_sku.sku);

      if (skuIndex > -1) {
        const temp = [...prev];

        temp[skuIndex] = { ...prev[skuIndex], qty: +prev[skuIndex].qty + +newItem.qty };

        return temp;
      }

      const newSku = {
        product_sku_uuid: newItem.product_sku.uuid,
        sku: newItem.product_sku.sku,
        product: `${newItem.product_sku.product.name}, ${newItem.product_sku.variant_value_1} ${
          newItem.product_sku.variant_value_2 !== null
            ? `- ${newItem.product_sku.variant_value_2}`
            : ''
        }`,
        qty: newItem.qty,
      };

      return [...prev, newSku];
    });

    if (packingValidity) {
      submitPackingData();
    }
  }

  function removeSkuItem(product_sku_uuid) {
    setProductSkus((prev) => prev.filter((item) => item.product_sku_uuid !== product_sku_uuid));

    if (packingValidity) {
      submitPackingData();
    }
  }

  const [checkSku, { loading: skuLoading }] = useLazyQuery(VERIFY_ORDER_ITEM_SKU, {
    onCompleted: (data) => {
      const { verifyOrderItemSku } = data ?? {};

      addSkuItem(verifyOrderItemSku);
    },
    onError: (data) => {
      toast.error(`Barang tidak ditemukan!`, toastOption);
    },
    fetchPolicy: 'cache-and-network',
  });

  function verifyOrderOrSku(params) {
    if (params.sku_name) {
      checkSku({
        variables: { sku_name: params.sku_name },
      });
    } else {
      submitVerifyOrder(params);
    }
  }

  function submitPackingData() {
    const sku_qty = productSkus.map(({ product_sku_uuid, qty }) => ({
      product_sku_uuid,
      qty,
    }));

    const input = {
      order_uuid: order.uuid,
      sku_qty,
    };

    verifyOrderPacking(input);
  }

  const updateToPacking = {
    uuid: order.uuid,
    status: 'PACKING',
  };

  const skuInputRef = useRef();
  const invoiceInputRef = useRef();

  useEffect(() => {
    invoiceInputRef.current.focus();

    if (order.is_paid) {
      skuInputRef.current.focus();
    }
  }, [order]);

  return (
    <>
      <LoadingAnimation open={skuLoading} />
      <Formik
        enableReinitialize={true}
        initialValues={{
          no_invoice: '',
          sku_name: '',
        }}
        onSubmit={(values, { setFieldValue }) => {
          verifyOrderOrSku(values);
          setFieldValue('sku_name', '');
        }}
      >
        {({ handleSubmit, setFieldValue, values, errors }) => (
          <>
            <Card>
              <CardHeader title="Packing Input">
                <CardHeaderToolbar>
                  <button
                    type="submit"
                    className="btn btn-danger ml-3 float-right"
                    onClick={() => {
                      history.push('/packing');
                    }}
                  >
                    Reset Form
                  </button>
                </CardHeaderToolbar>
              </CardHeader>
              <CardBody>
                <div className="form-group row">
                  <div className="col-lg-2">No Invoice</div>
                  <div className="col-lg-8">
                    <Form>
                      <input
                        name="no_invoice"
                        className="form-control"
                        component={Input}
                        placeholder="No Invoice..."
                        onChange={(e) => {
                          setFieldValue('no_invoice', e.target.value);
                        }}
                        ref={invoiceInputRef}
                      />
                    </Form>
                  </div>
                </div>
                {order.is_paid === true && (
                  <div className="form-group row">
                    <div className="col-lg-2">Barang</div>
                    <div className="col-lg-8">
                      <Form>
                        <input
                          name="sku_name"
                          className="form-control"
                          placeholder="Barang..."
                          value={values.sku_name}
                          onChange={(e) => {
                            setFieldValue('sku_name', e.target.value);
                          }}
                          ref={skuInputRef}
                        />
                      </Form>
                    </div>
                  </div>
                )}
              </CardBody>
              <CardBody>
                <PackingProductTable
                  orderItemsValidity={productSkus}
                  removeSkuItem={removeSkuItem}
                />
              </CardBody>
              <CardFooter>
                {productSkus.length > 0 && (
                  <button
                    type="submit"
                    className="btn btn-primary ml-3 float-right"
                    onClick={() => {
                      submitPackingData();
                    }}
                  >
                    Check
                  </button>
                )}
                {packingValidity && packingValidity.valid && (
                  <>
                    <button
                      type="submit"
                      className="btn btn-warning ml-3 float-right"
                      onClick={() => {
                        updateOrder(updateToPacking);
                        invoiceInputRef.current.focus();
                      }}
                    >
                      Print
                    </button>
                  </>
                )}
              </CardFooter>
            </Card>
          </>
        )}
      </Formik>
    </>
  );
}
