import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./MarketplaceOrderUIHelpers";
import { shallowEqual, useSelector } from "react-redux";

const MarketplaceOrderUIContext = createContext();

export function useMarketplaceOrderUIContext() {
  return useContext(MarketplaceOrderUIContext);
}

export const MarketplaceOrderUIConsumer = MarketplaceOrderUIContext.Consumer;

export function MarketplaceOrderUIProvider({
  marketplaceOrderUIEvents,
  children,
}) {
  const { filters } = useSelector((state) => state.marketplaceOrder, shallowEqual);
  const [queryParams, setQueryParamsBase] = useState(filters);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initMarketplaceOrder = {
    id: undefined,
    firstName: "",
    lastName: "",
    email: "",
    userName: "",
    gender: "Female",
    status: 0,
    dateOfBbirth: "",
    ipAddress: "",
    type: 1,
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initMarketplaceOrder,
    newMarketplaceOrderButtonClick:
      marketplaceOrderUIEvents.newMarketplaceOrderButtonClick,
    openEditMarketplaceOrder: marketplaceOrderUIEvents.openEditMarketplaceOrder,
  };

  return (
    <MarketplaceOrderUIContext.Provider value={value}>
      {children}
    </MarketplaceOrderUIContext.Provider>
  );
}
