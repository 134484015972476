import * as requestFromServer from "./campaignCrud";
import { campaignSlice, callTypes } from "./campaignSlice";

const { actions } = campaignSlice;

export const findCampaign = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findCampaign(queryParams)
    .then((response) => {
      const { total_items, items } = response;

      dispatch(actions.campaignsFetched({ total_items, items }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find campaign";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getCampaignById = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.campaignFetched({ campaignForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getCampaignById(id)
    .then((response) => {
      dispatch(actions.campaignFetched({ campaignForEdit: response }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find Payment Method";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const campaignFilter = params => dispatch => {
  dispatch(actions.startCall({ callTypes: callTypes.list }))
  dispatch(actions.campaignFilter(params))
}
