import { useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { toastOption } from "../../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { LoadingAnimation } from "../../../components/loading-animation/LoadingAnimation";
import {
  CREATE_INVENTORY_ITEMS,
  FIND_DETAIL_SKU_STOCK,
} from "../_redux/products/productCrud";
import FormTable from "./FormTable";

export default function ProductSkuStock({
  match: {
    params: { id, sku, product_name },
  },
  history,
}) {
  // * GET DATA
  const [dataStock, setDataStock] = useState([]);
  const [stockBranch, setStockBranch] = useState([]);
  const { loading } = useQuery(FIND_DETAIL_SKU_STOCK, {
    variables: {
      sku_uuid: id,
      sort_field: "inventory.created_at",
      sort_order: "DESC",
    },
    onCompleted: (data) => {
      const { findInventoryItems } = data;
      setDataStock(findInventoryItems.items);
      setStockBranch(findInventoryItems.branch_stocks);
    },
    fetchPolicy: "cache-and-network",
  });

  const [createInventoryStock, { loading: saveLoading }] = useMutation(
    CREATE_INVENTORY_ITEMS,
    {
      onCompleted: () => {
        toast.success("Berhasil menyesuaikan stock", toastOption);
        history.push(`/product-sku/${id}/${sku}`);
      },
      onError: (err) => {
        console.log(err);
        toast.error("Gagal menyesuaikan stock", toastOption);
      },
      fetchPolicy: "network-only",
    }
  );

  const saveAdjustmentStock = (values) => {
    createInventoryStock({
      variables: {
        branch_uuid: values.branch_uuid,
        product_sku_uuid: id,
        qty: +values.qty,
      },
    });
  };

  return (
    <>
      <LoadingAnimation open={loading || saveLoading} />
      <Card>
        <CardHeader title={`Rincian Stock: ${sku} (${product_name})`}>
          <CardHeaderToolbar>
            <button
              className="btn btn-secondary"
              onClick={() => history.push("/product")}
            >
              <i className="fa fa-arrow-left"></i>
              Kembali
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <FormTable
            dataStock={dataStock}
            stockBranch={stockBranch}
            saveAdjustmentStock={saveAdjustmentStock}
          />
        </CardBody>
      </Card>
    </>
  );
}
