import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/order/orderActions";
import { OrderEditDialogHeader } from "./OrderEditDialogHeader";
import { OrderEditForm } from "./OrderEditForm";
import { useOrderUIContext } from "../OrderUIContext";
import { ModalProgressBar } from "../../../../../_metronic/_partials/controls";

export function OrderEditDialog({ id, show, onHide }) {
  // Order UI Context
  const orderUIContext = useOrderUIContext();
  const orderUIProps = useMemo(() => {
    return {
      initOrder: orderUIContext.initOrder,
      openEditOrderDialog: orderUIContext.openEditOrderDialog,
    };
  }, [orderUIContext]);

  // Order Redux state
  const dispatch = useDispatch();
  const { actionsLoading, orderForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.orders.actionsLoading,
      orderForEdit: state.orders.orderForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    // server call for getting Order by id
    dispatch(actions.fetchOrder(id));
  }, [id, dispatch]);

  // server request for saving order
  const saveOrder = (order) => {
    if (!id) {
      // server request for creating order
      // dispatch(actions.createOrder(order)).then(() => onHide());
    } else {
      // server request for updating order
      // dispatch(actions.updateOrder(order)).then(() => onHide());
    }
  };

  return (
    <Modal
      size="xl"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {actionsLoading && <ModalProgressBar />}
      <OrderEditDialogHeader id={id} />
      <OrderEditForm
        saveOrder={saveOrder}
        // actionsLoading={actionsLoading}
        orderEdit={orderUIProps.openEditOrderDialog}
        order={orderForEdit || orderUIProps.initOrder}
        onHide={onHide}
      />
    </Modal>
  );
}
