export const CampaignStatusCssClasses = ["danger", "success", "info", ""];
export const CampaignStatusTitles = ["Suspended", "Active", "Pending", ""];
export const CampaignTypeCssClasses = ["success", "primary", ""];
export const CampaignTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "created_at", order: "desc" }];
export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
];
export const initialFilter = {
  content: "",
  is_active: null,
  name: "",
  title: "",
  page_number: 1,
  page_size: 10,
  sort_order: "DESC", // asc||desc
  sort_field: "created_at",
};

export let initialValue = {
  title: "",
  name: "",
  content: "",
  is_active: null,
  images: [],
};

export const IS_ACTIVE = [
  {
    name: "true",
    value: true,
  },
  {
    name: "false",
    value: false,
  },
];

export const TYPE = [
  {
    name: "INSTAGRAM",
    value: "INSTAGRAM",
  },
  {
    name: "BANNER",
    value: "BANNER",
  },
];

export const category = [
  {
    name: "Global",
    value: "GLOBAL",
  },
  {
    name: "Individu",
    value: "INDIVIDU",
  },
];

export const badge = (status) => {
  let data = {};
  if (status === "ACTIVE") data = { text: "ACTIVE", type: "success" };
  else data = { text: "INACTIVE", type: "warning" };

  return data;
};
