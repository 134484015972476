import React, { useState, useEffect, useContext } from 'react';
import { Field, useFormikContext } from 'formik';
import { OrderFormContext } from './FormContext';
import { Input } from '../../../../../_metronic/_partials/controls';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from '../../../../../_metronic/_partials/controls';

import { ORDER_STATUS } from '../OrderUIHelpers';
import { currencyFormatter, toastOption } from '../../../../../_metronic/_helpers';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import BootstrapTable from 'react-bootstrap-table-next';
import ReactSelect from 'react-select';
import { GET_SHIPPING_COST } from '../../../MasterData/_redux/shipper-service/shipperServiceCrud';
import { SS_CATEGORY } from '../../../../config/Helper';
import { GET_BRANCH } from '../../../MasterData/_redux/branch/branchCrud';
import { UpdateShipmentModal } from './modals/UpdateShipmentModal';
import { UPDATE_ORDER_SHIPMENT } from '../../_redux/order/orderCrud';
import { toast } from 'react-toastify';
import { CircularProgress } from '@material-ui/core';
import CurrencyInput from 'react-currency-input-field';

export const OrderShipmentForm = () => {
  // * Hooks
  const history = useHistory();

  // * Contexts
  const { order, orderItems, configs } = useContext(OrderFormContext);
  const { values, setFieldValue, errors, touched } = useFormikContext();
  const { store, order_delivery } = values;
  const { service_snapshot } = order_delivery;

  // * States
  const [shippingServiceOptions, setShippingServiceOptions] = useState([]);
  const [isGayaloStore, setIsGayaloStore] = useState(false);
  const [branch, setBranch] = useState(null);
  const [totalWeight, setTotalWeight] = useState(0);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  // * Queries
  const [getShippingCost, { loading: getShippingCostLoading }] = useLazyQuery(GET_SHIPPING_COST, {
    onCompleted: (data) => {
      const { customerGetShippingCost } = data || {};
      const { is_gayalo, discount, results = [] } = customerGetShippingCost || {};
      const services = [];

      results.forEach((item) => {
        const service = { ...item };
        const discountedService = { ...item };

        service.label = `${service.company_name} - ${service.service_name}`;
        service.value = service.uuid;
        service.discount = 0;
        service.price = +service.price;
        service.final_price = +service.price;

        services.push({ ...service });

        if (service.category.includes(SS_CATEGORY.BEBAS_ONGKIR)) {
          discountedService.label = `${discountedService.company_name} - ${discountedService.service_name} - (BEBAS ONGKIR)`;
          discountedService.value = discountedService.uuid;
          discountedService.discount = discount;
          discountedService.price = +item.price;
          discountedService.final_price =
            +discount > +discountedService.price ? 0 : +discountedService.price - +discount;
          services.push({ ...discountedService });
        }
      });

      setShippingServiceOptions(services);

      if (is_gayalo != null) {
        setIsGayaloStore(is_gayalo);
      }
    },
    onError: (data) => {
      console.log(data);
    },
    fetchPolicy: 'cache-and-network',
  });

  const [fetchBranch] = useLazyQuery(GET_BRANCH, {
    onCompleted: (data) => {
      const { getBranch } = data ?? {};

      setBranch(getBranch);
    },
    onError: (data) => {
      console.log(data);
    },
    fetchPolicy: 'cache-and-network',
  });

  const [fetchUpdateOrderShipment, { loading: updateShipmentLoading }] = useMutation(
    UPDATE_ORDER_SHIPMENT,
    {
      onCompleted: (data) => {
        const { updateOrder } = data ?? {};
        const { order_delivery = {} } = updateOrder;

        setFieldValue('order_delivery.awb', order_delivery.awb);
        setFieldValue('order_delivery.real_shipping_cost', order_delivery.real_shipping_cost);

        setShowUpdateModal(false);
        toast.success('Success', toastOption);
      },
      onError: (error) => {
        toast.error(error, toastOption);
      },
    }
  );

  // * Effects
  useEffect(() => {
    if (configs.default_branch_uuid) {
      fetchBranch({ variables: { uuid: configs.default_branch_uuid } });
    }
  }, [configs]);
  useEffect(() => {
    const totalWeight = orderItems.reduce((tot, { weight }) => tot + (weight ?? 0), 0);

    setTotalWeight(totalWeight);
  }, [orderItems]);

  // * Functions
  async function fetchShippingCost() {
    if (order_delivery && branch && orderItems instanceof Array) {
      const addressFields = ['city', 'codepos', 'province', 'sub_district'];
      const branchFields = ['postal_code', 'subdistrict_id'];

      if (
        addressFields.every((field) => order_delivery[field]) &&
        branchFields.every((field) => branch[field])
      ) {
        getShippingCost({
          variables: {
            destination_city_name: order_delivery.city,
            destination_postal_code: order_delivery.codepos,
            destination_province_name: order_delivery.province,
            destination_subdistrict_name: order_delivery.sub_district,
            origin_postal_code: branch.postal_code,
            origin_subdistrict_id: branch.subdistrict_id,
            store_uuid: store.uuid,
            weight: totalWeight,
          },
        });
      }
    }
  }

  function updateOrderShipment(values) {
    if (updateShipmentLoading) {
      return;
    }

    if (order) {
      fetchUpdateOrderShipment({
        variables: {
          updateOrderInput: {
            uuid: order.uuid,
            real_shipping_cost: values.real_shipping_cost,
            awb: values.awb,
          },
        },
      });
    }
  }

  // * Constants
  const columns = [
    {
      dataField: 'company_code',
      text: 'Company',
    },
    {
      dataField: 'service_name',
      text: 'Service',
    },
    {
      dataField: 'weight',
      text: 'Weight (g)',
    },
    {
      dataField: 'price',
      text: 'Cost',
      formatter: (cell) => {
        return <strong>{currencyFormatter(cell)}</strong>;
      },
    },
  ];

  if (!values.is_online) {
    return null;
  }

  if (order && order.is_online && service_snapshot) {
    return (
      <Card>
        <UpdateShipmentModal
          show={showUpdateModal}
          onHide={() => setShowUpdateModal(false)}
          actionsLoading={updateShipmentLoading}
          initialValues={{
            shipping_service_name: `${service_snapshot.company_code} - ${service_snapshot.service_name}`,
            shipping_cost: order_delivery.shipping_cost,
            real_shipping_cost: order_delivery.real_shipping_cost,
            awb: order_delivery.awb,
          }}
          submit={updateOrderShipment}
        />
        <CardHeader title="Biaya Pengiriman">
          <CardHeaderToolbar className="d-flex align-items-center">
            {values.uuid &&
              [
                ORDER_STATUS.DRAFT,
                ORDER_STATUS.NEW,
                ORDER_STATUS.PAYMENT,
                ORDER_STATUS.PAID,
                ORDER_STATUS.PROCESS,
                ORDER_STATUS.PACKING,
                ORDER_STATUS.PICKUP,
              ].includes(values.status) && (
                <button
                  style={{ width: '75px' }}
                  className="btn btn-success d-flex justify-content-center align-items-center"
                  onClick={() => setShowUpdateModal(true)}
                >
                  {updateShipmentLoading ? (
                    <CircularProgress color="inherit" size={16} />
                  ) : (
                    <span>Edit</span>
                  )}
                </button>
              )}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div className="row m-0 p-0">
            <div className="col-4 col-md-3">Layanan Pengiriman</div>
            <div className="col-8 col-md-9">
              <span>:</span>
              <span className="ml-3">{`${service_snapshot.company_code} - ${
                service_snapshot.service_name
              } ${
                +service_snapshot.price > service_snapshot.final_price ? '- (BEBAS ONGKIR)' : ''
              }`}</span>
            </div>
            <div className="col-4 col-md-3">Biaya Pengiriman</div>
            <div className="col-8 col-md-9">
              <span>:</span>
              <span className="ml-3">{currencyFormatter(service_snapshot.final_price)}</span>
            </div>
            <div className="col-4 col-md-3">Biaya Pengiriman Aktual</div>
            <div className="col-8 col-md-9">
              <span>:</span>
              <span className="ml-3">{currencyFormatter(order_delivery.real_shipping_cost)}</span>
            </div>
            <div className="col-4 col-md-3">AWB</div>
            <div className="col-8 col-md-9">
              <span>:</span>
              <span className="ml-3">{order_delivery.awb}</span>
            </div>
            <div className="col-4 col-md-3">Total Berat</div>
            <div className="col-8 col-md-9">
              <span>:</span>
              <span className="ml-3">{totalWeight}</span>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader title="Biaya Pengiriman">
        <CardHeaderToolbar></CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <form>
          <div className="form-group row">
            <div className="col-md-4 mb-5 mb-md-0 mb-4">
              <label htmlFor="city">Shipping Service *</label>
              <ReactSelect
                name="shipping_service_uuid"
                value={
                  service_snapshot
                    ? {
                        label: `${service_snapshot.company_code} - ${
                          service_snapshot.service_name
                        } ${
                          +service_snapshot.price > service_snapshot.final_price
                            ? '- (BEBAS ONGKIR)'
                            : ''
                        }`,
                        value: service_snapshot.uuid,
                      }
                    : {
                        label: '',
                        value: '',
                      }
                }
                options={shippingServiceOptions}
                onChange={({ value: uuid, label: name, discount, ...shippingService }) => {
                  setFieldValue('order_delivery.service_snapshot', shippingService);
                  setFieldValue('order_delivery.shipping_service', {
                    ...shippingService,
                    uuid,
                    name,
                  });
                }}
                isDisabled={Boolean(values.uuid)}
                isLoading={getShippingCostLoading}
                onFocus={() => fetchShippingCost()}
                noOptionsMessage={() => <p>Shipping Service tidak ditemukan</p>}
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    border:
                      errors.order_delivery &&
                      errors.order_delivery.service_snapshot &&
                      touched.order_delivery
                        ? '1px solid rgb(246, 78, 96)'
                        : '1px solid #ddd',
                  }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    color:
                      errors.order_delivery &&
                      errors.order_delivery.service_snapshot &&
                      touched.order_delivery
                        ? 'rgb(246, 78, 96)'
                        : '#ddd',
                  }),
                }}
              />
            </div>
            <div className="col-md-4 mb-5 mb-md-0">
              <label htmlFor="shipping_cost">Shipping Cost *</label>
              <CurrencyInput
                id="shipping_cost"
                name="order_delivery.service_snapshot.final_price"
                className="form-control"
                disabled={Boolean(values.uuid)}
                placeholder="Rp 10.000"
                decimalsLimit={2}
                value={order_delivery.service_snapshot.final_price}
                onValueChange={(value) => {
                  setFieldValue('order_delivery.service_snapshot.final_price', +value ?? 0);
                }}
              />
            </div>
            <div className="col-md-4 mb-5 mb-md-0">
              <label htmlFor="awb">AWB</label>
              <Field
                id="awb"
                name="awb"
                disabled={values.uuid}
                component={Input}
                value={order_delivery.awb}
                onChange={(e) => setFieldValue('order_delivery.awb', e.target.value)}
              />
            </div>
          </div>
          <BootstrapTable
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            bootstrap4
            remote
            keyField="uuid"
            data={
              order_delivery.service_snapshot
                ? [{ ...order_delivery.service_snapshot, weight: totalWeight }]
                : []
            }
            columns={columns}
          />
        </form>
      </CardBody>
    </Card>
  );
};
