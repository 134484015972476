import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '../SvgIcon'
import { defaultFill } from '../constant'

const Upload = props => (
  <SvgIcon {...props}>
    <path fill={props.fill} className="custom-fill" d="M9,16V10H5L12,3L19,10H15V16H9M5,20V18H19V20H5Z" />
  </SvgIcon>
)

Upload.propTypes = {
  fill: PropTypes.string,
}

Upload.defaultProps = {
  fill: defaultFill,
}

export default Upload
