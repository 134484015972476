import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { v4 as uuidv4 } from "uuid";
import { Input } from "../../../../../_metronic/_partials/controls";
import { Formik, Field, Form, ErrorMessage } from "formik";
import CreateableSelect from "react-select/creatable";
import {
  Form as Forms,
  OverlayTrigger,
  Popover,
  PopoverContent,
  PopoverTitle,
  Table,
} from "react-bootstrap";
import { DeleteSkuModal } from "./DeleteSkuModal";
import { setImageResolution } from "../../../../config/Helper";
import { gql, useLazyQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { VariantItem } from "./variant-sku/VariantItem";
import { SkuItem } from "./variant-sku/SkuItem";

export function VariantSku({
  skuInit,
  uploadSkuVariant,
  uploadImage,
  updateSkuImage,
  deleteImageSku,
  setOpenPrintLabelModal,
  setPrintData,
  productData
}) {
  const [variants, setVariants] = useState([]);
  // ? Initial skus
  const [skus, setSkus] = useState([]);
  // ? Separate array state to keep track of skus which have been updated/deleted
  const [finalSkus, setFinalSkus] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [templatePrice, setTemplatePrice] = useState("");
  const [templatePriceBefore, setTemplatePriceBefore] = useState("");

  useEffect(() => {
    // ? Copy redux objects to remove immutability
    if (skuInit.product_variant instanceof Array) {
      if (skuInit.product_variant.length === 0) {
        setVariants([
          {
            uuid: uuidv4(),
            name: "",
            options: [],
            product_uuid: "",
            status: "create",
          },
        ]);
      } else {
        setVariants(
          skuInit.product_variant
            .map((variant) => ({ ...variant, status: "" }))
            .sort((a, b) => +a.created_at - +b.created_at)
        );
      }
    }

    // ? Copy redux objects to remove immutability
    if (skuInit.sku instanceof Array) {
      setSkus(skuInit.sku.map((sku) => ({ ...sku, status: "" })));
    }
  }, [skuInit]);

  function applyTemplateValues() {
    setSkus(
      skus.map((sku) => {
        if (selectedRows.includes(sku.uuid)) {
          return {
            ...sku,
            price: templatePrice,
            priceBefore: templatePriceBefore,
          };
        }

        return sku;
      })
    );

    const finalSkusCpy = [...finalSkus];
    selectedRows.forEach((uuid) => {
      const skuIndex = finalSkusCpy.findIndex((sku) => sku.uuid === uuid);

      if (skuIndex > -1) {
        return (finalSkusCpy[skuIndex] = {
          ...finalSkusCpy[skuIndex],
          price: templatePrice,
          priceBefore: templatePriceBefore,
          status: finalSkusCpy[skuIndex].status === "create" ? "create" : "update",
        });
      }

      const sku = skus.find((sku) => sku.uuid === uuid);

      if (sku) {
        finalSkusCpy.push({
          ...sku,
          price: templatePrice,
          priceBefore: templatePriceBefore,
          status: sku.status === "create" ? "create" : "update",
        });
      }
    });

    setFinalSkus(finalSkusCpy);
  }

  function addVariant() {
    if (variants.length >= 2) {
      return;
    }

    setVariants([...variants, { uuid: uuidv4(), status: "create", name: "", options: [] }]);
  }

  function addVariantOption(variant_uuid, newValue) {
    setVariants((variants) => {
      const temp = [...variants];
      const variantIndex = variants.findIndex(({ uuid }) => uuid === variant_uuid);

      if (variantIndex > -1) {
        temp[variantIndex].options = [...temp[variantIndex].options, newValue];
        temp[variantIndex].status = temp[variantIndex].status === "create" ? "create" : "update";

        return temp;
      } else {
        return temp;
      }
    });

    setTimeout(() => generateOptionSkus(variant_uuid, newValue), 0);
  }

  function removeVariantOption(variant_uuid, removedValue) {
    setVariants((variants) => {
      const temp = [...variants];
      const variantIndex = variants.findIndex(({ uuid }) => uuid === variant_uuid);

      if (variantIndex > -1) {
        temp[variantIndex].options = temp[variantIndex].options.filter(
          (opt) => opt !== removedValue.value
        );
        temp[variantIndex].status = temp[variantIndex].status === "create" ? "create" : "update";

        return temp;
      } else {
        return temp;
      }
    });

    setTimeout(() => deleteOptionSkus(variant_uuid, removedValue.value), 0);
  }

  function handleSkuUpdate(sku) {
    const temp = [...finalSkus];
    const skuIndex = temp.findIndex((item) => item.uuid === sku.uuid);

    if (skuIndex > -1) {
      const item = temp[skuIndex];
      temp[skuIndex] = { ...item, ...sku, status: item.status === "create" ? "create" : "update" };

      return setFinalSkus(temp);
    }

    setFinalSkus((prev) => [
      ...prev,
      { ...sku, status: sku.status === "create" ? "create" : "update" },
    ]);
  }

  function handleSkuDelete(sku) {
    const skuIndex = skus.findIndex((item) => item.uuid === sku.uuid);

    if (skuIndex < 0) {
      return;
    }

    if (skus[skuIndex].status === "create") {
      return setFinalSkus((prev) => prev.filter(({ uuid }) => uuid !== sku.uuid));
    }

    const finalSkuIndex = finalSkus.findIndex((item) => item.uuid === sku.uuid);

    if (finalSkuIndex > -1) {
      const finalSkuCpy = [...finalSkus];

      if (finalSkuCpy[finalSkuIndex].status === "update") {
        finalSkuCpy[finalSkuIndex].status = "delete";

        return setFinalSkus(finalSkuCpy);
      }
    }

    setFinalSkus((prev) => [...prev, { ...sku, status: "delete" }]);
  }

  function generateOptionSkus(variant_uuid, newValue) {
    // ? Copy all current skus
    let oldSkus = [...skus];

    const updatedVariant = variants.find((variant) => variant.uuid === variant_uuid);
    const variantIndex = variants.findIndex((variant) => variant.uuid === variant_uuid);
    const otherVariants = variants.filter((variant) => variant.uuid !== variant_uuid);

    // ? Product has no skus or other variants have 0 option
    if (skus.length === 0 || otherVariants.every((other) => other.options.length === 0)) {
      // ? Create new sku with newValue
      const newSku = {
        uuid: uuidv4(),
        sku: "",
        price: 0,
        priceBefore: 0,
        status: "create",
      };

      if (variantIndex === 0) {
        newSku.variant_uuid_1 = variant_uuid;
        newSku.variant_value_1 = newValue;
      } else {
        newSku.variant_uuid_2 = variant_uuid;
        newSku.variant_value_2 = newValue;
      }

      oldSkus.push(newSku);

      setFinalSkus((prev) => [...prev, newSku]);

      return setSkus(oldSkus);
    }

    if (updatedVariant.options instanceof Array && updatedVariant.options.length === 1) {
      // ? Iterate over current skus and update/create new sku
      oldSkus = oldSkus.map((item, index) => {
        // ? Update sku variant_uuid_1 with variant_uuid if none
        if (!item.variant_uuid_1) {
          const sku = {
            ...item,
            variant_uuid_1: variant_uuid,
            variant_value_1: newValue,
          };

          handleSkuUpdate(sku);

          return sku;
        }

        // ? Update sku variant_uuid_2 with variant_uuid if none
        if (!item.variant_uuid_2) {
          const sku = {
            ...item,
            variant_uuid_2: variant_uuid,
            variant_value_2: newValue,
          };

          handleSkuUpdate(sku);

          return sku;
        }

        // ? Sku has variant_uuid_1 & variant_uuid_2, skip
        if (item.variant_uuid_1 && item.variant_uuid_2) {
          return item;
        }
      });

      return setSkus(oldSkus);
    }

    // ? Product has 2/more variants, create skus for all other variants with newValue
    const newFinalSkus = [];
    otherVariants.forEach((variant) => {
      const { options } = variant;

      if (!(options instanceof Array)) {
        return;
      }

      options.forEach((option) => {
        const newSku = {
          uuid: uuidv4(),
          sku: "",
          price: 0,
          priceBefore: 0,
          status: "create",
        };

        if (variantIndex === 0) {
          newSku.variant_uuid_1 = variant_uuid;
          newSku.variant_uuid_2 = variant.uuid;
          newSku.variant_value_1 = newValue;
          newSku.variant_value_2 = option;
        } else {
          newSku.variant_uuid_1 = variant.uuid;
          newSku.variant_uuid_2 = variant_uuid;
          newSku.variant_value_1 = option;
          newSku.variant_value_2 = newValue;
        }

        newFinalSkus.push(newSku);
        oldSkus.push(newSku);
      });
    });

    setFinalSkus((prev) => [...prev, newFinalSkus]);
    setSkus(oldSkus);
  }

  function deleteOptionSkus(variant_uuid, removedValue) {
    const updatedVariant = variants.find((variant) => variant.uuid === variant_uuid);
    const otherVariants = variants.filter((variant) => variant.uuid !== variant_uuid);

    if (!updatedVariant) {
      return;
    }

    if (updatedVariant.options.length === 0) {
      if (
        otherVariants.length > 0 &&
        otherVariants.some(({ options }) => options && options.length > 0)
      ) {
        const updatedSkus = skus.map((sku) => {
          if (sku.variant_uuid_1 === variant_uuid) {
            return {
              ...sku,
              variant_uuid_1: "",
              variant_value_1: "",
            };
          } else {
            return {
              ...sku,
              variant_uuid_2: "",
              variant_value_2: "",
            };
          }
        });

        setSkus(updatedSkus);
        setFinalSkus((prev) => [
          ...prev,
          ...updatedSkus.map((sku) => ({
            ...sku,
            status: sku.status === "create" ? "create" : "update",
          })),
        ]);

        return;
      }

      return setSkus([]);
    }

    setSkus(
      skus.filter(
        ({ uuid, status, variant_uuid_1, variant_uuid_2, variant_value_1, variant_value_2 }) =>
          !(
            (variant_uuid_1 === variant_uuid && variant_value_1 === removedValue) ||
            (variant_uuid_2 === variant_uuid && variant_value_2 === removedValue)
          )
      )
    );
  }

  function selectSkuRow(sku_uuid) {
    if (!sku_uuid) {
      setSelectedRows(skus.map(({ uuid }) => uuid));
    }

    if (sku_uuid && !selectedRows.includes(sku_uuid)) {
      setSelectedRows([...selectedRows, sku_uuid]);
    }
  }

  function deselectSkuRow(sku_uuid) {
    if (!sku_uuid) {
      setSelectedRows([]);
    }

    if (sku_uuid) {
      setSelectedRows(selectedRows.filter((uuid) => uuid !== sku_uuid));
    }
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ variants, skus }}
        onSubmit={() => {
          let submitVariants = variants
            .filter((variant) => variant.status)
            .map(({ uuid, name, options, status }) => ({ uuid, name, options, status }));
          let submitSkus = finalSkus.map(
            ({
              uuid,
              status,
              product_uuid,
              variant_uuid_1,
              variant_uuid_2,
              variant_value_1,
              variant_value_2,
              sku,
              price,
              priceBefore: price_before,
            }) => ({
              uuid,
              status,
              product_uuid,
              variant_uuid_1,
              variant_uuid_2,
              variant_value_1,
              variant_value_2,
              sku,
              price,
              price_before,
            })
          );

          uploadSkuVariant({ variant: submitVariants, sku: submitSkus });
          setFinalSkus([]);
        }}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <>
            {(() => {})()}
            <DeleteSkuModal
            // show={deleteSku}
            // onHide={() => setDeleteSku(false)}
            // deleteSku={() => deleteAllSKu(setFieldValue)}
            />
            <Card>
              <CardHeader title="Variant Produk">
                <CardHeaderToolbar>
                  {/* {!haveVariant.variant2 && ( */}
                  <button
                    type="button"
                    className="btn btn-primary mr-2"
                    onClick={addVariant}
                    aria-controls="collapse-filter-product"
                  >
                    Tambah Variant
                  </button>
                  {/* )} */}
                </CardHeaderToolbar>
              </CardHeader>
              <CardBody>
                {variants[0] && (
                  <VariantItem
                    index={0}
                    item={variants[0]}
                    updateItem={(newData) => {
                      setVariants(() => {
                        const temp = [...variants];
                        const variantIndex = variants.findIndex(
                          ({ uuid }) => uuid === variants[0].uuid
                        );

                        if (variantIndex > -1) {
                          temp[variantIndex] = { ...temp[variantIndex], ...newData };
                          return temp;
                        } else {
                          return temp;
                        }
                      });
                    }}
                    addOption={(newValue) => addVariantOption(variants[0].uuid, newValue)}
                    removeOption={(removedValue) =>
                      removeVariantOption(variants[0].uuid, removedValue)
                    }
                  />
                )}
              </CardBody>
              <CardBody>
                {variants[1] && (
                  <VariantItem
                    index={1}
                    item={variants[1]}
                    updateItem={(newData) => {
                      setVariants(() => {
                        const temp = [...variants];
                        const variantIndex = variants.findIndex(
                          ({ uuid }) => uuid === variants[1].uuid
                        );

                        if (variantIndex > -1) {
                          temp[variantIndex] = { ...temp[variantIndex], ...newData };
                          return temp;
                        } else {
                          return temp;
                        }
                      });
                    }}
                    addOption={(newValue) => addVariantOption(variants[1].uuid, newValue)}
                    removeOption={(removedValue) =>
                      removeVariantOption(variants[1].uuid, removedValue)
                    }
                  />
                )}
              </CardBody>
              <CardBody>
                {selectedRows.length > 0 && (
                  <div className="row bg-secondary p-2 d-flex">
                    <div className="col-lg-4 text-center">
                      <i
                        className="fas fa-info-circle mr-8 mt-3"
                        style={{ color: '#000', fontSize: '18px' }}
                      ></i>
                      <span>Atur dan terapkan data varian</span>
                    </div>
                    <div className="col">
                      <input
                        className="form-control"
                        placeholder="Harga"
                        type="number"
                        value={templatePriceBefore}
                        onChange={(e) => {
                          setTemplatePriceBefore(+e.target.value);
                        }}
                      />
                    </div>
                    <div className="col">
                      <input
                        className="form-control"
                        placeholder="Harga Diskon"
                        type="number"
                        value={templatePrice}
                        onChange={(e) => {
                          setTemplatePrice(+e.target.value);
                        }}
                      />
                    </div>
                    <div className="col">
                      <button className="btn btn-primary" onClick={() => applyTemplateValues()}>
                        Terapkan
                      </button>
                    </div>
                  </div>
                )}
              </CardBody>
              <CardBody>
                <div className="form-group row mt-4">
                  <Table striped hover responsive className="text-left">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>
                          <input
                            id={`checkbox-all`}
                            type="checkbox"
                            onChange={(e) => {
                              if (e.target.checked) {
                                selectSkuRow();
                              } else {
                                deselectSkuRow();
                              }
                            }}
                          />
                        </th>
                        <th>{variants[0] && variants[0].name}</th>
                        <th>{variants[1] && variants[1].name}</th>
                        <th>Harga</th>
                        <th>Harga Diskon</th>
                        <th>SKU</th>
                      </tr>
                    </thead>
                    <tbody>
                      {skus.map((item, index) => (
                        <SkuItem
                          key={item.uuid}
                          index={index}
                          productSku={item}
                          onUpdate={(sku) => {
                            handleSkuUpdate(sku);
                          }}
                          onDelete={(sku) => {
                            handleSkuDelete(sku);
                          }}
                          isSelected={selectedRows.includes(item.uuid)}
                          setIsSelected={(isSelected) => {
                            if (isSelected) {
                              selectSkuRow(item.uuid);
                            } else {
                              deselectSkuRow(item.uuid);
                            }
                          }}
                          setOpenPrintLabelModal={setOpenPrintLabelModal}
                          setPrintData={setPrintData}
                          productData={productData}
                        />
                      ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
              <CardFooter>
                <button
                  type="submit"
                  onClick={() => handleSubmit()}
                  className="btn btn-info btn-elevate float-right"
                >
                  Simpan SKU
                </button>
              </CardFooter>
            </Card>
          </>
        )}
      </Formik>
    </>
  );
}
