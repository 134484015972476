import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '../SvgIcon'
import { defaultFill } from '../constant'

const AlertCircleOutline = props => (
  <SvgIcon {...props}>
    <path fill={props.fill} className="custom-fill" d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z" />
  </SvgIcon>
)

AlertCircleOutline.propTypes = {
  fill: PropTypes.string,
}

AlertCircleOutline.defaultProps = {
  fill: defaultFill,
}

export default AlertCircleOutline
