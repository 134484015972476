/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonIcon from "@material-ui/icons/Person";
import LockIcon from "@material-ui/icons/Lock";
import { logoutUser } from "../../../../../app/modules/Auth/_redux/authCrud";
import { useHistory } from "react-router-dom";

export function UserProfileDropdown() {
  const { user } = useSelector((state) => state.auth);
  const uiService = useHtmlClassService();
  const history = useHistory();
  const layoutProps = useMemo(() => {
    return {
      light:
        objectPath.get(uiService.config, "extras.user.dropdown.style") ===
        "light",
    };
  }, [uiService]);

  return (
    <>
      <Dropdown drop="down" alignRight>
        <Dropdown.Toggle
          as={DropdownTopbarItemToggler}
          id="dropdown-toggle-user-profile"
        >
          <div
            className={
              "btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto"
            }
          >
            <span className="text-white opacity-70 font-weight-bold font-size-base d-none d-md-inline mr-1">
              Hi,
            </span>{" "}
            <span className="text-white opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-4">
              {user.name}
            </span>
            <span className="symbol symbol-35">
              <span className="symbol-label text-white font-size-h5 font-weight-bold bg-white-o-30">
                {user.name[0]}
              </span>
            </span>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-sm bg-white-o-70">
          <Dropdown.Item onClick={() => history.push("/profile")}>
            <div className="font-weight text-white text-hover-info">
              <PersonIcon className="mr-2" />
              Profile
            </div>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              history.push("/logout");
              logoutUser();
              localStorage.clear();
            }}
          >
            <div className="font-weight text-white text-hover-info">
              <ExitToAppIcon className="mr-2" />
              Sign Out
            </div>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
