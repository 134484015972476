import axios from 'axios';
import { gql } from '@apollo/client';
import { QueryGraphql, MutationGraphql } from '../../../../config/ApoloRequest';
import { API } from '../../../../config/UrlEndPoint';

export const URL = `${API}master-data/supplier/`;

export const CREATE_SUPPLIER = gql`
  mutation m($createSupplierInput: CreateSupplierInput!) {
    createSupplier(createSupplierInput: $createSupplierInput) {
      uuid
      name
      email
      hp
    }
  }
`;

export async function findSuppliers(queryParams) {
  const query = gql`
    {
        findSupplier(findSupplierInput: {
            email: "${queryParams.email}", 
            hp: "${queryParams.hp}", 
            name: "${queryParams.name}"
            page_size: ${+queryParams.page_size}
            page_number: ${+queryParams.page_number}
        }){
          items {
            uuid
            name
            email
            hp
          }
          total_items
          current_page
          total_pages
        }
        
      }
    `;
  const newResponse = await QueryGraphql(query);
  return newResponse && newResponse.data.findSupplier;
}

export const SEARCH_SUPPLIERS = gql`
  query Query($name: String) {
    findSupplier(findSupplierInput: { name: $name, page_number: 1, page_size: 10 }) {
      items {
        uuid
        name
      }
    }
  }
`;

export async function searchSuppliers(queryParams) {
  let filter = {};

  for (const key in queryParams.filter) {
    if (queryParams.filter[key]) {
      filter = Object.assign(filter, { [key]: queryParams.filter[key] });
    }
  }

  if (!queryParams.filter['page_number']) {
    filter.page_number = 1;
  }

  if (!queryParams.filter['page_size']) {
    filter.page_size = 10;
  }

  // console.log(`
  //   query Query {
  //     findBranches(findBranchInput: ${JSON.stringify(filter).replace(/"([^"]+)":/g, "$1:")}
  //     ) {
  //       items {
  //         uuid
  //         name
  //       }
  //     }
  //   }
  // `);

  const query = gql`
    {
      findSupplier(findSupplierInput: ${JSON.stringify(filter).replace(/"([^"]+)":/g, '$1:')}){
        items {
          uuid
          name
        }
      }
      
    }
  `;

  const newResponse = await QueryGraphql(query);
  return newResponse.data.findSupplier;
}

export async function createSupplier(payload) {
  const query = gql`
    mutation {
        createSupplier(createSupplierInput: {
          name: "${payload.name}"
          email: "${payload.email}"
          hp: "${payload.hp}"
          password: "${payload.password}"
        })
        {
          uuid
          name
          email
          hp
          password
          created_at
          updated_at
          is_active
        }
      }`;
  const newResponse = await MutationGraphql(query);
  return newResponse.data.createSupplier;
}

export async function updateSupplier(payload) {
  const query = gql`
  mutation{
    updateSupplier(updateSupplierInput: {
      uuid: "${payload.uuid}"
      name: "${payload.name}"
      email: "${payload.email}"
      hp: "${payload.hp}"
      password: "${payload.password}"
    })
    {
      name
      email
      hp
    }
  }`;

  const newResponse = await MutationGraphql(query);
  return newResponse.data.updateSupplier;
}

export async function deleteSupplier(payload) {
  const query = gql`
  mutation{
    deleteSupplier(uuid: "${payload}"){
      message
      item{
        name
        email
        hp
      }
    }
  }`;

  const newResponse = await MutationGraphql(query);
  return newResponse.data.deleteSupplier;
}

export async function getSupplierById(payload) {
  const query = gql`
    {
      __typename
      getSupplier(uuid: "${payload}") {
        uuid
        email
        hp
        name
      }
    }
  `;

  const newResponse = await QueryGraphql(query);
  return newResponse.data.getSupplier;
}

// CREATE =>  POST: add a new customer to the server
// export function createSupplier(supplier) {
//     return axios.post(URL, supplier);
// }

// GET ALL
export function getAllSuppliers() {
  return axios.get(URL);
}

// GET SINGLE USER
// export function getSupplierById(id) {
//     return axios.get(`${URL}${id}`);
// }

// GET USER WITH CONDITION
// export function findSuppliers(queryParams) {
//     return axios.post(`${URL}find`, queryParams);
// }

// UPDATE SINGEL USER
// export function updateSupplier(supplier) {
//     return axios.put(`${URL}`, supplier);
// }
