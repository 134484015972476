import { createSlice } from "@reduxjs/toolkit";

const initialShippingCompanysState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  shippingCompanyForEdit: undefined,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const shippingCompanySlice = createSlice({
  name: "shippingCompanys",
  initialState: initialShippingCompanysState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getShippingCompanyById
    shippingCompanyFetched: (state, action) => {
      state.actionsLoading = false;
      state.shippingCompanyForEdit = action.payload.shippingCompanyForEdit;
      state.error = null;
    },
    // findShippingCompanys
    shippingCompanysFetched: (state, action) => {
      const { total_items, items } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = items;
      state.totalCount = total_items;
    },
    // getAllShippingCompany
    shippingCompanyAllFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.all = action.payload.all;
    },
    // createShippingCompany
    shippingCompanyCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.shippingCompany);
    },
    // updateShippingCompany
    shippingCompanyUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.shippingCompany.id) {
          return action.payload.shippingCompany;
        }
        return entity;
      });
    },
  },
});
