/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
/* eslint-disable no-unused-vars*/
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderToolbar,
  ModalProgressBar,
} from '../../../../../_metronic/_partials/controls';
import { ToastContainer, toast } from 'react-toastify';
import { toastOption } from '../../../../../_metronic/_helpers';
import { MemberEditProfile } from './MemberEditProfile';
import { initMember } from '../MemberUIHelpers';
import { MemberAddressEditDialog } from './member-address-edit-dialog/MemberAddressEditDialog';

import * as mlActions from '../../../MasterData/_redux/member-level/memberLevelActions';
import * as actions from '../../_redux/member/memberActions';
import { MemberEditAddress } from './MemberEditAddress';
import { MemberAddressDisableDialog } from './member-address-delete-dialog/MemberAddressDeleteDialog';
import { createMember, updateMember } from '../../_redux/member/memberCrud';
import { useMutation } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';

export const initialFilter = {
  name: '',
  code: '',
  level_order: '',
  sortOrder: 'asc', // asc||desc
  sortField: 'created_at',
};

export const MemberEdit = ({
  history,
  match: {
    params: { id },
  },
}) => {
  const dispatch = useDispatch();

  const { entityLoading, memberForEdit, memberLevels, error } = useSelector(
    (state) => ({
      entityLoading: state.members.actionsLoading,
      memberForEdit: state.members.memberForEdit,
      memberLevels: state.memberLevels.entities,
      error: state.members.error,
    }),
    shallowEqual
  );

  const [tab, setTab] = useState('profile-tab');
  const [showMemberAddress, setShowMemberAddress] = useState(false);
  const [memberAddress, setMemberAddress] = useState(undefined);
  const [showDeleteMemberAddress, setShowDeleteMemberAddress] = useState(false);
  const [memberAddressUUID, setMemberAddressUUID] = useState(undefined);
  const [fetchLoading, setFetchLoading] = useState(false);

  useEffect(() => {
    dispatch(mlActions.fetchMemberLevels(initialFilter));
    dispatch(actions.fetchMember(id));
  }, [dispatch, id]);

  function saveProfile(values) {
    if (fetchLoading || entityLoading) {
      return;
    }

    setFetchLoading(true);

    // try {
    if (memberForEdit && memberForEdit.uuid) {
      // const res = await updateMember(values);

      // toast.success('Updated Successfully', toastOption);

      // return history.go(0);
      // }
      if (values.change_pass) delete values.password;
      delete values.change_pass;

      // console.log(`memberForEdit`, memberForEdit);
      // console.log(`values`, values.uuid);
      return updateMember(values)
        .then((response) => {
          toast.success('Update Successful', toastOption);
          history.push(`/member/${values.uuid}/edit`);
        })
        .catch((error) => {
          toast.error('Update Failed', toastOption);
          history.push(`/member/${values.uuid}/edit`);
          // dispatch(actions.catchError(error));

          // error.clientMessage = "Can't find members";
          // console.log(error);
          // dispatch(actions.catchError({ error, callType: callTypes.list }));
        });

      // return dispatch(actions.updateMember(values)).then(() => {
      //   console.log(`values`, values);

      //   toast.success('Update Successful', toastOption);
      //   backToList();
      // });
    }

    return createMember(values)
      .then((response) => {
        console.log(`response`, response);
        toast.success('Create Successful', toastOption);
        history.push(`/member/${response.uuid}/edit`);
      })
      .catch((error) => {
        toast.error('Create Failed', toastOption);
        history.push(`/member/new`);

        // error.clientMessage = "Can't find members";
        // console.log(error);
        // dispatch(actions.catchError({ error, callType: callTypes.list }));
      });

    // dispatch(actions.createMember(values)).then(() => {
    //   toast.success('Create member success', toastOption);
    //   console.log(`values`, values);
    //   // history.push(`/member/`);
    //   // backToList();
    // });
  }

  const btnRef = useRef();
  const saveMemberClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const loading = entityLoading || fetchLoading;

  return (
    <>
      <ToastContainer />
      {showMemberAddress && (
        <MemberAddressEditDialog
          show={showMemberAddress}
          onHide={() => setShowMemberAddress(false)}
          memberAddress={memberAddress}
          id={id}
        />
      )}
      {showDeleteMemberAddress && (
        <MemberAddressDisableDialog
          show={showDeleteMemberAddress}
          onHide={() => setShowDeleteMemberAddress(false)}
          memberAddress={memberAddressUUID}
        />
      )}
      <Card>
        {loading && <ModalProgressBar />}
        <CardHeader
          title={`${
            loading ? '' : memberForEdit ? 'Profile ' + memberForEdit.name : 'Member Baru'
          }`}
        >
          <CardHeaderToolbar>
            <button type="button" className="btn btn-light" onClick={() => history.push('/member')}>
              <i className="fa fa-arrow-left"></i>
              Kembali
            </button>
            {`  `}
            {tab === 'address-tab' && (
              <button
                type="button"
                className="btn btn-primary ml-2"
                onClick={() => {
                  setShowMemberAddress(true);
                  setMemberAddress(undefined);
                }}
              >
                Add New Address
              </button>
            )}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <ul className="nav nav-tabs nav-tabs-line " role="tablist">
            <li className="nav-item" onClick={() => setTab('profile-tab')}>
              <a
                className={`nav-link ${tab === 'profile-tab' && 'active'}`}
                data-toggle="tab"
                role="tab"
                aria-selected={(tab === 'profile-tab').toString()}
              >
                Profile
              </a>
            </li>
            <li className="nav-item" onClick={() => setTab('address-tab')}>
              <a
                className={`nav-link ${tab === 'address-tab' && 'active'}`}
                data-toggle="tab"
                role="tab"
                aria-selected={(tab === 'address-tab').toString()}
              >
                Address
              </a>
            </li>
          </ul>

          <div className="mt-5">
            {tab === 'profile-tab' && (
              <MemberEditProfile
                member={memberForEdit || initMember}
                loading={loading}
                btnRef={btnRef}
                saveProfile={saveProfile}
                memberLevelList={memberLevels || []}
              />
            )}
            {tab === 'address-tab' && id && (
              <MemberEditAddress
                entities={memberForEdit.member_address || ''}
                setMemberAddress={setMemberAddress}
                setShowMemberAddress={setShowMemberAddress}
                setShowDeleteMemberAddress={setShowDeleteMemberAddress}
                setMemberAddressUUID={setMemberAddressUUID}
              />
            )}
          </div>
        </CardBody>
        {tab === 'profile-tab' && (
          <CardFooter>
            <button
              type="submit"
              className="btn btn-primary ml-2 float-right d-flex justify-content-center align-items-center"
              style={{ width: '100px', height: '36px' }}
              onClick={saveMemberClick}
            >
              {loading ? <CircularProgress color="inherit" size={14} /> : <span>Simpan</span>}
            </button>
          </CardFooter>
        )}
      </Card>
    </>
  );
};
