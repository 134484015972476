import React, { useState, useEffect } from 'react';
import { Formik, Field } from 'formik';
import ReactSelect from 'react-select';
import { Modal } from 'react-bootstrap';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import { DatePicker } from 'antd';
import * as Yup from 'yup';
import { Input } from '../../../../../_metronic/_partials/controls';
import { CircularProgress } from '@material-ui/core';
import { useLazyQuery } from '@apollo/client';
import Select from 'react-select';

const CreateSupplierSchema = Yup.object().shape({
  email: Yup.string().email().required('Email Supplier wajib diisi.'),
  hp: Yup.string().required('Hp Supplier wajib diisi.'),
  name: Yup.string().required('Nama Supplier berakhir wajib diisi.'),
  password: Yup.string().required('Password  wajib diisi.'),
});

export const CreateSupplierModal = ({ show, onHide, isLoading, submitCreate, schema }) => {
  return (
    <Modal size="md" show={show} onHide={onHide} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Buat Supplier Baru</Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize={true}
        initialValues={{
          email: '',
          hp: '',
          name: '',
          password: '',
        }}
        validationSchema={CreateSupplierSchema}
        onSubmit={(values) => {
          submitCreate(values);
        }}
      >
        {({ handleSubmit, values, setFieldValue, errors }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              <div className="form-group">
                <div className="col">
                  <Field
                    name="email"
                    component={Input}
                    placeholder="Gayalo"
                    label="Email Supplier"
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="col">
                  <Field name="hp" component={Input} placeholder="Gayalo" label="Hp Supplier" />
                </div>
              </div>
              <div className="form-group">
                <div className="col">
                  <Field name="name" component={Input} placeholder="Gayalo" label="Nama Supplier" />
                </div>
              </div>
              <div className="form-group">
                <div className="col">
                  <Field
                    name="password"
                    type="password"
                    component={Input}
                    placeholder="Gayalo"
                    label="Password"
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button type="button" onClick={onHide} className="btn btn-light btn-elevate">
                Cancel
              </button>
              <button
                type="button"
                onClick={handleSubmit}
                className="btn btn-primary btn-elevate d-flex align-items-center justify-content-center"
                style={{ width: '75px' }}
              >
                {isLoading ? <CircularProgress color="white" size={14} /> : <span>Save</span>}
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
};
