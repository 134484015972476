// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/products/productActions";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../_metronic/_helpers";
import * as uiHelpers from "../ProductUIHelpers";
import * as columnFormatters from "../product-table/column-formatters";
import { Pagination } from "../../../../../_metronic/_partials/controls";
import { useProductUIContext } from "../ProductUIContext";
import { ProductDisableDialog } from "../product-disable-dialog/ProductDisableDialog";
import { RoleChecker } from "../../../../RoleChecker";
import { ImagesModal } from "../../../../components/ImagesModal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ProductRatingDialog } from "./ProductRatingDialog";
import { LoadingAnimation } from "../loading-animation/LoadingAnimation";

export function RatingTable({ uuid, disableReview, restoreReview }) {
  // Product UI Context
  const productUIContext = useProductUIContext();
  const productUIProps = useMemo(() => {
    return {
      queryParams: productUIContext.queryParams,
      setQueryParams: productUIContext.setQueryParams,
      openEditProductPage: productUIContext.openEditProductPage,
      openDeleteProductDialog: productUIContext.openDeleteProductDialog,
    };
  }, [productUIContext]);

  const { actionsLoading } = useSelector(
    (state) => ({
      actionsLoading: state.product.actionsLoading,
    }),
    shallowEqual
  );

  // * Image Modal
  const [showModalImage, setShowModalImage] = useState(false);
  const [currentImages, setCurrentImages] = useState(null);
  useEffect(() => {
    setShowModalImage(Boolean(currentImages));
  }, [currentImages]);

  // * Getting curret state of product list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.product }),
    shallowEqual
  );
  const { totalCountRating, entitiesRating, listLoading } = currentState;

  // * Product Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    Object.assign(productUIProps.queryParams, { uuid: uuid });
    dispatch(actions.fetchProductsRating(productUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productUIProps.queryParams, dispatch]);

  // * Modal Delete
  const [isShowModal, setIsShowModal] = useState(false);
  const [rowId, setRowId] = useState();

  // * Modal Rating
  const [modalRating, setModalRating] = useState(false);

  // * Table columns
  const columns = [
    {
      dataField: "member.name",
      text: "Member Name",
      // formatter: (cell, row, rowIndex) => {
      //   const member = row.member.name;
      //   const rating = row.rating;
      //   return (
      //     <h5>
      //       {member}, {rating}
      //       <i style={{ color: "#FFDF00" }} class="fas fa-star"></i>
      //     </h5>
      //   );
      // },
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "rating",
      text: "Rating",
      formatter: (cell, row, rowIndex) => {
        const rating = row.rating;
        return (
          <h5>
            {rating}
            <i style={{ color: "#FFDF00" }} class="fas fa-star"></i>
          </h5>
        );
      },
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "review",
      text: "Review",
      formatter: (cell, row, rowIndex) => {
        const review = row.review;
        return (
          <span>
            {review.length > 100 ? `${review.slice(0, 100)}...` : review}
          </span>
        );
      },
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "review",
      text: "Status",
      formatter: (cell, row, rowIndex) => {
        const isDisable = row.deleted_at;
        return (
          <>
            {isDisable === null ? (
              <span className="label label-lg label-light-success label-inline mr-4">
                Aktif
              </span>
            ) : (
              <span className="label label-lg label-light-danger label-inline mr-4">
                NonAktif
              </span>
            )}
          </>
        );
      },
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: (cell, row, rowIndex) => {
        return (
          <>
            <a
              title="Enable"
              className="btn btn-icon btn-light btn-hover-success mx-3 btn-sm"
              onClick={() => {
                restoreReview(row.uuid);
              }}
            >
              <i style={{ color: "#1BC5BD" }} class="fas fa-check-circle"></i>
            </a>
            <></>
            <a
              title="Disable"
              className="btn btn-icon btn-light btn-hover-danger mx-3 btn-sm"
              onClick={() => {
                disableReview(row.uuid);
              }}
            >
              <i style={{ color: "red" }} class="fas fa-ban"></i>
            </a>
          </>
        );
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  //   * Expand Row
  const expandRow = {
    renderer: (row) => (
      <div>
        <>
          <span className="font-weight-bolder">
            {row.member.name}
            {row.deleted_at === null ? (
              <span className="label label-md label-light-success label-inline ml-4">
                Aktif
              </span>
            ) : (
              <span className="label label-md label-light-danger label-inline ml-4">
                NonAktif
              </span>
            )}
            <br />
          </span>
          <span className="text-muted">
            {`Rating ${row.rating}`}
            <i
              style={{ color: "#FFDF00", fontSize: "12px" }}
              class="fas fa-star"
            ></i>
          </span>
        </>
        <p className="mt-2">{row.review}</p>
      </div>
    ),
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return (
          <i
            style={{ cursor: "pointer" }}
            class="fas fa-caret-down fa-rotate-180"
          ></i>
        );
      }
      return <i style={{ cursor: "pointer" }} class="fas fa-caret-down"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <i
            style={{ cursor: "pointer" }}
            class="fas fa-caret-down fa-rotate-180"
          ></i>
        );
      }
      return <i style={{ cursor: "pointer" }} class="fas fa-caret-down"></i>;
    },
    showExpandColumn: true,
  };

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCountRating,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: productUIProps.queryParams.page_size,
    page: productUIProps.queryParams.page_number,
  };

  return (
    <>
      <LoadingAnimation open={actionsLoading} />
      <ProductRatingDialog
        show={modalRating}
        onHide={() => setModalRating(false)}
        id={rowId}
      />
      <ImagesModal
        show={showModalImage}
        images={currentImages}
        onHide={() => setCurrentImages(null)}
      />
      <ProductDisableDialog
        show={isShowModal}
        onHide={() => setIsShowModal(false)}
        id={rowId}
      />
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="uuid"
                data={entitiesRating === undefined ? [] : entitiesRating}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  productUIProps.setQueryParams
                )}
                expandRow={expandRow}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entitiesRating} />
                <NoRecordsFoundMessage entities={entitiesRating} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
