import React, { useEffect, useMemo, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as actions from "../_redux/purchase/purchaseActions";
import { initialFilter } from "../pages/PurchaseUIHelpers";

const DELETE_PURCHASE = gql`
  mutation Mutation($uuid: ID!) {
    deletePurchase(uuid: $uuid) {
      message
    }
  }
`;

const PurchaseDeleteDialog = ({ id, show, onHide }) => {
  // Product Redux state
  const dispatch = useDispatch();
  // const { actionsLoading, productForEdit } = useSelector(
  //   (state) => ({
  //     actionsLoading: state.purchases.actionsLoading,
  //     productForEdit: state.purchases.productForEdit,
  //   }),
  //   shallowEqual
  // );

  const [fetchDeletePurchase, { loading, error, data }] = useMutation(DELETE_PURCHASE, {
    variables: {
      uuid: id,
    },
    onCompleted: ({ deletePurchase: { message } }) => {
      onHide();
      toast.success(message);
      dispatch(actions.fetchPurchases(initialFilter));
    },
    onError: (error) => {
      onHide();
      toast.error(error.message);
      dispatch(actions.fetchPurchases(initialFilter));
    },
  });

  const deletePurchase = () => {
    fetchDeletePurchase();
  };

  return (
    <Modal size="md" show={show} onHide={onHide} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Delete Purchase</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Anda yakin ingin menghapus pembelian ini?</span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button type="button" onClick={onHide} className="btn btn-light btn-elevate mr-4">
            Cancel
          </button>
          <button type="button" onClick={deletePurchase} className="btn btn-primary btn-elevate">
            Ya
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PurchaseDeleteDialog;
