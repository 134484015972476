import React, { useState, useEffect } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { Field, ErrorMessage } from 'formik';
import {
  Input,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderToolbar,
} from '../../../../../../_metronic/_partials/controls';

import {
  Form as Forms,
  OverlayTrigger,
  Popover,
  PopoverContent,
  PopoverTitle,
  Tooltip,
} from 'react-bootstrap';
import { DeleteSkuModal } from '../DeleteSkuModal';
import { setImageResolution } from '../../../../../config/Helper';
import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../../../_metronic/_helpers';
import SVG from 'react-inlinesvg';
import { useSkuImage } from './useSkuImage';
import { LoadingAnimation } from '../../loading-animation/LoadingAnimation';
import { toast } from 'react-toastify';

const SKU_SUGGESTION = gql`
  query Q($sku: String, $sort_field: String, $sort_order: String) {
    __typename
    findProductSku(
      findProductSkuInput: {
        page_number: 1
        page_size: 10
        product_name_or_sku: $sku
        sort_field: $sort_field
        sort_order: $sort_order
      }
    ) {
      items {
        sku
      }
    }
  }
`;

export const SkuItem = ({
  index,
  productSku,
  onUpdate,
  onDelete,
  isSelected,
  setIsSelected,
  printLabel,
  setOpenPrintLabelModal,
  setPrintData,
  productData = {},
}) => {
  const { name } = productData
  const { uuid } = productSku;
  const [sku, setSku] = useState(productSku.sku);
  const [price, setPrice] = useState(productSku.price);
  const [priceBefore, setPriceBefore] = useState(productSku.priceBefore);
  const [variant_uuid_1, set_variant_uuid_1] = useState(productSku.variant_uuid_1);
  const [variant_uuid_2, set_variant_uuid_2] = useState(productSku.variant_uuid_2);
  const [variant_value_1, set_variant_value_1] = useState(productSku.variant_value_1);
  const [variant_value_2, set_variant_value_2] = useState(productSku.variant_value_2);
  const [currentTimeout, setCurrentTimeout] = useState(null);
  const [skuSuggestions, setSkuSuggestions] = useState([]);
  const [image, loading, updateSkuImage, deleteSkuImage] = useSkuImage(productSku);

  const [fetchSkuSuggestions] = useLazyQuery(SKU_SUGGESTION, {
    onCompleted: (data) => {
      const { findProductSku } = data;
      setSkuSuggestions(findProductSku.items);
    },
    onError: (err) => {
      toast.error(err);
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    return () => {
      const item = {
        uuid,
        sku,
        price,
        priceBefore,
        variant_uuid_1,
        variant_uuid_2,
        variant_value_1,
        variant_value_2,
      };

      onDelete(item);
    };
  }, []);

  useEffect(() => {
    setSku(productSku.sku);
    setPrice(productSku.price);
    setPriceBefore(productSku.priceBefore);
    set_variant_uuid_1(productSku.variant_uuid_1);
    set_variant_uuid_2(productSku.variant_uuid_2);
    set_variant_value_1(productSku.variant_value_1);
    set_variant_value_2(productSku.variant_value_2);

    clearTimeout(currentTimeout);
    setCurrentTimeout(null);
  }, [productSku]);

  const searchSkuSuggestions = (input) => {
    clearTimeout();
    setTimeout(() => {
      fetchSkuSuggestions({
        variables: {
          sku: input,
          sort_field: 'psku.sku',
          sort_order: 'DESC',
          page_size: 20,
        },
      });
    }, 250);
  };

  function handleUpdate(fieldName, fieldValue, setter) {
    if (setter instanceof Function) {
      setter(fieldValue);
    }

    onUpdate({
      uuid,
      sku,
      price,
      priceBefore,
      variant_uuid_1,
      variant_uuid_2,
      variant_value_1,
      variant_value_2,
      [fieldName]: fieldValue,
    });
  }

  return (
    <tr>
      <th>{index + 1}</th>
      <th>
        <LoadingAnimation open={loading} />
        <input
          id={`checkbox-${index}`}
          type="checkbox"
          checked={isSelected}
          onChange={(e) => setIsSelected(e.target.checked)}
        />
      </th>
      <td>{variant_value_1 ? variant_value_1 : ''}</td>
      <td>{variant_value_2 ? variant_value_2 : ''}</td>
      <td>
        <Field
          name={`price_before-${index}`}
          id={`price_before-${index}`}
          component={Input}
          type="number"
          placeholder="Harga"
          value={priceBefore}
          onChange={(e) => {
            handleUpdate('priceBefore', +e.target.value, setPriceBefore);
          }}
        />
      </td>
      <td>
        <Field
          name={`price-${index}`}
          id={`price-${uuid}`}
          component={Input}
          type="number"
          placeholder="Harga Diskon"
          value={price}
          onChange={(e) => {
            handleUpdate('price', +e.target.value, setPrice);
          }}
        />
      </td>
      <td>
        <Field
          name={`sku-${index}`}
          id={`sku-${index}`}
          component={Input}
          type="text"
          placeholder="Sku"
          value={sku}
          list="suggestion"
          autocomplete="false"
          onChange={(e) => {
            handleUpdate('sku', e.target.value, setSku);
            searchSkuSuggestions(e.target.value);
          }}
        />
        <datalist id="suggestion">
          {skuSuggestions.map((item) => (
            <option value={item.sku}>{item.sku}</option>
          ))}
        </datalist>
      </td>
      <td>
        {productSku.status !== 'create' ? (
          image ? (
            <OverlayTrigger
              trigger={'click'}
              overlay={
                <Popover id="popover-positioned-right">
                  <PopoverTitle as="h3">Actions</PopoverTitle>
                  <PopoverContent>
                    <label
                      title="Edit image"
                      htmlFor={`image-sku-${index}`}
                      className="btn btn-icon btn-light btn-hover-primary btn-sm"
                    >
                      <span className="svg-icon svg-icon-md svg-icon-primary">
                        <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')} />
                      </span>
                      <Forms.Group>
                        <Forms.File
                          id={`image-sku-${index}`}
                          accept=".png, .jpg, .jpeg"
                          custom
                          style={{ width: '0px' }}
                          onChange={(e) => {
                            if (
                              e.currentTarget.files instanceof FileList &&
                              e.currentTarget.files[0]
                            ) {
                              updateSkuImage({ sku_uuid: uuid, image: e.currentTarget.files[0] });
                            }
                          }}
                        />
                      </Forms.Group>
                    </label>
                    <label
                      title="Delete image"
                      className="btn btn-icon btn-light btn-hover-danger btn-sm"
                      onClick={() => {
                        deleteSkuImage(uuid);
                      }}
                    >
                      <span className="svg-icon svg-icon-md svg-icon-danger">
                        <SVG src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')} />
                      </span>
                    </label>
                  </PopoverContent>
                </Popover>
              }
            >
              <div
                style={{
                  padding: '0px',
                  height: '75px',
                  width: '100px',
                }}
              >
                <img
                  src={image ? setImageResolution(image.url, '512') : ''}
                  height="100%"
                  width="100%"
                  style={{
                    objectFit: 'contain',
                    cursor: 'pointer',
                  }}
                  onClick={(e) => {}}
                />
              </div>
            </OverlayTrigger>
          ) : (
            <>
              <label
                htmlFor={`image-sku-${index}`}
                className="fas fa-upload btn btn-primary"
                style={{
                  color: '#fff',
                }}
              >
                <i className="fas fa-upload mr-2" style={{ marginLeft: '-10px' }}></i>
                Tambah Foto
              </label>
              <Forms.Group style={{ display: 'none' }}>
                <Forms.File
                  name="image-sku"
                  id={`image-sku-${index}`}
                  custom
                  accept=".png, .jpg, .jpeg"
                  onChange={(e) => {
                    if (e.currentTarget.files instanceof FileList && e.currentTarget.files[0]) {
                      updateSkuImage({ sku_uuid: uuid, image: e.currentTarget.files[0] });
                    }
                  }}
                />

                <ErrorMessage
                  name="image-sku"
                  render={(msg) => <div style={{ color: 'red' }}>{msg}</div>}
                />
              </Forms.Group>
            </>
          )
        ) : null}
      </td>
      {productSku.status !== 'create' && (
        <>
          <td>
            <OverlayTrigger overlay={<Tooltip>Detail SKU</Tooltip>}>
              <Link to={`/product-sku/${uuid}/${name}/${productSku.sku}`}>
                <a title="View Detail" className="btn btn-icon btn-light btn-hover-info btn-sm">
                  <i class="fas fa-eye"></i>
                </a>
              </Link>
            </OverlayTrigger>
          </td>
          <td>
            <OverlayTrigger overlay={<Tooltip>Print Label</Tooltip>}>
              <button
                title="Print"
                className="btn btn-icon btn-light btn-hover-dark btn-sm"
                onClick={() => {
                  setOpenPrintLabelModal();
                  setPrintData(productSku);
                }}
              >
                <span className="svg-icon svg-icon-md svg-icon-dark">
                  <SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Barcode.svg')} />
                </span>
              </button>
            </OverlayTrigger>
          </td>
        </>
      )}
    </tr>
  );
};
