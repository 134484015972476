import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '../SvgIcon'
import { defaultFill } from '../constant'

const CheckBoxBlankOutline = props => (
  <SvgIcon {...props}>
    <path fill={props.fill} className="custom-fill" d="M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z" />
  </SvgIcon>
)

CheckBoxBlankOutline.propTypes = {
  fill: PropTypes.string,
}

CheckBoxBlankOutline.defaultProps = {
  fill: defaultFill,
}

export default CheckBoxBlankOutline
