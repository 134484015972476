import { useMutation } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { toastOption } from '../../../../../_metronic/_helpers';
import { ActionButton } from '../../../../../_metronic/_partials/controls/ActionButton';
import * as actions from '../../_redux/story/storyActions';
import { DELETE_STORY } from '../../_redux/story/storyCrud';
import { useStoryUIContext } from '../StoryUIContext';

export function StoryDisableDialog({ id, show, onHide }) {
  // Story Redux state
  const dispatch = useDispatch();
  const { actionsLoading, storyForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.story.actionsLoading,
    }),
    shallowEqual
  );

  const storyUIContext = useStoryUIContext();
  const storyUIProps = useMemo(() => {
    return {
      ids: storyUIContext.ids,
      setIds: storyUIContext.setIds,
      queryParams: storyUIContext.queryParams,
      setQueryParams: storyUIContext.setQueryParams,
      openEditStory: storyUIContext.openEditStory,
    };
  }, [storyUIContext]);

  const [title, setTitle] = useState('');
  // Title couting
  useEffect(() => {
    setTitle(id);
    let _title = '';
    if (storyForEdit && id) {
      _title = `Delete story '${storyForEdit.name}'`;
    }

    setTitle(_title);
    // eslint-disable-next-line
  }, [storyForEdit, actionsLoading]);

  const [fetchDeleteStory, { loading }] = useMutation(DELETE_STORY, {
    onCompleted: () => {
      toast.success('Berhasil hapus story!', toastOption);
      dispatch(actions.fetchStories(storyUIProps.queryParams));
      onHide();
    },
    onError: (err) => {
      console.log(err);
      toast.error('Gagal hapus story!', toastOption);
      onHide();
    },
  });

  function deleteStory() {
    fetchDeleteStory({
      variables: { uuid: id },
    });
  }

  return (
    <Modal size="md" show={show} onHide={onHide} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Anda yakin akan menghapus story ini?</span>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex align-items-center">
          <button type="button" onClick={onHide} className="btn btn-light btn-elevate mr-4 mr-3">
            Cancel
          </button>
          <ActionButton onClick={deleteStory} loading={loading}>
            <span>Yakin</span>
          </ActionButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
