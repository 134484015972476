import React, { createContext, useContext, useState, useCallback } from 'react';
import { isEqual, isFunction } from 'lodash';
import { initialFilter } from './VoucherUIHelpers';
import { shallowEqual, useSelector } from 'react-redux';

const VoucherUIContext = createContext();

export function useVoucherUIContext() {
  return useContext(VoucherUIContext);
}

export const VoucherUIConsumer = VoucherUIContext.Consumer;

export function VoucherUIProvider({ voucherUIEvents, children }) {
  const { filters } = useSelector((state) => state.voucher, shallowEqual);
  const [queryParams, setQueryParamsBase] = useState(filters);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    newVoucherButtonClick: voucherUIEvents.newVoucherButtonClick,
    openEditVoucherDialog: voucherUIEvents.openEditVoucherDialog,
    openDeleteVoucherDialog: voucherUIEvents.openDeleteVoucherDialog,
    openFetchVoucherDialog: voucherUIEvents.openFetchVoucherDialog,
    openDisableVoucherDialog: voucherUIEvents.openDisableVoucherDialog,
    openUpdateVoucherStatusDialog: voucherUIEvents.openUpdateVoucherStatusDialog,
  };

  return <VoucherUIContext.Provider value={value}>{children}</VoucherUIContext.Provider>;
}
