// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/products/productActions";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../_metronic/_helpers";
import * as uiHelpers from "../ProductUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../_metronic/_partials/controls";
import { useProductUIContext } from "../ProductUIContext";
import { ProductDisableDialog } from "../product-disable-dialog/ProductDisableDialog";
import { RoleChecker } from "../../../../RoleChecker";
import { ImagesModal } from "../../../../components/ImagesModal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ProductRatingDialog } from "../product-rating/ProductRatingDialog";
import { LoadingAnimation } from "../../../../components/loading-animation/LoadingAnimation";
import { setImageResolution } from "../../../../config/Helper";
import { Link } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { GENERATE_PRODUCT_DLINK } from "../../_redux/products/productCrud";
import { toast } from "react-toastify";

export function ProductTable() {
  const allowDelete = RoleChecker("delete", "product");
  const allowUpdate = RoleChecker("update", "product");
  // Product UI Context
  const productUIContext = useProductUIContext();
  const productUIProps = useMemo(() => {
    return {
      queryParams: productUIContext.queryParams,
      setQueryParams: productUIContext.setQueryParams,
      openEditProductPage: productUIContext.openEditProductPage,
      openDeleteProductDialog: productUIContext.openDeleteProductDialog,
    };
  }, [productUIContext]);

  // * Image Modal
  const [showModalImage, setShowModalImage] = useState(false);
  const [currentImages, setCurrentImages] = useState(null);
  useEffect(() => {
    setShowModalImage(Boolean(currentImages));
  }, [currentImages]);

  // * Getting curret state of product list from store (Redux)
  const { currentState } = useSelector((state) => ({ currentState: state.product }), shallowEqual);
  const { totalCount, entities, listLoading } = currentState;
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (entities instanceof Array) {
      setProducts([...entities]);
    }
  }, [entities]);

  // * Product Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.fetchProducts(productUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productUIProps.queryParams, dispatch]);

  // * Modal Delete
  const [isShowModal, setIsShowModal] = useState(false);
  const [rowId, setRowId] = useState();

  // * Modal Rating
  const [modalRating, setModalRating] = useState(false);

  // * Generate Product Dynamic Link Query
  const [generateProductDlink] = useLazyQuery(GENERATE_PRODUCT_DLINK, {
    onCompleted: (data) => {
      dispatch(actions.fetchProducts(productUIProps.queryParams));
    },
    onError: (error) => {
      toast.error(error);
    },
    variables: { product_uuid: "" },
  });

  async function fetchProductDlink(product, index) {
    dispatch(actions.startAction());

    generateProductDlink({ variables: { product_uuid: product.uuid } });
  }

  // * Table columns
  const columns = [
    {
      dataField: "product_image",
      text: "Gambar",
      // sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row, rowIndex) => {
        const imageArray = row.product_image.filter((item) => {
          if (item.sort_number == 1) {
            return item.images.url;
          }
        });
        const image = imageArray[0] && imageArray[0].images.url;
        let strImage;
        if (image) strImage = setImageResolution(image, "512");

        const filterImage = row.product_image.map((item) => {
          return { url: setImageResolution(item.images.url, "1080") };
        });

        return (
          <>
            {image ? (
              <OverlayTrigger overlay={<Tooltip id="tooltip-top">Click For Detail Image!</Tooltip>}>
                <div style={{ height: "85px", width: "145px" }}>
                  <img
                    style={{ objectFit: "contain", cursor: "pointer" }}
                    src={strImage}
                    alt="Product Image"
                    width="100%"
                    height="100%"
                    className="shadow rounded"
                    onClick={() => {
                      setCurrentImages(filterImage);
                    }}
                    object
                  ></img>
                </div>
              </OverlayTrigger>
            ) : null}
          </>
        );
      },
      headerSortingClasses,
    },
    {
      dataField: "name",
      text: "Nama Produk",
      formatter: (cell, row) => {
        return (
          <div>
            <p>{cell}</p>
            <div>
              {row.category.map((item, index) => {
                return (
                  <span className={`badge badge-secondary mr-2 mb-1`}>
                    {item.categoryjoin.name}
                  </span>
                );
              })}
            </div>
          </div>
        );
      },
      // sort: true,
    },
    {
      dataField: "product_sku",
      text: "Stok",
      headerStyle: (colum, colIndex) => {
        return { width: "200px" };
      },
      formatter: (cell, row, rowIndex) => {
        const skus = cell.map((sku) => {
          return (
            <Link to={`/product-sku/${sku.uuid}/${row.name}/${sku.sku}`}>
              <li>
                {sku.sku} ({sku.stock})
              </li>
            </Link>
          );
        });
        return skus;
      },
    },
    {
      dataField: "product_variant",
      text: "Variant",
      // sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row, rowIndex) => {
        return row.product_variant.map((variant, index) => {
          const data = index === 1 ? ` & ${variant.name}` : `${variant.name}`;
          return data;
        });
      },
      headerSortingClasses,
    },
    {
      dataField: "publish_status",
      text: "Status",
      // sort: true,
    },
    {
      dataField: "brand.name",
      text: "Brand",
      // sort: true,
    },
    {
      dataField: "dynamic_link",
      text: "Link",
      formatter: (cell, row, index) => {
        if (!cell) {
          return (
            <button
              className="btn btn-primary"
              style={{ width: "120px" }}
              onClick={() => fetchProductDlink(row, index)}
            >
              Generate Link
            </button>
          );
        }

        return (
          <OverlayTrigger overlay={<Tooltip>Klik untuk copy link!</Tooltip>}>
            <span
              className="text-primary"
              href={cell}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigator.clipboard.writeText(cell);
                toast.success("Berhasil copy link");
              }}
            >
              {cell}
            </span>
          </OverlayTrigger>
        );
      },
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsFormatter,
      formatExtraData: {
        openEditProductPage: productUIProps.openEditProductPage,
        openDeleteProductDialog: productUIProps.openDeleteProductDialog,
        setIsShowModal,
        id: setRowId,
        allowDelete,
        allowUpdate,
        setModalRating,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: productUIProps.queryParams.page_size,
    page: productUIProps.queryParams.page_number,
  };
  return (
    <>
      <LoadingAnimation open={listLoading} />
      {rowId && (
        <ProductRatingDialog show={modalRating} onHide={() => setModalRating(false)} id={rowId} />
      )}
      <ImagesModal
        show={showModalImage}
        images={currentImages}
        onHide={() => setCurrentImages(null)}
      />
      <ProductDisableDialog show={isShowModal} onHide={() => setIsShowModal(false)} id={rowId} />
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={listLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="uuid"
                data={products === null ? [] : products}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(productUIProps.setQueryParams)}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: productUIProps.ids,
                //   setIds: productUIProps.setIds,
                // })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
