import axios from "axios";
import { API } from "../../../../config/UrlEndPoint";
import { gql, useMutation } from "@apollo/client";
import { MutationGraphql, QueryGraphql } from "../../../../config/ApoloRequest";

export const CUSTOMERS_URL = `${API}orderReport/`;

// CREATE =>  POST: add a new orderReport to the server
// export function createOrderReport(orderReport) {
//   return axios.post(CUSTOMERS_URL, { orderReport });
// }

export async function findOrderReport(queryParams) {
  const query = gql`
    query Query(
      $description: String
      $page_number: Int
      $page_size: Int
      $sort_field: String
      $sort_order: String
      $status: String
      $title: String
      $topic: String
      $type: String
      $member_uuid: String
    ) {
      findOrderReports(
        findOrderReportInput: {
          member_uuid: $member_uuid
          page_number: $page_number
          page_size: $page_size
          sort_field: $sort_field
          sort_order: $sort_order
          description: $description
          status: $status
          title: $title
          topic: $topic
          type: $type
        }
      ) {
        items {
          uuid
          description
          title
          type
          member_uuid
          image_url
          status
          member {
            uuid
            name
          }
        }
        current_page
        total_items
        total_pages
      }
    }
  `;

  const newResponse = await QueryGraphql(query, queryParams);
  return newResponse.data.findOrderReports;
}

export async function getOrderReportById(id) {
  const query = gql`
  {
  getOrderReport(uuid: "${id}") {
    uuid
    brand_uuid
    media_type
    orderReport_text
    orderReport_type
    video_link
    related_products {
      name
      uuid
    }
    related_products_uuid
    images {
      url
      uuid
      filename
    }
    brand {
      name
      uuid
    }
    related_products {
      name
      uuid
    }
  }
}
`;

  const newResponse = await QueryGraphql(query);
  return newResponse.data.getOrderReport;
}

export const CREATE_NOTIFICATION = gql`
  mutation Mutation(
    $description: String!
    $image_url: String
    $member_uuid: String
    $title: String!
    $topic: String
    $type: String!
  ) {
    createOrderReport(
      createOrderReportInput: {
        description: $description
        title: $title
        type: $type
        image_url: $image_url
        member_uuid: $member_uuid
        topic: $topic
      }
    ) {
      title
      description
      status
    }
  }
`;

export const RESEND_NOTIF = gql`
  mutation Mutation($uuid: String!) {
    resendOrderReport(orderReport_uuid: $uuid) {
      title
      status
      type
    }
  }
`;

export async function deleteOrderReport(id) {
  const query = gql`
    mutation Mutation {
      deleteOrderReport(orderReport_uuid: "${id}") {
        message
      }
    }
  `;

  const response = await MutationGraphql(query);
  return response.data.deleteOrderReport;
}

export function getAllOrderReports() {
  return axios.get(CUSTOMERS_URL);
}

// export function getOrderReportById(orderReportId) {
//   return axios.get(`${CUSTOMERS_URL}${orderReportId}`);
// }

// export function findOrderReports(queryParams) {
//   return axios.post(`${CUSTOMERS_URL}find`, queryParams );
// }

export function updateOrderReport(orderReport) {
  return axios.put(`${CUSTOMERS_URL}/`, orderReport);
}

export const FIND_ORDER_REPORT = gql`
  query ($orderReportInput: FindOrderReportInput) {
    findOrderReport(orderReportInput: $orderReportInput) {
      fee {
        total_order_payment
        total_order_payment_amount
        trans_code
      }
      income {
        total_order_payment
        total_order_payment_amount
        trans_code
      }
      total_fee
      total_income
      total_nett
    }
  }
`;
