// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/marketplaceOrderbook/index.html
import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/marketplaceOrder/marketplaceOrderActions";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
  toastOption,
} from "../../../../../_metronic/_helpers";
import * as uiHelpers from "../MarketplaceOrderUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../_metronic/_partials/controls";
import { useMarketplaceOrderUIContext } from "../MarketplaceOrderUIContext";
import { MarketplaceOrderDisableDialog } from "../marketplaceOrder-disable-dialog/MarketplaceOrderDisableDialog";
import { Link } from "react-router-dom";
import { ImagesModal } from "../../../../components/ImagesModal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { RESEND_NOTIF } from "../../_redux/marketplaceOrder/marketplaceOrderCrud";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

export function MarketplaceOrderTable() {
  // marketplaceOrder UI Context
  const marketplaceOrderUIContext = useMarketplaceOrderUIContext();
  const marketplaceOrderUIProps = useMemo(() => {
    return {
      ids: marketplaceOrderUIContext.ids,
      setIds: marketplaceOrderUIContext.setIds,
      queryParams: marketplaceOrderUIContext.queryParams,
      setQueryParams: marketplaceOrderUIContext.setQueryParams,
      openEditMarketplaceOrder:
        marketplaceOrderUIContext.openEditMarketplaceOrder,
      // openDeleteMarketplaceOrderDialog: marketplaceOrderUIContext.openDeleteMarketplaceOrderDialog,
    };
  }, [marketplaceOrderUIContext]);

  const [showModal, setShowModal] = useState(false);
  const [rowId, setRowId] = useState();

  const [showModalImage, setShowModalImage] = useState(false);
  const [currentImages, setCurrentImages] = useState(null);

  useEffect(() => {
    setShowModalImage(Boolean(currentImages));
  }, [currentImages]);

  // Getting curret state of marketplaceOrder list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.marketplaceOrder }),
    shallowEqual
  );

  const { totalCount, entities, listLoading } = currentState;

  // marketplaceOrder Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    marketplaceOrderUIProps.setIds([]);
    // server call by queryParams
    dispatch(
      actions.fetchMarketplaceOrders(marketplaceOrderUIProps.queryParams)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketplaceOrderUIProps.queryParams, dispatch]);
  // Table columns
  const columns = [
    {
      dataField: "invoice_no",
      text: "No Invoice",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "member.name",
      text: "Member",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "branch.city",
      text: "Kota Asal",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: columnFormatters.StatusFormatter,
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditMarketplaceOrder:
          marketplaceOrderUIProps.openEditMarketplaceOrder,
        // openDeleteMarketplaceOrderDialog: marketplaceOrderUIProps.openDeleteMarketplaceOrderDialog,
        showModal: setShowModal,
        id: setRowId,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: marketplaceOrderUIProps.queryParams.page_size,
    page: marketplaceOrderUIProps.queryParams.page_number,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="uuid"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  marketplaceOrderUIProps.setQueryParams
                )}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: marketplaceOrderUIProps.ids,
                //   setIds: marketplaceOrderUIProps.setIds,
                // })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
