import React from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { UploadIcon } from "../../../../config/icons/index";

const DropzoneUploader = ({ onDrop, children, isDisabled }) => {
  const maxSize = 5048000;

  return (
    <>
      <Dropzone
        onDrop={(acceptedFiles) => {
          // console.log(acceptedFiles);
          if (acceptedFiles.length > 0 && acceptedFiles[0].size <= maxSize) {
            onDrop(acceptedFiles);
          }
        }}
        ref={React.createRef()}
        maxSize={maxSize}
        accept="image/jpg, image/jpeg, image/png"
      >
        {({
          getRootProps,
          getInputProps,
          isDragActive,
          isDragReject,
          rejectedFiles,
        }) => {
          return (
            <div
              className="wrapper-flex is-column is-clickable"
              {...getRootProps()}
            >
              <input {...getInputProps()} type="file" />

              {children}

              {!isDragActive && ""}
              {isDragActive && !isDragReject && ""}
              {isDragReject && ""}
              {isDragActive ? (
                <div className="wrapper-flex is-h-center help is-danger mt-8">
                  Letakkan disini untuk upload foto
                </div>
              ) : (
                <div className="wrapper-flex is-h-center help is-danger mt-8">
                  Maksimum ukuran file 5MB
                </div>
              )}
            </div>
          );
        }}
      </Dropzone>
    </>
  );
};

DropzoneUploader.propTypes = {
  onDrop: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  isDisabled: PropTypes.bool,
};

DropzoneUploader.defaultProps = {
  isDisabled: false,
};

export default DropzoneUploader;
