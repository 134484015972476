import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/campaignActions";
import { CREATE_CAMPAIGN, UPDATE_CAMPAIGN } from "../../_redux/campaignCrud";
import { useMutation } from "@apollo/client";
import { CampaignEditDialogHeader } from "./CampaignEditDialogHeader";
import { CampaignEditForm } from "./CampaignditForm";
import { initialValue } from "../CampaignUIHelpers";
import { toastOption } from "../../../../../_metronic/_helpers";
import { toast } from "react-toastify";
import { LoadingAnimation } from "../loading-animation/LoadingAnimation";
import { useCampaignUIContext } from "../CampaignUIContext";

export const CampaignEditDialog = ({ id, show, onHide }) => {
  const campaignUIContext = useCampaignUIContext();
  const campaignUIProps = useMemo(() => {
    return {
      queryParams: campaignUIContext.queryParams,
    };
  });

  // Campaign Redux state
  const dispatch = useDispatch();
  const { actionsLoading, campaignForEdit } = useSelector((state) => state.campaign, shallowEqual);

  const [createCampaign, { loading: createLoading }] = useMutation(CREATE_CAMPAIGN, {
    onCompleted: (data) => {
      toast.success("Successful create Campaign ", toastOption);
      dispatch(actions.findCampaign(campaignUIProps.queryParams));
      onHide();
    },
    onError: (data) => {
      toast.error("Failed create Campaign ", toastOption);
      // onHide();
      console.log(data);
    },
  });

  const [updateCampaign, { loading: updateLoading }] = useMutation(UPDATE_CAMPAIGN, {
    onCompleted: (data) => {
      toast.success("Successful update Campaign ", toastOption);
      dispatch(actions.findCampaign(campaignUIProps.queryParams));
      onHide();
    },
    onError: (data) => {
      toast.error("Failed update Campaign ", toastOption);
      // onHide();
      console.log(data);
    },
  });

  useEffect(() => {
    // server call for getting Campaign by id
    dispatch(actions.getCampaignById(id));
  }, [id, dispatch]);

  // server request for saving campaign
  const saveCampaign = (params) => {
    if (!id) {
      if (!(params.images instanceof Array) || params.images.length === 0) {
        toast.error("Campaign belum punya gambar");

        return;
      }

      createCampaign({
        variables: {
          createCampaignInput: {
            title: params.title,
            name: params.name,
            is_active: params.is_active.value,
            content: params.content,
            products: params.products,
            type: params.type,
          },
          images: params.images.filter((img) => img instanceof File),
        },
      });
    } else {
      // console.log("update", params);
      updateCampaign({
        variables: {
          updateCampaignInput: {
            title: params.title,
            name: params.name,
            is_active: params.is_active.value ?? params.is_active,
            content: params.content,
            products: params.products,
            type: params.type,
            uuid: id,
          },
          images: params.images.filter((img) => img instanceof File),
          deletedImage: params.deletedImage,
        },
      });
    }
  };

  return (
    <>
      <LoadingAnimation open={createLoading || updateLoading} />
      <Modal size="lg" show={show} onHide={onHide} aria-labelledby="example-modal-sizes-title-lg">
        <CampaignEditDialogHeader id={id} />
        <CampaignEditForm
          saveCampaign={saveCampaign}
          actionsLoading={actionsLoading}
          campaign={campaignForEdit || initialValue}
          onHide={onHide}
        />
      </Modal>
    </>
  );
};
