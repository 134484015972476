import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLazyQuery, useQuery } from '@apollo/client';
import AsyncSelect from 'react-select/async';
import { Field, Formik, Form } from 'formik';
import { isEqual } from 'lodash';
import { useOrderUIContext } from '../OrderUIContext';
import * as moment from 'moment';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import { Input } from '../../../../../_metronic/_partials/controls';
import {
  initialFilter,
  IS_ONLINE,
  ORDER_SHORTCUT_STATUS,
  ORDER_STATUS_OPTIONS,
} from '../OrderUIHelpers';
import { SEARCH_BRANCHES } from '../../../MasterData/_redux/branch/branchCrud';
import { SEARCH_MEMBER } from '../../../Member/_redux/member/memberCrud';
import Select from 'react-select';
import { GET_ORDER_STATUS } from '../../_redux/order/orderCrud';
import { DatePicker } from 'antd';
import { findAdmins } from '../../../MasterData/_redux/admin/adminCrud';
import { findShipperServices } from '../../../MasterData/_redux/shipper-service/shipperServiceCrud';
import { findShippingCompanys } from '../../../MasterData/_redux/shipping-company/shippingCompanyCrud';
import { findStores, getStoreById } from '../../../MasterData/_redux/store/storeCrud';
import { getDynamicConfigById } from '../../../MasterData/_redux/dynamic-config/dynamicConfigCrud';
import { CONFIG_HELPER } from '../../../../config/Helper';
import { orderFilter, resetOrderFilter } from '../../_redux/order/orderActions';

const prepareFilter = (queryParams, values) => {
  const {
    branch_uuid,
    status,
    member_label,
    member_uuid,
    invoice_no,
    is_online,
    admin_name,
    admin_uuid,
    total_amount_start,
    total_amount_end,
    total_order_item_price_start,
    total_order_item_price_end,
    shipping_cost,
    shipping_service_uuid,
    shipping_company_code,
    receiver_name,
    receiver_hp,
    marketplace_invoice,
    created_at_start,
    created_at_end,
    trx_date_start,
    trx_date_end,
    store_name,
    store_uuid,
    status_array,
    status_array_label,
    filterIsOpen,
  } = values;

  const newQueryParams = { ...queryParams };
  newQueryParams.status = status ?? null;
  newQueryParams.member_label = member_label ?? null;
  newQueryParams.member_uuid = member_uuid ?? null;
  newQueryParams.invoice_no = invoice_no ?? null;
  newQueryParams.admin_name = admin_name ?? null;
  newQueryParams.admin_uuid = admin_uuid ?? null;
  // ? Disable sementara #673
  // newQueryParams.is_online = is_online ? is_online.value : null;
  // newQueryParams.branch_uuid = branch_uuid ?? null;
  // newQueryParams.total_amount_start = +total_amount_start ?? null;
  // newQueryParams.total_amount_end = +total_amount_end ?? null;
  // newQueryParams.total_order_item_price_start = +total_order_item_price_start ?? null;
  // newQueryParams.total_order_item_price_end = +total_order_item_price_end ?? null;
  // newQueryParams.shipping_cost = +shipping_cost ?? null;
  // newQueryParams.shipping_service_uuid = shipping_service_uuid ?? null;
  // ? Disable sementara #673
  newQueryParams.shipping_company_code = shipping_company_code ?? null;
  newQueryParams.receiver_name = receiver_name ?? null;
  newQueryParams.receiver_hp = receiver_hp ?? null;
  newQueryParams.marketplace_invoice = marketplace_invoice ?? null;
  newQueryParams.created_at_start = created_at_start ?? null;
  newQueryParams.created_at_end = created_at_end ?? null;
  newQueryParams.trx_date_start = trx_date_start ?? null;
  newQueryParams.trx_date_end = trx_date_end ?? null;
  newQueryParams.store_name = store_name ?? null;
  newQueryParams.store_uuid = store_uuid ?? null;
  newQueryParams.status_array = status_array ?? null;
  newQueryParams.status_array_label = status_array_label ?? null;
  newQueryParams.filterIsOpen = filterIsOpen;

  return newQueryParams;
};

export const OrderFilter = ({ listLoading, match, history }) => {
  const { loading, data } = useQuery(GET_ORDER_STATUS);
  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.orders, shallowEqual);

  // Order UI Context
  const orderUIContext = useOrderUIContext();
  const orderUIProps = useMemo(() => {
    return {
      queryParams: orderUIContext.queryParams,
      setQueryParams: orderUIContext.setQueryParams,
    };
  }, [orderUIContext]);

  const { branches, members } = useSelector((state) => state.orders.filter);

  // queryParams, setQueryParams,
  function applyFilter(values) {
    const newQueryParams = prepareFilter(orderUIProps.queryParams, values);

    if (!isEqual(newQueryParams, orderUIProps.queryParams)) {
      newQueryParams.page_number = 1;
    }
    orderUIProps.setQueryParams(newQueryParams);
    dispatch(orderFilter(newQueryParams));
  }

  const [currentTimeout, setCurrentTimeout] = useState(null);
  const [currentCallback, setCurrentCallback] = useState(() => () => null);
  const [onFocused, setOnFocused] = useState(null);
  const [tanggal, setTanggal] = useState({
    start_date: null,
    end_date: null,
  });
  const [branchName, setBranchName] = useState('');
  const [memberName, setMemberName] = useState(filters.member_label);

  const [searchBranches] = useLazyQuery(SEARCH_BRANCHES, {
    variables: {
      name: '',
    },
    onCompleted: ({ findBranches: { items } }) => {
      currentCallback(items.map(({ uuid, name }) => ({ label: name, value: uuid })));
    },
  });

  const [searchMembers] = useLazyQuery(SEARCH_MEMBER, {
    variables: {
      name: '',
    },
    onCompleted: ({ findMember: { items } }) => {
      currentCallback(items.map(({ uuid, name }) => ({ label: name, value: uuid })));
    },
  });

  function loadOptions(queryCaller, inputValue, callback) {
    clearTimeout(currentTimeout);

    if (inputValue.length >= 3) {
      setCurrentTimeout(
        setTimeout(() => {
          queryCaller({ variables: { name: inputValue } });
          setCurrentCallback(() => callback);
        }, 250)
      );
    } else {
      callback([]);
    }
  }

  const [adminOptions, setAdminOptions] = useState([]);
  const [shippingServiceOptions, setShippingServiceOptions] = useState([]);
  const [shippingCompanyOptions, setShippingCompanyOptions] = useState([]);
  const [storeOptions, setStoreOptions] = useState([]);

  useEffect(() => {
    findForFilter();
    if (match.path === '/order-application') {
      getDynamicConfigById(CONFIG_HELPER.gayalo_store)
        .then((res) =>
          getStoreById(res.value_config)
            .then((res) => {
              initialFilter.store_uuid = res.uuid;
              initialFilter.store_name = `${res.market_name} - ${res.platform}`;
            })
            .catch((err) => console.log(err))
        )
        .catch((err) => console.log(err));
    } else {
      initialFilter.store_uuid = null;
      initialFilter.store_name = null;
    }
  }, []);

  const findForFilter = () => {
    findAdmins({
      name: '',
      email: '',
      role: '',
      branch: '',
      sortOrder: 'asc', // asc||desc
      sortField: 'created_at',
      page_number: 1,
      page_size: 100,
    })
      .then((res) => setAdminOptions(res.items))
      .catch((err) => console.log(err));

    findShipperServices({
      company_code: '',
      service: '',
      category: '',
      is_active: null,
      code: '',
      sortOrder: 'DESC', // asc||desc
      sortField: 'created_at',
      page_number: 1,
      page_size: 100,
    })
      .then((res) => setShippingServiceOptions(res.items))
      .catch((err) => console.log(err));

    findShippingCompanys({
      name: '',
      code: '',
      sortOrder: 'DESC', // asc||desc
      sortField: 'created_at',
      page_number: 1,
      page_size: 100,
    })
      .then((res) => setShippingCompanyOptions(res.items))
      .catch((err) => console.log(err));

    findStores({
      is_active: null,
      market_name: '',
      platform: '',
      sort_order: 'DESC', // asc||desc
      sort_field: 'created_at',
      page_number: 1,
      page_size: 400,
    })
      .then((res) => setStoreOptions(res.items))
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Formik
        initialValues={{
          ...filters,
          total_amount_start: filters.total_amount_start ?? '',
          total_amount_end: filters.total_amount_end ?? '',
          total_order_item_price_start: filters.total_order_item_price_start ?? '',
          total_order_item_price_end: filters.total_order_item_price_end ?? '',
        }}
        onSubmit={(values) => {
          setTanggal({ start_date: null, end_date: null });
          applyFilter({ ...values, filterIsOpen: true });
        }}
        onReset={(values) => {
          setTanggal({ start_date: null, end_date: null });
          applyFilter({ ...initialFilter, filterIsOpen: false });
          history.push('/order');
        }}
      >
        {({ values, handleSubmit, handleBlur, handleChange, setFieldValue, resetForm }) => (
          <>
            <Form>
              <div className="form-group row">
                <div className="col">
                  <DatePicker.RangePicker
                    style={{ width: '100%' }}
                    value={[values.created_at_start, values.created_at_end]
                      .filter((date) => date)
                      .map((date) => moment(date))}
                    onChange={([start, end]) => {
                      setFieldValue('created_at_start', start.format('YYYY-MM-DD'));
                      setFieldValue('created_at_end', end.format('YYYY-MM-DD'));
                    }}
                  />
                  <small className="form-text ">
                    Cari <b>Tanggal Dibuat</b>
                  </small>
                </div>
                <div className="col">
                  <DatePicker.RangePicker
                    style={{ width: '100%' }}
                    value={[values.trx_date_start, values.trx_date_end]
                      .filter((date) => date)
                      .map((date) => moment(date))}
                    onChange={([start, end]) => {
                      setFieldValue('trx_date_start', start.format('YYYY-MM-DD'));
                      setFieldValue('trx_date_end', end.format('YYYY-MM-DD'));
                    }}
                  />
                  <small className="form-text ">
                    Cari <b>Tanggal Transaksi</b>
                  </small>
                </div>
                <div className="col">
                  <AsyncSelect
                    isClearable
                    cacheOptions={true}
                    name="member_uuid"
                    placeholder="Member"
                    noOptionsMessage={({ inputValue }) =>
                      inputValue.length >= 3 ? 'No options' : 'Type 3 or more characters'
                    }
                    loadOptions={(inputValue, callback) =>
                      loadOptions(searchMembers, inputValue, callback)
                    }
                    defaultOptions={false}
                    value={
                      values.member_label && values.member_uuid
                        ? {
                            label: values.member_label,
                            value: values.member_uuid,
                          }
                        : null
                    }
                    onChange={(e) => {
                      const value = e;
                      setFieldValue('member_label', value && value.label);
                      setFieldValue('member_uuid', value && value.value);
                    }}
                  />
                  <small className="form-text ">
                    Cari <b>ID Member</b>
                  </small>
                </div>
                {/* <div className="col-lg-3">
                  <Select
                    name="is_online"
                    isClearable
                    placeholder="Pilih is_online..."
                    value={
                      values.is_online
                        ? {
                            label: values.is_online.label,
                            value: values.is_online.value,
                          }
                        : null
                    }
                    options={IS_ONLINE.map((item) => ({
                      value: item.value,
                      label: item.label,
                    }))}
                    onChange={(e) => {
                      setFieldValue('is_online', e);
                    }}
                  />
                  <small className="form-text ">
                    Cari <b>Status</b>
                  </small>
                </div> */}
                {/* <div className="col-lg-4">
                <DateRangePicker
                  small={true}
                  startDate={tanggal.start_date}
                  endDate={tanggal.end_date}
                  onDatesChange={({ startDate, endDate }) => {
                    setTanggal({
                      start_date: startDate,
                      end_date: endDate,
                    });
                    setFieldValue("trx_date", `${startDate}-${endDate}`);
                  }}
                  focusedInput={onFocused}
                  onFocusChange={(focusedInput) => setOnFocused(focusedInput)}
                  numberOfMonths={1}
                  isOutsideRange={() => false}
                  showClearDates={true}
                  startDateId="start_date"
                  endDateId="end_date"
                  hideKeyboardShortcutsPanel={true}
                />
                <small className="form-text  d-print-none">
                  Cari berdasarkan <b>Jarak Waktu</b>
                </small>
              </div> */}
              </div>
              <div className="form-group row">
                <div className="col-lg-4">
                  <Select
                    name="status"
                    isClearable
                    placeholder="Pilih status..."
                    value={
                      values.status
                        ? {
                            label: values.status,
                            value: values.status,
                          }
                        : null
                    }
                    // options={ORDER_STATUS_OPTIONS.map((item) => ({
                    //   value: item.value,
                    //   label: item.label,
                    // }))}
                    options={
                      data &&
                      data.getOrderStatusGroup.items.map((item) => ({
                        label: `${item.status} - (${item.count})`,
                        value: item.status,
                      }))
                    }
                    onChange={(e) => {
                      const value = e;
                      setFieldValue('status', value && value.value);
                    }}
                  />
                  <small className="form-text ">
                    Cari <b>Status</b>
                  </small>
                </div>
                <div className="col-lg-4">
                  <Field
                    name="invoice_no"
                    component={Input}
                    value={values.invoice_no}
                    placeholder=" Cari No Invoice.."
                    onChange={(e) => setFieldValue('invoice_no', e.target.value)}
                  />
                  <small className="form-text ">
                    Cari <b>Invoice</b>
                  </small>
                </div>
                <div className="col-lg-4">
                  <Field
                    name="marketplace_invoice"
                    component={Input}
                    value={values.marketplace_invoice}
                    placeholder=" Cari No Marketplace Invoice.."
                    onChange={(e) => setFieldValue('marketplace_invoice', e.target.value)}
                  />
                  <small className="form-text ">
                    Cari <b>Marketplace Invoice</b>
                  </small>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-4">
                  <Field
                    name="receiver_name"
                    component={Input}
                    value={values.receiver_name}
                    placeholder="Cari Nama penerima.."
                    onChange={(e) => setFieldValue('receiver_name', e.target.value)}
                  />
                  <small className="form-text ">
                    Cari <b>Nama Penerima</b>
                  </small>
                </div>

                <div className="col-lg-4">
                  <Field
                    name="receiver_hp"
                    component={Input}
                    value={values.receiver_hp}
                    placeholder="Cari No. Hp penerima.."
                    onChange={(e) => setFieldValue('receiver_hp', e.target.value)}
                  />
                  <small className="form-text ">
                    Cari <b>No. HP Penerima</b>
                  </small>
                </div>

                <div className="col-lg-4">
                  <Select
                    cacheOptions={true}
                    isClearable
                    isDisabled={match.path === '/order-application' && true}
                    name="store_uuid"
                    placeholder="Cari Store"
                    options={storeOptions.map((item) => ({
                      label: `${item.market_name} - ${item.platform}`,
                      value: item.uuid,
                    }))}
                    value={
                      values.store_uuid
                        ? {
                            label: values.store_name,
                            value: values.store_uuid,
                          }
                        : null
                    }
                    onChange={(e) => {
                      const value = e;
                      setFieldValue('store_uuid', value && value.value);
                      setFieldValue('store_name', value && value.label);
                    }}
                  />
                  <small className="form-text ">
                    Cari <b>Store</b>
                  </small>
                </div>

                {/* ? Disable sementara #673  */}
                {/* <div className="col-lg-4">
                  <AsyncSelect
                    isClearable
                    cacheOptions={true}
                    name="branch_uuid"
                    placeholder="Branch"
                    noOptionsMessage={({ inputValue }) =>
                      inputValue.length >= 3 ? 'No options' : 'Type 3 or more characters'
                    }
                    loadOptions={(inputValue, callback) =>
                      loadOptions(searchBranches, inputValue, callback)
                    }
                    defaultOptions={false}
                    value={
                      branchName && values.branch_uuid
                        ? {
                            label: branchName,
                            value: values.branch_uuid,
                          }
                        : null
                    }
                    onChange={(e) => {
                      const value = e;
                      setBranchName(value && value.label);
                      setFieldValue('branch_uuid', value && value.value);
                    }}
                  />
                  <small className="form-text ">
                    Cari <b>ID Branch</b>
                  </small>
                </div> 
                <div className="col-lg-4">
                  <Select
                    isClearable
                    cacheOptions={true}
                    name="shipping_service_uuid"
                    placeholder="Cari Shipping service"
                    options={shippingServiceOptions.map((item) => ({
                      label: item.service,
                      value: item.uuid,
                    }))}
                    value={
                      values.shipping_service_uuid
                        ? {
                            label: values.shipping_name,
                            value: values.shipping_service_uuid,
                          }
                        : null
                    }
                    onChange={(e) => {
                      const value = e;
                      setFieldValue('shipping_service_uuid', value && value.value);
                      setFieldValue('shipping_name', value && value.label);
                    }}
                  />
                  <small className="form-text ">
                    Cari <b>Shipping Service</b>
                  </small>
                  
                </div>
                */}
                {/* ? Disable sementara #673  */}
              </div>

              <div className="form-group row">
                <div className="col-lg-4">
                  <Select
                    name="status"
                    isClearable
                    placeholder="Pilih status shotcut..."
                    value={
                      values.status_array_label && values.status_array
                        ? {
                            label: values.status_array_label,
                            value: values.status_array,
                          }
                        : null
                    }
                    options={ORDER_SHORTCUT_STATUS.map((item) => ({
                      label: item.label,
                      value: item.value,
                    }))}
                    onChange={(e) => {
                      const value = e;
                      setFieldValue('status_array_label', value && value.label);
                      setFieldValue('status_array', value && value.value);
                    }}
                  />
                  <small className="form-text ">
                    Cari <b>Status Shortcut</b>
                  </small>
                </div>

                <div className="col-lg-4">
                  <Select
                    cacheOptions={true}
                    isClearable
                    name="admin_uuid"
                    placeholder="Cari Admin"
                    options={adminOptions.map((item) => ({
                      label: item.name,
                      value: item.uuid,
                    }))}
                    value={
                      values.admin_name && values.admin_uuid
                        ? {
                            label: values.admin_name,
                            value: values.admin_uuid,
                          }
                        : null
                    }
                    onChange={(e) => {
                      const value = e;
                      setFieldValue('admin_uuid', value && value.value);
                      setFieldValue('admin_name', value && value.label);
                    }}
                  />
                  <small className="form-text ">
                    Cari <b>Admin</b>
                  </small>
                </div>

                <div className="col-lg-4">
                  <Select
                    cacheOptions={true}
                    isClearable
                    name="shipping_company_code"
                    placeholder="Cari Shipping Company"
                    options={shippingCompanyOptions.map((item) => ({
                      label: item.name,
                      value: item.code,
                    }))}
                    value={
                      values.shipping_company_code
                        ? {
                            label: values.shipping_company_name,
                            value: values.shipping_company_code,
                          }
                        : null
                    }
                    onChange={(e) => {
                      const value = e;
                      setFieldValue('shipping_company_code', value && value.value);
                      setFieldValue('shipping_company_name', value && value.label);
                    }}
                  />
                  <small className="form-text ">
                    Cari <b>Shipping Company</b>
                  </small>
                </div>
                {/* ? Disable sementara #673  */}
                {/* <div className="col-lg-4">
                  <Field
                    name="shipping_cost"
                    type="number"
                    value={values.shipping_cost}
                    component={Input}
                    placeholder="Cari Shipping cost.."
                    onChange={(e) => setFieldValue('shipping_cost', e.target.value)}
                  />
                  <small className="form-text ">
                    Cari <b>Shipping cost</b>
                  </small>
                </div>
                <div className="col-lg-4">
                  <Field
                    name="total_order_item_price"
                    type="number"
                    component={Input}
                    value={values.total_order_item_price}
                    placeholder="Total Harga.."
                    onChange={(e) => setFieldValue('total_order_item_price', e.target.value)}
                  />
                  <small className="form-text ">
                    Cari <b>Total harga</b>
                  </small>
                </div> */}
                {/* ? Disable sementara #673  */}
                {/* 
              </div>
              <div className="form-group row">
                <div className="col-lg-3">
                  <Field
                    name="total_amount_start"
                    type="number"
                    component={Input}
                    placeholder="Ex: >= 12000"
                    value={values.total_amount_start}
                    onChange={(e) => setFieldValue('total_amount_start', e.target.value)}
                  />
                  <small className="form-text ">
                    Cari <b>Total Pembayaran Min</b>
                  </small>
                </div>
                <div className="col-lg-3">
                  <Field
                    name="total_amount_end"
                    type="number"
                    component={Input}
                    placeholder="Ex: <= 12000"
                    value={values.total_amount_end}
                    onChange={(e) => setFieldValue('total_amount_end', e.target.value)}
                  />
                  <small className="form-text ">
                    Cari <b>Total Pembayaran Maks</b>
                  </small>
                </div>
                <div className="col-lg-3">
                  <Field
                    name="total_order_item_price_start"
                    type="number"
                    component={Input}
                    placeholder="Ex: >= 12000"
                    value={values.total_order_item_price_start}
                    onChange={(e) => setFieldValue('total_order_item_price_start', e.target.value)}
                  />
                  <small className="form-text ">
                    Cari <b>Total Harga Barang Min</b>
                  </small>
                </div>
                <div className="col-lg-3">
                  <Field
                    name="total_order_item_price_end"
                    type="number"
                    component={Input}
                    placeholder="Ex: <= 12000"
                    value={values.total_order_item_price_end}
                    onChange={(e) => setFieldValue('total_order_item_price_end', e.target.value)}
                  />
                  <small className="form-text ">
                    Cari <b>Total Harga Barang Maks</b>
                  </small>
                </div>
              </div>
              <div className="form-group row">
                 */}
                {/* ? Disable sementara #673  */}
                {/* <div className="col-lg-4">
                  <Select
                    cacheOptions={true}
                    isClearable
                    isDisabled={match.path === '/order-application' && true}
                    name="store_uuid"
                    placeholder="Cari Store"
                    options={storeOptions.map((item) => ({
                      label: `${item.market_name} - ${item.platform}`,
                      value: item.uuid,
                    }))}
                    value={
                      values.store_uuid
                        ? {
                            label: values.store_name,
                            value: values.store_uuid,
                          }
                        : null
                    }
                    onChange={(e) => {
                      const value = e;
                      setFieldValue('store_uuid', value && value.value);
                      setFieldValue('store_name', value && value.label);
                    }}
                  />
                  <small className="form-text ">
                    Cari <b>Store</b>
                  </small>
                </div> */}
                {/* ? Disable sementara #673  */}
              </div>
              <button
                type="submit"
                onClick={handleSubmit}
                className="btn btn-primary btn-elevate float-right mb-20 ml-4"
              >
                Terapkan
              </button>
              <button
                type="button"
                onClick={() => {
                  resetForm();
                  // setTanggal({ start_date: null, end_date: null });
                  // handleSubmit();
                }}
                className="btn btn-danger btn-elevate ml-3 float-right mb-20"
              >
                Hapus Pencarian
              </button>
              <hr className="mt-25" />
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
