import React from "react";
import { Route } from "react-router-dom";
import { OrderLoadingDialog } from "./order-loading-dialog/OrderLoadingDialog";
import { OrderEditDialog } from "./order-edit-dialog/OrderEditDialog";
import { OrderUIProvider } from "./OrderUIContext";
import { OrderCard } from "./OrderCard";

export function OrderPage({ history, match }) {
  const orderUIEvents = {
    newOrderButtonClick: () => {
      history.push("/order/new");
    },
    openEditOrderDialog: (id) => {
      history.push(`/order/${id}/edit`);
    },
    openFetchOrderDialog: () => {
      history.push(`/order/fetch`);
    },
  };

  return (
    <OrderUIProvider orderUIEvents={orderUIEvents}>
      {/*<OrderLoadingDialog />*/}
      {/* <Route path="/order/new">
        {({ history, match }) => (
          <OrderEditDialog
            show={match != null}
            onHide={() => {
              history.push("/order");
            }}
          />
        )}
      </Route> */}
      <Route path="/order/:id/edit">
        {({ history, match }) => (
          <OrderEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push('/order');
            }}
          />
        )}
      </Route>
      <OrderCard match={match} history={history} />
    </OrderUIProvider>
  );
}
