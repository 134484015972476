import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./PurchaseUIHelpers";
import { shallowEqual, useSelector } from "react-redux";

const PurchaseUIContext = createContext();

export function usePurchaseUIContext() {
  return useContext(PurchaseUIContext);
}

export const PurchaseUIConsumer = PurchaseUIContext.Consumer;

export function PurchaseUIProvider({ purchaseUIEvents, children }) {
  const { filters } = useSelector(state => state.purchases, shallowEqual)
  const [queryParams, setQueryParamsBase] = useState(filters);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initPurchase = {
    uuid: "",
    trx_date: "",
    status: "",
    purchase_items: [],
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initPurchase,
    newPurchaseButtonClick: purchaseUIEvents.newPurchaseButtonClick,
    openEditPurchase: purchaseUIEvents.openEditPurchase,
  };

  return <PurchaseUIContext.Provider value={value}>{children}</PurchaseUIContext.Provider>;
}
