import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Modal } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../../_redux/purchase/purchaseActions';
import {
  CREATE_PURCHASE,
  UPDATE_PURCHAE_ITEM,
  UPDATE_PURCHASE,
} from '../../_redux/purchase/purchaseCrud';
import { PurchaseEditForm } from './PurchaseEditForm';
import { toast } from 'react-toastify';
import { removeStorage, toastOption } from '../../../../../_metronic/_helpers';
import { AnimateLoading, Card, CardBody } from '../../../../../_metronic/_partials/controls';
import { initPurchase } from '../PurchaseUIHelpers';
import https from 'https';
import axios from 'axios';
import { API_URL } from '../../../../config/UrlEndPoint';

export const PurchaseEdit = ({
  history,
  match: {
    params: { id },
  },
}) => {
  useEffect(() => {
    if (id) removeStorage('purchase_product');
  }, []);

  const dispatch = useDispatch();

  React.useEffect(() => {
    printerDevice();
  }, []);

  const [printer, setPrinter] = useState();

  useEffect(() => {
    dispatch(actions.fetchPurchase(id));
  }, [id, dispatch]);

  const { purchaseForEdit, actionsLoading } = useSelector(({ purchases }) => purchases);

  const [createPurchase, { loading: createLoading }] = useMutation(CREATE_PURCHASE, {
    onCompleted: () => {
      toast.success('Successful added new purchase', toastOption);
      history.push('/purchase');
      removeStorage('purchase_product');
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const [updatePurchase, { loading: updateLoading }] = useMutation(UPDATE_PURCHASE, {
    onCompleted: () => {
      toast.success('Successful updated purchase', toastOption);
      history.push('/purchase');
      removeStorage('purchase_product');
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const [updatePurchaseItem] = useMutation(UPDATE_PURCHAE_ITEM, {
    onCompleted: () => {
      actions.fetchPurchase(id);
      toast.success('Success');
    },
    onError: (error) => {
      toast.error(error);
    },
  });

  function savePurchase(params) {
    if (!(id || purchaseForEdit)) {
      return createPurchase({
        variables: {
          ...params,
        },
      });
    }

    updatePurchase({
      variables: {
        ...params,
        uuid: purchaseForEdit.uuid,
      },
    });
  }

  const agent = new https.Agent({
    rejectUnauthorized: false,
  });

  const [isConnected, setIsConnected] = useState(false);
  const printerDevice = async () => {
    // ! NYALAIN LAGI
    fetch('https://localhost:9101/default?type=printer', {
      method: 'GET',
      mode: 'no-cors',
      agent: agent,
    })
      .then((res) => {
        printerAvailable();
      })
      .catch((err) => {
        setIsConnected(false);
        toast.error(
          'Error connecting printer, Pastikan aplikasi Browser Print sudah aktif',
          toastOption
        );
        // console.log(err);
      });
  };

  const printerAvailable = () => {
    fetch('https://localhost:9101/available', {
      method: 'GET',
      agent: agent,
    })
      .then((res) => res.json())
      .then((result) => {
        setPrinter(result.printer[0]);
        setIsConnected(true);
      })
      .catch((err) => {
        setIsConnected(false);
        toast.error(
          'Error connecting printer, Pastikan aplikasi Browser Print sudah aktif',
          toastOption
        );
        console.log('error available', err);
      });
  };

  const printLabel = (data) => {
    const { uuid, sku, name, qty, product_name, product_sku_uuid } = data;

    window.open(
      `${API_URL}template/label/${product_sku_uuid}?qty=${qty}`,
      '',
      'width=1080,height=720'
    );
    updatePurchaseItem({
      variables: {
        updatePurchaseItemInput: {
          uuid,
          is_printed: true,
        },
      },
    });
    return;
    // let barcode = sku.replaceAll('-', '->5');
    // let barcode_str = sku;
    // let prod_name1 = product_name.slice(0, 27);
    // let prod_name2 = product_name.slice(27);

    // // console.log('prod_name1',prod_name1);
    // // console.log('prod_name2',prod_name2);
    // // return;
    // let inputQty = qty;
    // // console.log({
    // //   barcode,
    // //   barcode_str,
    // //   prod_name1,
    // //   prod_name2,
    // //   inputQty,
    // // });
    // const zpl =
    //   'CT~~CD,~CC^~CT~^XA~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR5,5~SD15^JUS^LRN^CI0^XZ' +
    //   '^XA^MMT^PW240^LL0160^LS0^FO32,0^GFA,00640,00640,00020,:Z64:eJxjYMAK+P+jgA/4xdiBvHpUsX/MQJ49mjr5//+/I/QyKTE3P5CzY/j/wKCBueEB2F7GBEbGB/JyjP8fCBxhPPBAACTGvP2H5eN5fJb/H3e8M/78A6yXuYzx58eauPr/3+8eL/78FyzGpcbw44fNOX6gunPWj/+AxRjdCv9+lzl++P/HuccLhWvAYgzMzA0JBs2f/zdIMBkYWRiAhDiE2Q8UFLgX/j9Sw25oXGMAdfMPiEeAoMEe5o+/QDF3sBhDTQFU7CciWP4QCCvqiQ0LAADKK/dK:65A6' +
    //   '^BY2,3,62^FT230,89^BCI,,N,N' +
    //   '^FD>:' +
    //   barcode +
    //   '^FS' +
    //   '^FT230,72^A0I,17,16^FH^FD' +
    //   barcode_str +
    //   '^FS' +
    //   '^FT230,52^A0I,17,16^FH^FD' +
    //   prod_name1 +
    //   '^FS' +
    //   '^FT230,32^A0I,17,16^FH^FD' +
    //   prod_name2 +
    //   '^FS' +
    //   '^PQ' +
    //   inputQty +
    //   ',0,1,Y^XZ';

    // const payload = {
    //   device: printer,
    //   data: zpl,
    // };

    // fetch('https://localhost:9101/write', {
    //   method: 'POST',
    //   body: JSON.stringify(payload),
    //   agent: agent,
    // })
    //   .then((res) => {
    //     updatePurchaseItem({
    //       variables: {
    //         updatePurchaseItemInput: {
    //           uuid,
    //           is_printed: true,
    //         },
    //       },
    //     });
    //   })
    //   .catch((err) => {
    //     toast.error(
    //       'Error connecting printer, Pastikan aplikasi Browser Print sudah aktif',
    //       toastOption
    //     );
    //   });
  };

  const loading = createLoading || updateLoading || actionsLoading;

  return (
    <>
      {loading && <AnimateLoading />}
      <PurchaseEditForm
        history={history}
        purchase={purchaseForEdit || initPurchase}
        purchaseLoading={loading}
        savePurchase={savePurchase}
        dataPrint={printLabel}
      />
      <Card>
        <CardBody>
          <div className="row">
            <div className="col">
              <h6>Printer Status</h6>
              {isConnected ? (
                <span>
                  {printer.manufacturer} - {printer.name} - {printer.connection}
                </span>
              ) : (
                <span>Error connecting printer, Pastikan aplikasi Browser Print sudah aktif</span>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
