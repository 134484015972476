import { useState, useEffect } from "react";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { UPDATE_SKU_IMAGE, DELETE_SKU_IMAGE } from "../../../_redux/products/productCrud";
import { toastOption } from "../../../../../../_metronic/_helpers";

const GET_PRODUCT_SKU = gql`
  query Q($product_sku_uuid: String!) {
    getProductSku(product_sku_uuid: $product_sku_uuid) {
      uuid
      sku
      image {
        uuid
        url
        filename
      }
    }
  }
`;

export const useSkuImage = (productSku) => {
  const [image, setImage] = useState(productSku.image);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setImage(productSku.image);
  }, [productSku]);

  const [getProductSku, { loading: getLoading }] = useLazyQuery(GET_PRODUCT_SKU, {
    variables: { uuid: productSku.uuid },
    onCompleted: (data) => {
      const { getProductSku } = data ?? {};

      if (getProductSku && getProductSku.image) {
        setImage(getProductSku.image);
      }
    },
    onError: (error) => {
      setImage(null);
    },
    fetchPolicy: "no-cache",
  });

  const [fetchUpdateSkuImage, { loading: updateLoading }] = useMutation(UPDATE_SKU_IMAGE, {
    onCompleted: () => {
      getProductSku({ variables: { product_sku_uuid: productSku.uuid } });
      toast.success("Berhasil update foto sku!", toastOption);
    },
    onError: (err) => {
      toast.error("Gagal update foto sku!", toastOption);
    },
  });

  const [fetchDeleteSkuImage, { loading: deleteLoading }] = useMutation(DELETE_SKU_IMAGE, {
    onCompleted: () => {
      toast.success("Berhasil menghapus foto sku!", toastOption);
      setImage(null);
    },
    onError: (err) => {
      toast.error("Gagal menghapus foto sku!", toastOption);
    },
  });

  useEffect(() => {
    if ([getLoading, updateLoading, deleteLoading].every((isLoading) => !isLoading)) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [getLoading, updateLoading, deleteLoading]);

  function updateSkuImage(params) {
    fetchUpdateSkuImage({
      variables: {
        image: params.image,
        updateProductSkuInput: {
          uuid: params.sku_uuid,
        },
      },
    });
  }

  function deleteSkuImage(uuid) {
    fetchDeleteSkuImage({
      variables: {
        product_sku_uuid: uuid,
      },
    });
  }

  return [image, loading, updateSkuImage, deleteSkuImage];
};
