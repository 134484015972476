import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./ProductUIHelpers";
import { useSelector } from "react-redux";

const ProductUIContext = createContext();

export function useProductUIContext() {
  return useContext(ProductUIContext);
}

export const ProductUIConsumer = ProductUIContext.Consumer;

export function ProductUIProvider({ productUIEvents, children }) {
  const { filters } = useSelector(state => state.product)
  const [queryParams, setQueryParamsBase] = useState(filters);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initProduct = {
    id: undefined,
    firstName: "",
    lastName: "",
    email: "",
    userName: "",
    gender: "Female",
    status: 0,
    dateOfBbirth: "",
    ipAddress: "",
    type: 1,
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    setQueryParams,
    initProduct,
    newProductButtonClick: productUIEvents.newProductButtonClick,
    openEditProductPage: productUIEvents.openEditProductPage,
    openDeleteProductDialog: productUIEvents.openDeleteProductDialog,
    openFetchProductDialog: productUIEvents.openFetchProductDialog,
    openUpdateProductStatusDialog:
      productUIEvents.openUpdateProductStatusDialog,
  };

  return (
    <ProductUIContext.Provider value={value}>
      {children}
    </ProductUIContext.Provider>
  );
}
