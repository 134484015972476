import {createSlice} from "@reduxjs/toolkit";
import { initialFilter } from "../../pages/supplier/SupplierUIHelpers";

const initialSuppliersState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  supplierForEdit: undefined,
  lastError: null,
  filters: initialFilter
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const supplierSlice = createSlice({
  name: "suppliers",
  initialState: initialSuppliersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getSupplierById
    supplierFetched: (state, action) => {
      state.actionsLoading = false;
      state.supplierForEdit = action.payload.supplierForEdit;
      state.error = null;
    },
    // findSuppliers
    suppliersFetched: (state, action) => {
      const { total_items, items } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = items;
      state.totalCount = total_items;
    },
    // getAllSupplier
    supplierAllFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.all= action.payload.all
    },
    // createSupplier
    supplierCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.supplier);
    },
    // updateSupplier
    supplierUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.supplier.id) {
          return action.payload.supplier;
        }
        return entity;
      });
    },
    supplierFilter: (state, action) => {
      state.listLoading = false
      state.error = null
      state.filters = action.payload
    },
  }
});
