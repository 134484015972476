import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/campaignActions";
import { DELETE_CAMPAIGN } from "../../_redux/campaignCrud";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { toastOption } from "../../../../../_metronic/_helpers";
import { LoadingAnimation } from "../loading-animation/LoadingAnimation";
import { useCampaignUIContext } from "../CampaignUIContext";

export function CampaignDisableDialog({ id, show, onHide }) {
  const campaignUIContext = useCampaignUIContext();
  const campaignUIProps = useMemo(() => {
    return {
      queryParams: campaignUIContext.queryParams,
    };
  });
  // Campaign Redux state
  const dispatch = useDispatch();
  const { actionsLoading, campaignForEdit } = useSelector(
    (state) => state.campaign,
    shallowEqual
  );

  const [title, setTitle] = useState("");
  // Title couting
  useEffect(() => {
    let _title = "";
    if (campaignForEdit && id) {
      _title = `Delete Campaign '${campaignForEdit.name}'`;
    }

    setTitle(_title);
    // eslint - disable - next - line;
  }, [campaignForEdit, actionsLoading]);

  useEffect(() => {
    // server call for getting Campaign by id
    dispatch(actions.getCampaignById(id));
  }, [id, dispatch]);

  // server request for saving campaign
  const [deleteCampaign, { loading }] = useMutation(DELETE_CAMPAIGN, {
    onCompleted: (data) => {
      toast.success("Successful deleted!", toastOption);
      dispatch(actions.findCampaign(campaignUIProps.queryParams));
      onHide();
    },
    onError: (err) => toast.error(err.message, toastOption),
  });

  const deleting = () => {
    deleteCampaign({
      variables: {
        uuid: id,
      },
    });
  };

  return (
    <>
      <LoadingAnimation open={loading} />
      <Modal
        size="md"
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>Anda yakin akan menghapus Campaign ini?</span>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={onHide}
              className="btn btn-light btn-elevate"
            >
              Cancel
            </button>
            <> </>
            <button
              type="button"
              onClick={deleting}
              className="btn btn-primary btn-elevate"
            >
              Yakin
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
