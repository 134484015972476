// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState, useEffect } from "react";
import { Modal, Form as Forms } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Input } from "../../../_metronic/_partials/controls";

export function SupplierForm({
  saveSupplier,
  supplier,
  actionsLoading,
  onHide,
  customers,
}) {
  useEffect(() => {}, [supplier]);

  // Validation schema
  const shape = {
    name: Yup.string().required("Nama wajib diisi"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email wajib diisi"),
    hp: Yup.string().required("Judul wajib diisi"),
    password: Yup.string().required("Password wajib diisi"),
  };
  if (supplier.uuid) {
    Object.assign(shape, { password: Yup.string() });
  }
  const SupplierEditSchema = Yup.object().shape(shape);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={supplier}
        validationSchema={SupplierEditSchema}
        onSubmit={(values) => {
          saveSupplier(values);
        }}
      >
        {({ handleSubmit, setFieldValue }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col">
                    <Field
                      name="name"
                      component={Input}
                      placeholder="Name"
                      label="Name"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col">
                    <Field
                      name="email"
                      component={Input}
                      placeholder="email@email.com"
                      label="Email"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col">
                    <Field
                      name="hp"
                      type="number"
                      component={Input}
                      placeholder="08xxxxxxx"
                      label="Phone Number"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col">
                    <Field
                      name="password"
                      type="password"
                      component={Input}
                      placeholder="password"
                      label="Password"
                    />
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
