import * as XLSX from 'xlsx';

export function getTokopediaTransactionCode(transDesc) {
  if (/(^Withdrawal)/.test(transDesc)) {
    return 'WITHDRAWAL';
  }

  if (/(?=.*pembelian)(?=.*Ads)/i.test(transDesc)) {
    return 'EXPANSE_ADS';
  }

  if (/(?=.*Pemotongan)(?=.*Ongkir)/i.test(transDesc)) {
    return 'FEE_BEBAS_ONGKIR';
  }

  if (/(?=.*Pemotongan)(?=.*Voucher)/i.test(transDesc)) {
    return 'FEE_VOUCHER';
  }

  if (/(?=.*Pemotongan)(?=.*bayar\ di\ tempat)/i.test(transDesc)) {
    return 'FEE_COD';
  }

  if (/(?=.*Pemotongan)(?=.*Asuransi)/i.test(transDesc)) {
    return 'FEE_INSURANCE';
  }

  if (/(?=.*Pemotongan)(?=.*proteksi)/i.test(transDesc)) {
    return 'FEE_INSURANCE';
  }

  if (/(?=.*Pemotongan)(?=.*penalti)/i.test(transDesc)) {
    return 'FEE_PENALTI';
  }

  if (/(^Pemotongan Biaya Layanan Power Merchant Pro)/i.test(transDesc)) {
    return 'FEE_MERCHANT';
  }

  if (/(?=.*Dipotong)(?=.*Solusi\ dari\ Resolusi)/i.test(transDesc)) {
    return 'RETURN';
  }

  if (/(^Transaksi Penjualan Berhasil)/i.test(transDesc)) {
    return 'INCOME_SALES';
  }

  if (/(^Subsidi dari Tokopedia)/i.test(transDesc)) {
    return 'INCOME_SUBSIDI';
  }

  if (/(?=.*Pengembalian)(?=.*ongkir)/i.test(transDesc)) {
    return 'INCOME_ONGKIR';
  }

  if (/(?=.*Cashback)(?=.*pengiriman)/i.test(transDesc)) {
    return 'CASHBACK_ONGKIR';
  }
}

export function findRowIndexByLabels(labels, worksheet) {
  let charCode = 65;
  let column = String.fromCharCode(charCode);
  let startRow = 1;

  const range = XLSX.utils.decode_range(worksheet['!ref']);
  const nrows = range.e.r;

  while (
    labels.some(
      (label, index) =>
        ((worksheet[`${String.fromCharCode(charCode + index)}${startRow}`] ?? { v: '' }).v + '')
          .trim()
          .toLowerCase() != label.trim().toLowerCase()
    )
  ) {
    if (!worksheet[`A${startRow}`] && startRow >= nrows) {
      return -1;
    }

    startRow++;
  }

  if (
    labels.every(
      (label, index) =>
        ((worksheet[`${String.fromCharCode(charCode + index)}${startRow}`] ?? { v: '' }).v + '')
          .trim()
          .toLowerCase() == label.trim().toLowerCase()
    )
  ) {
    return startRow + 1;
  } else {
    return -1;
  }
}

export function getDescriptionFilter(trans_code, description) {
  if (trans_code === 'WITHRAWAL') {
    const match = description.match(/- \d+ - /);

    if (match) {
      return match[0].replace(/\D/g, '');
    }
  }

  if (trans_code === 'EXPANSE_ADS') {
    if (description.includes('Tokopedia Ads')) {
      const match = description.match(/id transaksi: \d+/i);

      if (match) {
        return match[0].replace(/\D/g, '');
      }
    }
  }

  return '';
}
