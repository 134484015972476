import React from "react";
import { Input } from "../../../../../../_metronic/_partials/controls";
import { Field } from "formik";
import CreateableSelect from "react-select/creatable";

export const VariantItem = ({ index, item, updateItem, addOption, removeOption }) => {
  return (
    <div className="form-group row" key={item.uuid}>
      <div className="col-lg-4">
        <Field
          name={`variant-${index}`}
          component={Input}
          placeholder="E.g., warna"
          label="Nama Varian"
          defaultValue={item.name}
          onChange={(e) => {
            const { value } = e.target;

            updateItem({ name: value });
          }}
        />
      </div>
      <div className="col-lg-7">
        <CreateableSelect
          isMulti
          name={`option-${index}`}
          placeholder="E.g.: hitam, putih, hijau"
          label="Opsi Varian"
          className="mt-8"
          defaultValue={item.options.map((opt) => ({
            label: opt,
            value: opt,
          }))}
          value={item.options.map((opt) => ({
            label: opt,
            value: opt,
          }))}
          onCreateOption={addOption}
          onChange={(items, props) => {
            if (props.action === "remove-value") {
              console.log("remove-value", props);
              removeOption(props.removedValue);
            }
          }}
        />
      </div>
      <div className="col-lg-1">
        {/* {!haveVariant.variant2 && (
          <button
            type="button"
            onClick={() => {
              setDeleteSku(true);
            }}
            className="btn mt-8"
          >
            <i className="fa fa-trash text-danger icon-xl ml-2"></i>
          </button>
        )} */}
      </div>
    </div>
  );
};
