import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '../SvgIcon'
import { defaultFill } from '../constant'

const Plus = props => (
  <SvgIcon {...props}>
    <path fill={props.fill} className="custom-fill" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
  </SvgIcon>
)

Plus.propTypes = {
  fill: PropTypes.string,
}

Plus.defaultProps = {
  fill: defaultFill,
}

export default Plus
