import React, { useState, useMemo } from "react";
import { useLazyQuery } from "@apollo/client";
import { Form, Formik } from "formik";
import { isEqual } from "lodash";
import { DateRangePicker } from "react-dates";
import AsyncSelect from "react-select/async";
import { usePurchaseUIContext } from "../PurchaseUIContext";
import { initialFilter } from "../PurchaseUIHelpers";
import { SEARCH_BRANCHES } from "../../../MasterData/_redux/branch/branchCrud";
import { SEARCH_PROJECTS } from "../../../MasterData/_redux/project/projectCrud";
import { SEARCH_SUPPLIERS } from "../../../MasterData/_redux/supplier/supplierCrud";
import { SEARCH_PRODUCTS } from "../../../Products2/_redux/products/productCrud";
import { useDispatch, useSelector } from "react-redux";
import { purchaseFilter } from "../../_redux/purchase/purchaseActions";

const prepareFilter = (queryParams, values) => {
  const {
    branch_label,
    branch_uuid,
    project_label,
    project_uuid,
    supplier_label,
    supplier_uuid,
    product_label,
    product_uuid,
    product_sku,
    description,
    start_trx_date,
    end_trx_date,
    page_number,
    page_size,
    filterIsOpen
  } = values;
  const newQueryParams = { ...queryParams };
  newQueryParams.branch_label = branch_label ?? "";
  newQueryParams.branch_uuid = branch_uuid ? branch_uuid.value : "";
  newQueryParams.project_label = project_label ?? "";
  newQueryParams.project_uuid = project_uuid ? project_uuid.value : "";
  newQueryParams.supplier_label = supplier_label ?? "";
  newQueryParams.supplier_uuid = supplier_uuid ? supplier_uuid.value : "";
  newQueryParams.product_label = product_label ?? "";
  newQueryParams.product_uuid = product_uuid ?? "";
  newQueryParams.product_sku = product_sku ?? "";
  newQueryParams.description = description ?? "";
  newQueryParams.start_trx_date = start_trx_date ?? null;
  newQueryParams.end_trx_date = end_trx_date ?? null;
  newQueryParams.page_number = page_number ?? 1;
  newQueryParams.page_size = page_size ?? 10;
  newQueryParams.filterIsOpen = filterIsOpen;
  return newQueryParams;
};

export function PurchaseFilter({ listLoading, history }) {
  const { filters } = useSelector(state => state.purchases)
  const dispatch = useDispatch()
  // Purchases UI Context
  const purchasesUIContext = usePurchaseUIContext();
  const purchasesUIProps = useMemo(() => {
    return {
      queryParams: purchasesUIContext.queryParams,
      setQueryParams: purchasesUIContext.setQueryParams,
    };
  }, [purchasesUIContext]);

  const [currentTimeout, setCurrentTimeout] = useState(null);
  const [currentCallback, setCurrentCallback] = useState(() => () => null);
  const [onFocused, setOnFocused] = useState(null);
  const [branchName, setBranchName] = useState("");
  const [projectName, setProjectName] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [productName, setProductName] = useState("");

  const [searchBranches] = useLazyQuery(SEARCH_BRANCHES, {
    variables: {
      name: "",
    },
    onCompleted: ({ findBranches: { items } }) => {
      currentCallback(items.map(({ uuid, name }) => ({ label: name, value: uuid })));
    },
  });

  const [searchProjects] = useLazyQuery(SEARCH_PROJECTS, {
    variables: {
      name: "",
    },
    onCompleted: ({ findProject: { items } }) => {
      currentCallback(items.map(({ uuid, name }) => ({ label: name, value: uuid })));
    },
  });

  const [searchSuppliers] = useLazyQuery(SEARCH_SUPPLIERS, {
    variables: {
      name: "",
    },
    onCompleted: ({ findSupplier: { items } }) => {
      currentCallback(items.map(({ uuid, name }) => ({ label: name, value: uuid })));
    },
  });

  const [searchProducts] = useLazyQuery(SEARCH_PRODUCTS, {
    variables: {
      name: "",
    },
    onCompleted: ({ findProduct: { items } }) => {
      currentCallback(items.map(({ uuid, name }) => ({ label: name, value: uuid })));
    },
    onError: err => {
      console.log('err', err);
    }
  });

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(purchasesUIProps.queryParams, values);

    purchasesUIProps.setQueryParams(newQueryParams);
    dispatch(purchaseFilter(newQueryParams))
  };

  function loadOptions(queryCaller, inputValue, callback) {
    clearTimeout(currentTimeout);

    if (inputValue.length >= 3) {
      setCurrentTimeout(
        setTimeout(() => {
          queryCaller({ variables: { name: inputValue } });
          setCurrentCallback(() => callback);
        }, 250)
      );
    } else {
      callback([]);
    }
  }

  return (
    <>
      <Formik
        initialValues={filters}
        onSubmit={(values, { resetForm }) => {
          applyFilter({ ...values, filterIsOpen: true });
        }}
        onReset={() => {
          applyFilter({ ...initialFilter, filterIsOpen: false });
          history.push('/purchase');
        }}
      >
        {({ values, handleSubmit, handleBlur, handleChange, setFieldValue, resetForm }) => (
          <>
            <Form>
              <div className="form-group row">
                <div className="col-lg-3">
                  <AsyncSelect
                    cacheOptions={true}
                    name="branch_uuid"
                    placeholder="Branch"
                    noOptionsMessage={({ inputValue }) =>
                      inputValue.length >= 3 ? 'No options' : 'Type 3 or more characters'
                    }
                    loadOptions={(inputValue, callback) =>
                      loadOptions(searchBranches, inputValue, callback)
                    }
                    defaultOptions={false}
                    value={
                      values.branch_label && values.branch_uuid
                        ? {
                            label: values.branch_label,
                            value: values.branch_uuid,
                          }
                        : null
                    }
                    onChange={(e) => {
                      // setBranchName(e.label);
                      setFieldValue('branch_label', e.label);
                      setFieldValue('branch_uuid', e);
                    }}
                  />
                  <small className="form-text ">
                    Cari <b>Branch</b>
                  </small>
                </div>
                <div className="col-lg-3">
                  <AsyncSelect
                    cacheOptions={true}
                    name="project_uuid"
                    placeholder="Project"
                    noOptionsMessage={({ inputValue }) =>
                      inputValue.length >= 3 ? 'No options' : 'Type 3 or more characters'
                    }
                    loadOptions={(inputValue, callback) =>
                      loadOptions(searchProjects, inputValue, callback)
                    }
                    defaultOptions={false}
                    value={
                      values.project_label && values.project_uuid
                        ? {
                            label: values.project_label,
                            value: values.project_uuid.value,
                          }
                        : null
                    }
                    onChange={(e) => {
                      // setProjectName(e.label);
                      setFieldValue('project_label', e.label);
                      setFieldValue('project_uuid', e);
                    }}
                  />
                  <small className="form-text ">
                    Cari <b>Project</b>
                  </small>
                </div>
                <div className="col-lg-3">
                  <AsyncSelect
                    cacheOptions={true}
                    name="supplier_uuid"
                    placeholder="Supplier"
                    noOptionsMessage={({ inputValue }) =>
                      inputValue.length >= 3 ? 'No options' : 'Type 3 or more characters'
                    }
                    loadOptions={(inputValue, callback) =>
                      loadOptions(searchSuppliers, inputValue, callback)
                    }
                    defaultOptions={false}
                    value={
                      values.supplier_label && values.supplier_uuid
                        ? {
                            label: values.supplier_label,
                            value: values.supplier_uuid.value,
                          }
                        : null
                    }
                    onChange={(e) => {
                      // setSupplierName(e.label);
                      setFieldValue('supplier_label', e.label);
                      setFieldValue('supplier_uuid', e);
                    }}
                  />
                  <small className="form-text ">
                    Cari <b>Supplier</b>
                  </small>
                </div>
                <div className="col-lg-3">
                  <AsyncSelect
                    cacheOptions={true}
                    name="product_uuid"
                    placeholder="Product"
                    noOptionsMessage={({ inputValue }) =>
                      inputValue.length >= 3 ? 'No options' : 'Type 3 or more characters'
                    }
                    loadOptions={(inputValue, callback) =>
                      loadOptions(searchProducts, inputValue, callback)
                    }
                    defaultOptions={false}
                    value={
                      values.product_label && values.product_uuid
                        ? {
                            label: values.product_label,
                            value: values.product_uuid.value,
                          }
                        : null
                    }
                    onChange={(e) => {
                      // setProductName(e);
                      setFieldValue('product_label', e.label);
                      setFieldValue('product_uuid', e.value);
                    }}
                  />
                  <small className="form-text ">
                    Cari <b>Product</b>
                  </small>
                </div>
                <div className="col-lg-3">
                  <input
                    type="text"
                    className="form-control"
                    name="product_sku"
                    placeholder="Product SKU"
                    onBlur={handleBlur}
                    value={values.product_sku}
                    onChange={handleChange}
                  />
                  <small className="form-text ">
                    Cari <b>Product SKU</b>
                  </small>
                </div>
                <div className="col-lg-3">
                  <input
                    type="text"
                    className="form-control"
                    name="description"
                    placeholder="Description"
                    onBlur={handleBlur}
                    value={values.description}
                    onChange={handleChange}
                  />
                  <small className="form-text ">
                    Cari <b>Deskripsi</b>
                  </small>
                </div>
                <div className="col-lg-3">
                  <DateRangePicker
                    small={true}
                    startDate={values.start_trx_date}
                    endDate={values.end_trx_date}
                    onDatesChange={({ startDate, endDate }) => {
                      setFieldValue('start_trx_date', startDate);
                      setFieldValue('end_trx_date', endDate);
                    }}
                    focusedInput={onFocused}
                    onFocusChange={(focusedInput) => setOnFocused(focusedInput)}
                    numberOfMonths={1}
                    isOutsideRange={() => false}
                    showClearDates={true}
                    startDateId="start_date"
                    endDateId="end_date"
                    hideKeyboardShortcutsPanel={true}
                  />
                  <small className="form-text  d-print-none">
                    Cari berdasarkan <b>Jarak Waktu</b>
                  </small>
                </div>
              </div>
              <button
                type="submit"
                onClick={handleSubmit}
                className="btn btn-primary btn-elevate float-right mb-20 ml-4"
              >
                Terapkan
              </button>
              <button
                type="button"
                onClick={() => {
                  resetForm();
                  handleSubmit();
                }}
                className="btn btn-danger btn-elevate float-right mb-20 ml-8"
              >
                Hapus Pencarian
              </button>
              <hr className="mt-25" />
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
