import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../../../../_metronic/_partials/controls";
import { StatusFormatter } from "../order-table/column-formatters";

export function OrderEditDialogHeader({ id }) {
  // Order Redux state
  const { orderForEdit, actionsLoading } = useSelector(
    (state) => ({
      orderForEdit: state.orders.orderForEdit,
      actionsLoading: state.orders.actionsLoading,
    }),
    shallowEqual
  );

  const [title, setTitle] = useState("");
  // Title couting
  useEffect(() => {
    let _title = id ? "" : "New Order";
    if (orderForEdit && id) {
      _title = `Order ${orderForEdit.invoice_no}`;
    }

    setTitle(_title);
    // eslint-disable-next-line
  }, [orderForEdit, actionsLoading]);

  return (
    <>
      {actionsLoading && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          {title}
          <span className="ml-4">
            {StatusFormatter(orderForEdit && orderForEdit.status)}
          </span>
        </Modal.Title>
      </Modal.Header>
    </>
  );
}
