// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useState, useEffect, useMemo } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../../_redux/voucherActions';
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
  toastOption,
} from '../../../../../_metronic/_helpers';
import * as uiHelpers from '../VoucherUIHelpers';
import * as columnFormatters from './column-formatters';
import { Pagination } from '../../../../../_metronic/_partials/controls';
import { useVoucherUIContext } from '../VoucherUIContext';
import { RoleChecker } from '../../../../RoleChecker';
import { ImagesModal } from '../../../../components/ImagesModal';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { VoucherEditDialog } from '../voucher-edit-dialog/VoucherEditDialog';
import { VoucherDisableDialog } from '../voucher-disable-dialog/VoucherDisableDialog';
import { setImageResolution } from '../../../../config/Helper';
import { toast } from 'react-toastify';

export function VoucherTable() {
  const allowDelete = RoleChecker('delete', 'voucher');
  const allowUpdate = RoleChecker('update', 'voucher');
  // Voucher UI Context
  const voucherUIContext = useVoucherUIContext();
  const voucherUIProps = useMemo(() => {
    return {
      ids: voucherUIContext.ids,
      setIds: voucherUIContext.setIds,
      queryParams: voucherUIContext.queryParams,
      setQueryParams: voucherUIContext.setQueryParams,
      openEditVoucherDialog: voucherUIContext.openEditVoucherDialog,
      openDeleteVoucherDialog: voucherUIContext.openDeleteVoucherDialog,
      openDisableVoucherDialog: voucherUIContext.openDisableVoucherDialog,
    };
  }, [voucherUIContext]);

  // Getting curret state of voucher list from store (Redux)
  const { currentState } = useSelector((state) => ({ currentState: state.voucher }), shallowEqual);
  const { totalCount, entities, listLoading } = currentState;

  const [showModal, setShowModal] = useState(false);
  const [currentImages, setCurrentImages] = useState(null);

  const [currentId, setCurrentId] = useState('');
  const [voucherModal, setVoucherModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    setShowModal(Boolean(currentImages));
  }, [currentImages]);

  // Voucher Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.findVoucher(voucherUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucherUIProps.queryParams, dispatch]);

  // Table columns
  const columns = [
    {
      dataField: 'name',
      text: 'Nama',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: 'code',
      text: 'Kode',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: 'type',
      text: 'Tipe',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: 'publish_status',
      text: 'Status',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: 'quota',
      text: 'Kuota',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cell, row) => (
        <span>
          {row.used_quota ?? 0}/{cell ?? 0}
        </span>
      ),
    },
    // {
    //   dataField: 'images',
    //   text: 'Images',
    //   sort: true,
    //   sortCaret: sortCaret,
    //   headerSortingClasses,
    //   formatter: (cell, row) => {
    //     const thumbnail = setImageResolution(cell[0].url, '512');

    //     const filterImage = cell.map((item) => {
    //       return { url: setImageResolution(item.url, '1080') };
    //     });

    //     return (
    //       <OverlayTrigger overlay={<Tooltip id="tooltip-top">Click For Detail Image!</Tooltip>}>
    //         <div style={{ width: '145px', height: '85px' }}>
    //           {cell instanceof Array && cell[0] && (
    //             <img
    //               style={{ objectFit: 'contain', cursor: 'pointer' }}
    //               onClick={() => {
    //                 setCurrentImages(filterImage);
    //               }}
    //               className="shadow rounded"
    //               src={thumbnail}
    //               alt="voucher"
    //               width="100%"
    //               height="100%"
    //             />
    //           )}
    //         </div>
    //       </OverlayTrigger>
    //     );
    //   },
    // },
    {
      dataField: 'action',
      text: 'Actions',
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditVoucherDialog: voucherUIProps.openEditVoucherDialog,
        openDisableVoucherDialog: voucherUIProps.openDisableVoucherDialog,
        allowDelete,
        allowUpdate,
        setCurrentId,
        setVoucherModal,
        setDeleteModal,
      },
      classes: 'text-right pr-0',
      headerClasses: 'text-right pr-3',
      style: {
        minWidth: '100px',
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: voucherUIProps.queryParams.page_size,
    page: voucherUIProps.queryParams.page_number,
  };

  return (
    <>
      <VoucherEditDialog
        show={voucherModal}
        onHide={() => {
          setVoucherModal(false);
          setCurrentId('');
        }}
        id={currentId}
      />
      <VoucherDisableDialog
        show={deleteModal}
        onHide={() => {
          setDeleteModal(false);
          setCurrentId('');
        }}
        id={currentId}
      />
      <ImagesModal show={showModal} images={currentImages} onHide={() => setCurrentImages(null)} />
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={listLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="uuid"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(voucherUIProps.setQueryParams)}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: voucherUIProps.ids,
                //   setIds: voucherUIProps.setIds,
                // })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
