import React, { useMemo, useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import { isEqual } from "lodash";
import { useNotificationUIContext } from "../NotificationUIContext";
import { initialFilter, STATUS_OPTIONS } from "../NotificationUIHelpers";
import ReactSelect from "react-select";
import { notification_type } from "../NotificationUIHelpers";
import { Input } from "../../../../../_metronic/_partials/controls";
import { SEARCH_MEMBER } from "../../../Member/_redux/member/memberCrud";
import { useLazyQuery } from "@apollo/client";
import AsyncSelect from "react-select/async";
import { useDispatch, useSelector } from "react-redux";
import { notificationFilter } from "../../_redux/notification/notificationActions";

const prepareFilter = (queryParams, values) => {
  const { title, type, type_label, status, member_uuid, member_name, filterIsOpen, status_label } = values;
  const newQueryParams = { ...queryParams };
  newQueryParams.title = title ?? "";
  newQueryParams.type_label = type_label ?? "";
  newQueryParams.type = type.value ?? "";
  newQueryParams.status_label = status_label ?? "";
  newQueryParams.status = status.value ?? "";
  newQueryParams.member_name = member_name ?? "";
  newQueryParams.member_uuid = member_uuid ?? "";
  newQueryParams.filterIsOpen = filterIsOpen
  // newQueryParams.notification_text = notification_text ?? "";
  // newQueryParams.notification_type = notification_type.value ?? "";

  return newQueryParams;
};

export function NotificationFilter({ listLoading, history }) {
  const { filters } = useSelector(state => state.notificationUser)
  const dispatch = useDispatch()
  // Notifications UI Context
  const notificationsUIContext = useNotificationUIContext();
  const notificationsUIProps = useMemo(() => {
    return {
      queryParams: notificationsUIContext.queryParams,
      setQueryParams: notificationsUIContext.setQueryParams,
    };
  }, [notificationsUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(
      notificationsUIProps.queryParams,
      values
    );
    if (!isEqual(newQueryParams, notificationsUIProps.queryParams)) {
      newQueryParams.page_number = 1;
      // update list by queryParams
      notificationsUIProps.setQueryParams(newQueryParams);
      dispatch(notificationFilter(newQueryParams))
    }
  };

  const [currentTimeout, setCurrentTimeout] = useState(null);
  const [currentCallback, setCurrentCallback] = useState(() => () => null);

  // * MEMBER
  const [searchMember] = useLazyQuery(SEARCH_MEMBER, {
    variables: {
      name: "",
    },
    onCompleted: ({ findMember: { items } }) => {
      currentCallback(
        items.map((member) => ({
          label: member.name,
          value: member.uuid,
          ...member,
        }))
      );
    },
    fetchPolicy: "cache-and-network",
  });

  function loadMemberOptions(inputValue, callback) {
    clearTimeout(currentTimeout);
    if (inputValue.length >= 1) {
      setCurrentTimeout(
        setTimeout(() => {
          searchMember({ variables: { name: inputValue } });
          setCurrentCallback(() => callback);
        }, 250)
      );
    } else {
      callback([]);
    }
  }

  return (
    <>
      <Formik
        initialValues={filters}
        onSubmit={(values) => {
          applyFilter({...values, filterIsOpen: true});
        }}
        onReset={(values) => {
          applyFilter({...initialFilter, filterIsOpen: false});
          history.push("/notification-user")
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
          resetForm,
        }) => (
          <>
            <Form onSubmit={handleSubmit} className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-4">
                  <Field
                    name="title"
                    component={Input}
                    isClearable
                    placeholder="Judul..."
                    onChange={(e) => {
                      setFieldValue("title", e.target.value);
                    }}
                  />
                  <small className="form-text ">
                    Cari <b>Judul</b>
                  </small>
                </div>
                <div className="col-lg-4">
                  <Field
                    name="type"
                    component={ReactSelect}
                    isClearable
                    placeholder="Pilih tipe..."
                    value={
                      values.type
                        ? {
                            label: values.type_label,
                            value: values.type.value,
                          }
                        : null
                    }
                    options={notification_type.map((item) => ({
                      value: item.value,
                      label: item.name,
                    }))}
                    onChange={(e) => {
                      setFieldValue("type_label", e.label);
                      setFieldValue("type", e);
                    }}
                  />
                  <small className="form-text ">
                    Cari <b>Tipe</b>
                  </small>
                </div>
                <div className="col-lg-4">
                  <Field
                    name="status"
                    component={ReactSelect}
                    isClearable
                    placeholder="Pilih status..."
                    value={
                      values.status
                        ? {
                            label: values.status_label,
                            value: values.status.value,
                          }
                        : null
                    }
                    options={STATUS_OPTIONS.map((item) => ({
                      value: item.value,
                      label: item.name,
                    }))}
                    onChange={(e) => {
                      setFieldValue("status_label", e.label);
                      setFieldValue("status", e);
                    }}
                  />
                  <small className="form-text ">
                    Cari <b>Status</b>
                  </small>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-4">
                  <AsyncSelect
                    id="member"
                    cacheOptions={false}
                    name="member_uuid"
                    placeholder="Cari Member..."
                    noOptionsMessage={({ inputValue }) =>
                      inputValue.length >= 3 ? (
                        <span>Member tidak ditemukan</span>
                      ) : (
                        <span>
                          Ketik 3 huruf atau lebih untuk mencari member
                        </span>
                      )
                    }
                    loadOptions={loadMemberOptions}
                    defaultOptions={false}
                    value={
                      values.member_name && values.member_uuid
                        ? {
                            label: values.member_name,
                            value: values.member_uuid,
                          }
                        : null
                    }
                    onChange={(option) => {
                      setFieldValue("member_uuid", option.value);
                      setFieldValue("member_name", option.label);
                    }}
                  />
                </div>
              </div>
              <button
                type="submit"
                onClick={handleSubmit}
                className="btn btn-primary btn-elevate float-right mb-20 ml-4"
              >
                Terapkan
              </button>
              <button
                type="button"
                onClick={() => {
                  resetForm();
                  handleSubmit();
                }}
                className="btn btn-danger btn-elevate float-right mb-20 ml-8"
              >
                Hapus Pencarian
              </button>
              <hr className="mt-25" />
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
