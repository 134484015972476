import React from 'react';
import { Route } from 'react-router-dom';
import { VoucherLoadingDialog } from './voucher-loading-dialog/VoucherLoadingDialog';
import { VoucherEditDialog } from './voucher-edit-dialog/VoucherEditDialog';
import { VoucherUIProvider } from './VoucherUIContext';
import { VoucherCard } from './VoucherCard';
import { VoucherDisableDialog } from './voucher-disable-dialog/VoucherDisableDialog';

export const VoucherPage = ({ history }) => {
  const voucherUIEvents = {
    newVoucherButtonClick: () => {
      history.push('/voucher/new');
    },
    openEditVoucherDialog: (id) => {
      history.push(`/voucher/${id}/edit`);
    },
    openDisableVoucherDialog: (id) => {
      history.push(`/voucher/${id}/disable`);
    },
    openFetchVoucherDialog: () => {
      history.push(`/voucher/fetch`);
    },
    openUpdateVoucherStatusDialog: () => {
      history.push('/voucher/updateStatus');
    },
  };

  return (
    <VoucherUIProvider voucherUIEvents={voucherUIEvents}>
      {/*<VoucherLoadingDialog />*/}
      <Route path="/voucher/new">
        {({ history, match }) => (
          <VoucherEditDialog
            show={match != null}
            onHide={() => {
              history.push('/voucher');
            }}
          />
        )}
      </Route>
      <Route path="/voucher/:id/edit">
        {({ history, match }) => (
          <VoucherEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push('/voucher');
            }}
          />
        )}
      </Route>
      <Route path="/voucher/:id/disable">
        {({ history, match }) => (
          <VoucherDisableDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push('/voucher');
            }}
          />
        )}
      </Route>
      <VoucherCard history={history} />
    </VoucherUIProvider>
  );
};
