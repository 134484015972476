import axios from 'axios';
import { API } from '../../../../config/UrlEndPoint';
import { gql, useMutation } from '@apollo/client';
import { QueryGraphql, MutationGraphql } from '../../../../config/ApoloRequest';
import { array } from 'prop-types';

export const USER_URL = `${API}master-data/admin/`;

export async function findOrderLogistics(queryParams) {
  const query = gql`
    query Q(
      $trx_date_start: String
      $trx_date_end: String
      $created_at_start: String
      $created_at_end: String
      $page_number: Int
      $page_size: Int
      $sort_field: String
      $sort_order: String
      $store_uuid: String
      $shipping_service_uuid: String
      $shipping_company_code: String
      $status_array: [String!]
    ) {
      findOrder(
        findOrderInput: {
          created_at_start: $created_at_start
          created_at_end: $created_at_end
          trx_date_start: $trx_date_start
          trx_date_end: $trx_date_end
          page_number: $page_number
          page_size: $page_size
          sort_field: $sort_field
          sort_order: $sort_order
          store_uuid: $store_uuid
          shipping_service_uuid: $shipping_service_uuid
          shipping_company_code: $shipping_company_code
          status_array: $status_array
        }
      ) {
        items {
          created_at
          trx_date
          uuid
          order_delivery {
            awb
            city
            province
            receiver_name
            shipping_cost
            real_shipping_cost
            shipping_service {
              company_code
              service
            }
          }
          status
          store {
            market_name
            uuid
            platform
          }
        }
        total_items
        total_real_shipping_cost
        total_shipping_cost
      }
    }
  `;
  const newResponse = await QueryGraphql(query, queryParams);

  return newResponse.data.findOrder;
}

export const FIND_ORDER = gql`
  query Q(
    $created_at_start: String
    $created_at_end: String
    $trx_date_start: String
    $trx_date_end: String
    $page_number: Int
    $page_size: Int
    $sort_field: String
    $sort_order: String
    $shipping_service_uuid: String
    $shipping_company_code: String
  ) {
    findOrder(
      findOrderInput: {
        created_at_start: $created_at_start
        created_at_end: $created_at_end
        trx_date_start: $trx_date_start
        trx_date_end: $trx_date_end
        page_number: $page_number
        page_size: $page_size
        sort_field: $sort_field
        sort_order: $sort_order
        shipping_service_uuid: $shipping_service_uuid
        shipping_company_code: $shipping_company_code
      }
    ) {
      items {
        created_at
        trx_date
        uuid
        order_delivery {
          awb
          city
          province
          receiver_name
          shipping_cost
          real_shipping_cost
          shipping_service {
            company_code
            service
          }
        }
        store {
          market_name
          uuid
          platform
        }
      }
      total_items
      total_real_shipping_cost
      total_shipping_cost
    }
  }
`;

// CREATE =>  POST: add a new customer to the server
// export async function createAdmin(payload) {
//   const query = gql`

//   `;
//   console.log(query);
//   const newResponse = await MutationGraphql(query);
//   console.log(newResponse.data.createBranch);
//   return newResponse.data.createBranch;
// }

export async function createOrderLogistic(payload) {
  const query = gql`
    mutation {
      createAdmin(
        createAdminInput: {
          email: "${payload.email}"
          password: "${payload.password}"
          branches: ${JSON.stringify(payload.branches)}
          name: "${payload.name}"
          role: "${payload.role}"
        }
      ) {
        name
        email
        hp
        role
      }
    }
  `;
  // return;
  const newResponse = await MutationGraphql(query);
  return newResponse.data.createAdmin;
}

// GET ALL
export function getAllAdmins() {
  return axios.get(USER_URL);
}

// GET SINGLE USER
export async function getOrderLogisticById(id) {
  const query = gql`
    {
      getOrderLogistic(uuid: "${id}") {
        uuid
        status
        trx_date
        updated_at
        is_online
        invoice_no
        created_at
        is_dropship
        dropship_name
        dropship_hp
        store{
          uuid
          platform
          market_name
        }
        marketplace_invoice
        branch {
          city
          name
          province
          uuid
        }
        member {
          name
          email
          hp
          uuid
          member_level_code
        }
        orderLogistic_delivery {
          awb
          address
          codepos
          shipping_cost
          city
          province
          sub_district
          receiver_name
          receiver_hp
          insurance_amount
          real_shipping_cost
          shipping_service {
            code
            company_code
            service
          }
        }
        image_confirmation {
          url
        }
        orderLogistic_logs {
          status
          uuid
          created_at
          description
        }
        orderLogistic_payment {
          uuid
          amount
          pg_trx_id
          payment_method {
            code
            name
            type
          }
      }
      orderLogistic_item {
        assigned_price
        notes
        product_sku_uuid
        product_sku_snapshot
        product_snapshot
        qty
        product_sku {
          sku
          product {
            weight
          }
        }
        uuid
      }
      }
    }
  `;

  const newResponse = await QueryGraphql(query);
  return newResponse.data.getOrderLogistic;
}

export const UPDATE_ORDER_ITEM = gql`
  mutation Mutation(
    $orderLogisticItemInput: [CreateOrderLogisticItemInput!]!
    $orderLogistic_items_uuid: [String!]!
    $orderLogistic_uuid: String!
    $trx_date: DateTime
    $awb: String
    $shipping_insurance_amount: Float
    $real_shipping_cost: Float
  ) {
    createOrderLogisticItem(createOrderLogisticItemInput: $orderLogisticItemInput) {
      qty
      orderLogistic_uuid
      uuid
    }
    deleteOrderLogisticItem(
      orderLogistic_item_uuids: $orderLogistic_items_uuid
      orderLogistic_uuid: $orderLogistic_uuid
    ) {
      message
    }
    updateOrderLogistic(
      updateOrderLogisticInput: {
        uuid: $orderLogistic_uuid
        awb: $awb
        trx_date: $trx_date
        real_shipping_cost: $real_shipping_cost
        shipping_insurance_amount: $shipping_insurance_amount
      }
    ) {
      orderLogistic_delivery {
        awb
      }
    }
  }
`;

export const ORDER_RECEIPT = gql`
  query Query($uuid: String!) {
    getOrderLogistic(uuid: $uuid) {
      uuid
      status
      trx_date
      updated_at
      is_online
      invoice_no
      created_at
      is_dropship
      dropship_hp
      dropship_name
      branch {
        city
        name
        province
        uuid
      }
      member {
        name
        email
        hp
      }
      orderLogistic_delivery {
        awb
        address
        codepos
        shipping_cost
        city
        province
        sub_district
        receiver_name
        receiver_hp
      }
      orderLogistic_item {
        notes
        assigned_price
        product_sku_snapshot
        product_snapshot
        product_sku {
          price_before
          sku
          price
          product {
            name
            weight
          }
        }
        qty
        uuid
      }
    }
  }
`;

// GET USER WITH CONDITION
// export function findAdmins(queryParams) {
//     return axios.post(`${USER_URL}find`, queryParams);
// }

export async function updateOrderLogistic(admin) {
  let password = admin.password ? `password: "${admin.password}"` : '';
  const stringQuery = `{
    uuid: "${admin.uuid}"
    name: "${admin.name}"
    email: "${admin.email}"
    ${password}
    role: "${admin.role}"
    hp: "${admin.hp}"
    branches: ${JSON.stringify(admin.branches)}
  }`;
  const query = gql`
  mutation {
    updateAdmin(
      updateAdminInput: ${stringQuery}
    ),{
      name
    }
  }
  `;
  const adminData = await MutationGraphql(query);
  console.log(adminData);
  return adminData.data.updateAdmin;
}

export async function deleteAdmin(id) {
  const query = gql`
    mutation {
      deleteAdmin(uuid: "${id}") {
        name
      }
    }
  `;
  const adminData = await MutationGraphql(query);
  return adminData.data.deleteAdmin;
}

export const CREATE_ORDER = gql`
  mutation Mutation(
    $branch_uuid: String
    $is_online: Boolean
    $member_uuid: String
    $trx_date: DateTime!
    $orderLogistic_delivery: OrderLogisticDeliveryInput
    $orderLogistic_items: [EditOrderLogisticItems!]!
    $orderLogistic_payment: OrderLogisticPaymentInput!
    $store_uuid: String
    $marketplace_invoice: String
    $is_dropship: Boolean
    $dropship_name: String
    $dropship_hp: String
  ) {
    createOrderLogistic(
      createOrderLogisticInput: {
        branch_uuid: $branch_uuid
        is_online: $is_online
        trx_date: $trx_date
        store_uuid: $store_uuid
        member_uuid: $member_uuid
        marketplace_invoice: $marketplace_invoice
        orderLogistic_delivery: $orderLogistic_delivery
        orderLogistic_items: $orderLogistic_items
        orderLogistic_payment: $orderLogistic_payment
        is_dropship: $is_dropship
        dropship_name: $dropship_name
        dropship_hp: $dropship_hp
      }
    ) {
      status
      is_online
    }
  }
`;

export const UPDATE_ORDER = gql`
  mutation Mutation($uuid: String!, $awb: String, $status: String) {
    updateOrderLogistic(updateOrderLogisticInput: { uuid: $uuid, awb: $awb, status: $status }) {
      status
      invoice_no
    }
  }
`;

export const GET_DAILY_SALES = gql`
  query Query(
    $date_start: String
    $date_end: String
    $sort_field: String
    $sort_orderLogistic: String
  ) {
    getDailySalesByDateRange(
      getSalesByDateRangeInput: {
        date_start: $date_start
        date_end: $date_end
        sort_field: $sort_field
        sort_orderLogistic: $sort_orderLogistic
      }
    ) {
      items {
        total_orderLogistic
        total_amount
        trx_date_time
      }
    }
  }
`;

export const GET_MONTHLY_SALES = gql`
  query Query(
    $date_start: String
    $date_end: String
    $sort_field: String
    $sort_orderLogistic: String
  ) {
    getMonthlySalesByDateRange(
      getSalesByDateRangeInput: {
        date_start: $date_start
        date_end: $date_end
        sort_field: $sort_field
        sort_orderLogistic: $sort_orderLogistic
      }
    ) {
      items {
        total_orderLogistic
        total_amount
        trx_date_month
      }
    }
  }
`;

export const FIND_ORDER_LOG = gql`
  query Query($orderLogistic_uuid: String!) {
    findOrderLogisticLog(findOrderLogisticInput: { orderLogistic_uuid: $orderLogistic_uuid }) {
      items {
        status
        uuid
        created_at
      }
    }
  }
`;

export const FETCH_TOKO = gql`
  query FindStore($is_active: Boolean, $is_online: Boolean) {
    findStore(findStoreInput: { is_active: $is_active, is_online: $is_online }) {
      items {
        is_online
        is_active
        market_name
        platform
        uuid
      }
      total_items
      total_pages
    }
  }
`;

export const GET_ORDER_STATUS = gql`
  {
    __typename
    getOrderLogisticStatusGroup {
      items {
        count
        status
      }
    }
  }
`;

export const GET_ADDRESS = gql`
  query Q($keyword: String!) {
    __typename
    findAddressSuggestion(keyword: $keyword) {
      items {
        city
        district
        postal_codes
        province_name
      }
    }
  }
`;
