import { Field, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { Input, Pagination } from "../../../../../_metronic/_partials/controls";
import * as Yup from "yup";
import { gql } from "@apollo/client";
import { useLazyQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";

const FIND_PRODUCT_SKU = gql`
  query Q($product_name_or_sku: String) {
    findProduct(
      findProductInput: {
        product_name_or_sku: $product_name_or_sku
        page_number: 1
        page_size: 100
        show_empty_stock: true
      }
    ) {
      items {
        uuid
        name
        product_sku {
          price
          sku
          uuid
          variant_value_1
          variant_value_2
        }
      }
      total_items
    }
  }
`;

export default function ProductPurchaseModal({
  show,
  onHide,
  initValue,
  onClick,
  addProduct,
}) {
  const inputSearchRef = useRef();
  const formikRef = useRef();
  const [currentTimeout, setCurrentTimeout] = useState(null);
  const [currentCallback, setCurrentCallback] = useState(null);
  const [currentProducts, setCurrentProducts] = useState([]);

  useEffect(() => {
    if (show) {
      setCurrentProducts([]);
      inputSearchRef.current.focus();
      formikRef.current.resetForm();
    }
  }, [show]);

  const [fetchProductOptions, { loading: fetchLoading }] = useLazyQuery(
    FIND_PRODUCT_SKU,
    {
      onCompleted: (data) => {
        if (!data) return;
        const {
          findProduct: { items },
        } = data;

        const skus = items.reduce(
          (prev, { name, product_sku }) => [
            ...prev,
            ...product_sku.map(
              ({ sku, uuid, variant_value_1, variant_value_2, price }) => ({
                name: `${name} ${
                  variant_value_1 ? "- " + variant_value_1 : ""
                } ${variant_value_2 ? "- " + variant_value_2 : ""}`,
                uuid,
                price,
                sku,
                qty: 1,
              })
            ),
          ],
          []
        );

        setCurrentProducts(skus);
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const loadProductOptions = (inputValue) => {
    clearTimeout(currentTimeout);
    if (inputValue.length >= 3) {
      setCurrentTimeout(
        setTimeout(() => {
          fetchProductOptions({
            variables: { product_name_or_sku: inputValue },
          });
        }, 350)
      );
    } else {
      setCurrentProducts([]);
    }
  };

  const onChangeChecked = (e, item, index, setFieldValue) => {
    if (e.target.checked) {
      setFieldValue(`name_${index}`, item.name);
      setFieldValue(`sku_${index}`, item.sku);
      setFieldValue(`price_${index}`, item.price);
      setFieldValue(`qty_${index}`, item.qty);
      setFieldValue(`uuid_${index}`, item.uuid);
    } else {
      setFieldValue(`name_${index}`, null);
      setFieldValue(`sku_${index}`, null);
      setFieldValue(`price_${index}`, null);
      setFieldValue(`qty_${index}`, null);
      setFieldValue(`uuid_${index}`, null);
    }
  };

  const processData = (data) => {
    currentProducts.forEach((product, index) => {
      if (data[`uuid_${index}`]) {
        let item = {};
        item["product_sku_uuid"] = data[`uuid_${index}`];
        item["product_name"] = data[`name_${index}`];
        item["sku"] = data[`sku_${index}`];
        item["price"] = data[`price_${index}`];
        item["quantity"] = data[`qty_${index}`];
        addProduct(item);
        // console.log("item", item);
      }
    });
  };

  const checkAllFunction = (e, setFieldValue) => {
    let newArr = [];
    currentProducts.forEach((item, i) => {
      if (e.target.checked) {
        // newArr.push(i);
        document.getElementById(`checkBox${i}`).checked = e.target.checked;
        setFieldValue(`name_${i}`, item.name);
        setFieldValue(`sku_${i}`, item.sku);
        setFieldValue(`price_${i}`, item.price);
        setFieldValue(`qty_${i}`, item.qty);
        setFieldValue(`uuid_${i}`, item.uuid);
      } else {
        // newArr = [];
        document.getElementById(`checkBox${i}`).checked = e.target.checked;
        setFieldValue(`name_${i}`, null);
        setFieldValue(`sku_${i}`, null);
        setFieldValue(`price_${i}`, null);
        setFieldValue(`qty_${i}`, null);
        setFieldValue(`uuid_${i}`, null);
      }
    });
  };

  return (
    <>
      <Formik
        innerRef={formikRef}
        enableReinitialize={true}
        // validationSchema={schema}
        initialValues={{ product_name_or_sku: "" }}
        onSubmit={(values) => {
          // console.log(values);
          processData(values);
        }}
      >
        {({ handleSubmit, values, setFieldValue }) => (
          <>
            <Modal
              size="xl"
              show={show}
              onHide={onHide}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                  Print Label
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="overlay overlay-block cursor-default">
                <div className="row">
                  <div className="col-lg-4">
                    <input
                      ref={inputSearchRef}
                      className="form-control"
                      label="Produk / SKU"
                      name="product_name_or_sku"
                      placeholder="Nama produk /sku"
                      // component={Input}
                      onChange={(e) => {
                        loadProductOptions(e.target.value);
                        setFieldValue("product_name_or_sku", e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    {fetchLoading ? (
                      <>
                        <CircularProgress />
                      </>
                    ) : (
                      <Table>
                        <thead>
                          <th>
                            <input
                              type="checkbox"
                              onChange={(e) => {
                                checkAllFunction(e, setFieldValue);
                              }}
                            />
                          </th>
                          <th>Nama</th>
                          <th>SKU</th>
                          <th>Harga</th>
                          <th>Qty</th>
                        </thead>
                        {currentProducts.map((item, index) => (
                          <>
                            <tbody>
                              <tr>
                                <td>
                                  <input
                                    id={`checkBox${index}`}
                                    type="checkbox"
                                    onChange={(e) => {
                                      onChangeChecked(
                                        e,
                                        item,
                                        index,
                                        setFieldValue
                                      );
                                    }}
                                  />
                                </td>
                                <td>{item.name}</td>
                                <td>{item.sku}</td>
                                <td>
                                  <input
                                    name={`price_${index}`}
                                    placeholder="Masukan Harga"
                                    className="form-control"
                                    defaultValue={item.price}
                                    // component={Input}
                                    onChange={(e) => {
                                      setFieldValue(
                                        `price_${index}`,
                                        +e.target.value
                                      );
                                    }}
                                  />
                                </td>
                                <td>
                                  <input
                                    name={`qty_${index}`}
                                    placeholder="Masukan qty"
                                    className="form-control"
                                    defaultValue={item.qty}
                                    // component={Input}
                                    onChange={(e) => {
                                      setFieldValue(
                                        `qty_${index}`,
                                        +e.target.value
                                      );
                                    }}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </>
                        ))}
                      </Table>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-primary" onClick={handleSubmit}>
                  Tambah
                </button>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </Formik>
    </>
  );
}
