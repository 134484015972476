import React, { useEffect, useMemo, useRef, useState } from "react";
import { Formik, Field, Form } from "formik";
import { isEqual } from "lodash";
import { useProductUIContext } from "../ProductUIContext";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import { initialFilter, PUBLISH_STATUS } from "../ProductUIHelpers";
import ReactSelect from "react-select";
import { FIND_BRAND } from "../../../MasterData/_redux/brand/brandCrud";
import { useLazyQuery } from "@apollo/client";
import AsyncSelect from "react-select/async";
import { Input, DatePicker, Cascader } from "antd";
import "antd/dist/antd.css";
import { findCategorys } from "../../../MasterData/_redux/category/categoryCrud";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { productFilter } from "../../_redux/products/productActions";

const prepareFilter = (queryParams, values) => {
  const {
    product_name_or_sku,
    name,
    slug,
    brand_name,
    brand_uuid,
    category_name,
    category_uuid,
    weight_start,
    weight_end,
    description,
    stock_start,
    stock_end,
    created_at_start,
    created_at_end,
    dateValue,
    publish_status,
    publish_status_label,
    filterIsOpen,
  } = values;
  const newQueryParams = { ...queryParams };
  // Filter by status
  // newQueryParams.name = name ?? "";
  newQueryParams.product_name_or_sku = product_name_or_sku ?? "";
  newQueryParams.slug = slug ?? "";
  newQueryParams.brand_name = brand_name ?? "";
  newQueryParams.brand_uuid = brand_uuid ?? "";
  newQueryParams.category_name = category_name ?? "";
  newQueryParams.category_uuid = category_uuid[0] ?? "";
  newQueryParams.weight_start = weight_start ?? null;
  newQueryParams.weight_end = weight_end ?? null;
  newQueryParams.stock_start = stock_start ?? null;
  newQueryParams.stock_end = stock_end ?? null;
  newQueryParams.created_at_start = created_at_start ?? null;
  newQueryParams.created_at_end = created_at_end ?? null;
  newQueryParams.dateValue = dateValue ?? null;
  newQueryParams.description = description ?? "";
  newQueryParams.publish_status = publish_status.value ?? "";
  newQueryParams.publish_status_label = publish_status_label ?? "";
  newQueryParams.filterIsOpen = filterIsOpen ?? "";

  return newQueryParams;
};

export function ProductFilter({ listLoading, history }) {
  const { filters } = useSelector(state => state.product)
  const dispatch = useDispatch()
  const [currentTimeout, setCurrentTimeout] = useState(null);
  const [currentCallback, setCurrentCallback] = useState(null);

  // Product UI Context
  const productUIContext = useProductUIContext();
  const productUIProps = useMemo(() => {
    return {
      queryParams: productUIContext.queryParams,
      setQueryParams: productUIContext.setQueryParams,
    };
  }, [productUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(productUIProps.queryParams, values);
    if (!isEqual(newQueryParams, productUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      productUIProps.setQueryParams(newQueryParams);
      dispatch(productFilter(newQueryParams))
    }
  };

  const [searchBrand] = useLazyQuery(FIND_BRAND, {
    variables: {
      name: "",
    },
    onCompleted: (data) => {
      if (!data) return;

      const { findBrand } = data || {};
      const { items = [] } = findBrand;

      if (items instanceof Array) {
        currentCallback(
          items.map(({ uuid, name }) => ({
            label: name,
            value: uuid,
          }))
        );
      } else {
      }
    },
    fetchPolicy: "cache-and-network",
  });

  function loadBrandOptions(inputValue, callback) {
    clearTimeout(currentTimeout);

    if (inputValue.length >= 1) {
      setCurrentTimeout(
        setTimeout(() => {
          searchBrand({ variables: { name: inputValue } });
          setCurrentCallback(() => callback);
        }, 250)
      );
    } else {
      callback([]);
    }
  }

  // * Category

  const [category, setCategory] = useState([]);

  const generateCategory = (categoryChildren) => {
    const categories = [];
    for (const item of categoryChildren) {
      let newCategory = {
        label: item.name,
        value: item.uuid,
      };
      if (item.children) newCategory.children = generateCategory(item.children);
      categories.push(newCategory);
    }
    return categories;
  };

  useEffect(() => {
    findCategorys().then((response) => {
      setCategory(generateCategory(response.children));
    });
  }, []);

  const weightStartRef = useRef()
  const weightEndRef = useRef()

  const stockStartRef = useRef()
  const stockEndRef = useRef()

  return (
    <>
      <Formik
        initialValues={filters}
        onSubmit={(values) => {
          applyFilter({...values, filterIsOpen: true});
        }}
        onReset={() => {
          applyFilter({...initialFilter, filterIsOpen: false});
          history.push("/product")
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
          resetForm,
        }) => (
          <>
            <Form onSubmit={handleSubmit} className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-4">
                  <input
                    type="text"
                    className="form-control"
                    // name="name"
                    name="product_name_or_sku"
                    placeholder="Cari Nama Produk / SKU"
                    onBlur={handleBlur}
                    value={values.product_name_or_sku}
                    onChange={(e) => {
                      // setFieldValue("name", e.target.value);
                      setFieldValue("product_name_or_sku", e.target.value);
                    }}
                  />
                  <small className="form-text">
                    Cari <b>Nama Produk / SKU</b>
                  </small>
                </div>
                <div className="col-lg-4">
                  <input
                    type="text"
                    className="form-control"
                    name="slug"
                    placeholder="Cari Slug"
                    onBlur={handleBlur}
                    value={values.slug}
                    onChange={(e) => {
                      setFieldValue("slug", e.target.value);
                    }}
                  />
                  <small className="form-text">
                    Cari <b>Slug</b>
                  </small>
                </div>
                <div className="col-lg-4">
                  <AsyncSelect
                    id="brand"
                    cacheOptions={false}
                    name="brand_uuid"
                    placeholder="Cari Brand..."
                    noOptionsMessage={({ inputValue }) =>
                      inputValue.length >= 1
                        ? "No options"
                        : "Ketik untuk mencari brand"
                    }
                    loadOptions={loadBrandOptions}
                    value={
                      values.brand_name && values.brand_uuid
                        ? {
                            label: values.brand_name,
                            value: values.brand_uuid,
                          }
                        : null
                    }
                    onChange={(e) => {
                      setFieldValue("brand", e);
                      setFieldValue("brand_uuid", e.value);
                      setFieldValue("brand_name", e.label);
                    }}
                  />
                  <small className="form-text">
                    Cari <b>Brand</b>
                  </small>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-4">
                  <Cascader
                    options={category}
                    onClear={() => setFieldValue("category_uuid", "")}
                    name="category"
                    changeOnSelect
                    style={{ width: "100%" }}
                    value={values.category_name}
                    onChange={(e) => {
                      setFieldValue("category_name", e);
                      const value = e && e.slice(-1);
                      setFieldValue("category_uuid", value);
                    }}
                  />
                  <small className="form-text">
                    Cari <b>Kategori</b>
                  </small>
                </div>
                <div className="col-lg-4">
                  {/* <Input.Group compact> */}
                  <div className="row no-gutters">
                    <div className="col-5 ">
                      <Input
                        style={{ textAlign: "center" }}
                        ref={weightStartRef}
                        onFocus={() => weightStartRef.current.select()}
                        placeholder="Lebih Dari"
                        onChange={(e) =>
                          setFieldValue(
                            "weight_start",
                            parseInt(e.target.value)
                          )
                        }
                        value={values.weight_start}
                      />
                    </div>
                    <div className="col-2">
                      <Input
                        // className="site-input-split"
                        style={{
                          pointerEvents: "none",
                          textAlign: "center",
                        }}
                        placeholder="~"
                        disabled
                      />
                    </div>
                    <div className="col-5">
                      <Input
                        className="site-input-right"
                        ref={weightEndRef}
                        onFocus={() => weightEndRef.current.select()}
                        style={{
                          textAlign: "center",
                        }}
                        placeholder="Kurang Dari"
                        onChange={(e) =>
                          setFieldValue("weight_end", parseInt(e.target.value))
                        }
                        value={values.weight_end}
                      />
                    </div>
                  </div>
                  {/* </Input.Group> */}
                  <small className="form-text">
                    Cari <b>Berat (Gram)</b>
                  </small>
                </div>
                <div className="col-lg-4">
                  <textarea
                    name="description"
                    className="form-control"
                    placeholder="Cari deskripsi"
                    onChange={(e) =>
                      setFieldValue("description", e.target.value)
                    }
                    value={values.description}
                  />
                </div>
                {/* <div className="col-lg-4">
                  <DatePicker.RangePicker style={{ width: "70%" }} />
                  <small className="form-text">
                    Cari <b>Tanggal</b>
                  </small>
                </div> */}
              </div>
              <div className="form-group row">
                <div className="col-lg-4">
                  {/* <Input.Group compact> */}
                  <div className="row no-gutters">
                    <div className="col-5">
                      <Input
                        style={{ textAlign: "center" }}
                        placeholder="Lebih Dari"
                        ref={stockStartRef}
                        onFocus={() => stockStartRef.current.select()}
                        onChange={(e) =>
                          setFieldValue("stock_start", parseInt(e.target.value))
                        }
                        value={values.stock_start}
                      />
                    </div>
                    <div className="col-2">
                      <Input
                        className="site-input-split"
                        style={{
                          // width: 30,
                          textAlign: "center",
                          borderLeft: 0,
                          borderRight: 0,
                          pointerEvents: "none",
                        }}
                        placeholder="~"
                        disabled
                      />
                    </div>
                    <div className="col-5">
                      <Input
                        className="site-input-right"
                        style={{
                          // width: 150,
                          textAlign: "center",
                        }}
                        ref={stockEndRef}
                        onFocus={() => stockEndRef.current.select()}
                        placeholder="Kurang Dari"
                        onChange={(e) =>
                          setFieldValue("stock_end", parseInt(e.target.value))
                        }
                        value={values.stock_end}
                      />
                    </div>
                  </div>

                  {/* </Input.Group> */}
                  <small className="form-text">
                    Cari <b>Stock SKU</b>
                  </small>
                </div>
                <div className="col-lg-4">
                  <DatePicker.RangePicker
                    style={{ width: "70%" }}
                    value={values.dateValue}
                    onChange={(e) =>
                      e &&
                      e.map((time, index) => {
                        setFieldValue("dateValue", e);
                        index === 0
                          ? setFieldValue(
                              "created_at_start",
                              time
                                .hour(0)
                                .minute(0)
                                .second(0)
                                .format("YYYY-MM-DD HH:mm:ss")
                            )
                          : setFieldValue(
                              "created_at_end",
                              time
                                .hour(23)
                                .minute(59)
                                .second(59)
                                .format("YYYY-MM-DD HH:mm:ss")
                            );
                      })
                    }
                  />
                  <small className="form-text">
                    Cari <b>Tanggal</b>
                  </small>
                </div>
                <div className="col-lg-4">
                  <Field
                    name="publish_status"
                    id="publish_status"
                    value={
                      values.publish_status_label && values.publish_status
                        ? {
                            label: values.publish_status_label,
                            value: values.publish_status.value,
                          }
                        : null
                    }
                    options={PUBLISH_STATUS.map((item) => ({
                      value: item.value,
                      label: item.name,
                    }))}
                    component={ReactSelect}
                    placeholder="Cari Publish Status..."
                    label="publish_status"
                    onChange={(e) => {
                      setFieldValue('publish_status_label', e.label);
                      setFieldValue("publish_status", e);
                    }}
                  />
                  <small className="form-text ">
                    Cari <b>Publish Status</b>
                  </small>
                </div>
              </div>
              <button
                type="submit"
                onClick={handleSubmit}
                className="btn btn-primary btn-elevate float-right mb-20 ml-4"
              >
                Terapkan
              </button>
              <button
                type="button"
                onClick={() => {
                  resetForm();
                  handleSubmit();
                }}
                className="btn btn-danger btn-elevate float-right mb-20 ml-8"
              >
                Hapus Pencarian
              </button>
              <hr className="mt-25" />
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
