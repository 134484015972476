import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../../_redux/products/productActions';
import { toast } from 'react-toastify';
import { toastOption } from '../../../../../_metronic/_helpers';
import { useMutation } from '@apollo/client';
import { LoadingAnimation } from '../loading-animation/LoadingAnimation';
import { Product } from './Product';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderToolbar,
} from '../../../../../_metronic/_partials/controls';
import { ImageProduct } from './Image';
// import { ImageProduct } from "./Image2";
import { VariantSku } from './Variant-Sku';
import {
  CREATE_PRODUCT,
  CREATE_IMAGE,
  CREATE_SKU_VARIANT,
  UPDATE_PRODUCT,
  UPDATE_IMAGE,
  UPDATE_SKU_VARIANT,
  SKU_IMAGE,
  DELETE_PRODUCT_IMAGE,
  UPDATE_IMAGE_SORT,
  UPDATE_SKU_IMAGE,
  DELETE_SKU_IMAGE,
} from '../../_redux/products/productCrud';
import { ValuesOfCorrectTypeRule } from 'graphql';
import PrintLabelModal from './PrintLabelModal';
import https from 'https';
import { API_URL } from '../../../../config/UrlEndPoint';

export function ProductEdit({ id, history, match }) {
  // * Back To List
  const backToList = () => {
    history.push('/product');
  };

  // Product Redux state
  const dispatch = useDispatch();
  const { actionsLoading, productForEdit } = useSelector(
    (state) => ({
      // actionsLoading: state.product.actionsLoading,
      productForEdit: state.product.productForEdit,
    }),
    shallowEqual
  );

  // console.log(productForEdit);

  // ! FOR INITIAL PRODUCT VALUE
  const generateCategory = (categoryChildren) => {
    const categories = [];
    for (const item of categoryChildren) {
      categories.push(item.categoryjoin.uuid);
    }
    return categories;
  };

  const productInit = () => {
    if (productForEdit) {
      return {
        name: productForEdit.name,
        brand: {
          label: productForEdit.brand.name,
          value: productForEdit.brand.uuid,
        },
        description: productForEdit.description,
        category: generateCategory(productForEdit.category),
        publish_status: {
          label: productForEdit.publish_status,
          value: productForEdit.publish_status,
        },
        weight: productForEdit.weight,
      };
    }
  };

  // ! FOR INITIAL SKU VALUE
  const generateSku = () => {
    let initSkuVariant = {};
    const skuArr = [];
    if (productForEdit) {
      for (const [index, item] of productForEdit.product_sku.entries()) {
        let newSku = {};

        newSku[`price`] = item.price;
        newSku[`priceBefore`] = item.price_before;
        newSku[`stock`] = item.stock;
        newSku[`sku`] = item.sku;
        newSku[`variant_uuid_1`] = item.variant_uuid_1;
        newSku[`variant_uuid_2`] = item.variant_uuid_2;
        newSku[`variant_value_1`] = item.variant_value_1;
        newSku[`variant_value_2`] = item.variant_value_2;
        newSku[`image`] = item.image;
        newSku[`uuid`] = item.uuid;
        // if (item.children) newSku.children = generateCategory(item.children);
        skuArr.push(newSku);
      }
      initSkuVariant = {
        sku: skuArr,
        variant1: productForEdit.product_variant[0] && productForEdit.product_variant[0].name,
        variant2: productForEdit.product_variant[1] && productForEdit.product_variant[1].name,
        product_variant: productForEdit.product_variant,
      };
    }
    return initSkuVariant;
  };

  useEffect(() => {
    // server call for getting Product by id
    dispatch(actions.fetchProduct(match.params.id));
  }, [id, dispatch]);

  // ! PRODUCT
  // * UPDATE PRODUCT
  const [updateProduct, { loading: updateProductLoading }] = useMutation(UPDATE_PRODUCT, {
    onCompleted: () => {
      toast.success('Berhasil update product!', toastOption);
      dispatch(actions.fetchProduct(match.params.id));
    },
    onError: (error) => {
      toast.error('Gagal update product', toastOption);
      console.log(error);
    },
  });

  // ! IMAGE
  // * CREATE IMAGE
  const [createImage, { loading: createImageLoading }] = useMutation(CREATE_IMAGE, {
    onCompleted: () => {
      toast.success('Berhasil menambahkan foto!', toastOption);
      history.push(`/product/${match.params.id}/edit`);
    },
    onError: (error) => {
      toast.error('Gagal menambahkan foto!', toastOption);
      console.log(error);
    },
  });

  // * UPDATE IMAGE
  const [updateImage, { loading: updateImageLoading }] = useMutation(UPDATE_IMAGE, {
    onCompleted: () => {
      toast.success('Berhasil update foto!', toastOption);
      dispatch(actions.fetchProduct(match.params.id));
    },
    onError: (error) => {
      toast.error('Gagal update foto!', toastOption);
      console.log(error);
    },
  });

  const [deleteImage, { loading: deleteImageLoading }] = useMutation(DELETE_PRODUCT_IMAGE, {
    onCompleted: () => {
      toast.success('Berhasil menghapus foto!', toastOption);
      dispatch(actions.fetchProduct(match.params.id));
    },
    onError: (error) => {
      console.log(error);
      toast.error('Gagal menghapus foto!', toastOption);
    },
  });

  let indexImage = 0;
  let imagesLength = 0;
  let images;
  const dropzoneUpload = (vImages) => {
    images = vImages;
    imagesLength = vImages.length;
    uploadDropzone();
  };
  function uploadDropzone() {
    indexImage++;
    let data = {};
    data[`image${indexImage}`] = images[indexImage - 1];
    createImage({
      variables: {
        uuid: match.params.id,
        ...data,
      },
    }).then(() => {
      if (indexImage < imagesLength) {
        uploadDropzone();
      }
    });
  }

  const deleteProductImage = (image) => {
    deleteImage({
      variables: {
        uuid: image,
        product_uuid: productForEdit.uuid,
      },
    });
  };

  const [updateImageSort, { loading: updateSortLoading }] = useMutation(UPDATE_IMAGE_SORT, {
    onCompleted: (data) => {
      // toast.success("OK! 👌🏻", toastOption);
      toast.success('Berhasil mengubah urutan gambar!', toastOption);
      history.push(`/product/${match.params.id}/edit`);
    },
    onError: (err) => {
      console.log(err);
      toast.error('Gagal mengubah urutan gambar!', toastOption);
    },
  });

  const changeSortImage = (value) => {
    updateImageSort({
      variables: {
        product_uuid: productForEdit.uuid,
        product_images: value,
      },
    });
  };

  // ! IMAGE END

  // ! SKU
  // * CREATE SKU VARIANT
  const [createSkuVariant, { loading: createSkuVariantLoading }] = useMutation(CREATE_SKU_VARIANT, {
    onCompleted: () => {
      toast.success('Berhasil menambahkan sku variant!', toastOption);
      dispatch(actions.fetchProduct(match.params.id));
    },
    onError: (error) => {
      toast.error('Gagal menambahkan sku variant!', toastOption);
      console.log(error);
    },
  });

  // * UPDATE SKU VARIANT
  const [updateSkuVariant, { loading: updateSkuVariantLoading }] = useMutation(UPDATE_SKU_VARIANT, {
    onCompleted: () => {
      toast.success('Berhasil update sku variant!', toastOption);
      dispatch(actions.fetchProduct(match.params.id));
    },
    onError: (error) => {
      toast.error('Gagal update sku variant!', toastOption);
      console.log(error);
    },
  });

  // * SKU IMAGE
  const [uploadSkuImage, { loading: uploadSkuImageLoading }] = useMutation(SKU_IMAGE, {
    onCompleted: () => {
      toast.success('Berhasil mengunggah foto sku!', toastOption);
      dispatch(actions.fetchProduct(match.params.id));
    },
    onError: (error) => {
      toast.error('Gagal mengunggah foto sku!', toastOption);
      console.log(error);
    },
  });

  const [updateSkuImage, { loading: updateSkuImageLoading }] = useMutation(UPDATE_SKU_IMAGE, {
    onCompleted: () => {
      toast.success('Berhasil update foto sku!', toastOption);
      dispatch(actions.fetchProduct(match.params.id));
    },
    onError: (err) => {
      console.log(err);
      toast.error('Gagal update foto sku!', toastOption);
    },
  });

  const [deleteSkuImage, { loading: deleteImageSkuLoading }] = useMutation(DELETE_SKU_IMAGE, {
    onCompleted: () => {
      toast.success('Berhasil menghapus foto sku!', toastOption);
      dispatch(actions.fetchProduct(match.params.id));
    },
    onError: (err) => {
      console.log(err);
      toast.error('Gagal menghapus foto sku!', toastOption);
    },
  });

  const saveProduct = (params) => {
    // console.log("params", params);
    // server request for creating product
    updateProduct({
      variables: {
        uuid: match.params.id,
        brand_uuid: params.brand.value,
        description: params.description,
        name: params.name,
        product_category: params.category,
        publish_status: params.publish_status.value,
        weight: params.weight,
      },
    });
  };

  const saveImage = (value) => {
    // console.log("value", value);
    if (productForEdit.product_image.length == 0) {
      createImage({
        variables: {
          uuid: match.params.id,
          image1: value.image1,
          image2: value.image2,
          image3: value.image3,
          image4: value.image4,
          image5: value.image5,
        },
      });
    } else {
      updateImage({
        variables: {
          uuid: match.params.id,
          image1: value.image1 && value.image1.uuid ? null : value.image1,
          image2: value.image2 && value.image2.uuid ? null : value.image2,
          image3: value.image3 && value.image3.uuid ? null : value.image3,
          image4: value.image4 && value.image4.uuid ? null : value.image4,
          image5: value.image5 && value.image5.uuid ? null : value.image5,
          // deletedImage: value.deletedImage,
        },
      });
    }
  };

  const defaultImage = () => {
    if (productForEdit && productForEdit.product_image) {
      let images = {};
      productForEdit.product_image.forEach((element, index) => {
        images[`image_${index + 1}`] = element;
      });
      return images;
    }
  };

  const saveSkuVariant = (value) => {
    // console.log("VALUES", value);

    updateSkuVariant({
      variables: {
        uuid: match.params.id,
        product_variants: value.variant,
        skus: value.sku,
      },
    });
    // }
  };

  const saveSkuImage = (value) => {
    uploadSkuImage({
      variables: {
        uuid: value.sku_uuid,
        image: value.image,
      },
    });
  };

  const updateSkuImageFunc = (params) => {
    updateSkuImage({
      variables: {
        image: params.image,
        updateProductSkuInput: {
          uuid: params.sku_uuid,
        },
      },
    });
  };

  const deleteImageSku = (uuid) => {
    deleteSkuImage({
      variables: {
        product_sku_uuid: uuid,
      },
    });
  };

  // * PRINT LABEL
  React.useEffect(() => {
    printerDevice();
  }, []);

  const [openPrintLabelModal, setOpenPrintLabelModal] = useState(false);
  const [printData, setPrintData] = useState({});
  const [printer, setPrinter] = useState();

  const agent = new https.Agent({
    rejectUnauthorized: false,
  });

  const [isConnected, setIsConnected] = useState(false);
  const printerDevice = async () => {
    // ! NYALAIN LAGI
    fetch('https://localhost:9101/default?type=printer', {
      method: 'GET',
      mode: 'no-cors',
      agent: agent,
    })
      .then((res) => {
        printerAvailable();
      })
      .catch((err) => {
        setIsConnected(false);
        // toast.error(
        //   'Error connecting printer, Pastikan aplikasi Browser Print sudah aktif',
        //   toastOption
        // );
        // console.log(err);
      });
  };

  const printerAvailable = () => {
    fetch('https://localhost:9101/available', {
      method: 'GET',
      agent: agent,
    })
      .then((res) => res.json())
      .then((result) => {
        setPrinter(result.printer[0]);
        setIsConnected(true);
      })
      .catch((err) => {
        setIsConnected(false);
        // toast.error(
        //   'Error connecting printer, Pastikan aplikasi Browser Print sudah aktif',
        //   toastOption
        // );
        console.log('error available', err);
      });
  };

  const printLabel = (data) => {
    const { uuid, sku, name, qty, product_name } = data;
    console.log('data', data);

    window.open(`${API_URL}template/label/${uuid}?qty=${qty}`, '', 'width=1080,height=720');
    setOpenPrintLabelModal(false);
    return;
    // const { uuid, sku, qty, product_name,product_sku_uuid } = data;
    // // console.log('data', data);

    // let barcode = sku.replaceAll('-', '->5');
    // let barcode_str = sku;
    // let prod_name1 = product_name.slice(0, 27);
    // let prod_name2 = product_name.slice(27);

    // let inputQty = qty;
    // const zpl =
    //   'CT~~CD,~CC^~CT~^XA~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR5,5~SD15^JUS^LRN^CI0^XZ' +
    //   '^XA^MMT^PW240^LL0160^LS0^FO32,0^GFA,00640,00640,00020,:Z64:eJxjYMAK+P+jgA/4xdiBvHpUsX/MQJ49mjr5//+/I/QyKTE3P5CzY/j/wKCBueEB2F7GBEbGB/JyjP8fCBxhPPBAACTGvP2H5eN5fJb/H3e8M/78A6yXuYzx58eauPr/3+8eL/78FyzGpcbw44fNOX6gunPWj/+AxRjdCv9+lzl++P/HuccLhWvAYgzMzA0JBs2f/zdIMBkYWRiAhDiE2Q8UFLgX/j9Sw25oXGMAdfMPiEeAoMEe5o+/QDF3sBhDTQFU7CciWP4QCCvqiQ0LAADKK/dK:65A6' +
    //   '^BY2,3,62^FT230,89^BCI,,N,N' +
    //   '^FD>:' +
    //   barcode +
    //   '^FS' +
    //   '^FT230,72^A0I,17,16^FH^FD' +
    //   barcode_str +
    //   '^FS' +
    //   '^FT230,52^A0I,17,16^FH^FD' +
    //   prod_name1 +
    //   '^FS' +
    //   '^FT230,32^A0I,17,16^FH^FD' +
    //   prod_name2 +
    //   '^FS' +
    //   '^PQ' +
    //   inputQty +
    //   ',0,1,Y^XZ';

    // const payload = {
    //   device: printer,
    //   data: zpl,
    // };

    // fetch('https://localhost:9101/write', {
    //   method: 'POST',
    //   body: JSON.stringify(payload),
    //   agent: agent,
    // })
    //   .then((res) => {
    //     toast.success('Berhasil print label!', toastOption);
    //     setOpenPrintLabelModal(false);
    //   })
    //   .catch((err) => {
    //     toast.error(
    //       'Error connecting printer, Pastikan aplikasi Browser Print sudah aktif',
    //       toastOption
    //     );
    //   });
  };

  const isLoading =
    updateProductLoading ||
    createImageLoading ||
    createSkuVariantLoading ||
    updateImageLoading ||
    updateSkuVariantLoading ||
    deleteImageLoading ||
    updateSortLoading ||
    uploadSkuImageLoading ||
    updateSkuImageLoading ||
    deleteImageSkuLoading;
  return (
    <>
      <LoadingAnimation open={isLoading} />
      <PrintLabelModal
        show={openPrintLabelModal}
        initValue={{
          ...printData,
          product_name:
            productForEdit &&
            `${productForEdit.name} ${
              printData.variant_value_1 ? ` - ${printData.variant_value_1}` : ''
            } ${printData.variant_value_2 ? ` - ${printData.variant_value_2}` : ''}`,
        }}
        onHide={() => {
          setOpenPrintLabelModal(false);
          setPrintData({});
        }}
        onClick={printLabel}
      />
      <Card>
        <CardHeader title="Tambah Produk">
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={backToList}
              aria-controls="collapse-filter-product"
            >
              <i className="fa fa-arrow-left"></i>
              Kembali
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Product uploadProduct={saveProduct} productInit={productInit()} />
          <ImageProduct
            uploadImage={saveImage}
            deleteImage={deleteProductImage}
            initImage={productForEdit && productForEdit.product_image}
            // initImage={defaultImage()}
            uploadDropzone={dropzoneUpload}
            changeSort={changeSortImage}
          />
          <VariantSku
            uploadSkuVariant={saveSkuVariant}
            uploadImage={saveSkuImage}
            skuInit={generateSku()}
            updateSkuImage={updateSkuImageFunc}
            deleteImageSku={deleteImageSku}
            setOpenPrintLabelModal={() => setOpenPrintLabelModal(true)}
            setPrintData={setPrintData}
            productData={productForEdit}
          />
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <div className="row">
            <div className="col">
              <h6>Printer Status</h6>
              {isConnected ? (
                <span>
                  {printer.manufacturer} - {printer.name} - {printer.connection}
                </span>
              ) : (
                <span>Error connecting printer, Pastikan aplikasi Browser Print sudah aktif</span>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
}
