export const SynchronizeMarketplaceStatusCssClasses = [
  "danger",
  "success",
  "info",
  "",
];
export const SynchronizeMarketplaceStatusTitles = [
  "Suspended",
  "Active",
  "Pending",
  "",
];
export const SynchronizeMarketplaceTypeCssClasses = ["success", "primary", ""];
export const SynchronizeMarketplaceTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "created_at", order: "desc" }];
export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
];
export const initialFilter = {
  invoice_no: "",
  sort_order: "DESC", // asc||desc
  sort_field: "trx_date",
  page_number: 1,
  page_size: 10,
};

export const ORDER_ONLINE = [
  {
    label: "Online",
    value: true,
  },
  {
    label: "Offline",
    value: false,
  },
];

export const initSynchronizeMarketplace = {
  title: "",
  type: "",
  member_name: "",
  member_uuid: "",
  description: "",
  topics: "",
  image_url: "",
};

export const synchronizeMarketplace_type = [
  {
    value: "PROMO",
    name: "PROMO",
  },
  {
    value: "SYSTEM",
    name: "SYSTEM",
  },
];

export const STATUS = {
  SENT: "SENT",
  READ: "READ",
  DELETED: "DELETED",
  FAILED: "FAILED",
};

export const STATUS_OPTIONS = [
  {
    value: STATUS.SENT,
    name: STATUS.SENT,
  },
  {
    value: STATUS.READ,
    name: STATUS.READ,
  },
  {
    value: STATUS.DELETED,
    name: STATUS.DELETED,
  },
  {
    value: STATUS.FAILED,
    name: STATUS.FAILED,
  },
];

export const badge = (status) => {
  let data = {};
  switch (status) {
    case STATUS.SENT:
      data = { type: "success" };
      break;
    case STATUS.READ:
      data = { type: "primary" };
      break;
    case STATUS.DELETED:
      data = { type: "warning" };
      break;
    case STATUS.FAILED:
      data = { type: "danger" };
      break;
    default:
      data = { type: "" };
  }

  return data;
};
