/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
/* eslint-disable no-unused-vars*/
import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  Input,
  CardFooter,
} from "../../../../../_metronic/_partials/controls";
import { Table } from "antd";
import moment from "moment";
import {
  currencyFormatter,
  toastOption,
} from "../../../../../_metronic/_helpers";
import { toast } from "react-toastify";
import { OverlayTrigger, Tooltip } from "react-bootstrap";


export function SynchronizeMarketplaceEditForm({
  history,
  updateMarketplaceTrx,
  groupedData,
  data: { marketplaceImportData, orderData },
  openProductModal
}) {
  // * Marketplace Table
  const [marketplaceData, setMarketplaceData] = useState([]);
  const marketplaceImportColumns = [
    {
      title: "Trans Description",
      dataIndex: "description",
    },
    {
      title: "Amount",
      dataIndex: "amount_value",
      render: value => <span>{currencyFormatter(value)}</span>,
    },
  ];

  const marketplaceRow = {
    onChange: (selectedRowKeys, selectedRows) => {
      setMarketplaceData(selectedRows);
    },
    getCheckboxProps: (item) => ({
      disabled: item.order_uuid !== null,
    }),
  };

  // * Order Table
  const [orderUuid, setOrderUuid] = useState();
  const orderRow = {
    onChange: (selectedRowKeys, selectedRows) => {
      setOrderUuid(selectedRowKeys);
    },
  };

  const orderColumns = [
    // {
    //   dataIndex: "invoice_no",
    //   title: "Invoice No",
    // },
    {
      dataIndex: "marketplace_invoice",
      title: "Marketplace Invoice",
    },
    {
      dataIndex: "status",
      title: "Status",
    },
    {
      dataIndex: "order_amount",
      title: "Amount",
      render: value => <span>{currencyFormatter(value)}</span>,
    },
    {
      dataIndex: "net_amount",
      title: "Net Amount",
      render: value => <span>{currencyFormatter(value)}</span>,
    },
  ];

  const updateData = () => {
    let dataArray = [];

    marketplaceData.forEach((item) => {
      dataArray.push({ uuid: item.uuid, order_uuid: orderUuid[0] });
    });
    updateMarketplaceTrx(dataArray);
  };

  const searchOrder = () => {
    history.push(`/order/new`);
  }

  const header = (
    <>
      <h4>
        {groupedData.marketplace} |{" "}
        {moment(groupedData.trx_date).format("YYYY-MM-DD")} |{" "}
        {groupedData.invoice_no} | {currencyFormatter(groupedData.total_amount)}
      </h4>
    </>
  );

  const marketplaceInvoice = (
    <>
      <OverlayTrigger overlay={<Tooltip>Klik untuk copy invoice!</Tooltip>}>
        <h4 style={{cursor:'pointer'}}
          onClick={() => {
            navigator.clipboard.writeText(groupedData.invoice_no);
            toast.success("Berhasil copy invoice!", toastOption);
          }}
        >
          {groupedData.invoice_no}
        </h4>
      </OverlayTrigger>
    </>
  );

  return (
    <>
      <Card>
        <CardHeader title="Data Dasar">
          <CardHeaderToolbar>
            <button
              className="btn btn-secondary"
              onClick={() => history.push("/marketplace-synchronize")}
            >
              <i className="fa fa-arrow-left"></i>
              Kembali
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-lg-12">
              <label>Marketplace Import Grouped</label>
              <label className="float-right">{ marketplaceInvoice }</label>
              {header}
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-lg-5">
              <label className="mb-5">Marketplace Import</label>
              <Table
                rowSelection={{
                  type: "checkbox",
                  ...marketplaceRow,
                }}
                pagination={{ position: ["none", "none"] }}
                columns={marketplaceImportColumns}
                dataSource={marketplaceImportData}
              />
            </div>
            <div className="col-lg-7">
              <label className="mb-5">Marketplace Order</label>
              <button
                type="button"
                className="btn btn-sm btn-primary float-right mb-1"
                onClick={() => {
                  openProductModal();
                }}
              >
                Cari Order
              </button>
              <Table
                rowSelection={{
                  type: "radio",
                  ...orderRow,
                }}
                pagination={{ position: ["none", "none"] }}
                columns={orderColumns}
                dataSource={orderData}
              />
            </div>
          </div>
        </CardBody>
      </Card>
      <CardFooter>
        <button
          type="submit"
          className="btn btn-primary ml-3 float-right"
          onClick={() => {
            updateData();
          }}
        >
          Simpan
        </button>
      </CardFooter>
    </>
  );
}
