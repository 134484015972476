import axios from 'axios';
import { API } from '../../../../config/UrlEndPoint';
import { gql } from '@apollo/client';
import { QueryGraphql, MutationGraphql } from '../../../../config/ApoloRequest';

export const URL = `${API}master-data/category/`;

export const UPDATE_CATEGORY_PARENT = gql`
  mutation M($category_uuid: String!, $parent_uuid: String, $sibling_uuids: [String!]) {
    updateCategoriesParent(
      updateCategoriesParentInput: {
        category_uuid: $category_uuid
        parent_uuid: $parent_uuid
        sibling_uuids: $sibling_uuids
      }
    ) {
      message
    }
  }
`;

export async function findCategorys(queryParams) {
  // console.log(queryParams);
  const query = gql`
    {
      findCategoryTree {
        children
        name
        uuid
      }
    }
  `;

  const newResponse = await QueryGraphql(query);
  // console.log(newResponse.data.findChildrenTreeCategory);
  return newResponse.data.findCategoryTree;
}

export async function createCategory(payload) {
  let parent = '';
  if (payload.parent_uuid) {
    parent = `parent_uuid:"${payload.parent_uuid}"`;
  }
  let param = `categoryInput: {
        category: [
          {
            name: "${payload.name}"
          }
        ]
        ${parent}
      }`;

  const query = gql`
    mutation {
        createCategory(
          ${param}
        ) {
          uuid
          name
          children
        }
      }`;

  const newResponse = await MutationGraphql(query);
  return newResponse.data.createCategory;
}

export async function updateCategory(payload) {
  const query = gql`
    mutation{
        updateCategory(
          name:"${payload.name}",
          uuid:"${payload.parent_uuid}"
          )
          {
          uuid
          name
        }
      }`;

  const newResponse = await MutationGraphql(query);
  return newResponse.data.updateCategory;
  //   return newResponse;
}

export async function deleteCategory(payload) {
  const query = gql`
  mutation{
    deleteCategory(uuid:"${payload}"){
      message
      data{
        name
      }
    }
  }`;

  const newResponse = await MutationGraphql(query);
  return newResponse.data.deleteCategory;
}

// CREATE =>  POST: add a new customer to the server
// export function createCategory(category) {
//     return axios.post(URL, category);
// }

// GET ALL
export function getAllCategorys() {
  return axios.get(URL);
}

// GET SINGLE USER
// export function getCategoryById(id) {
//     return axios.get(`${URL}${id}`);
// }

export async function getCategoryById(id) {
  const query = gql`{
  findCategoryTree(uuid: "${id}") {
    name
    children
    uuid
    image {
      url
    }
    homepage_image {
      storage
      url
    }
    homepage_image_uuid
    image_uuid
    parent {
      uuid
    }
  }
}
`;

  const newResponse = await QueryGraphql(query);
  return newResponse.data.findCategoryTree;
}

// GET USER WITH CONDITION
// export function findCategorys(queryParams) {
//     return axios.post(`${URL}find`, queryParams);
// }

// UPDATE SINGEL USER
// export function updateCategory(category) {
//     return axios.put(`${URL}`, category);
// }
