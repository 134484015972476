import React from "react";
import {StoryLoadingDialog} from "./story-loading-dialog/StoryLoadingDialog";
import {StoryUIProvider} from "./StoryUIContext";
import {StoryCard} from "./StoryCard";

export function StoryPage({history}) {
    const storyUIEvents = {
        newStoryButtonClick: () => {
            history.push("/story/new");
        },
        openEditStory: (id) => {
            history.push(`/story/${id}/edit`);
        },
    }

    return (
        <StoryUIProvider storyUIEvents={storyUIEvents}>
            {/*<StoryLoadingDialog/>*/}
            <StoryCard history={history}/>
        </StoryUIProvider>
    );
}
