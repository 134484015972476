import * as requestFromServer from './orderCrud';
import { orderSlice, callTypes } from './orderSlice';

const { actions } = orderSlice;

export const fetchOrders = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findOrders(queryParams)
    .then((response) => {
      const { total_items, items } = response;

      dispatch(actions.ordersFetched({ total_items, items }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find orders";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchOrder = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.orderFetched({ orderForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getOrderById(id)
    .then((response) => {
      const order = response;
      dispatch(actions.orderFetched({ orderForEdit: order }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find order";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createOrder = (orderForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createOrder(orderForCreation)
    .then((response) => {
      const { order } = response.data;
      dispatch(actions.orderCreated({ order }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create order";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const orderFilter = (params) => (dispatch) => {
  dispatch(actions.startCall({ callTypes: callTypes.list }));
  dispatch(actions.orderFilter(params));
};

// export const updateOrdersStatus = (ids, status) => dispatch => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .updateStatusForOrders(ids, status)
//     .then(() => {
//       dispatch(actions.orderStatusUpdated({ ids, status }));
//     })
//     .catch(error => {
//       error.clientMessage = "Can't update orders status";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };
