import axios from "axios";
import { API } from "../../../../config/UrlEndPoint";
import { MutationGraphql, QueryGraphql } from "../../../../config/ApoloRequest";
import { gql } from "@apollo/client";
export const MEMBER_LEVEL_URL = `${API}member-level/`;

// CREATE =>  POST: add a new customer to the server
export async function createMemberLevel(payload) {
  const query = gql`
    mutation {
      createMemberLevel(
        createMemberLevelInput: {
          code: "${payload.code}"
          name: "${payload.name}"
          level_order: ${+payload.level_order}
        }
      ) {
        code
      }
    }
  `;
  const newResponse = await MutationGraphql(query);
  return newResponse.data.createMemberLevel;
}

// GET ALL
export function getAllMemberLevels() {
  // return axios.get(MEMBER_LEVEL_URL);
}

// GET SINGLE MEMBER_LEVEL
export async function getMemberLevelById(id) {
  const query = gql`
    {
      getMemberLevel(code: "${id}") {
        name
        code
        level_order
      }
    }
  `;
  const newResponse = await QueryGraphql(query);
  return newResponse.data.getMemberLevel;
  // return axios.get(`${MEMBER_LEVEL_URL}${id}`);
}

// GET MEMBER_LEVEL WITH CONDITION
export async function findMemberLevels(queryParams) {
  const query = gql`
    {
      findMemberLevel(
        findMemberLevelInput: {
          code: "${queryParams.code}"
          name: "${queryParams.name}"
          level_order: ${+queryParams.level_order}
          ${+queryParams.page_size ? "page_size: " + +queryParams.page_size : ""}
          ${+queryParams.page_number ? "page_number: " + +queryParams.page_number : ""}
          sort_field: "${queryParams.sortField}"
          sort_order: "${queryParams.sortOrder}"
        }
      ) {
        items {
          code
          name
          level_order
          created_at
          updated_at
        }
        current_page
        total_items
        total_pages
        sort_field
        sort_order
      }
    }
  `;
  const newResponse = await QueryGraphql(query);

  return newResponse.data.findMemberLevel;
}

// UPDATE SINGEL MEMBER_LEVEL
export async function updateMemberLevel(memberLevel) {
  const query = gql`
    mutation {
      updateMemberLevel(
        updateMemberLevelInput: {
          code: "${memberLevel.code}"
          name: "${memberLevel.name}"
          level_order: ${memberLevel.level_order}
        }
      ) {
        level_order
        code
        name
        created_at
        updated_at
      }
    }
  `;
  const newResponse = await MutationGraphql(query);
  return newResponse.data.updateMemberLevel;
}

// DELETE SINGEL MEMBER_LEVEL
export async function deleteMemberLevel(code) {
  const query = gql`
    mutation Mutation {
      deleteMemberLevel(code: "${code}") {
        name
      }
    }
  `;

  const newResponse = await MutationGraphql(query);
  return newResponse.data.deleteMemberLevel;
}

export const FIND_MEMBER_LEVELS = gql`
  query Query {
    findMemberLevel {
      items {
        code
        name
        level_order
      }
    }
  }
`;
