/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
/* eslint-disable no-unused-vars*/
import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from '../../../../../_metronic/_partials/controls';
import { nanoid } from 'nanoid';
import AsikSelect from 'react-select/async';
import { Modal, Form as Forms, Image } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Input } from '../../../../../_metronic/_partials/controls';
import Select, { components } from 'react-select';
import * as yup from 'yup';
import 'antd/dist/antd.css';
import { getStoryInitialValue, story_type } from '../StoryUIHelpers';
import { CreateBrandModal } from '../../../../components/create-brand-modal/BrandDialog';
import { gql, useLazyQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { toastOption } from '../../../../../_metronic/_helpers';
import { getSelectorStyles } from '../../../../../_metronic/_helpers/style-helpers';
import { useParams } from 'react-router-dom';
import { FIND_BRAND } from '../../../MasterData/_redux/brand/brandCrud';

const { MenuList } = components;

export const SEARCH_PRODUCTS = gql`
  query search_products($findProductInput: FindCountAllProductInput!) {
    findProduct(findProductInput: $findProductInput) {
      items {
        uuid
        name
      }
    }
  }
`;

const StorySchema = yup.object().shape({
  brand: yup.object().shape({
    uuid: yup.string().required('Brand wajib dipilih.'),
    name: yup.string().required('Brand wajib dipilih.'),
  }),
  video_url: yup.string().optional(),
  related_products: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required('Produk wajib dipilih.'),
        label: yup.string().required('Produk wajib dipilih.'),
      })
    )
    .required('Produk terkait wajib diisi.'),
  story_type: yup.string().required('Tipe Story wajib diisi.'),
  story_text: yup.string().required('Teks Story wajib diisi.'),
});

export function StoryEditForm({ history, saveStory, story }) {
  const { id } = useParams();

  const [images, setImages] = useState([]);
  const [defaultBrandOptions, setDefaultBrandOptions] = useState([]);
  const [defaultProductOptions, setDefaultProductOptions] = useState([]);
  const [brandModal, setBrandModal] = useState(false);
  const [deletedImage, setDeletedImage] = useState([]);
  const [currentTimeout, setCurrentTimeout] = useState(null);
  const [currentCallback, setCurrentCallback] = useState(null);

  const [fetchSearchProduct] = useLazyQuery(SEARCH_PRODUCTS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const { findProduct } = data ?? {};
      const { items = [] } = findProduct;

      const options = items.map(({ uuid, name }) => ({ label: name, value: uuid }));

      if (currentCallback instanceof Function) {
        currentCallback(options);
      } else {
        setDefaultProductOptions(options);
      }
    },
    onError: (error) => {
      toast.error(error, toastOption);
    },
  });
  const [fetchSearchBrand] = useLazyQuery(FIND_BRAND, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const { findBrand } = data ?? {};
      const { items = [] } = findBrand;

      const options = items.map(({ uuid, name }) => ({ label: name, value: uuid }));

      if (currentCallback instanceof Function) {
        currentCallback(options);
      } else {
        setDefaultBrandOptions(options);
      }
    },
    onError: (error) => {
      toast.error(error, toastOption);
    },
  });

  useEffect(() => {
    if (story && story.uuid) {
      const { images: story_images = [] } = story;

      if (story_images instanceof Array) {
        setImages(story_images);
      }
    }
  }, [story]);

  useEffect(() => {
    searchBrand('');
    searchProduct('');
  }, []);

  function submitStory(story) {
    const { brand, video_link, related_products = [], story_text, story_type } = story;

    let media_type = '';

    if (video_link && images instanceof Array && images.length > 0) {
      media_type = 'images/video_link';
    }
    if (video_link) {
      media_type = 'video_link';
    }
    if (images instanceof Array && images.length > 0) {
      media_type = 'images';
    }

    const image = images.filter((image) => image instanceof File);
    const deleted_images = deletedImage
      .filter((img) => !(img instanceof File))
      .map(({ filename }) => filename);

    const data = {
      media_type,
      image,
      video_link,
      story_text,
      story_type,
      brand: brand.uuid,
      related_products_uuid: related_products.map(({ value }) => value),
      deletedImage: deleted_images,
    };

    saveStory(data);
  }

  function backToList() {
    history.push('/story');
  }

  function getImageUrl(value) {
    if (value instanceof File) {
      return URL.createObjectURL(value);
    }

    return value.url ?? '';
  }

  function addImages(files) {
    const newImages = [];

    if (files instanceof FileList) {
      for (const file of files) {
        file.uuid = nanoid();
        newImages.push(file);
      }
      setImages((prev) => [...prev, ...newImages]);
    }
  }

  function removeImage(image_id) {
    const imageToDelete = images.find((image) => image.uuid === image_id);

    if (!(imageToDelete instanceof File)) {
      setDeletedImage((prev) => [...prev, imageToDelete]);
    }

    setImages((prev) => {
      return prev.filter((image) => image.uuid !== image_id);
    });
  }

  function genLoadOptions(fetcher) {
    return (inputValue, callback) => {
      clearTimeout(currentTimeout);

      if (!inputValue || inputValue.length < 3) {
        return callback([]);
      }

      setCurrentTimeout(
        setTimeout(() => {
          fetcher(inputValue);

          setCurrentCallback(() => callback);
        }, 250)
      );
    };
  }

  function searchBrand(inputValue) {
    fetchSearchBrand({
      variables: {
        product_name_or_sku: inputValue,
        sort_field: 'name',
        page_number: 1,
        page_size: 15,
      },
    });
  }

  function searchProduct(inputValue) {
    fetchSearchProduct({
      variables: {
        findProductInput: {
          product_name_or_sku: inputValue,
          sort_field: 'name',
          page_number: 1,
          page_size: 15,
        },
      },
    });
  }

  const CustomMenuList = ({ selectProps, ...props }) => {
    return (
      <div style={{ overflow: 'scroll', height: '250px' }}>
        <div
          style={{
            width: '100%',
            height: '40px',
            boxSizing: 'border-box',
            padding: '10px',
            backgroundColor: '#f1f1f1',
            textAlign: 'center',
            cursor: 'pointer',
          }}
          onClick={() => setBrandModal(true)}
        >
          Tambah Brand
        </div>
        <MenuList {...props} selectProps={selectProps} />
      </div>
    );
  };

  return (
    <>
      <CreateBrandModal
        show={brandModal}
        onHide={() => setBrandModal(false)}
        history={history}
        linkTo={id ? `/story/${id}/edit` : '/story/new'}
      />
      <Card>
        <CardHeader title={id ? 'Edit Story' : 'Tambah Story'}>
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={backToList}
              aria-controls="collapse-filter-story"
            >
              <i className="fa fa-arrow-left"></i>
              Kembali
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Card>
            <Formik
              enableReinitialize={true}
              initialValues={getStoryInitialValue(story)}
              validationSchema={StorySchema}
              onSubmit={(values) => {
                submitStory(values);
              }}
            >
              {({ handleSubmit, setFieldValue, values, errors, touched }) => (
                <>
                  {/* PHOTO PRODUCT START */}
                  <Card>
                    <CardHeader title="Foto Story">
                      <CardHeaderToolbar>
                        <>
                          <label htmlFor="image">
                            <i
                              className="fa fa-plus btn btn-primary mr-2"
                              style={{ color: '#fff', marginRight: '5px' }}
                            >
                              Tambah Foto
                            </i>
                          </label>
                          <Forms.Group>
                            <Forms.File
                              id="image"
                              accept=".png, .jpg, .jpeg"
                              multiple
                              style={{ width: '0px' }}
                              onChange={(e) => {
                                const { files } = e.currentTarget;
                                addImages(files);
                              }}
                            />

                            <ErrorMessage
                              name="image"
                              render={(msg) => <div style={{ color: 'red' }}>{msg}</div>}
                            />
                          </Forms.Group>
                        </>
                      </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>
                      <Form className="form form-label-right">
                        {/* Row Start */}
                        <div className="form-group row">
                          <div className="col">
                            <h4>Foto Story</h4> <br /> Format gambar .jpg .jpeg
                            <br /> Pilih foto Story atau tarik dan letakkan hingga 5 foto sekaligus
                            di sini. Cantumkan min. 3 foto yang menarik agar Story semakin menarik
                            pembeli.
                          </div>
                        </div>
                        <div className="form-group row">
                          {images instanceof Array &&
                            images.map((item, index) => (
                              <div
                                key={`${item.uuid}-${index}`}
                                className="col"
                                style={{
                                  height: '100px',
                                  width: '150px',
                                }}
                              >
                                <Image
                                  src={getImageUrl(item)}
                                  rounded
                                  name="images"
                                  onClick={() => removeImage(item.uuid)}
                                  style={{
                                    height: '100%',
                                    width: '100%',
                                    objectFit: 'contain',
                                  }}
                                />
                              </div>
                            ))}
                        </div>

                        {/* Row Start */}
                      </Form>
                    </CardBody>
                    {/* PHOTO PRODUCT END */}
                  </Card>
                  <Card>
                    <CardHeader title="Informasi Story" />
                    <CardBody>
                      <div className="form-group row">
                        <div className="col-lg-4">Video Url</div>
                        <div className="col-lg-8">
                          <Field
                            name="video_link"
                            component={Input}
                            placeholder="Masukkan URL Youtube"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-lg-4">Pilih Brand</div>
                        <div className="col-lg-8">
                          <AsikSelect
                            name="brand"
                            components={{ MenuList: CustomMenuList }}
                            value={
                              values.brand
                                ? {
                                    label: values.brand.name,
                                    value: values.brand.uuid,
                                  }
                                : {
                                    label: '',
                                    value: '',
                                  }
                            }
                            placeholder="Pilih brand..."
                            defaultOptions={defaultBrandOptions}
                            loadOptions={genLoadOptions(searchBrand)}
                            onChange={({ label: name, value: uuid }) => {
                              setFieldValue('brand', {
                                uuid,
                                name,
                              });
                            }}
                            styles={getSelectorStyles('brand', errors, touched)}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-lg-4">Produk Terkait</div>
                        <div className="col-lg-8">
                          <AsikSelect
                            cacheOptions={false}
                            name="related_products"
                            isMulti
                            defaultOptions={defaultProductOptions}
                            loadOptions={genLoadOptions(searchProduct)}
                            value={
                              values.related_products instanceof Array
                                ? values.related_products
                                : []
                            }
                            onChange={(values) => {
                              if (values instanceof Array) {
                                return setFieldValue('related_products', values);
                              }
                              setFieldValue('related_products', []);
                            }}
                            styles={getSelectorStyles('related_products', errors, touched)}
                          />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  {/* DESCRIPTION PRODUCT START */}
                  <Card>
                    <CardHeader title="Detail Story" />
                    <CardBody>
                      <div className="form-group row">
                        <div className="col-lg-4">Tipe Story</div>
                        <div className="col-lg-8">
                          <Select
                            name="story_type"
                            placeholder="Pilih Tipe Story..."
                            value={
                              values.story_type
                                ? { label: values.story_type, value: values.story_type }
                                : { label: '', value: '' }
                            }
                            options={story_type.map((item) => ({
                              value: item.value,
                              label: item.name,
                            }))}
                            onChange={({ value }) => {
                              setFieldValue('story_type', value);
                            }}
                            styles={getSelectorStyles('story_type', errors, touched)}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-lg-4">Story Text</div>
                        <div className="col-lg-8">
                          <Field
                            className="form-control"
                            name="story_text"
                            component="textarea"
                            rows="5"
                            placeholder="Masukkan caption story..."
                            label="Caption"
                            onChange={(e) => {
                              setFieldValue('story_text', e.target.value);
                            }}
                          />
                          <ErrorMessage
                            name="story_text"
                            render={(msg) => <div style={{ color: 'red' }}>{msg}</div>}
                          />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  {/* DESCRIPTION PRODUCT END */}

                  <Modal.Footer>
                    <button
                      type="submit"
                      onClick={() => handleSubmit()}
                      className="btn btn-primary btn-elevate"
                    >
                      Save
                    </button>
                  </Modal.Footer>
                </>
              )}
            </Formik>
          </Card>
        </CardBody>
      </Card>
    </>
  );
}
