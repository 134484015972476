import React, { useEffect, useMemo, useState } from 'react';
import { PackingEditForm } from './PackingEditForm';
import { toast } from 'react-toastify';
import { toastOption } from '../../../../../_metronic/_helpers';
import { useLazyQuery, useMutation } from '@apollo/client';
import { LoadingAnimation } from '../loading-animation/LoadingAnimation';
import {
  VERIFY_ORDER_ITEM_SKU,
  VERIFY_ORDER_PAYMENT,
  VERIFY_ORDER_PACKING_INPUT,
  UPDATE_ORDER,
  GET_ORDER_AND_ITEMS,
} from './PackingQuery';
import { API_URL } from '../../../../config/UrlEndPoint';

export function PackingEdit({ history }) {
  const [orderItems, setOrderItems] = useState([]);
  const [orderPaymentStatus, setOrderPaymentStatus] = useState('');
  const [fetchOrderAndOrderItems, { loading: orderItemsLoading }] = useLazyQuery(
    GET_ORDER_AND_ITEMS,
    {
      onCompleted: (data) => {
        const { getOrder } = data ?? {};
        const { order_item = [] } = getOrder;

        if (order_item instanceof Array) {
          setOrderItems(order_item);
        }
      },
    }
  );
  const [checkOrder, { loading: orderPaymentStatusLoading }] = useLazyQuery(VERIFY_ORDER_PAYMENT, {
    onCompleted: (data) => {
      toast.info(`${data.verifyOrderPayment.message}`, toastOption);
      setOrderPaymentStatus(data.verifyOrderPayment);
    },
    onError: (data) => {
      toast.error(`No Invoice tidak ditemukan!`, toastOption);
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (orderPaymentStatus.uuid) {
      fetchOrderAndOrderItems({ variables: { uuid: orderPaymentStatus.uuid } });
    }
  }, [orderPaymentStatus]);

  function verifyOrder(params) {
    checkOrder({
      variables: { no_invoice: params.no_invoice },
    });
  }

  const [packingResult, setPackingResult] = useState(null);
  const [isPrinted, setIsPrinted] = useState(false);
  const [checkPacking, { loading: packingLoading }] = useLazyQuery(VERIFY_ORDER_PACKING_INPUT, {
    onCompleted: (data) => {
      const { verifyOrderPacking } = data ?? {};

      setPackingResult(verifyOrderPacking);

      toast.info(`${data.verifyOrderPacking.message}`, toastOption);
    },
    onError: (data) => {
      toast.error(`Error`, toastOption);
    },
    fetchPolicy: 'no-cache',
  });

  function printPackingSummary() {
    if (orderPaymentStatus) {
      window.open(`${API_URL}template/packing/${orderPaymentStatus.uuid}`);
      setIsPrinted(true);
    }
  }

  const verifyOrderPacking = (params) => {
    checkPacking({
      variables: {
        order_uuid: params.order_uuid,
        product_sku_qty: params.sku_qty ?? [],
      },
    });
  };

  const [updateToPacking, { loading: updateLoading }] = useMutation(UPDATE_ORDER, {
    onCompleted: (data) => {
      console.log(data);
      toast.success('Success update order', toastOption);
      history.push('/packing');
    },
    onError: () => {
      toast.error('Failed update order', toastOption);
      //   history.push("/order");
    },
  });

  const updateOrder = (params) => {
    if (orderPaymentStatus) {
      window.open(
        `${API_URL}template/packing/${orderPaymentStatus.uuid}`,
        '',
        'width=1080,height=720'
      );
      updateToPacking({
        variables: {
          uuid: params.uuid,
          status: params.status,
        },
      });
    }
  };

  return (
    <>
      <LoadingAnimation open={orderPaymentStatusLoading || packingLoading || updateLoading} />
      <PackingEditForm
        history={history}
        order={orderPaymentStatus}
        actualOrderItems={orderItems}
        submitVerifyOrder={verifyOrder}
        verifyOrderPacking={verifyOrderPacking}
        packingValidity={packingResult}
        isPrinted={isPrinted}
        printPacking={printPackingSummary}
        updateOrder={updateOrder}
      />
    </>
  );
}
