import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./PickupUIHelpers";

const PickupUIContext = createContext();

export function usePickupUIContext() {
  return useContext(PickupUIContext);
}

export const PickupUIConsumer = PickupUIContext.Consumer;

export function PickupUIProvider({ pickupUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    newPickupButtonClick: pickupUIEvents.newPickupButtonClick,
    openEditPickupDialog: pickupUIEvents.openEditPickupDialog,
    openDeletePickupDialog: pickupUIEvents.openDeletePickupDialog,
    openFetchPickupDialog: pickupUIEvents.openFetchPickupDialog,
    openDisablePickupDialog: pickupUIEvents.openDisablePickupDialog,
    openUpdatePickupStatusDialog: pickupUIEvents.openUpdatePickupStatusDialog,
  };

  return (
    <PickupUIContext.Provider value={value}>
      {children}
    </PickupUIContext.Provider>
  );
}
