import React, { useMemo, useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { Badge, Collapse } from "react-bootstrap";
import { MemberFilter } from "./member-filter/MemberFilter";
import { MemberTable } from "./member-table/MemberTable";
import { useMemberUIContext } from "./MemberUIContext";
import { RoleChecker } from "../../../RoleChecker";
import { shallowEqual, useSelector } from "react-redux";

export function MemberCard({ history }) {
  const memberUIContext = useMemberUIContext();
  const memberUIProps = useMemo(() => {
    return {
      queryParams: memberUIContext.queryParams,
      newMemberButtonClick: memberUIContext.newMemberButtonClick,
    };
  }, [memberUIContext]);

  const [statusFilter, setStatusFilter] = useState(false);
  const [open, setOpen] = useState(false);

  const changeShow = () => {
    setOpen(!open);

    // Set Status Filter To False
    setStatusFilter(false);
    const filter = memberUIProps.queryParams;
    for (const [key, value] of Object.entries(filter)) {
      if (value !== "" && key) {
        setStatusFilter(true);
      }
    }
  };

  useEffect(() => {
    if (memberUIProps.filterType !== undefined) {
      setStatusFilter(true);
      // setIsDisabled(true);
    }
    // eslint-disable-next-line
  }, [memberUIProps.filterType]);

  const { filters } = useSelector(state => state.members, shallowEqual)
  useEffect(() => {
    if(filters.filterIsOpen) setOpen(true)
  }, [])

  return (
    <Card>
      <CardHeader title="Daftar Pelanggan">
        <CardHeaderToolbar>
          {statusFilter && (
            <Badge className="mr-5" pill variant="danger">
              Filter Aktif
            </Badge>
          )}
          {RoleChecker("read", "member") && (
            <button
              type="button"
              className="btn btn-warning mr-2"
              onClick={changeShow}
              aria-controls="collapse-filter-member"
              aria-expanded={open}
            >
              Cari
            </button>
          )}
          {RoleChecker("create", "member") && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={memberUIProps.newMemberButtonClick}
            >
              Tambah Member
            </button>
          )}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <Collapse in={open}>
          <div id="collapse-filter-member">
            <MemberFilter history={history} />
          </div>
        </Collapse>

        <MemberTable />
      </CardBody>
    </Card>
  );
}
