export const MemberStatusCssClasses = ['danger', 'success', 'info', ''];
export const MemberStatusTitles = ['Suspended', 'Active', 'Pending', ''];
export const MemberTypeCssClasses = ['success', 'primary', ''];
export const MemberTypeTitles = ['Business', 'Individual', ''];
export const defaultSorted = [{ dataField: 'created_at', order: 'desc' }];
export const sizePerPageList = [
  { text: '10', value: 10 },
  { text: '50', value: 50 },
  { text: '100', value: 100 },
];
export const initialFilter = {
  member_level_code: '',
  name: '',
  email: '',
  hp: '',
  sortOrder: 'DESC', // asc||desc
  sortField: 'created_at',
  page_number: 1,
  page_size: 10,
};

export const initMember = {
  email: '',
  phone: '',
  name: '',
  password: '',
  member_level_code: '',
};

export function getMemberInitialValues(member) {
  const {
    uuid = '',
    name = '',
    email = '',
    hp = '',
    member_level_code = '',
    dropship_name = '',
    dropship_hp = '',
    member_level = {},
  } = member ?? {};

  const values = {
    uuid: uuid ?? '',
    name: name ?? '',
    email: email ?? '',
    password: '',
    hp: hp ?? '',
    member_level_code: member_level_code ?? '',
    dropship_name: dropship_name ?? '',
    dropship_hp: dropship_hp ?? '',
    update_password: false,
    member_level: member_level.code ?? '',
  };

  return values;
}
