import React from "react";
import { Prompt } from "react-router-dom";

const LeavePageConfirmation = (
  message = "Apakah anda yakin ingin meninggalkan halaman ini?"
) => {
  const [trigger, setTrigger] = React.useState(false);

  React.useEffect(() => {
    window.onbeforeunload = trigger && (() => message);

    return () => {
      window.onbeforeunload = null;
    };
  }, [trigger]);

  const routerPrompt = <Prompt when={trigger} message={message} />;

  return [routerPrompt, () => setTrigger(true), () => setTrigger(false)];
};

export default LeavePageConfirmation;
