import * as requestFromServer from "./marketplaceOrderCrud";
import { marketplaceOrderSlice, callTypes } from "./marketplaceOrderSlice";
import { toastOption } from "../../../../../_metronic/_helpers";
import { toast } from "react-toastify";

const { actions } = marketplaceOrderSlice;

export const fetchMarketplaceOrders = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findMarketplaceOrders(queryParams)
    .then((response) => {
      const { total_items, items } = response;
      dispatch(actions.marketplaceOrdersFetched({ total_items, items }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find marketplaceOrders";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchMarketplaceOrderAll = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllMarketplaceOrders(queryParams)
    .then((response) => {
      dispatch(
        actions.marketplaceOrderALlFetched({ allData: response.data.data })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find marketplaceOrders";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchMarketplaceOrder = (id) => (dispatch) => {
  if (!id) {
    return dispatch(
      actions.marketplaceOrderFetched({ marketplaceOrderForEdit: undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getMarketplaceOrderById(id)
    .then((response) => {
      dispatch(
        actions.marketplaceOrderFetched({ marketplaceOrderForEdit: response })
      );
      return response;
    })
    .catch((error) => {
      error.clientMessage = "Can't find marketplaceOrder";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateMarketplaceOrder = (marketplaceOrder) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateMarketplaceOrder(marketplaceOrder)
    .then((response) => {
      toast.success(response.data.message, toastOption);
      dispatch(actions.marketplaceOrderUpdated({ marketplaceOrder }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update marketplaceOrder";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteMarketplaceOrder = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteMarketplaceOrder(id)
    .then(() => {
      fetchMarketplaceOrders();
      toast.success("Successful MarketplaceOrder Brand!", toastOption);
    })
    .catch((error) => {
      console.log(error);
      error.clientMessage = "Can't delete category";
      toast.error("Delete MarketplaceOrder Failed!", toastOption);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const orderMarketplaceFilter = params => dispatch => {
  dispatch(actions.startCall({ callTypes: callTypes.list }));
  dispatch(actions.orderMarketplaceFilter(params));
}
