import React, { useEffect } from 'react';
import { Field, Form, Formik } from 'formik';
import { Modal } from 'react-bootstrap';
import { Input } from '../../../../../_metronic/_partials/controls';
import * as Yup from 'yup';

export default function PrintLabelModal({ show, onHide, initValue, onClick }) {
  const qtyRef = React.useRef();
  useEffect(() => {
    if (show) {
      setTimeout(() => {
        qtyRef.current.select();
      }, 0);
    }
  }, [show, initValue]);
  const schema = Yup.object().shape({
    qty: Yup.number().moreThan(0).required('Masukkan qty minimal lebih dari 0'),
  });
  return (
    <Formik
      enableReinitialize={true}
      validationSchema={schema}
      initialValues={initValue}
      onSubmit={(values) => {
        onClick(values);
        onHide();
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => (
        <>
          <Modal
            size="md"
            show={show}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg">Print Label</Modal.Title>
            </Modal.Header>
            <Modal.Body className="overlay overlay-block cursor-default">
              <Form>
                <input
                  className="form-control"
                  onFocus={() => qtyRef.current.select()}
                  ref={qtyRef}
                  label="Qty"
                  placeholder="Masukkan jumlah"
                  type="number"
                  value={values.qty}
                  name="qty"
                  component={Input}
                  onChange={(e) => setFieldValue('qty', parseInt(e.target.value))}
                />
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-primary" onClick={handleSubmit}>
                Print
              </button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </Formik>
  );
}
