import React, { useMemo, useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { Badge, Collapse } from "react-bootstrap";
import { StoryFilter } from "./story-filter/StoryFilter";
import { StoryTable } from "./story-table/StoryTable";
import { useStoryUIContext } from "./StoryUIContext";
import { RoleChecker } from "../../../RoleChecker";
import { shallowEqual, useSelector } from "react-redux";

export function StoryCard({ history }) {
  const storyUIContext = useStoryUIContext();
  const storyUIProps = useMemo(() => {
    return {
      queryParams: storyUIContext.queryParams,
      newStoryButtonClick: storyUIContext.newStoryButtonClick,
    };
  }, [storyUIContext]);

  const [statusFilter, setStatusFilter] = useState(false);
  const [open, setOpen] = useState(false);

  const changeShow = () => {
    setOpen(!open);

    // Set Status Filter To False
    setStatusFilter(false);
    const filter = storyUIProps.queryParams;
    for (const [key, value] of Object.entries(filter)) {
      if (value !== "" && key) {
        setStatusFilter(true);
      }
    }
  };

  useEffect(() => {
    if (storyUIProps.filterType !== undefined) {
      setStatusFilter(true);
      // setIsDisabled(true);
    }
    // eslint-disable-next-line
  }, [storyUIProps.filterType]);

  const { filters } = useSelector(state => state.story, shallowEqual)
  useEffect(() => {
    if(filters.filterIsOpen) setOpen(true)
  }, [])

  return (
    <Card>
      <CardHeader title="Daftar Story">
        <CardHeaderToolbar>
          {statusFilter && (
            <Badge className="mr-5" pill variant="danger">
              Filter Aktif
            </Badge>
          )}
          {RoleChecker("read", "story") && (
            <button
              type="button"
              className="btn btn-warning mr-2"
              onClick={changeShow}
              aria-controls="collapse-filter-story"
              aria-expanded={open}
            >
              Cari
            </button>
          )}
          {RoleChecker("create", "story") && (
            <button
              type="button"
              className="btn btn-primary mr-2"
              onClick={storyUIProps.newStoryButtonClick}
              aria-controls="collapse-filter-story"
            >
              Tambah Story
            </button>
          )}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <Collapse in={open}>
          <div id="collapse-filter-story">
            <StoryFilter history={history} />
          </div>
        </Collapse>

        <StoryTable />
      </CardBody>
    </Card>
  );
}
