/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
/* eslint-disable no-unused-vars*/
import React, { useState, useEffect } from 'react';
import { useLazyQuery, gql, useMutation } from '@apollo/client';
import AsyncSelect from 'react-select/async';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderToolbar,
} from '../../../../../_metronic/_partials/controls';
import { Modal } from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import { Input } from '../../../../../_metronic/_partials/controls';
import * as Yup from 'yup';
import { SEARCH_BRANCHES } from '../../../MasterData/_redux/branch/branchCrud';
import { SEARCH_SUPPLIERS } from '../../../MasterData/_redux/supplier/supplierCrud';
import { SEARCH_PROJECTS } from '../../../MasterData/_redux/project/projectCrud';
import { statusPurchase, statusPurchaseItem } from '../PurchaseUIHelpers';
import PurchaseProductTable from './PurchaseProductTable';
import PurchaseAddProduct from './PurchaseAddProduct';
import * as branchCrud from '../../../MasterData/_redux/branch/branchCrud';
import * as supplierCrud from '../../../MasterData/_redux/supplier/supplierCrud';
import * as projectCrud from '../../../MasterData/_redux/project/projectCrud';
import { getStorage, removeStorage, setStorage } from '../../../../../_metronic/_helpers';
import ProductPurchaseModal from './ProductPurchaseModal';
import LeavePageConfirmation from '../../../../components/LeavePageConfirmation';
import { CreateModal } from './CreateModal';
import { CreateProjectModal } from './CreateProjectModal';
import { CreateSupplierModal } from './CreateSupplierModal';
import { ActionButton } from '../../../../../_metronic/_partials/controls/ActionButton';

const PurchaseSchema = Yup.object().shape({
  trx_date: Yup.date().required(),
  branch_uuid: Yup.string().required(),
  supplier_uuid: Yup.string().required(),
  description: Yup.string().required().min(3),
});

const schemas = {
  project: Yup.object().shape({
    name: Yup.string().required('Nama Project wajib diisi.'),
    start_date: Yup.date().required('Tanggal mulai wajib diisi.'),
    end_date: Yup.date().required('Tanggal berakhir wajib diisi.'),
  }),
  supplier: Yup.object().shape({
    email: Yup.string().email().required('Email Supplier wajib diisi.'),
    hp: Yup.string().required('Hp Supplier wajib diisi.'),
    name: Yup.string().required('Nama Supplier berakhir wajib diisi.'),
    password: Yup.string().required('Password  wajib diisi.'),
  }),
};

export const PurchaseEditForm = ({
  history,
  purchase,
  purchaseLoading,
  savePurchase,
  dataPrint,
}) => {
  // ! CONFIRMATION LEAVE PAGE
  const [Prompt, setTrigger, setPristine] = LeavePageConfirmation(
    'Perubahan anda tidak akan tersimpan! Apakah anda yakin ingin meninggalkan halaman ini?'
  );

  const [products, setProducts] = useState([]);

  useEffect(() => {
    getBranch();
    getSupplier();
    getProject();
  }, []);

  // * Get Branch
  const [branchOptions, setBranchOptions] = useState([]);
  const getBranch = () => {
    branchCrud
      .findBranchs({
        page_number: 1,
        page_size: 10,
      })
      .then((res) => setBranchOptions(res.items));
  };

  // * Get Supplier
  const [supplierOptions, setsupplierOptions] = useState([]);
  const getSupplier = () => {
    supplierCrud
      .findSuppliers({
        page_number: 1,
        page_size: 10,
        name: '',
        hp: '',
        email: '',
      })
      .then((res) => {
        setsupplierOptions(res.items);
      });
  };

  // * Get Project
  const [projectOptions, setprojectOptions] = useState([]);
  const getProject = () => {
    projectCrud
      .findProjects({
        page_number: 1,
        page_size: 10,
        name: '',
      })
      .then((res) => {
        if (res && res.items instanceof Array) {
          setprojectOptions(res.items);
        }
      })
      .catch(() => null);
  };

  useEffect(() => {
    setProducts((products) => [
      ...products,
      ...purchase.purchase_items.map(
        ({
          uuid,
          product_sku_uuid,
          price,
          total_price,
          qty,
          product_sku,
          is_printed,
          product_sku: {
            product: { name },
            sku,
            variant_value_1,
            variant_value_2,
          },
        }) => ({
          uuid,
          product_sku_uuid,
          price,
          total_price,
          qty,
          product_sku,
          is_printed,
          sku,
          name,
          product_name: `${name} ${variant_value_1 ? '- ' + variant_value_1 : ''} ${
            variant_value_2 ? '- ' + variant_value_2 : ''
          }`,
        })
      ),
    ]);

    return () => {
      setProducts([]);
    };
  }, [purchase]);

  const [currentTimeout, setCurrentTimeout] = useState(null);
  const [currentCallback, setCurrentCallback] = useState(() => () => null);

  const [searchBranches] = useLazyQuery(SEARCH_BRANCHES, {
    variables: {
      name: '',
    },
    onCompleted: ({ findBranches: { items } }) => {
      currentCallback(items.map(({ uuid, name }) => ({ label: name, value: uuid })));
    },
    fetchPolicy: 'cache-and-network',

    // onError:
  });

  const [searchSuppliers] = useLazyQuery(SEARCH_SUPPLIERS, {
    variables: {
      name: '',
    },
    onCompleted: ({ findSupplier: { items } }) => {
      currentCallback(items.map(({ uuid, name }) => ({ label: name, value: uuid })));
    },
    fetchPolicy: 'cache-and-network',
  });

  const [searchprojects] = useLazyQuery(SEARCH_PROJECTS, {
    variables: {
      name: '',
    },
    onCompleted: ({ findProject: { items } }) => {
      currentCallback(items.map(({ uuid, name }) => ({ label: name, value: uuid })));
    },
    fetchPolicy: 'cache-and-network',
  });

  // const [fetchBranch, { loading: branchLoading, data: branch }] = useLazyQuery(branchQuery);
  // const [fetchSupplier, { loading: supplierLoading, data: supplier }] = useLazyQuery(supplierQuery);

  // useEffect(() => {
  //   if (purchase.branch_uuid) {
  //     fetchBranch({ variables: { uuid: purchase.branch_uuid } });
  //   }

  //   if (purchase.suppliers && purchase.suppliers.uuid) {
  //     fetchSupplier({ variables: { uuid: purchase.suppliers.uuid } });
  //   }
  // }, [purchase]);

  function loadBranchOptions(inputValue, callback) {
    clearTimeout(currentTimeout);

    if (inputValue.length >= 3) {
      setCurrentTimeout(
        setTimeout(() => {
          searchBranches({ variables: { name: inputValue } });
          setCurrentCallback(() => callback);
        }, 250)
      );
    } else {
      callback([]);
    }
  }

  function loadSupplierOptions(inputValue, callback) {
    clearTimeout(currentTimeout);

    if (inputValue.length >= 3) {
      setCurrentTimeout(
        setTimeout(() => {
          searchSuppliers({ variables: { name: inputValue } });
          setCurrentCallback(() => callback);
        }, 250)
      );
    } else {
      callback([]);
    }
  }

  function loadProjectOptions(inputValue, callback) {
    clearTimeout();

    if (inputValue.length >= 3) {
      setCurrentTimeout(
        setTimeout(() => {
          searchprojects({ variables: { name: inputValue } });
          setCurrentCallback(() => callback);
        }, 250)
      );
    } else {
      callback([]);
    }
  }

  function addProduct(values) {
    const { product_sku_uuid, product_name, price: product_price, quantity, sku } = values;

    const price = Number.parseInt(product_price);
    const qty = Number.parseInt(quantity);
    const total_price = Number.parseInt(price * qty);

    const newProduct = {
      product_sku_uuid,
      product_name,
      price,
      qty,
      total_price,
      status: statusPurchaseItem.ADDED,
      sku,
    };

    const sameItemIndex = products.findIndex(
      (product) => product.product_sku_uuid === newProduct.product_sku_uuid
    );

    if (sameItemIndex > -1) {
      const temp = [...products];

      if (temp[sameItemIndex].status === statusPurchaseItem.REMOVED) {
        temp[sameItemIndex] = {
          ...temp[sameItemIndex],
          qty,
          status: statusPurchaseItem.EDITED,
          total_price: +product_price * +qty,
        };
      } else {
        temp[sameItemIndex] = {
          ...temp[sameItemIndex],
          price,
          qty: temp[sameItemIndex].qty + qty,
          total_price: product_price * (temp[sameItemIndex].qty + qty),
          status: statusPurchaseItem.EDITED,
        };
      }

      setPurchaseModal(false);
      return setProducts(temp);
    }

    // setProducts([...products, newProduct]);
    setProducts((prev) => {
      const old = [...prev];
      old.push(newProduct);
      setProducts(old);
    });
    setPurchaseModal(false);

    let productForLocalStorage = [...products];
    productForLocalStorage.push(newProduct);
    setTrigger();
  }

  function removeProduct(removeId) {
    if (purchase.uuid) {
      setProducts((prevState) => {
        const productIndex = prevState.findIndex(
          ({ product_sku_uuid }) => product_sku_uuid === removeId
        );

        const temp = [...prevState];

        temp[productIndex] = {
          ...temp[productIndex],
          status: statusPurchaseItem.REMOVED,
        };
        return temp;
      });
    } else {
      setProducts((prev) => {
        const oldProducts = [...prev];
        const newProducts = oldProducts.filter((item) => {
          return item.product_sku_uuid != removeId;
        });
        return newProducts;
      });
    }
  }

  function submitpurchase(values) {
    const { branch_uuid, project_uuid, supplier_uuid, trx_date, description, products, status } =
      values;

    // console.log(values);

    if (!products || products.length === 0) {
      return;
    }

    const purchase_items = products.map(
      ({ uuid, status, product_sku_uuid, price, total_price, qty }) => ({
        purchase_item_uuid: uuid,
        product_sku_uuid,
        qty,
        price,
        total_price,
        status,
      })
    );

    const purchase = {
      branch_uuid,
      project_uuid,
      supplier_uuid,
      description,
      purchase_items,
      trx_date,
      status,
    };

    savePurchase(purchase);
  }

  const backToList = () => {
    history.push('/purchase');
  };

  // * Local Storage

  const purchaseKey = 'purchase_storage';

  const storageValue = JSON.parse(getStorage(purchaseKey));

  const [purchaseValue, setPurchaseValue] = useState({
    branch_name: storageValue ? storageValue.branch_name : '',
    branch_value: storageValue ? storageValue.branch_value : '',
    supplier_name: storageValue ? storageValue.supplier_name : '',
    supplier_value: storageValue ? storageValue.supplier_value : '',
    project_name: storageValue ? storageValue.project_name : '',
    project_value: storageValue ? storageValue.project_value : '',
    description: storageValue ? storageValue.description : '',
  });

  useEffect(() => {
    removeStorage(purchaseKey);
    setStorage(purchaseKey, JSON.stringify(purchaseValue));
    // console.log("purchaseKey", JSON.parse(getStorage(purchaseKey)));
  }, [purchaseValue]);

  const [createProject, { loading: createProjectLoading }] = useMutation(
    projectCrud.CREATE_PROJECT,
    {
      onCompleted: (data) => {
        const { createProject } = data ?? {};

        if (createProject && modalCallback instanceof Function) {
          modalCallback(createProject);
        }

        setShowCreateProjectModal({ show: false });
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const [createSupplier, { loading: createSupplierLoading }] = useMutation(
    supplierCrud.CREATE_SUPPLIER,
    {
      onCompleted: (data) => {
        const { createSupplier } = data ?? {};

        if (createSupplier && modalCallback instanceof Function) {
          modalCallback(createSupplier);
        }

        setShowCreateSupplierModal({ show: false });
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const [showCreateProjectModal, setShowCreateProjectModal] = useState({
    show: false,
    entityName: '',
  });
  const [showCreateSupplierModal, setShowCreateSupplierModal] = useState({
    show: false,
    entityName: '',
  });
  const [modalCallback, setModalCallback] = useState(() => () => null);

  // * Purchase Modal
  const [purchaseModal, setPurchaseModal] = useState(false);

  return (
    <>
      <CreateProjectModal
        show={showCreateProjectModal.show}
        onHide={() => setShowCreateProjectModal((state) => ({ ...state, show: false }))}
        submitCreate={(values) => createProject({ variables: values })}
        isLoading={createProjectLoading}
      />
      <CreateSupplierModal
        show={showCreateSupplierModal.show}
        onHide={() => setShowCreateSupplierModal((state) => ({ ...state, show: false }))}
        submitCreate={(values) => createSupplier({ variables: { createSupplierInput: values } })}
        isLoading={createSupplierLoading}
      />
      <ProductPurchaseModal
        show={purchaseModal}
        onHide={() => setPurchaseModal(false)}
        initValue={{}}
        addProduct={addProduct}
      />
      <Card>
        <CardHeader title="Tambah Purchase">
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={backToList}
              aria-controls="collapse-filter-purchase"
            >
              <i className="fa fa-arrow-left"></i>
              Kembali
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Card>
            <Formik
              validationSchema={PurchaseSchema}
              enableReinitialize={true}
              initialValues={{
                trx_date: purchase.trx_date
                  ? purchase.trx_date
                  : new Date().toLocaleDateString('fr-CA'),
                branch_uuid: purchase.branches.uuid,
                branch_name: purchase.branches.name,
                supplier_uuid: purchase.suppliers.uuid,
                supplier_name: purchase.suppliers.name,
                project_uuid: purchase.projects.uuid,
                project_name: purchase.projects.name,
                description: purchase.description,
              }}
              onSubmit={() => null}
            >
              {({ values, handleSubmit, setFieldValue, handleChange, errors }) => (
                <>
                  <Card>
                    <CardHeader title="Pembelian Baru" />
                    <CardBody>
                      <div className="row m-0 p-0">
                        <div className="col-12 col-md-6 col-lg-4">
                          <div className="form-group d-flex flex-column">
                            <label htmlFor="trx_date">Tanggal transaksi</label>
                            <Field
                              name="trx_date"
                              type="date"
                              component={Input}
                              value={values.trx_date}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                          <div className="form-group d-flex flex-column">
                            <label htmlFor="branch_uuid">Pilih Branch</label>
                            <AsyncSelect
                              cacheOptions={false}
                              name="branch_uuid"
                              placeholder="Branch"
                              noOptionsMessage={({ inputValue }) =>
                                inputValue.length >= 3 ? 'No options' : 'Type 3 or more characters'
                              }
                              loadOptions={loadBranchOptions}
                              defaultOptions={branchOptions.map((item) => ({
                                value: item.uuid,
                                label: item.name,
                              }))}
                              value={
                                values.branch_name && values.branch_uuid
                                  ? {
                                      label: values.branch_name,
                                      value: values.branch_uuid,
                                    }
                                  : null
                              }
                              onChange={({ label, value }) => {
                                setFieldValue('branch_uuid', value);
                                setFieldValue('branch_name', label);
                                setPurchaseValue((prevState) => ({
                                  ...prevState,
                                  branch_name: label,
                                  branch_value: value,
                                }));
                              }}
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  border: errors.branch_uuid
                                    ? '1px solid rgb(246, 78, 96)'
                                    : '1px solid #ddd',
                                }),
                                dropdownIndicator: (provided, state) => ({
                                  ...provided,
                                  color: errors.branch_uuid ? 'rgb(246, 78, 96)' : '#ddd',
                                }),
                              }}
                            />
                            <ErrorMessage
                              name="branch_uuid"
                              render={(msg) => (
                                <div style={{ color: 'rgb(246, 78, 96)' }}>{msg}</div>
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                          <div className="form-group d-flex flex-column">
                            <label htmlFor="supplier_uuid">Pilih Supplier</label>
                            <AsyncSelect
                              name="supplier_uuid"
                              cacheOptions={false}
                              placeholder="Supplier"
                              loadOptions={loadSupplierOptions}
                              defaultOptions={supplierOptions.map((item) => ({
                                label: item.name,
                                value: item.uuid,
                              }))}
                              noOptionsMessage={({ inputValue }) =>
                                inputValue.length >= 3 ? (
                                  <>
                                    <p>Supplier tidak ditemukan</p>
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-secondary mr-2"
                                      aria-controls="collapse-filter-product"
                                      onClick={() => {
                                        setShowCreateSupplierModal({
                                          show: true,
                                          entityName: 'supplier',
                                        });
                                        setModalCallback(() => (newSupplier) => {
                                          setFieldValue('supplier_uuid', newSupplier.uuid);
                                          setFieldValue('supplier_name', newSupplier.name);
                                        });
                                      }}
                                    >
                                      Tambah
                                    </button>
                                  </>
                                ) : (
                                  <span>Ketik 3 huruf atau lebih untuk mencari project</span>
                                )
                              }
                              value={
                                values.supplier_name && values.supplier_uuid
                                  ? {
                                      label: values.supplier_name,
                                      value: values.supplier_uuid,
                                    }
                                  : null
                              }
                              onChange={({ label, value }) => {
                                setFieldValue('supplier_name', label);
                                setFieldValue('supplier_uuid', value);
                                setPurchaseValue((prevState) => ({
                                  ...prevState,
                                  supplier_name: label,
                                  supplier_value: value,
                                }));
                              }}
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  border: errors.branch_uuid
                                    ? '1px solid rgb(246, 78, 96)'
                                    : '1px solid #ddd',
                                }),
                                dropdownIndicator: (provided, state) => ({
                                  ...provided,
                                  color: errors.branch_uuid ? 'rgb(246, 78, 96)' : '#ddd',
                                }),
                              }}
                            />
                            <ErrorMessage
                              name="supplier_uuid"
                              render={(msg) => (
                                <div style={{ color: 'rgb(246, 78, 96)' }}>{msg}</div>
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                          <div className="form-group d-flex flex-column">
                            <label htmlFor="branch_uuid">Pilih Proyek</label>
                            <AsyncSelect
                              cacheOptions={false}
                              name="project_uuid"
                              placeholder="Project"
                              loadOptions={loadProjectOptions}
                              defaultOptions={projectOptions.map((item) => ({
                                label: item.name,
                                value: item.uuid,
                              }))}
                              noOptionsMessage={({ inputValue }) =>
                                inputValue.length >= 3 ? (
                                  <>
                                    <p>Project tidak ditemukan</p>
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-secondary mr-2"
                                      aria-controls="collapse-filter-product"
                                      onClick={() => {
                                        setShowCreateProjectModal({
                                          show: true,
                                          entityName: 'project',
                                        });
                                        setModalCallback(() => (newStore) => {
                                          setFieldValue('project_uuid', newStore.uuid);
                                          setFieldValue('project_name', newStore.name);
                                        });
                                      }}
                                    >
                                      Tambah
                                    </button>
                                  </>
                                ) : (
                                  <span>Ketik 3 huruf atau lebih untuk mencari project</span>
                                )
                              }
                              value={
                                values.project_name && values.project_uuid
                                  ? {
                                      label: values.project_name,
                                      value: values.project_uuid,
                                    }
                                  : null
                              }
                              onChange={({ label, value }) => {
                                setFieldValue('project_uuid', value);
                                setFieldValue('project_name', label);
                                setPurchaseValue((prevState) => ({
                                  ...prevState,
                                  project_name: label,
                                  project_value: value,
                                }));
                              }}
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  border: errors.project_uuid
                                    ? '1px solid rgb(246, 78, 96)'
                                    : '1px solid #ddd',
                                }),
                                dropdownIndicator: (provided, state) => ({
                                  ...provided,
                                  color: errors.project_uuid ? 'rgb(246, 78, 96)' : '#ddd',
                                }),
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-8">
                          <label htmlFor="description">Description</label>
                          <Field
                            className="form-control"
                            name="description"
                            component="textarea"
                            rows="1"
                            id="description"
                            placeholder="Masukkan deskripsi pembelian..."
                            label="Description"
                            value={values.description}
                            onChange={(e) => {
                              let value = e.target.value;
                              setFieldValue('description', value);
                              setPurchaseValue((prevState) => ({
                                ...prevState,
                                description: value,
                              }));
                              // removeStorage("purchase_description")
                              // setStorage("purchase_description", e.target.value)
                            }}
                          />
                          <ErrorMessage
                            name="description"
                            render={(msg) => <div style={{ color: 'rgb(246, 78, 96)' }}>{msg}</div>}
                          />
                        </div>
                        {/* <div className="col-12 col-md-6 col-lg-4"></div> */}
                        {/* <div className="col-12 col-md-6 col-lg-4"></div> */}
                      </div>
                    </CardBody>
                  </Card>
                  {/* ADD PRODUCT START */}
                  {!purchaseLoading && purchase.status !== statusPurchase.SETTLE && (
                    <PurchaseAddProduct
                      addProduct={addProduct}
                      supplier_uuid={values.supplier_uuid}
                    />
                  )}
                  {/* ADD PRODUCT END */}

                  {/* {PRODUCT TABLE START} */}
                  <PurchaseProductTable
                    purchase={purchase}
                    rows={
                      purchaseLoading
                        ? []
                        : products.filter(({ status }) => status !== statusPurchaseItem.REMOVED)
                    }
                    removeProduct={removeProduct}
                    dataPrint={dataPrint}
                    openProductModal={() => setPurchaseModal(true)}
                  />
                  {/* {PRODUCT TABLE END} */}
                  <Modal.Footer>
                    {purchase.status !== statusPurchase.SETTLE && (
                      <ActionButton
                        type="submit"
                        loading={purchaseLoading}
                        onClick={() => {
                          setPristine();
                          submitpurchase({
                            ...values,
                            products,
                            status: statusPurchase.SETTLE,
                          });
                        }}
                        text="SETTLE"
                        className="btn btn-info btn-elevate"
                      />
                    )}
                    {purchase.status !== statusPurchase.SETTLE && (
                      <ActionButton
                        type="submit"
                        loading={purchaseLoading}
                        onClick={() => {
                          setPristine();
                          submitpurchase({
                            ...values,
                            products,
                            status: statusPurchase.DRAFT,
                          });
                        }}
                        text="Save Draft"
                        className="btn btn-primary btn-elevate"
                      />
                    )}
                  </Modal.Footer>
                </>
              )}
            </Formik>
          </Card>
        </CardBody>
      </Card>
      {Prompt}
    </>
  );
};
