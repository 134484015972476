// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/synchronizeMarketplacebook/index.html?selectedKind=Work%20on%20Columns&selectedSynchronizeMarketplace=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=synchronizeMarketplacebook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import SyncIcon from "@material-ui/icons/Sync";

export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  { openEditSynchronizeMarketplace, allowUpdate }
) {
  const invoice = row.invoice_no.replaceAll("/", "-");

  return (
    <>
      {allowUpdate && (
      <a
        title="Edit Order"
        className="btn btn-icon btn-light btn-hover-primary btn-sm"
        onClick={() => {
          openEditSynchronizeMarketplace(invoice);
        }}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SyncIcon />
        </span>
      </a>
      )}
    </>
  );
}
