import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '../SvgIcon'
import { defaultFill } from '../constant'

const Menu = props => (
  <SvgIcon {...props}>
    <g id="menu" transform="translate(-309 -42)">
      <rect fill={props.fill} className="custom-fill" id="Rectangle_1229" width="24" height="2" data-name="Rectangle 1229" rx="1" transform="translate(309 42)" />
      <rect fill={props.fill} className="custom-fill" id="Rectangle_1230" width="24" height="2" data-name="Rectangle 1230" rx="1" transform="translate(309 48)" />
      <rect fill={props.fill} className="custom-fill" id="Rectangle_1231" width="24" height="2" data-name="Rectangle 1231" rx="1" transform="translate(309 54)" />
    </g>
  </SvgIcon>
)

Menu.propTypes = {
  fill: PropTypes.string,
}

Menu.defaultProps = {
  fill: defaultFill,
}

export default Menu
