import React, { useMemo, useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import { isEqual } from "lodash";
import { useCampaignUIContext } from "../CampaignUIContext";
import { initialFilter } from "../CampaignUIHelpers";
import { IS_ACTIVE } from "../CampaignUIHelpers";
import ReactSelect from "react-select";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { campaignFilter } from "../../_redux/campaignActions";

const prepareFilter = (queryParams, values) => {
  const { name, title, is_active, filterIsOpen } = values;
  const newQueryParams = { ...queryParams };

  console.log(is_active);
  newQueryParams.name = name ?? "";
  newQueryParams.title = title ?? "";
  newQueryParams.is_active = is_active ? is_active.value : null;
  newQueryParams.filterIsOpen = filterIsOpen

  return newQueryParams;
};

export const CampaignFilter = ({ listLoading, history }) => {
  const { filters } = useSelector(state => state.campaign, shallowEqual)
  const dispatch = useDispatch()
  // Campaign UI Context
  const campaignUIContext = useCampaignUIContext();
  const campaignUIProps = useMemo(() => {
    return {
      queryParams: campaignUIContext.queryParams,
      setQueryParams: campaignUIContext.setQueryParams,
    };
  }, [campaignUIContext]);

  const [pgOptions, setPgOptions] = useState([]);

  useEffect(() => {}, []);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(campaignUIProps.queryParams, values);
    if (!isEqual(newQueryParams, campaignUIProps.queryParams)) {
      newQueryParams.page_number = 1;
      // update list by queryParams
      campaignUIProps.setQueryParams(newQueryParams);
      dispatch(campaignFilter(newQueryParams))
    }
  };

  return (
    <>
      <Formik
        initialValues={filters}
        onSubmit={(values) => {
          applyFilter({...values, filterIsOpen: true});
          // console.log(values);
        }}
        onReset={() => {
          applyFilter({...initialFilter, filterIsOpen: false});
          history.push("/campaign")
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
          resetForm,
        }) => (
          <>
            <Form onSubmit={handleSubmit} className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-4">
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    placeholder="Cari Nama..."
                    onBlur={handleBlur}
                    value={values.name}
                    onChange={(e) => {
                      setFieldValue("name", e.target.value);
                    }}
                  />
                  <small className="form-text ">
                    Cari <b>Judul</b>
                  </small>
                </div>
                <div className="col-lg-4">
                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    placeholder="Cari Judul..."
                    onBlur={handleBlur}
                    value={values.title}
                    onChange={(e) => {
                      setFieldValue("title", e.target.value);
                    }}
                  />
                  <small className="form-text ">
                    Cari <b>Judul</b>
                  </small>
                </div>
                <div className="col-lg-4">
                  <Field
                    name="is_active"
                    id="is_active"
                    value={
                      values.is_active
                        ? {
                            label: values.is_active.label,
                            value: values.is_active.value,
                          }
                        : null
                    }
                    options={IS_ACTIVE.map((item) => ({
                      value: item.value,
                      label: item.name,
                    }))}
                    component={ReactSelect}
                    placeholder="True/False"
                    label="Is_Active"
                    onChange={(e) => {
                      setFieldValue("is_active", e);
                      // setIsActiveValue(e);
                    }}
                  />
                  <small className="form-text ">
                    Cari <b>Is_Active</b>
                  </small>
                </div>
              </div>
              <button
                type="submit"
                onClick={handleSubmit}
                className="btn btn-primary btn-elevate float-right mb-20 ml-4"
              >
                Terapkan
              </button>
              <button
                type="button"
                onClick={() => {
                  resetForm();
                }}
                className="btn btn-danger btn-elevate float-right mb-20 ml-8"
              >
                Hapus Pencarian
              </button>
              <hr className="mt-25" />
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
