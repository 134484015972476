import React, { useMemo } from 'react';
import { Card, CardBody, CardHeader } from '../../../../_metronic/_partials/controls';
import { OrderLogisticFilter } from './order-logistic-filter/OrderLogisticFilter';
import { OrderLogisticTable } from './order-logistic-table/OrderLogisticTable';
import { useOrderLogisticUIContext } from './OrderLogisticUIContext';
import { Collapse } from 'react-bootstrap';

export function OrderLogisticCard({ match }) {
  const orderLogisticUIContext = useOrderLogisticUIContext();
  const orderLogisticUIProps = useMemo(() => {
    return {
      queryParams: orderLogisticUIContext.queryParams,
      newOrderLogisticButtonClick: orderLogisticUIContext.newOrderLogisticButtonClick,
    };
  }, [orderLogisticUIContext]);

  return (
    <Card>
      <CardHeader title="Daftar OrderLogistic"></CardHeader>
      <CardBody>
        <Collapse in={true}>
          <div id="collapse-filter-orderLogistic">
            <OrderLogisticFilter match={match} />
          </div>
        </Collapse>

        <OrderLogisticTable match={match} />
      </CardBody>
    </Card>
  );
}
