import axios from "axios";
import { API } from "../../../../config/UrlEndPoint";
import { gql, useMutation } from "@apollo/client";
import { MutationGraphql, QueryGraphql } from "../../../../config/ApoloRequest";

export const CUSTOMERS_URL = `${API}notification/`;

// CREATE =>  POST: add a new notification to the server
// export function createNotification(notification) {
//   return axios.post(CUSTOMERS_URL, { notification });
// }

export async function findNotifications(queryParams) {
  const query = gql`
    query Query(
      $description: String
      $page_number: Int
      $page_size: Int
      $sort_field: String
      $sort_order: String
      $status: String
      $title: String
      $topic: String
      $type: String
      $member_uuid: String
    ) {
      findNotifications(
        findNotificationInput: {
          member_uuid: $member_uuid
          page_number: $page_number
          page_size: $page_size
          sort_field: $sort_field
          sort_order: $sort_order
          description: $description
          status: $status
          title: $title
          topic: $topic
          type: $type
        }
      ) {
        items {
          uuid
          description
          title
          type
          member_uuid
          image_url
          status
          member {
            uuid
            name
          }
        }
        current_page
        total_items
        total_pages
      }
    }
  `;

  const newResponse = await QueryGraphql(query, queryParams);
  return newResponse.data.findNotifications;
}

export async function getNotificationById(id) {
  const query = gql`
  {
  getNotification(uuid: "${id}") {
    uuid
    brand_uuid
    media_type
    notification_text
    notification_type
    video_link
    related_products {
      name
      uuid
    }
    related_products_uuid
    images {
      url
      uuid
      filename
    }
    brand {
      name
      uuid
    }
    related_products {
      name
      uuid
    }
  }
}
`;

  const newResponse = await QueryGraphql(query);
  return newResponse.data.getNotification;
}

export const CREATE_NOTIFICATION = gql`
  mutation Mutation(
    $description: String!
    $image_url: String
    $member_uuid: String
    $title: String!
    $topic: String
    $type: String!
  ) {
    createNotification(
      createNotificationInput: {
        description: $description
        title: $title
        type: $type
        image_url: $image_url
        member_uuid: $member_uuid
        topic: $topic
      }
    ) {
      title
      description
      status
    }
  }
`;

export const RESEND_NOTIF = gql`
  mutation Mutation($uuid: String!) {
    resendNotification(notification_uuid: $uuid) {
      title
      status
      type
    }
  }
`;

export async function deleteNotification(id) {
  const query = gql`
    mutation Mutation {
      deleteNotification(notification_uuid: "${id}") {
        message
      }
    }
  `;

  const response = await MutationGraphql(query);
  return response.data.deleteNotification;
}

export function getAllNotifications() {
  return axios.get(CUSTOMERS_URL);
}

// export function getNotificationById(notificationId) {
//   return axios.get(`${CUSTOMERS_URL}${notificationId}`);
// }

// export function findNotifications(queryParams) {
//   return axios.post(`${CUSTOMERS_URL}find`, queryParams );
// }

export function updateNotification(notification) {
  return axios.put(`${CUSTOMERS_URL}/`, notification);
}

// DELETE => delete the notification from the server
// export function deleteNotification(notificationId) {
//   return axios.delete(`${CUSTOMERS_URL}/${notificationId}`);
// }
