import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import { Badge } from 'react-bootstrap';
import * as moment from 'moment';
import { ORDER_STATUS } from '../../OrderUIHelpers';
import { paymentMethodType } from '../../../../MasterData/pages/payment-method/PMUIHelpers';
import { useContext } from 'react';
import { OrderFormContext } from '../FormContext';
import { ORDER_VERIFY_PAYMENT } from '../../../_redux/order/orderCrud';
import { useHistory } from 'react-router-dom';
import { toastOption } from '../../../../../../_metronic/_helpers';
import { toast } from 'react-toastify';
import { CircularProgress } from '@material-ui/core';

export const OrderPaymentVerification = ({ logs = [] }) => {
  const history = useHistory();

  const { order } = useContext(OrderFormContext);
  const { image_confirmation = {}, order_payment } = order;

  const [paidLog, setPaidLog] = useState(null);

  const [fetchVerifyPayment, { loading: verifyLoading }] = useMutation(ORDER_VERIFY_PAYMENT, {
    onCompleted: (data) => {
      toast.success('Success verify payment!', toastOption);
      history.go(0);
    },
    onError: (err) => {
      console.log(err);
      toast.error('Failed verify payment!', toastOption);
    },
  });

  useEffect(() => {
    const paidLog = logs.find((log) => log.status === ORDER_STATUS.PAID);

    if (paidLog) {
      setPaidLog(paidLog);
    }
  }, [logs]);

  function verifyPayment() {
    if (order && order.order_payment) {
      fetchVerifyPayment({
        variables: {
          payment_uuid: order.order_payment.uuid,
        },
      });
    }
  }

  return (
    <>
      <div className="row m-0 mt-5 p-0">
        <div className="row m-0 p-0 col-md-7">
          {order_payment.payment_method.type === paymentMethodType.WIRED_TRANSFER && (
            <>
              <div className="col-8 m-0 p-0 mb-4 d-flex align-items-center justify-content-between">
                <div className="h4 mr-5 font-weight-bold">Bukti Transfer</div>
                <a
                  type="button"
                  className="btn btn-primary"
                  download
                  href={
                    (image_confirmation && image_confirmation.url) ||
                    'https://media.istockphoto.com/vectors/thumbnail-image-vector-graphic-vector-id1147544807?k=20&m=1147544807&s=612x612&w=0&h=pBhz1dkwsCMq37Udtp9sfxbjaMl27JUapoyYpQm0anc='
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  Download
                </a>
              </div>
              <div className="col-8 m-0 p-0 rounded">
                <img
                  className="w-100 h-100"
                  src={
                    (image_confirmation && image_confirmation.url) ||
                    'https://media.istockphoto.com/vectors/thumbnail-image-vector-graphic-vector-id1147544807?k=20&m=1147544807&s=612x612&w=0&h=pBhz1dkwsCMq37Udtp9sfxbjaMl27JUapoyYpQm0anc='
                  }
                  alt="placeholder"
                />
              </div>
            </>
          )}
          <div className="row m-0 mt-5 p-0 col-8 d-flex align-items-center justify-content-between">
            {[ORDER_STATUS.DRAFT, ORDER_STATUS.PAYMENT].includes(order.status) && (
              <button
                type="submit"
                className="btn btn-info d-flex justify-content-center align-items-center"
                style={{ width: '125px' }}
                onClick={() => {
                  verifyPayment();
                }}
              >
                {verifyLoading ? (
                  <CircularProgress color="inherit" size={16} />
                ) : (
                  <span>Verify Payment</span>
                )}
              </button>
            )}
            {paidLog && <Badge variant="success">PAID</Badge>}
            {paidLog && (
              <div className="display-5">
                {moment(paidLog.created_at)
                  .utcOffset(Math.abs(new Date().getTimezoneOffset()))
                  .format('ddd MMM DD YYYY HH:MM:ss')}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
