// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/synchronizeMarketplacebook/index.html
import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/synchronizeMarketplace/synchronizeMarketplaceActions";
import { getHandlerTableChange } from "../../../../../_metronic/_helpers";
import * as uiHelpers from "../SynchronizeMarketplaceUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../_metronic/_partials/controls";
import { useSynchronizeMarketplaceUIContext } from "../SynchronizeMarketplaceUIContext";
import { SynchronizeMarketplaceDisableDialog } from "../synchronizeMarketplace-disable-dialog/SynchronizeMarketplaceDisableDialog";
import moment from "moment";
import { RoleChecker } from "../../../../RoleChecker";

export function SynchronizeMarketplaceTable() {
  const allowUpdate = RoleChecker('update', 'synchronize_marketplace');
  // synchronizeMarketplace UI Context
  const synchronizeMarketplaceUIContext = useSynchronizeMarketplaceUIContext();
  const synchronizeMarketplaceUIProps = useMemo(() => {
    return {
      ids: synchronizeMarketplaceUIContext.ids,
      setIds: synchronizeMarketplaceUIContext.setIds,
      queryParams: synchronizeMarketplaceUIContext.queryParams,
      setQueryParams: synchronizeMarketplaceUIContext.setQueryParams,
      openEditSynchronizeMarketplace:
        synchronizeMarketplaceUIContext.openEditSynchronizeMarketplace,
      // openDeleteSynchronizeMarketplaceDialog: synchronizeMarketplaceUIContext.openDeleteSynchronizeMarketplaceDialog,
    };
  }, [synchronizeMarketplaceUIContext]);

  const [showModal, setShowModal] = useState(false);
  const [rowId, setRowId] = useState();

  const [showModalImage, setShowModalImage] = useState(false);
  const [currentImages, setCurrentImages] = useState(null);

  useEffect(() => {
    setShowModalImage(Boolean(currentImages));
  }, [currentImages]);

  // Getting curret state of synchronizeMarketplace list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.synchronizeMarketplace }),
    shallowEqual
  );

  const { totalCount, entities, listLoading } = currentState;

  // synchronizeMarketplace Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // server call by queryParams
    dispatch(
      actions.fetchSynchronizeMarketplaces(
        synchronizeMarketplaceUIProps.queryParams
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [synchronizeMarketplaceUIProps.queryParams, dispatch]);
  // Table columns
  const columns = [
    {
      dataField: "marketplace",
      text: "Marketplace",
    },
    {
      dataField: "invoice_no",
      text: "Invoice No",
    },
    {
      dataField: "trx_date",
      text: "Tgl Import",
      formatter: (cell) => {
        return moment(cell).format("YYYY-MM-DD");
      },
    },
    {
      dataField: "synchronized_count",
      text: "Tersinkron",
    },
    {
      dataField: "unsynchronized_count",
      text: "Tidak Tersinkron",
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditSynchronizeMarketplace:
          synchronizeMarketplaceUIProps.openEditSynchronizeMarketplace,
        // openDeleteSynchronizeMarketplaceDialog: synchronizeMarketplaceUIProps.openDeleteSynchronizeMarketplaceDialog,
        showModal: setShowModal,
        id: setRowId,
        allowUpdate: allowUpdate
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: synchronizeMarketplaceUIProps.queryParams.page_size,
    page: synchronizeMarketplaceUIProps.queryParams.page_number,
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              // isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="uuid"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  synchronizeMarketplaceUIProps.setQueryParams
                )}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: synchronizeMarketplaceUIProps.ids,
                //   setIds: synchronizeMarketplaceUIProps.setIds,
                // })}
                {...paginationTableProps}
              >
                {/* <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} /> */}
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
