import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./OrderLogisticUIHelpers";

const OrderLogisticUIContext = createContext();

export function useOrderLogisticUIContext() {
  return useContext(OrderLogisticUIContext);
}

export const OrderLogisticUIConsumer = OrderLogisticUIContext.Consumer;

export function OrderLogisticUIProvider({ orderLogisticUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initOrderLogistic = {
    uuid: "",
    status: "",
    trx_date: "",
    updated_at: "",
    is_online: false,
    invoice_no: "",
    created_at: "",
    branch: {},
    member: {},
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    setQueryParams,
    initOrderLogistic,
    newOrderLogisticButtonClick:
      orderLogisticUIEvents.newOrderLogisticButtonClick,
    openEditOrderLogisticDialog:
      orderLogisticUIEvents.openEditOrderLogisticDialog,
    openDeleteOrderLogisticDialog:
      orderLogisticUIEvents.openDeleteOrderLogisticDialog,
    openFetchOrderLogisticDialog:
      orderLogisticUIEvents.openFetchOrderLogisticDialog,
    openUpdateOrderLogisticStatusDialog:
      orderLogisticUIEvents.openUpdateOrderLogisticStatusDialog,
  };

  return (
    <OrderLogisticUIContext.Provider value={value}>
      {children}
    </OrderLogisticUIContext.Provider>
  );
}
