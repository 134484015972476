import { ApolloError, gql, useLazyQuery } from '@apollo/client';

export const DEFAULT_QUERY = gql`
  query Q {
    __typename
  }
`;

export const defaultComplete = (data) => {};
export const defaultError = (error = new ApolloError()) => {
  console.error(error);
};

export const useCLazyQuery = (
  query = DEFAULT_QUERY,
  options = {
    onCompleted: defaultComplete,
    onError: defaultError,
    fetchPolicy: 'cache-and-network',
  }
) => {
  return useLazyQuery(query, {
    onCompleted: options.onCompleted,
    onError: options.onError,
    fetchPolicy: options.fetchPolicy,
  });
};
