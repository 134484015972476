import React from 'react';
import * as utils from './LocalStorageHelpers';
import { Redirect, Route } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';

const localStorageLastLocationKey = 'metronic-lastLocation';

function acceptLocation(lastLocation) {
  if (
    lastLocation &&
    lastLocation.pathname &&
    lastLocation.pathname !== '/' &&
    lastLocation.pathname.indexOf('auth') === -1 &&
    lastLocation.pathname !== '/logout'
  ) {
    return true;
  }

  return false;
}

export function saveLastLocation(lastLocation) {
  if (acceptLocation(lastLocation)) {
    utils.setStorage(localStorageLastLocationKey, JSON.stringify(lastLocation), 120);
  }
}

export function forgotLastLocation() {
  utils.removeStorage(localStorageLastLocationKey);
}

export function getLastLocation() {
  const defaultLocation = { pathname: '/', title: 'Dashboard' };
  const localStorateLocations = utils.getStorage(localStorageLastLocationKey);
  if (!localStorateLocations) {
    return { pathname: '/', title: 'Dashboard' };
  }

  try {
    const result = JSON.parse(localStorateLocations);
    return result;
  } catch (error) {
    console.error(error);
    return defaultLocation;
  }
}

export function getCurrentUrl(location) {
  return location.pathname.split(/[?#]/)[0];
}

export function checkIsActive(location, url, includeQueryParams = false) {
  const current = includeQueryParams
    ? `${location.pathname}${location.search}`
    : getCurrentUrl(location);

  if (!current || !url) {
    return false;
  }

  if (current === url) {
    return true;
  }

  if (current.indexOf(url) > -1) {
    return true;
  }

  return false;
}

// routes access from BE
// gunakan huruf kapital agar useSelector tidak error
export function Permissions(path, access) {
  const { role } = useSelector((state) => ({ role: state.auth.user.access }), shallowEqual);
  let validate = false;
  JSON.parse(role).permissions.map((value) => {
    if (access) {
      let methods = false;
      value.methods.map((data) => {
        if (data === access) methods = true;
        return methods;
      });
      if (value.resource.startsWith(path) && methods) validate = true;
    } else if (value.resource.startsWith(path)) {
      validate = value.resource.startsWith(path);
    }
    return validate;
  });
  return validate;
}

export const PrivateRoute = ({ role, component: Component, ...rest }) => (
  <Route
    {...rest}
    component={(props) =>
      role ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
    }
  />
);
