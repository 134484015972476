import React from 'react';
import { Card, CardHeader, CardHeaderToolbar } from '../../../../../_metronic/_partials/controls';
import { StatusFormatter } from '../order-table/column-formatters';

export const OrderFormHeader = ({ order, history }) => {
  const { order_delivery } = order ?? {};

  return (
    <Card>
      <CardHeader title={order && order.uuid ? 'Edit Order' : 'Tambah Order'}>
        <CardHeaderToolbar>
          {order_delivery && order_delivery.awb && (
            <span className="mr-4 text-dark">AWB: {order_delivery.awb}</span>
          )}
          {order && order.uuid && StatusFormatter(order.status)}
          <button className="btn btn-secondary" onClick={() => history.goBack()}>
            Kembali
          </button>
        </CardHeaderToolbar>
      </CardHeader>
    </Card>
  );
};
