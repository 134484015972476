import React, { useMemo, useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { Badge, Collapse } from "react-bootstrap";
import { NotificationFilter } from "./notification-filter/NotificationFilter";
import { NotificationTable } from "./notification-table/NotificationTable";
import { useNotificationUIContext } from "./NotificationUIContext";
import { RoleChecker } from "../../../RoleChecker";
import { NotificationEditDialog } from "./notification-edit/NotificationEdit";
import { useSelector } from "react-redux";

export function NotificationCard({ history }) {
  const notificationUIContext = useNotificationUIContext();
  const notificationUIProps = useMemo(() => {
    return {
      queryParams: notificationUIContext.queryParams,
      newNotificationButtonClick:
        notificationUIContext.newNotificationButtonClick,
    };
  }, [notificationUIContext]);

  const [statusFilter, setStatusFilter] = useState(false);
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const changeShow = () => {
    setOpen(!open);

    // Set Status Filter To False
    setStatusFilter(false);
    const filter = notificationUIProps.queryParams;
    for (const [key, value] of Object.entries(filter)) {
      if (value !== "" && key) {
        setStatusFilter(true);
      }
    }
  };

  useEffect(() => {
    if (notificationUIProps.filterType !== undefined) {
      setStatusFilter(true);
      // setIsDisabled(true);
    }
    // eslint-disable-next-line
  }, [notificationUIProps.filterType]);

  const { filters } = useSelector(state => state.notificationUser)
  useEffect(() => {
    if(filters.filterIsOpen) setOpen(true)
  }, [])

  return (
    <>
      <NotificationEditDialog
        show={showModal}
        onHide={() => setShowModal(false)}
        history={history}
      />
      <Card>
        <CardHeader title="Daftar Notification">
          <CardHeaderToolbar>
            {statusFilter && (
              <Badge className="mr-5" pill variant="danger">
                Filter Aktif
              </Badge>
            )}
            {RoleChecker("read", "notification") && (
            <button
              type="button"
              className="btn btn-warning mr-2"
              onClick={changeShow}
              aria-controls="collapse-filter-notification"
              aria-expanded={open}
            >
              Cari
            </button>
            )}
            {RoleChecker("create", "notification") && (
            <button
              type="button"
              className="btn btn-primary mr-2"
              // onClick={notificationUIProps.newNotificationButtonClick}
              onClick={() => setShowModal(true)}
              aria-controls="collapse-filter-notification"
            >
              Notifikasi Baru
            </button>
            )}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Collapse in={open}>
            <div id="collapse-filter-notification">
              <NotificationFilter history={history} />
            </div>
          </Collapse>

          <NotificationTable />
        </CardBody>
      </Card>
    </>
  );
}
