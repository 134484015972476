// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useState, useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../../_redux/order/orderLogisticActions';
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from '../../../../../_metronic/_helpers';
import * as uiHelpers from '../OrderLogisticUIHelpers';
import * as columnFormatters from './column-formatters';
import { Pagination } from '../../../../../_metronic/_partials/controls';
import { useOrderLogisticUIContext } from '../OrderLogisticUIContext';
import moment from 'moment';
import { LoadingAnimation } from '../../../../components/loading-animation/LoadingAnimation';

export function OrderLogisticTable({ match }) {
  // OrderLogistic UI Context
  const orderLogisticUIContext = useOrderLogisticUIContext();
  const orderLogisticUIProps = useMemo(() => {
    return {
      queryParams: orderLogisticUIContext.queryParams,
      setQueryParams: orderLogisticUIContext.setQueryParams,
      openEditOrderLogisticDialog: orderLogisticUIContext.openEditOrderLogisticDialog,
      openDeleteOrderLogisticDialog: orderLogisticUIContext.openDeleteOrderLogisticDialog,
    };
  }, [orderLogisticUIContext]);

  // Getting curret state of orderLogistic list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.orderLogistic }),
    shallowEqual
  );

  const { totalCount, entities, listLoading, total_real_shipping_cost, total_shipping_cost } =
    currentState;

  // OrderLogistic Redux state
  // ! NYALAIN KALO BUTUH
  const dispatch = useDispatch();
  useEffect(() => {
    const { queryParams } = orderLogisticUIProps;
    if (Object.keys(queryParams).length !== 0) {
      dispatch(actions.fetchOrderLogistics(queryParams));
    } else {
      dispatch(actions.removeState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderLogisticUIProps.queryParams, dispatch]);

  // * NYALAIN LAGI KALO BUTUH
  // const [totalRealCost, setTotalRealCost] = useState(0);
  // const [totalCost, setTotalCost] = useState(0);
  // useEffect(() => {
  //   setTotalRealCost(0);
  //   setTotalCost(0);
  //   if (entities.length !== 0) {
  //     entities.forEach((item) => {
  //       setTotalRealCost(
  //         (oldTotalQty) =>
  //           (oldTotalQty += +item.orderLogistic_delivery.real_shipping_cost)
  //       );
  //       setTotalCost(
  //         (oldTotalQty) => (oldTotalQty += +item.orderLogistic_delivery.shipping_cost)
  //       );
  //     });
  //   }
  // }, [entities]);

  // Table columns
  const columns = [
    {
      dataField: 'trx_date',
      text: 'Tanggal',
      footer: () => {
        return (
          <>
            <strong style={{ fontSize: '16px' }}>{`Total (${totalCount})`}</strong>
          </>
        );
      },
    },
    {
      dataField: 'store.market_name',
      text: 'Pengirim',
      footer: '',
    },
    {
      dataField: 'order_delivery.receiver_name',
      text: 'Penerima',
      footer: '',
    },
    {
      dataField: 'order_delivery.shipping_service',
      text: 'Logistic',
      formatter: (cell, row, indexRow) => {
        return (
          <span>
            {cell && cell.company_code} - {cell && cell.service}
          </span>
        );
      },
      footer: '',
    },
    {
      dataField: 'order_delivery.shipping_cost',
      text: 'Perkiraan',
      headerClasses: 'text-right',
      classes: 'text-right',
      formatter: (cell, row, indexRow) => {
        return columnFormatters.currencyFormatter(cell);
      },
      footerClasses: 'text-right',
      footer: (cell) => {
        return columnFormatters.currencyFormatter(total_shipping_cost);
      },
    },
    {
      dataField: 'order_delivery.real_shipping_cost',
      text: 'Aktual',
      headerClasses: 'text-right',
      classes: 'text-right',
      formatter: (cell, row, indexRow) => {
        return columnFormatters.currencyFormatter(cell);
      },
      footerClasses: 'text-right',
      footer: (cell) => {
        return columnFormatters.currencyFormatter(total_real_shipping_cost);
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: orderLogisticUIProps.queryParams.page_size,
    page: orderLogisticUIProps.queryParams.page_number,
  };
  return (
    <>
      <LoadingAnimation open={listLoading} />
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={listLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="uuid"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(orderLogisticUIProps.setQueryParams)}
                // selectRow={getSelectRow({
                //   entities,
                // })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
