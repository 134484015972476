import * as requestFromServer from "./synchronizeMarketplaceCrud";
import {
  synchronizeMarketplaceSlice,
  callTypes,
} from "./synchronizeMarketplaceSlice";
import { toastOption } from "../../../../../_metronic/_helpers";
import { toast } from "react-toastify";

const { actions } = synchronizeMarketplaceSlice;

export const fetchSynchronizeMarketplaces = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findSynchronizeMarketplaces(queryParams)
    .then((response) => {
      const { total_items, items } = response;
      dispatch(actions.synchronizeMarketplacesFetched({ total_items, items }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find synchronizeMarketplaces";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchSynchronizeMarketplaceAll = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllSynchronizeMarketplaces(queryParams)
    .then((response) => {
      dispatch(
        actions.synchronizeMarketplaceALlFetched({
          allData: response.data.data,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find synchronizeMarketplaces";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchSynchronizeMarketplace = (id) => (dispatch) => {
  if (!id) {
    return dispatch(
      actions.synchronizeMarketplaceFetched({
        synchronizeMarketplaceForEdit: undefined,
      })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getSynchronizeMarketplaceById(id)
    .then((response) => {
      dispatch(
        actions.synchronizeMarketplaceFetched({
          synchronizeMarketplaceForEdit: response,
        })
      );
      return response;
    })
    .catch((error) => {
      error.clientMessage = "Can't find synchronizeMarketplace";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateSynchronizeMarketplace =
  (synchronizeMarketplace) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
      .updateSynchronizeMarketplace(synchronizeMarketplace)
      .then((response) => {
        toast.success(response.data.message, toastOption);
        dispatch(
          actions.synchronizeMarketplaceUpdated({ synchronizeMarketplace })
        );
      })
      .catch((error) => {
        error.clientMessage = "Can't update synchronizeMarketplace";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
  };

export const deleteSynchronizeMarketplace = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSynchronizeMarketplace(id)
    .then(() => {
      fetchSynchronizeMarketplaces();
      toast.success("Successful SynchronizeMarketplace Brand!", toastOption);
    })
    .catch((error) => {
      console.log(error);
      error.clientMessage = "Can't delete category";
      toast.error("Delete SynchronizeMarketplace Failed!", toastOption);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const synchronizeMarketplacesFilter = params => dispatch => {
  dispatch(actions.startCall({ callTypes: callTypes.list }))
  dispatch(actions.synchronizeMarketplacesFilter(params))
}
