import axios from 'axios';
import { API } from '../../../../config/UrlEndPoint';
import { gql } from '@apollo/client';
import { MutationGraphql, QueryGraphql } from '../../../../config/ApoloRequest';

export const CUSTOMERS_URL = `${API}story/`;

// CREATE =>  POST: add a new story to the server
// export function createStory(story) {
//   return axios.post(CUSTOMERS_URL, { story });
// }

export async function findStorys(queryParams) {
  const query = gql`
  {
  findStory(findStoryInput: {
    story_text: "${queryParams.story_text}", 
    story_type: "${queryParams.story_type}", 
    brand: "${queryParams.brand}", 
    sort_field: "${queryParams.sortField}",
    sort_order: "${queryParams.sortOrder}",
    is_deleted: false, 
    page_number: ${+queryParams.page_number}, 
    page_size: ${+queryParams.page_size}}) 
    {
    items {
      brand_uuid
      media_type
      story_type
      uuid
      brand {
        file {
          url
        }
        name
      }
      images {
        url
      }
      story_text
    }
    total_items
    total_pages
  }
}
`;

  const newResponse = await QueryGraphql(query);
  return newResponse.data.findStory;
}

export async function getStoryById(id) {
  const query = gql`
  {
  getStory(uuid: "${id}") {
    uuid
    brand_uuid
    media_type
    story_text
    story_type
    video_link
    related_products {
      name
      uuid
    }
    related_products_uuid
    images {
      url
      uuid
      filename
    }
    brand {
      name
      uuid
    }
    related_products {
      name
      uuid
    }
  }
}
`;

  const newResponse = await QueryGraphql(query);
  return newResponse.data.getStory;
}

export const DELETE_STORY = gql`
  mutation M($uuid: ID!) {
    deleteStory(uuid: $uuid) {
      message
    }
  }
`;

export async function deleteStory(id) {
  const query = gql`
    mutation Mutation {
    deleteStory(uuid: "${id}") {
      message
    }
  }`;

  const response = await MutationGraphql(query);
  return response.data.deleteStory;
}

export function getAllStorys() {
  return axios.get(CUSTOMERS_URL);
}

export function updateStory(story) {
  return axios.put(`${CUSTOMERS_URL}/`, story);
}

export const GET_STORY = gql`
  query get_story($uuid: ID!) {
    getStory(uuid: $uuid) {
      uuid
      images {
        uuid
        url
        filename
      }
      brand {
        uuid
        name
      }
      video_link
      related_products {
        uuid
        name
      }
      related_products_uuid
      story_type
      story_text
    }
  }
`;

export const CREATE_STORY = gql`
  mutation Mutation(
    $image: [Upload!]
    $brand: ID!
    $media_type: String
    $related_products: [ID!]!
    $story_text: String!
    $story_type: String!
    $video_link: String
  ) {
    createStory(
      createStoryInput: {
        brand_uuid: $brand
        media_type: $media_type
        related_products_uuid: $related_products
        story_text: $story_text
        story_type: $story_type
        video_link: $video_link
      }
      images: $image
    ) {
      message
      story {
        uuid
      }
    }
  }
`;

export const UPDATE_STORY = gql`
  mutation Mutation(
    $uuid: ID!
    $image: [Upload!]
    $brand: ID
    $media_type: String
    $related_products: [ID!]
    $story_text: String
    $story_type: String
    $video_link: String
    $deletedImageFilenames: [String!]
  ) {
    updateStory(
      updateStoryInput: {
        uuid: $uuid
        video_link: $video_link
        story_type: $story_type
        story_text: $story_text
        related_products_uuid: $related_products
        media_type: $media_type
        brand_uuid: $brand
        deletedImageFilenames: $deletedImageFilenames
      }
      images: $image
    ) {
      message
    }
  }
`;
