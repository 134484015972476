import React from "react";
import { Modal } from "react-bootstrap";

export function DeleteSkuModal({ onHide, show, deleteSku }) {
  return (
    <div>
      <Modal
        size="md"
        show={show}
        centered
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Hapus Tipe Variant?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ textAlign: "center" }}>
            <i
              className="fas fa-exclamation-circle"
              style={{ fontSize: "64px", color: "#F23013" }}
            ></i>
          </div>
          <div className="mt-8">
            <strong>
              Kalau kamu hapus, semua data sku yang telah dimasukkan akan
              hilang.
            </strong>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={onHide}
              className="btn btn-light btn-elevate"
            >
              Cancel
            </button>
            <> </>
            <button
              type="button"
              onClick={deleteSku}
              className="btn btn-primary btn-elevate"
            >
              Yakin
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
