import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Card, CardHeader, CardBody } from '../../../../../../_metronic/_partials/controls';
import { GET_SHIPMENT_TRACKING } from '../../../../MasterData/_redux/shipper-service/shipperServiceCrud';
import TimelineContainer from '../timeline/TimelineContainer';
import TimelineItem from '../timeline/TimelineItem';

export const OrderTrackingLog = ({ order }) => {
  const [delivered, setDelivered] = useState(false);
  const [deliveryStatus, setDeliveryStatus] = useState(null);
  const [manifests, setManifests] = useState([]);

  const [getShipmentTracking] = useLazyQuery(GET_SHIPMENT_TRACKING, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const { getShipmentTracking } = data || {};
      const { delivered, delivery_status, manifest = [] } = getShipmentTracking || {};

      setDelivered(delivered);
      setDeliveryStatus(delivery_status);
      setManifests(manifest);
    },
  });

  useEffect(() => {
    if (order && order.uuid) {
      getShipmentTracking({ variables: { order_uuid: order.uuid } });
    }
  }, [order]);

  return (
    <Card>
      <CardHeader title="Tracking" />
      <CardBody>
        <div className="row m-0 p-0">
          <div className="col-12 col-lg-6">
            <TimelineContainer>
              {manifests &&
                manifests instanceof Array &&
                [...manifests]
                  .sort(
                    (a, b) =>
                      new Date(`${b.manifest_date} ${b.manifest_time}`).getTime() -
                      new Date(`${a.manifest_date} ${a.manifest_time}`).getTime()
                  )
                  .map(({ manifest_description, manifest_date, manifest_time }, index) => (
                    <TimelineItem key={manifest_date + manifest_time + index}>
                      <div className="pb-5 display 5 d-flex flex-column">
                        <span className="font-weight-bold">
                          {`${new Date(manifest_date).toDateString()} `}
                          {manifest_time}
                        </span>
                        <span className="">{manifest_description}</span>
                      </div>
                    </TimelineItem>
                  ))}
            </TimelineContainer>
          </div>
          <div className="col-12 col-lg-6">
            {delivered && (
              <div className="d-flex flex-column">
                <div className="h4">Pesanan Diterima</div>
                <div className="mt-4 d-flex align-items-center">
                  <div className="display-5">Penerima: </div>
                  <div className="display-5 ml-3">
                    {deliveryStatus && deliveryStatus.pod_receiver}
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="display-5">Diterima Pada: </div>
                  <div className="display-5 ml-3">
                    {deliveryStatus && deliveryStatus.pod_date}{' '}
                    {deliveryStatus && deliveryStatus.pod_time}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
