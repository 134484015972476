import React, { useState, useEffect, useContext } from 'react';
import { Field, Formik, useFormikContext } from 'formik';
import { OrderFormContext } from './FormContext';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderToolbar,
  Select,
  Input,
} from '../../../../../_metronic/_partials/controls';

import {
  badge,
  buttonStatus,
  ORDER_ONLINE,
  ORDER_STATUS,
  ORDER_STATUS_OPTIONS,
  ORDER_STATUS_VALIDATION,
} from '../OrderUIHelpers';
import { currencyFormatter } from '../../../../../_metronic/_helpers';
import { useHistory } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useLazyQuery } from '@apollo/client';
import { FIND_STORE } from '../../../MasterData/_redux/store/storeCrud';
import { CreateStoreModal } from './modals/CreateStoreModal';
import BootstrapTable from 'react-bootstrap-table-next';
import { CreatePaymentMethodModal } from './modals/CreatePaymentMethodModal';
import ReactSelect from 'react-select';
import { FIND_PAYMENT_METHOD } from '../../../MasterData/_redux/payment-method/pmCrud';
import CurrencyInput from 'react-currency-input-field';

export const OrderPaymentForm = () => {
  // * Hooks

  // * Contexts
  const { order, orderItems } = useContext(OrderFormContext);
  const { values, setFieldValue, errors } = useFormikContext();
  const { order_payment, order_delivery } = values;

  // * States
  const [pmOptions, setPMOptions] = useState([]);
  const [showPMModal, setShowPMModal] = useState(false);

  // * Queries
  const [fetchPaymentMethods] = useLazyQuery(FIND_PAYMENT_METHOD, {
    onCompleted: (data) => {
      const { findPaymentMethod } = data ?? {};
      const { items = [] } = findPaymentMethod;

      setPMOptions(items.map(({ uuid: value, name: label }) => ({ label, value })));
    },
    onError: (data) => {
      console.log(data);
    },
    fetchPolicy: 'cache-and-network',
  });

  // * Effects
  useEffect(() => {
    fetchPaymentMethods({
      variables: { is_active: true, page_number: 1, page_size: null },
    });
  }, []);

  useEffect(() => {
    const orderAmount = orderItems.reduce((tot, { total_price }) => +tot + +total_price, 0);
    const {
      service_snapshot: { final_price },
    } = order_delivery;

    const newGrandTotal = (+orderAmount ?? 0) + (+final_price ?? 0);
    setFieldValue('order_payment.amount', newGrandTotal);
  }, [orderItems, order_delivery]);

  // * Functions
  function createPaymentMethod() {}

  if (values.uuid) {
    return null;
  }

  return (
    <Card>
      <CardHeader title="Pembayaran"></CardHeader>
      <CreatePaymentMethodModal
        show={showPMModal}
        onHide={() => setShowPMModal(false)}
        submitCreatePM={createPaymentMethod}
        isLoading={false}
      />
      <CardBody>
        <div className="form-group row">
          <div className="col-md-3 mb-5 mb-md-0">
            <label htmlFor="payment_method_uuid">Payment Method</label>
            <ReactSelect
              id="payment_method_uuid"
              name="payment_method_uuid"
              options={pmOptions}
              placeholder="Metode Pembayaran..."
              isDisabled={order && order.uuid}
              value={
                order_payment.payment_method_uuid
                  ? {
                      label: order_payment.payment_method.name,
                      value: order_payment.payment_method_uuid,
                    }
                  : {
                      label: '',
                      value: '',
                    }
              }
              onChange={({ value: payment_method_uuid, label: name }) => {
                setFieldValue('order_payment.payment_method_uuid', payment_method_uuid);
                setFieldValue('order_payment.payment_method.uuid', payment_method_uuid);
                setFieldValue('order_payment.payment_method.name', name);
              }}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  border:
                    errors.order_payment && errors.order_payment.payment_method_uuid
                      ? '1px solid rgb(246, 78, 96)'
                      : '1px solid #ddd',
                }),
                dropdownIndicator: (provided, state) => ({
                  ...provided,
                  color:
                    errors.order_payment && errors.order_payment.payment_method_uuid
                      ? 'rgb(246, 78, 96)'
                      : '#ddd',
                }),
              }}
            />
          </div>
          <div className="col-md-3 mb-5 mb-md-0">
            <label htmlFor="amount">Amount *</label>
            <CurrencyInput
              id="amount"
              name="amount"
              className="form-control"
              disabled={order && order.uuid}
              value={order_payment.amount}
              intlConfig={{ locale: 'id-ID', currency: 'IDR' }}
              decimalsLimit={2}
              placeholder="Total Harga..."
              onValueChange={(value) => {
                setFieldValue('order_payment.amount', +value);
              }}
            />
            {errors.order_payment && errors.order_payment.amount && (
              <div className="mt-2" style={{ color: 'rgb(246, 78, 96)' }}>
                {errors.order_payment.amount}
              </div>
            )}
          </div>
          {order && order.uuid && (
            <div className="col-md-3 mb-5 mb-md-0">
              <label htmlFor="pg_trx_id">PG Transaction ID</label>
              <Field
                id="pg_trx_id"
                name="pg_trx_id"
                disabled={order && order.uuid && true}
                component={Input}
                placeholder="PG Transaction ID..."
                value={order_payment.pg_trx_id}
              />
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  );
};
