import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '../SvgIcon'
import { defaultFill } from '../constant'

const FlashGold = props => (
  <SvgIcon {...props}>
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
        <stop stopColor="#FFE600" offset="0%" />
        <stop stopColor="#AD950F" offset="100%" />
      </linearGradient>
    </defs>
    <g id="User" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Login" transform="translate(-275.000000, -278.000000)" fill="url(#linearGradient-1)">
        <g id="title" transform="translate(273.000000, 278.000000)">
          <path d="M46.6520171,1.42108547e-14 L103.337108,1.42108547e-14 C107.755385,6.29380286e-15 111.337108,3.581722 111.337108,8 C111.337108,8.96789456 111.161465,9.92775568 110.818718,10.8329319 L87.1206909,73.4180606 L87.1206909,73.4180606 L142.168916,73.4180606 C146.587194,73.4180606 150.168916,76.9997826 150.168916,81.4180606 C150.168916,83.3869633 149.442847,85.2867178 148.129668,86.7537356 L44.2592345,202.792658 C43.5225316,203.615666 42.2581358,203.68563 41.4351276,202.948927 C40.8192644,202.397647 40.6059703,201.524308 40.8983807,200.751201 L74.690553,111.407772 L74.690553,111.407772 L8.60696242,111.407772 C5.29325392,111.407772 2.60696242,108.72148 2.60696242,105.407772 C2.60696242,104.699472 2.73237694,103.996767 2.97739839,103.332197 L39.1459317,5.23256755 C40.3051353,2.0884689 43.3010308,1.95790515e-14 46.6520171,1.42108547e-14 Z" id="Path" />
        </g>
      </g>
    </g>
  </SvgIcon>
)

FlashGold.propTypes = {
  fill: PropTypes.string,
}

FlashGold.defaultProps = {
  fill: defaultFill,
}

export default FlashGold
