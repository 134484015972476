import * as requestFromServer from "./supplierCrud";
import { supplierSlice, callTypes } from "./supplierSlice";
import { toastOption } from "../../../../../_metronic/_helpers";
import { toast } from "react-toastify";

const { actions } = supplierSlice;

export const fetchSuppliers = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findSuppliers(queryParams)
    .then((response) => {
      const { total_items, items } = response;
      dispatch(actions.suppliersFetched({ total_items, items }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find suppliers";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchAllSupplier = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllSuppliers(queryParams)
    .then((response) => {
      dispatch(actions.supplierAllFetched({ all: response.data.data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find suppliers";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchSupplier = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.supplierFetched({ supplierForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getSupplierById(id)
    .then((response) => {
      dispatch(actions.supplierFetched({ supplierForEdit: response }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find supplier";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createSupplier = (supplierForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createSupplier(supplierForCreation)
    .then((res) => {
      // console.log(res);
      toast.success("Berhasil menambahkan admin!", toastOption);
      // const {data} = res;
      // dispatch(actions.supplierCreated({ data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create supplier";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateSupplier = (supplier) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateSupplier(supplier)
    .then(() => {
      toast.success("Successful updated Supplier!", toastOption);
      // dispatch(actions.supplierUpdated({ supplier }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update supplier";
      toast.error("Updated Supplier failed!", toastOption);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteSupplier = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSupplier(id)
    .then((res) => {
      // console.log(res);
      toast.success("Successful deleted Supplier!", toastOption);
    })
    .catch((error) => {
      toast.error("Delete Supplier failed!", toastOption);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const supplierFilter = params => dispatch => {
  dispatch(actions.startCall({ callTypes: callTypes.list }))
  dispatch(actions.supplierFilter(params))
}
