import React, { useMemo, useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import { Badge, Collapse } from 'react-bootstrap';
import { MarketplaceTransactionFilter } from './marketplaceTransaction-filter/MarketplaceTransactionFilter';
import { MarketplaceTransactionTable } from './marketplaceTransaction-table/MarketplaceTransactionTable';
import { useMarketplaceTransactionUIContext } from './MarketplaceTransactionUIContext';
import { RoleChecker } from '../../../RoleChecker';
import { MarketplaceTransactionEditDialog } from './marketplaceTransaction-edit/MarketplaceTransactionEdit';

export function MarketplaceTransactionCard({ history }) {
  const marketplaceTransactionUIContext = useMarketplaceTransactionUIContext();
  const marketplaceTransactionUIProps = useMemo(() => {
    return {
      queryParams: marketplaceTransactionUIContext.queryParams,
      newMarketplaceTransactionButtonClick:
        marketplaceTransactionUIContext.newMarketplaceTransactionButtonClick,
    };
  }, [marketplaceTransactionUIContext]);

  const [statusFilter, setStatusFilter] = useState(false);
  const [open, setOpen] = useState(false);

  const changeShow = () => {
    setOpen(!open);

    // Set Status Filter To False
    setStatusFilter(false);
    const filter = marketplaceTransactionUIProps.queryParams;
    for (const [key, value] of Object.entries(filter)) {
      if (value !== '' && key) {
        setStatusFilter(true);
      }
    }
  };

  useEffect(() => {
    if (marketplaceTransactionUIProps.filterType !== undefined) {
      setStatusFilter(true);
      // setIsDisabled(true);
    }
    // eslint-disable-next-line
  }, [marketplaceTransactionUIProps.filterType]);

  return (
    <>
      <Card>
        <CardHeader title="Marketplace Transaction">
          <CardHeaderToolbar>
            {statusFilter && (
              <Badge className="mr-5" pill variant="danger">
                Filter Aktif
              </Badge>
            )}
            {/* {RoleChecker("read", "marketplaceTransaction") && ( */}
            {/* <button
              type="button"
              className="btn btn-warning mr-2"
              onClick={changeShow}
              aria-controls="collapse-filter-marketplaceTransaction"
              aria-expanded={open}
            >
              Cari
            </button> */}
            {/* )} */}
            {RoleChecker('create', 'import_transaction') && (
              <button
                type="button"
                className="btn btn-primary mr-2"
                // onClick={marketplaceTransactionUIProps.newMarketplaceTransactionButtonClick}
                onClick={() => history.push('/marketplace-transaction/new')}
                aria-controls="collapse-filter-marketplaceTransaction"
              >
                Tambah
              </button>
            )}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Collapse in={open}>
            <div id="collapse-filter-marketplaceTransaction">
              <MarketplaceTransactionFilter />
            </div>
          </Collapse>

          <MarketplaceTransactionTable />
        </CardBody>
      </Card>
    </>
  );
}
