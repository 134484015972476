import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import * as actions from "../../../_redux/member/memberActions";
import { MemberAddressEditDialogHeader } from "./MemberAddressEditDialogHeader";
import { MemberAddressEditForm } from "./MemberAddressEditForm";
import { toastOption } from "../../../../../../_metronic/_helpers";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { LoadingAnimation } from "../../loading-animation/LoadingAnimation";
import {
  CREATE_MEMBER_ADDRESS,
  UPDATE_MEMBER_ADDRESS,
} from "../../../_redux/member/memberCrud";

export function MemberAddressEditDialog({ id, memberAddress, show, onHide }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchMember(id));
  }, [memberAddress]);

  const { actionsLoading, memberForEdit, memberLevels, error } = useSelector(
    (state) => ({
      actionsLoading: state.members.actionsLoading,
      memberForEdit: state.members.memberForEdit,
      memberLevels: state.memberLevels.entities,
      error: state.members.error,
    }),
    shallowEqual
  );

  const initValue = {
    label: "Alamat Rumah",
    recipient_name: memberForEdit.name,
    recipient_phone: memberForEdit.hp,
  };

  const [createMember, { loading: createLoading }] = useMutation(
    CREATE_MEMBER_ADDRESS,
    {
      onCompleted: () => {
        toast.success("Berhasil menambahkan alamat member!", toastOption);
        dispatch(actions.fetchMember(id));
        onHide();
      },
      onError: (error) => {
        console.log(error);
        toast.error("Gagal menambahkan alamat member!", toastOption);
      },
    }
  );

  const [updateMember, { loading: updateLoading }] = useMutation(
    UPDATE_MEMBER_ADDRESS,
    {
      onCompleted: () => {
        toast.success("Berhasil update alamat member!", toastOption);
        dispatch(actions.fetchMember(id));
        onHide();
      },
      onError: (error) => {
        console.log(error);
        toast.error("Gagal update alamat member!", toastOption);
      },
    }
  );

  const saveMemberAddress = (data) => {
    // console.log("value", data);
    if (!memberAddress) {
      createMember({
        variables: {
          address: data.address,
          city: data.city,
          codepos: data.codepos,
          label: data.label,
          latitude: data.latitude ? data.latitude.toString() : "",
          longitude: data.longitude ? data.longitude.toString() : "",
          member_uuid: id,
          province: data.province,
          recipient_name: data.recipient_name,
          recipient_phone: data.recipient_phone,
          sub_district: data.sub_district,
          village: data.village ? data.village : "",
          is_default: data.is_default,
        },
      });
    } else {
      updateMember({
        variables: {
          uuid: data.uuid,
          address: data.address,
          city: data.city,
          codepos: data.codepos,
          label: data.label,
          latitude: data.latitude.toString(),
          longitude: data.longitude.toString(),
          member_uuid: id,
          province: data.province,
          recipient_name: data.recipient_name,
          recipient_phone: data.recipient_phone,
          sub_district: data.sub_district,
          village: data.village,
          is_default: data.is_default,
        },
      });
    }
  };

  return (
    <>
      <LoadingAnimation open={createLoading || updateLoading} />
      <Modal
        size="lg"
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
        backdrop="static"
      >
        <MemberAddressEditDialogHeader memberAddress={memberAddress} />
        <MemberAddressEditForm
          saveMemberAddress={saveMemberAddress}
          // actionsLoading={actionsLoading}
          memberAddress={memberAddress ?? initValue}
          onHide={onHide}
        />
      </Modal>
    </>
  );
}
