import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '../SvgIcon'
import { defaultFill } from '../constant'

const Delete = props => (
  <SvgIcon {...props}>
    <path fill={props.fill} className="custom-fill" d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
  </SvgIcon>
)

Delete.propTypes = {
  fill: PropTypes.string,
}

Delete.defaultProps = {
  fill: defaultFill,
}

export default Delete
