import * as requestFromServer from "./marketplaceTransactionCrud";
import {
  marketplaceTransactionSlice,
  callTypes,
} from "./marketplaceTransactionSlice";
import { toastOption } from "../../../../../_metronic/_helpers";
import { toast } from "react-toastify";

const { actions } = marketplaceTransactionSlice;

export const fetchMarketplaceTransactions = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findMarketplaceTransactions(queryParams)
    .then((response) => {
      const { total_items, items } = response;
      dispatch(actions.marketplaceTransactionsFetched({ total_items, items }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find marketplaceTransactions";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchMarketplaceTransactionAll = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllMarketplaceTransactions(queryParams)
    .then((response) => {
      dispatch(
        actions.marketplaceTransactionALlFetched({
          allData: response.data.data,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find marketplaceTransactions";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchMarketplaceTransaction = (id) => (dispatch) => {
  if (!id) {
    return dispatch(
      actions.marketplaceTransactionFetched({
        marketplaceTransactionForEdit: undefined,
      })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getMarketplaceTransactionById(id)
    .then((response) => {
      dispatch(
        actions.marketplaceTransactionFetched({
          marketplaceTransactionForEdit: response,
        })
      );
      return response;
    })
    .catch((error) => {
      error.clientMessage = "Can't find marketplaceTransaction";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateMarketplaceTransaction =
  (marketplaceTransaction) => (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
      .updateMarketplaceTransaction(marketplaceTransaction)
      .then((response) => {
        toast.success(response.data.message, toastOption);
        dispatch(
          actions.marketplaceTransactionUpdated({ marketplaceTransaction })
        );
      })
      .catch((error) => {
        error.clientMessage = "Can't update marketplaceTransaction";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
  };

export const deleteMarketplaceTransaction = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteMarketplaceTransaction(id)
    .then(() => {
      fetchMarketplaceTransactions();
      toast.success("Berhasil menghapus notifikasi!", toastOption);
    })
    .catch((error) => {
      console.log(error);
      error.clientMessage = "Can't delete category";
      toast.error("Gagal menghapus notifikasi!", toastOption);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
