import { useSelector, shallowEqual } from "react-redux";

export function RoleChecker(action, subject) {
  const { user } = useSelector((state) => state.auth, shallowEqual);
  let access = user.access.filter((item) => {
    if (
      item.subject === subject &&
      item.action === action &&
      item.inverted !== true
    ) {
      return item;
    }
  });
  return access.length > 0;
}
