import React from "react";
import { PickupUIProvider } from "./PickupUIContext";
import { PickupCard } from "./PickupCard";

export function PickupPage({ history }) {
  const pickupUIEvents = {
    newPickupButtonClick: () => {
      history.push("/master-data/pickup/new");
    },
    openEditPickupDialog: (id) => {
      history.push(`/master-data/pickup/${id}/edit`);
    },
    openDisablePickupDialog: (id) => {
      history.push(`/master-data/pickup/${id}/disable`);
    },
    openFetchPickupDialog: () => {
      history.push(`/master-data/pickup/fetch`);
    },
    openUpdatePickupStatusDialog: () => {
      history.push("/master-data/pickup/updateStatus");
    },
  };

  return (
    <PickupUIProvider pickupUIEvents={pickupUIEvents}>
      <PickupCard history={history} />
    </PickupUIProvider>
  );
}
