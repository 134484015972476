import React from "react";
import { Route } from "react-router-dom";
import { PurchaseEdit } from "./purchase-edit/PurchaseEdit";
import { PurchaseLoadingDialog } from "./purchase-loading-dialog/PurchaseLoadingDialog";
import { PurchaseUIProvider } from "./PurchaseUIContext";
import { PurchaseCard } from "./PurchaseCard";

export function PurchasePage({ history }) {
  const purchaseUIEvents = {
    newPurchaseButtonClick: () => {
      history.push("/purchase/new");
    },
    openEditPurchase: (id) => {
      history.push(`/purchase/${id}/edit`);
    },
  };

  return (
    <PurchaseUIProvider purchaseUIEvents={purchaseUIEvents}>
      {/*<PurchaseLoadingDialog/>*/}
      {/* <Route path="/purchase/new">
        {({ history, match }) => (
          <PurchaseEdit
            show={match != null}
            onHide={() => {
              history.push("/purchase");
            }}
          />
        )}
      </Route>
      <Route path="/purchase/:id/edit">
        {({ history, match }) => (
          <PurchaseEdit
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/purchase");
            }}
          />
        )}
      </Route> */}
      <PurchaseCard history={history} />
    </PurchaseUIProvider>
  );
}
