import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./SynchronizeMarketplaceUIHelpers";
import { useSelector } from "react-redux";

const SynchronizeMarketplaceUIContext = createContext();

export function useSynchronizeMarketplaceUIContext() {
  return useContext(SynchronizeMarketplaceUIContext);
}

export const SynchronizeMarketplaceUIConsumer =
  SynchronizeMarketplaceUIContext.Consumer;

export function SynchronizeMarketplaceUIProvider({
  synchronizeMarketplaceUIEvents,
  children,
}) {
  const { filters } = useSelector(state => state.synchronizeMarketplace)
  const [queryParams, setQueryParamsBase] = useState(filters);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initSynchronizeMarketplace = {
    id: undefined,
    firstName: "",
    lastName: "",
    email: "",
    userName: "",
    gender: "Female",
    status: 0,
    dateOfBbirth: "",
    ipAddress: "",
    type: 1,
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initSynchronizeMarketplace,
    newSynchronizeMarketplaceButtonClick:
      synchronizeMarketplaceUIEvents.newSynchronizeMarketplaceButtonClick,
    openEditSynchronizeMarketplace:
      synchronizeMarketplaceUIEvents.openEditSynchronizeMarketplace,
  };

  return (
    <SynchronizeMarketplaceUIContext.Provider value={value}>
      {children}
    </SynchronizeMarketplaceUIContext.Provider>
  );
}
