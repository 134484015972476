import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/products/productActions";
import { useProductUIContext } from "../ProductUIContext";
import { toast } from "react-toastify";
import { toastOption } from "../../../../../_metronic/_helpers";
import { useMutation, gql } from "@apollo/client";
import { LoadingAnimation } from "../loading-animation/LoadingAnimation";
import { initValue } from "../ProductUIHelpers";
import { Product } from "./Product";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { ImageProduct } from "./Image";
// import { VariantSku } from "./Variant-Sku";
import { CREATE_PRODUCT } from "../../_redux/products/productCrud";

export function ProductCreate({ id, show, onHide, history, match }) {
  // * Back To List
  const backToList = () => {
    history.push("/product");
  };

  // Product UI Context
  const productUIContext = useProductUIContext();
  // const productUIProps = useMemo(() => {
  //   return {
  //     initProduct: productUIContext.initProduct,
  //   };
  // }, [productUIContext]);

  // Product Redux state
  const dispatch = useDispatch();
  const { actionsLoading, productForEdit } = useSelector(
    (state) => ({
      // actionsLoading: state.product.actionsLoading,
      productForEdit: state.product.productForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    // server call for getting Product by id
    dispatch(actions.fetchProduct(match.params.id));
  }, [id, dispatch]);

  const [createProduct, { loading }] = useMutation(CREATE_PRODUCT, {
    onCompleted: (data) => {
      console.log("data success", data);
      toast.success("Success add new product", toastOption);
      history.push(`/product/${data.createProductOnly.uuid}/edit`);
    },
    onError: (error) => {
      toast.error("Failed add new product", toastOption);
      console.log(error);
    },
  });

  const saveProduct = (params) => {
    // server request for creating product
    createProduct({
      variables: {
        brand_uuid: params.brand,
        description: params.description,
        name: params.name,
        product_category: params.category,
        publish_status: params.publish_status,
        weight: params.weight,
      },
    });
  };

  return (
    <>
      <LoadingAnimation open={loading} />
      <Card>
        <CardHeader title="Tambah Produk">
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={backToList}
              aria-controls="collapse-filter-product"
            >
              <i className="fa fa-arrow-left"></i>
              Kembali
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Product uploadProduct={saveProduct} history={history} />
        </CardBody>
      </Card>
    </>
  );
}
