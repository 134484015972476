import React, { useMemo, useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import { OrderFilter } from './order-filter/OrderFilter';
import { OrderTable } from './order-table/OrderTable';
import { useOrderUIContext } from './OrderUIContext';
import { Badge, Collapse } from 'react-bootstrap';
import { RoleChecker } from '../../../RoleChecker';
import { shallowEqual, useSelector } from 'react-redux';

export function OrderCard({ match, history }) {
  const orderUIContext = useOrderUIContext();
  const orderUIProps = useMemo(() => {
    return {
      queryParams: orderUIContext.queryParams,
      newOrderButtonClick: orderUIContext.newOrderButtonClick,
    };
  }, [orderUIContext]);
  const [statusFilter, setStatusFilter] = useState(false);
  const [open, setOpen] = useState(false);

  const changeShow = () => {
    setOpen(!open);

    // Set Status Filter To False
    setStatusFilter(false);
    const filter = orderUIProps.queryParams;
    for (const [key, value] of Object.entries(filter)) {
      if (value !== '' && key) {
        setStatusFilter(true);
      }
    }
  };

  useEffect(() => {
    if (orderUIProps.filterType !== undefined) {
      setStatusFilter(true);
      // setIsDisabled(true);
    }
    // eslint-disable-next-line
  }, [orderUIProps.filterType]);

  const { filters } = useSelector((state) => state.orders, shallowEqual);
  useEffect(() => {
    if (filters.filterIsOpen) {
      setOpen(true);
    }
  }, []);

  return (
    <Card>
      <CardHeader title="Daftar Order">
        <CardHeaderToolbar>
          {statusFilter && (
            <Badge className="mr-5" pill variant="danger">
              Filter Aktif
            </Badge>
          )}
          {RoleChecker('read', 'order') && (
            <button
              type="button"
              className="btn btn-warning mr-2"
              onClick={changeShow}
              aria-controls="collapse-filter-order"
              aria-expanded={open}
            >
              Cari
            </button>
          )}
          {RoleChecker('create', 'order') && (
            <button
              type="button"
              className="btn btn-primary mr-2"
              onClick={orderUIProps.newOrderButtonClick}
              aria-controls="collapse-filter-product"
              aria-expanded={open}
            >
              Tambah Order
            </button>
          )}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <Collapse in={open}>
          <div id="collapse-filter-order">
            <OrderFilter match={match} history={history} />
          </div>
        </Collapse>

        <OrderTable match={match} />
      </CardBody>
    </Card>
  );
}
