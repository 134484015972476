import React, { useState, useEffect, useContext } from 'react';
import { Field, Formik, useFormikContext } from 'formik';
import { OrderFormContext } from './FormContext';
import { Input } from '../../../../../_metronic/_partials/controls';
import AsyncSelect from 'react-select/async';
import { Switch } from 'antd';

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from '../../../../../_metronic/_partials/controls';
import {
  badge,
  buttonStatus,
  ORDER_ONLINE,
  ORDER_STATUS,
  ORDER_STATUS_OPTIONS,
  ORDER_STATUS_VALIDATION,
} from '../OrderUIHelpers';
import { useHistory } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  CREATE_MEMBER_ADDRESS,
  GET_MEMBER_ADDRESS,
  SEARCH_MEMBER_ANY,
} from '../../../Member/_redux/member/memberCrud';
import { CreateMemberModal } from './modals/CreateMemberModal';
import BootstrapTable from 'react-bootstrap-table-next';
import { CreateMemberAddressModal } from './modals/CreateMemberAddressModal';
import { toast } from 'react-toastify';
import { toastOption } from '../../../../../_metronic/_helpers';
import ReactSelect from 'react-select';

export const OrderMemberAddressTable = () => {
  // * Contexts
  const { order } = useContext(OrderFormContext);
  const { values, setFieldValue, errors } = useFormikContext();

  // * States
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [memberOptions, setMemberOptions] = useState([]);
  const [currentCallback, setCurrentCallback] = useState(null);
  const [memberAddressList, setMemberAddressList] = useState([]);
  const [addressInput, setAddressInput] = useState({
    uuid: '',
    province: '',
    city: '',
    address: '',
    receiver_name: '',
    receiver_hp: '',
    codepos: '',
    sub_district: '',
  });

  // * Queries
  const [fetchMembers] = useLazyQuery(SEARCH_MEMBER_ANY, {
    onCompleted: (data) => {
      const { findMember } = data ?? {};
      const { items = [] } = findMember;

      if (items instanceof Array) {
        const options = items.map(({ uuid, name }) => ({
          label: name,
          value: uuid,
        }));

        setMemberOptions(options);

        if (currentCallback instanceof Function) {
          currentCallback(options);
        }
      }
    },
    onError: (error) => {
      console.error(error);
    },
    fetchPolicy: 'cache-and-network',
  });
  const [fetchMemberAddress] = useLazyQuery(GET_MEMBER_ADDRESS, {
    onCompleted: (data) => {
      const { getMember } = data ?? {};
      const { member_address } = getMember ?? {};

      if (member_address instanceof Array) {
        setMemberAddressList(member_address);
      }
    },
    onError: (error) => {
      console.error(error);
    },
    fetchPolicy: 'cache-and-network',
  });
  const [fetchCreateMemberAddress, { loading: createMemberAddressLoading }] = useMutation(
    CREATE_MEMBER_ADDRESS,
    {
      onCompleted: (data) => {
        const { createMemberAddress = {} } = data ?? {};

        setFieldValue('order_delivery.address_uuid', createMemberAddress.uuid);
        setFieldValue('order_delivery.province', createMemberAddress.province);
        setFieldValue('order_delivery.city', createMemberAddress.city);
        setFieldValue('order_delivery.address', createMemberAddress.address);
        setFieldValue('order_delivery.receiver_name', createMemberAddress.recipient_name);
        setFieldValue('order_delivery.receiver_hp', createMemberAddress.recipient_phone);
        setFieldValue('order_delivery.codepos', createMemberAddress.codepos);
        setFieldValue('order_delivery.sub_district', createMemberAddress.sub_district);

        if (values.member && values.member.uuid) {
          fetchMemberAddress({ variables: { uuid: values.member.uuid } });
        }

        setShowAddressModal(false);
      },
      onError: (error) => {
        toast.error(error, toastOption);
      },
    }
  );

  // * Effects
  useEffect(() => {
    fetchMembers({ variables: { page_number: 1, page_size: 20 } });
  }, []);

  useEffect(() => {
    if (values.member && values.member.uuid) {
      fetchMemberAddress({ variables: { uuid: values.member.uuid } });
    }
  }, [values.member]);

  // * Functions
  function createMemberAddress(payload) {
    if (createMemberAddressLoading) {
      return;
    }

    if (values.member && values.member.uuid) {
      fetchCreateMemberAddress({
        variables: {
          address: payload.address,
          city: payload.city,
          codepos: payload.codepos,
          is_default: payload.is_default,
          label: payload.label,
          member_uuid: values.member.uuid,
          province: payload.province,
          recipient_name: payload.recipient_name,
          recipient_phone: payload.recipient_phone,
          sub_district: payload.sub_district,
          village: payload.village,
        },
      });
    }
  }

  // * Constants
  const selectRow = {
    mode: 'radio',
    clickToSelect: true,
    onSelect: (row) => {
      setFieldValue('order_delivery.address_uuid', row.uuid);
      setFieldValue('order_delivery.province', row.province);
      setFieldValue('order_delivery.city', row.city);
      setFieldValue('order_delivery.address', row.address);
      setFieldValue('order_delivery.receiver_name', row.recipient_name);
      setFieldValue('order_delivery.receiver_hp', row.recipient_phone);
      setFieldValue('order_delivery.codepos', row.codepos);
      setFieldValue('order_delivery.sub_district', row.sub_district);
    },
  };

  const addressColumns = [
    {
      dataField: 'province',
      text: 'Provinsi',
    },
    {
      dataField: 'city',
      text: 'Kota',
    },
    {
      dataField: 'sub_district',
      text: 'Kecamatan',
    },
    {
      dataField: 'codepos',
      text: 'Kode Pos',
    },
    {
      dataField: 'address',
      text: 'Alamat',
    },
  ];

  if (values.uuid) {
    return null;
  }

  if (!values.is_online || !values.member.uuid) {
    return null;
  }

  return (
    <Card>
      <CreateMemberAddressModal
        show={showAddressModal}
        onHide={() => setShowAddressModal(false)}
        isLoading={createMemberAddressLoading}
        submitCreateMemberAddress={createMemberAddress}
      />
      <CardHeader title={values.uuid ? 'Pengiriman' : 'Pilih Alamat'}>
        <CardHeaderToolbar>
          {!values.uuid && (
            <button className="btn btn-success" onClick={() => setShowAddressModal(true)}>
              Tambah Member Address
            </button>
          )}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {values.is_dropship ? (
          <div className="form-group row">
            <div className="col-12 col-md-8 col-lg-6">
              <ReactSelect
                name="search_address"
                options={memberAddressList.map((item) => ({
                  ...item,
                  value: item.uuid,
                  label: `${item.recipient_name} - ${item.recipient_phone} (${item.label})`,
                }))}
                placeholder="Cari Alamat..."
                onChange={(item) => {
                  setFieldValue('order_delivery.address_uuid', item.uuid);
                  setFieldValue('order_delivery.province', item.province);
                  setFieldValue('order_delivery.city', item.city);
                  setFieldValue('order_delivery.address', item.address);
                  setFieldValue('order_delivery.receiver_name', item.recipient_name);
                  setFieldValue('order_delivery.receiver_hp', item.recipient_phone);
                  setFieldValue('order_delivery.codepos', item.codepos);
                  setFieldValue('order_delivery.sub_district', item.sub_district);
                }}
              />
            </div>
          </div>
        ) : (
          <BootstrapTable
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            bootstrap4
            remote
            keyField="uuid"
            data={memberAddressList}
            columns={addressColumns}
            selectRow={selectRow}
          />
        )}
      </CardBody>
    </Card>
  );
};
