import React, { useEffect, useState } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { useDispatch } from "react-redux";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
} from "../../../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
  Pagination,
} from "../../../../../_metronic/_partials/controls";
import { initialParams, sizePerPageList } from "./OverStockHelper";

const FIND_OVER_STOCK = gql`
  query Q(
    $page_number: Int
    $page_size: Int
    $sort_field: String
    $sort_order: String
  ) {
    __typename
    findOverStockedSku(
      findOverStockedSkuInput: {
        page_number: $page_number
        page_size: $page_size
        sort_field: $sort_field
        sort_order: $sort_order
      }
    ) {
      items {
        sku
        product_name
        variant_value_1
        variant_value_2
        stock
      }
      total_items
    }
  }
`;

export default function OverStockTable() {
  const [queryParams, setQueryParams] = useState(initialParams);

  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const [findOverStock, { loading }] = useLazyQuery(FIND_OVER_STOCK, {
    onCompleted: (data) => {
      const { findOverStockedSku } = data;
      setData(findOverStockedSku.items);
      setTotalItems(findOverStockedSku.total_items);
    },
    onError: (err) => {
      console.log("err", err);
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    findOverStock({
      variables: {
        ...queryParams,
      },
    });
  }, [queryParams]);

  // ! Table
  const columns = [
    {
      dataField: "sku",
      text: "SKU",
      sort: true,
    },
    {
      dataField: "product_name",
      text: "Nama Produk",
      sort: true,
    },
    {
      dataField: "",
      text: "Variant",
      sort: true,
      formatter: (cell, row, indexRow) => {
        const variant1 = row.variant_value_1;
        const variant2 = row.variant_value_2;
        return (
          <>
            <span>{variant1 && variant1}</span>
            {variant2 && <span>, {variant2}</span>}
          </>
        );
      },
    },
    {
      dataField: "stock",
      text: "Stok",
      sort: true,
    },
  ];

  const paginationOptions = {
    custom: true,
    totalSize: totalItems,
    sizePerPageList: sizePerPageList,
    sizePerPage: queryParams.page_size,
    page: queryParams.page_number,
  };

  return (
    <>
      <Card>
        <CardHeader title="OverStock" />
        <CardBody>
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => {
              return (
                <Pagination
                  isLoading={loading}
                  paginationProps={paginationProps}
                >
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    bordered={false}
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    bootstrap4
                    remote
                    keyField="uuid"
                    onTableChange={getHandlerTableChange(setQueryParams)}
                    data={data}
                    columns={columns}
                    {...paginationTableProps}
                  ></BootstrapTable>
                </Pagination>
              );
            }}
          </PaginationProvider>
        </CardBody>
      </Card>
    </>
  );
}
