// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/marketplaceTransactionbook/index.html
import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/marketplaceTransaction/marketplaceTransactionActions";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
  toastOption,
} from "../../../../../_metronic/_helpers";
import * as uiHelpers from "../MarketplaceTransactionUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../_metronic/_partials/controls";
import { useMarketplaceTransactionUIContext } from "../MarketplaceTransactionUIContext";
import { MarketplaceTransactionDisableDialog } from "../marketplaceTransaction-disable-dialog/MarketplaceTransactionDisableDialog";
import { RoleChecker } from "../../../../RoleChecker";
import { Link } from "react-router-dom";
import { ImagesModal } from "../../../../components/ImagesModal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { RESEND_NOTIF } from "../../_redux/marketplaceTransaction/marketplaceTransactionCrud";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import moment from "moment";

export function MarketplaceTransactionTable() {
  const allowDelete = RoleChecker("delete", "marketplaceTransaction");
  const allowUpdate = RoleChecker("update", "marketplaceTransaction");
  // marketplaceTransaction UI Context
  const marketplaceTransactionUIContext = useMarketplaceTransactionUIContext();
  const marketplaceTransactionUIProps = useMemo(() => {
    return {
      ids: marketplaceTransactionUIContext.ids,
      setIds: marketplaceTransactionUIContext.setIds,
      queryParams: marketplaceTransactionUIContext.queryParams,
      setQueryParams: marketplaceTransactionUIContext.setQueryParams,
      openEditMarketplaceTransaction:
        marketplaceTransactionUIContext.openEditMarketplaceTransaction,
      // openDeleteMarketplaceTransactionDialog: marketplaceTransactionUIContext.openDeleteMarketplaceTransactionDialog,
    };
  }, [marketplaceTransactionUIContext]);

  const [showModal, setShowModal] = useState(false);
  const [rowId, setRowId] = useState();
  // Getting curret state of marketplaceTransaction list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.marketplaceTransaction }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;

  // marketplaceTransaction Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    marketplaceTransactionUIProps.setIds([]);
    // server call by queryParams
    dispatch(
      actions.fetchMarketplaceTransactions(
        marketplaceTransactionUIProps.queryParams
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketplaceTransactionUIProps.queryParams, dispatch]);
  // Table columns
  const columns = [
    {
      dataField: "marketplace",
      text: "Marketplace",
      // sort: true,
    },
    {
      dataField: "trx_date_start",
      text: "Tanggal Mulai",
      // sort: true,
      formatter: (cell, row, indexRow) => {
        return moment(cell).format("YYYY-MM-DD");
      },
    },
    {
      dataField: "trx_date_end",
      text: "Tanggal Akhir",
      // sort: true,
      formatter: (cell, row, indexRow) => {
        return moment(cell).format("YYYY-MM-DD");
      },
    },
    {
      dataField: "synchronized_count",
      text: "Jumlah Data",
      // sort: true,
      formatter: (cell, row, rowIndex) => {
        return row.synchronized_count + row.unsynchronized_count;
      },
    },
    {
      dataField: "synchronized_count",
      text: "Terhubung",
      // sort: true,
    },
    {
      dataField: "unsynchronized_count",
      text: "Tidak Terhubung",
      // sort: true,
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditMarketplaceTransaction:
          marketplaceTransactionUIProps.openEditMarketplaceTransaction,
        // openDeleteMarketplaceTransactionDialog: marketplaceTransactionUIProps.openDeleteMarketplaceTransactionDialog,
        showModal: setShowModal,
        allowDelete,
        allowUpdate,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: marketplaceTransactionUIProps.queryParams.page_size,
    page: marketplaceTransactionUIProps.queryParams.page_number,
  };
  return (
    <>
      <MarketplaceTransactionDisableDialog
        show={showModal}
        onHide={() => setShowModal(false)}
        id={rowId}
      />
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="file_uuid"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  marketplaceTransactionUIProps.setQueryParams
                )}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: marketplaceTransactionUIProps.ids,
                //   setIds: marketplaceTransactionUIProps.setIds,
                // })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
