import React from 'react';
import { Modal } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import { Input } from '../../../../../../_metronic/_partials/controls';
import { ORDER_STATUS } from '../../OrderUIHelpers';
import { toast } from 'react-toastify';

export const VoidModal = ({ onHide, show, updateVoid, order }) => {
  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{ reason: '' }}
        onSubmit={(values) => {
          // console.log('values', values);
          if (
            order.uuid &&
            [
              ORDER_STATUS.RECEIVED,
              ORDER_STATUS.RECEIVED_CONFIRM,
              // ORDER_STATUS.SENDING,
              // ORDER_STATUS.FINISH,
            ].includes(order.status) &&
            !values.reason
          ) {
            toast.error('Alasan harus di isi');
            return;
          }
          updateVoid(values.reason);
        }}
      >
        {({ handleSubmit, errors }) => (
          <Modal
            size="md"
            show={show}
            centered
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg">Void Order?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={{ textAlign: 'center' }}>
                <i
                  className="fas fa-exclamation-circle"
                  style={{ fontSize: '64px', color: '#F23013' }}
                ></i>
              </div>
              <div className="mt-8 text-center">
                <strong>Apakah kamu yakin ingin void orderan ini?</strong>
              </div>
              {order.uuid &&
                [
                  ORDER_STATUS.SENDING,
                  ORDER_STATUS.RECEIVED,
                  ORDER_STATUS.RECEIVED_CONFIRM,
                  ORDER_STATUS.FINISH,
                ].includes(order.status) && (
                  <div>
                    <label htmlFor="reason">Alasan Void</label>
                    <Field
                      id="reason"
                      name="reason"
                      type="text"
                      disabled={false}
                      component={Input}
                    />
                  </div>
                )}
            </Modal.Body>
            <Modal.Footer>
              <div>
                <button type="button" onClick={onHide} className="mr-4 btn btn-light btn-elevate">
                  Cancel
                </button>
                <button type="button" onClick={handleSubmit} className="btn btn-danger btn-elevate">
                  Yakin
                </button>
              </div>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </div>
  );
};
