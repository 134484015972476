import { Table, Tag, Space } from "antd";
import "antd/dist/antd.css";
import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { CardBody } from "../../../../_metronic/_partials/controls";
import StockAdjustmentModal from "./StockAdjustmentModal";
import moment from "moment";
import { Link } from "react-router-dom";

export default function FormTable({
  dataStock,
  stockBranch,
  saveAdjustmentStock,
}) {
  const [showModal, setShowModal] = useState(false);
  const [branchData, setBranchData] = useState();

  const columnStocks = [
    {
      dataField: "",
      text: "No",
      formatter: (cell, row, indexRow) => {
        return indexRow + 1;
      },
    },
    {
      dataField: "created_at",
      text: "Tanggal",
      formatter: (cell, row, indexRow) => {
        return moment(cell).format("YYYY-MM-DD");
      },
    },
    {
      dataField: "trans_type",
      text: "Jenis",
      formatter: (cell, row, indexRow) => {
        const LinkTo = () => {
          let link;
          switch (cell) {
            case "ORDER":
              link = <Link to={`/order/${row.trans_uuid}/edit`}>{cell}</Link>;
              break;
            case "PURCHASE":
              link = (
                <Link to={`/purchase/${row.trans_uuid}/edit`}>{cell}</Link>
              );
              break;

            default:
              link = <span>{cell}</span>;
              break;
          }
          return link;
        };
        return LinkTo();
      },
    },
    {
      dataField: "branch.name",
      text: "Gudang",
    },
    {
      dataField: "qty",
      text: "QTY",
    },
  ];

  const columnBranch = [
    {
      dataField: "name",
      text: "Gudang",
    },
    {
      dataField: "stock",
      text: "Stok",
    },
    {
      align: "right",
      formatter: (cell, row, indexRow) => {
        return (
          <button
            className="btn btn-outline-primary"
            onClick={() => {
              setShowModal(true);
              setBranchData(row);
            }}
          >
            Penyesuaian Stok
          </button>
        );
      },
    },
  ];

  return (
    <>
      <StockAdjustmentModal
        show={showModal}
        onHide={() => setShowModal(false)}
        branchData={branchData}
        saveAdjustmentStock={saveAdjustmentStock}
      />
      <CardBody>
        <BootstrapTable
          wrapperClasses="table-responsive"
          bordered={false}
          classes="table table-head-custom table-vertical-center overflow-hidden"
          bootstrap4
          remote
          keyField="uuid"
          data={stockBranch}
          columns={columnBranch}
        >
          {/* <PleaseWaitMessage entities={entities} /> */}
          {/* <NoRecordsFoundMessage entities={entities} /> */}
        </BootstrapTable>
      </CardBody>
      <CardBody>
        <BootstrapTable
          wrapperClasses="table-responsive"
          bordered={false}
          classes="table table-head-custom table-vertical-center overflow-hidden"
          bootstrap4
          remote
          keyField="trans_uuid"
          data={dataStock}
          columns={columnStocks}
        >
          {/* <PleaseWaitMessage entities={entities} /> */}
          {/* <NoRecordsFoundMessage entities={entities} /> */}
        </BootstrapTable>
      </CardBody>
    </>
  );
}
