import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import { Input, Select } from '../../../../../../_metronic/_partials/controls';
import { useLazyQuery } from '@apollo/client';
import AsyncSelect from 'react-select/async';
import ReactSelect from 'react-select';
import { GET_ADDRESS } from '../../../_redux/order/orderCrud';
import { CircularProgress } from '@material-ui/core';

const MemberAddressEditSchema = Yup.object().shape({
  label: Yup.string().required('label is required'),
  city: Yup.string().required('Kota is required'),
  sub_district: Yup.string().required('Kecamatan is required'),
  address: Yup.string().required('Alamat is required'),
  province: Yup.string().required('Provinsi is required'),
  recipient_name: Yup.string().required('Recipient Name is required'),
  recipient_phone: Yup.string().required('Recipient Phone is required'),
  // latitude: Yup.number().required('Latitude is required'),
  // longitude: Yup.number().required('Longitude is required'),
  village: Yup.string().optional('Village is required'),
  codepos: Yup.string().required('Kode Pos is required'),
});

export const CreateMemberAddressModal = ({
  show,
  onHide,
  isLoading,
  submitCreateMemberAddress,
}) => {
  // * Find Address
  const [currentTimeout, setCurrentTimeout] = useState(null);
  const [currentCallback, setCurrentCallback] = useState(() => () => null);
  const [codeposOptions, setCodeposOptions] = useState([]);

  const [searchAddress] = useLazyQuery(GET_ADDRESS, {
    variables: {
      keyword: '',
    },
    onCompleted: (data) => {
      const { findAddressSuggestion } = data;

      if (findAddressSuggestion.items instanceof Array) {
        currentCallback(
          findAddressSuggestion.items.map(({ city, province_name, district, postal_codes }) => ({
            label: `${district}, ${city}, ${province_name}`,
            city,
            province_name,
            district,
            postal_codes,
          }))
        );
      }
    },
    fetchPolicy: 'cache-and-network',
  });

  const loadAddressOptions = (inputValue, callback) => {
    clearTimeout(currentTimeout);

    if (inputValue.length >= 1) {
      setCurrentTimeout(
        setTimeout(() => {
          searchAddress({ variables: { keyword: inputValue } });
          setCurrentCallback(() => callback);
        }, 250)
      );
    } else {
      callback([]);
    }
  };

  return (
    <Modal size="lg" show={show} onHide={onHide} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Buat Member Address Baru</Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize={true}
        initialValues={{
          label: 'Alamat Rumah',
          recipient_name: '',
          recipient_phone: '',
          city: '',
          address: '',
          codepos: '',
          sub_district: '',
          latitude: '',
          longitude: '',
          village: '',
        }}
        validationSchema={MemberAddressEditSchema}
        onSubmit={(values) => {
          submitCreateMemberAddress(values);
        }}
      >
        {({ handleSubmit, values, setFieldValue, errors }) => (
          <>
            <Modal.Body>
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col">
                    <Field name="label" component={Input} placeholder="Label" label="Label" />
                  </div>
                  <div className="col">
                    <Select
                      name="is_default"
                      label="Alamat Utama (Default)"
                      onChange={(e) => {
                        let data = false;
                        if (e.target.value === 'true') data = true;
                        setFieldValue('is_default', data);
                      }}
                    >
                      <option value="true">true</option>
                      <option value="false">false</option>
                    </Select>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col">
                    <Field
                      name="recipient_name"
                      component={Input}
                      placeholder="Nama Penerima"
                      label="Nama Penerima"
                    />
                  </div>
                  <div className="col">
                    <Field
                      name="recipient_phone"
                      component={Input}
                      placeholder="No Telp Penerima"
                      label="No Telp Penerima"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col">
                    <label>Kota / Kecamatan</label>
                    <AsyncSelect
                      id="address_suggestion"
                      cacheOptions={false}
                      name="address_suggestion"
                      placeholder="Cari Kota / Kecamatan..."
                      noOptionsMessage={({ inputValue }) =>
                        inputValue.length >= 1
                          ? 'Kota / Kecamatan tidak di temukan'
                          : 'Ketik untuk mencari Kota / Kecamatan'
                      }
                      value={
                        values.city && values.sub_district && values.province
                          ? {
                              label: `${values.sub_district}, ${values.city}, ${values.province}`,
                              value: values.city,
                            }
                          : {
                              label: '',
                              value: '',
                            }
                      }
                      loadOptions={loadAddressOptions}
                      defaultOptions={false}
                      onChange={({ city, province_name, district, postal_codes }) => {
                        setFieldValue('city', city);
                        setFieldValue('province', province_name);
                        setFieldValue('sub_district', district);
                        setFieldValue('codepos', '');
                        setCodeposOptions(postal_codes);
                      }}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          border: errors.branch_uuid
                            ? '1px solid rgb(246, 78, 96)'
                            : '1px solid #ddd',
                        }),
                        dropdownIndicator: (provided, state) => ({
                          ...provided,
                          color: errors.branch_uuid ? 'rgb(246, 78, 96)' : '#ddd',
                        }),
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col">
                    <Field
                      name="village"
                      component={Input}
                      placeholder="Kelurahan"
                      label="Kelurahan"
                    />
                  </div>
                  <div className="col">
                    <label>Kode Pos</label>
                    <ReactSelect
                      id="codepos"
                      name="codepos"
                      type="number"
                      options={codeposOptions.map((code) => ({
                        label: code,
                        value: code,
                      }))}
                      value={
                        values.codepos
                          ? {
                              label: values.codepos,
                              value: values.codepos,
                            }
                          : null
                      }
                      placeholder="Kode Pos..."
                      onChange={(e) => {
                        setFieldValue('codepos', e.value);
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col">
                    <label>Alamat Lengkap</label>
                    <Field
                      name="address"
                      placeholder="Address"
                      label="Address"
                      as="textarea"
                      className="form-control"
                    />
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
                disabled={isLoading && true}
              >
                Cancel
                {isLoading && <span className="ml-3 spinner spinner-white"></span>}
              </button>
              <> </>
              <button
                type="button"
                onClick={handleSubmit}
                className="btn btn-primary btn-elevate"
                style={{ width: '75px' }}
              >
                {isLoading ? <CircularProgress color="inherit" size={14} /> : <span>Save</span>}
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
};
