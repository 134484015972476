import React, { useState, useEffect, useContext } from 'react';
import { Field, Formik, useFormikContext } from 'formik';
import { OrderFormContext } from './FormContext';
import { Input } from '../../../../../_metronic/_partials/controls';
import AsyncSelect from 'react-select/async';
import { Switch } from 'antd';

import {
  Card,
  CardHeader,
  CardHeaderToolbar,
  CardBody,
} from '../../../../../_metronic/_partials/controls';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CREATE_MEMBER, SEARCH_MEMBER_ANY } from '../../../Member/_redux/member/memberCrud';
import { CreateMemberModal } from './modals/CreateMemberModal';

export const OrderMemberForm = () => {
  // * Contexts
  const { order } = useContext(OrderFormContext);
  const { values, setFieldValue, errors, touched } = useFormikContext();

  // * States
  const [showMemberModal, setShowMemberModal] = useState(false);
  const [createMemberCallback, setCreateMemberCallback] = useState(() => () => null);
  const [memberOptions, setMemberOptions] = useState([]);
  const [currentTimeout, setCurrentTimeout] = useState(null);
  const [currentCallback, setCurrentCallback] = useState(null);

  // * Queries
  const [fetchMembers] = useLazyQuery(SEARCH_MEMBER_ANY, {
    onCompleted: (data) => {
      const { findMember } = data ?? {};
      const { items = [] } = findMember;

      if (items instanceof Array) {
        const options = items.map(({ uuid, name, ...member }) => ({
          label: name,
          value: uuid,
          ...member,
        }));

        setMemberOptions(options);

        if (currentCallback instanceof Function) {
          currentCallback(options);
        }
      }
    },
    onError: (error) => {
      console.error(error);
    },
    fetchPolicy: 'cache-and-network',
  });
  const [fetchCreateMember, { loading: createMemberLoading }] = useMutation(CREATE_MEMBER, {
    onCompleted: (data) => {
      const { createMember = {} } = data ?? {};

      setFieldValue('member.uuid', createMember.uuid);
      setFieldValue('member.email', createMember.email);
      setFieldValue('member.hp', createMember.hp);

      setShowMemberModal(false);
    },
  });

  // * Effects
  useEffect(() => {
    fetchMembers({ variables: { page_number: 1, page_size: 20 } });
  }, []);

  // * Functions
  function createMember(values) {
    const { email, hp, name, password } = values;

    fetchCreateMember({
      variables: { email, hp, name, password },
    });
  }

  function loadOptions(queryCaller, inputValue, callback) {
    clearTimeout(currentTimeout);

    if (inputValue.length >= 3) {
      setCurrentTimeout(
        setTimeout(() => {
          queryCaller({ variables: { nameEmailHp: inputValue } });
          setCurrentCallback(() => callback);
        }, 250)
      );
    } else {
      callback([]);
    }
  }

  return (
    <Card>
      <CreateMemberModal
        show={showMemberModal}
        onHide={() => setShowMemberModal(false)}
        isLoading={createMemberLoading}
        submitCreateMember={createMember}
      />
      <CardHeader title="Data Pelanggan">
        <CardHeaderToolbar>
          <div className="d-flex align-items-center">
            <AsyncSelect
              name="member_uuid"
              defaultOptions={memberOptions}
              isDisabled={order && order.uuid}
              loadOptions={(inputValue, callback) =>
                loadOptions(fetchMembers, inputValue, callback)
              }
              value={
                values.member
                  ? {
                      label: values.member.name,
                      value: values.member.uuid,
                    }
                  : {
                      label: '',
                      value: '',
                    }
              }
              onChange={({ value: uuid, label: name, email, hp, dropship_name, dropship_hp }) => {
                setFieldValue('member', { uuid, name, email, hp });
                setFieldValue('dropship_name', dropship_name);
                setFieldValue('dropship_hp', dropship_hp);
              }}
              noOptionsMessage={({ inputValue }) =>
                inputValue.length >= 3 ? (
                  <>
                    <p>Member tidak ditemukan</p>
                    <button
                      type="button"
                      className="btn btn-sm btn-secondary mr-2"
                      aria-controls="collapse-filter-product"
                      onClick={() => {
                        setShowMemberModal(true);
                        setCreateMemberCallback(() => (newMember) => {});
                      }}
                    >
                      Tambah
                    </button>
                  </>
                ) : (
                  <span>Ketik 3 huruf atau lebih untuk mencari member.</span>
                )
              }
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  width: '250px',
                  border:
                    errors.member && touched.member
                      ? '1px solid rgb(246, 78, 96)'
                      : '1px solid #ddd',
                }),
                dropdownIndicator: (provided, state) => ({
                  ...provided,
                  color: errors.member && touched.member ? 'rgb(246, 78, 96)' : '#ddd',
                }),
              }}
            />

            <div className="ml-5 d-flex flex-nowrap">
              <div className="mr-3 text-nowrap">DROPSHIP :</div>
              <Switch
                className="mr-3"
                name="is_dropship"
                checked={values.is_dropship}
                disabled={values.uuid}
                onChange={(e) => {
                  setFieldValue('is_dropship', e);
                }}
              />
            </div>
          </div>
        </CardHeaderToolbar>
      </CardHeader>
      {values.member && values.member.uuid && (
        <CardBody>
          <div className="row m-0 p-0">
            {values.is_dropship ? (
              <>
                {order && order.uuid ? (
                  <>
                    <div className="col-4 col-md-2">Nama Dropship</div>
                    <div className="col-8 col-md-10">
                      <span>:</span>
                      <span className="ml-3">{values.dropship_name}</span>
                    </div>
                    <div className="col-4 col-md-2">No Hp Dropship</div>
                    <div className="col-8 col-md-10">
                      <span>:</span>
                      <span className="ml-3">{values.dropship_hp}</span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-md-4 mb-5 mb-md-0">
                      <label htmlFor="dropship_name">Nama Dropship *</label>
                      <Field
                        id="dropship_name"
                        name="dropship_name"
                        disabled={Boolean(values.uuid)}
                        component={Input}
                        value={values.dropship_name}
                        onChange={(e) => {
                          setFieldValue('dropship_name', e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-md-4 mb-5 mb-md-0">
                      <label htmlFor="dropship_hp">No Hp Dropship *</label>
                      <Field
                        id="dropship_hp"
                        name="dropship_hp"
                        disabled={Boolean(values.uuid)}
                        component={Input}
                        value={values.dropship_hp}
                        onChange={(e) => {
                          setFieldValue('dropship_hp', e.target.value);
                        }}
                      />
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div className="col-4 col-md-2">Nama</div>
                <div className="col-8 col-md-10">
                  <span>:</span>
                  <span className="ml-3">{values.member.name}</span>
                </div>
                <div className="col-4 col-md-2">Email</div>
                <div className="col-8 col-md-10">
                  <span>:</span>
                  <span className="ml-3">{values.member.email}</span>
                </div>
                <div className="col-4 col-md-2">Hp</div>
                <div className="col-8 col-md-10">
                  <span>:</span>
                  <span className="ml-3">{values.member.hp}</span>
                </div>
              </>
            )}
          </div>
        </CardBody>
      )}
    </Card>
  );
};
