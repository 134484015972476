import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import * as columnFormatters from "./column-formatters";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import SVG from "react-inlinesvg";
import { currencyFormatter, getStorage, toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import PrintLabelModal from "./PrintLabelModal";
import { statusPurchase } from "../PurchaseUIHelpers";

const PurchaseProductTable = ({ purchase, rows, removeProduct, dataPrint, openProductModal }) => {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState({});

  const columns = [
    {
      text: "Actions",
      footer: "Sub Total:",
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex align-items-center">
            {purchase.status !== "PROCESSING" && purchase.status !== "SETTLE" && (
              <a
                title="Delete"
                className={`btn btn-icon btn-light btn-hover-danger btn-sm mr-5 flex-shrink-0`}
                onClick={() => {
                  removeProduct(row.product_sku_uuid);
                }}
              >
                <span className="svg-icon svg-icon-md svg-icon-danger">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                </span>
              </a>
            )}
            {purchase.uuid && !row.is_printed && (
              <a
                title="Print"
                className={`btn btn-icon btn-light btn-hover-dark btn-sm mr-5 flex-shrink-0`}
                onClick={() => {
                  setShowModal(true);
                  setData(row);
                }}
              >
                {row.is_printed}
                <span className="svg-icon svg-icon-md svg-icon-dark">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Barcode.svg")} />
                </span>
              </a>
            )}
          </div>
        </>
      ),
    },
    {
      dataField: "sku",
      footer: "",
      text: "SKU",
    },
    {
      dataField: "product_name",
      footer: "",
      text: "Product Name",
    },
    {
      dataField: "price",
      text: "Price",
      footer: "",
      headerAlign: "right",
      classes: "text-right",
      formatter: (cell, row, rowIndex) => {
        return <span>{currencyFormatter(cell)}</span>;
      },
    },
    {
      dataField: "qty",
      text: "Qty",
      footer: (cell, row) => {
        const totalQty = cell.reduce((prev, curr) => prev + curr, 0);
        return <span>{totalQty}</span>;
      },
    },
    {
      dataField: "total_price",
      text: "Total Price",
      headerAlign: "right",
      classes: "text-right",
      formatter: (cell, row, rowIndex) => {
        return <span>{currencyFormatter(cell)}</span>;
      },
      footerAlign: "right",
      footer: (cell, row) => {
        const sum = cell.reduce((prev, curr) => prev + curr, 0);
        return currencyFormatter(sum);
      },
    },
  ];

  return (
    <>
      <PrintLabelModal
        initValue={data}
        show={showModal}
        onHide={() => setShowModal(false)}
        onClick={dataPrint}
      />
      <Card>
        <CardHeader title="Daftar Produk yang akan dibeli">
          <CardHeaderToolbar>
            {purchase.status !== statusPurchase.SETTLE && (
              <button className="btn btn-outline-primary" onClick={openProductModal}>
                Tambah Sekaligus
              </button>
            )}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <BootstrapTable
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            bootstrap4
            remote
            keyField="product_sku_uuid"
            // data={!purchase_product ? rows : purchase_product}
            data={rows}
            columns={columns}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default PurchaseProductTable;
