export const StoryStatusCssClasses = ['danger', 'success', 'info', ''];
export const StoryStatusTitles = ['Suspended', 'Active', 'Pending', ''];
export const StoryTypeCssClasses = ['success', 'primary', ''];
export const StoryTypeTitles = ['Business', 'Individual', ''];
export const defaultSorted = [{ dataField: 'created_at', order: 'desc' }];
export const sizePerPageList = [
  { text: '10', value: 10 },
  { text: '50', value: 50 },
  { text: '100', value: 100 },
];
export const initialFilter = {
  brand: '',
  story_text: '',
  story_type: '',
  name: '',
  sortOrder: 'DESC', // asc||desc
  sortField: 'story.created_at',
  page_number: 1,
  page_size: 10,
};

export const initStory = {
  brand: [],
  related_products: [],
  story_text: '',
  story_type: '',
  image: [],
  video_link: '',
};

export const story_type = [
  {
    value: 'TESTIMONI',
    name: 'TESTIMONI',
  },
  {
    value: 'UPDATES',
    name: 'UPDATES',
  },
];

export function getStoryInitialValue(story) {
  const {
    uuid = '',
    images = [],
    brand = {},
    video_link = '',
    related_products = [],
    related_products_uuid = [],
    story_type = '',
    story_text = '',
  } = story ?? {};

  const values = {
    uuid: uuid ?? '',
    images: images ?? '',
    brand: {
      uuid: brand.uuid ?? '',
      name: brand.name ?? '',
    },
    video_link: video_link ?? '',
    related_products: [],
    related_products_uuid: related_products_uuid ?? [],
    story_type: story_type ?? '',
    story_text: story_text ?? '',
  };

  if (related_products instanceof Array) {
    values.related_products = related_products.map(({ uuid, name }) => ({
      label: name,
      value: uuid,
    }));
  }

  return values;
}
