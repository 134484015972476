import React from "react";
import { SynchronizeMarketplaceLoadingDialog } from "./synchronizeMarketplace-loading-dialog/SynchronizeMarketplaceLoadingDialog";
import { SynchronizeMarketplaceUIProvider } from "./SynchronizeMarketplaceUIContext";
import { SynchronizeMarketplaceCard } from "./SynchronizeMarketplaceCard";

export function SynchronizeMarketplacePage({ history }) {
  const synchronizeMarketplaceUIEvents = {
    newSynchronizeMarketplaceButtonClick: () => {
      history.push("/synchronizeMarketplace-user/new");
    },
    openEditSynchronizeMarketplace: (id) => {
      history.push(`/marketplace-synchronize/${id}`);
    },
  };

  return (
    <SynchronizeMarketplaceUIProvider
      synchronizeMarketplaceUIEvents={synchronizeMarketplaceUIEvents}
    >
      {/*<SynchronizeMarketplaceLoadingDialog/>*/}
      <SynchronizeMarketplaceCard history={history} />
    </SynchronizeMarketplaceUIProvider>
  );
}
