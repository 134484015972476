import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Card, CardBody, CardHeader } from '../../../../../_metronic/_partials/controls';
import { currencyFormatter } from '../../../../../_metronic/_helpers';
import { useContext } from 'react';
import { OrderFormContext } from './FormContext';
import { useFormikContext } from 'formik';
import { paymentMethodType } from '../../../MasterData/pages/payment-method/PMUIHelpers';
import { VOUCHER_TYPES } from '../../../Voucher/page/VoucherUIHelpers';

export const OrderGrandTotal = () => {
  // * Hooks

  // * Contexts
  const { order, orderItems = [] } = useContext(OrderFormContext);
  const { values } = useFormikContext();
  const { order_payment, order_delivery = {}, order_vouchers = [] } = values;

  // * States
  const [prices, setPrices] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [grandTotal, setGrandTotal] = useState([]);

  // * Queries

  // * Effects
  useEffect(() => {
    // console.log('order_vouchers', order_vouchers);
    const totalPrice = orderItems.reduce(
      (tot, { qty, assigned_price }) => tot + +qty * +assigned_price,
      0
    );

    let shippingCost = 0;
    let shippingCostDiscount = 0;

    if (order_delivery) {
      const { service_snapshot, shipping_cost } = order_delivery;
      if (service_snapshot) {
        const { price, final_price } = service_snapshot;

        shippingCost = price;

        if (+final_price > +price) {
          shippingCost = Math.abs(+final_price - +price);
        }

        if (+price > +final_price && +final_price != +shipping_cost) {
          shippingCostDiscount = Math.abs(+price - +final_price);
        }
      }
    }

    const priceList = [
      { type: 'Total Pembelian', price: totalPrice },
      { type: 'Biaya Pengiriman', price: shippingCost },
    ];

    if (values.insurance && values.insurance_amount != null) {
      priceList.push({ type: 'Biaya Asuransi', price: +values.insurance_amount });
    }

    if (order_payment.order_payment && order_payment.pg_type === paymentMethodType.WIRED_TRANSFER) {
      priceList.push({ type: 'Prefix', price: +order_payment.pg_trx_id });
    }

    const discountList = [];

    if (order_vouchers.length > 0) {
      order_vouchers.map((item) => {
        const typeTxt = VOUCHER_TYPES.find((e) => e.value == item.voucher.type);
        discountList.push({
          type: typeTxt.description + ' (Voucher)',
          price: +item.voucher_amount,
        });
      });
    }

    if (shippingCostDiscount !== 0) {
      discountList.push({ type: 'Pengurangan Gratis Ongkir', price: shippingCostDiscount });
    }

    const totalPaid = priceList.reduce((tot, { price }) => +tot + +price, 0);
    const totalDiscount = discountList.reduce((tot, { price }) => +tot + +price, 0);
    setPrices(priceList);
    setDiscounts(discountList);
    setGrandTotal([
      {
        type: 'Grand Total (Total Belanja - Total Potongan)',
        price: totalPaid - totalDiscount,
      },
    ]);
  }, [order_delivery, orderItems, order_vouchers]);

  // * Functions

  // * Constants
  const columns = [
    {
      dataField: 'type',
      text: 'Tipe',
      footer: 'Total',
    },
    {
      dataField: 'price',
      text: 'Harga',
      headerAlign: 'right',
      classes: 'text-right',
      formatter: (cell) => {
        return +cell < 0 ? `- ${currencyFormatter(Math.abs(+cell))}` : currencyFormatter(cell);
      },
      footer: (columns) => {
        const total = columns.reduce((tot, price) => +tot + +price, 0);
        return currencyFormatter(total);
      },
      footerAlign: 'right',
    },
  ];

  return (
    <>
      {prices.length > 0 && (
        <Card>
          <CardHeader title="Total Belanja"></CardHeader>
          <CardBody>
            <BootstrapTable
              wrapperClasses="table-responsive"
              bordered={false}
              classes="table table-head-custom table-vertical-center overflow-hidden"
              bootstrap4
              remote
              keyField="type"
              data={prices}
              columns={columns}
            />
          </CardBody>
        </Card>
      )}
      {discounts.length > 0 && (
        <Card>
          <CardHeader title="Total Potongan"></CardHeader>
          <CardBody>
            <BootstrapTable
              wrapperClasses="table-responsive"
              bordered={false}
              classes="table table-head-custom table-vertical-center overflow-hidden"
              bootstrap4
              remote
              keyField="type"
              data={discounts}
              columns={columns}
            />
          </CardBody>
        </Card>
      )}
      {grandTotal.length > 0 && (
        <Card>
          <CardHeader title="Total Bayar"></CardHeader>
          <CardBody>
            <BootstrapTable
              wrapperClasses="table-responsive"
              bordered={false}
              classes="table table-head-custom table-vertical-center overflow-hidden"
              bootstrap4
              remote
              keyField="type"
              data={grandTotal}
              columns={columns}
            />
          </CardBody>
        </Card>
      )}
    </>
  );
};
