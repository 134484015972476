import React, { useMemo, useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { Badge, Collapse } from "react-bootstrap";
import { PurchaseFilter } from "./purchase-filter/PurchaseFilter";
import { PurchaseTable } from "./purchase-table/PurchaseTable";
import { usePurchaseUIContext } from "./PurchaseUIContext";
import { RoleChecker } from "../../../RoleChecker";
import { shallowEqual, useSelector } from "react-redux";

export function PurchaseCard({ history }) {
  const purchaseUIContext = usePurchaseUIContext();
  const purchaseUIProps = useMemo(() => {
    return {
      queryParams: purchaseUIContext.queryParams,
      newPurchaseButtonClick: purchaseUIContext.newPurchaseButtonClick,
    };
  }, [purchaseUIContext]);

  const [statusFilter, setStatusFilter] = useState(false);
  const [open, setOpen] = useState(false);

  const changeShow = () => {
    setOpen(!open);

    // Set Status Filter To False
    setStatusFilter(false);
    const filter = purchaseUIProps.queryParams;
    for (const [key, value] of Object.entries(filter)) {
      if (value !== "" && key) {
        setStatusFilter(true);
      }
    }
  };

  useEffect(() => {
    if (purchaseUIProps.filterType !== undefined) {
      setStatusFilter(true);
      // setIsDisabled(true);
    }
    // eslint-disable-next-line
  }, [purchaseUIProps.filterType]);

  const { filters } = useSelector(state => state.purchases, shallowEqual)
  useEffect(() => {
    if (filters.filterIsOpen) setOpen(true)
  }, [])

  return (
    <Card>
      <CardHeader title="Daftar Pembelian">
        <CardHeaderToolbar>
          {statusFilter && (
            <Badge className="mr-5" pill variant="danger">
              Filter Aktif
            </Badge>
          )}
          {RoleChecker("read", "purchase") && (
            <button
              type="button"
              className="btn btn-warning mr-2"
              onClick={changeShow}
              aria-controls="collapse-filter-purchase"
              aria-expanded={open}
            >
              Cari
            </button>
          )}
          {RoleChecker("create", "purchase") && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={purchaseUIProps.newPurchaseButtonClick}
            >
              Tambah Purchase
            </button>
          )}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <Collapse in={open}>
          <div id="collapse-filter-purchase">
            <PurchaseFilter history={history} />
          </div>
        </Collapse>

        <PurchaseTable />
      </CardBody>
    </Card>
  );
}
