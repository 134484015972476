import * as moment from 'moment';

export const OrderStatusCssClasses = ['danger', 'success', 'info', ''];
export const OrderStatusTitles = ['Suspended', 'Active', 'Pending', ''];
export const OrderTypeCssClasses = ['success', 'primary', ''];
export const OrderTypeTitles = ['Business', 'Individual', ''];
export const ORDER_STATUS_VALIDATION = ['NEW', 'DRAFT', 'PACKING', 'PICKUP', 'COMPLAIN', 'RETURN'];
export const defaultSorted = [{ dataField: 'created_at', order: 'desc' }];
export const sizePerPageList = [
  { text: '10', value: 10 },
  { text: '50', value: 50 },
  { text: '100', value: 100 },
];
export const initialFilter = {
  branch_uuid: '',
  dateValue: [],
  status: '',
  member_label: '',
  member_uuid: '',
  invoice_no: '',
  is_online: undefined,
  admin_uuid: '',
  total_amount_start: undefined,
  total_amount_end: undefined,
  total_order_item_price_start: undefined,
  total_order_item_price_end: undefined,
  shipping_cost: 0,
  shipping_service_uuid: '',
  store_uuid: '',
  status_array: [],
  status_array_label: '',
  created_at_start: '',
  created_at_end: '',
  trx_date_start: '',
  trx_date_end: '',
  receiver_name: '',
  marketplace_invoice: '',
  page_number: 1,
  page_size: 10,
};

export const ORDER_ONLINE = [
  {
    label: 'Online',
    value: true,
  },
  {
    label: 'Offline',
    value: false,
  },
];

export const IS_ONLINE = [
  {
    label: 'Online',
    value: true,
  },
  {
    label: 'Offline',
    value: false,
  },
];

export const ORDER_STATUS_OPTIONS = [
  {
    label: 'ABORT',
    value: 'ABORT',
  },
  {
    label: 'DRAFT',
    value: 'DRAFT',
  },
  {
    label: 'PAYMENT',
    value: 'PAYMENT',
  },
  {
    label: 'PAID',
    value: 'PAID',
  },
  {
    label: 'PACKING',
    value: 'PACKING',
  },
  {
    label: 'PICKUP',
    value: 'PICKUP',
  },
  {
    label: 'SENDING',
    value: 'SENDING',
  },
  {
    label: 'RECEIVED',
    value: 'RECEIVED',
  },
  {
    label: 'COMPLAIN',
    value: 'COMPLAIN',
  },
  {
    label: 'COMPLAIN CONFIRM',
    value: 'COMPLAIN_CONFIRM',
  },
  {
    label: 'RETURN',
    value: 'RETURN',
  },
];

export const ORDER_STATUS = {
  DRAFT: 'DRAFT',
  NEW: 'NEW',
  PAYMENT: 'PAYMENT',
  PAID: 'PAID',
  PROCESS: 'PROCESS',
  PACKING: 'PACKING',
  PICKUP: 'PICKUP',
  SENDING: 'SENDING',
  RECEIVED: 'RECEIVED',
  RECEIVED_CONFIRM: 'RECEIVED_CONFIRM',
  COMPLAIN: 'COMPLAIN',
  COMPLAIN_CONFIRM: 'COMPLAIN_CONFIRM',
  RETURN: 'RETURN',
  ABORT: 'ABORT',
  FINISH: 'FINISH',
  VOID: 'VOID',
};

export const ORDER_SHIPPED_STATUS = [
  ORDER_STATUS.SENDING,
  ORDER_STATUS.RECEIVED,
  ORDER_STATUS.RECEIVED_CONFIRM,
  ORDER_STATUS.COMPLAIN,
  ORDER_STATUS.COMPLAIN_CONFIRM,
  ORDER_STATUS.RETURN,
  ORDER_STATUS.FINISH,
];

export const buttonStatus = (status) => {
  let data = {};
  switch (status) {
    case ORDER_STATUS.PAYMENT:
      data = { updateTo: ORDER_STATUS.PAID };
      break;
    case ORDER_STATUS.DRAFT:
      data = { updateTo: ORDER_STATUS.PAID };
      break;
    case ORDER_STATUS.PAID:
      data = { updateTo: ORDER_STATUS.PROCESS };
      break;
    case ORDER_STATUS.PROCESS:
      data = { updateTo: ORDER_STATUS.PACKING };
      break;
    case ORDER_STATUS.PACKING:
      data = { updateTo: ORDER_STATUS.SENDING };
      break;
    case ORDER_STATUS.PICKUP:
      data = { updateTo: ORDER_STATUS.SENDING };
      break;
    case ORDER_STATUS.SENDING:
      data = { updateTo: ORDER_STATUS.FINISH };
      break;
    case ORDER_STATUS.COMPLAIN:
      data = { updateTo: 'CONFIRM COMPLAIN' };
      break;
    default:
      data = { updateTo: '' };
  }

  return data;
};

export const badge = (status) => {
  let data = {};
  switch (status) {
    case ORDER_STATUS.DRAFT:
      data = { type: 'dark' };
      break;
    case ORDER_STATUS.PAYMENT:
      data = { type: 'warning' };
      break;
    case ORDER_STATUS.PAID:
      data = { type: 'primary' };
      break;
    case ORDER_STATUS.PACKING:
      data = { type: 'primary' };
      break;
    case ORDER_STATUS.PICKUP:
      data = { type: 'info' };
      break;
    case ORDER_STATUS.SENDING:
      data = { type: 'info' };
      break;
    case ORDER_STATUS.RECEIVED:
      data = { type: 'success' };
      break;
    case ORDER_STATUS.COMPLAIN:
      data = { type: 'danger' };
      break;
    case ORDER_STATUS.COMPLAIN_CONFIRM:
      data = { type: 'success' };
      break;
    case ORDER_STATUS.ABORT:
      data = { type: 'danger' };
      break;
    case ORDER_STATUS.VOID:
      data = { type: 'dark' };
      break;
    case ORDER_STATUS.FINISH:
      data = { type: 'success' };
      break;
    default:
      data = { type: '' };
  }

  return data;
};

export const ORDER_SHORTCUT_STATUS = [
  {
    label: 'STATUS GANTUNG',
    value: ['PAID', 'PROCESS', 'PACKING', 'PICKUP', 'SENDING', 'RECEIVED', 'RECEIVED_CONFIRM'],
  },
  {
    label: 'BATAL',
    value: ['VOID', 'ABORT', 'RETURN'],
  },
  {
    label: 'BARU',
    value: ['NEW', 'DRAFT', 'PAYMENT'],
  },
  {
    label: 'KOMPLAIN',
    value: ['COMPLAIN', 'COMPLAIN_CONFIRM'],
  },
];

export const ORDER_FOR_RETURN_STATUS = [
  ORDER_STATUS.RECEIVED,
  ORDER_STATUS.RECEIVED_CONFIRM,
  ORDER_STATUS.COMPLAIN,
  ORDER_STATUS.COMPLAIN_CONFIRM,
  ORDER_STATUS.RETURN,
  ORDER_STATUS.FINISH,
];

export function getOrderInitialValue(order) {
  const {
    uuid = '',
    trx_date = moment().format('YYYY-MM-DD'),
    is_online = true,
    is_dropship = false,
    insurance = '',
    insurance_amount = '',
    dropship_name = '',
    dropship_hp = '',
    invoice_no = '',
    marketplace_invoice = '',
    status = '',
    branch = {},
    order_delivery = {},
    order_payment = {},
    store = {},
    member = {},
    order_vouchers = [],
  } = order ?? {};

  const { payment_method = {} } = order_payment ?? {};
  let {
    awb = '',
    shipping_cost = '',
    real_shipping_cost = '',
    address = '',
    sub_district = '',
    city = '',
    codepos = '',
    province = '',
    receiver_hp = '',
    receiver_name = '',
    address_uuid = '',
    shipping_service = {},
    service_snapshot = {},
  } = order_delivery ?? {};

  // console.log('order', order);
  // console.log('shipping_service', shipping_service);

  if (!service_snapshot) {
    service_snapshot = {};

    if (shipping_service) {
      service_snapshot = {
        uuid: shipping_service.uuid,
        category: [],
        company_code: shipping_service.company_code,
        service_name: shipping_service.service,
        price: shipping_cost ?? '',
        final_price: shipping_cost ?? '',
      };
    }
  }

  const initialValue = {
    uuid,
    trx_date,
    is_online,
    is_dropship,
    invoice_no,
    insurance,
    insurance_amount,
    marketplace_invoice,
    status,
    dropship_name: dropship_name ?? '',
    dropship_hp: dropship_hp ?? '',
    branch: {
      uuid: branch.uuid ?? '',
      name: branch.name ?? '',
    },
    store: {
      uuid: (store && store.uuid) ?? '',
      market_name: (store && store.market_name) ?? '',
      platform: (store && store.platform) ?? '',
    },
    member: {
      uuid: member.uuid ?? '',
      name: member.name ?? '',
      email: member.email ?? '',
      hp: member.hp ?? '',
    },
    order_payment: {
      payment_method_uuid: order_payment.payment_method_uuid ?? '',
      amount: order_payment.amount ?? '',
      pg_type: order_payment.pg_type ?? '',
      pg_trx_id: order_payment.pg_trx_id ?? '',
      payment_method: {
        uuid: payment_method.uuid ?? '',
        name: payment_method.name ?? '',
      },
    },
    order_delivery: {
      uuid: (order_delivery && order_delivery.uuid) ?? '',
      awb: awb ?? '',
      shipping_cost: shipping_cost ?? '',
      real_shipping_cost: real_shipping_cost ?? '',
      address_uuid: address_uuid ?? '',
      address: address ?? '',
      sub_district: sub_district ?? '',
      city: city ?? '',
      province: province ?? '',
      codepos: codepos ?? '',
      receiver_hp: receiver_hp ?? '',
      receiver_name: receiver_name ?? '',
      shipping_service: {
        uuid: shipping_service.uuid ?? '',
        service: shipping_service.service ?? '',
        company_code: shipping_service.company_code ?? '',
      },
      service_snapshot: {
        category: service_snapshot.category ?? [],
        company_code: service_snapshot.company_code ?? '',
        comany_name: service_snapshot.comany_name ?? '',
        service_code: service_snapshot.service_code ?? '',
        service_name: service_snapshot.service_name ?? '',
        description: service_snapshot.description ?? '',
        etd: service_snapshot.etd ?? '',
        price: service_snapshot.price ?? '',
        final_price: service_snapshot.final_price ?? '',
        uuid: service_snapshot.uuid ?? '',
      },
    },
    order_vouchers,
  };

  return initialValue;
}
