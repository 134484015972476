import axios from 'axios';
import { API } from '../../../../config/UrlEndPoint';
import { gql, useQuery } from '@apollo/client';
import { QueryGraphql, MutationGraphql } from '../../../../config/ApoloRequest';

export const ORDER_URL = `${API}product`;

// ! CREATE PRODUCT ONLY
export const CREATE_PRODUCT = gql`
  mutation Mutation(
    $brand_uuid: String!
    $description: String!
    $name: String!
    $product_category: [ID!]!
    $publish_status: String
    $weight: Int!
  ) {
    createProductOnly(
      createProductOnlyInput: {
        brand_uuid: $brand_uuid
        description: $description
        name: $name
        product_category: $product_category
        publish_status: $publish_status
        weight: $weight
      }
    ) {
      uuid
      name
    }
  }
`;

// ! CREATE IMAGE PRODUCT
export const CREATE_IMAGE = gql`
  mutation Mutation(
    $uuid: ID!
    $image1: Upload
    $image2: Upload
    $image3: Upload
    $image4: Upload
    $image5: Upload
  ) {
    createProductImage(
      createProductImageInput: { product_uuid: $uuid }
      image1: $image1
      image2: $image2
      image3: $image3
      image5: $image4
      image4: $image5
    ) {
      message
    }
  }
`;

// ! CREATE SKU VARIANT
export const CREATE_SKU_VARIANT = gql`
  mutation Mutation(
    $uuid: ID!
    $product_variants: [ProductSkuVariantInput!]!
    $skus: [ProductSkuInput!]!
  ) {
    createProductSkusVariant(
      createProductSkusVariant: {
        product_uuid: $uuid
        product_variants: $product_variants
        skus: $skus
      }
    ) {
      message
    }
  }
`;

// ! UPDATE PRODUCT
export const UPDATE_PRODUCT = gql`
  mutation Mutation(
    $uuid: String!
    $brand_uuid: String
    $description: String
    $name: String
    $product_category: [ID!]
    $publish_status: String
    $weight: Int
  ) {
    updateProductOnly(
      updateProductOnlyInput: {
        product_uuid: $uuid
        brand_uuid: $brand_uuid
        description: $description
        name: $name
        category_uuids: $product_category
        publish_status: $publish_status
        weight: $weight
      }
    ) {
      name
      uuid
      weight
      description
      publish_status
    }
  }
`;

// ! UPDATE IMAGE
export const UPDATE_IMAGE = gql`
  mutation Mutation(
    $uuid: ID!
    $image1: Upload
    $image2: Upload
    $image3: Upload
    $image4: Upload
    $image5: Upload
    $deletedImage: [Int!]
  ) {
    updateProductImage(
      updateProductImageInput: { product_uuid: $uuid, deleted_images_number: $deletedImage }
      image1: $image1
      image2: $image2
      image3: $image3
      image4: $image4
      image5: $image5
    ) {
      message
    }
  }
`;

// ! UPDATE SORT NUMBER IMAGE
export const UPDATE_IMAGE_SORT = gql`
  mutation M($product_uuid: ID!, $product_images: [ProductImageNumber!]!) {
    updateProductImageNumbers(
      updateProductImageNumbersInput: {
        product_images: $product_images
        product_uuid: $product_uuid
      }
    ) {
      message
    }
  }
`;

// ! UPDATE SKU VARIANT
export const UPDATE_SKU_VARIANT = gql`
  mutation Mutation(
    $uuid: ID!
    $product_variants: [ProductVariantInput!]
    $skus: [UpdateProductSkuInput!]
  ) {
    updateProductSkusVariant(
      updateProductSkusVariant: {
        product_uuid: $uuid
        product_variants: $product_variants
        skus: $skus
      }
    ) {
      message
    }
  }
`;

// ! UPLOAD SKU IMAGE
export const SKU_IMAGE = gql`
  mutation Mutation($image: Upload, $uuid: ID) {
    updateProductSku(updateProductSkuInput: { uuid: $uuid }, image_sku: $image) {
      message
    }
  }
`;

// ! UPDATE SKU IMAGE
export const UPDATE_SKU_IMAGE = gql`
  mutation M($image: Upload, $updateProductSkuInput: UpdateProductSkuInput!) {
    updateProductSku(image_sku: $image, updateProductSkuInput: $updateProductSkuInput) {
      message
    }
  }
`;

// ! DELETE SKU IMAGE
export const DELETE_SKU_IMAGE = gql`
  mutation M($product_sku_uuid: String!) {
    deleteProductSkuImage(product_sku_uuid: $product_sku_uuid) {
      message
    }
  }
`;

// ! DELETE PRODUCT
export const DELETE_PRODUCT = gql`
  mutation Mutation($uuid: String!) {
    deleteProduct(uuid: $uuid) {
      message
    }
  }
`;

// ! DELETE PRODUCT IMAGE
export const DELETE_PRODUCT_IMAGE = gql`
  mutation Mutation($uuid: [ID!]!, $product_uuid: String) {
    deleteProductImage(
      deleteProductImages: { product_image_uuid: $uuid, product_uuid: $product_uuid }
    ) {
      message
    }
  }
`;

// ! FIND DETAIL SKU STOCK
export const FIND_DETAIL_SKU_STOCK = gql`
  query Q($sku_uuid: String, $sort_field: String, $sort_order: String) {
    findInventoryItems(
      findInventoryItemInput: {
        product_sku_uuid: $sku_uuid
        sort_field: $sort_field
        sort_order: $sort_order
      }
    ) {
      branch_stocks {
        name
        branch_uuid
        stock
      }
      items {
        branch {
          name
          uuid
        }
        product_sku_uuid
        created_at
        qty
        total_price
        trans_type
        trans_uuid
      }
    }
  }
`;

// ! CERATE INVENTORY ITEMS
export const CREATE_INVENTORY_ITEMS = gql`
  mutation M($branch_uuid: String!, $product_sku_uuid: String!, $qty: Int!) {
    createInventoryItem(
      createInventoryItemInput: {
        branch_uuid: $branch_uuid
        product_sku_uuid: $product_sku_uuid
        qty: $qty
      }
    ) {
      branch_uuid
      qty
      trans_uuid
    }
  }
`;

// READ
export function getAllProducts() {
  return axios.get(ORDER_URL);
}

export async function getProductById(id) {
  const query = gql`
  {
  getProduct(uuid: "${id}") {
    uuid
    brand {
      name
      uuid
    }
    category {
      category_uuid
      categoryjoin {
        name
        uuid
      }
    }
    weight
    description
    name
    publish_status
    product_image {
      sort_number
      uuid
      images {
        url
        uuid
      }
    }
    product_variant {
      uuid
      name
      options
      created_at
    }
    product_sku {
      uuid
      price
      price_before
      sku
      stock
      variant_value_1
      variant_uuid_1
      variant_value_2
      variant_uuid_2
      image {
        url
        uuid
      }
    }
  }
}
`;

  const newResponse = await QueryGraphql(query);
  return newResponse.data.getProduct;
}

// export function getProductById(ProductId) {
//   return axios.get(`${ORDER_URL}/${ProductId}`);
// }

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result

export async function findProducts(queryParams) {
  const query = gql`
    query Q(
      $name: String
      $product_name_or_sku: String
      $description: String
      $slug: String
      $brand_uuid: String
      $category_uuid: String
      $page_number: Int!
      $page_size: Int!
      $sort_order: String
      $sort_field: String
      $show_empty_stock: Boolean
      $weight_start: Int
      $weight_end: Int
      $stock_start: Int
      $stock_end: Int
      $created_at_start: String
      $created_at_end: String
      $publish_status: String
    ) {
      findProduct(
        findProductInput: {
          name: $name
          product_name_or_sku: $product_name_or_sku
          publish_status: $publish_status
          description: $description
          slug: $slug
          brand_uuid: $brand_uuid
          category_uuid: $category_uuid
          page_number: $page_number
          page_size: $page_size
          sort_order: $sort_order
          sort_field: $sort_field
          show_empty_stock: $show_empty_stock
          weight_start: $weight_start
          weight_end: $weight_end
          stock_start: $stock_start
          stock_end: $stock_end
          created_at_start: $created_at_start
          created_at_end: $created_at_end
        }
      ) {
        items {
          uuid
          name
          created_at
          dynamic_link
          product_image {
            sort_number
            images {
              url
            }
          }
          category {
            categoryjoin {
              name
              children {
                name
              }
            }
          }
          publish_status
          product_variant {
            name
          }
          brand {
            name
          }
          product_sku {
            stock
            sku
            uuid
          }
        }
        total_items
      }
    }
  `;
  const newResponse = await QueryGraphql(query, queryParams);
  return newResponse.data.findProduct;
}

export async function findProductsRating(params) {
  const query = gql`
    {
      findProductRating(
        findRating: {
          page_number: ${+params.page_number}, 
          page_size: ${+params.page_size}
          product_uuid: "${params.uuid}"
        }
      ) {
        items {
          member {
            name
          }
          product {
            name
          }
          rating
          review
          uuid
          deleted_at
        }
        total_items
        total_pages
      }
    }
  `;

  const newResponse = await QueryGraphql(query);

  return newResponse.data.findProductRating;
}

export const RESTORE_RATING = gql`
  mutation Mutation($uuid: String!) {
    restoreProductRating(rating_uuid: $uuid) {
      message
    }
  }
`;

export const DISABLE_RATING = gql`
  mutation Mutation($uuid: String!) {
    disableProductRating(rating_uuid: $uuid) {
      message
    }
  }
`;

export const SEARCH_PRODUCTS = gql`
  query Query(
    $product_name_or_sku: String
    $sort_field: String
    $sort_order: String
    $stock_start: Int
    $page_number: Int!
    $page_size: Int!
  ) {
    findProduct(
      findProductInput: {
        product_name_or_sku: $product_name_or_sku
        page_number: $page_number
        page_size: $page_size
        sort_field: $sort_field
        sort_order: $sort_order
        stock_start: $stock_start
      }
    ) {
      items {
        uuid
        name
        description
        is_deleted
        weight
        brand {
          name
        }
        product_sku {
          stock
          price
          price_before
          sku
          uuid
          variant_value_1
          variant_value_2
          image {
            url
          }
        }
      }
    }
  }
`;

// DELETE => delete the Product from the server
export async function deleteProduct(id) {
  const query = gql`
    mutation Mutation {
      deleteProduct(uuid: "${id}") {
        message
      }
    }
    `;

  const newResponse = await MutationGraphql(query);

  return newResponse.data.deleteProduct;
}

export const GENERATE_PRODUCT_DLINK = gql`
  query ($product_uuid: String!) {
    generateProductDynamicLink(product_uuid: $product_uuid) {
      uuid
      name
      slug
      dynamic_link
    }
  }
`;
