export const OrderReportStatusCssClasses = ["danger", "success", "info", ""];
export const OrderReportStatusTitles = ["Suspended", "Active", "Pending", ""];
export const OrderReportTypeCssClasses = ["success", "primary", ""];
export const OrderReportTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "created_at", order: "desc" }];
export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
];
export const initialFilter = {
  start_date: "",
  end_date: "",
  store_name: "",
  store_uuid: "",
};

export const initOrderReport = {
  start_date: "",
  end_date: "",
  store_name: "",
  store_uuid: "",
};

export const notification_type = [
  {
    value: "PROMO",
    name: "PROMO",
  },
  {
    value: "TRANSACTION",
    name: "TRANSACTION",
  },
];

export const STATUS = {
  SENT: "SENT",
  DELIVERED: "DELIVERED",
  READ: "READ",
  DELETED: "DELETED",
  FAILED: "FAILED",
};

export const STATUS_OPTIONS = [
  {
    value: STATUS.SENT,
    name: STATUS.SENT,
  },
  {
    value: STATUS.DELIVERED,
    name: STATUS.DELIVERED,
  },
  {
    value: STATUS.READ,
    name: STATUS.READ,
  },
  {
    value: STATUS.DELETED,
    name: STATUS.DELETED,
  },
  {
    value: STATUS.FAILED,
    name: STATUS.FAILED,
  },
];

export const badge = (status) => {
  let data = {};
  switch (status) {
    case STATUS.SENT:
      data = { type: "warning" };
      break;
    case STATUS.DELIVERED:
      data = { type: "success" };
      break;
    case STATUS.READ:
      data = { type: "primary" };
      break;
    case STATUS.DELETED:
      data = { type: "warning" };
      break;
    case STATUS.FAILED:
      data = { type: "danger" };
      break;
    default:
      data = { type: "" };
  }

  return data;
};
