// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Select } from "../../../../../../_metronic/_partials/controls";
import { copyValue } from "../../../../../../_metronic/_helpers";
import { GET_ADDRESS } from "../../../../Order/_redux/order/orderCrud";
import { useLazyQuery } from "@apollo/client";
import AsyncSelect from "react-select/async";
import ReactSelect from "react-select";
import CreatableSelect from "react-select/creatable";

// Validation schema
const MemberAddressEditSchema = Yup.object().shape({
  label: Yup.string().required("label is required"),
  city: Yup.string().required("Kota is required"),
  // village: Yup.string().required("Kelurahan is required"),
  sub_district: Yup.string().required("Kecamatan is required"),
  address: Yup.string().required("Alamat is required"),
  // longitude: Yup.number().required("Longitude is required"),
  // latitude: Yup.number().required("Latitude is required"),
  province: Yup.string().required("Provinsi is required"),
  recipient_name: Yup.string().required("Recipient Name is required"),
  recipient_phone: Yup.string().required("Recipient Phone is required"),
  codepos: Yup.string().required("Kode Pos is required"),
});

export function MemberAddressEditForm({
  saveMemberAddress,
  memberAddress,
  actionsLoading,
  onHide,
}) {
  // * Find Address
  const [currentTimeout, setCurrentTimeout] = useState(null);
  const [currentCallback, setCurrentCallback] = useState(() => () => null);
  const [codeposOptions, setCodeposOptions] = useState([]);

  const [searchAddress] = useLazyQuery(GET_ADDRESS, {
    variables: {
      keyword: "",
    },
    onCompleted: (data) => {
      const { findAddressSuggestion } = data;

      if (findAddressSuggestion.items instanceof Array) {
        currentCallback(
          findAddressSuggestion.items.map(
            ({
              // uuid,
              city,
              province_name,
              district,
              postal_codes,
            }) => ({
              label: `${district}, ${city}, ${province_name}`,
              // value: uuid,
              city,
              province_name,
              district,
              postal_codes,
            })
          )
        );
      }
    },
    fetchPolicy: "cache-and-network",
  });

  const loadAddressOptions = (inputValue, callback) => {
    clearTimeout(currentTimeout);

    if (inputValue.length >= 1) {
      setCurrentTimeout(
        setTimeout(() => {
          searchAddress({ variables: { keyword: inputValue } });
          setCurrentCallback(() => callback);
        }, 250)
      );
    } else {
      callback([]);
    }
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={memberAddress}
        validationSchema={MemberAddressEditSchema}
        onSubmit={(values) => {
          // console.log(values);
          let newValue = copyValue(values);
          if (!newValue.is_default) newValue["is_default"] = false;
          saveMemberAddress(newValue);
        }}
      >
        {({ handleSubmit, setFieldValue, values, errors }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col">
                    <Field
                      name="label"
                      component={Input}
                      placeholder="Label"
                      label="Label"
                    />
                  </div>
                  <div className="col">
                    <Select
                      name="is_default"
                      label="Default Address"
                      onChange={(e) => {
                        let data = false;
                        if (e.target.value === "true") data = true;
                        setFieldValue("is_default", data);
                      }}
                    >
                      <option value="true">true</option>
                      <option value="false">false</option>
                    </Select>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col">
                    <Field
                      name="recipient_name"
                      component={Input}
                      placeholder="Recipient Name"
                      label="Recipient Name"
                    />
                  </div>
                  <div className="col">
                    <Field
                      name="recipient_phone"
                      component={Input}
                      placeholder="Recipient Phone"
                      label="Recipient Phone"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col">
                    <label>Kota / Kecamatan</label>
                    <AsyncSelect
                      id="address_suggestion"
                      cacheOptions={false}
                      name="address_suggestion"
                      placeholder="Cari Kota / Kecamatan..."
                      noOptionsMessage={({ inputValue }) =>
                        inputValue.length >= 1
                          ? "Kota / Kecamatan tidak di temukan"
                          : "Ketik untuk mencari Kota / Kecamatan"
                      }
                      value={
                        values.city && values.sub_district && values.province
                          ? {
                              label: `${values.sub_district}, ${values.city}, ${values.province}`,
                              value: null,
                            }
                          : null
                      }
                      loadOptions={loadAddressOptions}
                      defaultOptions={false}
                      onChange={({
                        city,
                        province_name,
                        district,
                        postal_codes,
                      }) => {
                        setFieldValue("city", city);
                        setFieldValue("province", province_name);
                        setFieldValue("sub_district", district);
                        setFieldValue("codepos", "");
                        setCodeposOptions(postal_codes);
                      }}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          border: errors.branch_uuid
                            ? "1px solid rgb(246, 78, 96)"
                            : "1px solid #ddd",
                        }),
                        dropdownIndicator: (provided, state) => ({
                          ...provided,
                          color: errors.branch_uuid
                            ? "rgb(246, 78, 96)"
                            : "#ddd",
                        }),
                      }}
                    />
                  </div>
                  <div className="col">
                    <Field
                      name="village"
                      component={Input}
                      placeholder="Kelurahan"
                      label="Kelurahan"
                    />
                  </div>
                </div>
                {/* <div className="form-group row">
                  <div className="col">
                    <Field
                      name="sub_district"
                      component={Input}
                      placeholder="Kecamatan"
                      label="Kecamatan"
                    />
                  </div>
                  <div className="col">
                    <Field
                      name="village"
                      component={Input}
                      placeholder="Kelurahan"
                      label="Kelurahan"
                    />
                  </div>
                </div> */}
                {/* <div className="form-group row">
                  <div className="col">
                    <Field
                      name="province"
                      component={Input}
                      placeholder="Province"
                      label="Province"
                    />
                  </div>
                  <div className="col">
                    <Field
                      name="city"
                      component={Input}
                      placeholder="Kota"
                      label="Kota"
                    />
                  </div>
                </div> */}
                <div className="form-group row">
                  <div className="col">
                    <label>Kode Pos</label>
                    <CreatableSelect
                      id="codepos"
                      name="codepos"
                      type="number"
                      // isDisabled={!addressInput.codepos && true}
                      options={codeposOptions.map((code) => ({
                        label: code,
                        value: code,
                      }))}
                      value={
                        values.codepos
                          ? {
                              label: values.codepos,
                              value: values.codepos,
                            }
                          : null
                      }
                      placeholder="Kode Pos..."
                      onChange={(e) => {
                        setFieldValue("codepos", e.value);
                      }}
                    />
                    {/* <Field
                      name="codepos"
                      component={Input}
                      placeholder="Kode Pos"
                      label="Kode Pos"
                    /> */}
                  </div>
                  <div className="col">
                    <label>Address</label>
                    <Field
                      name="address"
                      // component={Input}
                      placeholder="Address"
                      label="Address"
                      as="textarea"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col">
                    <Field
                      name="longitude"
                      component={Input}
                      type="number"
                      placeholder="Longitude"
                      label="Longitude"
                    />
                  </div>
                  <div className="col">
                    <Field
                      name="latitude"
                      component={Input}
                      type="number"
                      placeholder="Latitude"
                      label="Latitude"
                    />
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
