import axios from 'axios';
import { API } from '../../../../config/UrlEndPoint';
import { gql, useMutation } from '@apollo/client';
import { QueryGraphql, MutationGraphql } from '../../../../config/ApoloRequest';

export const USER_URL = `${API}master-data/brand/`;

export async function findBrands(queryParams) {
  const query = gql`
    {
    findBrand(findAllBrandInput: {
      name: "${queryParams.name}"
      is_deleted: ${queryParams.is_deleted}
      page_size: ${+queryParams.page_size}
      page_number: ${+queryParams.page_number}
      sort_field: "${queryParams.sortField}"
      sort_order: "${queryParams.sortOrder}"
    }) {
      items {
        name
        uuid
        file {
          url
        }
      }
      total_items
    }
  }

  `;

  const newResponse = await QueryGraphql(query);
  return newResponse.data.findBrand;
}

export const FIND_BRAND = gql`
  query findBrand(
    $name: String
    $page_number: Int
    $page_size: Int
    $sort_field: String
    $sort_order: String
  ) {
    findBrand(
      findAllBrandInput: {
        name: $name
        page_number: $page_number
        page_size: $page_size
        sort_field: $sort_field
        sort_order: $sort_order
      }
    ) {
      items {
        name
        uuid
      }
    }
  }
`;

export const CREATE_BRAND = gql`
  mutation Mutation($logo: Upload!, $name: String!) {
    createBrand(createBrandInput: { name: $name }, logo: $logo) {
      message
      data {
        name
      }
    }
  }
`;

export const UPDATE_BRAND = gql`
  mutation Mutation($logo: Upload, $uuid: String!, $name: String!) {
    updateBrand(updateBrandInput: { uuid: $uuid, name: $name }, logo: $logo) {
      message
    }
  }
`;

export async function createBrand(payload) {
  // const query = gql`
  //   mutation Mutation($logo: Upload!, $name: String!) {
  //     createBrand(createBrandInput: { name: $name }, logo: $logo) {
  //       message
  //       data {
  //         name
  //       }
  //     }
  //   }
  // `;
  // const newResponse = await MutationUploadFile(query);
  // return newResponse.data.createBrand;
}

export async function updateBrand(payload) {
  const query = gql`
    mutation {
        updateBrand(updateBrandInput: {
          uuid: "${payload.uuid}"
          name: "${payload.name}"
          city: "${payload.city}"
          province: "${payload.province}"
        })
        {
          name
          city
          province
        }
      }`;

  const newResponse = await MutationGraphql(query);
  return newResponse.data.updateBrand;
}

export async function deleteBrand(id) {
  const query = gql`
    mutation Mutation {
      deleteBrand(uuid: "${id}") {
        message
      }
}
`;

  const newResponse = await MutationGraphql(query);
  return newResponse.data.deleteBrand;
}

// CREATE =>  POST: add a new customer to the server
// export function createBrand(brand) {
//     return axios.post(USER_URL, brand);
// }

// GET ALL
export async function getAllBrands() {
  const query = gql`
    {
      findAllBrandes {
        uuid
        name
        city
        province
      }
    }
  `;

  const newResponse = await QueryGraphql(query);
  return newResponse.data.findAllBrandes;
}

export async function getBrandById(id) {
  const query = gql`
    {
      getBrand(uuid: "${id}") {
        file {
          url
        }
        uuid
        name
      }
    }
  `;

  const newResponse = await QueryGraphql(query);
  return newResponse.data.getBrand;
}

// GET SINGLE USER
// export function getBrandById(id) {
//   return axios.get(`${USER_URL}${id}`);
// }

// GET USER WITH CONDITION
// export function findBrands(queryParams) {
//     return axios.post(`${USER_URL}find`, queryParams);
// }

// UPDATE SINGEL USER
// export function updateBrand(brand) {
//     return axios.put(`${USER_URL}`, brand);
// }
