import * as requestFromServer from './memberCrud';
import { memberSlice, callTypes } from './memberSlice';
import { toastOption } from '../../../../../_metronic/_helpers';
import { toast } from 'react-toastify';

const { actions } = memberSlice;

export const fetchMembers = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findMembers(queryParams)
    .then((response) => {
      const { total_items, items } = response;
      dispatch(actions.membersFetched({ total_items, items }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find members";
      console.log(error);
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchMemberAll = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllMembers(queryParams)
    .then((response) => {
      dispatch(actions.memberALlFetched({ allData: response.data.data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find members";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchMember = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.memberFetched({ memberForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getMemberById(id)
    .then((response) => {
      dispatch(actions.memberFetched({ memberForEdit: response }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find member";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateMember = (member) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateMember(member)
    .then((response) => {
      console.log(`response`, response);
      fetchMembers();
      // toast.success(response.data.message, toastOption);
      // dispatch(actions.memberUpdated({ member }));
      // dispatch(actions.memberUpdated());
    })
    .catch((error) => {
      console.log(`error`, error);
      error.clientMessage = "Can't update member";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createMember = (payload) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .createMember(payload)
    .then((response) => {
      fetchMembers();
    })
    .catch((error) => {
      error.clientMessage = "Can't create member";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createMemberAddress = (payload) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createMemberAddress(payload)
    .then((response) => {
      fetchMembers();
      toast.success('Create member address success', toastOption);
    })
    .catch((error) => {
      error.clientMessage = "Can't create member address";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const updateMemberAddress = (payload) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateMemberAddress(payload)
    .then((response) => {
      fetchMembers();
      toast.success('Update member address success', toastOption);
    })
    .catch((error) => {
      error.clientMessage = "Can't update member address";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const deleteMemberAddress = (payload) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteMemberAddress(payload)
    .then((response) => {
      fetchMembers();
      toast.success('Delete member address success', toastOption);
    })
    .catch((error) => {
      console.log(error);
      error.clientMessage = "Can't delete member address";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const catchError = (error) => (dispatch) => {
  dispatch(actions.catchError({ error, callType: callTypes.action }));
};

export const memberFilter = (params) => (dispatch) => {
  dispatch(actions.startCall({ callTypes: callTypes.list }));
  dispatch(actions.memberFilter(params));
};
