// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { Link } from "react-router-dom";

export function ActionsFormatter(
  cellContent,
  row,
  rowIndex,
  {
    openEditProductPage,
    openDeleteCustomerDialog,
    setIsShowModal,
    id,
    history,
    allowDelete,
    allowUpdate,
    setModalRating,
  }
) {
  return (
    <>
      {allowUpdate && (
        <a
          title="View Detail"
          className="btn btn-icon btn-light btn-hover-info btn-sm"
          onClick={() => {
            setModalRating(true);
            id(row.uuid);
          }}
        >
          <i class="fas fa-comments"></i>
        </a>
      )}
      <> </>
      {allowUpdate && (
        <Link to={`/product/${row.uuid}/edit`}>
          <button
            title="Edit customer"
            className="btn btn-icon btn-light btn-hover-primary btn-sm"
            // onClick={() => openEditCustomerDialog(row.id)}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
              />
            </span>
          </button>
        </Link>
      )}
      <> </>
      {allowDelete && (
        <a
          title="Delete customer"
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={() => {
            setIsShowModal(true);
            id(row.uuid);
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      )}
    </>
  );
}
