import React, { useState, useEffect, useContext } from 'react';
import { Field, Formik, Form, useFormikContext } from 'formik';
import { OrderFormContext } from './FormContext';
import { Input } from '../../../../../_metronic/_partials/controls';
import AsyncSelect from 'react-select/async';
import CurrencyInput from 'react-currency-input-field';
import * as Yup from 'yup';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from '../../../../../_metronic/_partials/controls';

import { ORDER_STATUS } from '../OrderUIHelpers';
import { useLazyQuery } from '@apollo/client';
import { SEARCH_PRODUCTS } from '../../../Products2/_redux/products/productCrud';
import { useRef } from 'react';
import { toast } from 'react-toastify';
import { toastOption } from '../../../../../_metronic/_helpers';

const ProductSkuSchema = Yup.object().shape({
  product_sku_uuid: Yup.string().required('product_sku_uuid wajib diisi'),
  product_name: Yup.string().required('product_name wajib diisi'),
  product_sku: Yup.string().required('product_sku wajib diisi'),
  qty: Yup.string().required('qty wajib diisi'),
  assigned_price: Yup.string().required('assigned_price wajib diisi'),
  weight: Yup.string().required('weight wajib diisi'),
});

export const OrderItemForm = () => {
  // * Hooks
  const priceRef = useRef();
  const qtyRef = useRef();
  const productRef = useRef();

  // * Contexts
  const { order, addProduct } = useContext(OrderFormContext);
  const { values } = useFormikContext();

  // * States
  const [currentTimeout, setCurrentTimeout] = useState(null);
  const [currentCallback, setCurrentCallback] = useState(null);
  const [productSkuOptions, setProductSkuOptions] = useState([]);

  // * Queries
  const [fetchProducts] = useLazyQuery(SEARCH_PRODUCTS, {
    onCompleted: (data) => {
      const { findProduct } = data ?? {};
      const { items = [] } = findProduct;

      if (items instanceof Array) {
        const productSkus = items.reduce((skus, { product_sku, ...product }) => {
          if (!(product_sku instanceof Array)) {
            return skus;
          }

          const currentSkus = product_sku.map((skuItem) => {
            const { uuid, variant_value_1, variant_value_2, sku, stock } = skuItem;

            const label = `${sku} - ${product.name} - ${variant_value_1 || ''} ${
              variant_value_2 || ''
            } (${stock})`;
            const value = uuid;

            return { label, value, ...skuItem, product };
          });

          return [...skus, ...currentSkus];
        }, []);

        setProductSkuOptions(productSkus);

        return currentCallback(productSkus);
      }

      setProductSkuOptions([]);

      currentCallback([]);
    },
    onError: (error) => {
      console.error(error);
    },
    fetchPolicy: 'cache-and-network',
  });

  // * Effects
  useEffect(() => {}, []);

  // * Functions
  function loadOptions(queryCaller, inputValue, callback) {
    clearTimeout(currentTimeout);

    if (inputValue.length >= 3) {
      setCurrentTimeout(
        setTimeout(() => {
          queryCaller({
            variables: {
              product_name_or_sku: inputValue,
              sort_order: 'DESC',
              sort_field: 'total_stock',
              stock_start: 1,
              page_number: 1,
              page_size: 20,
            },
          });
          setCurrentCallback(() => callback);
        }, 250)
      );
    } else {
      callback([]);
    }
  }

  function changeInput(e, target) {
    if (e.key === 'Enter') {
      setTimeout(() => {
        if (target === 'price') priceRef.current.select();
        if (target === 'qty') qtyRef.current.select();
        if (target === 'product') productRef.current.focus();
      }, 0);
    }
  }

  if (
    order &&
    ![ORDER_STATUS.NEW, ORDER_STATUS.DRAFT, ORDER_STATUS.PAYMENT].includes(order.status)
  ) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        product_sku_uuid: '',
        product_name: '',
        product_sku: '',
        qty: '',
        assigned_price: '',
        weight: '',
      }}
      validationSchema={ProductSkuSchema}
      onSubmit={(values, { resetForm }) => {
        const { qty, assigned_price, weight } = values;

        const total_price = +qty * +assigned_price;
        const totalWeight = +qty * +weight;

        const selectedSku = productSkuOptions.find((item) => item.uuid === values.product_sku_uuid);

        if (selectedSku && +qty > +(selectedSku.stock ?? 0)) {
          return toast.error('Qty melebihi jumlah stock', toastOption);
        }

        addProduct({
          ...values,
          total_price,
          assigned_price: +assigned_price,
          weight: totalWeight,
        });
        resetForm();
      }}
    >
      {({ values, setFieldValue, handleSubmit, errors, touched }) => (
        <Card>
          <CardHeader title="Pembelian">
            <CardHeaderToolbar></CardHeaderToolbar>
          </CardHeader>
          <CardBody>
            <div className="form-group row">
              <div className="col-md-4 mb-5 mb-md-0">
                <label htmlFor="product_sku_uuid">Nama Produk/Sku</label>
                <AsyncSelect
                  name="product_sku_uuid"
                  loadOptions={(inputValue, callback) =>
                    loadOptions(fetchProducts, inputValue, callback)
                  }
                  value={
                    values.product_sku_uuid
                      ? {
                          label: values.label,
                          value: values.product_sku_uuid,
                        }
                      : {
                          label: '',
                          value: '',
                        }
                  }
                  onChange={(selected) => {
                    setFieldValue('variant_value_1', selected.variant_value_1);
                    setFieldValue('variant_value_2', selected.variant_value_2);
                    setFieldValue('label', selected.label);
                    setFieldValue('product_sku_uuid', selected.uuid);
                    setFieldValue('product_sku', selected.sku);
                    setFieldValue('product_name', selected.product.name);
                    setFieldValue('assigned_price', selected.price);
                    setFieldValue('qty', 1);
                    setFieldValue('weight', selected.product.weight);
                  }}
                  noOptionsMessage={({ inputValue }) =>
                    inputValue.length >= 3 ? (
                      <p>Product/Sku tidak ditemukan</p>
                    ) : (
                      <span>Ketik 3 huruf atau lebih untuk mencari Product/Sku.</span>
                    )
                  }
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      border:
                        touched.product_sku_uuid && errors.product_sku_uuid
                          ? '1px solid rgb(246, 78, 96)'
                          : '1px solid #ddd',
                    }),
                    dropdownIndicator: (provided, state) => ({
                      ...provided,
                      color:
                        touched.product_sku_uuid && errors.product_sku_uuid
                          ? 'rgb(246, 78, 96)'
                          : '#ddd',
                    }),
                  }}
                  onKeyDown={(e) => changeInput(e, 'price')}
                  ref={productRef}
                />
              </div>
              <div className="col-md-4 mb-5 mb-md-0">
                <label htmlFor="assigned_price">Harga</label>
                <CurrencyInput
                  name="assigned_price"
                  placeholder="Rp 25.000"
                  intlConfig={{ locale: 'id-ID', currency: 'IDR' }}
                  className="form-control"
                  value={values.assigned_price}
                  onValueChange={(value) => setFieldValue('assigned_price', value)}
                  decimalsLimit={2}
                  onKeyPress={(e) => changeInput(e, 'qty')}
                  ref={priceRef}
                />
                {touched.qty && errors.assigned_price && (
                  <div className="mt-2" style={{ color: 'rgb(246, 78, 96)' }}>
                    {errors.assigned_price}
                  </div>
                )}
              </div>
              <Form className="col-md-4 mb-5 mb-md-0">
                <label htmlFor="qty">Qty</label>
                <input
                  name="qty"
                  className="form-control"
                  type="number"
                  placeholder="10"
                  value={values.qty}
                  onChange={(e) => setFieldValue('qty', +e.target.value)}
                  onKeyPress={(e) => changeInput(e, 'product')}
                  ref={qtyRef}
                />
                {touched.qty && errors.qty && (
                  <div className="mt-2" style={{ color: 'rgb(246, 78, 96)' }}>
                    {errors.qty}
                  </div>
                )}
              </Form>
            </div>
            <div className="form-group row">
              <div className="col d-flex justify-content-end">
                <button className="btn btn-primary ml-3 float-right" onClick={() => handleSubmit()}>
                  Tambah
                </button>
              </div>
            </div>
          </CardBody>
        </Card>
      )}
    </Formik>
  );
};
