import React, { useState, useEffect, useContext } from 'react';
import { ErrorMessage, Field, Formik, useFormikContext } from 'formik';
import { OrderFormContext } from './FormContext';
import { Input } from '../../../../../_metronic/_partials/controls';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from '../../../../../_metronic/_partials/controls';

import { ORDER_STATUS } from '../OrderUIHelpers';
import { useHistory } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_ADDRESS, UPDATE_ORDER_DELIVERY } from '../../_redux/order/orderCrud';
import { CircularProgress } from '@material-ui/core';
import { UpdateAddressModal } from './modals/UpdateAddressModal';
import { toast } from 'react-toastify';
import { toastOption } from '../../../../../_metronic/_helpers';
import { UPDATE_MEMBER_ADDRESS } from '../../../Member/_redux/member/memberCrud';

export const OrderMemberAddressForm = () => {
  // * Hooks
  const history = useHistory();

  // * Contexts
  const { order } = useContext(OrderFormContext);
  const { values, setFieldValue, errors, touched } = useFormikContext();
  const { order_delivery } = values;

  // * States
  const [cityOptions, setCityOptions] = useState([]);
  const [postalCodeOptions, setPostalCodeOptions] = useState([]);
  const [currentTimeout, setCurrentTimeout] = useState(null);
  const [currentCallback, setCurrentCallback] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  // * Queries
  const [searchAddress] = useLazyQuery(GET_ADDRESS, {
    variables: {
      keyword: '',
    },
    onCompleted: (data) => {
      const { findAddressSuggestion } = data;
      const { items = [] } = findAddressSuggestion;

      currentCallback(
        items.map(({ city, province_name, district, postal_codes }) => ({
          label: `${district}, ${city}, ${province_name}`,
          value: city,
          city,
          province_name,
          district,
          postal_codes,
        }))
      );
    },
    onError: (error) => {
      console.error(error);
    },
    fetchPolicy: 'cache-and-network',
  });
  const [fetchUpdateOrderDelivery, { loading: updateLoading }] = useMutation(
    UPDATE_ORDER_DELIVERY,
    {
      onCompleted: (data) => {
        const { updateOrderDelivery } = data ?? {};
        const { address, city, codepos, province, receiver_name, receiver_hp, sub_district } =
          updateOrderDelivery;

        setFieldValue('order_delivery.address', address);
        setFieldValue('order_delivery.city', city);
        setFieldValue('order_delivery.codepos', codepos);
        setFieldValue('order_delivery.province', province);
        setFieldValue('order_delivery.receiver_name', receiver_name);
        setFieldValue('order_delivery.receiver_hp', receiver_hp);
        setFieldValue('order_delivery.sub_district', sub_district);

        setShowUpdateModal(false);
      },
      onError: (error) => {
        toast.error(error, toastOption);
      },
    }
  );
  const [fetchUpdateMemberAddess, { loading: updateMemberAddressLoading }] = useMutation(
    UPDATE_MEMBER_ADDRESS,
    {
      onCompleted: (data) => {
        const { updateMemberAddress } = data ?? {};

        setFieldValue('order_delivery.address_uuid', updateMemberAddress.uuid);
        setFieldValue('order_delivery.province', updateMemberAddress.province);
        setFieldValue('order_delivery.city', updateMemberAddress.city);
        setFieldValue('order_delivery.sub_district', updateMemberAddress.sub_district);
        setFieldValue('order_delivery.codepos', updateMemberAddress.codepos);
        setFieldValue('order_delivery.address', updateMemberAddress.address);
        setFieldValue('order_delivery.recipient_name', updateMemberAddress.recipient_name);
        setFieldValue('order_delivery.recipient_phone', updateMemberAddress.recipient_phone);

        toast.success('Success', toastOption);
      },
      onError: (error) => {
        toast.error(error, toastOption);
      },
    }
  );

  // * Effects

  // * Functions

  function loadAddressOptions(inputValue, callback) {
    clearTimeout(currentTimeout);

    if (inputValue.length >= 1) {
      setCurrentTimeout(
        setTimeout(() => {
          searchAddress({ variables: { keyword: inputValue } });
          setCurrentCallback(() => callback);
        }, 250)
      );
    } else {
      callback([]);
    }
  }

  function updateOrderDelivery(values) {
    const { address, city, codepos, province, receiver_name, receiver_hp, sub_district } = values;

    if (order && order.order_delivery) {
      fetchUpdateOrderDelivery({
        variables: {
          updateOrderDeliveryInput: {
            uuid: order_delivery.uuid,
            address,
            city,
            codepos,
            province,
            receiver_name,
            receiver_hp,
            sub_district,
          },
        },
      });
    }
  }

  function updateMemberAddress() {
    if (order_delivery.address_uuid) {
      fetchUpdateMemberAddess({
        variables: {
          updateMemberAddressInput: {
            uuid: order_delivery.address_uuid,
            address: order_delivery.address,
            city: order_delivery.city,
            codepos: order_delivery.codepos,
            province: order_delivery.province,
            recipient_name: order_delivery.receiver_name,
            recipient_phone: order_delivery.receiver_hp,
            sub_district: order_delivery.sub_district,
          },
        },
      });
    }
  }

  if (!values.is_online) {
    return null;
  }

  if (order) {
    return (
      <Card>
        <UpdateAddressModal
          show={showUpdateModal}
          onHide={() => setShowUpdateModal(false)}
          actionsLoading={updateLoading}
          initialValues={{ ...order_delivery }}
          submit={updateOrderDelivery}
        />
        <CardHeader title="Alamat Pengiriman">
          <CardHeaderToolbar className="d-flex align-items-center">
            {values.uuid &&
              [ORDER_STATUS.NEW, ORDER_STATUS.DRAFT, ORDER_STATUS.PAYMENT].includes(
                values.status
              ) && (
                <button
                  style={{ width: '75px' }}
                  className="btn btn-success d-flex justify-content-center align-items-center"
                  onClick={() => setShowUpdateModal(true)}
                >
                  {updateLoading ? (
                    <CircularProgress color="inherit" size={16} />
                  ) : (
                    <span>Edit</span>
                  )}
                </button>
              )}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div className="row m-0 p-0">
            <div className="col-4 col-md-3">Nama Penerima</div>
            <div className="col-8 col-md-9">
              <span>:</span>
              <span className="ml-3">{order_delivery.receiver_name}</span>
            </div>
            <div className="col-4 col-md-3">No Hp Penerima</div>
            <div className="col-8 col-md-9">
              <span>:</span>
              <span className="ml-3">{order_delivery.receiver_hp}</span>
            </div>
            <div className="col-4 col-md-3">Alamat</div>
            <div className="col-8 col-md-9">
              <span>:</span>
              <span className="ml-3">{order_delivery.address}</span>
            </div>
            <div className="col-4 col-md-3">Kota / Kecamatan</div>
            <div className="col-8 col-md-9">
              <span>:</span>
              <span className="ml-3">{`${order_delivery.sub_district}, ${order_delivery.city}, ${order_delivery.province}`}</span>
            </div>
            <div className="col-4 col-md-3">Kode Pos</div>
            <div className="col-8 col-md-9">
              <span>:</span>
              <span className="ml-3">{order_delivery.codepos}</span>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader title="Alamat Pengiriman">
        <CardHeaderToolbar>
          {updateMemberAddressLoading ? (
            <CircularProgress color="inherit" size={14} />
          ) : (
            <button
              className="btn btn-outline-success"
              style={{ width: '150px' }}
              onClick={updateMemberAddress}
            >
              Update Alamat
            </button>
          )}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="form-group row">
          <div className="col-md-4 mb-5 mb-md-0">
            <label htmlFor="order_delivery.receiver_name">Nama Penerima *</label>
            <Field
              id="order_delivery.receiver_name"
              name="order_delivery.receiver_name"
              value={order_delivery.receiver_name}
              onChange={(e) => setFieldValue('order_delivery.receiver_name', e.target.value)}
              component={Input}
              placeholder="Fred Astaire"
            />
            {errors.order_delivery &&
              errors.order_delivery.receiver_name &&
              touched.order_delivery && (
                <div className="mt-2" style={{ color: 'rgb(246, 78, 96)' }}>
                  {errors.order_delivery.receiver_name}
                </div>
              )}
          </div>
          <div className="col-md-4 mb-5 mb-md-0">
            <label htmlFor="receiver_hp">No HP Penerima *</label>
            <Field
              id="receiver_name"
              name="receiver_name"
              value={order_delivery.receiver_hp}
              onChange={(e) => setFieldValue('order_delivery.receiver_hp', e.target.value)}
              component={Input}
              placeholder="Fred Astaire"
            />
            {errors.order_delivery && errors.order_delivery.receiver_hp && touched.order_delivery && (
              <div className="mt-2" style={{ color: 'rgb(246, 78, 96)' }}>
                {errors.order_delivery.receiver_hp}
              </div>
            )}
          </div>
          <div className="col-md-4 mb-5 mb-md-0">
            <label htmlFor="address">Alamat *</label>
            <Field
              id="address"
              name="address"
              value={order_delivery.address}
              onChange={(e) => setFieldValue('order_delivery.address', e.target.value)}
              className="form-control"
              component={'textarea'}
              placeholder="Jln.Pengasinan, Sawangan, Depok"
            />
            {errors.order_delivery && errors.order_delivery.address && touched.order_delivery && (
              <div className="mt-2" style={{ color: 'rgb(246, 78, 96)' }}>
                {errors.order_delivery.address}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-md-8 mb-5 mb-md-0">
            <label htmlFor="city">Kota / Kecamatan *</label>
            <AsyncSelect
              name="city"
              defaultOptions={cityOptions}
              loadOptions={(inputValue, callback) => loadAddressOptions(inputValue, callback)}
              value={
                order_delivery.city
                  ? {
                      label: `${order_delivery.sub_district}, ${order_delivery.city}, ${order_delivery.province}`,
                      value: order_delivery.city,
                    }
                  : {
                      label: '',
                      value: '',
                    }
              }
              onChange={({ city, province_name, district, postal_codes }) => {
                setFieldValue('order_delivery.city', city);
                setFieldValue('order_delivery.province', province_name);
                setFieldValue('order_delivery.sub_district', district);
                setPostalCodeOptions(postal_codes);
              }}
              noOptionsMessage={({ inputValue }) =>
                inputValue ? (
                  <p>Kota / Kecamatan tidak ditemukan</p>
                ) : (
                  <span>Ketik untuk mencari Kota / Kecamatan.</span>
                )
              }
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  border:
                    errors.order_delivery && errors.order_delivery.city && touched.order_delivery
                      ? '1px solid rgb(246, 78, 96)'
                      : '1px solid #ddd',
                }),
                dropdownIndicator: (provided, state) => ({
                  ...provided,
                  color:
                    errors.order_delivery && errors.order_delivery.city && touched.order_delivery
                      ? 'rgb(246, 78, 96)'
                      : '#ddd',
                }),
              }}
            />
          </div>
          <div className="col-md-4 mb-5 mb-md-0">
            <label htmlFor="city">Kode Pos *</label>
            <CreatableSelect
              id="codepos"
              name="codepos"
              type="number"
              options={postalCodeOptions.map((code) => ({
                label: code,
                value: code,
              }))}
              value={{
                label: order_delivery.codepos,
                value: order_delivery.codepos,
              }}
              placeholder="Kode Pos..."
              onChange={({ value }) => {
                setFieldValue('order_delivery.codepos', value);
              }}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  border:
                    errors.order_delivery && errors.order_delivery.codepos && touched.order_delivery
                      ? '1px solid rgb(246, 78, 96)'
                      : '1px solid #ddd',
                }),
                dropdownIndicator: (provided, state) => ({
                  ...provided,
                  color:
                    errors.order_delivery && errors.order_delivery.codepos && touched.order_delivery
                      ? 'rgb(246, 78, 96)'
                      : '#ddd',
                }),
              }}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
