import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { ModalProgressBar } from '../../../../../_metronic/_partials/controls';

export const VoucherEditDialogHeader = ({ id }) => {
  const { voucherForEdit, actionsLoading } = useSelector(
    (state) => ({
      voucherForEdit: state.voucher.voucherForEdit,
      actionsLoading: state.voucher.actionsLoading,
    }),
    shallowEqual
  );

  const [title, setTitle] = useState('');
  useEffect(() => {
    let _title = id ? '' : 'New Voucher';
    if (voucherForEdit && id) {
      _title = `Edit Voucher ${voucherForEdit.code}`;
    }

    setTitle(_title);
    // eslint-disable-next-line
  }, [voucherForEdit, actionsLoading]);

  return (
    <>
      {actionsLoading && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
    </>
  );
};
