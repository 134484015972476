import React, { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import BootstrapTable from "react-bootstrap-table-next";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
} from "../../../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";

export const MemberEditAddress = ({
  entities,
  setMemberAddress,
  setShowMemberAddress,
  setShowDeleteMemberAddress,
  setMemberAddressUUID,
}) => {
  useEffect(() => {
    // eslint-disable-next-line
  }, []);
  const columns = [
    {
      dataField: "recipient_name",
      text: "Nama",
      sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses,
    },
    {
      dataField: "recipient_phone",
      text: "HP",
      sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses,
    },
    {
      dataField: "is_default",
      text: "Default",
      sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses,
    },
    {
      dataField: "label",
      text: "Label",
      sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses,
    },
    {
      dataField: "sub_district",
      text: "Kecamatan",
      sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses,
    },
    {
      dataField: "city",
      text: "Kota",
      sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses,
    },
    {
      dataField: "province",
      text: "Provinsi",
      sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses,
    },
    {
      dataField: "codepos",
      text: "Kode pos",
      sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses,
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditMemberAddress: setMemberAddress,
        setShowMemberAddress: setShowMemberAddress,
        setMemberAddressUUID: setMemberAddressUUID,
        setShowDeleteMemberAddress: setShowDeleteMemberAddress,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  return (
    <BootstrapTable
      wrapperClasses="table-responsive"
      bordered={false}
      classes="table table-head-custom table-vertical-center overflow-hidden"
      bootstrap4
      remote
      keyField="uuid"
      data={entities === null ? [] : entities}
      columns={columns}
    >
      <PleaseWaitMessage entities={entities} />
      <NoRecordsFoundMessage entities={entities} />
    </BootstrapTable>
  );
};
