import {createSlice} from "@reduxjs/toolkit";

const initialCategorysState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  categoryForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const categorySlice = createSlice({
  name: "categorys",
  initialState: initialCategorysState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getCategoryById
    categoryFetched: (state, action) => {
      state.actionsLoading = false;
      state.categoryForEdit = action.payload.categoryForEdit;
      state.error = null;
    },
    // findCategorys
    categorysFetched: (state, action) => {
      // console.log(action);
      // const { total_items, items } = action.payload;
      const { children } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = children;
      // state.totalCount = total_items;
    },
    // getAllCategory
    categoryAllFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.all= action.payload.all
    },
    // createCategory
    categoryCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.category);
    },
    // updateCategory
    categoryUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.category.id) {
          return action.payload.category;
        }
        return entity;
      });
    },
  }
});
