import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toastOption } from '../../../../../_metronic/_helpers';
import { LoadingAnimation } from '../../../MasterData/pages/payment-method/loading-animation/LoadingAnimation';
import { CREATE_ORDER, GET_ORDER } from '../../_redux/order/orderCrud';
import { OrderForm } from './OrderForm';
import { OrderFormHeader } from './OrderFormHeader';

export const OrderFormPage = () => {
  // * Hooks
  const { id } = useParams();
  const history = useHistory();

  // * Contexts

  // * States
  const [order, setOrder] = useState(null);

  // * Queries
  const [fetchCreateOrder, { loading: createOrderLoading }] = useMutation(CREATE_ORDER, {
    onCompleted: (data) => {
      const { createOrder } = data ?? {};
      const { uuid } = createOrder;

      if (uuid) {
        toast.success('Success', toastOption);
        setTimeout(() => {
          history.push(`/order/${uuid}/edit`);
        }, 250);
      }
    },
    onError: (error) => {
      toast.error(`Gagal membuat order, ${error}`, toastOption);
    },
  });
  const [fetchOrder, { loading: orderLoading }] = useLazyQuery(GET_ORDER, {
    onCompleted: (data) => {
      const { getOrder } = data ?? {};

      setOrder(getOrder);
    },
    onError: (error) => {
      console.error(error);
    },
    fetchPolicy: 'cache-and-network',
  });

  // * Effects
  useEffect(() => {
    if (id) {
      fetchOrder({
        variables: {
          order_uuid: id,
        },
      });
    }
  }, [id]);

  // * Functions
  function submitOrder(values) {
    if (!order) {
      createOrder(values);
    }
  }

  function createOrder(values) {
    const { branch, member, store, order_item, order_delivery: od, order_payment: op } = values;

    const order = {
      marketplace_invoice: values.marketplace_invoice,
      trx_date: values.trx_date,
      branch_uuid: values.branch_uuid,
      member_uuid: member.uuid,
      store_uuid: store.uuid,
      is_online: values.is_online,
      is_dropship: values.is_dropship,
    };

    if (values.is_dropship) {
      order.dropship_name = values.dropship_name;
      order.dropship_hp = values.dropship_hp;
    }

    const { __typename, ...service_snapshot } = od.service_snapshot ?? {};

    const order_delivery = {
      awb: od.awb,
      address: od.address,
      address_uuid: od.address_uuid,
      city: od.city,
      codepos: od.codepos,
      province: od.province,
      receiver_hp: od.receiver_hp,
      receiver_name: od.receiver_name,
      sub_district: od.sub_district,
    };

    if (service_snapshot) {
      order_delivery.service_snapshot = service_snapshot;
      order_delivery.shipping_cost = service_snapshot.final_price;
      order_delivery.shipping_service_uuid = service_snapshot.uuid;
    }

    const order_payment = {
      payment_method_uuid: op.payment_method_uuid,
      amount: op.amount,
    };

    const order_items = order_item.map((item) => {
      const { product_sku_uuid, qty, assigned_price } = item;

      return { product_sku_uuid, qty, assigned_price };
    });

    if (!order.uuid) {
      return fetchCreateOrder({
        variables: {
          ...order,
          order_payment,
          order_items,
          order_delivery: values.is_online ? order_delivery : null,
        },
      });
    }
  }

  const actionsLoading = orderLoading || createOrderLoading;

  return (
    <>
      <LoadingAnimation open={orderLoading || createOrderLoading} />
      <OrderFormHeader order={order} history={history} />
      <OrderForm order={order} actionsLoading={actionsLoading} submitOrder={submitOrder} />
    </>
  );
};
