import { createSlice } from "@reduxjs/toolkit";
import { initialFilter } from "../../pages/OrderUIHelpers";

const initialOrdersState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  orderForEdit: undefined,
  lastError: null,
  filter: {
    branches: [],
    members: [],
    statuses: ["unpaid", "paid", "processed", "delivery", "finished"],
  },
  filters: initialFilter
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const orderSlice = createSlice({
  name: "orders",
  initialState: initialOrdersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getOrderById
    orderFetched: (state, action) => {
      state.actionsLoading = false;
      state.orderForEdit = action.payload.orderForEdit;
      state.error = null;
    },
    // findOrders
    ordersFetched: (state, action) => {
      const { total_items, items } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = items;
      state.totalCount = total_items;
    },
    // createOrder
    orderCreated: (state, action) => {
      state.ewactionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.order);
    },
     orderFilter: (state, action) => {
      state.listLoading = false;
      state.error = null
      state.filters = action.payload
    },
    // updateOrder
    orderUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.order.id) {
          return action.payload.order;
        }
        return entity;
      });
    },
    // ordersUpdateState
    orderStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    orderFiltersFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;

      const { branches, members } = action.payload;

      state.filter.branches = branches.items.map((branch) => branch);
      state.filter.members = members.items.map((member) => member);
    },
  },
});
