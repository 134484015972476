// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Modal } from "react-bootstrap";
// import * as Yup from "yup";
// import { Input, Select, DatePickerField } from "../../../../../_metronic/_partials/controls";

// Validation schema
// const OrderEditSchema = Yup.object().shape({
//   firstName: Yup.string()
//     .min(3, "Minimum 3 symbols")
//     .max(50, "Maximum 50 symbols")
//     .required("Firstname is required"),
//   lastName: Yup.string()
//     .min(3, "Minimum 3 symbols")
//     .max(50, "Maximum 50 symbols")
//     .required("Lastname is required"),
//   email: Yup.string().email("Invalid email").required("Email is required"),
//   userName: Yup.string().required("Username is required"),
//   dateOfBbirth: Yup.mixed().nullable(false).required("Date of Birth is required"),
//   ipAddress: Yup.string().required("IP Address is required"),
// });

export function OrderEditForm({ saveOrder, order, actionsLoading, onHide, orderEdit }) {
  const orderDelivery = order.order_delivery;

  return (
    <>
      <Modal.Body className="overlay overlay-block cursor-default">
        {actionsLoading && (
          <div className="overlay-layer bg-transparent">
            <div className="spinner spinner-lg spinner-success" />
          </div>
        )}
        <div className="row m-0 p-0">
          <div className="col-12 col-lg-6 m-0 p-0 px-md-3 d-flex flex-column">
            <div className="row m-0 mb-5 p-0 border-2 border-bottom border-secondary">
              <div className="h4">Order</div>
            </div>
            <div className="row m-0 mb-3 p-0">
              <div className="col-3 font-weight-bold display-5">uuid</div>
              <div className="col-9 display-5">
                <span className="mr-3 font-weight-bold display-5">:</span>
                <span>{order.uuid}</span>
              </div>
            </div>
            <div className="row m-0 mb-3 p-0">
              <div className="col-3 font-weight-bold display-5">Status</div>
              <div className="col-9 display-5">
                <span className="mr-3 font-weight-bold display-5">:</span>
                <span>{order.status}</span>
              </div>
            </div>
            <div className="row m-0 mb-3 p-0">
              <div className="col-3 font-weight-bold display-5">trx_date</div>
              <div className="col-9 display-5">
                <span className="mr-3 font-weight-bold display-5">:</span>
                <span>{new Date(order.trx_date).toDateString()}</span>
              </div>
            </div>
            <div className="row m-0 mb-3 p-0">
              <div className="col-3 font-weight-bold display-5">is_online</div>
              <div className="col-9 display-5">
                <span className="mr-3 font-weight-bold display-5">:</span>
                <span>{`${order.is_online}`}</span>
              </div>
            </div>
            <div className="row m-0 mb-3 p-0">
              <div className="col-3 font-weight-bold display-5">invoice_no</div>
              <div className="col-9 display-5">
                <span className="mr-3 font-weight-bold display-5">:</span>
                <span>{order.invoice_no}</span>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 m-0 p-0 px-md-3 d-flex flex-column">
            <div className="row m-0 mb-5 p-0 border-2 border-bottom border-secondary">
              <div className="h4">Branch</div>
            </div>
            <div className="row m-0 mb-3 p-0">
              <div className="col-3 font-weight-bold display-5">City</div>
              <div className="col-9 display-5">
                <span className="mr-3 font-weight-bold display-5">:</span>
                <span>{order.branch.city}</span>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 m-0 p-0 px-md-3 d-flex flex-column">
            <div className="row m-0 my-5 p-0 border-2 border-bottom border-secondary">
              <div className="h4">Member</div>
            </div>
            <div className="row m-0 mb-3 p-0">
              <div className="col-3 font-weight-bold display-5">Member uuid</div>
              <div className="col-9 display-5">
                <span className="mr-3 font-weight-bold display-5">:</span>
                <span>{order.member.uuid}</span>
              </div>
            </div>
            <div className="row m-0 mb-3 p-0">
              <div className="col-3 font-weight-bold display-5">Member name</div>
              <div className="col-9 display-5">
                <span className="mr-3 font-weight-bold display-5">:</span>
                <span>{order.member.name}</span>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 m-0 p-0 px-md-3 d-flex flex-column">
            <div className="row m-0 my-5 p-0 border-2 border-bottom border-secondary">
              <div className="h4">Delivery</div>
            </div>
            <div className="row m-0 mb-3 p-0">
              <div className="col-3 font-weight-bold display-5">Destination</div>
              <div className="col-9 display-5">
                <span className="mr-3 font-weight-bold display-5">:</span>
                <span>{orderDelivery && orderDelivery.city}</span>
              </div>
            </div>
            <div className="row m-0 mb-3 p-0">
              <div className="col-3 font-weight-bold display-5">Company Name</div>
              <div className="col-9 display-5">
                <span className="mr-3 font-weight-bold display-5">:</span>
                <span>{orderDelivery && orderDelivery.shipping_service && orderDelivery.shipping_service.company_name}</span>
              </div>
            </div>
            <div className="row m-0 mb-3 p-0">
              <div className="col-3 font-weight-bold display-5">Service Name</div>
              <div className="col-9 display-5">
                <span className="mr-3 font-weight-bold display-5">:</span>
                <span>{orderDelivery && orderDelivery.shipping_service && orderDelivery.shipping_service.service}</span>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" onClick={onHide} className="btn btn-light btn-elevate">
          Close
        </button>
      </Modal.Footer>
    </>
  );
}
