import React, { useMemo, useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import { isEqual } from "lodash";
import { useOrderReportUIContext } from "../OrderReportUIContext";
import { initialFilter, STATUS_OPTIONS } from "../OrderReportUIHelpers";
import ReactSelect from "react-select";
import { notification_type } from "../OrderReportUIHelpers";
import { Input } from "../../../../../_metronic/_partials/controls";
import { FIND_STORE } from "../../../MasterData/_redux/store/storeCrud";
import { useLazyQuery } from "@apollo/client";
import AsyncSelect from "react-select/async";
import { DatePicker } from "antd";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { ORDER_STATUS } from "../../../Order/pages/OrderUIHelpers";
import { SEARCH_MEMBER } from "../../../Member/_redux/member/memberCrud";

const prepareFilter = (queryParams, values) => {
  const { start_date, end_date, store_name, store_uuid, list_status, member_uuid } = values;
  const newQueryParams = { ...queryParams };
  newQueryParams.start_date = start_date ?? "";
  newQueryParams.end_date = end_date ?? "";
  newQueryParams.store_name = store_name ?? "";
  newQueryParams.store_uuid = store_uuid ?? "";
  newQueryParams.list_status = list_status ?? "";
  newQueryParams.member_uuid = member_uuid ?? "";

  return newQueryParams;
};
let defaultOrderStatus = Object.keys(ORDER_STATUS);

let listStatus = defaultOrderStatus;

export function OrderReportFilter({ listLoading }) {
  // OrderReports UI Context
  const orderReportUIContext = useOrderReportUIContext();
  const orderReportUIProps = useMemo(() => {
    return {
      queryParams: orderReportUIContext.queryParams,
      setQueryParams: orderReportUIContext.setQueryParams,
    };
  }, [orderReportUIContext]);
  const [unCheckedArray, setUnCheckedArray] = useState([])

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    // if (listStatus.length == 0) listStatus = defaultOrderStatus;
    const list_status = listStatus.filter(item => !unCheckedArray.includes(item))
    let finalValue = { ...values, list_status: list_status };

    const newQueryParams = prepareFilter(
      orderReportUIProps.queryParams,
      finalValue
    );
    orderReportUIProps.setQueryParams(newQueryParams);
    // if (!isEqual(newQueryParams, orderReportUIProps.queryParams)) {
    // }
  };

  const [currentTimeout, setCurrentTimeout] = useState(null);
  const [currentCallback, setCurrentCallback] = useState(() => () => null);

  // * STORE
  const [searchStore] = useLazyQuery(FIND_STORE, {
    variables: {
      is_active: true,
      market_name: "",
      platform: "",
      sort_order: "ASC", // asc||desc
      sort_field: "store_name",
      page_number: 1,
      page_size: 20,
    },
    onCompleted: ({ findStore: { items } }) => {
      currentCallback(
        items.map((values) => ({
          label: `${values.market_name} - ${values.platform}`,
          value: values.uuid,
          ...values,
        }))
      );
    },
    fetchPolicy: "cache-and-network",
  });

  function loadStoreOptions(inputValue, callback) {
    clearTimeout(currentTimeout);
    setCurrentTimeout(
      setTimeout(() => {
        searchStore({ variables: { market_name: inputValue } });
        setCurrentCallback(() => callback);
      }, 500)
    );
    // if (inputValue.length >= 1) {

    // } else {
    //   callback([]);
    // }
  }

  function changeCheckbox(value, item) {
    // if (listStatus.length == 0) listStatus = defaultOrderStatus;
    listStatus = listStatus.filter((status) => item != status);

    if (value) {
      listStatus.push(item);
      setUnCheckedArray(prevState => {
        const temp = [...prevState]
        const newItems = temp.filter(checked => checked !== item)
        return newItems;
      })
    } else {
      setUnCheckedArray(prevState => {
        const temp = [...prevState]
        temp.push(item)
        return temp
      });
    }
    // console.log(value);
    // console.log(listStatus);
    // console.log("defaultOrderStatus", defaultOrderStatus);
    // console.log("changeCheckbox", value);
  }

  function orderStatusChecklist() {
    let checbox = [];
    defaultOrderStatus.map((item) => {
      checbox.push(
        <span
          style={{
            border: "1px solid",
            marginRight: "5px",
            borderRadius: "10px",
            padding: "3px",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={true}
                onChange={(e) => changeCheckbox(e.target.checked, item)}
                checked={!unCheckedArray.includes(item)}
              />
            }
            label={item}
          />
        </span>
      );
    });
    return checbox;
  }

  // * MEMBER
  const [memberName, setMemberName] = useState('');
  const [searchMembers] = useLazyQuery(SEARCH_MEMBER, {
    variables: {
      name: '',
    },
    onCompleted: ({ findMember: { items } }) => {
      currentCallback(items.map(({ uuid, name }) => ({ label: name, value: uuid })));
    },
  });

  function loadOptions(queryCaller, inputValue, callback) {
    clearTimeout(currentTimeout);

    if (inputValue.length >= 3) {
      setCurrentTimeout(
        setTimeout(() => {
          queryCaller({ variables: { name: inputValue } });
          setCurrentCallback(() => callback);
        }, 250)
      );
    } else {
      callback([]);
    }
  }

  return (
    <>
      <Card>
        <CardHeader title="Pemasukan"></CardHeader>
        <CardBody>
          <Formik
            initialValues={initialFilter}
            onSubmit={(values) => {
              applyFilter(values);
            }}
          >
            {({
              values,
              handleSubmit,
              handleBlur,
              handleChange,
              setFieldValue,
              resetForm,
            }) => (
              <>
                <Form onSubmit={handleSubmit} className="form form-label-right">
                  <div className="form-group row">
                    <div className="col-lg-4">
                      <DatePicker.RangePicker
                        style={{ width: "100%" }}
                        value={values.dateValue ?? null}
                        onChange={(e) => {
                          if (e) {
                            e.map((time, index) => {
                              setFieldValue("dateValue", e);
                              index === 0
                                ? setFieldValue(
                                    "start_date",
                                    time
                                      .hour(0)
                                      .minute(0)
                                      .second(0)
                                      .format("YYYY-MM-DD HH:mm:ss")
                                  )
                                : setFieldValue(
                                    "end_date",
                                    time
                                      .hour(23)
                                      .minute(59)
                                      .second(59)
                                      .format("YYYY-MM-DD HH:mm:ss")
                                  );
                            });
                          } else {
                            setFieldValue("dateValue", null);
                            setFieldValue("end_date", null);
                            setFieldValue("start_date", null);
                          }
                        }}
                      />
                      <small className="form-text ">
                        Cari <b>Tanggal</b>
                      </small>
                    </div>

                    <div className="col-lg-4">
                      <AsyncSelect
                        isClearable
                        id="store"
                        cacheOptions={false}
                        name="store_uuid"
                        placeholder="Cari Store..."
                        noOptionsMessage={({ inputValue }) =>
                          inputValue.length >= 1 ? (
                            <span>Store Tidak di temukan</span>
                          ) : (
                            <span>Store Tidak di temukan</span>
                          )
                        }
                        loadOptions={loadStoreOptions}
                        defaultOptions={true}
                        value={
                          values.store_name && values.store_uuid
                            ? {
                                label: values.store_name,
                                value: values.store_uuid,
                              }
                            : null
                        }
                        onChange={(option) => {
                          if (option) {
                            setFieldValue("store_uuid", option.value);
                            setFieldValue("store_name", option.label);
                          } else {
                            setFieldValue("store_uuid", null);
                            setFieldValue("store_name", null);
                          }
                        }}
                      />
                      <small className="form-text ">
                        Cari <b>Store</b>
                      </small>
                    </div>
                    <div className="col-lg-4">
                      <AsyncSelect
                        isClearable
                        cacheOptions={true}
                        name="member_uuid"
                        placeholder="Member"
                        noOptionsMessage={({ inputValue }) =>
                          inputValue.length >= 3 ? 'No options' : 'Type 3 or more characters'
                        }
                        loadOptions={(inputValue, callback) =>
                          loadOptions(searchMembers, inputValue, callback)
                        }
                        defaultOptions={false}
                        value={
                          memberName && values.member_uuid
                            ? {
                                label: memberName,
                                value: values.member_uuid,
                              }
                            : null
                        }
                        onChange={(e) => {
                          const value = e;
                          setMemberName(value && value.label);
                          setFieldValue('member_uuid', value && value.value);
                        }}
                      />
                      <small className="form-text ">
                      Cari <b>Member</b>
                    </small>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">{orderStatusChecklist()}</div>
                  </div>
                  <div className="mt-2">
                  <button
                      type="submit"
                      onClick={handleSubmit}
                      className="btn btn-primary btn-elevate float-right mb-6 ml-4"
                    >
                      Terapkan
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        // setUnCheckedArray(defaultOrderStatus)
                        resetForm()
                        handleSubmit()
                      }}
                      className="btn btn-danger btn-elevate float-right mb-6 ml-8"
                    >
                      Hapus Pencarian
                    </button>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </CardBody>
      </Card>
    </>
  );
}
